import { SpecialLook } from '..';
import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Look extends makeDescribable(Action) {
    id = 'look';

    description(game: Game): string {
        return 'look around';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('look'),
            new Verb('look around'),
            new Verb('l'),
        ];
        return {
            handlers: [lookHandler],
            parser: () => Parse.words(verbs).into(new Look()),
            // TODO crs joke "ls" that lists some fake files....?
            verbs,
            prepositions: [],
        };
    }
}

export const lookHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Look)) return;
    await game.applyAction(runner, new SpecialLook({ force: true }));
    return Action.complete();
};
