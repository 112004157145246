import { Passage, Room } from '..';
import { MAZE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Maze4 } from '../Maze4';
import { Maze2 } from '../Maze2';
import { Maze5 } from '../Maze5';

export class Maze3 extends Room {
    static spec(): EntitySpec<Maze3> {
        return {
            ref: 'maze-3',
            constructor: Maze3,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Maze3.spec().ref;
    }

    name(): string {
        return 'Maze';
    }

    description(): string {
        return MAZE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: Maze2.spec().ref }),
            new Passage({ via: Direction.North, to: Maze4.spec().ref }),
            new Passage({ via: Direction.Up, to: Maze5.spec().ref }),
        ];
    }

    // TODO
    isPartOfMaze() {
        return true;
    }
}
