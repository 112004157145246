import { Adjective, Article, Noun } from './lexicon';

export class Obj {
    article: Article | undefined;

    adjectives: Adjective[];

    noun: Noun;

    constructor(
        article: Article | undefined,
        adjectives: Adjective[],
        noun: Noun
    ) {
        this.article = article;
        this.adjectives = adjectives;
        this.noun = noun;
    }

    get description() {
        return this.adjectives
            .concat(this.noun)
            .map((a) => a.value)
            .join(' ');
    }
}
