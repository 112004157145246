import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { NarrowLedge } from '../NarrowLedge';
import { BlueBook, GreenBook, PurpleBook, WhiteBook } from '../../items';

export class Library extends Room {
    static spec(): EntitySpec<Library> {
        return {
            ref: 'library',
            constructor: Library,
            initial: {
                contents: [
                    BlueBook.spec().ref,
                    PurpleBook.spec().ref,
                    GreenBook.spec().ref,
                    WhiteBook.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Library.spec().ref;
    }

    name(): string {
        return 'Library';
    }

    description(): string {
        return (
            'This is a room which must have been a large library, ' +
            'probably for the royal family. All of the shelves appear ' +
            'to have been gnawed to pieces by unfriendly gnomes. To the north is an exit.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [SpecialDirection.Out, Direction.North],
                to: NarrowLedge.spec().ref,
            }),
        ];
    }
}
