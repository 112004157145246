import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    RequestUnresolved,
    requestUnresolvedHandler,
} from './RequestUnresolved';

export class Request extends Action {
    id = '~request';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [requestHandler, requestUnresolvedHandler],
            parser,
            verbs: [
                new Verb('send for'),
                new Verb('request'),
                new Verb('order'),
            ],
            prepositions: [],
        };
    }
}

export const requestHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Request)) return;
    const { item } = action;
    if (item.isActor() && item.isVillain()) {
        await runner.doOutput(`Why would you send for ${item.the()}?`);
    } else {
        await runner.doOutput(`That doesn't make sends.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, RequestUnresolved> => {
    const request = Parse.words(Request.ability().verbs);
    const requestObject = request.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // request
        request.into(new RequestUnresolved({ item: undefined })),
        // request brochure
        requestObject.map((item) => new RequestUnresolved({ item }))
    );
};
