import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeExaminable, makeTakeable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Ring } from '../../abilities/Ring';
import { EntranceToHades } from '../../rooms/EntranceToHades';
import { Candles } from '../Candles';
import { SpecialTimerTick, Take } from '../../abilities';
import { Player } from '../../actors';
import { PourOn } from '../../abilities/PourOn';
import { QuantityOfWater } from '../QuantityOfWater';

interface BellState extends ItemState {
    isHot: boolean;
    hotDuration: number;
}

abstract class Base extends Item<BellState> {}

export class Bell extends makeExaminable(makeTakeable(Base)) {
    static spec(): EntitySpec<Bell> {
        return {
            ref: 'bell',
            constructor: Bell,
            initial: {
                hotDuration: 0,
                hasBeenTaken: false,
                isHot: false,
            },
            nouns: [
                new Noun('bell'),
                new Noun('bells', { plural: true }),
                new Noun('hand-bell'),
                new Noun('hand-bells', { plural: true }),
                new Noun('hand bell'),
                new Noun('hand bells', { plural: true }),
            ],
            adjectives: [
                new Adjective('small'),
                new Adjective('brass'),
                new Adjective('red-hot'),
                new Adjective('red hot'),
                new Adjective('hot'),
            ],
            handlers: [
                ringBell,
                coolBell,
                pourWaterOnBell,
                takeHotBell,
                ringHotBell,
            ],
        };
    }

    size(): number {
        return 10;
    }

    ref() {
        return Bell.spec().ref;
    }

    isHot() {
        return this.state.isHot;
    }

    setIsHot(isHot: boolean) {
        this.state.isHot = isHot;
    }

    setHotDuration(hotDuration: number) {
        this.state.hotDuration = hotDuration;
    }

    hotDuration() {
        return this.state.hotDuration;
    }

    name(): string {
        return this.isHot() ? 'red hot brass bell' : 'bell';
    }

    description(): string {
        return this.isHot()
            ? 'On the ground is a red hot bell.'
            : 'There is a small brass bell here.';
    }

    initialDescription() {
        return 'Lying in a corner of the room is a small brass bell.';
    }

    nouns() {
        return Bell.spec().nouns;
    }

    adjectives() {
        const adjectives = [new Adjective('small'), new Adjective('brass')];
        if (this.isHot()) {
            adjectives.push(
                new Adjective('red-hot'),
                new Adjective('red hot'),
                new Adjective('hot')
            );
        }
        return adjectives;
    }

    examineText(): string {
        return this.isHot()
            ? 'It is quite literally red-hot and cannot be closely examined ' +
                  'without seriously damaging your fingers.'
            : 'It is a small brass bell with a leather strap. ' +
                  'Inside the bell is an engraving which reads, ' +
                  '"Property of the First Congregational Church of Zork".';
    }
}

const ringBell: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Ring) && action.item.is(Bell) && !action.item.isHot()) {
        await runner.doOutput('Ding, dong.');
        const room = actor?.location();
        if (room?.is(EntranceToHades)) {
            await runner.doOutput(
                'The bell suddenly becomes red hot and falls to the ground. ' +
                    'The wraiths, as if paralyzed, stop their jeering and slowly ' +
                    'turn to face you. On their ashen faces, the expression of a ' +
                    'long-forgotten terror takes shape.'
            );
            action.item.moveTo(room);
            action.item.setIsHot(true);
            action.item.setHotDuration(20);
            room.setExorcismTimeLimit(6);
            const candles = game.ent(Candles);
            if (actor?.hasItem(candles)) {
                await runner.doOutput(
                    'In your confusion, the candles drop to the ground (and they are out).'
                );
                candles.moveTo(room);
                candles.state.isAflame = false;
            }
        }
        return Action.complete();
    }
};

const coolBell: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const bell = game.ent(Bell);
        if (bell.isHot()) {
            bell.setHotDuration(bell.hotDuration() - 1);
            if (bell.hotDuration() <= 0) {
                bell.setIsHot(false);
                if (bell.location()?.isEqualTo(game.ent(Player).location())) {
                    await runner.doOutput(
                        'The bell appears to have cooled down.'
                    );
                }
            }
        }
        return Action.incomplete();
    }
};

const pourWaterOnBell: Handler = async ({ action, runner }) => {
    if (
        action.is(PourOn) &&
        action.item.is(QuantityOfWater) &&
        action.surface.is(Bell) &&
        action.surface.isHot()
    ) {
        action.item.moveTo(undefined);
        action.surface.setIsHot(false);
        await runner.doOutput('The water cools the bell and is evaporated.');
        return Action.complete();
    }
};

const takeHotBell: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Bell) && action.item.isHot()) {
        await runner.doOutput('The bell is very hot and cannot be taken.');
        return Action.complete();
    }
};

const ringHotBell: Handler = async ({ action, runner }) => {
    if (action.is(Ring) && action.item.is(Bell) && action.item.isHot()) {
        await runner.doOutput('The bell is too hot to reach.');
        return Action.complete();
    }
};

/*
TODO "ring bell with X"

	  (<AND <VERB? "RING"> .PRSI>
	   <COND (<TRNN <PRSI> ,BURNBIT>
		  <TELL "The " 1 <ODESC2 <PRSI>> " burns and is consumed.">
		  <REMOVE-OBJECT <PRSI>>)
		 (<==? <PRSI> <SFIND-OBJ "HANDS">>
		  <TELL "The bell is too hot to reach.">)
		 (<TELL "The heat from the bell is too intense.">)>)
 */
