import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Jade extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<Jade> {
        return {
            ref: 'jade',
            constructor: Jade,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('jade', { collective: true }),
                new Noun('figurine'),
                new Noun('figurines', { plural: true }),
            ],
            adjectives: [new Adjective('jade'), new Adjective('exquisite')],
        };
    }

    ref() {
        return Jade.spec().ref;
    }

    name() {
        return 'jade figurine';
    }

    description() {
        return 'There is an exquisite jade figurine here.';
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 5;
    }

    scoreInCase(): number {
        return 5;
    }

    nouns(): Noun[] {
        return Jade.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Jade.spec().adjectives;
    }
}
