import { EntitySpec } from '../../game';
import { RoomInAPuzzle } from '../RoomInAPuzzle11';
import { Slit, SteelDoor } from '../../items';
import { Passage } from '../Passage';
import { Direction } from '../../../parse';
import { SmallSquareRoom } from '../SmallSquareRoom';

export class RoomInAPuzzle63 extends RoomInAPuzzle {
    static spec(): EntitySpec<RoomInAPuzzle63> {
        return {
            ref: 'room-in-a-puzzle-6-3',
            constructor: RoomInAPuzzle63,
            initial: {
                hasWestLadder: false,
                hasEastLadder: false,
                containsSandstone: false,
                contents: [SteelDoor.spec().ref, Slit.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    row() {
        return 6;
    }

    column() {
        return 3;
    }

    ref() {
        return RoomInAPuzzle63.spec().ref;
    }

    featureDescriptions(): string {
        return '\nThe west wall here has a large steel door at its center. On one side of the door is a small slit.';
    }

    passages(): Passage[] {
        // TODO this is to not create a sink for the thief
        return [
            new Passage({
                via: Direction.Up,
                to: SmallSquareRoom.spec().ref,
            }),
        ];
    }
}
