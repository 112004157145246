import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { BlowInto } from './BlowInto';
import { UnresolvedAction } from '../UnresolvedAction';

export class BlowIntoUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'blow-into';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'blow into something';
        }
        return `blow into ${targetDescription(game, this.item)}`;
    }
}

export const blowIntoUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(BlowIntoUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new BlowIntoUnresolved({ item }),
        missing: () => 'Blow into what?',
        ambiguous: (desc, opt) =>
            `Into which ${desc} would you like to blow, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    return await game.applyAction(runner, new BlowInto({ item }), actor);
};
