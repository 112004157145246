import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class MahoganyPanel extends Item {
    static spec(): EntitySpec<MahoganyPanel> {
        return {
            ref: 'mahogany-panel',
            constructor: MahoganyPanel,
            initial: {},
            nouns: [
                new Noun('panel'),
                new Noun('panels', { plural: true }),
                new Noun('wall'),
                new Noun('walls', { plural: true }),
            ],
            adjectives: [
                new Adjective('mahogany'),
                new Adjective('wood'),
                new Adjective('wooden'),
            ],
        };
    }

    ref() {
        return MahoganyPanel.spec().ref;
    }

    name(): string {
        return 'mahogany panel';
    }

    description(): string {
        return '';
    }

    nouns() {
        return MahoganyPanel.spec().nouns;
    }

    adjectives() {
        return MahoganyPanel.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
