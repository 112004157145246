import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { ReadUnresolved, readUnresolvedHandler } from './ReadUnresolved';

export class Read extends Action {
    id = '~read';

    item: Entity;

    tool: Entity | undefined;

    constructor({ item, tool }: { item: Entity; tool: Entity | undefined }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [readHandler, readUnresolvedHandler],
            parser,
            verbs: [new Verb('read'), new Verb('skim')],
            prepositions: [
                new Preposition('using'),
                new Preposition('with'),
                new Preposition('through'),
            ],
        };
    }
}

export const readHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Read)) return undefined;
    const { item, tool } = action;
    if (!game.here().isLit()) {
        await runner.doOutput('It is impossible to read in the dark.');
    } else if (tool && !(tool.isItem() && tool.isTransparent())) {
        await runner.doOutput(`How does one look through ${tool.an()}?`);
    } else if (item.isItem() && item.isReadable()) {
        await runner.doOutput(item.text());
    } else {
        await runner.doOutput(`How can I read ${item.an()}?`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, ReadUnresolved> => {
    const ability = Read.ability();
    const read = Parse.words(ability.verbs);
    const readItem = read.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const readItemWith = readItem.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // read
        read.into(new ReadUnresolved({ item: undefined, tool: undefined })),
        // read cake
        readItem.map((item) => new ReadUnresolved({ item, tool: undefined })),
        // read cake through flask
        readItemWith.map(([item, tool]) => new ReadUnresolved({ item, tool }))
    );
};
