import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { EntitySpec } from '../../game';

interface BaubleState extends ItemState {
    isHidden: boolean;
}

abstract class Base extends Item<BaubleState> {}

export class Bauble extends makeTreasure(makeTakeable(Base)) {
    static spec(): EntitySpec<Bauble> {
        return {
            ref: 'bauble',
            constructor: Bauble,
            initial: {
                isHidden: true,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('bauble'),
                new Noun('baubles', { plural: true }),
                new Noun('sphere'),
                new Noun('spheres', { plural: true }),
            ],
            adjectives: [
                new Adjective('brass'),
                new Adjective('metal'),
                new Adjective('beautiful'),
            ],
        };
    }

    isHidden() {
        return this.state.isHidden;
    }

    setIsHidden(isHidden: boolean) {
        this.state.isHidden = isHidden;
    }

    ref() {
        return Bauble.spec().ref;
    }

    name() {
        return 'beautiful brass bauble';
    }

    description() {
        return 'There is a beautiful brass bauble here.';
    }

    scoreOnTake(): number {
        return 1;
    }

    scoreInCase(): number {
        return 1;
    }

    nouns(): Noun[] {
        return Bauble.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Bauble.spec().adjectives;
    }
}
