import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { TeaRoom } from '../TeaRoom';
import { Flask, Pool, PoolLeak, Saffron } from '../../items';

export class PoolRoom extends Room {
    static spec(): EntitySpec<PoolRoom> {
        return {
            ref: 'pool-room',
            constructor: PoolRoom,
            initial: {
                contents: [
                    Flask.spec().ref,
                    Pool.spec().ref,
                    Saffron.spec().ref,
                    PoolLeak.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return PoolRoom.spec().ref;
    }

    name(): string {
        return 'Pool Room';
    }

    description(): string {
        return (
            'This is a large room, one half of which is depressed. ' +
            'There is a large leak in the ceiling through which brown ' +
            'colored goop is falling. ' +
            'The only exit to this room is to the west.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [new Passage({ via: Direction.West, to: TeaRoom.spec().ref })];
    }
}
