import { Obj } from './Obj';
import { Pronoun } from './lexicon';

export class Group {
    include: Group | Obj | Pronoun;

    exclude: (Group | Obj | Pronoun)[] | undefined;

    constructor({
        include,
        exclude,
    }: {
        include: Group | Obj | Pronoun;
        exclude?: (Group | Obj | Pronoun)[];
    }) {
        this.include = include;
        this.exclude = exclude;
    }
}
