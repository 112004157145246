import { Action, Handler } from '../../game';
import { SpecialTimerTick } from '../../abilities';

const prepareEndGame: Handler = async ({ action, game, runner }) => {
    if (action.is(SpecialTimerTick)) {
        if (game.isReadyForEndgame() && !game.hasEndgameBeenHeralded()) {
            if (game.timeUntilEndgameHerald() === 0) {
                game.setTimeUntilEndgameHerald(15);
            } else if (game.timeUntilEndgameHerald() === 1) {
                game.setTimeUntilEndgameHerald(0);
                game.setHasEndgameBeenHeralded(true);
                await runner.doOutput(
                    'Suddenly a sinister wraithlike figure, cloaked and hooded, appears ' +
                        'seeming to float in the air before you. In a low, almost inaudible ' +
                        'voice he says, "I welcome you to the ranks of the chosen of Zork. ' +
                        'You have persisted through many trials and tests, and have overcome ' +
                        'them all, dispelling the darkness of ignorance and danger. ' +
                        'One such as yourself is fit to join even the Implementers!" ' +
                        'He then raises his oaken staff, and chuckling, drifts away like ' +
                        'a wisp of smoke, his laughter fading in the distance.'
                );
            } else {
                game.setTimeUntilEndgameHerald(
                    game.timeUntilEndgameHerald() - 1
                );
            }
        } else {
            game.setTimeUntilEndgameHerald(0);
        }
        return Action.incomplete();
    }
};

export const PrepareEndgame = {
    handler: () => prepareEndGame,
};
