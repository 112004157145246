import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { FightRemarks, FightResult } from '../../handlers';

export class Knife extends makeTakeable(Item) {
    static spec(): EntitySpec<Knife> {
        return {
            ref: 'knife',
            constructor: Knife,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('knife'),
                new Noun('knives', { plural: true }),
                new Noun('blade'),
                new Noun('blades', { plural: true }),
            ],
            adjectives: [
                new Adjective('nasty-looking'),
                new Adjective('nasty looking'),
                new Adjective('nasty'),
                new Adjective('plain'),
                new Adjective('sharp'),
                new Adjective('unrusty'),
            ],
        };
    }

    ref() {
        return Knife.spec().ref;
    }

    name() {
        return 'knife';
    }

    description() {
        return 'There is a nasty-looking knife lying here.';
    }

    initialDescription() {
        return 'On a table is a nasty-looking knife.';
    }

    nouns(): Noun[] {
        return Knife.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Knife.spec().adjectives;
    }

    isTool(): boolean {
        return true;
    }

    isWeapon(): boolean {
        return true;
    }

    fightRemarks(): FightRemarks {
        return KNIFE_FIGHT_REMARKS;
    }
}

const KNIFE_FIGHT_REMARKS: FightRemarks = ({ defender }) => ({
    [FightResult.Hesitated]: [],
    [FightResult.Missed]: [
        `Your stab misses the ${defender} by an inch.`,
        `A good slash, but it misses the ${defender} by a mile.`,
        `You charge, but the ${defender} jumps nimbly aside.`,
        `A quick stroke, but the ${defender} is on guard.`,
        `A good stroke, but it's too slow, the ${defender} dodges.`,
    ],
    [FightResult.Unconscious]: [
        `The shaft of your knife knocks out the ${defender}.`,
        `The ${defender} drops to the floor, unconscious.`,
        `The ${defender} is knocked out!`,
    ],
    [FightResult.Killed]: [
        `The end for the ${defender} as your knife severs his jugular.`,
        `The fatal thrust strikes the ${defender} square in the heart: He dies.`,
        `The ${defender} takes a final blow and slumps to the floor dead.`,
    ],
    [FightResult.LightWound]: [
        `The ${defender} is slashed on the arm, blood begins to trickle down.`,
        `Your knife point pinks the ${defender} on the wrist, but it's not serious.`,
        'Your stroke lands, but it was only the flat of the blade.',
        `The blow lands, making a shallow gash in the ${defender}'s arm!`,
    ],
    [FightResult.SeriousWound]: [
        `The ${defender} receives a deep gash in his side.`,
        `A savage cut on the leg stuns the ${defender}, but he can still fight!`,
        `Slash! Your stroke connects! The ${defender} could be in serious trouble!`,
    ],
    [FightResult.Stun]: [
        `The ${defender} drops to his knees, staggered.`,
        `The ${defender} is confused and can't fight back.`,
        `The quickness of your thrust knocks the ${defender} back, stunned.`,
    ],
    [FightResult.LostWeapon]: [
        `The ${defender} is disarmed by a subtle feint past his guard.`,
    ],
    // TODO these don't seem to exist for the knife in the original?
    [FightResult.SittingDuck]: [
        `Not taking any chances, you finish ${defender.the()}.`,
        `You thrust your knife into ${defender.the()}'s heart.`,
        `The unconscious ${defender} cannot defend himself: He dies.`,
    ],
});
