import { Container, Item, Vehicle } from '../../items/Item';
import { Constructor } from './Constructor';

export function makeVehicle<TBase extends Constructor<Item & Container>>(
    Base: TBase
) {
    abstract class C extends Base implements Vehicle {
        isVehicle(): this is Vehicle {
            return true;
        }
    }
    return C;
}
