import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Pedestal extends Item {
    static spec(): EntitySpec<Pedestal> {
        return {
            ref: 'pedestal',
            constructor: Pedestal,
            initial: {},
            nouns: [
                new Noun('pedestal'),
                new Noun('pedestals', { plural: true }),
                new Noun('plinth'),
                new Noun('plinths', { plural: true }),
                new Noun('stand'),
                new Noun('stands', { plural: true }),
            ],
            adjectives: [
                new Adjective('white'),
                new Adjective('marble'),
                new Adjective('stone'),
            ],
        };
    }

    ref() {
        return Pedestal.spec().ref;
    }

    name(): string {
        return 'pedestal';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Pedestal.spec().nouns;
    }

    adjectives() {
        return Pedestal.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
