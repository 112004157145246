import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Hello } from '../../abilities';

export class PoledHeads extends Item {
    static spec(): EntitySpec<PoledHeads> {
        return {
            ref: 'poled-heads',
            constructor: PoledHeads,
            initial: {},
            nouns: [
                new Noun('head'),
                new Noun('heads', { collective: true }),
                new Noun('set of poled heads'),
                new Noun('pole'),
                new Noun('poles', { collective: true }),
                new Noun('implementer'),
                new Noun('implementers', { collective: true }),
                new Noun('loser'),
                new Noun('losers', { collective: true }),
            ],
            adjectives: [new Adjective('poled')],
            handlers: [helloImplementers],
        };
    }

    ref() {
        return PoledHeads.spec().ref;
    }

    name(): string {
        return 'set of poled heads';
    }

    description(): string {
        return 'There are four heads here, mounted securely on poles.';
    }

    nouns() {
        return PoledHeads.spec().nouns;
    }

    adjectives() {
        return PoledHeads.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return true;
    }

    isSacred() {
        return true;
    }
}

// TODO test this
const helloImplementers: Handler = async ({ action, runner }) => {
    if (action.is(Hello) && action.person?.is(PoledHeads)) {
        await runner.doOutput(
            'The implementers are dead; therefore they do not respond.'
        );
        return Action.complete();
    }
};
