import { Comma, Token, QuotationMark, Whitespace, Word, Value } from '.';

export function tokenize(command: string): Token<Value>[] {
    // TODO Not sure why the split gives any empty strings, so for now
    // we just filter them out.
    return command
        .trim()
        .split(/(,|\s+|")/)
        .filter((x) => x.length)
        .reduce((tokens, token) => {
            let value;
            if (/^\s+$/.test(token)) {
                value = new Whitespace(token);
            } else if (token === ',') {
                value = new Comma(token);
            } else if (token === '"') {
                value = new QuotationMark(token);
            } else {
                value = new Word(token);
            }
            return tokens.concat(new Token(value, []));
        }, [] as Token<Value>[]);
}
