import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { BlowUpUnresolved, blowUpUnresolvedHandler } from './BlowUpUnresolved';

export class BlowUp extends Action {
    id = '~blow-up';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [blowUpHandler, blowUpUnresolvedHandler],
            parser,
            verbs: [new Verb('blow')],
            prepositions: [new Preposition('up')],
        };
    }
}

export const blowUpHandler: Handler = async ({ action, runner }) => {
    if (!action.is(BlowUp)) return;
    await runner.doOutput("I don't really know how to do that.");
    return Action.complete();
};

const parser = (game: Game): Parser<Value, BlowUpUnresolved> => {
    const ability = BlowUp.ability();
    const blowUp = Parse.words(ability.verbs)
        .before(Parse.whitespace())
        .before(Parse.words(ability.prepositions));
    const blowUpObject = blowUp.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const blowObjectUp = Parse.word('blow')
        .before(Parse.whitespace())
        .beforeX(Parse.target(game.lexicon))
        .before(Parse.whitespace())
        .before(Parse.word('up'));
    return Parse.any(
        // blow up
        blowUp.into(new BlowUpUnresolved({ item: undefined })),
        // blow up safe
        Parse.either(blowUpObject, blowObjectUp).map(
            (item) => new BlowUpUnresolved({ item })
        )
    );
};
