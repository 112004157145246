import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Take } from '../../abilities';
import { SteelCage } from '../SteelCage';
import { DingyCloset } from '../../rooms/DingyCloset';
import { Player } from '../../actors';

interface WhitePalantirState extends ItemState {
    isProtected: boolean;
}

abstract class Base extends Item<WhitePalantirState> {}

export class WhitePalantir extends makeTreasure(makeTakeable(Base)) {
    static spec(): EntitySpec<WhitePalantir> {
        return {
            ref: 'white-palantir',
            constructor: WhitePalantir,
            initial: {
                hasBeenTaken: false,
                isProtected: true,
            },
            nouns: [
                new Noun('sphere'),
                new Noun('spheres', { plural: true }),
                new Noun('ball'),
                new Noun('balls', { plural: true }),
                new Noun('palantir'),
                new Noun('palantirs', { plural: true }),
                new Noun('stone'),
                new Noun('stones', { plural: true }),
            ],
            adjectives: [
                new Adjective('seeing'),
                new Adjective('beautiful'),
                new Adjective('white'),
                new Adjective('crystal'),
                new Adjective('glass'),
            ],
            handlers: [takePalantir],
        };
    }

    ref() {
        return WhitePalantir.spec().ref;
    }

    name() {
        return 'white crystal sphere';
    }

    description() {
        return 'There is a beautiful white crystal sphere here.';
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 6;
    }

    scoreInCase(): number {
        return 6;
    }

    nouns(): Noun[] {
        return WhitePalantir.spec().nouns;
    }

    adjectives(): Adjective[] {
        return WhitePalantir.spec().adjectives;
    }
}

const takePalantir: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Take) && action.item.is(WhitePalantir)) {
        const { item: palantir } = action;
        if (palantir.state.isProtected) {
            const cage = game.ent(SteelCage);
            const room = game.ent(DingyCloset);
            cage.moveTo(room);
            if (actor?.is(Player)) {
                actor?.moveTo(cage);
                palantir.state.isProtected = false;
                await runner.doOutput(TRAP_MESSAGE);
                room.state.isPoisonTimerActivated = true;
            } else {
                palantir.moveTo(undefined);
                actor?.moveTo(undefined);
                await runner.doOutput(ROBOT_CRUSHED);
            }
            return Action.complete();
        }
    }
};

const TRAP_MESSAGE =
    'As you reach for the sphere, a steel cage falls from the ' +
    'ceiling to entrap you. To make matters worse, ' +
    'poisonous gas starts coming into the room.';

const ROBOT_CRUSHED =
    'As the robot reaches for the sphere, a steel cage falls from the ' +
    'ceiling. The robot attempts to fend it off, but is trapped below it. ' +
    'Alas, the robot short-circuits in his vain attempt to escape, ' +
    'and crushes the sphere beneath him as he falls to the floor.';
