import { Cellar, InASlide1, MineEntrance, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { ColdPassage } from '../ColdPassage';
import { Coffin, Rope, Slide, Timber } from '../../items';
import { Go, SpecialDescribe, SpecialEnter } from '../../abilities';

export class SlideRoom extends Room {
    static spec(): EntitySpec<SlideRoom> {
        return {
            ref: 'slide-room',
            constructor: SlideRoom,
            initial: {
                contents: [Slide.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [goFromSlideRoom, describeTiedItem],
        };
    }

    ref() {
        return SlideRoom.spec().ref;
    }

    name(): string {
        return 'Slide Room';
    }

    description(): string {
        return (
            'This is a small chamber, which appears to have ' +
            'been part of a coal mine. On the south wall of the ' +
            'chamber the letters "Granite Wall" are etched in the rock. ' +
            'To the east is a long passage and there is a steep metal ' +
            'slide twisting downward. To the north is a small opening.'
        );
        // TODO object tied by rope here?
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: ColdPassage.spec().ref }),
            new Passage({
                via: Direction.North,
                to: MineEntrance.spec().ref,
            }),
            new Passage({
                via: Direction.Down,
                to: Cellar.spec().ref,
            }),
        ];
    }
}

const goFromSlideRoom: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Go) && action.direction === Direction.Down && actor) {
        const room = game.locateEntity(actor);
        if (room && room.is(SlideRoom)) {
            const rope = game.ent(Rope);
            const fastenedItem = rope.fastenedItem();
            if (fastenedItem && room.contains(fastenedItem)) {
                await runner.doOutput(
                    'As you descend, you realize that the rope is slippery from the ' +
                        'grime of the coal chute and that your grasp will not last long.'
                );
                const slide = game.ent(Slide);
                slide.state.remainingTime = Math.max(
                    Math.floor(100 / Math.max(1, actor.loadWeight())),
                    2
                );
                await game.applyAction(
                    runner,
                    new SpecialEnter({ room: game.ent(InASlide1) })
                );
                return Action.complete();
            }
        }
    }
};

const describeTiedItem: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(SpecialDescribe) &&
        (action.item.is(Timber) || action.item.is(Coffin)) &&
        game.here().is(SlideRoom) &&
        game.ent(Rope).fastenedItem()?.isEqualTo(action.item)
    ) {
        await runner.doOutput(
            `${action.item.An()} is lying on the ground here. ` +
                `Tied to it is a piece of rope, which is dangling down the slide.`
        );
        return Action.complete();
    }
};
