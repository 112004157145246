import { GlacierRoom, Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { ReservoirSouth } from '../ReservoirSouth';
import { Boat, Fuse } from '../../items';
import { Stream } from '../Stream';

export class StreamView extends Room {
    static spec(): EntitySpec<StreamView> {
        return {
            ref: 'stream-view',
            constructor: StreamView,
            initial: {
                contents: [Fuse.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return StreamView.spec().ref;
    }

    name(): string {
        return 'Reservoir South';
    }

    description(): string {
        return (
            'You are standing on a path beside a gently flowing stream. ' +
            'The path travels to the north and the east.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: SpecialDirection.Launch,
                to: Stream.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({ via: Direction.East, to: ReservoirSouth.spec().ref }),
            new Passage({ via: Direction.North, to: GlacierRoom.spec().ref }),
        ];
    }
}
