import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec, Handler } from '../../game';
import { River4 } from '../River4';
import { Shore } from '../Shore';
import { Boat, River, Sand, Statue } from '../../items';
import { Dig } from '../../abilities';

export class SandyBeach extends Room {
    static spec(): EntitySpec<SandyBeach> {
        return {
            ref: 'sandy-beach',
            constructor: SandyBeach,
            initial: {
                contents: [
                    Statue.spec().ref,
                    Sand.spec().ref,
                    River.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [digInSand],
        };
    }

    ref() {
        return SandyBeach.spec().ref;
    }

    name(): string {
        return 'Sandy Beach';
    }

    description(): string {
        return (
            'You are on a large sandy beach at the shore of the river, ' +
            'which is flowing quickly by. A path runs beside the river to the south here.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: SpecialDirection.Launch,
                to: River4.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({ via: Direction.South, to: Shore.spec().ref }),
        ];
    }

    hasWalls() {
        return false;
    }

    hasGround() {
        return false;
    }
}

const digInSand: Handler = async ({ action, runner, game, actor }) => {
    if (
        action.is(Dig) &&
        action.item === undefined &&
        actor?.location()?.is(SandyBeach)
    ) {
        return game.applyAction(
            runner,
            new Dig({ tool: action.tool, item: game.ent(Sand) })
        );
    }
};
