import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { listify, targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { PutIn } from './PutIn';
import { UnresolvedAction } from '../UnresolvedAction';

export class PutInUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'put-in';

    item: Target | undefined;

    container: Target | undefined;

    constructor({
        item,
        container,
    }: {
        item: Target | undefined;
        container: Target | undefined;
    }) {
        super();
        this.item = item;
        this.container = container;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'put something';
        }
        if (this.container === undefined) {
            return `put ${targetDescription(game, this.item)} in something`;
        }
        return `put ${targetDescription(
            game,
            this.item
        )} into ${targetDescription(game, this.container)}`;
    }
}

export const putInUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(PutInUnresolved) || actor === undefined) return;

    const { items, plural } = await game.resolve(runner, action.item, actor, {
        partial: (item) =>
            new PutInUnresolved({ item, container: action.container }),
        missing: () => 'What would you like to put?',
        allowGroups: true,
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to put, ${opt}?`,
        condition: (item) => actor.hasItem(item),
    });

    if (items.length === 0) return Action.complete({ withConsequence: false });

    const { item: container } = await game.resolve(
        runner,
        action.container,
        actor,
        {
            partial: (container) =>
                new PutInUnresolved({ item: action.item, container }),
            missing: () =>
                `What do you want to put ${listify(
                    items.map((item) => item.the())
                )} in?`,
            ambiguous: (desc, opt) =>
                `In which ${desc} would you like to put ${listify(
                    items.map((item) => item.the())
                )}, ${opt}?`,
            condition: (item) =>
                item.isItem() &&
                item.isContainer() &&
                !items.some((putItem) => putItem.isEqualTo(item)) &&
                (!item.isOpenable() || item.isOpen()),
        }
    );

    // TODO crs delegate to put-in-multiple so that the container can override!
    // TODO check this out http://web.mit.edu/marleigh/www/portfolio/Files/zork/beginner-transcript.html

    if (container === undefined)
        return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, container, actor))) return Action.complete();

    if (!container.isItem() || !container.isContainer()) {
        await runner.doOutput("You can't put things in that.");
        return Action.complete();
    }

    if (container.isOpenable() && !container.isOpen()) {
        await runner.doOutput(`${container.The()} is closed.`);
        return Action.complete();
    }

    for (const item of items) {
        if (plural) {
            await runner.doOutput(`${item.Name()}: `, { newLine: false });
        }
        if (await game.reach(runner, item, actor)) {
            await game.applyAction(runner, new PutIn({ item, container }));
        }
    }
    return Action.complete();
};
