import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { GiveUnresolved, giveUnresolvedHandler } from './GiveUnresolved';
import { Entity } from '../../game/Entity';

export class Give extends Action {
    id = '~give';

    item: Entity;

    recipient: Entity;

    constructor({ item, recipient }: { item: Entity; recipient: Entity }) {
        super();
        this.item = item;
        this.recipient = recipient;
    }

    static ability(): Ability {
        return {
            handlers: [giveHandler, giveUnresolvedHandler],
            parser,
            verbs: [new Verb('give'), new Verb('offer'), new Verb('hand')],
            prepositions: [new Preposition('to')],
        };
    }
}

export const giveHandler: Handler = async ({ action, actor, runner, game }) => {
    if (!action.is(Give) || actor === undefined) return;

    const { recipient, item } = action;

    if (!(await game.have(runner, item, actor))) {
        return Action.complete();
    }

    if (!recipient.isActor()) {
        await runner.doOutput(
            `You can't give ${item.the()} to ${recipient.an()}.`
        );
    } else if (recipient.isEqualTo(actor)) {
        await runner.doOutput(`You already have ${item.the()}.`);
    } else {
        item.moveTo(recipient);
        await runner.doOutput('Done.');
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, GiveUnresolved> => {
    const ability = Give.ability();
    const give = Parse.words(ability.verbs);
    const giveThing = give.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const giveThingToActor = giveThing.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.word('to'))
                .after(Parse.whitespace())
        ).map((recipient) => [item, recipient])
    );
    const giveActorThing = giveThing.chain((recipient) =>
        Parse.option(Parse.target(game.lexicon).after(Parse.whitespace())).map(
            (item) => [item, recipient]
        )
    );
    return Parse.any(
        // give
        give.into(
            new GiveUnresolved({ recipient: undefined, item: undefined })
        ),
        // give sandwich
        giveThing.map(
            (item) => new GiveUnresolved({ recipient: undefined, item })
        ),
        // give sandwich to cyclops / give cyclops sandwich
        Parse.either(giveThingToActor, giveActorThing).map(
            ([item, recipient]) => new GiveUnresolved({ recipient, item })
        )
    );
};
