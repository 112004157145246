import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeOpenable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Open } from '../../abilities';
import { LivingRoom } from '../../rooms';
import { DUMMY } from '../../constants';

interface TrapDoorState extends ItemState {
    isHidden: boolean;
    isSacred: boolean;
}

abstract class Base extends Item<TrapDoorState> {}

export class TrapDoor extends makeOpenable(Base) {
    static spec(): EntitySpec<TrapDoor> {
        return {
            ref: 'trap-door',
            constructor: TrapDoor,
            initial: {
                isSacred: false,
                isHidden: true,
                isOpen: false,
            },
            nouns: [
                new Noun('door'),
                new Noun('doors', { plural: true }),
                new Noun('trap door'),
                new Noun('trap doors', { plural: true }),
                new Noun('trapdoor'),
                new Noun('trapdoors', { plural: true }),
                new Noun('trap-door'),
                new Noun('trap-doors', { plural: true }),
            ],
            adjectives: [new Adjective('secret')],
            handlers: [openCloseTrapDoor],
        };
    }

    ref() {
        return TrapDoor.spec().ref;
    }

    name(): string {
        return 'trap door';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    canBeOpened(): boolean {
        return true;
    }

    canSeeInto(): boolean {
        return this.isOpen() || this.isTransparent();
    }

    nouns() {
        return TrapDoor.spec().nouns;
    }

    adjectives() {
        return TrapDoor.spec().adjectives;
    }

    isHidden(): boolean {
        return this.state.isHidden;
    }

    isSacred(): boolean {
        return this.state.isSacred;
    }

    setIsSacred(isSacred: boolean) {
        this.state.isSacred = isSacred;
    }

    setIsHidden(isHidden: boolean) {
        this.state.isHidden = isHidden;
    }

    isDoor() {
        return true;
    }
}

const openCloseTrapDoor: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Open) && action.item.is(TrapDoor)) {
        if (action.item.isOpen()) {
            await runner.doOutput(game.choiceOf(DUMMY));
        } else if (actor?.location()?.is(LivingRoom)) {
            action.item.setIsOpen(true);
            await runner.doOutput(
                'The door reluctantly opens to reveal a rickety staircase descending into darkness.'
            );
        } else {
            await runner.doOutput('The door is locked from above.');
        }
        return Action.complete();
    }

    if (action.is(Close) && action.item.is(TrapDoor)) {
        if (!action.item.isOpen()) {
            await runner.doOutput(game.choiceOf(DUMMY));
        } else if (actor?.location()?.is(LivingRoom)) {
            action.item.setIsOpen(false);
            await runner.doOutput('The door swings shut and closes.');
        } else {
            await runner.doOutput("You can't reach it from here.");
        }
        return Action.complete();
    }
};
