import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that parses a single node with any value.
 *
 * ```
 * node().match(tokenize("cat")) // successfully matches
 * node().match(Set.empty())     // no match
 * ```
 */
export class NodeParser<T> extends Parser<T, T> {
    *match(tokens: Token<T>[]): Generator<Match<T, T>> {
        const token: Token<T> = tokens[0];
        if (token) {
            yield new Match(token.clone(), tokens.slice(1));
        }
    }
}
