import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { EntitySpec } from '../../game';

interface SaffronState extends ItemState {
    isHidden: boolean;
}

abstract class Base extends Item<SaffronState> {}

export class Saffron extends makeTreasure(makeTakeable(Base)) {
    static spec(): EntitySpec<Saffron> {
        return {
            ref: 'saffron',
            constructor: Saffron,
            initial: {
                hasBeenTaken: false,
                isHidden: true,
            },
            nouns: [
                new Noun('tin'),
                new Noun('tins', { plural: true }),
                new Noun('spices', { collective: true }),
                new Noun('saffron', { collective: true }),
                new Noun('tin of spices'),
                new Noun('tins of spices', { plural: true }),
                new Noun('tin of saffron'),
                new Noun('tins of saffron', { plural: true }),
            ],
            adjectives: [
                new Adjective('metal'),
                new Adjective('platinum'),
                new Adjective('large'),
            ],
        };
    }

    isHidden(): boolean {
        return this.state.isHidden;
    }

    ref() {
        return Saffron.spec().ref;
    }

    name() {
        return 'tin of spices';
    }

    description() {
        return 'There is a tin of rare spices here.';
    }

    size() {
        return 8;
    }

    scoreOnTake(): number {
        return 5;
    }

    scoreInCase(): number {
        return 5;
    }

    nouns(): Noun[] {
        return Saffron.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Saffron.spec().adjectives;
    }
}
