import { Item } from '../../items/Item';
import { Actor } from '../../actors';

export enum FightResult {
    Missed = 'missed',
    Unconscious = 'knocked unconscious',
    Killed = 'killed',
    LightWound = 'lightly wounded',
    SeriousWound = 'seriously wounded',
    Stun = 'stunned',
    LostWeapon = 'lost weapon',
    Hesitated = 'hesitated',
    SittingDuck = 'sitting duck',
}

type RemarksTable = { [result in FightResult]: string[] };
export type FightRemarks = (options: {
    attacker: Actor;
    defender: Actor;
    attackingWeapon: Item | undefined;
    defendingWeapon: Item | undefined;
}) => RemarksTable;
