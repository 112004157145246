import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';

interface ConsoleProps {
    prefix: string;
    lines: LineProps[];
    onInput: (line: string) => void;
}

export interface LineProps {
    text: string;
    isDimmed: boolean;
    isInput: boolean;
}

export const Terminal: React.FC<ConsoleProps> = ({
    prefix,
    lines,
    onInput,
}) => {
    const [line, setLine] = useState('');
    const input = useRef<HTMLTextAreaElement>(null);
    const wrapper = useRef<HTMLDivElement>(null);
    const cursor = useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = useState(false);

    const focus = () => {
        input.current?.focus();
        setTimeout(scroll, 75);
    };

    useEffect(focus, []);

    const scroll = () => {
        cursor.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    function onKeyPress(event: React.KeyboardEvent) {
        if (event.metaKey) return;
        if (event.key === 'Enter') {
            const target = event.target as HTMLInputElement;
            target.value = '';
            onInput(line);
            setLine('');
            event.preventDefault();
        }
        if (/^[a-zA-Z0-9-_,"' ]$/.test(event.key)) {
            setLine(line + event.key);
        }
        if (event.key === 'Backspace' || event.key === 'Delete') {
            setLine(line.slice(0, -1));
        }
        event.preventDefault();
    }

    useEffect(() => {
        scroll();
    }, [line, lines]);

    return (
        <ConsoleDiv ref={wrapper} onClick={focus}>
            {lines.map((line, index) => (
                <Line key={index} className={classNameForLine(line)}>
                    {line.text}
                </Line>
            ))}
            <Line />
            <Line className="input">
                {prefix} {line}
                {isFocused && <Cursor ref={cursor} />}
            </Line>
            <Input
                autoCapitalize="off"
                onKeyDown={onKeyPress}
                ref={input}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
        </ConsoleDiv>
    );
};

function classNameForLine(line: LineProps) {
    if (line.isDimmed) return 'dimmed';
    if (line.isInput) return 'input';
    return '';
}

const Cursor = styled.span`
    &::before {
        content: ' ';
        width: 1en;
        background-color: #acb7ac;
        height: 1en;
        animation: blink 1s step-start 0s infinite;
        animation-delay: 0.5s;
    }

    @keyframes blink {
        50% {
            background-color: transparent;
        }
    }
`;

const ConsoleDiv = styled.div`
    font-size: 14px;
    color: white;
    width: calc(100% - 20px);
    min-height: calc(100% - 28px);
    padding: 10px;
    white-space: pre-wrap;
    font-family: monospace;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow: hidden;
`;

const Input = styled.textarea`
    position: absolute;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    outline: none;
    color: transparent;
    filter: alpha(opacity=0);
    caret-color: transparent;
    resize: none;
    pointer-events: none;
    transform: scale(0);
`;

const Line = styled.div`
    min-height: 16px;
    height: auto;
    flex-shrink: 0;

    &.dimmed {
        opacity: 0.5;
    }

    &.input {
        color: #acb7ac;
    }
`;
