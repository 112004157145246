import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec, Handler } from '../../game';
import { Dig } from '../../abilities';

export class Shovel extends makeTakeable(Item) {
    static spec(): EntitySpec<Shovel> {
        return {
            ref: 'shovel',
            constructor: Shovel,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('shovel'),
                new Noun('shovels', { plural: true }),
                new Noun('spade'),
                new Noun('spades', { plural: true }),
            ],
            adjectives: [new Adjective('large')],
            handlers: [digWithShovel],
        };
    }

    ref() {
        return Shovel.spec().ref;
    }

    name() {
        return 'shovel';
    }

    size() {
        return 15;
    }

    isTool() {
        return true;
    }

    description() {
        return 'There is a large shovel here.';
    }

    nouns(): Noun[] {
        return Shovel.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Shovel.spec().adjectives;
    }
}

const digWithShovel: Handler = async ({ action, runner, game, actor }) => {
    if (
        action.is(Dig) &&
        action.tool === undefined &&
        actor?.hasItem(game.ent(Shovel))
    ) {
        return game.applyAction(
            runner,
            new Dig({ tool: game.ent(Shovel), item: action.item })
        );
    }
};
