import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class MineLadder extends Item {
    static spec(): EntitySpec<MineLadder> {
        return {
            ref: 'mine-ladder',
            constructor: MineLadder,
            initial: {},
            nouns: [new Noun('ladder'), new Noun('ladders', { plural: true })],
            adjectives: [
                new Adjective('rickety'),
                new Adjective('wooden'),
                new Adjective('wood'),
            ],
        };
    }

    ref() {
        return MineLadder.spec().ref;
    }

    name(): string {
        return 'ladder';
    }

    description(): string {
        return '';
    }

    nouns() {
        return MineLadder.spec().nouns;
    }

    adjectives() {
        return MineLadder.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    canBeClimbed(): boolean {
        return true;
    }
}
