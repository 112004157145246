import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { OpenUnresolved, openUnresolvedHandler } from './OpenUnresolved';

export class Open extends Action {
    id = '~open';

    item: Entity;

    tool: Entity | undefined;

    constructor({ item, tool }: { item: Entity; tool: Entity | undefined }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [openHandler, openUnresolvedHandler],
            parser,
            verbs: [new Verb('open'), new Verb('open up')],
            prepositions: [],
        };
    }
}

export const openHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Open)) return;
    const { item } = action;
    if (item.isItem() && item.isOpenable() && item.canBeOpened()) {
        if (item.state.isOpen) {
            await runner.doOutput("It's already open.");
        } else {
            item.state.isOpen = true;
            if (item.isContainer()) {
                if (!item.isTransparent() && !item.isEmpty()) {
                    await runner.doOutput(
                        `Opening the ${item} reveals ${item.contentsString()}.`
                    );
                    return Action.complete();
                }
            }
            await runner.doOutput('Opened.');
        }
    } else {
        await runner.doOutput(`You must tell me how to open ${item.an()}.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, OpenUnresolved> => {
    const open = Parse.words(Open.ability().verbs);
    const openObject = open.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const openObjectWith = openObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // open
        open.into(new OpenUnresolved({ item: undefined, tool: undefined })),
        // open box
        openObject.map((item) => new OpenUnresolved({ item, tool: undefined })),
        // open egg with hammer
        openObjectWith.map(
            ([item, tool]) =>
                new OpenUnresolved({
                    item,
                    tool,
                })
        )
    );
};
