import { Action, Handler } from '../../game';
import { SpecialAction } from '../SpecialAction';
import { Room } from '../../rooms';

export class SpecialDescribeRoom extends SpecialAction {
    id = '*describe-room';

    room: Room;

    constructor({ room }: { room: Room }) {
        super();
        this.room = room;
    }
}

export const specialDescribeRoomHandler: Handler = async ({
    action,
    runner,
}) => {
    if (!action.is(SpecialDescribeRoom)) return;
    await runner.doOutput(action.room.description());
    return Action.complete({ withConsequence: false });
};
