import { Direction, Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Climb } from './Climb';
import { UnresolvedAction } from '../UnresolvedAction';

export class ClimbUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'climb';

    item: Target | undefined;

    direction: Direction | undefined;

    constructor({
        item,
        direction,
    }: {
        item: Target | undefined;
        direction: Direction | undefined;
    }) {
        super();
        this.item = item;
        this.direction = direction;
    }

    description(game: Game) {
        const dir = this.direction ? ` ${this.direction}` : '';
        if (this.item === undefined) {
            return `climb${dir} something`;
        }
        return `climb${dir} ${targetDescription(game, this.item)}`;
    }
}

export const climbUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(ClimbUnresolved)) return;

    const dir = action.direction ? ` ${action.direction}` : '';

    const partial = (item: Target) =>
        new ClimbUnresolved({ item, direction: action.direction });
    const { item } = await game.resolve(runner, action.item, actor, {
        partial,
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to climb${dir}, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (action.item && !item)
        return Action.complete({ withConsequence: false });

    if (item && !(await game.reach(runner, item, actor)))
        return Action.complete();

    if (item === undefined && action.direction === undefined) {
        await runner.doOutput('Climb what?');
        game.partial = partial;
        return Action.complete({ withConsequence: false });
    }

    return await game.applyAction(
        runner,
        new Climb({ item, direction: action.direction }),
        actor
    );
};
