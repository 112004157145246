import { Noun, Adjective } from '../../../parse';
import { makeExaminable, makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Count, Eat, Light } from '../../abilities';

export class Bills extends makeExaminable(makeTreasure(makeTakeable(Item))) {
    static spec(): EntitySpec<Bills> {
        return {
            ref: 'bills',
            constructor: Bills,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('bills', { collective: true }),
                new Noun('zorkmids', { collective: true }),
                new Noun('pile of bills'),
                new Noun('stack of bills', { plural: true }),
                new Noun('pile of zorkmids'),
                new Noun('stack of zorkmids', { plural: true }),
            ],
            adjectives: [
                new Adjective('zorkmid'),
                new Adjective('neat'),
                new Adjective('200'),
            ],
            handlers: [burnBills, eatBills, countBills],
        };
    }

    ref() {
        return Bills.spec().ref;
    }

    name() {
        return 'stack of zorkmid bills';
    }

    description() {
        return '200 neatly stacked zorkmid bills are here.';
    }

    initialDescription() {
        return 'On the floor sit 200 neatly stacked zorkmid bills.';
    }

    size() {
        return 10;
    }

    quantity() {
        return 200;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 15;
    }

    nouns(): Noun[] {
        return Bills.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Bills.spec().adjectives;
    }

    isFood(): boolean {
        return true;
    }

    // TODO maybe add some description here
    examineText() {
        return ZORKMID_BILLS_TEXT;
    }
}

const ZORKMID_BILLS_TEXT = ` --------------------------------------------------------------
| 1  0   0          GREAT UNDERGROUND EMPIRE         1  0   0  |
| 1 0 0 0 0                                          1 0 0 0 0 |
| 1 0 0 0 0                                          1 0 0 0 0 |
| 1  0   0                   DIMWIT                  1  0   0  |
|                       ||||||||||||||||                       |
|                       ||   __  __   ||         B30332744D    |
|                       ||  -OO  OO-  ||                       |
|      IN  FROBS        \\||    >>    ||/      WE  TRUST        |
|                        ||  ______  ||                        |
|   B30332744D            |  ------  |                         |
|                         \\\\\\\\________//                         |
| 1  0   0    Series  FLATHEAD LD Flathead           1  0   0  |
| 1 0 0 0 0   719GUE        Treasurer                1 0 0 0 0 |
| 1 0 0 0 0                                          1 0 0 0 0 |
| 1  0   0          One Hundred Royal Zorkmids       1  0   0  |
 --------------------------------------------------------------`;

export const burnBills: Handler = async ({ action, runner }) => {
    if (action.is(Light) && action.item.is(Bills)) {
        await runner.doOutput('Nothing like having money to burn!');
        return Action.incomplete();
    }
};

export const eatBills: Handler = async ({ action, runner }) => {
    if (action.is(Eat) && action.item.is(Bills)) {
        await runner.doOutput('Talk about eating rich foods!');
        return Action.complete();
    }
};

const countBills: Handler = async ({ action, runner }) => {
    if (action.is(Count) && action.item?.is(Bills)) {
        await runner.doOutput("Don't you trust me? There are 200 bills.");
        return Action.complete();
    }
};
