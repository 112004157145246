import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Foo extends makeDescribable(Action) {
    id = 'foo';

    description(game: Game): string {
        return 'say some jargon';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('foo'),
            new Verb('bar'),
            new Verb('bletch'),
            new Verb('baz'),
            new Verb('qux'),
            new Verb('quux'),
        ];
        return {
            handlers: [adventureHandler],
            parser: () => Parse.words(verbs).into(new Foo()),
            verbs,
            prepositions: [],
        };
    }
}

export const adventureHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Foo)) return;
    await runner.doOutput('Well, FOO, BAR, and BLETCH to you too!');
    return Action.complete();
};
