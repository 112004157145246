import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Inflate } from './Inflate';
import { UnresolvedAction } from '../UnresolvedAction';

export class InflateUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'inflate';

    item: Target | undefined;

    tool: Target | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Target | undefined;
        tool: Target | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'inflate something';
        }
        if (this.tool === undefined) {
            return `inflate ${targetDescription(game, this.item)}`;
        }
        return `inflate ${targetDescription(
            game,
            this.item
        )} with ${targetDescription(game, this.tool)}`;
    }
}

export const inflateUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(InflateUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new InflateUnresolved({ item, tool: undefined }),
        missing: () => 'Inflate what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to inflate, ${opt}?`,
        condition: (item) => item.isItem() && item.shouldTryToOpen(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    let tool;
    if (action.tool) {
        const { item: resolvedTool } = await game.resolve(
            runner,
            action.tool,
            actor,
            {
                partial: (tool) =>
                    new InflateUnresolved({ item: action.item, tool }),
                ambiguous: (desc, opt) =>
                    `Which ${desc} would you like to use to inflate ${item.the()}, ${opt}?`,
                condition: (item) => item.isItem() && item.isTool(),
            }
        );
        tool = resolvedTool;

        if (!tool) {
            return Action.complete({ withConsequence: false });
        }

        if (tool && !(await game.have(runner, tool, actor)))
            return Action.complete();
    }

    return await game.applyAction(runner, new Inflate({ item, tool }));
};
