import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Crown extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<Crown> {
        return {
            ref: 'crown',
            constructor: Crown,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [new Noun('crown'), new Noun('crowns', { plural: true })],
            adjectives: [new Adjective('gaudy')],
        };
    }

    ref() {
        return Crown.spec().ref;
    }

    name() {
        return 'crown';
    }

    description() {
        return "Lord Dimwit's crown is here.";
    }

    initialDescription() {
        return 'The excessively gaudy crown of Lord Dimwit Flathead is here.';
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 15;
    }

    scoreInCase(): number {
        return 10;
    }

    nouns(): Noun[] {
        return Crown.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Crown.spec().adjectives;
    }
}
