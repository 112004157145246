import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    GoThroughUnresolved,
    goThroughUnresolvedHandler,
} from './GoThroughUnresolved';

export class GoThrough extends Action {
    id = '~go-through';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [goThroughHandler, goThroughUnresolvedHandler],
            parser,
            verbs: [
                new Verb('go'),
                new Verb('pass'),
                new Verb('travel'),
                new Verb('walk'),
            ],
            prepositions: [new Preposition('through')],
        };
    }
}

export const goThroughHandler: Handler = async ({ action, runner, actor }) => {
    if (!action.is(GoThrough)) return;
    const { item } = action;
    const room = actor?.location();
    if (!room?.hasItem(item)) {
        await runner.doOutput('That would involve quite a contortion!');
    } else {
        await runner.doOutput(
            `You hit your head against ${item.the()} as you attempt this feat.`
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, GoThroughUnresolved> => {
    const goThrough = Parse.words(GoThrough.ability().verbs)
        .before(Parse.whitespace())
        .before(Parse.word('through'));
    const goThroughObject = goThrough.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // go through
        goThrough.into(new GoThroughUnresolved({ item: undefined })),
        // go through window
        goThroughObject.map((item) => new GoThroughUnresolved({ item }))
    );
};
