import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that parses the end of the input tokenset. If there are any nodes
 * remaining in the input, the match will fail.
 *
 * ```
 * word("mail").end().match(tokenize("mail")) // successfully matches
 * ```
 */
export class EndParser<I, T> extends Parser<I, T> {
    parser: Parser<I, T>;

    constructor(parser: Parser<I, T>) {
        super();
        this.parser = parser;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, T>> {
        for (const match of this.parser.match(tokens)) {
            if (match.rest.length === 0) {
                yield match;
            }
        }
    }
}
