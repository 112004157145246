import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that, given a function `maker` (which should return a parser),
 * and any arguments to pass it, creates the parser using the maker function
 * and uses it to parse the input nodes.
 *
 * This is useful when writing recursive definitions without defining a
 * dedicated parser class, such as:
 *
 * ```
 * function group() {
 *     return either(
 *        sequence(word("x"), whitespace(), recurse(group)),
 *        sequence(word("x"), whitespace(), word("x")),
 *     );
 * }
 *
 * group().match(tokenize("x x"))
 * group().match(tokenize("x x x x x"))
 * ```
 */
export class RecurseParser<I, T> extends Parser<I, T> {
    maker: () => Parser<I, T>;

    constructor(maker: () => Parser<I, T>) {
        super();
        this.maker = maker;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, T>> {
        for (const match of this.maker().match(tokens)) {
            yield match;
        }
    }
}
