import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { makeExaminable } from '../../game/Entity';

export class AtticTable extends makeExaminable(Item) {
    static spec(): EntitySpec<AtticTable> {
        return {
            ref: 'attic-table',
            constructor: AtticTable,
            initial: {},
            nouns: [new Noun('table'), new Noun('tables', { plural: true })],
            adjectives: [
                new Adjective('dusty'),
                new Adjective('wooden'),
                new Adjective('wood'),
                new Adjective('mid-century'),
            ],
        };
    }

    ref() {
        return AtticTable.spec().ref;
    }

    name(): string {
        return 'table';
    }

    description(): string {
        return '';
    }

    nouns() {
        return AtticTable.spec().nouns;
    }

    adjectives() {
        return AtticTable.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    examineText(): string {
        return 'It is a dusty wooden table about 3 feet long. It looks to be of a mid-century aesthetic.';
    }
}
