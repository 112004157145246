import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { Rope, Slide } from '../../items';
import { InASlide1 } from '../InASlide1';
import { InASlide3 } from '../InASlide3';

export class InASlide2 extends Room {
    static spec(): EntitySpec<InASlide2> {
        return {
            ref: 'in-a-slide-2',
            constructor: InASlide2,
            initial: {
                contents: [Slide.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return InASlide2.spec().ref;
    }

    name(): string {
        return 'Slide';
    }

    description(): string {
        return (
            'This is another spot within the coal chute. ' +
            'Above you the rope climbs into darkness and the end ' +
            'of the rope is dangling five feet beneath you.'
        );
    }

    globalObjects(): Entity[] {
        return [this.game.ent(Rope)];
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Up, to: InASlide1.spec().ref }),
            new Passage({
                via: Direction.Down,
                to: InASlide3.spec().ref,
            }),
        ];
    }
}
