import { Action, Handler } from '../../game';
import { Room } from '../../rooms';
import { SpecialAction } from '../SpecialAction';
import { SpecialDescribe } from '../SpecialDescribe';
import { SpecialDescribeRoom } from '../SpecialDescribeRoom';
import { SpecialListContents } from '../SpecialListContents';

export class SpecialLook extends SpecialAction {
    id = '*look';

    force: boolean;

    room: Room | undefined;

    showRoomName: boolean;

    constructor({
        force = false,
        room,
        showRoomName = true,
    }: {
        force?: boolean;
        room?: Room;
        showRoomName?: boolean;
    }) {
        super();
        this.force = force;
        this.room = room;
        this.showRoomName = showRoomName;
    }
}

export const specialLookHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SpecialLook) || actor === undefined) return;
    const room = action.room || game.locateEntity(actor);
    if (!room) throw new Error('Expected room to be a room.');

    if (room.isLit() || !actor.isAlive()) {
        if (action.showRoomName) {
            await runner.doOutput(room.name(), { isDimmed: true });
        }
        if (action.force || !room?.hasBeenDescribed()) {
            await game.applyAction(runner, new SpecialDescribeRoom({ room }));
            if (room) {
                room.state.hasBeenDescribed = true;
            }
        }

        const items = [...room.contents(), ...room.globalObjects()];
        for (const item of items) {
            await game.applyAction(runner, new SpecialDescribe({ item }));
        }
        for (const item of items) {
            if (
                item.isItem() &&
                item.isContainer() &&
                item.canSeeInto() &&
                item.shouldBeDescribed()
            ) {
                await game.applyAction(
                    runner,
                    new SpecialListContents({ item })
                );
            }
        }
        return Action.complete({ withConsequence: false });
    }
    await runner.doOutput(
        'It is pitch black. You are likely to be eaten by a grue.'
    );
    return Action.complete({ withConsequence: false });
};
