import { Passage, Room, SmallSquareRoom } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { Direction } from '../../../parse';
import { CyclopsRoom } from '../CyclopsRoom';
import { SpecialEnter } from '../../abilities';
import { Thief } from '../../actors';
import { Chalice, GraniteWall } from '../../items';
import { Temple } from '../../abilities/Temple';
import { Treasure } from '../../abilities/Treasure';
import { Temple as TempleRoom } from '../Temple';

export class TreasureRoom extends Room {
    static spec(): EntitySpec<TreasureRoom> {
        return {
            ref: 'treasure-room',
            constructor: TreasureRoom,
            initial: {
                contents: [
                    Chalice.spec().ref,
                    Thief.spec().ref,
                    GraniteWall.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [enterTreasureRoom, teleportToAndFromTemple],
        };
    }

    scoreOnEntry(): number {
        return 25;
    }

    ref() {
        return TreasureRoom.spec().ref;
    }

    name(): string {
        return 'Treasure Room';
    }

    description(): string {
        return (
            'This is a large room, whose north wall is solid granite. ' +
            'A number of discarded bags, which crumble at your touch, ' +
            'are scattered about on the floor. There is an exit down ' +
            'and what appears to be a newly created passage to the east.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Down, to: CyclopsRoom.spec().ref }),
            new Passage({
                via: Direction.East,
                to: SmallSquareRoom.spec().ref,
            }),
        ];
    }

    hasNorthWall() {
        return false;
    }
}

const enterTreasureRoom: Handler = async ({
    action,
    runner,
    game,
    extensions,
}) => {
    if (action.is(SpecialEnter) && action.room.is(TreasureRoom)) {
        await extensions.deferHandling();
        const thief = game.ent(Thief);
        if (thief.isAlive()) {
            thief.state.isFighting = true;
            const { room } = action;
            if (room && !game.locateEntity(thief)?.isEqualTo(room)) {
                thief.moveTo(room);
                await runner.doOutput(
                    "You hear a scream of anguish as you violate the robber's " +
                        'hideaway. Using passages unknown to you, he rushes to its defense.'
                );
            }
            const roomContents = room.contents();
            for (const item of roomContents) {
                if (item.isItem() && item.isTreasure() && !item.is(Chalice)) {
                    item.moveTo(undefined);
                    thief.state.hiddenItems.push(item.ref());
                }
            }
            if (thief.state.hiddenItems.length > 0) {
                await runner.doOutput(
                    'The thief gestures mysteriously, and the treasures ' +
                        'in the room suddenly vanish.'
                );
            }
        }
        return Action.complete({ withConsequence: false });
    }
};

export const teleportToAndFromTemple: Handler = async ({
    action,
    runner,
    actor,
    game,
}) => {
    if (action.is(Treasure) && actor?.location()?.is(TempleRoom)) {
        await game.applyAction(
            runner,
            new SpecialEnter({ room: game.ent(TreasureRoom) })
        );
        return Action.complete();
    }

    if (action.is(Temple) && actor?.location()?.is(TreasureRoom)) {
        await game.applyAction(
            runner,
            new SpecialEnter({ room: game.ent(TempleRoom) })
        );
        return Action.complete();
    }
};
