import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, Entity, EntitySpec, Handler } from '../../game';
import { makeTakeable } from '../../game/Entity';
import { Mung, Throw } from '../../abilities';
import { Runner } from '../../game/Runner';

export class CokeBottles extends makeTakeable(Item) {
    static spec(): EntitySpec<CokeBottles> {
        return {
            ref: 'coke-bottles',
            constructor: CokeBottles,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('coke bottle'),
                new Noun('coke bottles', { collective: true }),
                new Noun('bottle'),
                new Noun('bottles', { collective: true }),
                new Noun('bottle of coke'),
                new Noun('bottles of coke', { collective: true }),
                new Noun('coke', { collective: true }),
                new Noun('cokes', { collective: true }),
                new Noun('bunch of coke bottles'),
            ],
            adjectives: [new Adjective('empty')],
            handlers: [mungBottles],
        };
    }

    ref() {
        return CokeBottles.spec().ref;
    }

    size() {
        return 15;
    }

    name(): string {
        return 'bunch of coke bottles';
    }

    description(): string {
        return "Many empty Coke bottles are here. Alas, they can't hold water.";
    }

    initialDescription(): string {
        return (
            'There is a large pile of empty Coke bottles here, evidently ' +
            'produced by the implementers during their long struggle to win totally.'
        );
    }

    nouns() {
        return CokeBottles.spec().nouns;
    }

    adjectives() {
        return CokeBottles.spec().adjectives;
    }
}

async function breakBottles(
    runner: Runner,
    bottles: CokeBottles,
    other?: Entity
) {
    await runner.doOutput(
        "Congratulations! You've managed to break all those bottles. " +
            'Fortunately for your feet, they were made of magic glass and disappear immediately.'
    );
    bottles.moveTo(undefined);
    if (other) {
        other.moveTo(undefined);
        await runner.doOutput(
            `Somehow, ${other.the()} managed to disappear as well.`
        );
    }
}

const mungBottles: Handler = async ({ action, runner }) => {
    if ((action.is(Throw) || action.is(Mung)) && action.item.is(CokeBottles)) {
        await breakBottles(runner, action.item);
        return Action.complete();
    }
    if (action.is(Throw) && action.enemy?.is(CokeBottles)) {
        await breakBottles(runner, action.enemy, action.item);
        return Action.complete();
    }
    if (action.is(Mung) && action.weapon?.is(CokeBottles)) {
        await breakBottles(runner, action.weapon, action.item);
        return Action.complete();
    }
};
