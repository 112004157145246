import { Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeContainer, makeOpenable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { SpecialListContents, SpecialTimerTick, Take } from '../../abilities';

interface TrophyCaseState extends ItemState {
    scoreOfContents: number;
}

abstract class Base extends Item<TrophyCaseState> {}

export class TrophyCase extends makeOpenable(makeContainer(Base)) {
    static spec(): EntitySpec<TrophyCase> {
        return {
            ref: 'trophy-case',
            constructor: TrophyCase,
            initial: {
                contents: [],
                isOpen: false,
                scoreOfContents: 0,
            },
            nouns: [
                new Noun('trophy case'),
                new Noun('trophy cases', { plural: true }),
                new Noun('case'),
                new Noun('cases', { plural: true }),
            ],
            adjectives: [],
            handlers: [
                handleTakeTrophyCase,
                listTrophyContents,
                handleTrophyCaseScore,
            ],
        };
    }

    ref() {
        return TrophyCase.spec().ref;
    }

    name(): string {
        return 'trophy case';
    }

    description(): string {
        return 'There is a trophy case here.';
    }

    totalCapacity(): undefined {
        return undefined;
    }

    canBeOpened(): boolean {
        return true;
    }

    isTransparent(): boolean {
        return true;
    }

    nouns() {
        return TrophyCase.spec().nouns;
    }

    adjectives() {
        return TrophyCase.spec().adjectives;
    }
}

const handleTakeTrophyCase: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(TrophyCase)) {
        await runner.doOutput(
            'The trophy case is securely fastened to the wall (perhaps to ' +
                'foil any attempt by robbers to remove it).'
        );
        return Action.complete();
    }
};

const handleTrophyCaseScore: Handler = async ({ action, game }) => {
    if (action.is(SpecialTimerTick)) {
        const trophyCase = game.ent(TrophyCase);
        const newScore = trophyCase
            .contents()
            .reduce(
                (score, item) =>
                    score +
                    (item.isItem() && item.isTreasure()
                        ? item.scoreInCase()
                        : 0),
                0
            );
        game.state.score += newScore - trophyCase.state.scoreOfContents;
        trophyCase.state.scoreOfContents = newScore;
        return Action.incomplete();
    }
};

// TODO change this to use extensions.replaceAction
const listTrophyContents: Handler = async ({ action, runner, game }) => {
    if (
        action.is(SpecialListContents) &&
        action.item.is(TrophyCase) &&
        !action.leadIn
    ) {
        return game.applyAction(
            runner,
            new SpecialListContents({
                item: action.item,
                leadIn: 'Your collection of treasures consists of:',
            })
        );
    }
};

// TODO handle "unfasten trophy case"
