import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Find } from './Find';
import { UnresolvedAction } from '../UnresolvedAction';

export class FindUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'find';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'find something';
        }
        return `find ${targetDescription(game, this.item)}`;
    }
}

export const findUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(FindUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new FindUnresolved({ item }),
        absent: (item) => `I couldn't find any ${item}.`,
        missing: () => 'Find what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to find, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    return await game.applyAction(runner, new Find({ item }), actor);
};
