import { Reference } from '../game';
import { Direction } from '../../parse';
import { Item, Vehicle } from '../items/Item';
import { SpecialDirection } from '../../parse/lexicon/Direction';

export class Passage {
    to: Reference | undefined;

    directions: (Direction | SpecialDirection)[];

    message: string | undefined;

    condition: (() => boolean) | undefined;

    allowedVehicles: (Item & Vehicle)[];

    constructor({
        via,
        to,
        message,
        condition,
        allowedVehicles,
    }: {
        via: (Direction | SpecialDirection) | (Direction | SpecialDirection)[];
        to?: Reference;
        message?: string;
        condition?: () => boolean;
        allowedVehicles?: (Item & Vehicle)[];
    }) {
        this.directions = via instanceof Array ? via : [via];
        this.to = to;
        this.message = message;
        this.condition = condition;
        this.allowedVehicles = allowedVehicles || [];
    }

    canGo(): boolean {
        return this.condition ? this.condition() : this.to !== undefined;
    }

    allowsVehicle(vehicle: (Item & Vehicle) | undefined): boolean {
        return (
            !vehicle ||
            this.allowedVehicles.some((allowed) => allowed.isEqualTo(vehicle))
        );
    }
}
