import { Actor, ActorState, Player } from '../index';
import { Adjective, Noun } from '../../../parse';
import { Action, Handler } from '../../game';
import {
    Give,
    Kick,
    Kill,
    Light,
    Mung,
    Poke,
    SpecialJigsUp,
    SpecialTimerTick,
    Take,
    Wake,
} from '../../abilities';
import { CyclopsRoom } from '../../rooms';
import { Bottle, Garlic, Lunch, QuantityOfWater } from '../../items';
import { FightRemarks, FightResult } from '../../handlers';

export enum CyclopsMood {
    Hungry = 'hungry',
    Asleep = 'asleep',
    Enflamed = 'enflamed',
    Suspicious = 'suspicious',
}

const CYCLOPS_MAD = [
    'The cyclops seems somewhat agitated.',
    'The cyclops appears to be getting more agitated.',
    'The cyclops is moving about the room, looking for something.',
    'The cyclops was looking for salt and pepper. I think he is gathering condiments for his upcoming snack.',
    'The cyclops is moving toward you in an unfriendly manner.',
    'You have two choices: 1. Leave 2. Become dinner.',
];

interface CyclopsState extends ActorState {
    wrathLevel: number;
    mood: CyclopsMood;
    hasFled: boolean;
    wrathDelay: number;
}

export class Cyclops extends Actor<CyclopsState> {
    static spec() {
        return {
            ref: 'cyclops',
            constructor: Cyclops,
            initial: {
                inventory: [],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: 10000,
                wrathLevel: -1,
                wrathDelay: 0,
                mood: CyclopsMood.Hungry,
                hasFled: false,
                isEngrossed: false,
            },
            nouns: [
                new Noun('one-eye'),
                new Noun('cyclops'),
                new Noun('cyclopes', { plural: true }),
                new Noun('monster'),
                new Noun('monsters', { plural: true }),
            ],
            adjectives: [
                new Adjective('one-eyed'),
                new Adjective('one eyed'),
                new Adjective('big'),
                new Adjective('ugly'),
            ],
            handlers: [
                cyclopsAnger,
                giveToCyclops,
                wakeUpCyclops,
                attackCyclops,
                pokeCyclops,
                takeCyclops,
            ],
        };
    }

    wrathLevel(): number {
        return this.state.wrathLevel;
    }

    wrathDelay(): number {
        return this.state.wrathDelay;
    }

    ref() {
        return Cyclops.spec().ref;
    }

    name(): string {
        return 'cyclops';
    }

    description(): string {
        switch (this.state.mood) {
            case CyclopsMood.Hungry:
                return (
                    'A cyclops, who looks prepared to eat horses (much less mere adventurers), ' +
                    'blocks the staircase. From his state of health and the bloodstains on ' +
                    'the walls, you gather that he is not very friendly, ' +
                    'though he likes people.'
                );
            case CyclopsMood.Asleep:
                return 'The cyclops is sleeping blissfully at the foot of the stairs.';
            case CyclopsMood.Enflamed:
                return (
                    'The cyclops, having eaten the hot peppers, appears to be gasping. ' +
                    'His enflamed tongue protrudes from his man-sized mouth.'
                );
            case CyclopsMood.Suspicious:
                return (
                    'The cyclops is standing in the corner, eyeing you closely. ' +
                    "I don't think he likes you very much. " +
                    'He looks extremely hungry even for a cyclops.'
                );
        }
    }

    isVillain(): boolean {
        return true;
    }

    isVictim(): boolean {
        return true;
    }

    nouns(): Noun[] {
        return Cyclops.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Cyclops.spec().adjectives;
    }

    isReadyToFight(): boolean {
        return true;
    }

    isAsleep() {
        return this.state.mood === CyclopsMood.Asleep;
    }

    isHungry() {
        return this.state.mood === CyclopsMood.Hungry;
    }

    isEnflamed() {
        return this.state.mood === CyclopsMood.Enflamed;
    }

    isSuspicious() {
        return this.state.mood === CyclopsMood.Suspicious;
    }

    mood() {
        return this.state.mood;
    }

    setMood(mood: CyclopsMood) {
        this.state.mood = mood;
    }

    hasFled() {
        return this.state.hasFled;
    }

    setWrathDelay(wrathDelay: number) {
        this.state.wrathDelay = wrathDelay;
    }

    setWrathLevel(wrathLevel: number) {
        this.state.wrathLevel = wrathLevel;
    }

    fightRemarks(): FightRemarks {
        return CYCLOPS_FIGHT_REMARKS;
    }

    isConscious(): boolean {
        return !this.isAsleep();
    }
}

const cyclopsAnger: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const player = game.ent(Player);
        const cyclops = game.ent(Cyclops);
        const room = game.locateEntity(player);
        if (cyclops.hasFled() || cyclops.isAsleep() || cyclops.isFighting())
            return;
        if (!room?.is(CyclopsRoom)) {
            cyclops.state.wrathLevel = -1;
        } else if (cyclops.wrathDelay() > 0) {
            cyclops.state.wrathDelay -= 1;
        } else if (cyclops.isEnflamed()) {
            cyclops.setMood(CyclopsMood.Suspicious);
        } else if (cyclops.wrathLevel() >= CYCLOPS_MAD.length) {
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message:
                        'The cyclops, tired of all of your games and trickery, eats you. ' +
                        'The cyclops says "Mmm. Just like mom used to make \'em."',
                })
            );
        } else {
            if (cyclops.wrathLevel() >= 0) {
                await runner.doOutput(CYCLOPS_MAD[cyclops.wrathLevel()]);
            }
            cyclops.state.wrathLevel += 1;
        }
        return Action.incomplete();
    }
};

const giveToCyclops: Handler = async ({ action, runner, game, actor }) => {
    if (
        action.is(Give) &&
        action.recipient.is(Cyclops) &&
        actor?.is(Player) &&
        actor?.hasItem(action.item)
    ) {
        const { item: gift, recipient: cyclops } = action;
        if (cyclops.isAsleep()) {
            await runner.doOutput(
                "The cyclops is asleep and probably doesn't want to be bothered."
            );
        } else if (gift.is(Lunch)) {
            gift.moveTo(undefined);
            cyclops.setMood(CyclopsMood.Enflamed);
            cyclops.setWrathDelay(3);
            await runner.doOutput(
                'The cyclops says "Mmm Mmm. I love hot peppers! ' +
                    'But oh, could I use a drink. Perhaps I could drink ' +
                    'the blood of that thing". From the gleam in his eye, ' +
                    'it could be surmised that you are "that thing".'
            );
        } else if (gift.is(Garlic)) {
            await runner.doOutput(
                'The cyclops may be hungry, but there is a limit.'
            );
        } else if (gift.isItem() && gift.isFood()) {
            await runner.doOutput(
                `The cyclops doesn't seem to want ${gift.the()}.`
            );
        } else if (
            (gift.isItem() && gift.is(QuantityOfWater)) ||
            (gift.is(Bottle) && gift.contains(game.ent(QuantityOfWater)))
        ) {
            if (cyclops.isEnflamed()) {
                gift.moveTo(undefined);
                cyclops.state.mood = CyclopsMood.Asleep;
                cyclops.state.isFighting = false;
                await runner.doOutput(
                    'The cyclops looks tired and quickly falls fast asleep (what did you put in that drink, anyway?).'
                );
            } else {
                await runner.doOutput(
                    'The cyclops apparently is not thirsty and refuses your generosity.'
                );
            }
        } else {
            await runner.doOutput(
                'The cyclops is not so stupid as to eat THAT!'
            );
        }

        return Action.complete();
    }
};

const wakeUpCyclops: Handler = async ({ action, runner, game }) => {
    if (
        ((action.is(Wake) ||
            action.is(Kick) ||
            action.is(Mung) ||
            (action.is(Light) &&
                action.tool?.isItem() &&
                action.tool.isFlammable() &&
                action.tool.isAflame())) &&
            action.item.is(Cyclops)) ||
        (action.is(Kill) && action.weapon && action.enemy?.is(Cyclops))
    ) {
        const cyclops = game.ent(Cyclops);
        if (cyclops.isAsleep()) {
            await runner.doOutput(
                'The cyclops yawns and stares at the thing that woke him up.'
            );
            cyclops.setIsFighting(true);
            cyclops.setMood(CyclopsMood.Suspicious);
            return Action.complete();
        }
    }
};

const attackCyclops: Handler = async ({ action, runner, game }) => {
    if (
        ((action.is(Kill) && action.weapon && action.enemy.is(Cyclops)) ||
            (action.is(Mung) && action.item.is(Cyclops))) &&
        !game.ent(Cyclops).isAsleep()
    ) {
        await runner.doOutput(
            'The cyclops ignores all injury to his body with a shrug.'
        );
        game.ent(Cyclops).setIsFighting(true);
        return Action.complete();
    }
};

const pokeCyclops: Handler = async ({ action, runner }) => {
    if (
        action.is(Poke) &&
        action.enemy.is(Cyclops) &&
        !action.enemy.isAsleep()
    ) {
        await runner.doOutput(
            '"Do you think I\'m as stupid as my father was?", he says, dodging.'
        );
        action.enemy.setIsFighting(true);
        return Action.complete();
    }
};

const takeCyclops: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Cyclops)) {
        await runner.doOutput(
            "The cyclops doesn't take kindly to being grabbed."
        );
        return Action.complete();
    }
};

// TODO Not sure where this comes from?
// const tieCyclops: Handler = async ({ action, runner }) => {
//     if (action.is(Tie) && action.item.is(Cyclops)) {
//         await runner.doOutput(
//           "You cannot tie the cyclops, though he is fit to be tied."
//         );
//         return Action.complete();
//     }
// };

const CYCLOPS_FIGHT_REMARKS: FightRemarks = ({ defendingWeapon }) => ({
    [FightResult.Missed]: [
        'The Cyclops misses, but the backwash almost knocks you over.',
        'The Cyclops rushes you, but runs into the wall.',
        'The Cyclops trips over his feet trying to get at you.',
        'The Cyclops unleashes a roundhouse punch, but you have time to dodge.',
    ],
    [FightResult.Unconscious]: [
        'The Cyclops knocks you unconscious.',
        'The Cyclops sends you crashing to the floor, unconscious.',
    ],
    [FightResult.Killed]: [
        'The Cyclops raises his arms and crushes your skull.',
        'The Cyclops has just essentially ripped you to shreds.',
        'The Cyclops decks you. In fact, you are dead.',
        'The Cyclops breaks your neck with a massive smash.',
    ],
    [FightResult.LightWound]: [
        'A quick punch, but it was only a glancing blow.',
        'The Cyclops grabs but you twist free, leaving part of your cloak.',
        "A glancing blow from the Cyclops' fist.",
        'The Cyclops chops at you with the side of his hand, and it connects, but not solidly.',
    ],
    [FightResult.SeriousWound]: [
        'The Cyclops gets a good grip and breaks your arm.',
        'The monster smashes his huge fist into your chest, breaking several ribs.',
        'The Cyclops almost knocks the wind out of you with a quick punch.',
        'A flying drop kick breaks your jaw.',
        'The Cyclops breaks your leg with a staggering blow.',
    ],
    [FightResult.Stun]: [
        'The Cyclops knocks you silly, and you reel back.',
        'The Cyclops lands a punch that knocks the wind out of you.',
        'Heedless of your weapons, the Cyclops tosses you against the rock wall of the room.',
        'The Cyclops grabs you, and almost strangles you before you wiggle free, breathless.',
    ],
    [FightResult.LostWeapon]: [
        `The Cyclops grabs you by the arm, and you drop your ${defendingWeapon}.`,
        `The Cyclops kicks your ${defendingWeapon} out of your hand.`,
        `The Cyclops grabs your ${defendingWeapon}, tastes it, and throws it to the ground in disgust.`,
        `The monster grabs you on the wrist, squeezes, and you drop your ${defendingWeapon} in pain.`,
    ],
    [FightResult.Hesitated]: [
        'The Cyclops is so excited by his success that he neglects to kill you.',
        'The Cyclops, momentarily overcome by remorse, holds back.',
        'The Cyclops seems unable to decide whether to broil or stew his dinner.',
        'The troll seems afraid to approach your crumpled form.',
    ],
    [FightResult.SittingDuck]: [
        'The Cyclops, no sportsman, dispatches his unconscious victim.',
    ],
});
