export class Token<V> {
    value: V;

    source: Token<unknown>[];

    constructor(value: V, source: Token<unknown>[]) {
        this.value = value;
        this.source = source;
    }

    clone(): Token<V> {
        return this.withValue(this.value);
    }

    withValue<X>(value: X): Token<X> {
        return new Token<X>(value, [...this.source]);
        // const Constructor = this.constructor as TokenConstructor<X>;
        // return new Constructor<X>(
        //     value,
        //     [...this.source]
        // );
    }
}
