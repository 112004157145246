import { Handler, Entity, Action } from '../../game';
import { SpecialAction } from '../SpecialAction';
import { Item, Takeable } from '../../items/Item';

export class SpecialListContents extends SpecialAction {
    id = '*list-contents';

    item: Entity;

    indent: number;

    leadIn?: string;

    constructor({
        item,
        indent = 0,
        leadIn,
    }: {
        item: Entity;
        indent?: number;
        leadIn?: string;
    }) {
        super();
        this.item = item;
        this.indent = indent;
        this.leadIn = leadIn;
    }
}

function shouldDescribeViaList(item: Entity): boolean {
    return (
        item.isItem() &&
        !item.isHidden() &&
        item.shouldBeDescribed() &&
        (!item.isTakeable() ||
            item.hasBeenTaken() ||
            !item.initialDescription())
    );
}

export const specialListContentsHandler: Handler = async ({
    action,
    runner,
}) => {
    if (!action.is(SpecialListContents)) return;
    const container = action.item;
    if (
        !(
            (container.isItem() && container.isContainer()) ||
            container.isActor() ||
            container.isRoom()
        )
    ) {
        return Action.complete({ withConsequence: false });
    }

    const contents = container.contents();

    const itemsToList = contents.filter(shouldDescribeViaList) as Item[];

    const itemsToInitiallyDescribe = contents.filter(
        (item) =>
            item.isItem() &&
            !item.isHidden() &&
            item.shouldBeDescribed() &&
            !shouldDescribeViaList(item)
    ) as (Item & Takeable)[];

    if (itemsToList.length > 0) {
        const indent = '  '.repeat(action.indent);
        if (action.leadIn === undefined) {
            await runner.doOutput(`${indent}The ${action.item} contains:`);
        } else {
            await runner.doOutput(`${indent}${action.leadIn}`);
        }
        for (const item of itemsToList) {
            const innerContents =
                item.isContainer() && item.canSeeInto() && !item.isEmpty()
                    ? ` with ${item.contentsString()}`
                    : '';
            await runner.doOutput(`${indent}  ${item.An()}${innerContents}`);
        }
    }

    for (const item of itemsToInitiallyDescribe) {
        await runner.doOutput(item.initialDescription() || item.description());
    }

    return Action.complete({ withConsequence: false });
};
