import { MirrorRoom2, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Cave2 } from '../Cave2';
import { GrailRoom } from '../GrailRoom';

export class NarrowCrawlway extends Room {
    static spec(): EntitySpec<NarrowCrawlway> {
        return {
            ref: 'narrow-crawlway',
            constructor: NarrowCrawlway,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return NarrowCrawlway.spec().ref;
    }

    name(): string {
        return 'Narrow Crawlway';
    }

    description(): string {
        return (
            'This is a narrow crawlway. The crawlway leads from north to south. ' +
            'However the south passage divides to the south and southwest.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: GrailRoom.spec().ref }),
            new Passage({ via: Direction.South, to: Cave2.spec().ref }),
            new Passage({
                via: Direction.Southwest,
                to: MirrorRoom2.spec().ref,
            }),
        ];
    }
}
