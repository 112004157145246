import { MirrorRoom1, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { SlideRoom } from '../SlideRoom';
import { SteepCrawlway } from '../SteepCrawlway';

export class ColdPassage extends Room {
    static spec(): EntitySpec<ColdPassage> {
        return {
            ref: 'cold-passage',
            constructor: ColdPassage,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return ColdPassage.spec().ref;
    }

    name(): string {
        return 'Cold Passage';
    }

    description(): string {
        return 'This is a cold and damp corridor where a long east-west passageway intersects with a northward path.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: MirrorRoom1.spec().ref }),
            new Passage({
                via: Direction.West,
                to: SlideRoom.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                to: SteepCrawlway.spec().ref,
            }),
        ];
    }
}
