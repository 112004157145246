import { EndOfTheRainbow, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { RockyLedge } from '../RockyLedge';

export class CanyonBottom extends Room {
    static spec(): EntitySpec<CanyonBottom> {
        return {
            ref: 'canyon-bottom',
            constructor: CanyonBottom,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return CanyonBottom.spec().ref;
    }

    name(): string {
        return 'Canyon Bottom';
    }

    description(): string {
        return (
            'You are beneath the walls of the river canyon which may be ' +
            'climbable here. There is a small stream here, which is the ' +
            'lesser part of the runoff of Aragain Falls. To the north is a narrow path.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: EndOfTheRainbow.spec().ref,
            }),
            new Passage({
                via: Direction.Up,
                to: RockyLedge.spec().ref,
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
