import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { PushUnresolved, pushUnresolvedHandler } from './PushUnresolved';
import { HO_HUM } from '../../constants';

export class Push extends Action {
    id = '~push';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [pushHandler, pushUnresolvedHandler],
            parser,
            verbs: [
                new Verb('push'),
                new Verb('push on'),
                new Verb('press'),
                new Verb('press on'),
                new Verb('depress'),
            ],
            prepositions: [],
        };
    }
}

export const pushHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Push)) return;
    const { item } = action;
    await runner.doOutput(`Pushing ${item.the()} ${game.choiceOf(HO_HUM)}`);
    return Action.complete();
};

const parser = (game: Game): Parser<Value, PushUnresolved> => {
    const push = Parse.words(Push.ability().verbs);
    const pushObject = push.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // push
        push.into(new PushUnresolved({ item: undefined })),
        // push button
        pushObject.map((item) => new PushUnresolved({ item }))
    );
};
