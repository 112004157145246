import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeOpenable } from '../../game/Entity';
import { SpecialTimerTick } from '../../abilities';

interface PinePanelState extends ItemState {
    timeUntilCloses: number;
}

abstract class Base extends Item<PinePanelState> {}

export class PinePanel extends makeOpenable(Base) {
    static spec(): EntitySpec<PinePanel> {
        return {
            ref: 'pine-panel',
            constructor: PinePanel,
            initial: {
                timeUntilCloses: 0,
                isOpen: false,
            },
            nouns: [
                new Noun('panel'),
                new Noun('panels', { plural: true }),
                new Noun('wall'),
                new Noun('walls', { plural: true }),
            ],
            adjectives: [
                new Adjective('pine'),
                new Adjective('wood'),
                new Adjective('wooden'),
            ],
            handlers: [closePinePanel],
        };
    }

    ref() {
        return PinePanel.spec().ref;
    }

    name(): string {
        return 'pine panel';
    }

    description(): string {
        return '';
    }

    nouns() {
        return PinePanel.spec().nouns;
    }

    adjectives() {
        return PinePanel.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    timeUntilCloses() {
        return this.state.timeUntilCloses;
    }

    setTimeUntilCloses(timeUntilCloses: number) {
        this.state.timeUntilCloses = timeUntilCloses;
    }
}

const closePinePanel: Handler = async ({ action, game, runner }) => {
    if (action.is(SpecialTimerTick) && game.isInEndgame()) {
        const pinePanel = game.ent(PinePanel);
        if (pinePanel.isOpen()) {
            pinePanel.setTimeUntilCloses(pinePanel.timeUntilCloses() - 1);
            if (pinePanel.timeUntilCloses() <= 0) {
                pinePanel.setIsOpen(false);
                await runner.doOutput('The pine wall closes quietly.');
            }
        }
        return Action.incomplete();
    }
};
