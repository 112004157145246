import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push } from '../../abilities';
import { MaintenanceRoom } from '../../rooms';

export class RedButton extends Item {
    static spec(): EntitySpec<RedButton> {
        return {
            ref: 'red-button',
            constructor: RedButton,
            initial: {},
            nouns: [
                new Noun('button'),
                new Noun('buttons', { plural: true }),
                new Noun('switch'),
                new Noun('switches', { plural: true }),
            ],
            adjectives: [new Adjective('red')],
            handlers: [pushRedButton],
        };
    }

    ref() {
        return RedButton.spec().ref;
    }

    name(): string {
        return 'red button';
    }

    description(): string {
        return '';
    }

    nouns() {
        return RedButton.spec().nouns;
    }

    adjectives() {
        return RedButton.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushRedButton: Handler = async ({ action, runner, game }) => {
    if (action.is(Push) && action.item.is(RedButton)) {
        const room = game.ent(MaintenanceRoom);
        room.state.areLightsPowered = !room.state.areLightsPowered;

        const state = room.state.areLightsPowered ? 'come on' : 'shut off';

        await runner.doOutput(`The lights within the room ${state}.`);

        return Action.complete();
    }
};
