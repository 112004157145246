import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Shovel } from '../Shovel';
import { Dig, SpecialJigsUp } from '../../abilities';
import { Statue } from '../Statue';

interface SandState extends ItemState {
    holeSize: number;
}

export class Sand extends Item<SandState> {
    static spec(): EntitySpec<Sand> {
        return {
            ref: 'sand',
            constructor: Sand,
            initial: {
                holeSize: 0,
            },
            nouns: [new Noun('sand', { collective: true }), new Noun('beach')],
            adjectives: [new Adjective('sandy')],
            handlers: [digInSand],
        };
    }

    ref() {
        return Sand.spec().ref;
    }

    name() {
        return 'sandy beach';
    }

    description() {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns(): Noun[] {
        return Sand.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Sand.spec().adjectives;
    }

    holeSize(): number {
        return this.state.holeSize;
    }

    setHoleSize(holeSize: number) {
        this.state.holeSize = holeSize;
    }
}

const SAND_HOLE_PROGRESS = [
    'You seem to be digging a hole here.',
    "The hole is getting deeper, but that's about it.",
    'You are surrounded by a wall of sand on all sides.',
];

const digInSand: Handler = async ({ action, runner, game }) => {
    if (action.is(Dig) && action.item?.is(Sand) && action.tool?.is(Shovel)) {
        const { item: sand } = action;
        const statue = game.ent(Statue);
        sand.setHoleSize(sand.holeSize() + 1);
        if (sand.holeSize() > 4) {
            sand.setHoleSize(0);
            if (!statue.hasBeenTaken()) {
                statue.setIsHidden(true);
            }
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message: 'The hole collapses, smothering you.',
                })
            );
        } else if (sand.holeSize() === 4) {
            if (statue.isHidden()) {
                statue.setIsHidden(false);
                await runner.doOutput(
                    'You can see a small statue here in the sand.'
                );
            }
        } else {
            await runner.doOutput(SAND_HOLE_PROGRESS[sand.holeSize() - 1]);
        }
        return Action.complete();
    }
};
