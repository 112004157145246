import { Noun, Adjective } from '../../../parse';
import { makeReadable, makeTakeable } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler, Reference } from '../../game';
import { Move, SpecialDescribe, Take } from '../../abilities';
import { Player } from '../../actors';
import { OakDoor } from '../OakDoor';

interface WelcomeMatState extends ItemState {
    itemOnTop: Reference | undefined;
}

abstract class Base extends Item<WelcomeMatState> {}

export class WelcomeMat extends makeTakeable(makeReadable(Base)) {
    static spec(): EntitySpec<WelcomeMat> {
        return {
            ref: 'welcome-mat',
            constructor: WelcomeMat,
            initial: {
                hasBeenTaken: false,
                itemOnTop: undefined,
            },
            nouns: [
                new Noun('welcome mat'),
                new Noun('mat'),
                new Noun('welcome mats', { plural: true }),
                new Noun('mats', { plural: true }),
            ],
            adjectives: [new Adjective('rubber')],
            handlers: [moveWelcomeMat, takeItemOnTop, describeItemOnTop],
        };
    }

    ref() {
        return WelcomeMat.spec().ref;
    }

    name() {
        return 'welcome mat';
    }

    description() {
        if (this.game.ent(OakDoor).itemUnderneath()) {
            return 'The edge of a welcome mat is visible under the door.';
        }
        return 'There is a welcome mat here.';
    }

    initialDescription() {
        return "A rubber mat saying 'Welcome to Zork!' lies by the door.";
    }

    size() {
        return 12;
    }

    text() {
        return 'Welcome to Zork!';
    }

    nouns(): Noun[] {
        return WelcomeMat.spec().nouns;
    }

    adjectives(): Adjective[] {
        return WelcomeMat.spec().adjectives;
    }

    shouldTakeToRead() {
        return false;
    }

    itemOnTop(): Item | undefined {
        return this.state.itemOnTop
            ? (this.game.get(this.state.itemOnTop) as Item)
            : undefined;
    }

    setItemOnTop(item: Item | undefined) {
        this.state.itemOnTop = item && item.ref();
    }
}

const moveWelcomeMat: Handler = async ({
    action,
    runner,
    actor,
    extensions,
    game,
}) => {
    // TODO PULL
    if (
        (action.is(Take) || action.is(Move)) /* || action.is(Pull) */ &&
        action.item.is(WelcomeMat) &&
        actor?.is(Player)
    ) {
        const { item: welcomeMat } = action;
        const itemOnTop = welcomeMat.itemOnTop();
        const oakDoor = game.ent(OakDoor);
        oakDoor.state.itemUnderneath = undefined;
        if (itemOnTop) {
            const room = actor.location();
            welcomeMat.moveTo(room);
            itemOnTop.moveTo(room);
            welcomeMat.setItemOnTop(undefined);
            if (action.is(Take)) {
                await extensions.deferHandling();
            }
            await runner.doOutput(
                `As the mat is moved, ${itemOnTop.an()} falls from it and onto the floor.`
            );
            return Action.complete();
        }
    }
};

export const takeItemOnTop: Handler = async ({ action, game }) => {
    let itemOnTop;
    let welcomeMat;
    if (
        action.is(Take) &&
        (welcomeMat = game.ent(WelcomeMat)) &&
        (itemOnTop = welcomeMat.itemOnTop()) &&
        action.item.isEqualTo(itemOnTop)
    ) {
        welcomeMat.setItemOnTop(undefined);
        return Action.incomplete();
    }
};

export const describeItemOnTop: Handler = async ({ action, runner, game }) => {
    let itemOnTop;
    if (
        action.is(SpecialDescribe) &&
        (itemOnTop = game.ent(WelcomeMat).itemOnTop()) &&
        action.item.isEqualTo(itemOnTop)
    ) {
        await runner.doOutput(`Lying on the mat is ${itemOnTop.an()}.`);
        return Action.complete();
    }
};
