import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, Entity, EntitySpec, Handler } from '../../game';
import { SmellyRoom } from '../SmellyRoom';
import { Light, SpecialEnter, SpecialJigsUp } from '../../abilities';
import { SapphireBracelet } from '../../items';

export class GasRoom extends Room {
    static spec(): EntitySpec<GasRoom> {
        return {
            ref: 'gas-room',
            constructor: GasRoom,
            initial: {
                contents: [SapphireBracelet.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [enterGasRoom, lightInGasRoom],
        };
    }

    ref() {
        return GasRoom.spec().ref;
    }

    name(): string {
        return 'Gas Room';
    }

    description(): string {
        return 'This is a small room which smells strongly of coal gas.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Up,
                to: SmellyRoom.spec().ref,
            }),
        ];
    }
}

function isFlamingItem(item: Entity) {
    return item.isItem() && item.isFlammable() && item.isAflame();
}

const enterGasRoom: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(SpecialEnter) && action.room.is(GasRoom)) {
        const flamingItem = actor?.inventory().find(isFlamingItem);
        if (flamingItem) {
            await runner.doOutput(
                'Oh dear. It appears that the smell coming from ' +
                    'this room was coal gas. I would have thought twice ' +
                    `about carrying ${flamingItem.an()} in here.`
            );
            await game.applyAction(
                runner,
                new SpecialJigsUp({ message: '  BOOOOOOOOOOOM' })
            );
            return Action.complete();
        }
    }
};

const lightInGasRoom: Handler = async ({
    action,
    runner,
    game,
    actor,
    extensions,
}) => {
    if (action.is(Light) && actor?.location()?.is(GasRoom)) {
        await extensions.deferHandling();
        if (isFlamingItem(action.item)) {
            await runner.doOutput(
                "I didn't realize that adventurers are stupid enough to light " +
                    `${action.item} in a room which reeks of coal gas. ` +
                    'Fortunately, there is justice in the world.'
            );
            await game.applyAction(
                runner,
                new SpecialJigsUp({ message: '  BOOOOOOOOOOOM' })
            );
            return Action.complete();
        }
    }
};
