import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeContainer, makeVehicle } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Kick, Light, SpecialJigsUp } from '../../abilities';

interface BucketState extends ItemState {
    timeUntilWaterEvaporates: number;
}

abstract class Base extends Item<BucketState> {}

export class Bucket extends makeVehicle(makeContainer(Base)) {
    static spec(): EntitySpec<Bucket> {
        return {
            ref: 'bucket',
            constructor: Bucket,
            initial: {
                timeUntilWaterEvaporates: 0,
                contents: [],
            },
            nouns: [new Noun('bucket'), new Noun('buckets', { plural: true })],
            adjectives: [
                new Adjective('large'),
                new Adjective('huge'),
                new Adjective('wood'),
                new Adjective('wooden'),
            ],
            handlers: [kickBucket, burnBucket],
        };
    }

    ref() {
        return Bucket.spec().ref;
    }

    name(): string {
        return 'wooden bucket';
    }

    description(): string {
        return 'There is a wooden bucket here, 3 feet in diameter and 3 feet high.';
    }

    totalCapacity(): number {
        return 100;
    }

    size(): number {
        return 100;
    }

    nouns() {
        return Bucket.spec().nouns;
    }

    adjectives() {
        return Bucket.spec().adjectives;
    }

    timeUntilWaterEvaporates() {
        return this.state.timeUntilWaterEvaporates;
    }

    setTimeUntilWaterEvaporates(timeUntilWaterEvaporates: number) {
        this.state.timeUntilWaterEvaporates = timeUntilWaterEvaporates;
    }

    decrementTimeUntilWaterEvaporates() {
        this.state.timeUntilWaterEvaporates -= 1;
    }
}

const kickBucket: Handler = async ({ action, runner, game }) => {
    if (action.is(Kick) && action.item.is(Bucket)) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({ message: 'If you insist.' })
        );
        return Action.complete();
    }
};

const burnBucket: Handler = async ({ action, runner }) => {
    if (
        action.is(Light) &&
        action.item.is(Bucket) &&
        action.tool?.isItem() &&
        action.tool.isFlammable() &&
        action.tool.isAflame()
    ) {
        await runner.doOutput("The bucket is fireproof, and won't burn.");
        return Action.complete();
    }
};
