import { Parse, Verb } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { SpecialAction } from '../SpecialAction';

export class SpecialAgain extends makeDescribable(SpecialAction) {
    id = '*again';

    description(game: Game): string {
        return 'repeat your last action';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('again'),
            new Verb('do that again'),
            new Verb('do it again'),
            new Verb('repeat'),
        ];
        return {
            handlers: [specialAgainHandler],
            parser: () => Parse.words(verbs).into(new SpecialAgain()),
            verbs,
            prepositions: [],
        };
    }
}

export const specialAgainHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SpecialAgain)) return;

    if (game.previous) {
        return game.applyAction(runner, game.previous, actor);
    }
    await runner.doOutput("You haven't done anything yet, cretin.");
    return Action.complete({ withConsequence: false });
};
