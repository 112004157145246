import { Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { makeReadable } from '../../game/Entity';

export class WellTopEtchings extends makeReadable(Item) {
    static spec(): EntitySpec<WellTopEtchings> {
        return {
            ref: 'well-top-etchings',
            constructor: WellTopEtchings,
            initial: {},
            nouns: [
                new Noun('etching'),
                new Noun('etchings', { collective: true }),
                new Noun('wall'),
                new Noun('walls', { plural: true }),
            ],
            adjectives: [],
        };
    }

    ref() {
        return WellTopEtchings.spec().ref;
    }

    name(): string {
        return 'wall with etchings';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    text(): string {
        return WELL_TOP_ETCHINGS_TEXT;
    }

    nouns() {
        return WellTopEtchings.spec().nouns;
    }

    adjectives() {
        return WellTopEtchings.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const WELL_TOP_ETCHINGS_TEXT = `
           o   b   o
        r             z
     f   M  A  G  I  C   z
     c    W  E   L  L    y
        o             n
           m   p   a
`;
