import { AncientChasm, Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Bug, Echo, Feature, Go } from '../../abilities';
import { Action, EntitySpec, Handler } from '../../game';
import { PlatinumBar } from '../../items';
import { DampCave } from '../DampCave';
import { NorthSouthPassage } from '../NorthSouthPassage';
import { RoomState } from '../Room';

interface LoudRoomState extends RoomState {
    isEchoey: boolean;
}

export class LoudRoom extends Room<LoudRoomState> {
    static spec(): EntitySpec<LoudRoom> {
        return {
            ref: 'loud-room',
            constructor: LoudRoom,
            initial: {
                contents: [PlatinumBar.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
                isEchoey: true,
            },
            nouns: [],
            adjectives: [],
            handlers: [handleBugFeature, handleEcho, isVeryLoud],
        };
    }

    ref() {
        return LoudRoom.spec().ref;
    }

    name(): string {
        return 'Loud Room';
    }

    description(): string {
        return (
            'This is a large room with a ceiling which cannot be detected from the ' +
            'ground. There is a narrow passage from east to west and a stone stairway ' +
            'leading upward. The room is extremely noisy. In fact, it is ' +
            'difficult to hear yourself think.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: AncientChasm.spec().ref }),
            new Passage({
                via: Direction.West,
                to: NorthSouthPassage.spec().ref,
            }),
            new Passage({ via: Direction.Up, to: DampCave.spec().ref }),
        ];
    }

    isEchoey(): boolean {
        return this.state.isEchoey;
    }
}

const VALID_DIRECTIONS: (Direction | SpecialDirection)[] = [
    Direction.East,
    Direction.West,
    Direction.South,
];
const isVeryLoud: Handler = async ({ action, runner, game, actor }) => {
    if (
        actor &&
        game.locateEntity(actor)?.is(LoudRoom) &&
        !(action.is(Go) && VALID_DIRECTIONS.includes(action.direction)) &&
        action.command &&
        game.ent(LoudRoom).isEchoey()
    ) {
        // TODO if the set of words contains "echo" also fix the room
        const words = action.command.split(/\s/);
        const lastWord = words[words.length - 1];
        await runner.doOutput(
            `${lastWord.toUpperCase()} ${lastWord.toLowerCase()}`
        );
        return Action.complete();
    }
};

const handleEcho: Handler = async ({ action, runner, game, actor }) => {
    if (
        actor &&
        game.locateEntity(actor)?.is(LoudRoom) &&
        action.is(Echo) &&
        game.ent(LoudRoom).isEchoey()
    ) {
        game.ent(LoudRoom).state.isEchoey = false;
        await runner.doOutput('ECHO');
        await runner.doOutput('The acoustics of the room change subtly.');
        return Action.complete();
    }
};

const handleBugFeature: Handler = async ({ action, runner, game, actor }) => {
    if (
        actor &&
        game.locateEntity(actor)?.is(LoudRoom) &&
        game.ent(LoudRoom).isEchoey()
    ) {
        if (action.is(Bug)) {
            await runner.doOutput('Feature.');
            return Action.complete();
        }
        if (action.is(Feature)) {
            await runner.doOutput("That's right.");
            return Action.complete();
        }
    }
};
