import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    DisembarkUnresolved,
    disembarkUnresolvedHandler,
} from './DisembarkUnresolved';
import { Player } from '../../actors';

export class Disembark extends Action {
    id = '~disembark';

    vehicle: Entity;

    constructor({ vehicle }: { vehicle: Entity }) {
        super();
        this.vehicle = vehicle;
    }

    static ability(): Ability {
        return {
            handlers: [disembarkHandler, disembarkUnresolvedHandler],
            parser,
            verbs: [
                new Verb('disembark'),
                new Verb('disembark from'),
                new Verb('get out'),
                new Verb('get out from'),
                new Verb('get out of'),
                new Verb('get outside'),
                new Verb('exit'),
                new Verb('go out'),
                new Verb('go out of'),
                new Verb('go outside'),
                new Verb('step out'),
                new Verb('step out from'),
                new Verb('step out of'),
                new Verb('step outside'),
            ],
            prepositions: [],
        };
    }
}

export const disembarkHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Disembark)) return;
    const { vehicle } = action;
    const player = game.ent(Player);

    if (
        !vehicle.isItem() ||
        !vehicle.isVehicle() ||
        !vehicle.contains(player)
    ) {
        await runner.doOutput(`You aren't in that!`);
    } else if (!player.location().isOnLand()) {
        await runner.doOutput(
            `You realize, just in time, that disembarking here would probably be fatal.`
        );
    } else {
        player.moveTo(player.location());
        await runner.doOutput(`You are on your own feet again.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, DisembarkUnresolved> => {
    const disembark = Parse.words([
        new Verb('disembark'),
        new Verb('get out'),
        new Verb('step out'),
    ]);
    const disembarkObject = Parse.words([
        new Verb('disembark'),
        new Verb('get out of'),
        new Verb('get outside'),
        new Verb('exit'),
        new Verb('go out of'),
        new Verb('step out of'),
        new Verb('step outside'),
    ]).chain((_verb) => Parse.target(game.lexicon).after(Parse.whitespace()));
    return Parse.any(
        // disembark
        disembark.into(new DisembarkUnresolved({ vehicle: undefined })),
        // disembark bucket
        disembarkObject.map((vehicle) => new DisembarkUnresolved({ vehicle }))
    );
};
