import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Open } from '../../abilities';
import { makeOpenable, makeReadable } from '../../game/Entity';

export class CryptDoor extends makeReadable(makeOpenable(Item)) {
    static spec(): EntitySpec<CryptDoor> {
        return {
            ref: 'crypt-door',
            constructor: CryptDoor,
            initial: {
                isOpen: false,
            },
            nouns: [
                new Noun('door'),
                new Noun('doors', { plural: true }),
                new Noun('crypt'),
                new Noun('crypts', { plural: true }),
                new Noun('tomb'),
                new Noun('tombs', { plural: true }),
                new Noun('grave'),
                new Noun('graves', { plural: true }),
            ],
            adjectives: [new Adjective('crypt'), new Adjective('marble')],
            handlers: [openCryptEndgame, closeCrypt],
        };
    }

    ref() {
        return CryptDoor.spec().ref;
    }

    name(): string {
        return 'crypt door';
    }

    description(): string {
        return '';
    }

    text() {
        return (
            'Here lie the implementers, whose heads were placed on poles ' +
            'by the Keeper of the Dungeon for amazing untastefulness.'
        );
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns() {
        return CryptDoor.spec().nouns;
    }

    adjectives() {
        return CryptDoor.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const openCryptEndgame: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Open) &&
        action.item.is(CryptDoor) &&
        game.isReadyForEndgame()
    ) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput('The crypt is already open.');
        } else {
            door.setIsOpen(true);
            await runner.doOutput(
                'The door of the crypt is extremely heavy, but it opens easily.'
            );
        }
        return Action.complete();
    }
};

const closeCrypt: Handler = async ({ action, runner }) => {
    if (action.is(Close) && action.item.is(CryptDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            door.setIsOpen(false);
            await runner.doOutput('The crypt is closed.');
        } else {
            await runner.doOutput('The crypt is already closed.');
        }
        return Action.complete();
    }
};
