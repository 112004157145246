import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Take } from '../../abilities';
import { FightRemarks, FightResult } from '../../handlers';
import { Thief } from '../../actors';

export class Stiletto extends makeTakeable(Item) {
    static spec(): EntitySpec<Stiletto> {
        return {
            ref: 'stiletto',
            constructor: Stiletto,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('stiletto'),
                new Noun('stilettos', { plural: true }),
                new Noun('knife'),
                new Noun('knives', { plural: true }),
                new Noun('blade'),
                new Noun('blades', { plural: true }),
            ],
            adjectives: [
                new Adjective('vicious'),
                new Adjective('vicious-looking'),
            ],
            handlers: [handleTakeStiletto],
        };
    }

    size() {
        return 10;
    }

    ref() {
        return Stiletto.spec().ref;
    }

    name() {
        return 'stiletto';
    }

    description(): string {
        return 'There is a vicious-looking stiletto here.';
    }

    nouns(): Noun[] {
        return Stiletto.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Stiletto.spec().adjectives;
    }

    isTool(): boolean {
        return true;
    }

    isWeapon(): boolean {
        return true;
    }

    fightRemarks(): FightRemarks {
        return STILETTO_FIGHT_REMARKS;
    }
}

const handleTakeStiletto: Handler = async ({ action, runner, game }) => {
    if (action.is(Take) && action.item.is(Stiletto)) {
        const thief = game.ent(Thief);
        if (thief.isConscious()) {
            await runner.doOutput('The thief scoffs at your foolish attempt.');
        } else {
            thief.setIsConscious(true);
            await runner.doOutput(
                'Your attempt fails, and the thief wakes up.'
            );
        }
        return Action.complete();
    }
};

const STILETTO_FIGHT_REMARKS: FightRemarks = ({ defendingWeapon }) => ({
    [FightResult.Missed]: [
        'The thief stabs nonchalantly with his stiletto and misses.',
        'You dodge as the thief comes in low.',
        'You parry a lightning thrust, and the thief salutes you with a grim nod.',
        'The thief tries to sneak past your guard, but you twist away.',
    ],
    [FightResult.Unconscious]: [
        'Shifting in the midst of a thrust, the thief knocks you unconscious with the haft of his stiletto.',
        'The thief knocks you out.',
    ],
    [FightResult.Killed]: [
        'Finishing you off, a lightning throw right to the heart.',
        'The stiletto severs your jugular. It looks like the end.',
        'The thief comes in from the side, feints, and inserts the blade into your ribs.',
        'The thief bows formally, raises his stiletto, and with a wry grin, ends the battle and your life.',
    ],
    [FightResult.LightWound]: [
        'A quick thrust pinks your left arm, and blood starts to trickle down.',
        'The thief draws blood, raking his stiletto across your arm.',
        'The stiletto flashes faster than you can follow, and blood wells from your leg.',
        'The thief slowly approaches, strikes like a snake, and leaves you wounded.',
    ],
    [FightResult.SeriousWound]: [
        'The thief strikes like a snake! The resulting wound is serious.',
        'The thief stabs a deep cut in your upper arm.',
        'The stiletto touches your forehead, and the blood obscures your vision.',
        'The thief strikes at your wrist, and suddenly your grip is slippery with blood.',
    ],
    [FightResult.Stun]: [
        'The butt of his stiletto cracks you on the skull, and you stagger back.',
        'You are forced back, and trip over your own feet, falling heavily to the floor.',
        'The thief rams the haft of his blade into your stomach, leaving you out of breath.',
        'The thief attacks, and you fall back desperately.',
    ],
    [FightResult.LostWeapon]: [
        `A long, theatrical slash. You catch it on your ${defendingWeapon}, but the thief twists his knife, and the ${defendingWeapon} goes flying.`,
        `The thief neatly flips your ${defendingWeapon} out of your hands, and it drops to the floor.`,
        `You parry a low thrust, and your ${defendingWeapon} slips out of your hand.`,
        `Avoiding the thief's stiletto, you stumble to the floor, dropping your ${defendingWeapon}.`,
    ],
    [FightResult.Hesitated]: [
        'The thief, a man of good breeding, refrains from attacking a helpless opponent.',
        'The thief amuses himself by searching your pockets.',
        'The thief entertains himself by rifling your pack.',
    ],
    [FightResult.SittingDuck]: [
        'The thief, noticing you begin to stir, reluctantly finishes you off.',
        'The thief, forgetting his essentially genteel upbringing, cuts your throat.',
        'The thief, who is essentially a pragmatist, dispatches you as a threat to his livelihood.',
    ],
});
