import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Play } from '../../abilities';

interface ViolinState extends ItemState {
    isMunged: boolean;
}

abstract class Base extends Item<ViolinState> {}

export class Violin extends makeTreasure(makeTakeable(Base)) {
    static spec(): EntitySpec<Violin> {
        return {
            ref: 'violin',
            constructor: Violin,
            initial: {
                hasBeenTaken: false,
                isMunged: false,
            },
            nouns: [
                new Noun('violin'),
                new Noun('violins', { plural: true }),
                new Noun('instrument'),
                new Noun('instruments', { plural: true }),
                new Noun('stradivarius'),
            ],
            adjectives: [new Adjective('fancy')],
            handlers: [playViolin],
        };
    }

    ref() {
        return Violin.spec().ref;
    }

    name() {
        return this.isMunged() ? 'broken violin' : 'fancy violin';
    }

    description() {
        return this.isMunged()
            ? 'There is a horribly-mistreated violin here.'
            : 'There is a Stradivarius here.';
    }

    isTreasure() {
        return !this.isMunged();
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 10;
    }

    nouns(): Noun[] {
        return Violin.spec().nouns;
    }

    adjectives(): Adjective[] {
        // TODO adjectives based on munged
        return Violin.spec().adjectives;
    }

    isMunged() {
        return this.state.isMunged;
    }

    setIsMunged(isMunged: boolean) {
        this.state.isMunged = isMunged;
    }
}

const playViolin: Handler = async ({ action, runner }) => {
    if (action.is(Play) && action.item.is(Violin)) {
        if (action.tool && action.tool.isItem() && action.tool.isWeapon()) {
            action.item.setIsMunged(true);
            await runner.doOutput('Very good. The violin is now worthless.');
        } else {
            await runner.doOutput(
                'An amazingly offensive noise issues from the violin.'
            );
        }
        return Action.complete();
    }
};
