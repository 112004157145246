import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    PutUnderUnresolved,
    putUnderUnresolvedHandler,
} from './PutUnderUnresolved';

export class PutUnder extends Action {
    id = '~put-under';

    item: Entity;

    cover: Entity;

    constructor({ item, cover }: { item: Entity; cover: Entity }) {
        super();
        this.item = item;
        this.cover = cover;
    }

    static ability(): Ability {
        return {
            handlers: [putUnderHandler, putUnderUnresolvedHandler],
            parser,
            verbs: [
                new Verb('put'),
                new Verb('place'),
                new Verb('slide'),
                new Verb('slip'),
            ],
            prepositions: [
                new Preposition('under'),
                new Preposition('underneath'),
            ],
        };
    }
}

export const putUnderHandler: Handler = async ({ action, runner, actor }) => {
    if (!action.is(PutUnder) || actor === undefined) return;
    const { cover } = action;

    if (cover.isItem() && cover.isDoor()) {
        await runner.doOutput("There's not enough room under this door.");
    } else {
        await runner.doOutput("You can't do that.");
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, PutUnderUnresolved> => {
    const ability = PutUnder.ability();
    const put = Parse.words(ability.verbs);
    const putObject = put.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const putObjectUnder = putObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((cover) => [item, cover])
    );
    return Parse.any(
        // put
        put.into(new PutUnderUnresolved({ item: undefined, cover: undefined })),
        // put mat
        putObject.map(
            (item) => new PutUnderUnresolved({ item, cover: undefined })
        ),
        // put mat under box
        putObjectUnder.map(
            ([item, cover]) =>
                new PutUnderUnresolved({
                    item,
                    cover,
                })
        )
    );
};
