import { Target } from '../../../parse';
import { Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Swim } from './Swim';
import { UnresolvedAction } from '../UnresolvedAction';

export class SwimUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'swim';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'swim';
        }
        return `swim in ${targetDescription(game, this.item)}`;
    }
}

export const swimUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SwimUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new SwimUnresolved({ item }),
        ambiguous: (desc, opt) =>
            `In which ${desc} would you like to swim, ${opt}?`,
        condition: (item) => item.isItem() && item.isVehicle(),
    });

    return await game.applyAction(runner, new Swim({ item }));
};
