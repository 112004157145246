import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { Hallway2 } from '../Hallway2';
import { GuardianHallway } from '../GuardianHallway';
import {
    getNarrowRoomDescription,
    GUARD_STRING,
    hallwayGlobalObjects,
    narrowRoomEnterPassage,
} from '../InsideMirror/mirror_utils';
import { Guardians } from '../../items';

export class WestNarrowRoom3 extends Room {
    static spec(): EntitySpec<WestNarrowRoom3> {
        return {
            ref: 'west-narrow-room-1',
            constructor: WestNarrowRoom3,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return WestNarrowRoom3.spec().ref;
    }

    name(): string {
        return 'Narrow Room';
    }

    description(): string {
        let description = getNarrowRoomDescription(
            this.game,
            this,
            Direction.West
        );
        description += `\nSomewhat to the north${GUARD_STRING}\nTo the south is a large hallway.`;
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    globalObjects(): Entity[] {
        return [
            ...super.globalObjects(),
            ...hallwayGlobalObjects(this),
            this.game.ent(Guardians),
        ];
    }

    passages(): Passage[] {
        return [
            narrowRoomEnterPassage(this.game, Direction.East),
            new Passage({
                via: Direction.South,
                to: Hallway2.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                to: GuardianHallway.spec().ref,
            }),
        ];
    }
}
