import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { EntitySpec } from '../../game';

interface HookState extends ItemState {
    isAnchoring: boolean;
}

export class Hook1 extends Item<HookState> {
    static spec(): EntitySpec<Hook1> {
        return {
            ref: 'hook-1',
            constructor: Hook1,
            initial: {
                isAnchoring: false,
            },
            nouns: [new Noun('hook'), new Noun('hooks', { plural: true })],
            adjectives: [new Adjective('small')],
        };
    }

    isAnchoring() {
        return this.state.isAnchoring;
    }

    setIsAnchoring(isAnchoring: boolean) {
        this.state.isAnchoring = isAnchoring;
    }

    ref() {
        return Hook1.spec().ref;
    }

    name(): string {
        return 'hook';
    }

    description(): string {
        return 'There is a small hook attached to the rock here.';
    }

    shouldBeDescribed(): boolean {
        return !this.isAnchoring();
    }

    nouns() {
        return Hook1.spec().nouns;
    }

    adjectives() {
        return Hook1.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
