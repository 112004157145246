import { Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { SpecialAction } from '../SpecialAction';

export class SpecialMostlyUnderstand extends SpecialAction {
    id = '*mostly-understand';

    action: Action;

    constructor({ action }: { action: Action }) {
        super();
        this.action = action;
    }

    static ability(): Ability {
        return {
            handlers: [specialMostlyUnderstandHandler],
            parser: (game) =>
                Parse.longest(...game.parsers)
                    .before(Parse.anything())
                    .map((action) => new SpecialMostlyUnderstand({ action })),
            verbs: [],
            prepositions: [],
        };
    }
}

export const specialMostlyUnderstandHandler: Handler = async ({
    action,
    runner,
    game,
}) => {
    if (!action.is(SpecialMostlyUnderstand) || !action.action.isDescribable())
        return;

    await runner.doOutput(
        `I only understood you as far as wanting to ${action.action.description(
            game
        )}.`
    );
    return Action.complete({ withConsequence: false });
};
