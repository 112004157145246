import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Cliffs extends Item {
    static spec(): EntitySpec<Cliffs> {
        return {
            ref: 'cliffs',
            constructor: Cliffs,
            initial: {},
            nouns: [
                new Noun('cliff'),
                new Noun('ledge'),
                new Noun('cliff', { collective: true }),
            ],
            adjectives: [new Adjective('rocky'), new Adjective('sheer')],
        };
    }

    ref() {
        return Cliffs.spec().ref;
    }

    name(): string {
        return 'cliffs';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Cliffs.spec().nouns;
    }

    adjectives() {
        return Cliffs.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    canBeClimbed() {
        return true;
    }
}

// TODO climb tree
