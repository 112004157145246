import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * TODO crs doc
 */
export class LongestParser<I, T> extends Parser<I, T> {
    parsers: Parser<I, T>[];

    constructor(...parsers: Parser<I, T>[]) {
        super();
        this.parsers = parsers;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, T>> {
        let longest: Match<I, T> | undefined;
        for (const parser of this.parsers) {
            for (const match of parser.match(tokens)) {
                if (
                    longest === undefined ||
                    longest.rest.length > match.rest.length
                ) {
                    longest = match;
                }
            }
        }
        if (longest !== undefined) {
            yield longest;
        }
    }
}
