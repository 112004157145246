import { Passage, Room, Shore } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { Jump, SpecialJigsUp } from '../../abilities';
import { JUMP_DEATH } from '../../constants';
import { OnTheRainbow } from '../OnTheRainbow';
import { Rainbow } from '../../items';
import { Barrel } from '../../items/Barrel';

export class AragainFalls extends Room {
    static spec(): EntitySpec<AragainFalls> {
        return {
            ref: 'aragain-falls',
            constructor: AragainFalls,
            initial: {
                contents: [Barrel.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [jumpOffWaterfall],
        };
    }

    ref() {
        return AragainFalls.spec().ref;
    }

    name(): string {
        return 'Aragain Falls';
    }

    description(): string {
        let description =
            'You are at the top of Aragain Falls, an enormous waterfall ' +
            'with a drop of about 450 feet. The only path here is on the north end.';
        if (this.game.ent(Rainbow).isSolid()) {
            description += '\nA solid rainbow spans the falls.';
        } else {
            description +=
                '\nA beautiful rainbow can be seen over the falls and to the east.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.Up, Direction.East],
                to: OnTheRainbow.spec().ref,
                condition: () => this.game.ent(Rainbow).isSolid(),
            }),
            new Passage({
                via: Direction.North,
                to: Shore.spec().ref,
            }),
            new Passage({
                via: Direction.Down,
                message: "It's a long way...",
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}

const jumpOffWaterfall: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Jump) && actor?.location()?.is(AragainFalls)) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({ message: game.choiceOf(JUMP_DEATH) })
        );
        return Action.complete();
    }
};
