import { Actor, ActorState, Player } from '../index';
import { Adjective, Direction, Noun, SpecialDirection } from '../../../parse';
import { Action, Handler } from '../../game';
import {
    Close,
    Drop,
    Enter,
    Exit,
    Follow,
    Go,
    Kill,
    Mung,
    Open,
    Poke,
    Push,
    PutIn,
    SetTo,
    SpecialEnter,
    SpecialJigsUp,
    SpecialMostlyUnderstand,
    SpecialNoParse,
    SpecialUnknownWord,
    Spin,
    Stay,
    Take,
    Throw,
    Turn,
} from '../../abilities';
import { QuestionDoor } from '../../items';
import { UnresolvedAction } from '../../abilities/UnresolvedAction';
import { SpecialAction } from '../../abilities/SpecialAction';
import {
    EastCorridor,
    NorthCorridor,
    Parapet,
    PrisonCell,
    SouthCorridor,
    WestCorridor,
} from '../../rooms';

interface DungeonMasterState extends ActorState {
    isFollowing: boolean;
}

export class DungeonMaster extends Actor<DungeonMasterState> {
    static spec() {
        return {
            ref: 'dungeon-master',
            constructor: DungeonMaster,
            initial: {
                inventory: [],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: 10000,
                isEngrossed: false,
                hitPoints: 10000,
                isFollowing: false,
            },
            nouns: [
                new Noun('master'),
                new Noun('masters', { plural: true }),
                new Noun('dungeon master'),
                new Noun('dungeon masters', { plural: true }),
                new Noun('man'),
                new Noun('men', { plural: true }),
                new Noun('dm'),
                new Noun('dms', { plural: true }),
            ],
            adjectives: [],
            handlers: [
                attackDungeonMaster,
                takeDungeonMaster,
                actLikeDungeonMaster,
                dungeonMasterFollowYou,
            ],
        };
    }

    ref() {
        return DungeonMaster.spec().ref;
    }

    name(): string {
        return 'dungeon master';
    }

    description(): string {
        return 'The dungeon master is quietly leaning on his staff here.';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    isVillain(): boolean {
        return false;
    }

    isVictim(): boolean {
        return false;
    }

    nouns(): Noun[] {
        return DungeonMaster.spec().nouns;
    }

    adjectives(): Adjective[] {
        return DungeonMaster.spec().adjectives;
    }

    isReadyToFight(): boolean {
        return false;
    }

    respondsToCommands(): boolean {
        return true;
    }

    isFollowing() {
        return this.state.isFollowing;
    }

    setIsFollowing(isFollowing: boolean) {
        this.state.isFollowing = isFollowing;
    }
}

const attackDungeonMaster: Handler = async ({ action, runner, game }) => {
    if (
        (((action.is(Kill) && action.weapon) || action.is(Poke)) &&
            action.enemy.is(DungeonMaster)) ||
        (action.is(Mung) && action.item.is(DungeonMaster)) ||
        (action.is(Throw) && action.enemy?.is(DungeonMaster))
    ) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    'The dungeon master is taken momentarily by surprise. He dodges your ' +
                    'blow, and then, with a disappointed expression on his face, he ' +
                    'raises his staff, and traces a complicated pattern in the air. ' +
                    'As it completes you crumble into dust.',
            })
        );
        return Action.complete();
    }
};

const takeDungeonMaster: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(DungeonMaster)) {
        await runner.doOutput(
            "'I'm willing to accompany you, but not ride in your pocket!'"
        );
        return Action.complete();
    }
};

const actLikeDungeonMaster: Handler = async ({
    action,
    runner,
    actor,
    game,
}) => {
    if (actor?.is(DungeonMaster)) {
        if (!game.ent(QuestionDoor).isOpen()) {
            await runner.doOutput('There is no reply.');
            return Action.complete({ withConsequence: false });
        }
        if (action instanceof UnresolvedAction) return Action.incomplete();
        if (action.is(Stay)) {
            await runner.doOutput("The dungeon master says, 'I will stay.'");
            actor.setIsFollowing(false);
            return Action.complete();
        }
        if (action.is(Follow)) {
            if (action.item.is(Player)) {
                await runner.doOutput(
                    "The dungeon master answers, 'I will follow.'"
                );
                actor.setIsFollowing(true);
            } else {
                await runner.doOutput(
                    "The dungeon master answers, 'No, thank you.'"
                );
            }
            return Action.complete();
        }
        if (action.is(Go)) {
            const room = game.ent(Player).location();
            if (
                ((action.direction === Direction.South ||
                    action.direction === SpecialDirection.In) &&
                    room.is(NorthCorridor)) ||
                ((action.direction === Direction.North ||
                    action.direction === SpecialDirection.In) &&
                    room.is(SouthCorridor))
            ) {
                await runner.doOutput(
                    "'I am not permitted to enter the prison cell.'"
                );
            } else {
                await runner.doOutput(
                    '"I prefer to stay where I am, thank you."'
                );
            }
            return Action.complete({ withConsequence: false });
        }
        if (
            action.is(Enter) ||
            action.is(Exit) ||
            action.is(Take) ||
            action.is(Drop) ||
            action.is(PutIn) ||
            action.is(Turn) ||
            action.is(SetTo) ||
            action.is(Spin) ||
            action.is(Push) ||
            action.is(Open) ||
            action.is(Close) ||
            action.is(Kill) ||
            action.is(Throw)
        ) {
            await runner.doOutput('"If you wish," he replies.');
            return Action.incomplete();
        }
        if (action.is(SpecialNoParse)) {
            await runner.doOutput(
                '"I apologize, but I admit I do not understand you."'
            );
            return Action.complete({ withConsequence: false });
        }
        if (
            action.is(SpecialMostlyUnderstand) &&
            action.action.isDescribable()
        ) {
            await runner.doOutput(
                `"I only understood you as far as wanting me to ${action.action.description(
                    game
                )}."`
            );
            return Action.complete({ withConsequence: false });
        }
        if (action.is(SpecialUnknownWord)) {
            await runner.doOutput(
                `"I am terribly sorry, but I do not know the word '${action.unknownWord}'."`
            );
            return Action.complete({ withConsequence: false });
        }
        if (action instanceof SpecialAction) return Action.incomplete();
        await runner.doOutput('"I cannot perform that action for you."');
        return Action.complete({ withConsequence: false });
    }
};

const dungeonMasterFollowYou: Handler = async ({
    action,
    game,
    runner,
    actor,
    extensions,
}) => {
    if (action.is(SpecialEnter) && actor?.is(Player)) {
        await extensions.deferHandling();
        const dungeonMaster = game.ent(DungeonMaster);
        if (!dungeonMaster.isFollowing()) return Action.complete();
        const dmLocation = dungeonMaster.location();
        const player = game.ent(Player);
        const room = player.location();
        if (dmLocation?.isEqualTo(room)) return Action.complete();
        if (
            room.like(Parapet) ||
            room.like(NorthCorridor) ||
            room.like(WestCorridor) ||
            room.like(EastCorridor) ||
            room.like(SouthCorridor)
        ) {
            dungeonMaster.moveTo(room);
            const passageExists = dmLocation
                ?.passages()
                .some((passage) => passage.to === room.ref());
            if (passageExists) {
                await runner.doOutput('The dungeon master follows you.');
            } else {
                await runner.doOutput('The dungeon master catches up to you.');
            }
        } else if (room.like(PrisonCell)) {
            await runner.doOutput(
                'You notice that the dungeon master does not follow.'
            );
        }
        return Action.complete();
    }
};
