import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that runs a first parser, then pipes the result value into a function
 * that produces a second parser, which is run in sequence. The result is the
 * result of the second parser.
 *
 * ```
 * word("cat").chain(c => whitespace()).match(tokenize("cat "))
 * ```
 */
export class ChainParser<I, O1, O2> extends Parser<I, O2> {
    parser: Parser<I, O1>;

    maker: (value: O1) => Parser<I, O2>;

    constructor(parser: Parser<I, O1>, maker: (value: O1) => Parser<I, O2>) {
        super();
        this.parser = parser;
        this.maker = maker;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, O2>> {
        for (const match of this.parser.match(tokens)) {
            const parser2 = this.maker(match.token.value);
            for (const match2 of parser2.match(match.rest)) {
                yield new Match(
                    new Token(match2.token.value, [
                        match.token,
                        ...match2.token.source,
                    ]),
                    match2.rest
                );
            }
        }
    }
}
