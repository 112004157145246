import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    InflateUnresolved,
    inflateUnresolvedHandler,
} from './InflateUnresolved';

export class Inflate extends Action {
    id = '~inflate';

    item: Entity;

    tool: Entity | undefined;

    constructor({ item, tool }: { item: Entity; tool: Entity | undefined }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [inflateHandler, inflateUnresolvedHandler],
            parser,
            verbs: [
                new Verb('inflate'),
                new Verb('pump up'), // TODO Pump the boat up with the air pump
                new Verb('blow up'),
            ],
            prepositions: [],
        };
    }
}

export const inflateHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Inflate)) return;
    await runner.doOutput("I really don't see how.");
    return Action.complete();
};

const parser = (game: Game): Parser<Value, InflateUnresolved> => {
    const inflate = Parse.words(Inflate.ability().verbs);
    const inflateObject = inflate.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const inflateObjectWith = inflateObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // inflate
        inflate.into(
            new InflateUnresolved({ item: undefined, tool: undefined })
        ),
        // inflate box
        inflateObject.map(
            (item) => new InflateUnresolved({ item, tool: undefined })
        ),
        // inflate egg with hammer
        inflateObjectWith.map(
            ([item, tool]) =>
                new InflateUnresolved({
                    item,
                    tool,
                })
        )
    );
};
