import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { Boat, Bolt, Pump, ReservoirWater } from '../../items';
import { AtlantisRoom } from '../AtlantisRoom';
import { Reservoir } from '../Reservoir';

export class ReservoirNorth extends Room {
    static spec(): EntitySpec<ReservoirNorth> {
        return {
            ref: 'reservoir-north',
            constructor: ReservoirNorth,
            initial: {
                contents: [Pump.spec().ref, ReservoirWater.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return ReservoirNorth.spec().ref;
    }

    name(): string {
        return 'Reservoir North';
    }

    description(): string {
        let description;
        if (this.game.ent(Bolt).isPowered()) {
            description =
                'You are in a large cavernous room, the south of which was ' +
                'formerly a reservoir. However, with the water level lowered, ' +
                'there is merely a wide stream running through the center of the room.';
        } else {
            description =
                '\nYou are in a large cavernous room, north of a large reservoir.';
        }
        description += '\nThere is a tunnel leaving the room to the north.';
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        const lowTideCondition = () => this.game.ent(Bolt).isPowered();
        return [
            new Passage({ via: Direction.North, to: AtlantisRoom.spec().ref }),
            // new Passage({
            //     via: Direction.Cross,
            //     to: Reservoir.spec().ref,
            //     condition: lowTideCondition,
            //     message: '',
            // }),
            new Passage({
                via: SpecialDirection.Launch,
                to: Reservoir.spec().ref,
                condition: () => !lowTideCondition(),
                message:
                    'With the water level lowered, the reservoir is not suitable for watercraft.',
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: Direction.South,
                to: Reservoir.spec().ref,
                condition: lowTideCondition,
                message: 'You are not equipped for swimming.',
            }),
        ];
    }
}
