import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeTakeable } from '../../game/Entity';
import { EntitySpec } from '../../game';
import { Egg } from '../Egg';

export class Nest extends makeTakeable(makeContainer(Item)) {
    static spec(): EntitySpec<Nest> {
        return {
            ref: 'nest',
            constructor: Nest,
            initial: {
                contents: [Egg.spec().ref],
                hasBeenTaken: false,
            },
            nouns: [new Noun('nest'), new Noun('nests', { plural: true })],
            adjectives: [
                new Adjective('small'),
                new Adjective("bird's"),
                new Adjective('birds'),
            ],
        };
    }

    ref() {
        return Nest.spec().ref;
    }

    name(): string {
        return "bird's nest";
    }

    description(): string {
        return "There is a small bird's nest here.";
    }

    initialDescription(): string {
        return "On the branch is a small bird's nest.";
    }

    totalCapacity(): number {
        return 20;
    }

    size(): number {
        return 9;
    }

    nouns() {
        return Nest.spec().nouns;
    }

    adjectives() {
        return Nest.spec().adjectives;
    }

    isFlammable() {
        return true;
    }

    // TODO crs
    canBeFastened() {
        return true;
    }
}
