import { Action, Handler } from '../../game';
import { Yes, No } from '../../abilities';
import { SpecialAction } from '../../abilities/SpecialAction';

const handler: Handler = async ({ action, game, runner }) => {
    if (action.is(Yes) || action.is(No) || action instanceof SpecialAction)
        return;
    game.yesNo = undefined;
    return Action.incomplete();
};

export const ClearYesNo = {
    handler: () => handler,
};
