import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Dig } from './Dig';
import { UnresolvedAction } from '../UnresolvedAction';

export class DigUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'dig';

    item: Target | undefined;

    tool: Target | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Target | undefined;
        tool: Target | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'dig something';
        }
        if (this.tool === undefined) {
            return `dig ${targetDescription(game, this.item)}`;
        }
        return `dig ${targetDescription(
            game,
            this.item
        )} with ${targetDescription(game, this.tool)}`;
    }
}

export const digUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(DigUnresolved)) return;

    let item;
    if (action.item) {
        const { item: resolvedItem } = await game.resolve(
            runner,
            action.item,
            actor,
            {
                partial: (item) => new DigUnresolved({ item, tool: undefined }),
                ambiguous: (desc, opt) =>
                    `Which ${desc} would you like to dig, ${opt}?`,
                condition: (item) => item.isItem() && item.canBeDug(),
            }
        );
        item = resolvedItem;
        if (item === undefined)
            return Action.complete({ withConsequence: false });

        if (!(await game.reach(runner, item, actor))) return Action.complete();
    }

    let tool;
    if (action.tool) {
        const { item: resolvedTool } = await game.resolve(
            runner,
            action.tool,
            actor,
            {
                partial: (tool) =>
                    new DigUnresolved({ item: action.item, tool }),
                missing: () => `With what would you like to dig?`,
                ambiguous: (desc, opt) =>
                    `Which ${desc} would you like to use to dig, ${opt}?`,
                condition: (item) => item.isItem() && item.isTool(),
            }
        );
        tool = resolvedTool;

        if (!tool) {
            return Action.complete({ withConsequence: false });
        }

        if (tool && !(await game.have(runner, tool, actor)))
            return Action.complete();
    }

    return await game.applyAction(runner, new Dig({ item, tool }));
};
