import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Smell } from './Smell';
import { UnresolvedAction } from '../UnresolvedAction';

export class SmellUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'smell';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'smell something';
        }
        return `smell ${targetDescription(game, this.item)}`;
    }
}

export const smellUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SmellUnresolved)) return;

    // TODO maybe just "smell" to smell the room

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new SmellUnresolved({ item }),
        missing: () => 'Smell what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to smell, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    return await game.applyAction(runner, new Smell({ item }), actor);
};
