import { Passage, Room, TinyRoom } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { NorthSouthCrawlway } from '../NorthSouthCrawlway';
import { Pedestal, Railing, Rope, Torch } from '../../items';

export class TorchRoom extends Room {
    static spec(): EntitySpec<TorchRoom> {
        return {
            ref: 'torch-room',
            constructor: TorchRoom,
            initial: {
                contents: [Torch.spec().ref, Pedestal.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return TorchRoom.spec().ref;
    }

    name(): string {
        return 'Torch Room';
    }

    description(): string {
        let description =
            'This is a large room with a prominent doorway leading to a ' +
            'down staircase. To the west is a narrow twisting tunnel, covered ' +
            'with a thin layer of dust. Above you is a large dome painted with ' +
            'scenes depicting elfin hacking rites. Up around the edge of the dome ' +
            '(20 feet up) is a wooden railing. In the center of the room ' +
            'there is a white marble pedestal.';
        if (this.game.ent(Rope).fastenedItem()?.is(Railing)) {
            description +=
                '\nA large piece of rope descends from the railing above, ending some five feet above your head.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Up,
                message: this.game.ent(Rope).fastenedItem()?.is(Railing)
                    ? 'You cannot reach the rope.'
                    : 'There is no way up.',
            }),
            new Passage({ via: Direction.West, to: TinyRoom.spec().ref }),
            new Passage({
                via: Direction.Down,
                to: NorthSouthCrawlway.spec().ref,
            }),
        ];
    }

    visibleObjects() {
        const objects = super.visibleObjects();
        const railing = this.game.ent(Railing);
        const rope = this.game.ent(Rope);
        objects.push(railing);
        if (rope.fastenedItem()?.isEqualTo(railing)) {
            objects.push(rope);
        }
        return objects;
    }
}
