import { Passage, ReservoirSouth, Room, RoundRoom } from '..';
import { Direction } from '../../../parse';
import { Player } from '../../actors';
import { EntitySpec } from '../../game';
import { Coffin } from '../../items';
import { Dam } from '../Dam';

export class DeepCanyon extends Room {
    static spec(): EntitySpec<DeepCanyon> {
        return {
            ref: 'deep-canyon',
            constructor: DeepCanyon,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return DeepCanyon.spec().ref;
    }

    name(): string {
        return 'Deep Canyon';
    }

    description(): string {
        return (
            'You are on the south edge of a deep canyon. ' +
            'Passages lead off to the east, south, and northwest. ' +
            'You can hear the sound of flowing water below.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Northwest,
                to: ReservoirSouth.spec().ref,
                condition: () =>
                    !this.game.ent(Player).hasItem(this.game.ent(Coffin)),
                message: 'The passage is too steep for carrying the coffin.',
            }),
            new Passage({ via: Direction.East, to: Dam.spec().ref }),
            new Passage({ via: Direction.South, to: RoundRoom.spec().ref }),
        ];
    }
}
