import { Passage, Room, TopOfStairs } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { SmallRoom } from '../SmallRoom';
import { RedEndgameButton } from '../../items';

export class StoneRoom extends Room {
    static spec(): EntitySpec<StoneRoom> {
        return {
            ref: 'stone-room',
            constructor: StoneRoom,
            initial: {
                contents: [RedEndgameButton.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return StoneRoom.spec().ref;
    }

    name(): string {
        return 'Stone Room';
    }

    description(): string {
        return (
            'You are standing near one end of a long, dimly lit hall. ' +
            'At the south stone stairs ascend. To the north the corridor ' +
            'is illuminated by torches set high in the walls, out of reach. ' +
            'On one wall is a red button.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.South, Direction.Up],
                to: TopOfStairs.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                to: SmallRoom.spec().ref,
            }),
        ];
    }
}
