import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Open } from '../../abilities';
import { makeOpenable } from '../../game/Entity';

interface VolcanoGnomeDoorState extends ItemState {
    isHidden: boolean;
}

abstract class Base extends Item<VolcanoGnomeDoorState> {}

export class VolcanoGnomeDoor extends makeOpenable(Base) {
    static spec(): EntitySpec<VolcanoGnomeDoor> {
        return {
            ref: 'volcano-gnome-door',
            constructor: VolcanoGnomeDoor,
            initial: {
                isOpen: true,
                isHidden: true,
            },
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [new Adjective('stone'), new Adjective('secret')],
            handlers: [openVolcanoGnomeDoor],
        };
    }

    isHidden() {
        return this.state.isHidden;
    }

    setIsHidden(isHidden: boolean) {
        this.state.isHidden = isHidden;
    }

    ref() {
        return VolcanoGnomeDoor.spec().ref;
    }

    name(): string {
        return 'secret door';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns() {
        return VolcanoGnomeDoor.spec().nouns;
    }

    adjectives() {
        return VolcanoGnomeDoor.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const openVolcanoGnomeDoor: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(VolcanoGnomeDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput(
                'The gnome kindly left the door open for you.'
            );
        } else {
            door.setIsOpen(true);
            await runner.doOutput('The door is open.');
        }
        return Action.complete();
    }

    if (action.is(Close) && action.item.is(VolcanoGnomeDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            door.setIsOpen(false);
            await runner.doOutput('The door is closed.');
        } else {
            await runner.doOutput('You have already closed it.');
        }
        return Action.complete();
    }
};
