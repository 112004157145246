import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class SkeletonKeys extends makeTakeable(Item) {
    static spec(): EntitySpec<SkeletonKeys> {
        return {
            ref: 'skeleton-keys',
            constructor: SkeletonKeys,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('key'),
                new Noun('keys', { collective: true }),
                new Noun('set of keys'),
                new Noun('sets of keys', { plural: true }),
                new Noun('skeleton key'),
                new Noun('skeleton keys', { collective: true }),
                new Noun('set of skeleton keys'),
                new Noun('sets of skeleton keys', { plural: true }),
            ],
            adjectives: [],
        };
    }

    ref() {
        return SkeletonKeys.spec().ref;
    }

    name() {
        return 'set of skeleton keys';
    }

    isTool() {
        return true;
    }

    description() {
        return 'There is a set of skeleton keys here.';
    }

    size() {
        return 10;
    }

    nouns(): Noun[] {
        return SkeletonKeys.spec().nouns;
    }

    adjectives(): Adjective[] {
        return SkeletonKeys.spec().adjectives;
    }

    canBeTurned(): boolean {
        return true;
    }
}
