import { Passage, Room, RoomInAPuzzle11, TreasureRoom } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { SideRoom } from '../SideRoom';
import { Rope, WarningNote } from '../../items';
import { Climb, Go, SpecialEnter } from '../../abilities';

export class SmallSquareRoom extends Room {
    static spec(): EntitySpec<SmallSquareRoom> {
        return {
            ref: 'small-square-room',
            constructor: SmallSquareRoom,
            initial: {
                contents: [WarningNote.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [goIntoPuzzle, climbDownRopeIntoPuzzle],
        };
    }

    ref() {
        return SmallSquareRoom.spec().ref;
    }

    name(): string {
        return 'Small Square Room';
    }

    description(): string {
        let description =
            'This is a small square room, in the middle of which is a ' +
            'recently created hole';
        if (this.game.ent(RoomInAPuzzle11).containsSandstone()) {
            description += ' which is blocked by smooth sandstone.';
        } else {
            description +=
                ' through which you can barely discern the floor ' +
                "some ten feet below. It doesn't seem likely you could climb back up.";
        }
        description += ' There are exits to the west and south.';
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: SideRoom.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: TreasureRoom.spec().ref,
            }),
            new Passage({
                via: Direction.Down,
                to: RoomInAPuzzle11.spec().ref,
                condition: () =>
                    !this.game.ent(RoomInAPuzzle11).containsSandstone(),
                message: 'The way is blocked by sandstone.',
            }),
        ];
    }
}

const goIntoPuzzle: Handler = async ({
    action,
    runner,
    actor,
    game,
    extensions,
}) => {
    if (action.is(Go) && action.direction === Direction.Down && actor) {
        const room = game.locateEntity(actor);
        if (room && room.is(SmallSquareRoom)) {
            const rope = game.ent(Rope);
            const fastenedItem = rope.fastenedItem();
            if (fastenedItem && room.contains(fastenedItem)) {
                await extensions.deferHandling();
                const newRoom = actor.location();
                if (newRoom?.is(RoomInAPuzzle11)) {
                    await runner.doOutput(
                        `The ${fastenedItem} was not well-braced and falls through the hole after you, nearly cracking your skull.`
                    );
                    fastenedItem.moveTo(newRoom);
                    rope.moveTo(newRoom);
                }
                return Action.complete();
            }
        }
    }
};

const climbDownRopeIntoPuzzle: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (
        action.is(Climb) &&
        action.direction === Direction.Down &&
        actor?.location()?.is(SmallSquareRoom) &&
        action.item?.is(Rope)
    ) {
        if (!action.item.fastenedItem()) {
            await runner.doOutput("Well, it's not really tied, but....");
            const destination = game.ent(RoomInAPuzzle11);
            await game.applyAction(
                runner,
                new SpecialEnter({ room: destination })
            );
            await runner.doOutput('The rope falls in after you.');
            action.item?.moveTo(destination);
            return Action.complete();
        }
    }
};
