import { SpecialEnter, SpecialStartUp } from '../abilities';
import { Handler } from '../game';
import { Room, WestOfHouse } from '../rooms';

export const startUp: Handler = async ({ action, runner, game }) => {
    if (!action.is(SpecialStartUp)) return;

    await runner.doOutput('Welcome to Zork!\n\n');
    return game.applyAction(
        runner,
        new SpecialEnter({ room: game.get(WestOfHouse.spec().ref) as Room })
    );
};
