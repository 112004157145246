import { Action, Handler } from '../../game';
import { SpecialAction } from '../SpecialAction';
import { Player } from '../../actors';
import {
    BehindHouse,
    Clearing,
    Forest2,
    Forest3,
    Kitchen,
    LandOfTheLivingDead,
    LivingRoom,
    SouthOfHouse,
} from '../../rooms';
import { SpecialEnter } from '../SpecialEnter';

export class SpecialJigsUp extends SpecialAction {
    id = '*jigs-up';

    message: string;

    constructor({ message }: { message: string }) {
        super();
        this.message = message;
    }

    static handler: Handler = async ({ action, runner, actor, game }) => {
        if (!action.is(SpecialJigsUp) || !actor) return;

        await runner.doOutput(action.message);

        if (actor.vehicle()) {
            actor.moveTo(actor?.location());
        }

        if (!actor.is(Player)) {
            await runner.doOutput(`${actor.The()} has died.`);
            actor.moveTo(undefined);
            return Action.complete({ withConsequence: false });
        }

        game.state.score -= 10;
        actor.state.isAlive = false;

        if (game.state.isEndgame) {
            await runner.doOutput(ENDGAME_DEATH_MESSAGE);
            await game.quit(runner, 'died in the end game');
            return;
        }

        game.state.deathCount += 1;

        if (game.state.deathCount > 2) {
            await runner.doOutput(SUICIDAL_MESSAGE);
            await game.quit(runner, 'died too many times');
            return;
        }

        await runner.doOutput(DEATH_MESSAGE);

        const roomsToScatterItems = [
            game.ent(LivingRoom),
            game.ent(Kitchen),
            game.ent(Clearing),
            game.ent(Forest2),
            game.ent(Forest2),
            game.ent(Forest3),
            game.ent(SouthOfHouse),
            game.ent(BehindHouse),
        ];
        for (const item of actor.inventory()) {
            item.moveTo(game.choiceOf(roomsToScatterItems));
        }

        await game.applyAction(
            runner,
            new SpecialEnter({ room: game.ent(LandOfTheLivingDead) }),
            actor
        );

        // TODO all the other death things

        return Action.complete({ withConsequence: false });
    };
}

const DEATH_MESSAGE =
    'As you take your last breath, you feel relieved of your burdens. ' +
    'The feeling passes as you find yourself before the gates of Hell, ' +
    'where the spirits jeer at you and deny you entry. Your senses are ' +
    'disturbed. The objects in the dungeon appear indistinct, ' +
    'bleached of color, even unreal.';

const SUICIDAL_MESSAGE =
    "You clearly are a suicidal maniac. We don't allow psychotics " +
    'in the cave, since they may harm other adventurers. ' +
    'Your remains will be installed in the Land of the Living Dead, ' +
    'where your fellow adventurers may gloat over them.';

const ENDGAME_DEATH_MESSAGE =
    "Normally I could attempt to rectify your condition, but I'm " +
    'ashamed to say my abilities are not equal to dealing with ' +
    'your present state of disrepair. ' +
    'Permit me to express my profoundest regrets.';
