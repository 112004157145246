import { CanyonView, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { NO_TREE } from '../../constants';
import { EntitySpec } from '../../game';
import { Forest2 } from '../Forest2';
import { Forest5 } from '../Forest5';

export class Forest4 extends Room {
    static spec(): EntitySpec<Forest4> {
        return {
            ref: 'forest-4',
            constructor: Forest4,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Forest4.spec().ref;
    }

    name(): string {
        return 'Forest';
    }

    description(): string {
        return (
            'This is a large forest, with trees obstructing all views except ' +
            'to the east, where a small clearing may be seen through the trees.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: Forest5.spec().ref }),
            new Passage({ via: Direction.West, to: Forest2.spec().ref }),
            new Passage({ via: Direction.South, to: Forest4.spec().ref }),
            new Passage({ via: Direction.East, to: CanyonView.spec().ref }),
            new Passage({ via: Direction.Up, message: NO_TREE }),
        ];
    }

    isPartOfForest() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
