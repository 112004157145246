import { Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { EntitySpec } from '../../game';

interface RainbowState extends ItemState {
    isSolid: boolean;
}

export class Rainbow extends Item<RainbowState> {
    static spec(): EntitySpec<Rainbow> {
        return {
            ref: 'rainbow',
            constructor: Rainbow,
            initial: {
                isSolid: false,
            },
            nouns: [
                new Noun('rainbow'),
                new Noun('rainbows', { plural: true }),
                new Noun('rain bow'),
                new Noun('rain bows', { plural: true }),
            ],
            adjectives: [],
        };
    }

    ref() {
        return Rainbow.spec().ref;
    }

    name(): string {
        return 'rainbow';
    }

    isSolid() {
        return this.state.isSolid;
    }

    setIsSolid(isSolid: boolean) {
        this.state.isSolid = isSolid;
    }

    description(): string {
        return '';
    }

    nouns() {
        return Rainbow.spec().nouns;
    }

    adjectives() {
        return Rainbow.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
