import { Verb, Parse, Parser, Preposition, Value } from '../../../parse';
import { Player } from '../../actors';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { DropUnresolved, dropUnresolvedHandler } from './DropUnresolved';

export class Drop extends Action {
    id = '~drop';

    item: Entity;

    silent: boolean;

    constructor({ item, silent = false }: { item: Entity; silent?: boolean }) {
        super();
        this.item = item;
        this.silent = silent;
    }

    static ability(): Ability {
        return {
            handlers: [dropHandler, dropUnresolvedHandler],
            parser,
            verbs: [
                new Verb('drop'),
                new Verb('put down'),
                new Verb('discard'),
                new Verb('leave'),
            ],
            prepositions: [new Preposition('up')],
        };
    }
}

export const dropHandler: Handler = async ({ action, runner, game, actor }) => {
    if (!action.is(Drop) || actor === undefined) return undefined;
    const { item } = action;
    if (actor.hasItem(item)) {
        const room = game.locateEntity(actor);
        if (room === undefined) return;
        item.moveTo(room);
        await runner.doOutput('Done.');
    } else if (actor.is(Player)) {
        await runner.doOutput(`You do not have ${item.the()}.`);
    } else {
        await runner.doOutput(`${actor.The()} does not have ${item.the()}.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, DropUnresolved> => {
    const object = Parse.target(game.lexicon);
    return Parse.either(
        Parse.words(Drop.ability().verbs)
            .then(Parse.whitespace())
            .beforeX(object),
        Parse.word('put')
            .then(Parse.whitespace())
            .beforeX(object)
            .before(Parse.whitespace().then(Parse.word('down')))
    ).map((items) => new DropUnresolved({ items }));
};
