import { Passage, Room } from '..';
import { Direction, Group, Obj, Target } from '../../../parse';
import { Kitchen } from '../Kitchen';
import { Action, EntitySpec, Handler } from '../../game';
import { SpecialDescribeRoom } from '../../abilities/SpecialDescribeRoom';
import {
    Lamp,
    Newspaper,
    Rug,
    Sword,
    TrapDoor,
    TrophyCase,
    WoodenDoor,
    WoodenDoorLettering,
} from '../../items';
import { Cellar } from '../Cellar';
import { Cyclops } from '../../actors';
import { StrangePassage } from '../StrangePassage';
import { CountMany } from '../../abilities/Count/Count';
import { NUMBERS } from '../../constants';
import { pluralize } from '../../utils';

export class LivingRoom extends Room {
    static spec(): EntitySpec<LivingRoom> {
        return {
            ref: 'living-room',
            constructor: LivingRoom,
            initial: {
                contents: [
                    TrophyCase.spec().ref,
                    Rug.spec().ref,
                    TrapDoor.spec().ref,
                    WoodenDoorLettering.spec().ref,
                    WoodenDoor.spec().ref,
                    Lamp.spec().ref,
                    Newspaper.spec().ref,
                    Sword.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [describeLivingRoom, countValuablesInLivingRoom],
        };
    }

    ref() {
        return LivingRoom.spec().ref;
    }

    name(): string {
        return 'Living Room';
    }

    description(): string {
        throw new Error('Handler should have overridden this.');
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: Kitchen.spec().ref }),
            new Passage({
                via: Direction.West,
                to: StrangePassage.spec().ref,
                condition: () => this.game.ent(Cyclops).hasFled(),
                message: 'The door is nailed shut.',
            }),
            new Passage({
                via: Direction.Down,
                to: Cellar.spec().ref,
                condition: () => this.game.ent(TrapDoor).isOpen(),
                message: 'There is no way down.',
            }),
        ];
    }

    isPartOfHouse(): boolean {
        return true;
    }
}

const describeLivingRoom: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialDescribeRoom) && action.room.is(LivingRoom)) {
        const rug = game.ent(Rug);
        const trapDoor = game.ent(TrapDoor);
        let status;
        if (rug.isMoved()) {
            if (trapDoor.isOpen()) {
                status = 'and a rug lying beside an open trap-door';
            } else {
                status = 'and a closed trap-door at your feet';
            }
        } else {
            status = 'and a large oriental rug in the center of the room';
        }
        if (game.ent(Cyclops).hasFled()) {
            await runner.doOutput(
                `You are in the living room. There is a ` +
                    `door to the east. To the west is a cyclops-shaped hole in an ` +
                    `old wooden door, above which is some strange gothic ` +
                    `lettering, ${status}.`
            );
        } else {
            await runner.doOutput(
                `You are in the living room. There is a door to the east, a ` +
                    `wooden door with strange gothic lettering to the west, which ` +
                    `appears to be nailed shut, ${status}.`
            );
        }
        return Action.complete({ withConsequence: false });
    }
};

const countValuablesInLivingRoom: Handler = async ({
    action,
    runner,
    extensions,
    game,
    actor,
}) => {
    if (
        action.is(CountMany) &&
        game.here().is(LivingRoom) &&
        targetIsValuables(action.target)
    ) {
        await extensions.deferHandling();
        const count = game.ent(TrophyCase).contents().length;
        const numString = count === 0 ? 'no' : NUMBERS[count];
        await runner.doOutput(
            `Your adventure has netted ${numString} ${pluralize(
                count,
                'treasure'
            )}.`
        );
        return Action.complete();
    }
};

function targetIsValuables(target: Target) {
    if (target.items.length > 1) {
        return false;
    }
    const item = target.items[0];
    // TODO This is pretty hacky...
    if (item instanceof Group) {
        if (!item.exclude && item.include instanceof Obj) {
            if (
                item.include.noun.value === 'valuables' ||
                item.include.noun.value === 'treasures'
            ) {
                return true;
            }
        }
    }
    return false;
}
