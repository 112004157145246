import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Emerald extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<Emerald> {
        return {
            ref: 'emerald',
            constructor: Emerald,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('emerald'),
                new Noun('emeralds', { plural: true }),
                new Noun('jewel'),
            ],
            adjectives: [
                new Adjective('huge'),
                new Adjective('enormous'),
                new Adjective('large'),
                new Adjective('perfect'),
            ],
        };
    }

    ref() {
        return Emerald.spec().ref;
    }

    name() {
        return 'large emerald';
    }

    description() {
        return 'There is an emerald here.';
    }

    scoreOnTake(): number {
        return 5;
    }

    scoreInCase(): number {
        return 10;
    }

    nouns(): Noun[] {
        return Emerald.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Emerald.spec().adjectives;
    }
}
