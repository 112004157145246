import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { WhiteCliffsBeach2 } from '../WhiteCliffsBeach2';
import { River3 } from '../River3';
import { Boat, River, WhiteCliffs } from '../../items';

export class WhiteCliffsBeach1 extends Room {
    static spec(): EntitySpec<WhiteCliffsBeach1> {
        return {
            ref: 'white-cliffs-beach-1',
            constructor: WhiteCliffsBeach1,
            initial: {
                contents: [WhiteCliffs.spec().ref, River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return WhiteCliffsBeach1.spec().ref;
    }

    name(): string {
        return 'White Cliffs Beach';
    }

    description(): string {
        return 'You are on a narrow strip of beach which runs along the base of the White Cliffs. The only path here is a narrow one, heading south along the Cliffs.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: WhiteCliffsBeach2.spec().ref,
            }),
            new Passage({
                via: SpecialDirection.Launch,
                to: River3.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
