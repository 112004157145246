import { Passage, Room } from '..';
import { MAZE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Maze7 } from '../Maze7';
import { DeadEnd3 } from '../DeadEnd3';

export class Maze8 extends Room {
    static spec(): EntitySpec<Maze8> {
        return {
            ref: 'maze-8',
            constructor: Maze8,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Maze8.spec().ref;
    }

    name(): string {
        return 'Maze';
    }

    description(): string {
        return MAZE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Northeast, to: Maze7.spec().ref }),
            new Passage({ via: Direction.West, to: Maze8.spec().ref }),
            new Passage({ via: Direction.Southeast, to: DeadEnd3.spec().ref }),
        ];
    }

    // TODO
    isPartOfMaze() {
        return true;
    }
}
