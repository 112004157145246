import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Wind } from '../../abilities';
import { Bauble } from '..';
import { Player } from '../../actors';
import { Forest3, UpATree } from '../../rooms';

interface CanaryState extends ItemState {
    isBroken: boolean;
}

abstract class Base extends Item<CanaryState> {}

export class Canary extends makeTreasure(makeTakeable(Base)) {
    static spec(): EntitySpec<Canary> {
        return {
            ref: 'canary',
            constructor: Canary,
            initial: {
                hasBeenTaken: false,
                isBroken: false,
            },
            nouns: [new Noun('canary'), new Noun('canaries', { plural: true })],
            adjectives: [
                new Adjective('clockwork'),
                new Adjective('mechanical'),
                new Adjective('gold'),
                new Adjective('golden'),
                new Adjective('non-functional'),
                new Adjective('broken'),
            ],
            handlers: [windCanary],
        };
    }

    ref() {
        return Canary.spec().ref;
    }

    name(): string {
        return this.isBroken()
            ? 'broken jewel-encrusted egg'
            : 'clockwork canary';
    }

    isTreasure() {
        return !this.isBroken();
    }

    isBroken(): boolean {
        return this.state.isBroken;
    }

    setIsBroken(isBroken: boolean) {
        this.state.isBroken = isBroken;
    }

    scoreOnTake(): number {
        return 6;
    }

    scoreInCase(): number {
        return 2;
    }

    description(): string {
        return this.isBroken()
            ? 'There is a non-functional canary here.'
            : 'There is golden clockwork canary here.';
    }

    initialDescription(): string {
        return this.isBroken()
            ? 'There is a golden clockwork canary nestled in the egg. ' +
                  'It seems to have recently had a bad experience. The mountings for ' +
                  'its jewel-like eyes are empty, and its silver beak is crumpled. ' +
                  'Through a cracked crystal window below its left wing you can see the ' +
                  'remains of intricate machinery. It is not clear what result winding ' +
                  'it would have, as the mainspring seems sprung.'
            : 'There is a golden clockwork canary nestled in the egg. ' +
                  'It has ruby eyes and a silver beak. Through a crystal window ' +
                  'below its left wing you can see intricate machinery inside. ' +
                  'It appears to have wound down.';
    }

    nouns() {
        return Canary.spec().nouns;
    }

    adjectives() {
        const adjectives = [...Canary.spec().adjectives];
        if (this.isBroken()) {
            adjectives.push(
                new Adjective('non-functional'),
                new Adjective('broken')
            );
        }
        return adjectives;
    }
}

const windCanary: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Wind) && action.item.is(Canary) && actor?.is(Player)) {
        const { item: canary } = action;
        if (canary.isBroken()) {
            await runner.doOutput(
                'There is an unpleasant grinding noise from inside the canary.'
            );
        } else if (
            !game.ent(Bauble).isHidden() ||
            !actor.location().isPartOfForest()
        ) {
            await runner.doOutput(
                'The canary chirps blithely, if somewhat tinnily, for a short time.'
            );
        } else {
            let room = actor.location();
            if (room.is(UpATree)) room = game.ent(Forest3);
            const bauble = game.ent(Bauble);
            bauble.moveTo(room);
            bauble.setIsHidden(false);
            await runner.doOutput(
                'The canary chirps, slightly off-key, an aria from a forgotten opera. ' +
                    'From out of the greenery flies a lovely songbird. It perches on a ' +
                    'limb just over your head and opens its beak to sing. As it does ' +
                    'so a beautiful brass bauble drops from its mouth, bounces off the ' +
                    'top of your head, and lands glimmering in the grass. ' +
                    'As the canary winds down, the songbird flies away.'
            );
        }
        return Action.complete();
    }
};
