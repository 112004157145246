import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that matches each of the given `parser`'s results iff the `condition`
 * function returns a truthy value given the yielded value from `parser`.
 *
 * ```
 * premise(constant(2), (x) => x % 2 == 0).match(tokenize("")) // yes
 * premise(constant(3), (x) => x % 2 == 0).match(tokenize("")) // no
 * ```
 */
export class PremiseParser<I, O> extends Parser<I, O> {
    parser: Parser<I, O>;

    condition: (v: O) => boolean;

    constructor(parser: Parser<I, O>, condition: (v: O) => boolean) {
        super();
        this.parser = parser;
        this.condition = condition;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, O>> {
        for (const match of this.parser.match(tokens)) {
            if (this.condition(match.token.value)) {
                yield match;
            }
        }
    }
}
