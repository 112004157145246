import { TurnOff } from './TurnOff';
import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { UnresolvedAction } from '../UnresolvedAction';

export class TurnOffUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'turn-off';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'turn something off';
        }
        return `turn off ${targetDescription(game, this.item)}`;
    }
}

export const turnOffUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(TurnOffUnresolved)) return;
    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new TurnOffUnresolved({ item }),
        missing: () => 'What do you want to turn off?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to turn off, ${opt}?`,
        condition: (item) => item.isItem() && item.isFood(),
    });
    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) {
        return Action.complete();
    }

    return await game.applyAction(runner, new TurnOff({ item }));
};
