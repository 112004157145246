import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { Maze15 } from '../Maze15';
import { Cyclops } from '../../actors';
import { SpecialDescribeRoom } from '../../abilities/SpecialDescribeRoom';
import { Ulysses } from '../../abilities';
import { StrangePassage } from '../StrangePassage';
import { TreasureRoom } from '../TreasureRoom';

export class CyclopsRoom extends Room {
    static spec(): EntitySpec<CyclopsRoom> {
        return {
            ref: 'cyclops-room',
            constructor: CyclopsRoom,
            initial: {
                contents: [Cyclops.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [describeCyclopsRoom, scareCyclops],
        };
    }

    ref() {
        return CyclopsRoom.spec().ref;
    }

    name(): string {
        return 'Cyclops Room';
    }

    description(): string {
        return '';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: Maze15.spec().ref }),
            new Passage({
                via: Direction.Up,
                to: TreasureRoom.spec().ref,
                condition: () => {
                    const cyclops = this.game.ent(Cyclops);
                    return cyclops.isAsleep() || cyclops.hasFled();
                },
                message: "The cyclops doesn't look like he'll let you pass.",
            }),
            new Passage({
                via: Direction.North,
                to: StrangePassage.spec().ref,
                condition: () => this.game.ent(Cyclops).hasFled(),
                message: 'The north wall is solid rock.',
            }),
        ];
    }
}

// TODO turn this into just a description() implementation...
const describeCyclopsRoom: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialDescribeRoom) && action.room.is(CyclopsRoom)) {
        await runner.doOutput(
            'This room has an exit on the west side, and a staircase leading up.'
        );
        if (game.ent(Cyclops).hasFled()) {
            await runner.doOutput(
                'The north wall, previously solid, now has a cyclops-sized hole in it.'
            );
        }
        return Action.complete({ withConsequence: false });
    }
};

const scareCyclops: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Ulysses) && actor) {
        const cyclops = game.ent(Cyclops);
        const room = game.locateEntity(actor);
        if (!cyclops.hasFled() && room?.is(CyclopsRoom)) {
            cyclops.state.hasFled = true;
            cyclops.moveTo(undefined);
            await runner.doOutput(
                "The cyclops, hearing the name of his father's deadly nemesis, " +
                    'flees the room by knocking down the wall on the north of the room.'
            );
            return Action.complete();
        }
    }
};
