import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Find } from '../../abilities';

export class GraniteWall extends Item {
    static spec(): EntitySpec<GraniteWall> {
        return {
            ref: 'granite-wall',
            constructor: GraniteWall,
            initial: {},
            nouns: [new Noun('wall'), new Noun('walls', { plural: true })],
            adjectives: [
                new Adjective('granite'),
                new Adjective('north'),
                new Adjective('northern'),
                new Adjective('northernmost'),
            ],
            handlers: [findGraniteWall],
        };
    }

    ref() {
        return GraniteWall.spec().ref;
    }

    name(): string {
        return 'granite wall';
    }

    description(): string {
        return '';
    }

    nouns() {
        return GraniteWall.spec().nouns;
    }

    adjectives() {
        return GraniteWall.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isWall(): boolean {
        return true;
    }
}

const findGraniteWall: Handler = async ({ action, runner }) => {
    if (action.is(Find) && action.item.is(GraniteWall)) {
        await runner.doOutput('The north wall is solid granite here.');
        return Action.complete();
    }
};
