import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import {
    Board,
    GoThrough,
    PutIn,
    SpecialEnter,
    SpecialTimerTick,
} from '../../abilities';
import { makeContainer } from '../../game/Entity';
import { QuantityOfWater } from '../QuantityOfWater';
import {
    Cellar,
    InASlide2,
    InASlide1,
    InASlide3,
    SlideRoom,
} from '../../rooms';
import { Rope } from '../Rope';

interface SlideState extends ItemState {
    remainingTime: number;
}

abstract class Base extends Item<SlideState> {}

export class Slide extends makeContainer(Base) {
    static spec(): EntitySpec<Slide> {
        return {
            ref: 'slide',
            constructor: Slide,
            initial: {
                contents: [],
                remainingTime: 0,
            },
            nouns: [
                new Noun('slide'),
                new Noun('slides', { plural: true }),
                new Noun('chute'),
                new Noun('chutes', { plural: true }),
            ],
            adjectives: [new Adjective('steep'), new Adjective('metal')],
            handlers: [goInSlide, putInSlide, slipperySlide],
        };
    }

    totalCapacity(): undefined {
        return undefined;
    }

    ref() {
        return Slide.spec().ref;
    }

    name(): string {
        return 'slide';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Slide.spec().nouns;
    }

    adjectives() {
        return Slide.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    remainingTime(): number {
        return this.state.remainingTime;
    }
}

const goInSlide: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(Board) && action.vehicle.is(Slide)) ||
        (action.is(GoThrough) && action.item.is(Slide))
    ) {
        // TODO if rope tied don't do this???
        await runner.doOutput('You tumble down the slide....');
        await game.applyAction(
            runner,
            new SpecialEnter({ room: game.ent(Cellar) })
        );
        return Action.complete();
    }
};

const putInSlide: Handler = async ({ action, runner, game, actor }) => {
    if (
        action.is(PutIn) &&
        action.container.is(Slide) &&
        actor?.hasItem(action.item)
    ) {
        await runner.doOutput(
            `${action.item.The()} falls through the slide and is gone.`
        );
        if (action.item.is(QuantityOfWater)) {
            action.item.moveTo(undefined);
        } else {
            const rope = game.ent(Rope);
            const fastenedItem = rope.fastenedItem();
            if (
                action.item.is(Rope) &&
                fastenedItem &&
                game.ent(SlideRoom).contains(fastenedItem)
            ) {
                await runner.doOutput('The rope dangles down the slide.');
            } else {
                const cellar = game.ent(Cellar);
                if (fastenedItem?.isEqualTo(action.item)) {
                    rope.moveTo(cellar);
                }
                action.item.moveTo(cellar);
            }
        }
        return Action.complete();
    }
};

const slipperySlide: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(SpecialTimerTick)) {
        const slide = game.ent(Slide);
        if (slide.remainingTime()) {
            slide.state.remainingTime -= 1;
            if (slide.state.remainingTime === 0) {
                const room = actor?.location();
                if (
                    room &&
                    (room.isEqualTo(game.ent(InASlide1)) ||
                        room.isEqualTo(game.ent(InASlide2)) ||
                        room.isEqualTo(game.ent(InASlide3)))
                ) {
                    await runner.doOutput(
                        'The rope slips from your grasp and you tumble to the cellar.'
                    );
                    await game.applyAction(
                        runner,
                        new SpecialEnter({ room: game.ent(Cellar) })
                    );
                }
            }
        }
        return Action.incomplete();
    }
};
