import { Noun, Adjective } from '../../../parse';
import { makeReadable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Eat, Read, SpecialJigsUp } from '../../abilities';
import { TeaRoom } from '../../rooms/TeaRoom';
import { RedCake } from '../RedCake';
import { OrangeCake } from '../OrangeCake';
import { Bottle } from '../Bottle';
import { Flask } from '../Flask';

export class BlueCake extends makeReadable(makeTakeable(Item)) {
    static spec(): EntitySpec<BlueCake> {
        return {
            ref: 'blue-cake',
            constructor: BlueCake,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('cake', { collective: true }),
                new Noun('cakes', { plural: true }),
                new Noun('icing', { collective: true }),
                new Noun('piece of cake'),
                new Noun('pieces of cake', { plural: true }),
                new Noun('piece of cake with blue icing'),
                new Noun('pieces of cake with blue icing', { plural: true }),
            ],
            adjectives: [
                new Adjective('blue'),
                new Adjective('eech'),
                new Adjective('repulsive'),
            ],
            handlers: [eatBlueCake, readCakesThrough],
        };
    }

    text(): string {
        return (
            'The only writing legible is a capital E. ' +
            'The rest is too small to be clearly visible.'
        );
    }

    enlargedText(): string {
        return 'Enlarge';
    }

    size(): number {
        return 4;
    }

    ref() {
        return BlueCake.spec().ref;
    }

    name() {
        return 'piece of cake with blue icing';
    }

    description() {
        return 'There is a piece of cake with blue (ecch) icing here.';
    }

    nouns(): Noun[] {
        return BlueCake.spec().nouns;
    }

    adjectives(): Adjective[] {
        return BlueCake.spec().adjectives;
    }

    isFood(): boolean {
        return true;
    }
}

const eatBlueCake: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Eat) && action.item.is(BlueCake)) {
        const room = actor?.location();
        if (room?.is(TeaRoom)) {
            await runner.doOutput(
                'The room around you seems to be getting smaller.'
            );
            action.item.moveTo(undefined);
            if (room.isEnlarged()) {
                room.setIsEnlarged(false);
            } else {
                await game.applyAction(
                    runner,
                    new SpecialJigsUp({
                        message:
                            'The room seems to have become too small to hold you. ' +
                            'It seems that the walls are not as compressible as your body, ' +
                            'which is more or less demolished.',
                    })
                );
            }
            return Action.complete();
        }
    }
};

const readCakesThrough: Handler = async ({ action, runner }) => {
    if (
        action.is(Read) &&
        (action.item.is(BlueCake) ||
            action.item.is(RedCake) ||
            action.item.is(OrangeCake)) &&
        action.tool
    ) {
        if (action.tool.is(Bottle)) {
            await runner.doOutput(
                'The letters appear larger, but still are too small to be read.'
            );
        } else if (action.tool.is(Flask)) {
            const text = action.item.enlargedText();
            await runner.doOutput(`The icing, now visible, says '${text}'.`);
        } else {
            await runner.doOutput("You can't see through that!");
        }
        return Action.complete();
    }
};
