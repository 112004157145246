import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { EastTellersRoom } from '../EastTellersRoom';
import { WestTellersRoom } from '../WestTellersRoom';
import { Gallery } from '../Gallery';

export class BankEntrance extends Room {
    static spec(): EntitySpec<BankEntrance> {
        return {
            ref: 'bank-entrance',
            constructor: BankEntrance,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return BankEntrance.spec().ref;
    }

    name(): string {
        return 'Bank Entrance';
    }

    description(): string {
        return BANK_ENTRANCE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Northeast,
                to: EastTellersRoom.spec().ref,
            }),
            new Passage({
                via: Direction.Northwest,
                to: WestTellersRoom.spec().ref,
            }),
            new Passage({ via: Direction.South, to: Gallery.spec().ref }),
        ];
    }

    hasWalls(): boolean {
        return true;
    }
}

const BANK_ENTRANCE_DESCRIPTION =
    'This is the large entrance hall of the Bank of Zork, ' +
    'the largest banking institution of the Great Underground Empire. ' +
    "A partial account of its history is in 'The Lives of the Twelve Flatheads' " +
    'with the chapter on J. Pierpont Flathead. A more detailed history ' +
    "(albeit less objective) may be found in Flathead's outrageous autobiography " +
    "'I'm Rich and You Aren't - So There!'. Most of the furniture has been ravaged " +
    'by passing scavengers. All that remains are two signs at the Northwest ' +
    'and Northeast corners of the room, which say\n\n' +
    `      <--  WEST VIEWING ROOM        EAST VIEWING ROOM  -->`;
