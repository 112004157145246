import { GlacierRoom, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Coffin } from '../../items';
import { RockyCrawl } from '../RockyCrawl';
import { Player } from '../../actors';
import { VolcanoView } from '../VolcanoView';

export class EgyptianRoom extends Room {
    static spec(): EntitySpec<EgyptianRoom> {
        return {
            ref: 'egyptian-room',
            constructor: EgyptianRoom,
            initial: {
                contents: [Coffin.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return EgyptianRoom.spec().ref;
    }

    name(): string {
        return 'Egyptian Room';
    }

    description(): string {
        return (
            'This is a room which looks like an Egyptian tomb. ' +
            'There is an ascending staircase in the room as well as doors, east and south.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Up,
                to: GlacierRoom.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                to: VolcanoView.spec().ref,
            }),
            new Passage({
                via: Direction.East,
                condition: () =>
                    !this.game.ent(Player).hasItem(this.game.ent(Coffin)),
                message: 'The passage is to narrow to accommodate coffins.',
                to: RockyCrawl.spec().ref,
            }),
        ];
    }
}
