import { Noun, Adjective } from '../../../parse';
import { Entity, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import {
    Board,
    Pour,
    PutIn,
    SpecialEnter,
    SpecialTimerTick,
    Swim,
    Take,
    Throw,
} from '../../abilities';
import { Bottle } from '../Bottle';
import { Player } from '../../actors';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';
import { CircularRoom } from '../../rooms';
import { TopOfWell } from '../../rooms/TopOfWell';
import { Bucket } from '../Bucket';
import { PourOn } from '../../abilities/PourOn';
import { Torch } from '../Torch';
import { BalloonReceptacle } from '../BalloonReceptacle';
import { Fill } from '../../abilities/Fill';

export class QuantityOfWater extends makeTakeable(Item) {
    static spec(): EntitySpec<QuantityOfWater> {
        return {
            ref: 'quantity-of-water',
            constructor: QuantityOfWater,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('quantity of water'),
                new Noun('h2o', { collective: true }),
                new Noun('liquid', { collective: true }),
                new Noun('water', { collective: true }),
            ],
            adjectives: [],
            handlers: [
                takeWaterHandler,
                throwWater,
                fillWithWater,
                fillBottle,
                pourWater,
                pourWaterOn,
                evaporateWater,
                goInWater,
            ],
        };
    }

    ref() {
        return QuantityOfWater.spec().ref;
    }

    name() {
        return 'quantity of water';
    }

    description() {
        return 'There is some water here.';
    }

    nouns(): Noun[] {
        return QuantityOfWater.spec().nouns;
    }

    adjectives(): Adjective[] {
        return QuantityOfWater.spec().adjectives;
    }

    isLiquid(): boolean {
        return true;
    }

    size(): number {
        return 4;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const takeWaterHandler: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Take) && action.item.is(QuantityOfWater)) {
        // TODO compare to original game...
        if (actor?.hasItem(action.item)) {
            await runner.doOutput('You already have it.');
        } else if (actor?.hasItem(game.ent(Bottle))) {
            action.item.moveTo(game.ent(Bottle));
            await runner.doOutput('The bottle is now full of water.');
            await maybeToggleWellPosition(game, runner);
        } else {
            await runner.doOutput('The water slips through your fingers.');
        }
        return Action.complete();
    }
};

const throwWater: Handler = async ({ action, runner }) => {
    if (action.is(Throw) && action.item.is(QuantityOfWater)) {
        await runner.doOutput(
            'The water splashes on the walls, and evaporates immediately.'
        );
        return Action.complete();
    }
};

function canUseToFillBottle(item: Entity) {
    return (item.isItem() && item.isBodyOfWater()) || item.is(QuantityOfWater);
}

const goInWater: Handler = async ({ action, game, extensions, actor }) => {
    // TODO maybe separate GoIn, so you can't say "board the water"
    if (action.is(Board) && canUseToFillBottle(action.vehicle)) {
        return extensions.replaceAction(
            new Swim({
                item: action.vehicle,
            })
        );
    }
};

const fillWithWater: Handler = async ({ action, game, extensions, actor }) => {
    if (action.is(Fill) && !action.item && action.container.is(Bottle)) {
        const room = game.here();
        const places = [actor?.vehicle(), room];
        const waterIsHere = places
            .flatMap((place) => place?.contents() || [])
            .some(canUseToFillBottle);

        if (waterIsHere) {
            return extensions.replaceAction(
                new Fill({
                    item: game.ent(QuantityOfWater),
                    container: action.container,
                })
            );
        }
    }
};

const fillBottle: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(PutIn) || action.is(Pour) || action.is(Fill)) &&
        action.item &&
        canUseToFillBottle(action.item) &&
        action.container?.is(Bottle)
    ) {
        const water = game.ent(QuantityOfWater);
        const bottle = action.container;
        if (!bottle.isOpen()) {
            await runner.doOutput('The bottle is closed.');
        } else if (bottle.contains(water)) {
            await runner.doOutput("It's already in the bottle.");
        } else {
            await runner.doOutput('The bottle is now full of water.');
            water.moveTo(bottle);
            await maybeToggleWellPosition(game, runner);
        }
        return Action.complete();
    }
};

async function maybeToggleWellPosition(game: Game, runner: Runner) {
    const circularRoom = game.ent(CircularRoom);
    const player = game.ent(Player);
    const location = player.location();
    let destination;
    let directionOfMotion;
    const water = game.ent(QuantityOfWater);
    const bucket = game.ent(Bucket);
    if (location.isEqualTo(circularRoom)) {
        directionOfMotion = 'rises';
        destination = game.ent(TopOfWell);
        if (!bucket.contains(water)) return;
    } else {
        directionOfMotion = 'descends';
        destination = circularRoom;
        if (bucket.contains(water)) return;
    }
    // TODO decide whether I want the requirement that the player is in the bucket... :)
    if (bucket.location()?.isEqualTo(player.location())) {
        await runner.doOutput(
            `The bucket ${directionOfMotion} and comes to a stop.`
        );
    }
    if (bucket.contains(player)) {
        await game.applyAction(
            runner,
            new SpecialEnter({ room: destination }),
            player
        );
    } else {
        bucket.moveTo(destination);
        if (location.is(CircularRoom)) {
            // TODO this is my addition...
            await runner.doOutput(
                `You must feel like you're at the bottom of a well.`
            );
        }
    }
}

const pourWater: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(PutIn) || action.is(Pour)) &&
        action.item.is(QuantityOfWater) &&
        !action.container?.is(Bottle)
    ) {
        const { container, item: water } = action;
        const player = game.ent(Player);
        const vehicle = container?.isVehicle() ? container : player.vehicle();
        if (vehicle) {
            water.moveTo(vehicle);
            await runner.doOutput(
                `There is now a puddle in the bottom of ${vehicle.the()}.`
            );
            await maybeToggleWellPosition(game, runner);
        } else {
            water.moveTo(undefined);
            if (container) {
                await runner.doOutput(
                    `The water leaks out of ${container.the()} and evaporates immediately.`
                );
            } else {
                await runner.doOutput(
                    'The water spills to the floor and evaporates immediately.'
                );
            }
        }
        return Action.complete();
    }
};

const pourWaterOn: Handler = async ({ action, runner }) => {
    if (action.is(PourOn) && action.item.is(QuantityOfWater)) {
        const { item: water, surface } = action;
        if (surface.isItem() && surface.isFlammable() && surface.isAflame()) {
            water.moveTo(undefined);
            if (surface.is(Torch)) {
                await runner.doOutput(
                    'The water evaporates before it gets close.'
                );
            } else if (surface.container()?.is(BalloonReceptacle)) {
                await runner.doOutput(
                    `The water enters but cannot stop ${surface.the()} from burning.`
                );
            } else {
                await runner.doOutput(`${surface.The()} is extinguished.`);
                surface.state.isAflame = false;
            }
            return Action.complete();
        }
    }
};

// After some time, the water in the bucket evaporates.
const evaporateWater: Handler = async ({ action, game, runner }) => {
    if (action.is(SpecialTimerTick)) {
        const bucket = game.ent(Bucket);
        const water = game.ent(QuantityOfWater);
        if (bucket.timeUntilWaterEvaporates() > 0) {
            if (!bucket.contains(water)) {
                bucket.setTimeUntilWaterEvaporates(0);
            } else {
                bucket.decrementTimeUntilWaterEvaporates();
                if (bucket.timeUntilWaterEvaporates() === 0) {
                    water.moveTo(undefined);
                    await maybeToggleWellPosition(game, runner);
                }
            }
        } else if (bucket.contains(water)) {
            bucket.setTimeUntilWaterEvaporates(100);
        }
        return Action.incomplete();
    }
};
