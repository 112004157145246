import { Verb, Parse, Parser, Value } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { DrinkUnresolved, drinkUnresolvedHandler } from './DrinkUnresolved';

export class Drink extends Action {
    id = '~drink';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [drinkHandler, drinkUnresolvedHandler],
            parser,
            verbs: [
                new Verb('drink'),
                new Verb('sip'),
                new Verb('imbibe'),
                new Verb('swallow'),
            ],
            prepositions: [],
        };
    }
}

export const drinkHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(Drink)) return undefined;
    const { item } = action;
    if (item.isItem() && item.isLiquid()) {
        const container = item.container();
        if (await game.reach(runner, item, actor, { silent: true })) {
            if (container) {
                await game.tryTake(runner, container, actor);
            }
            item.moveTo(undefined);
            await runner.doOutput(
                'Thank you very much. I was rather thirsty (from all this talking, probably).'
            );
        } else {
            await runner.doOutput("I'd like to, but I can't get to it.");
        }
    } else {
        await runner.doOutput('How can I drink that?');
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, DrinkUnresolved> =>
    Parse.words(Drink.ability().verbs)
        .before(Parse.whitespace())
        .chain((_verb) =>
            Parse.target(game.lexicon).map(
                (item) => new DrinkUnresolved({ item })
            )
        );
