import { Room, RoomState } from '../Room';
import { Passage } from '../Passage';
import { Entity } from '../../game';
import { Direction, SpecialDirection } from '../../../parse';
import {
    EastLadder,
    EastMarbleWall,
    EastSandstoneWall,
    NorthMarbleWall,
    NorthSandstoneWall,
    SouthMarbleWall,
    SouthSandstoneWall,
    WestLadder,
    WestMarbleWall,
    WestSandstoneWall,
} from '../../items';
import { puzzleRoomAtPosition } from './utils';
import { listify } from '../../utils';
import {
    EMPTY_SYMBOL,
    MARBLE_SYMBOL,
    PLAYER_SYMBOL,
    SANDSTONE_SYMBOL,
    UNKNOWN_SYMBOL,
} from '../../constants';
import { RoomInAPuzzle11 } from './RoomInAPuzzle11';

interface RoomInAPuzzleState extends RoomState {
    containsSandstone: boolean;
    hasWestLadder: boolean;
    hasEastLadder: boolean;
}

export function offsetForDirection(
    direction: SpecialDirection | Direction
): { row: number; column: number } | undefined {
    switch (direction) {
        case Direction.North:
            return { row: -1, column: 0 };
        case Direction.South:
            return { row: 1, column: 0 };
        case Direction.East:
            return { row: 0, column: 1 };
        case Direction.West:
            return { row: 0, column: -1 };
        case Direction.Northeast:
            return { row: -1, column: 1 };
        case Direction.Northwest:
            return { row: -1, column: -1 };
        case Direction.Southeast:
            return { row: 1, column: 1 };
        case Direction.Southwest:
            return { row: 1, column: -1 };
    }
}

export abstract class RoomInAPuzzle extends Room<RoomInAPuzzleState> {
    abstract row(): number;

    abstract column(): number;

    name(): string {
        return 'Room in a Puzzle';
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [];
    }

    featureDescriptions(): string {
        return '';
    }

    description(): string {
        const northRoom = this.roomInDirection(Direction.North);
        const eastRoom = this.roomInDirection(Direction.East);
        const southRoom = this.roomInDirection(Direction.South);
        const westRoom = this.roomInDirection(Direction.West);
        const marble = [];
        const sandstone = [];
        if (northRoom === undefined) {
            marble.push('north');
        } else if (northRoom.containsSandstone()) {
            sandstone.push('north');
        }
        if (eastRoom === undefined) {
            marble.push('east');
        } else if (eastRoom.containsSandstone()) {
            sandstone.push('east');
        }
        if (southRoom === undefined) {
            marble.push('south');
        } else if (southRoom.containsSandstone()) {
            sandstone.push('south');
        }
        if (westRoom === undefined) {
            marble.push('west');
        } else if (westRoom.containsSandstone()) {
            sandstone.push('west');
        }
        let description = `This is a small square room`;
        if (marble.length === 0 && sandstone.length === 0) {
            description += '.';
        } else {
            description += ' bounded';
            if (marble.length > 0) {
                const a = marble.length === 1 ? 'a ' : '';
                const s = marble.length === 1 ? '' : 's';
                description += ` to the ${listify(
                    marble
                )} with ${a}marble wall${s}`;
            }
            if (sandstone.length > 0) {
                if (marble.length > 0) {
                    description += ' and';
                }
                const a = sandstone.length === 1 ? 'a ' : '';
                const s = sandstone.length === 1 ? '' : 's';
                description += ` to the ${listify(
                    sandstone
                )} with ${a}sandstone wall${s}`;
            }
            description += '.';
        }
        description += this.featureDescriptions();

        const isBeginning = !this.game.ent(RoomInAPuzzle11).hasBeenVisited();
        if (!isBeginning) {
            description += `\n${this.puzzleMap()}`;
        }
        return description;
    }

    symbolForOffset(row: number, column: number) {
        if (row === 0 && column === 0) {
            return PLAYER_SYMBOL;
        }
        if (Math.abs(row) === 1 && Math.abs(column) === 1) {
            const diag1 = this.roomAtOffset({ row, column: 0 });
            const diag2 = this.roomAtOffset({ row: 0, column });
            if (
                (!diag1 || diag1.containsSandstone()) &&
                (!diag2 || diag2?.containsSandstone())
            ) {
                return UNKNOWN_SYMBOL;
            }
        }
        const room = this.roomAtOffset({ row, column });
        if (!room) {
            return MARBLE_SYMBOL;
        }
        if (room.containsSandstone()) {
            return SANDSTONE_SYMBOL;
        }
        return EMPTY_SYMBOL;
    }

    puzzleMap() {
        const symbols = [];
        for (let row = -1; row <= 1; row++) {
            for (let column = -1; column <= 1; column++) {
                symbols.push(this.symbolForOffset(row, column));
            }
        }
        return `Your position is as follows:
           ${symbols[0]} ${symbols[1]} ${symbols[2]}
      West ${symbols[3]} ${symbols[4]} ${symbols[5]} East
           ${symbols[6]} ${symbols[7]} ${symbols[8]}`;
    }

    containsSandstone() {
        return this.state.containsSandstone;
    }

    setContainsSandstone(containsSandstone: boolean) {
        this.state.containsSandstone = containsSandstone;
    }

    hasWestLadder() {
        return this.state.hasWestLadder;
    }

    hasEastLadder() {
        return this.state.hasEastLadder;
    }

    setHasWestLadder(hasWestLadder: boolean) {
        this.state.hasWestLadder = hasWestLadder;
    }

    setHasEastLadder(hasEastLadder: boolean) {
        this.state.hasEastLadder = hasEastLadder;
    }

    globalObjects(): Entity[] {
        const objects = [...super.globalObjects()];
        const northRoom = this.roomInDirection(Direction.North);
        const eastRoom = this.roomInDirection(Direction.East);
        const southRoom = this.roomInDirection(Direction.South);
        const westRoom = this.roomInDirection(Direction.West);
        if (northRoom === undefined) {
            objects.push(this.game.ent(NorthMarbleWall));
        } else if (northRoom.containsSandstone()) {
            objects.push(this.game.ent(NorthSandstoneWall));
        }
        if (eastRoom === undefined) {
            objects.push(this.game.ent(EastMarbleWall));
        } else if (eastRoom.containsSandstone()) {
            objects.push(this.game.ent(EastSandstoneWall));
        }
        if (southRoom === undefined) {
            objects.push(this.game.ent(SouthMarbleWall));
        } else if (southRoom.containsSandstone()) {
            objects.push(this.game.ent(SouthSandstoneWall));
        }
        if (westRoom === undefined) {
            objects.push(this.game.ent(WestMarbleWall));
        } else if (westRoom.containsSandstone()) {
            objects.push(this.game.ent(WestSandstoneWall));
        }
        if (westRoom?.hasEastLadder()) {
            objects.push(this.game.ent(EastLadder));
        }
        if (eastRoom?.hasWestLadder()) {
            objects.push(this.game.ent(WestLadder));
        }
        return objects;
    }

    roomInDirection(direction: Direction) {
        const offset = offsetForDirection(direction);
        if (!offset) {
            return undefined;
        }
        return this.roomAtOffset(offset);
    }

    roomAtOffset(offset: { row: number; column: number }) {
        return puzzleRoomAtPosition(
            this.game,
            this.row() + offset.row,
            this.column() + offset.column
        );
    }

    hasWalls() {
        return false;
    }
}
