import { Noun, Adjective } from '../../../parse';
import { makeContainer, makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Take } from '../../abilities';
import { TreasureRoom } from '../../rooms';
import { Thief } from '../../actors';

export class Chalice extends makeTreasure(makeContainer(makeTakeable(Item))) {
    static spec(): EntitySpec<Chalice> {
        return {
            ref: 'chalice',
            constructor: Chalice,
            initial: {
                hasBeenTaken: false,
                contents: [],
            },
            nouns: [
                new Noun('chalice'),
                new Noun('chalices', { plural: true }),
                new Noun('silver', { collective: true }),
                new Noun('cup'),
                new Noun('cups', { plural: true }),
                new Noun('goblet'),
                new Noun('goblets', { plural: true }),
            ],
            adjectives: [
                new Adjective('silver'),
                new Adjective('intricately engraved'),
                new Adjective('intricately-engraved'),
            ],
            handlers: [handleTakeChalice],
        };
    }

    ref() {
        return Chalice.spec().ref;
    }

    name() {
        return 'silver chalice';
    }

    description() {
        return 'There is a silver chalice, intricately engraved, here.';
    }

    size() {
        return 10;
    }

    totalCapacity(): number {
        return 5;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 10;
    }

    nouns(): Noun[] {
        return Chalice.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Chalice.spec().adjectives;
    }
}

const handleTakeChalice: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Take) && action.item.is(Chalice) && actor) {
        if (
            game.locateEntity(actor)?.is(TreasureRoom) &&
            game.ent(Thief).isFighting()
        ) {
            await runner.doOutput(
                "Realizing just in time that you'd be stabbed in the back " +
                    'if you attempted to take the chalice, you return to the fray.'
            );
            return Action.complete();
        }
    }
};
