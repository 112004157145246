import { Reference } from '../index';
import { Room } from '../../rooms';
import { Game } from '../game';
import { Container, Examinable, Item } from '../../items/Item';
import { VOWELS } from '../../constants';
import { Actor } from '../../actors';
import { Adjective, Noun } from '../../../parse';
import { EntityConstructor } from './Constructor';

export interface EntityState {}

export abstract class Entity<S extends EntityState = EntityState> {
    game: Game;

    state: S;

    abstract ref(): string;

    constructor(game: Game, state: S) {
        this.game = game;
        this.state = state;
    }

    miscellaneousScore(): number {
        return 0;
    }

    is<S, T extends Entity<S>>(ActionClass: EntityConstructor<T>): this is T {
        return this instanceof ActionClass;
    }

    like<S, T extends Entity<S>>(ActionClass: EntityConstructor<T>): boolean {
        return this instanceof ActionClass;
    }

    abstract name(): string;

    abstract description(): string;

    indefiniteArticle(): string {
        return VOWELS.includes(this.name().toLowerCase()[0]) ? 'an' : 'a';
    }

    abstract nouns(): Noun[];

    abstract adjectives(): Adjective[];

    sharedNouns(): Noun[] {
        const nouns = [];
        if (this.isItem()) {
            nouns.push(new Noun('thing'));
            nouns.push(new Noun('things', { plural: true }));
            if (this.isTakeable()) {
                nouns.push(new Noun('item'));
                nouns.push(new Noun('items', { plural: true }));
                nouns.push(new Noun('possession'));
                nouns.push(new Noun('possessions', { plural: true }));
                nouns.push(new Noun('stuff', { collective: true }));
            }
        }

        if (this.isItem() && this.isTreasure()) {
            nouns.push(new Noun('valuables', { plural: true }));
            nouns.push(new Noun('treasure'));
            nouns.push(new Noun('treasures', { plural: true }));
        }

        if (this.isItem() && this.isFood()) {
            nouns.push(new Noun('food', { collective: true }));
        }

        return nouns;
    }

    get(ref: Reference): Entity {
        return this.game.get(ref);
    }

    toString() {
        return this.name();
    }

    isEqualTo(other: Entity) {
        return this.ref() === other.ref();
    }

    isLit(): boolean {
        if ((this.isRoom() || this.isItem()) && this.isNaturallyLit()) {
            return true;
        }
        const contentsAreLit =
            (this.isActor() ||
                this.isRoom() ||
                (this.isItem() && this.isContainer())) &&
            this.contents().some((item) => item.isLit());
        if (this.isRoom() || this.isActor()) {
            return contentsAreLit;
        }
        return (
            this.isItem() &&
            this.isContainer() &&
            this.canSeeInto() &&
            contentsAreLit
        );
    }

    isRoom(): this is Room {
        return false;
    }

    isItem(): this is Item {
        return false;
    }

    isActor(): this is Actor {
        return false;
    }

    isExaminable(): this is Examinable {
        return false;
    }

    get id() {
        return this.constructor.name;
    }

    the(): string {
        return `the ${this}`;
    }

    The(): string {
        return `The ${this}`;
    }

    Name(): string {
        return `${this.name().charAt(0).toUpperCase()}${this.name().slice(1)}`;
    }

    an(): string {
        return `${this.indefiniteArticle()} ${this}`;
    }

    An(): string {
        return `A${this.an().slice(1)}`;
    }

    moveTo(destination: Container | Room | Actor | undefined) {
        const source = this.container();
        if (source) {
            source.removeEntity(this);
        }
        if (destination) {
            destination.addEntity(this);
        }
        this.cachedContainer = destination;
    }

    cachedContainer: (Container | Room | Actor) | null | undefined = null;

    container(): (Entity & (Container | Room | Actor)) | undefined {
        if (this.cachedContainer === null) {
            this.cachedContainer = this.game.findEntity(this);
        }
        return this.cachedContainer as
            | (Entity & (Container | Room | Actor))
            | undefined;
    }

    ent<T extends Entity>(c: EntityConstructor<T>): T {
        return this.game.ent(c);
    }
}
