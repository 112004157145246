import { DeepCanyon, Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { Boat, Bolt, Coffin, ReservoirWater } from '../../items';
import { DeepRavine } from '../DeepRavine';
import { Reservoir } from '../Reservoir';
import { StreamView } from '../StreamView';
import { Player } from '../../actors';

export class ReservoirSouth extends Room {
    static spec(): EntitySpec<ReservoirSouth> {
        return {
            ref: 'reservoir-south',
            constructor: ReservoirSouth,
            initial: {
                contents: [ReservoirWater.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return ReservoirSouth.spec().ref;
    }

    name(): string {
        return 'Reservoir South';
    }

    description(): string {
        let description;
        if (this.game.ent(Bolt).isPowered()) {
            description =
                'You are in a long room, to the north of which was ' +
                'formerly a reservoir. However, with the water level lowered, ' +
                'there is merely a wide stream running through the center of the room.';
        } else {
            description =
                '\nYou are in a long room on the south shore of a large reservoir.';
        }
        description +=
            '\nThere is a western exit, a passageway south, and ' +
            'a steep pathway climbing up along the edge of a cliff.';
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        const lowTideCondition = () => this.game.ent(Bolt).isPowered();
        return [
            // TODO
            new Passage({
                via: Direction.South,
                to: DeepRavine.spec().ref,
                condition: () =>
                    !this.game.ent(Player).hasItem(this.game.ent(Coffin)),
                message: 'The passage is too steep for carrying the coffin.',
            }),
            new Passage({
                via: Direction.Up,
                to: DeepCanyon.spec().ref,
                condition: () =>
                    !this.game.ent(Player).hasItem(this.game.ent(Coffin)),
                message: 'The stairs are too steep for carrying the coffin.',
            }),
            new Passage({ via: Direction.West, to: StreamView.spec().ref }),
            // new Passage({
            //     via: Direction.Cross,
            //     to: WoodenTunnel.spec().ref,
            //     condition: lowTideCondition,
            //     message: '',
            // }),
            new Passage({
                via: SpecialDirection.Launch,
                to: Reservoir.spec().ref,
                condition: () => !lowTideCondition(),
                message:
                    'With the water level lowered, the reservoir is not suitable for watercraft.',
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: Direction.North,
                to: Reservoir.spec().ref,
                condition: lowTideCondition,
                message: 'You are not equipped for swimming.',
            }),
        ];
    }
}
