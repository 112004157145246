import { Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { MaintenanceRoom } from '../../rooms';
import { Plug } from '../../abilities';
import { Gunk } from '../Gunk';

export class DamLeak extends Item {
    static spec(): EntitySpec<DamLeak> {
        return {
            ref: 'dam-leak',
            constructor: DamLeak,
            initial: {},
            nouns: [
                new Noun('leak'),
                new Noun('leaks', { collective: true }),
                new Noun('drip'),
                new Noun('drips', { collective: true }),
                new Noun('hole'),
                new Noun('holes', { collective: true }),
            ],
            adjectives: [],
            handlers: [plugLeak],
        };
    }

    isHidden() {
        return !this.game.ent(MaintenanceRoom).isLeaky();
    }

    ref() {
        return DamLeak.spec().ref;
    }

    name(): string {
        return 'leak';
    }

    description(): string {
        return '';
    }

    nouns() {
        return DamLeak.spec().nouns;
    }

    adjectives() {
        return DamLeak.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const plugLeak: Handler = async ({ action, runner, game }) => {
    if (action.is(Plug) && action.item.is(DamLeak)) {
        if (action.tool.is(Gunk)) {
            await runner.doOutput(
                'By some miracle of elven technology, you have managed to stop the leak in the dam.'
            );
            game.ent(MaintenanceRoom).state.isLeaky = false;
        } else {
            await runner.doOutput(`With ${action.tool.an()}?`);
        }
        return Action.complete();
    }
};

// TODO maybe add -- put the putty back in the room, and if you take it, the leak starts again...
