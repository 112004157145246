import {
    EastGuardianNarrowRoom,
    EastNarrowRoom2,
    Passage,
    Room,
    WestGuardianNarrowRoom,
    WestNarrowRoom2,
} from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { GuardianHallway } from '../GuardianHallway';
import { Hallway2 } from '../Hallway2';
import {
    enterPassage,
    getHallwayDescription,
    GUARD_STRING,
    hallwayGlobalObjects,
    hallwayPassage,
} from '../InsideMirror/mirror_utils';
import { Guardians } from '../../items';

export class Hallway3 extends Room {
    static spec(): EntitySpec<Hallway3> {
        return {
            ref: 'hallway-3',
            constructor: Hallway3,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Hallway3.spec().ref;
    }

    name(): string {
        return 'Hallway';
    }

    description(): string {
        return getHallwayDescription(
            this.game,
            this.game.ent(GuardianHallway),
            this.game.ent(Hallway2),
            `Somewhat to the north${GUARD_STRING}`,
            undefined,
            true
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    globalObjects(): Entity[] {
        return [
            ...super.globalObjects(),
            ...hallwayGlobalObjects(this),
            this.game.ent(Guardians),
        ];
    }

    passages(): Passage[] {
        return [
            hallwayPassage({
                to: this.game.ent(GuardianHallway),
                via: Direction.North,
            }),
            hallwayPassage({
                to: this.game.ent(Hallway2),
                via: Direction.South,
            }),
            hallwayPassage({
                to: this.game.ent(EastGuardianNarrowRoom),
                via: Direction.Northeast,
            }),
            hallwayPassage({
                to: this.game.ent(WestGuardianNarrowRoom),
                via: Direction.Northwest,
            }),
            hallwayPassage({
                to: this.game.ent(EastNarrowRoom2),
                via: Direction.Southeast,
            }),
            hallwayPassage({
                to: this.game.ent(WestNarrowRoom2),
                via: Direction.Southwest,
            }),
            enterPassage({ from: this }),
        ];
    }
}
