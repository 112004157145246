import { EntitySpec } from '../../game';
import { RoomInAPuzzle } from '../RoomInAPuzzle11';
import { GoldCard } from '../../items';

export class RoomInAPuzzle44 extends RoomInAPuzzle {
    static spec(): EntitySpec<RoomInAPuzzle44> {
        return {
            ref: 'room-in-a-puzzle-4-4',
            constructor: RoomInAPuzzle44,
            initial: {
                containsSandstone: true,
                hasWestLadder: false,
                hasEastLadder: false,
                contents: [GoldCard.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    row() {
        return 4;
    }

    column() {
        return 4;
    }

    ref() {
        return RoomInAPuzzle44.spec().ref;
    }

    featureDescriptions(): string {
        return '\nThe center of the floor here is noticeably depressed.';
    }
}
