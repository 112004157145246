import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { PotOfGold, Rainbow } from '../../items';
import { CanyonBottom } from '../CanyonBottom';
import { OnTheRainbow } from '../OnTheRainbow';

export class EndOfTheRainbow extends Room {
    static spec(): EntitySpec<EndOfTheRainbow> {
        return {
            ref: 'end-of-the-rainbow',
            constructor: EndOfTheRainbow,
            initial: {
                contents: [PotOfGold.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return EndOfTheRainbow.spec().ref;
    }

    name(): string {
        return 'End of the Rainbow';
    }

    description(): string {
        return (
            'You are on a small, rocky beach on the continuation of the ' +
            'Frigid River past the Falls. The beach is narrow due to the ' +
            'presence of the White Cliffs. The river canyon opens here and ' +
            'sunlight shines in from above. A rainbow crosses over the falls ' +
            'to the west and a narrow path continues to the southeast.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.Up, Direction.Northwest, Direction.West],
                to: OnTheRainbow.spec().ref,
                condition: () => this.game.ent(Rainbow).isSolid(),
            }),
            new Passage({
                via: Direction.Southeast,
                to: CanyonBottom.spec().ref,
            }),
            new Passage({
                via: SpecialDirection.Launch,
                message: 'The sharp rocks endanger your boat.',
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
