import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { EastCorridor } from '../EastCorridor';
import { WestCorridor } from '../WestCorridor';
import { Parapet } from '../Parapet';
import { PrisonCell } from '../PrisonCell';
import { CellDoor } from '../../items';
import { DungeonMaster } from '../../actors';

export class NorthCorridor extends Room {
    static spec(): EntitySpec<NorthCorridor> {
        return {
            ref: 'north-corridor',
            constructor: NorthCorridor,
            initial: {
                contents: [CellDoor.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return NorthCorridor.spec().ref;
    }

    name(): string {
        return 'North Corridor';
    }

    description(): string {
        const openClose = this.game.ent(CellDoor).isOpen() ? 'open' : 'closed';
        return (
            'This is a large east-west corridor which opens onto a northern ' +
            'parapet at its center. You can see flames and smoke as you peer towards ' +
            'the parapet. The corridor turns south at its east and west ends, ' +
            'and due south is a massive wooden door. In the door is a small window barred with iron. ' +
            `The door is ${openClose}.`
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.East,
                to: EastCorridor.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: WestCorridor.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                to: Parapet.spec().ref,
            }),
            new Passage({
                via: [Direction.South, SpecialDirection.In],
                condition: () => this.game.ent(CellDoor).isOpen(),
                to: PrisonCell.spec().ref,
                message: 'The cell door is closed.',
            }),
        ];
    }

    visibleObjects(): Entity[] {
        const objects = [...super.visibleObjects()];
        const dungeonMaster = this.game.ent(DungeonMaster);
        if (this.game.ent(Parapet).contains(dungeonMaster)) {
            objects.push(dungeonMaster);
        }
        return objects;
    }
}
