import { Passage, Room } from '..';
import { MAZE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Maze3 } from '../Maze3';
import { Maze6 } from '../Maze6';
import { DeadEnd2 } from '../DeadEnd2';
import { BagOfCoins, BrokenLantern, RustyKnife, Skeleton } from '../../items';
import { SkeletonKeys } from '../../items/SkeletonKeys';

export class Maze5 extends Room {
    static spec(): EntitySpec<Maze5> {
        return {
            ref: 'maze-5',
            constructor: Maze5,
            initial: {
                contents: [
                    Skeleton.spec().ref,
                    BrokenLantern.spec().ref,
                    BagOfCoins.spec().ref,
                    SkeletonKeys.spec().ref,
                    RustyKnife.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Maze5.spec().ref;
    }

    name(): string {
        return 'Maze';
    }

    description(): string {
        return MAZE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: DeadEnd2.spec().ref }),
            new Passage({ via: Direction.North, to: Maze3.spec().ref }),
            new Passage({ via: Direction.Southwest, to: Maze6.spec().ref }),
        ];
    }

    // TODO
    isPartOfMaze() {
        return true;
    }
}
