import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { TrollRoom } from '../TrollRoom';
import { Studio } from '../Studio';
import { WestOfChasm } from '../WestOfChasm';

export class NorthSouthCrawlway extends Room {
    static spec(): EntitySpec<NorthSouthCrawlway> {
        return {
            ref: 'north-south-crawlway',
            constructor: NorthSouthCrawlway,
            initial: {
                contents: [
                    // TODO maybe add "hole"
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return NorthSouthCrawlway.spec().ref;
    }

    name(): string {
        return 'North-South Crawlway';
    }

    description(): string {
        return (
            'This is a north-south crawlway; a passage goes to the east also. ' +
            'There is a hole above, but it provides no opportunities for climbing.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: TrollRoom.spec().ref }),
            new Passage({ via: Direction.North, to: WestOfChasm.spec().ref }),
            new Passage({ via: Direction.South, to: Studio.spec().ref }),
            new Passage({
                via: Direction.Up,
                message: 'Not even a human fly could get up it.',
            }),
        ];
    }
}
