import { Passage, RoomInAPuzzle12, RoomInAPuzzle21, SideRoom } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { SmallSquareRoom } from '../SmallSquareRoom';
import { Go, Move, Push, SpecialEnter } from '../../abilities';
import { offsetForDirection, RoomInAPuzzle } from './RoomInAPuzzle';
import { puzzleRoomAtPosition } from './utils';
import {
    EastMarbleWall,
    EastSandstoneWall,
    NorthMarbleWall,
    NorthSandstoneWall,
    SouthMarbleWall,
    SouthSandstoneWall,
    SteelDoor,
    WarningNote,
    WestMarbleWall,
    WestSandstoneWall,
} from '../../items';
import { COMPLEX_ARCHITECTURE } from '../../constants';

export class RoomInAPuzzle11 extends RoomInAPuzzle {
    static spec(): EntitySpec<RoomInAPuzzle11> {
        return {
            ref: 'room-in-a-puzzle-1-1',
            constructor: RoomInAPuzzle11,
            initial: {
                containsSandstone: false,
                hasWestLadder: false,
                hasEastLadder: false,
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [
                goInPuzzle,
                pushSandstoneWall,
                enterPuzzleRoom,
                pushMarbleWall,
            ],
        };
    }

    row() {
        return 1;
    }

    column() {
        return 1;
    }

    ref() {
        return RoomInAPuzzle11.spec().ref;
    }

    featureDescriptions(): string {
        return ' In the ceiling above you is a large circular opening.';
    }

    passages(): Passage[] {
        // TODO this is to not create a sink for the thief
        return [
            new Passage({
                via: Direction.Up,
                to: SmallSquareRoom.spec().ref,
            }),
        ];
    }
}

const goInPuzzle: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Go)) {
        const room = actor?.location();
        if (room && room instanceof RoomInAPuzzle) {
            if (action.direction === Direction.Up) {
                if (
                    room.roomInDirection(Direction.West)?.hasEastLadder() ||
                    room.roomInDirection(Direction.East)?.hasWestLadder()
                ) {
                    if (room.row() === 1 && room.column() === 1) {
                        await runner.doOutput(
                            'With the help of the ladder, you exit the puzzle.'
                        );
                        await game.applyAction(
                            runner,
                            new SpecialEnter({
                                room: game.ent(SmallSquareRoom),
                            })
                        );
                    } else {
                        await runner.doOutput(
                            'You hit your head on the ceiling and fall off the ladder.'
                        );
                    }
                    return Action.complete();
                }
                if (room.row() === 1 && room.column() === 1) {
                    await runner.doOutput(
                        'The exit is too far above your head.'
                    );
                    return Action.complete();
                }
                return Action.incomplete();
            }
            const door = game.ent(SteelDoor);
            if (action.direction === Direction.West && room.contains(door)) {
                if (door.isOpen()) {
                    await game.applyAction(
                        runner,
                        new SpecialEnter({ room: game.ent(SideRoom) })
                    );
                } else {
                    await runner.doOutput('The metal door bars the way.');
                }
                return Action.complete();
            }
            const offset = offsetForDirection(action.direction);
            if (offset) {
                const intermediateRoom1 = puzzleRoomAtPosition(
                    game,
                    room.row(),
                    room.column() + offset.column
                );
                const intermediateRoom2 = puzzleRoomAtPosition(
                    game,
                    room.row() + offset.row,
                    room.column()
                );
                const newRoom = puzzleRoomAtPosition(
                    game,
                    room.row() + offset.row,
                    room.column() + offset.column
                );
                if (
                    (!intermediateRoom1 ||
                        intermediateRoom1.containsSandstone()) &&
                    (!intermediateRoom2 ||
                        intermediateRoom2.containsSandstone())
                ) {
                    await runner.doOutput('Your way is blocked.');
                } else if (newRoom === undefined) {
                    await runner.doOutput('There is a marble wall there.');
                } else if (newRoom.containsSandstone()) {
                    await runner.doOutput('There is a sandstone wall there.');
                } else {
                    await game.applyAction(
                        runner,
                        new SpecialEnter({ room: newRoom })
                    );
                }
                return Action.complete();
            }
        }
    }
};

const pushSandstoneWall: Handler = async ({ action, runner, actor, game }) => {
    if (
        (action.is(Push) || action.is(Move)) &&
        (action.item.like(WestSandstoneWall) ||
            action.item.like(EastSandstoneWall) ||
            action.item.like(NorthSandstoneWall) ||
            action.item.like(SouthSandstoneWall))
    ) {
        let row;
        let column;
        if (action.item.is(WestSandstoneWall)) {
            row = 0;
            column = -1;
        } else if (action.item.is(EastSandstoneWall)) {
            row = 0;
            column = 1;
        } else if (action.item.is(NorthSandstoneWall)) {
            row = -1;
            column = 0;
        } else {
            row = 1;
            column = 0;
        }
        const currentRoom = actor?.location();
        if (currentRoom && currentRoom instanceof RoomInAPuzzle) {
            const room1 = currentRoom.roomAtOffset({ row, column });
            const room2 = currentRoom.roomAtOffset({
                row: row * 2,
                column: column * 2,
            });
            if (!room2 || room2.containsSandstone()) {
                await runner.doOutput('The wall does not budge.');
            } else if (room1) {
                room2.setContainsSandstone(true);
                room1.setContainsSandstone(false);
                room2.setHasEastLadder(room1.hasEastLadder());
                room2.setHasWestLadder(room1.hasWestLadder());
                room1.setHasEastLadder(false);
                room1.setHasWestLadder(false);

                const isStarted =
                    game.ent(RoomInAPuzzle12).hasBeenVisited() ||
                    game.ent(RoomInAPuzzle21).hasBeenVisited();
                if (isStarted) {
                    await runner.doOutput(
                        `The wall slides forward and you follow it to this position:`
                    );
                } else {
                    await runner.doOutput(
                        `The wall slides forward and you follow it.`
                    );
                    await runner.doOutput(COMPLEX_ARCHITECTURE);
                }
                await game.applyAction(
                    runner,
                    new SpecialEnter({ room: room1 })
                );
            }
        }
        return Action.complete();
    }
};

const enterPuzzleRoom: Handler = async ({
    action,
    runner,
    extensions,
    game,
}) => {
    if (action.is(SpecialEnter) && action.room instanceof RoomInAPuzzle) {
        const isBeginning = !game.ent(RoomInAPuzzle11).hasBeenVisited();
        const visited = action.room.hasBeenVisited();
        await extensions.deferHandling();
        if (isBeginning) {
            if (game.ent(WarningNote).hasBeenTaken()) {
                await runner.doOutput('It appears the thief was correct.');
            }
        } else if (visited) {
            await runner.doOutput(action.room.puzzleMap());
        }
        return Action.complete({ withConsequence: false });
    }
};

const pushMarbleWall: Handler = async ({ action, runner }) => {
    if (
        action.is(Push) &&
        (action.item.like(WestMarbleWall) ||
            action.item.like(EastMarbleWall) ||
            action.item.like(NorthMarbleWall) ||
            action.item.like(SouthMarbleWall))
    ) {
        await runner.doOutput('The wall does not budge.');
        return Action.complete();
    }
};
