import { EntitySpec } from '../../game';
import { RoomInAPuzzle } from '../RoomInAPuzzle11';

export class RoomInAPuzzle22 extends RoomInAPuzzle {
    static spec(): EntitySpec<RoomInAPuzzle22> {
        return {
            ref: 'room-in-a-puzzle-2-2',
            constructor: RoomInAPuzzle22,
            initial: {
                containsSandstone: false,
                hasWestLadder: false,
                hasEastLadder: false,
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    row() {
        return 2;
    }

    column() {
        return 2;
    }

    ref() {
        return RoomInAPuzzle22.spec().ref;
    }
}
