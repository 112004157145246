import { Action, Entity, Handler } from '../../game';
import { SpecialAction } from '../SpecialAction';
import { Take } from '../Take';

export class SpecialTryTake extends SpecialAction {
    id = '*try-take';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }
}

export const specialTryTakeHandler: Handler = async ({
    game,
    action,
    actor,
    runner,
}) => {
    if (!action.is(SpecialTryTake)) return;
    const { item } = action;
    if (actor?.isActor() && !actor.hasItem(item)) {
        if (item.isItem() && item.shouldTryToTake()) {
            await game.applyAction(
                runner,
                new Take({ item, silent: true, container: undefined }),
                actor
            );

            if (actor.hasItem(action.item)) {
                await runner.doOutput(
                    `First taking the ${action.item}: Taken.`
                );
                return Action.complete({ withConsequence: false });
            }
        }
    }
    return Action.complete({ withConsequence: false });
};
