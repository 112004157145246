import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Board, PutIn, Throw } from '../../abilities';
import { CircularRoom } from '../../rooms';

export class Well extends makeContainer(Item) {
    static spec(): EntitySpec<Well> {
        return {
            ref: 'well',
            constructor: Well,
            initial: {
                contents: [],
            },
            nouns: [new Noun('well'), new Noun('wells')],
            adjectives: [new Adjective('magic')],
            handlers: [putInWell, boardWell],
        };
    }

    totalCapacity(): undefined {
        return undefined;
    }

    ref() {
        return Well.spec().ref;
    }

    name(): string {
        return 'well';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed() {
        return false;
    }

    nouns() {
        return Well.spec().nouns;
    }

    adjectives() {
        return Well.spec().adjectives;
    }
}

const putInWell: Handler = async ({ action, runner, actor, game }) => {
    if (
        (action.is(PutIn) && action.container.is(Well)) ||
        (action.is(Throw) && action.enemy?.is(Well))
    ) {
        action.item.moveTo(game.ent(CircularRoom));
        await runner.doOutput(
            `${action.item.The()} is now sitting at the bottom of the well.`
        );
        return Action.complete();
    }
};

const boardWell: Handler = async ({ action, runner, game }) => {
    if (action.is(Board) && action.vehicle.is(Well)) {
        if (game.here().is(CircularRoom)) {
            await runner.doOutput('You are already in the well.');
        } else {
            await runner.doOutput("It's a long way down...");
        }
        return Action.complete();
    }
};
