import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class No extends makeDescribable(Action) {
    id = 'no';

    description(game: Game): string {
        return 'no';
    }

    static ability(): Ability {
        const verbs = [new Verb('no'), new Verb('nope'), new Verb('nah')];
        return {
            handlers: [noHandler],
            parser: () => Parse.words(verbs).into(new No()),
            verbs,
            prepositions: [],
        };
    }
}

export const noHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(No)) return;
    if (game.yesNo) {
        await game.yesNo(false);
    } else {
        await runner.doOutput('No.');
    }
    return Action.complete({ withConsequence: false });
};
