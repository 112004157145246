import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class SapphireBracelet extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<SapphireBracelet> {
        return {
            ref: 'sapphire-bracelet',
            constructor: SapphireBracelet,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('sapphires', { collective: true }),
                new Noun('jewelry', { collective: true }),
                new Noun('bracelet'),
                new Noun('bracelets', { plural: true }),
            ],
            adjectives: [
                new Adjective('sapphire'),
                new Adjective('sapphire-encrusted'),
                new Adjective('sapphire encrusted'),
                new Adjective('jeweled'),
            ],
        };
    }

    ref() {
        return SapphireBracelet.spec().ref;
    }

    name() {
        return 'sapphire bracelet';
    }

    description() {
        return 'There is a sapphire-encrusted bracelet here.';
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 5;
    }

    scoreInCase(): number {
        return 3;
    }

    nouns(): Noun[] {
        return SapphireBracelet.spec().nouns;
    }

    adjectives(): Adjective[] {
        return SapphireBracelet.spec().adjectives;
    }
}
