import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { Container, Item } from '../../items/Item';
import { PourUnresolved, pourUnresolvedHandler } from './PourUnresolved';
import { Player } from '../../actors';

export class Pour extends Action {
    id = '~pour';

    item: Entity;

    container: (Item & Container) | undefined;

    constructor({
        item,
        container,
    }: {
        item: Entity;
        container: (Item & Container) | undefined;
    }) {
        super();
        this.item = item;
        this.container = container;
    }

    static ability(): Ability {
        return {
            handlers: [pourHandler, pourUnresolvedHandler],
            parser,
            verbs: [
                new Verb('pour'),
                new Verb('pour out'),
                new Verb('spill'),
                new Verb('dump'),
                new Verb('dump out'),
            ],
            prepositions: [
                new Preposition('out'),
                new Preposition('in'),
                new Preposition('into'),
                new Preposition('in to'),
                new Preposition('inside'),
            ],
        };
    }
}

export const pourHandler: Handler = async ({ action, runner, actor, game }) => {
    if (!action.is(Pour) || actor === undefined) return;
    const { item, container } = action;

    if (!item.isItem() || !item.isLiquid()) {
        await runner.doOutput("You can't pour that!");
    } else {
        const destination = container || game.ent(Player).container();
        if (destination) {
            item.moveTo(destination);
            await runner.doOutput('Done.');
        }
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, PourUnresolved> => {
    const ability = Pour.ability();
    const pour = Parse.words(ability.verbs);
    const pourObject = pour.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const pourObjectIn = pourObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((container) => [item, container])
    );
    return Parse.any(
        // pour out
        pour.into(
            new PourUnresolved({ item: undefined, container: undefined })
        ),
        // pour out the water
        pourObject.map(
            (item) => new PourUnresolved({ item, container: undefined })
        ),
        // dump the water in bucket
        pourObjectIn.map(
            ([item, container]) =>
                new PourUnresolved({
                    item,
                    container,
                })
        )
    );
};
