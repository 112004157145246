import { LoadError } from '../game/Runner';
import { Action, Handler } from '../game';
import { SpecialLoadError } from '../abilities';

export const loadError: Handler = async ({ action, runner }) => {
    if (!action.is(SpecialLoadError)) return;

    if (action.error === LoadError.NotFound) {
        await runner.doOutput('No saved progress could be found.');
    } else if (action.error === LoadError.Invalid) {
        await runner.doOutput('Your save data got corrupted. Ho hum.');
    } else {
        await runner.doOutput('Something went wrong.');
    }
    return Action.complete({ withConsequence: false });
};
