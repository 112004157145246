import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Ruby extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<Ruby> {
        return {
            ref: 'ruby',
            constructor: Ruby,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('ruby'),
                new Noun('rubies', { plural: true }),
                new Noun('jewel'),
                new Noun('gem'),
                new Noun('gems', { plural: true }),
                new Noun('gemstone'),
                new Noun('gemstones', { plural: true }),
            ],
            adjectives: [new Adjective('moby')],
        };
    }

    ref() {
        return Ruby.spec().ref;
    }

    name() {
        return 'ruby';
    }

    description() {
        return 'There is a moby ruby lying here.';
    }

    initialDescription() {
        return 'On the floor lies a moby ruby.';
    }

    scoreOnTake(): number {
        return 15;
    }

    scoreInCase(): number {
        return 8;
    }

    nouns(): Noun[] {
        return Ruby.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Ruby.spec().adjectives;
    }
}
