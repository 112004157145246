import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { ThrowUnresolved, throwUnresolvedHandler } from './ThrowUnresolved';
import { Kill } from '../Kill';

export class Throw extends Action {
    id = '~throw';

    item: Entity;

    enemy: Entity | undefined;

    constructor({ item, enemy }: { item: Entity; enemy: Entity | undefined }) {
        super();
        this.item = item;
        this.enemy = enemy;
    }

    static ability(): Ability {
        return {
            handlers: [throwHandler, throwUnresolvedHandler],
            parser,
            verbs: [
                new Verb('throw'),
                new Verb('yeet'),
                new Verb('hurl'),
                new Verb('chuck'),
                new Verb('toss'),
            ],
            prepositions: [new Preposition('at'), new Preposition('through')],
        };
    }
}

export const throwHandler: Handler = async ({
    action,
    actor,
    runner,
    game,
}) => {
    if (!action.is(Throw) || actor === undefined) return;

    const { item, enemy } = action;

    const room = game.locateEntity(actor);

    if (enemy === undefined) {
        await runner.doOutput('Thrown.');
    } else {
        // TODO is this how weapon throwing is supposed to work?
        await game.applyAction(runner, new Kill({ weapon: item, enemy }));
    }

    if (room) {
        item.moveTo(room);
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, ThrowUnresolved> => {
    const ability = Throw.ability();
    const chuck = Parse.words(ability.verbs);
    const chuckThing = chuck.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const chuckThingAt = chuckThing.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((enemy) => [item, enemy])
    );
    return Parse.any(
        // throw
        chuck.into(new ThrowUnresolved({ item: undefined, enemy: undefined })),
        // throw sword
        chuckThing.map(
            (item) => new ThrowUnresolved({ item, enemy: undefined })
        ),
        // throw sword at troll
        chuckThingAt.map(
            ([item, enemy]) => new ThrowUnresolved({ item, enemy })
        )
    );
};
