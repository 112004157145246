import { Token, Value, Whitespace } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that parses a single node of whitespace. If using the `tokenize`
 * method from `./node.ts`, all consecutive whitespace should already be
 * coalesced into one `Whitespace` node.
 *
 * ```
 * whitespace().match(tokenize("   "))
 * ```
 */
export class WhitespaceParser extends Parser<Value, Whitespace> {
    *match(tokens: Token<Value>[]): Generator<Match<Value, Whitespace>> {
        const token = tokens[0];
        if (token && token.value instanceof Whitespace) {
            // TODO should the source actually be [token]?
            yield new Match(new Token(token.value, []), tokens.slice(1));
        }
    }
}
