import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeOpenable, makeTakeable } from '../../game/Entity';
import { EntitySpec } from '../../game';
import { Garlic } from '../Garlic';
import { Lunch } from '../Lunch';

export class Sack extends makeTakeable(makeOpenable(makeContainer(Item))) {
    static spec(): EntitySpec<Sack> {
        return {
            ref: 'sack',
            constructor: Sack,
            initial: {
                contents: [Garlic.spec().ref, Lunch.spec().ref],
                isOpen: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('sack'),
                new Noun('bag'),
                new Noun('pouch'),
                new Noun('sacks', { plural: true }),
                new Noun('bags', { plural: true }),
                new Noun('pouches', { plural: true }),
            ],
            adjectives: [new Adjective('brown'), new Adjective('elongated')],
        };
    }

    ref() {
        return Sack.spec().ref;
    }

    name(): string {
        return 'brown sack';
    }

    description(): string {
        return 'A brown sack is here.';
    }

    initialDescription(): string {
        return 'On the table is an elongated brown sack, smelling of hot peppers.';
    }

    totalCapacity(): number {
        return 15;
    }

    size(): number {
        return 3;
    }

    canBeOpened(): boolean {
        return true;
    }

    canSeeInto(): boolean {
        return this.isOpen() || this.isTransparent();
    }

    nouns() {
        return Sack.spec().nouns;
    }

    adjectives() {
        return Sack.spec().adjectives;
    }
}
