import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that tries to parse using a given `parser`, but falls back to
 * a `null` valued node. Yields all of the matches of `parser` first.
 *
 * ```
 * option(word("cat")).parse(tokenize(""))
 * ```
 */
export class OptionParser<I, O> extends Parser<I, O | undefined> {
    parser: Parser<I, O>;

    constructor(parser: Parser<I, O>) {
        super();
        this.parser = parser;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, O | undefined>> {
        for (const match of this.parser.match(tokens)) {
            yield match;
        }
        yield new Match(new Token(undefined, []), tokens);
    }
}
