import {
    Article,
    Conjunction,
    Lexicon as BaseLexicon,
    Noun,
    Preposition,
    Pronoun,
} from '../../parse';
import { ALL_WORDS } from '../constants';

export class Lexicon extends BaseLexicon {
    constructor() {
        super();

        // Add global articles
        this.addArticle(new Article('the'));
        this.addArticle(new Article('a'));
        this.addArticle(new Article('an'));

        this.addPronoun(new Pronoun('it'));
        this.addPronoun(new Pronoun('them', { plural: true }));

        for (const pronoun of ALL_WORDS) {
            this.addPronoun(pronoun);
        }

        this.addConjunction(new Conjunction('and'));
        this.addConjunction(new Conjunction('except'));
        this.addConjunction(new Conjunction('but'));

        this.addNoun(new Noun('item'));
        this.addNoun(new Noun('items', { plural: true }));
        this.addNoun(new Noun('thing'));
        this.addNoun(new Noun('things', { plural: true }));
        this.addNoun(new Noun('stuff', { collective: true }));

        this.addNoun(new Noun('valuables', { plural: true }));
        this.addNoun(new Noun('treasure'));
        this.addNoun(new Noun('treasures', { plural: true }));

        this.addNoun(new Noun('possession'));
        this.addNoun(new Noun('possessions', { plural: true }));

        this.addPreposition(new Preposition('then'));
    }
}
