import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Lubricate } from './Lubricate';
import { UnresolvedAction } from '../UnresolvedAction';

export class LubricateUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'lubricate';

    item: Target | undefined;

    tool: Target | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Target | undefined;
        tool: Target | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'lubricate something';
        }
        if (this.tool === undefined) {
            return `lubricate ${targetDescription(game, this.item)}`;
        }
        return `lubricate ${targetDescription(
            game,
            this.item
        )} with ${targetDescription(game, this.tool)}`;
    }
}

export const lubricateUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(LubricateUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new LubricateUnresolved({ item, tool: undefined }),
        missing: () => 'Lubricate what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to lubricate, ${opt}?`,
        condition: (item) => item.isItem() && item.shouldTryToOpen(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    const { item: tool } = await game.resolve(runner, action.tool, actor, {
        partial: (tool) => new LubricateUnresolved({ item: action.item, tool }),
        missing: () => `With what would you like to lubricate ${item.the()}?`,
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to use to lubricate ${item.the()}, ${opt}?`,
        condition: (item) => item.isItem() && item.isTool(),
    });

    if (!tool) {
        return Action.complete({ withConsequence: false });
    }

    if (tool && !(await game.have(runner, tool, actor)))
        return Action.complete();

    return await game.applyAction(runner, new Lubricate({ item, tool }));
};
