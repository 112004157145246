import { Noun } from '../../../parse';
import { Item } from '../Item';
import { Entity, makeContainer } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import {
    Close,
    LookIn,
    Lower,
    Open,
    PutIn,
    Raise,
    Take,
} from '../../abilities';
import { OakDoorKeyhole2 } from '../OakDoorKeyhole2';
import { Player } from '../../actors';
import { DrearyRoom } from '../../rooms/DrearyRoom';
import { OakDoorLid2 } from '../OakDoorLid2';
import { OakDoorLid1 } from '../OakDoorLid1';
import { OakDoor } from '../OakDoor';
import { WelcomeMat } from '../WelcomeMat';
import { RustyKey } from '../RustyKey';
import { TinyRoom } from '../../rooms/TinyRoom';
import { Screwdriver } from '../Screwdriver';
import { SkeletonKeys } from '../SkeletonKeys';
import { Stick } from '..';

export class OakDoorKeyhole1 extends makeContainer(Item) {
    static spec(): EntitySpec<OakDoorKeyhole1> {
        return {
            ref: 'oak-door-keyhole-1',
            constructor: OakDoorKeyhole1,
            initial: {
                contents: [],
            },
            nouns: [
                new Noun('keyhole'),
                new Noun('key hole'),
                new Noun('hole'),
                new Noun('keyholes', { plural: true }),
                new Noun('key holes', { plural: true }),
                new Noun('holes', { plural: true }),
            ],
            adjectives: [],
            handlers: [
                putInKeyhole,
                lookInKeyhole,
                takeFromKeyhole,
                openCloseLid,
            ],
        };
    }

    ref() {
        return OakDoorKeyhole1.spec().ref;
    }

    name(): string {
        return 'keyhole';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    description(): string {
        return '';
    }

    totalCapacity(): number {
        return 0;
    }

    nouns() {
        return OakDoorKeyhole1.spec().nouns;
    }

    adjectives() {
        return OakDoorKeyhole1.spec().adjectives;
    }
}

const putInKeyhole: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(PutIn) &&
        (action.container.is(OakDoorKeyhole1) ||
            action.container.is(OakDoorKeyhole2)) &&
        actor?.is(Player)
    ) {
        const room = actor.location();
        const otherRoom = room.is(DrearyRoom)
            ? game.ent(TinyRoom)
            : game.ent(DrearyRoom);
        const keyhole = action.container;
        const otherKeyhole = room.is(DrearyRoom)
            ? game.ent(OakDoorKeyhole1)
            : game.ent(OakDoorKeyhole2);
        const lid = room.is(DrearyRoom)
            ? game.ent(OakDoorLid2)
            : game.ent(OakDoorLid1);
        const oakDoor = game.ent(OakDoor);
        const mat = game.ent(WelcomeMat);
        if (lid.isOpen()) {
            if (!keyhole.isEmpty()) {
                await runner.doOutput('The keyhole is blocked.');
            } else if (isKeyholeItem(action.item)) {
                if (!otherKeyhole.isEmpty()) {
                    const key = otherKeyhole.contents()[0] as Item;
                    key.moveTo(otherRoom);
                    if (oakDoor.itemUnderneath()?.is(WelcomeMat)) {
                        mat.setItemOnTop(key);
                    } else {
                        key.moveTo(otherRoom);
                    }
                    action.item.moveTo(keyhole);
                    await runner.doOutput(
                        'There is a faint noise from behind the door and a small cloud of dust rises from beneath it.'
                    );
                } else {
                    action.item.moveTo(keyhole);
                    await runner.doOutput('Done.');
                }
            } else {
                await runner.doOutput(`${action.item.The()} doesn't fit.`);
            }
        } else {
            await runner.doOutput('The lid is in the way.');
        }
        return Action.complete();
    }
};

function isKeyholeItem(item: Entity): boolean {
    return (
        item.is(Screwdriver) ||
        item.is(SkeletonKeys) ||
        item.is(Stick) ||
        item.is(RustyKey)
    );
}

const lookInKeyhole: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(LookIn) &&
        (action.item.is(OakDoorKeyhole1) || action.item.is(OakDoorKeyhole2)) &&
        actor?.is(Player)
    ) {
        const room = actor.location();
        const otherRoom = room.is(DrearyRoom)
            ? game.ent(TinyRoom)
            : game.ent(DrearyRoom);
        if (
            game.ent(OakDoorLid1).isOpen() &&
            game.ent(OakDoorLid1).isOpen() &&
            game.ent(OakDoorKeyhole1).isEmpty() &&
            game.ent(OakDoorKeyhole2).isEmpty() &&
            otherRoom.isLit()
        ) {
            await runner.doOutput(
                'You can barely make out a lighted room at the other end.'
            );
        } else {
            await runner.doOutput('No light can be seen through the keyhole.');
        }
        return Action.complete();
    }
};

const takeFromKeyhole: Handler = async ({
    action,
    runner,
    game,
    extensions,
}) => {
    let container;
    if (
        action.is(Take) &&
        (container = action.item.container()) &&
        (container.is(OakDoorKeyhole1) || container.is(OakDoorKeyhole2))
    ) {
        await extensions.deferHandling();
        await runner.doOutput('The lid falls to cover the keyhole.');
        const lid = container.is(OakDoorKeyhole1)
            ? game.ent(OakDoorLid1)
            : game.ent(OakDoorLid2);
        lid.setIsOpen(false);
        return Action.complete();
    }
};

const openCloseLid: Handler = async ({ action, runner, actor, game }) => {
    if (
        (action.is(Open) || action.is(Raise)) &&
        (action.item.is(OakDoorLid1) || action.item.is(OakDoorLid2))
    ) {
        const { item: lid } = action;
        lid.setIsOpen(true);
        await runner.doOutput('The lid is open.');
        return Action.complete();
    }

    if (
        (action.is(Close) || action.is(Lower)) &&
        (action.item.is(OakDoorLid1) || action.item.is(OakDoorLid2))
    ) {
        const { item: lid } = action;
        const keyhole = lid.is(OakDoorLid1)
            ? game.ent(OakDoorKeyhole1)
            : game.ent(OakDoorKeyhole2);
        if (!keyhole.isEmpty()) {
            await runner.doOutput('The keyhole is occupied.');
        } else {
            lid.setIsOpen(true);
            await runner.doOutput('The lid covers the keyhole.');
        }
        return Action.complete();
    }
};
