import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Win extends makeDescribable(Action) {
    id = 'win';

    description(game: Game): string {
        return 'win';
    }

    static ability(): Ability {
        const verbs = [new Verb('win')];
        return {
            handlers: [winHandler],
            parser: () => Parse.words(verbs).into(new Win()),
            verbs,
            prepositions: [],
        };
    }
}

export const winHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Win)) return;
    await runner.doOutput('Naturally!');
    return Action.complete();
};
