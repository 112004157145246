import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { Bolt } from '../Bolt';

export class TrunkOfJewels extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<TrunkOfJewels> {
        return {
            ref: 'trunk-of-jewels',
            constructor: TrunkOfJewels,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('jewels', { collective: true }),
                new Noun('trunk'),
                new Noun('trunks', { plural: true }),
                new Noun('trunk of jewels'),
                new Noun('trunks of jewels', { plural: true }),
            ],
            adjectives: [new Adjective('old')],
        };
    }

    isHidden() {
        // TODO shouldn't this apply to any item that is in the reservoir?
        return !this.hasBeenTaken() && !this.game.ent(Bolt).isPowered();
    }

    ref() {
        return TrunkOfJewels.spec().ref;
    }

    name() {
        return 'trunk of jewels';
    }

    description() {
        return 'There is an old trunk here, bulging with assorted jewels.';
    }

    initialDescription() {
        return 'Lying half buried in the mud is an old trunk, bulging with jewels.';
    }

    size() {
        return 35;
    }

    scoreOnTake(): number {
        return 15;
    }

    scoreInCase(): number {
        return 8;
    }

    nouns(): Noun[] {
        return TrunkOfJewels.spec().nouns;
    }

    adjectives(): Adjective[] {
        return TrunkOfJewels.spec().adjectives;
    }
}
