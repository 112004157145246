import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { AncientChasm } from '../AncientChasm';

export class AncientDeadEnd2 extends Room {
    static spec(): EntitySpec<AncientDeadEnd2> {
        return {
            ref: 'ancient-dead-end-2',
            constructor: AncientDeadEnd2,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return AncientDeadEnd2.spec().ref;
    }

    name(): string {
        return 'Dead End';
    }

    description(): string {
        return 'You have reached a dead end.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.East,
                to: AncientChasm.spec().ref,
            }),
        ];
    }
}
