import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Tie } from './Tie';
import { UnresolvedAction } from '../UnresolvedAction';

export class TieUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'tie';

    fixture: Target | undefined;

    item: Target | undefined;

    constructor({
        fixture,
        item,
    }: {
        fixture: Target | undefined;
        item: Target | undefined;
    }) {
        super();
        this.fixture = fixture;
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'tie something';
        }
        if (this.fixture === undefined) {
            return `tie ${targetDescription(game, this.item)} to something`;
        }
        return `tie ${targetDescription(
            game,
            this.item
        )} to ${targetDescription(game, this.fixture)}`;
    }
}

export const tieUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(TieUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new TieUnresolved({ fixture: action.fixture, item }),
        missing: () => 'What would you like to tie?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to tie, ${opt}?`,
        condition: (item) => item.isItem(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (action.fixture) {
        const { item: fixture } = await game.resolve(
            runner,
            action.fixture,
            actor,
            {
                partial: (fixture) =>
                    new TieUnresolved({ fixture, item: action.item }),
                missing: () => `To what would you like to tie ${item.the()}?`,
                ambiguous: (desc, opt) =>
                    `To which ${desc} would you like to tie ${item.the()}, ${opt}?`,
                condition: (item) => item.isItem() && item.isWeapon(),
            }
        );

        if (fixture === undefined)
            return Action.complete({ withConsequence: false });

        if (!(await game.reach(runner, fixture, actor))) {
            return Action.complete();
        }

        return game.applyAction(runner, new Tie({ item, fixture }));
    }

    return game.applyAction(runner, new Tie({ item, fixture: undefined }));
};
