import { Verb, Parse, Parser, Value } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import {
    TurnOffUnresolved,
    turnOffUnresolvedHandler,
} from './TurnOffUnresolved';

export class TurnOff extends Action {
    id = '~turn-off';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [turnOffHandler, turnOffUnresolvedHandler],
            parser,
            verbs: [
                new Verb('turn off'),
                new Verb('turn'),
                new Verb('unpower'),
                new Verb('power off'),
                // TODO another action for extinguish / put out / unlight
            ],
            prepositions: [],
        };
    }
}

export const turnOffHandler: Handler = async ({ action, runner }) => {
    if (!action.is(TurnOff)) return undefined;
    await runner.doOutput("You can't turn that off.");
    return Action.complete();
};

const parser = (game: Game): Parser<Value, TurnOffUnresolved> => {
    const turnOff = Parse.any(
        Parse.phrase('turn off'),
        Parse.phrase('power off'),
        Parse.word('unpower')
    );
    const target = Parse.target(game.lexicon);
    return Parse.any(
        turnOff.map(() => new TurnOffUnresolved({ item: undefined })),
        turnOff
            .beforeX(Parse.option(Parse.whitespace().beforeX(target)))
            .map((item) => new TurnOffUnresolved({ item })),
        Parse.words([new Verb('power'), new Verb('turn')])
            .beforeX(
                Parse.option(Parse.whitespace().beforeX(target)).before(
                    Parse.whitespace().then(Parse.word('off'))
                )
            )
            .map((item) => new TurnOffUnresolved({ item }))
    );
};
