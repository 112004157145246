import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeReadable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class WarningCard extends makeFlammable(
    makeTakeable(makeReadable(Item))
) {
    static spec(): EntitySpec<WarningCard> {
        return {
            ref: 'warning-card',
            constructor: WarningCard,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('papers', { plural: true }),
                new Noun('paper'),
                new Noun('card'),
                new Noun('cards', { plural: true }),
                new Noun('warning'),
                new Noun('warnings', { plural: true }),
                new Noun('piece of paper'),
                new Noun('pieces of paper', { plural: true }),
                new Noun('slip of paper'),
                new Noun('slips of paper', { plural: true }),
            ],
            adjectives: [new Adjective('warning')],
        };
    }

    ref() {
        return WarningCard.spec().ref;
    }

    name() {
        return 'card';
    }

    description() {
        return 'There is a card with writing on it here.';
    }

    size() {
        return 1;
    }

    text() {
        return NOTE_TEXT;
    }

    nouns(): Noun[] {
        return WarningCard.spec().nouns;
    }

    adjectives(): Adjective[] {
        return WarningCard.spec().adjectives;
    }
}

const NOTE_TEXT = `  Warning:
    This room was constructed over very weak rock strata. Detonation of explosives in this room is strictly prohibited!
    Frobozz Magic Cave Company
      per M. Agrippa, foreman`;
