import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { PlugUnresolved, plugUnresolvedHandler } from './PlugUnresolved';

export class Plug extends Action {
    id = '~plug';

    item: Entity;

    tool: Entity;

    constructor({ item, tool }: { item: Entity; tool: Entity }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [plugHandler, plugUnresolvedHandler],
            parser,
            verbs: [
                new Verb('plug'),
                new Verb('patch'),
                new Verb('mend'),
                new Verb('glue'),
            ],
            prepositions: [],
        };
    }
}

export const plugHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Plug)) return;
    await runner.doOutput('This has no effect.');
    return Action.complete();
};

const parser = (game: Game): Parser<Value, PlugUnresolved> => {
    const plug = Parse.words(Plug.ability().verbs);
    const plugObject = plug.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const plugObjectWith = plugObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // plug
        plug.into(new PlugUnresolved({ item: undefined, tool: undefined })),
        // plug box
        plugObject.map((item) => new PlugUnresolved({ item, tool: undefined })),
        // plug egg with hammer
        plugObjectWith.map(
            ([item, tool]) =>
                new PlugUnresolved({
                    item,
                    tool,
                })
        )
    );
};
