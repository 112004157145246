import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { KnockUnresolved, knockUnresolvedHandler } from './KnockUnresolved';

export class Knock extends Action {
    id = '~knock';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [knockHandler, knockUnresolvedHandler],
            parser,
            verbs: [
                new Verb('knock on'),
                new Verb('knock'),
                new Verb('knock at'),
                new Verb('rap on'),
                new Verb('rap at'),
            ],
            prepositions: [],
        };
    }
}

export const knockHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Knock)) return;
    const { item } = action;
    // TODO add isDoor: true to all the doors
    if (item.isItem() && item.isDoor()) {
        await runner.doOutput("I don't think that anybody's home.");
    } else {
        await runner.doOutput(`Why knock on ${item.an()}?`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, KnockUnresolved> => {
    const knock = Parse.words(Knock.ability().verbs);
    const knockObject = knock.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // knock
        knock.into(new KnockUnresolved({ item: undefined })),
        // knock on the door
        knockObject.map((item) => new KnockUnresolved({ item }))
    );
};
