import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that never consumes any nodes, and yields exactly one match with
 * no value (`null`).
 *
 * ```
 * nothing().match(tokenize("")) // yields null
 * ```
 */
export class NothingParser<T> extends Parser<T, null> {
    *match(tokens: Token<T>[]): Generator<Match<T, null>> {
        yield new Match(new Token(null, []), tokens);
    }
}
