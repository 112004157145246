import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { UntieUnresolved, untieUnresolvedHandler } from './UntieUnresolved';

export class Untie extends Action {
    id = '~untie';

    fixture: Entity | undefined;

    item: Entity;

    constructor({
        fixture,
        item,
    }: {
        fixture: Entity | undefined;
        item: Entity;
    }) {
        super();
        this.fixture = fixture;
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [untieHandler, untieUnresolvedHandler],
            parser,
            verbs: [
                new Verb('untie'),
                new Verb('loose'),
                new Verb('unfasten'),
                new Verb('unknot'),
                new Verb('unbind'),
            ],
            prepositions: [new Preposition('from')],
        };
    }
}

export const untieHandler: Handler = async ({ action, actor, runner }) => {
    if (!action.is(Untie) || actor === undefined) return;

    const { item } = action;

    if (!item.isItem() || !item.canBeFastened()) {
        await runner.doOutput('This cannot be tied, so it cannot be untied!');
    } else {
        await runner.doOutput("I don't think so.");
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, UntieUnresolved> => {
    const ability = Untie.ability();
    const untie = Parse.words(ability.verbs);
    const untieEnemy = untie.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const untieEnemyWith = untieEnemy.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((container) => [item, container])
    );
    return Parse.any(
        // untie
        untie.into(
            new UntieUnresolved({ fixture: undefined, item: undefined })
        ),
        // untie rope
        untieEnemy.map(
            (item) => new UntieUnresolved({ fixture: undefined, item })
        ),
        // untie rope to railing
        untieEnemyWith.map(
            ([item, fixture]) => new UntieUnresolved({ fixture, item })
        )
    );
};
