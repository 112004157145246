import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class RedPalantir extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<RedPalantir> {
        return {
            ref: 'red-palantir',
            constructor: RedPalantir,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('sphere'),
                new Noun('spheres', { plural: true }),
                new Noun('ball'),
                new Noun('balls', { plural: true }),
                new Noun('palantir'),
                new Noun('palantirs', { plural: true }),
                new Noun('stone'),
                new Noun('stones', { plural: true }),
            ],
            adjectives: [
                new Adjective('seeing'),
                new Adjective('beautiful'),
                new Adjective('red'),
                new Adjective('crystal'),
                new Adjective('glass'),
            ],
            handlers: [],
        };
    }

    ref() {
        return RedPalantir.spec().ref;
    }

    name() {
        return 'red crystal sphere';
    }

    description() {
        return 'There is red crystal sphere here.';
    }

    initialDescription() {
        return 'On the floor sits a red crystal sphere.';
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 5;
    }

    nouns(): Noun[] {
        return RedPalantir.spec().nouns;
    }

    adjectives(): Adjective[] {
        return RedPalantir.spec().adjectives;
    }
}
