import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Bug extends makeDescribable(Action) {
    id = 'bug';

    description(game: Game): string {
        return 'report a bug';
    }

    static ability(): Ability {
        const verbs = [new Verb('bug')];
        return {
            handlers: [bugHandler],
            parser: () => Parse.words(verbs).into(new Bug()),
            verbs,
            prepositions: [],
        };
    }
}

export const bugHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Bug)) return;

    // TODO crs actual bug reporting mechanism
    await runner.doOutput(
        'Please forward problem reports by Internet mail to christopher@swenson.org.'
    );
    return Action.complete();
};
