import { Drink } from './Drink';
import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { UnresolvedAction } from '../UnresolvedAction';

export class DrinkUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'drink';

    item: Target;

    constructor({ item }: { item: Target }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        return `drink ${targetDescription(game, this.item)}`;
    }
}

export const drinkUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(DrinkUnresolved)) return;
    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new DrinkUnresolved({ item }),
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to drink, ${opt}?`,
        condition: (item) => item.isItem() && item.isLiquid(),
    });
    if (item === undefined) return Action.complete({ withConsequence: false });

    return await game.applyAction(runner, new Drink({ item }));
};
