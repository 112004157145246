import { Action, Handler } from '../game';
import { SpecialDescribe } from '../abilities';

export const doNotDescribeSelf: Handler = async ({ action, actor }) => {
    // Actors should not describe themselves...
    if (action instanceof SpecialDescribe && actor?.isEqualTo(action.item)) {
        return Action.complete({ withConsequence: false });
    }
    return undefined;
};
