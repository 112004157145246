import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { MirrorRoom2 } from '../MirrorRoom2';

export class WindingPassage extends Room {
    static spec(): EntitySpec<WindingPassage> {
        return {
            ref: 'winding-passage',
            constructor: WindingPassage,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return WindingPassage.spec().ref;
    }

    name(): string {
        return 'Winding Passage';
    }

    description(): string {
        return (
            'This is a winding passage. It seems that there is only an ' +
            'exit on the east end although the whirring from the ' +
            'round room can be heard faintly to the north.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: MirrorRoom2.spec().ref }),
            new Passage({
                via: Direction.North,
                message:
                    'You hear the whir from the round room but can find no entrance.',
            }),
        ];
    }
}
