import React, { ReactNode, useRef, useState } from 'react';
import { LineProps, Terminal } from './Terminal';

interface UseTerminalProps {
    prefix?: string;
    onInput?: (line: string) => void;
}

interface UseTerminalResult {
    onOutput: (message: string, options?: { newLine?: boolean }) => void;
    terminal: ReactNode;
    clearOutput: () => void;
}

const DEFAULT_PREFIX = '○';

export function useTerminal({
    prefix = DEFAULT_PREFIX,
    onInput: doInput,
}: UseTerminalProps): UseTerminalResult {
    const append = useRef(false);
    const [lines, setLines] = useState<LineProps[]>([]);
    const linesRef = useRef(lines);
    linesRef.current = lines;
    const setLinesRef = useRef(setLines);
    setLinesRef.current = setLines;

    const onInput = (line: string) => {
        linesRef.current = linesRef.current.concat({
            text: `\n${prefix} ${line}`,
            isDimmed: false,
            isInput: true,
        });
        setLinesRef.current(linesRef.current);
        doInput && doInput(line);
    };

    const clearOutput = () => {
        linesRef.current = [];
        setLinesRef.current(linesRef.current);
    };

    const onOutput = (
        message: string,
        {
            newLine = true,
            isDimmed = false,
        }: { newLine?: boolean; isDimmed?: boolean } = {}
    ) => {
        if (append.current) {
            const lastLine = linesRef.current[linesRef.current.length - 1];
            linesRef.current = [
                ...linesRef.current.slice(0, -1),
                {
                    text: lastLine.text.concat(message),
                    isDimmed,
                    isInput: false,
                },
            ];
            setLinesRef.current(linesRef.current);
            append.current = false;
        } else {
            linesRef.current = linesRef.current.concat({
                text: message,
                isDimmed,
                isInput: false,
            });
            setLinesRef.current(linesRef.current);
        }
        if (!newLine) {
            append.current = true;
        }
    };

    const terminal = (
        <Terminal prefix={prefix} lines={lines} onInput={onInput} />
    );

    return { terminal, onOutput, clearOutput };
}
