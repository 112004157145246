import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { FightRemarks } from '../../handlers';
import { Knife } from '../Knife';
import { Kill, Poke, SpecialJigsUp, Take, Throw } from '../../abilities';
import { Sword } from '../Sword';

export class RustyKnife extends makeTakeable(Item) {
    static spec(): EntitySpec<RustyKnife> {
        return {
            ref: 'rusty-knife',
            constructor: RustyKnife,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('knife'),
                new Noun('knives', { plural: true }),
                new Noun('blade'),
                new Noun('blades', { plural: true }),
            ],
            adjectives: [
                new Adjective('rusty'),
                new Adjective('rusted'),
                new Adjective('cursed'),
            ],
            handlers: [takeRustyKnife, attackWithRustyKnife],
        };
    }

    ref() {
        return RustyKnife.spec().ref;
    }

    name() {
        return 'rusty knife';
    }

    description() {
        return 'There is a rusty knife here.';
    }

    initialDescription() {
        return 'Beside the skeleton is a rusty knife.';
    }

    nouns(): Noun[] {
        return RustyKnife.spec().nouns;
    }

    adjectives(): Adjective[] {
        return RustyKnife.spec().adjectives;
    }

    isTool(): boolean {
        return true;
    }

    isWeapon(): boolean {
        return true;
    }

    size() {
        return 20;
    }

    fightRemarks(): FightRemarks {
        return this.game.ent(Knife).fightRemarks();
    }
}

const takeRustyKnife: Handler = async ({
    action,
    runner,
    actor,
    game,
    extensions,
}) => {
    if (action.is(Take) && action.item.is(RustyKnife)) {
        await extensions.deferHandling();
        if (actor?.hasItem(game.ent(Sword)) && actor.hasItem(action.item)) {
            await runner.doOutput(
                'As you pick up the rusty knife, your sword gives ' +
                    'a single pulse of blinding blue light.'
            );
        }
        return Action.complete();
    }
};

const attackWithRustyKnife: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(Kill) && action.weapon?.is(RustyKnife)) ||
        (action.is(Throw) && action.item.is(RustyKnife) && action.enemy) ||
        (action.is(Poke) && action.weapon?.is(RustyKnife) && action.enemy)
    ) {
        game.ent(RustyKnife).moveTo(undefined);
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    'As the knife approaches its victim, your mind is ' +
                    'submerged by overmastering will. Slowly, your hand turns, ' +
                    'until the rusty blade is an inch from your neck. ' +
                    'The knife seems to sing as it savagely slits your throat.',
            })
        );
        return Action.complete();
    }
};
