import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { badDoor } from '../utils';

export class FrontDoor extends Item {
    static spec(): EntitySpec<FrontDoor> {
        return {
            ref: 'front-door',
            constructor: FrontDoor,
            initial: {},
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [
                new Adjective('barred'),
                new Adjective('boarded'),
                new Adjective('front'),
            ],
            handlers: [handleFrontDoorOpenClose],
        };
    }

    ref() {
        return FrontDoor.spec().ref;
    }

    name(): string {
        return 'front door';
    }

    description(): string {
        return '';
    }

    nouns() {
        return FrontDoor.spec().nouns;
    }

    adjectives() {
        return FrontDoor.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToOpen(): boolean {
        return true;
    }
}

const handleFrontDoorOpenClose = badDoor({
    item: FrontDoor,
    open: 'The door cannot be opened.',
    close: 'The door is barred.',
});
