import { Noun, Adjective } from '../../../parse';
import { makeExaminable, makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class BagOfCoins extends makeExaminable(
    makeTreasure(makeTakeable(Item))
) {
    static spec(): EntitySpec<BagOfCoins> {
        return {
            ref: 'bag-of-coins',
            constructor: BagOfCoins,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('bag'),
                new Noun('bags', { plural: true }),
                new Noun('coins', { collective: true }),
                new Noun('bag of coins'),
                new Noun('bags of coins', { plural: true }),
            ],
            adjectives: [new Adjective('old'), new Adjective('leather')],
        };
    }

    ref() {
        return BagOfCoins.spec().ref;
    }

    name() {
        return 'bag of coins';
    }

    description() {
        return 'An old leather bag, bulging with coins, is here.';
    }

    size() {
        return 15;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 5;
    }

    nouns(): Noun[] {
        return BagOfCoins.spec().nouns;
    }

    adjectives(): Adjective[] {
        return BagOfCoins.spec().adjectives;
    }

    examineText(): string {
        return (
            'The coins are in an old leather bag with is quite plain ' +
            'considering the large quantity of gold contained within.'
        );
    }
}
