import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeFlammable } from '../../game/Entity';
import { Kill, Light, Mung, Rub, Take, Throw } from '../../abilities';

export class Stove extends makeFlammable(Item) {
    static spec(): EntitySpec<Stove> {
        return {
            ref: 'stove',
            constructor: Stove,
            initial: {
                isAflame: true,
            },
            nouns: [
                new Noun('stove'),
                new Noun('stoves', { plural: true }),
                new Noun('oven'),
                new Noun('ovens', { plural: true }),
            ],
            adjectives: [new Adjective('old'), new Adjective('coal')],
            handlers: [messWithStove, throwAtStove],
        };
    }

    // TODO can be extinguished = false?

    ref() {
        return Stove.spec().ref;
    }

    name(): string {
        return 'old coal stove';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Stove.spec().nouns;
    }

    adjectives() {
        return Stove.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const messWithStove: Handler = async ({ action, runner }) => {
    if (
        ((action.is(Take) || action.is(Rub) || action.is(Mung)) &&
            action.item.is(Stove)) ||
        (action.is(Kill) && action.enemy.is(Stove))
    ) {
        await runner.doOutput('The intense heat of the stove keeps you away.');
        return Action.complete();
    }
};

const throwAtStove: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Throw) &&
        action.enemy?.is(Stove) &&
        action.item.isItem() &&
        action.item.isFlammable()
    ) {
        await game.applyAction(
            runner,
            new Light({ item: action.item, tool: action.enemy })
        );
        return Action.complete();
    }
};
