import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { TurnUnresolved, turnUnresolvedHandler } from './TurnUnresolved';

export class Turn extends Action {
    id = '~turn';

    item: Entity;

    tool: Entity | undefined;

    constructor({ item, tool }: { item: Entity; tool: Entity | undefined }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [turnHandler, turnUnresolvedHandler],
            parser,
            verbs: [new Verb('turn'), new Verb('twist'), new Verb('rotate')],
            prepositions: [],
        };
    }
}

export const turnHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Turn)) return;
    if (!action.item.isItem() || !action.item.canBeTurned()) {
        await runner.doOutput("You can't turn that!");
    } else if (action.tool) {
        if (action.tool.isItem() && action.tool.isTool()) {
            await runner.doOutput(`It doesn't seem to work.`);
        } else {
            await runner.doOutput(
                `You certainly can't turn it with ${action.tool.an()}.`
            );
        }
    } else {
        await runner.doOutput(
            `You certainly can't turn it with your bare hands.`
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, TurnUnresolved> => {
    const turn = Parse.words(Turn.ability().verbs);
    const turnObject = turn.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const turnObjectWith = turnObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // turn
        turn.into(new TurnUnresolved({ item: undefined, tool: undefined })),
        // turn key
        turnObject.map((item) => new TurnUnresolved({ item, tool: undefined })),
        // turn switch with screwdriver
        turnObjectWith.map(
            ([item, tool]) =>
                new TurnUnresolved({
                    item,
                    tool,
                })
        )
    );
};
