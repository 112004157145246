import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Examine, Find } from '../../abilities';

export class Grue extends Item {
    static spec(): EntitySpec<Grue> {
        return {
            ref: 'grue',
            constructor: Grue,
            initial: {},
            nouns: [new Noun('grue'), new Noun('grues', { plural: true })],
            adjectives: [new Adjective('lurking')],
            handlers: [findGrue, examineGrue],
        };
    }

    ref() {
        return Grue.spec().ref;
    }

    name(): string {
        return 'lurking grue';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Grue.spec().nouns;
    }

    adjectives() {
        return Grue.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const findGrue: Handler = async ({ action, runner }) => {
    if (action.is(Find) && action.item.is(Grue)) {
        await runner.doOutput(
            "There is no grue here, but I'm sure there is at least one lurking " +
                "in the darkness nearby. I wouldn't let my light go out if I were you!"
        );
        return Action.complete();
    }
};

const examineGrue: Handler = async ({ action, runner }) => {
    if (action.is(Examine) && action.item.is(Grue)) {
        await runner.doOutput(
            'The grue is a sinister, lurking presence in the dark places of the earth. ' +
                'Its favorite diet is adventurers, but its insatiable appetite is tempered ' +
                'by its fear of light. No grue has ever been seen by the light of day, ' +
                'and few have survived its fearsome jaws to tell the tale.'
        );
        return Action.complete();
    }
};
