import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { LivingRoom } from '../LivingRoom';
import { CyclopsRoom } from '../CyclopsRoom';

export class StrangePassage extends Room {
    static spec(): EntitySpec<StrangePassage> {
        return {
            ref: 'strange-passage',
            constructor: StrangePassage,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    scoreOnEntry(): number {
        return 10;
    }

    ref() {
        return StrangePassage.spec().ref;
    }

    name(): string {
        return 'Strange Passage';
    }

    description(): string {
        return 'This is a long passage. To the south is one entrance. On the east there is an old wooden door, with a large hole in it (about cyclops sized).';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.South, to: CyclopsRoom.spec().ref }),
            new Passage({ via: Direction.East, to: LivingRoom.spec().ref }),
        ];
    }
}
