import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Railing extends Item {
    static spec(): EntitySpec<Railing> {
        return {
            ref: 'railing',
            constructor: Railing,
            initial: {},
            nouns: [
                new Noun('railing'),
                new Noun('railings', { plural: true }),
                new Noun('rail'),
                new Noun('rails', { plural: true }),
            ],
            adjectives: [new Adjective('wood'), new Adjective('wooden')],
        };
    }

    ref() {
        return Railing.spec().ref;
    }

    name(): string {
        return 'wooden railing';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Railing.spec().nouns;
    }

    adjectives() {
        return Railing.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
