import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { UnlockUnresolved, unlockUnresolvedHandler } from './UnlockUnresolved';

export class Unlock extends Action {
    id = '~unlock';

    item: Entity;

    tool: Entity;

    constructor({ item, tool }: { item: Entity; tool: Entity }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [unlockHandler, unlockUnresolvedHandler],
            parser,
            verbs: [new Verb('unlock')],
            prepositions: [],
        };
    }
}

export const unlockHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Unlock)) return;
    await runner.doOutput("It doesn't seem to work.");
    return Action.complete();
};

const parser = (game: Game): Parser<Value, UnlockUnresolved> => {
    const unlock = Parse.words(Unlock.ability().verbs);
    const unlockObject = unlock.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const unlockObjectWith = unlockObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // unlock
        unlock.into(new UnlockUnresolved({ item: undefined, tool: undefined })),
        // unlock box
        unlockObject.map(
            (item) => new UnlockUnresolved({ item, tool: undefined })
        ),
        // unlock egg with hammer
        unlockObjectWith.map(
            ([item, tool]) =>
                new UnlockUnresolved({
                    item,
                    tool,
                })
        )
    );
};
