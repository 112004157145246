import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { SpecialTimerTick } from '../../abilities';
import { NO_TREE } from '../../constants';
import { Action, EntitySpec, Handler } from '../../game';
import { Forest2 } from '../Forest2';
import { Forest3 } from '../Forest3';
import { Player } from '../../actors';
import { GlobalTree } from '../../items';

export class Forest1 extends Room {
    static spec(): EntitySpec<Forest1> {
        return {
            ref: 'forest-1',
            constructor: Forest1,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [birdChirps],
        };
    }

    ref() {
        return Forest1.spec().ref;
    }

    name(): string {
        return 'Forest';
    }

    description(): string {
        return 'This is a forest, with trees in all directions around you.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: Forest1.spec().ref }),
            new Passage({ via: Direction.East, to: Forest3.spec().ref }),
            new Passage({ via: Direction.South, to: Forest2.spec().ref }),
            new Passage({ via: Direction.West, to: Forest1.spec().ref }),
            new Passage({ via: Direction.Up, message: NO_TREE }),
        ];
    }

    isPartOfForest() {
        return true;
    }

    hasWalls() {
        return false;
    }

    globalObjects() {
        return [...super.globalObjects(), this.game.ent(GlobalTree)];
    }
}

const birdChirps: Handler = async ({ action, game, runner }) => {
    if (action.is(SpecialTimerTick)) {
        const player = game.ent(Player);
        const room = player.location();
        if (room.isPartOfForest()) {
            if (player.testLuck(10)) {
                await runner.doOutput(
                    'You hear in the distance the chirping of a song bird.'
                );
            }
        }
        return Action.incomplete();
    }
};
