import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { WHEEE } from '../../constants';

export class Skip extends makeDescribable(Action) {
    id = 'skip';

    description(game: Game): string {
        return 'skip';
    }

    static ability(): Ability {
        const verbs = [new Verb('skip')];
        return {
            handlers: [adventureHandler],
            parser: () => Parse.words(verbs).into(new Skip()),
            verbs,
            prepositions: [],
        };
    }
}

export const adventureHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Skip)) return;
    await runner.doOutput(game.choiceOf(WHEEE));
    return Action.complete();
};
