import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { SpecialTimerTick } from '../SpecialTimerTick';

export class Wait extends makeDescribable(Action) {
    id = 'wait';

    description(game: Game): string {
        return 'wait around';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('wait'),
            new Verb('idle'),
            new Verb('wait around'),
            new Verb('take five'),
            new Verb('stall'),
            new Verb('stall for time'),
        ];
        return {
            handlers: [waitHandler],
            parser: () => Parse.words(verbs).into(new Wait()),
            verbs,
            prepositions: [],
        };
    }
}

export const waitHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Wait)) return;
    await runner.doOutput('Time passes...');
    // This action generates three game ticks: the one from this action, plus
    // two extra ticks.
    for (let i = 0; i < 2; i++) {
        await game.applyAction(runner, new SpecialTimerTick({}));
    }
    return Action.complete();
};
