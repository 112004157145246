import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Turn } from '../../abilities';
import { Wrench } from '../Wrench';
import { Lubricate } from '../../abilities/Lubricate';
import { Gunk } from '../Gunk';

interface BoltState extends ItemState {
    isLocked: boolean;
    isPowered: boolean;
}

export class Bolt extends Item<BoltState> {
    static spec(): EntitySpec<Bolt> {
        return {
            ref: 'bolt',
            constructor: Bolt,
            initial: {
                isLocked: true,
                isPowered: false,
            },
            nouns: [
                new Noun('bolt'),
                new Noun('bolts', { plural: true }),
                new Noun('nut'),
                new Noun('nuts', { plural: true }),
            ],
            adjectives: [new Adjective('metal'), new Adjective('large')],
            handlers: [turnBolt, lubricateBolt],
        };
    }

    ref() {
        return Bolt.spec().ref;
    }

    name(): string {
        return 'bolt';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Bolt.spec().nouns;
    }

    adjectives() {
        return Bolt.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    canBeTurned(): boolean {
        return true;
    }

    isLocked(): boolean {
        return this.state.isLocked;
    }

    isPowered(): boolean {
        return this.state.isPowered;
    }
}

const turnBolt: Handler = async ({ action, runner }) => {
    if (action.is(Turn) && action.item.is(Bolt)) {
        if (!action.tool /* || action.tool.is(Hands) TODO */) {
            await runner.doOutput(
                'Turning the bolt with your bare hands is ineffective.'
            );
        } else if (!action.tool.is(Wrench)) {
            await runner.doOutput(
                `The bolt won't turn using ${action.tool.the()}.`
            );
        } else if (action.item.isLocked()) {
            await runner.doOutput("The bolt won't turn with your best effort.");
        } else {
            action.item.state.isPowered = !action.item.isPowered();
            // TODO
            // game.rooms["reservoir"].water = not game.sluice_gates_open
            // game.rooms["reservoir"].visited = False
            if (action.item.isPowered()) {
                await runner.doOutput(
                    'The sluice gates open and water pours through the dam.'
                );
            } else {
                await runner.doOutput(
                    'The sluice gates close and water starts to collect behind the dam.'
                );
            }
        }
        return Action.complete();
    }
};

const lubricateBolt: Handler = async ({ action, runner }) => {
    if (action.is(Lubricate) && action.item.is(Bolt) && action.tool.is(Gunk)) {
        await runner.doOutput(
            'Hmm. It appears the tube contained glue, not oil. ' +
                "Turning the bolt won't get any easier..."
        );
        return Action.complete();
    }
};
