import { Actor, ActorState, Player } from '../index';
import { Adjective, Noun } from '../../../parse';
import { Action, Handler } from '../../game';
import {
    Drop,
    Eat,
    Give,
    Go,
    Mung,
    Push,
    PutIn,
    Raise,
    Read,
    SpecialMostlyUnderstand,
    SpecialNoParse,
    SpecialUnknownWord,
    Take,
    Throw,
    Jump,
} from '../../abilities';
import { SpecialAction } from '../../abilities/SpecialAction';
import { UnresolvedAction } from '../../abilities/UnresolvedAction';

interface RobotState extends ActorState {}

export class Robot extends Actor<RobotState> {
    static spec() {
        return {
            ref: 'robot',
            constructor: Robot,
            initial: {
                inventory: [],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: 0,
                isEngrossed: false,
            },
            nouns: [
                new Noun('robot'),
                new Noun('robots', { plural: true }),
                new Noun('r2d2'),
                new Noun('c3po'),
                new Noun('robby'),
            ],
            adjectives: [new Adjective('mechanical')],
            handlers: [actLikeARobot, giveToRobot, destroyRobot],
        };
    }

    ref() {
        return Robot.spec().ref;
    }

    name(): string {
        return 'robot';
    }

    description(): string {
        return 'There is a robot here.';
    }

    isVillain(): boolean {
        return false;
    }

    isVictim(): boolean {
        return true;
    }

    nouns(): Noun[] {
        return Robot.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Robot.spec().adjectives;
    }

    isReadyToFight(): boolean {
        return false;
    }

    respondsToCommands(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }
}

const actLikeARobot: Handler = async ({ action, runner, actor, game }) => {
    if (actor?.is(Robot)) {
        if (action.is(Eat)) {
            await runner.doOutput(
                '"I am sorry but that action is difficult for a being with no mouth."'
            );
            return Action.complete({ withConsequence: false });
        }
        if (action.is(Read)) {
            await runner.doOutput(
                '"My vision is not sufficiently acute to read such small type."'
            );
            return Action.complete({ withConsequence: false });
        }
        if (action.is(SpecialNoParse)) {
            await runner.doOutput(
                '"Apologies, but my natural language parser is not sufficiently intricate to understand such a command."'
            );
            return Action.complete({ withConsequence: false });
        }
        if (
            action.is(SpecialMostlyUnderstand) &&
            action.action.isDescribable()
        ) {
            await runner.doOutput(
                `"I only understood you as far as wanting me to ${action.action.description(
                    game
                )}."`
            );
            return Action.complete({ withConsequence: false });
        }
        if (action.is(SpecialUnknownWord)) {
            await runner.doOutput(
                `"My lexicon is extremely limited and does not contain the word '${action.unknownWord}'."`
            );
            return Action.complete({ withConsequence: false });
        }
        if (
            action instanceof SpecialAction ||
            action instanceof UnresolvedAction
        ) {
            return Action.incomplete();
        }
        if (
            action.is(Go) ||
            action.is(Take) ||
            action.is(Drop) ||
            action.is(PutIn) ||
            action.is(Raise) ||
            action.is(Jump) ||
            action.is(Push) ||
            // TODO this just seems unnecessary?
            // action.is(Turn) ||
            action.is(Throw)
        ) {
            await runner.doOutput('"Whirr, buzz, click!"');
            return Action.incomplete();
        }
        await runner.doOutput(
            '"I am only a stupid robot and cannot perform that command."'
        );
        return Action.complete({ withConsequence: false });
    }
};

// TODO add in a message if you try to take the robot...
//      "Please refrain from touching me... my accelerometers are very delicate..."

const giveToRobot: Handler = async ({ action, runner, actor }) => {
    if (
        action.is(Give) &&
        action.recipient.is(Robot) &&
        actor?.is(Player) &&
        actor?.hasItem(action.item)
    ) {
        const { item: gift, recipient: robot } = action;
        gift.moveTo(robot);
        await runner.doOutput(
            `The robot gladly takes ${gift.the()} and nods his head-like appendage in thanks.`
        );

        return Action.complete();
    }
};

const destroyRobot: Handler = async ({ action, runner }) => {
    if ((action.is(Throw) || action.is(Mung)) && action.item.is(Robot)) {
        action.item.moveTo(undefined);
        await runner.doOutput(
            'The robot is injured (being of shoddy construction) and falls ' +
                'to the floor in a pile of garbage, which disintegrates before your eyes.'
        );
        return Action.complete();
    }
};
