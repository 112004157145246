import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { MINE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { CoalMine1 } from '../CoalMine1';
import { LadderTop } from '../LadderTop';
import { CoalMine6 } from '../CoalMine6';
import { CoalMine5 } from '../CoalMine5';

export class CoalMine7 extends Room {
    static spec(): EntitySpec<CoalMine7> {
        return {
            ref: 'coal-mine-7',
            constructor: CoalMine7,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return CoalMine7.spec().ref;
    }

    name(): string {
        return 'Coal Mine';
    }

    description(): string {
        return MINE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: CoalMine1.spec().ref }),
            new Passage({ via: Direction.West, to: CoalMine5.spec().ref }),
            new Passage({ via: Direction.Down, to: LadderTop.spec().ref }),
            new Passage({ via: Direction.South, to: CoalMine6.spec().ref }),
        ];
    }
}
