import { Noun, Adjective } from '../../../parse';
import {
    makeTakeable,
    makeFlammable,
    makeReadable,
    makeContainer,
    makeExaminable,
} from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { DonWoodsStamp } from '../DonWoodsStamp';
import { Request } from '../../abilities';

interface BrochureState extends ItemState {
    timeUntilDelivered: number;
    hasBeenRequested: boolean;
    hasBeenDelivered: boolean;
}

abstract class Base extends Item<BrochureState> {}

export class Brochure extends makeExaminable(
    makeContainer(makeReadable(makeFlammable(makeTakeable(Base))))
) {
    static spec(): EntitySpec<Brochure> {
        return {
            ref: 'brochure',
            constructor: Brochure,
            initial: {
                contents: [DonWoodsStamp.spec().ref],
                isAflame: false,
                hasBeenTaken: false,
                timeUntilDelivered: 0,
                hasBeenRequested: false,
                hasBeenDelivered: false,
            },
            nouns: [
                new Noun('brochure'),
                new Noun('brochures', { plural: true }),
                new Noun('pamphlet'),
                new Noun('pamphlets', { plural: true }),
            ],
            adjectives: [new Adjective('free'), new Adjective('glossy')],
            handlers: [sendForBrochure],
        };
    }

    isContainer() {
        const stamp = this.game.ent(DonWoodsStamp);
        return this.contains(stamp);
    }

    totalCapacity() {
        return 1;
    }

    ref() {
        return Brochure.spec().ref;
    }

    name() {
        return 'free brochure';
    }

    description() {
        return 'There is a large brochure here.';
    }

    initialDescription() {
        return 'In the mailbox is a large brochure.';
    }

    text() {
        return (
            `The mailing label on this glossy brochure ` +
            `from MIT Tech reads:\n${BROCHURE_TEXT}`
        );
    }

    size() {
        return 30;
    }

    nouns(): Noun[] {
        return Brochure.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Brochure.spec().adjectives;
    }

    setTimeUntilDelivered(timeUntilDelivered: number) {
        this.state.timeUntilDelivered = timeUntilDelivered;
    }

    timeUntilDelivered() {
        return this.state.timeUntilDelivered;
    }

    setHasBeenRequested(hasBeenRequested: boolean) {
        this.state.hasBeenRequested = hasBeenRequested;
    }

    hasBeenRequested() {
        return this.state.hasBeenRequested;
    }

    setHasBeenDelivered(hasBeenDelivered: boolean) {
        this.state.hasBeenDelivered = hasBeenDelivered;
    }

    hasBeenDelivered() {
        return this.state.hasBeenDelivered;
    }

    examineText() {
        let description =
            'It is a glossy brochure from MIT Tech, which reads:\n';
        description += BROCHURE_TEXT;
        if (this.contains(this.ent(DonWoodsStamp))) {
            description +=
                '\nAffixed loosely to the brochure is a small stamp.';
        }
        return description;
    }
}

const BROCHURE_TEXT =
    `  c/o Local Dungeon Master
  White House, GUE

From the Introduction:

The brochure describes, for the edification of the prospective student, ` +
    `the stringent but wide-ranging curriculum of MIT Tech. Required courses ` +
    `are offered in Ambition, Distraction, Uglification and Derision. ` +
    `The Humanities are not slighted in this institution, as the student may ` +
    `register for Reeling and Writhing, Mystery (Ancient and Modern), Seaography, ` +
    `and Drawling (which includes Stretching and Fainting in Coils). ` +
    `Advanced students are expected to learn Laughing and Grief.

    William Barton Flathead, Fovnder

(The brochure continues in this vein for a few hundred more pages.)`;

const sendForBrochure: Handler = async ({ action, runner }) => {
    if (action.is(Request) && action.item?.is(Brochure)) {
        const { item: brochure } = action;
        if (brochure.hasBeenTaken()) {
            await runner.doOutput('Why? Do you need another one?');
        } else if (
            brochure.hasBeenRequested() &&
            !brochure.hasBeenDelivered()
        ) {
            await runner.doOutput("It's probably on the way.");
        } else {
            await runner.doOutput(
                'And how exactly do you expect me to do that?'
            );
        }
        return Action.complete();
    }
};
