import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { Boat, Bolt, TrunkOfJewels, ReservoirWater } from '../../items';
import { ReservoirNorth } from '../ReservoirNorth';
import { ReservoirSouth } from '../ReservoirSouth';
import { Stream } from '../Stream';

export class Reservoir extends Room {
    static spec(): EntitySpec<Reservoir> {
        return {
            ref: 'reservoir',
            constructor: Reservoir,
            initial: {
                contents: [TrunkOfJewels.spec().ref, ReservoirWater.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Reservoir.spec().ref;
    }

    name(): string {
        return 'Reservoir';
    }

    description(): string {
        let description;
        if (this.game.ent(Bolt).isPowered()) {
            description =
                'You are on what used to be a large reservoir, but which is ' +
                "now a large mud pile. There are 'shores' to the north and south.";
        } else {
            description =
                'You are on the reservoir. Beaches can be seen north and south. ' +
                'Upstream a small stream enters the reservoir through a ' +
                'narrow cleft in the rocks. The dam can be seen downstream.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isOnLand(): boolean {
        return this.game.ent(Bolt).isPowered();
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: ReservoirNorth.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                to: ReservoirSouth.spec().ref,
            }),
            // TODO not sure about this navigation re: in a boat vs. not in a boat when the reservoir is lowered...
            new Passage({
                via: Direction.Up,
                to: Stream.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: Direction.Down,
                message: 'The dam blocks your way.',
            }),
            new Passage({
                via: SpecialDirection.Land,
                message: 'Which direction do you want to land, north or south?',
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
