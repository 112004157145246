import { Passage, Room, RoundRoom } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Grail, GrailPedestal } from '../../items';
import { NarrowCrawlway } from '../NarrowCrawlway';
import { Temple } from '../Temple';

export class GrailRoom extends Room {
    static spec(): EntitySpec<GrailRoom> {
        return {
            ref: 'grail-room',
            constructor: GrailRoom,
            initial: {
                contents: [Grail.spec().ref, GrailPedestal.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return GrailRoom.spec().ref;
    }

    name(): string {
        return 'Grail Room';
    }

    description(): string {
        return (
            'You are standing in a small circular room with a pedestal. ' +
            'A set of stairs leads up, and passages leave to the east and west.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: RoundRoom.spec().ref }),
            new Passage({ via: Direction.East, to: NarrowCrawlway.spec().ref }),
            new Passage({
                via: Direction.Up,
                to: Temple.spec().ref,
            }),
        ];
    }
}
