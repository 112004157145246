import { Entity, Reference } from '../game';
import { Adjective, Direction, Noun, SpecialDirection } from '../../parse';
import { Passage } from '.';
import { NorthWall } from '../items/NorthWall';
import { EastWall } from '../items/EastWall';
import { WestWall } from '../items/WestWall';
import { SouthWall } from '../items/SouthWall';

export enum MungedSource {
    BlastingCakes = 'orange-cake',
    NoxiousGas = 'noxious-gas',
    PoisonousGas = 'poisonous-gas',
    Flooded = 'flooded',
    Exploded = 'exploded',
    LedgeCollapse = 'ledge-collapse',
}

export interface RoomState {
    contents: Reference[];
    hasBeenVisited: boolean;
    hasBeenDescribed: boolean;
    isMunged?: boolean;
    howMunged?: MungedSource;
}

export abstract class Room<S extends RoomState = RoomState> extends Entity<S> {
    hasWalls(): boolean {
        return true;
    }

    isMunged() {
        return this.state.isMunged;
    }

    setIsMunged(isMunged: boolean) {
        this.state.isMunged = isMunged;
    }

    howMunged() {
        return this.state.howMunged;
    }

    setHowMunged(howMunged: MungedSource) {
        this.state.howMunged = howMunged;
    }

    mung(howMunged: MungedSource) {
        this.setIsMunged(true);
        this.setHowMunged(howMunged);
    }

    hasNorthWall(): boolean {
        return this.hasWalls();
    }

    hasSouthWall(): boolean {
        return this.hasWalls();
    }

    hasEastWall(): boolean {
        return this.hasWalls();
    }

    hasWestWall(): boolean {
        return this.hasWalls();
    }

    hasGround(): boolean {
        return this.isOnLand();
    }

    isNaturallyLit(): boolean {
        return false;
    }

    scoreOnEntry(): number {
        return 0;
    }

    isOnLand(): boolean {
        return true;
    }

    isSacred(): boolean {
        return false;
    }

    abstract passages(): Passage[];

    getPassage(direction: Direction | SpecialDirection): Passage | undefined {
        return this.passages().find((passage) =>
            passage.directions.includes(direction)
        );
    }

    hasBeenVisited(): boolean {
        return this.state.hasBeenVisited;
    }

    hasBeenDescribed(): boolean {
        return this.state.hasBeenDescribed;
    }

    isRoom(): this is Room {
        return true;
    }

    isInfested() {
        return this.state.contents.some((ref) => {
            const item = this.get(ref);
            return item.isActor() && item.isVillain();
        });
    }

    // TODO crs make this on all containers too?
    removeEntity(entity: Entity) {
        const index = this.state.contents.findIndex(
            (ref) => entity.ref() === ref
        );
        if (index !== undefined) {
            this.state.contents.splice(index, 1);
        }
    }

    // TODO crs make this on all containers too?
    addEntity(entity: Entity) {
        this.state.contents.push(entity.ref());
    }

    contents(): Entity[] {
        return this.state.contents.map((ref) => this.get(ref));
    }

    globalObjects(): Entity[] {
        // TODO it would be nice if these were not truly global, but attached to
        //      the player -- you can tell the DM to do stuff with your body parts
        //      even when he is not in the same room as you...
        // TODO also you can't currently use these as a tool, because you don't "have" them
        const items = [this.get('teeth'), this.get('lungs'), this.get('hands')];
        if (this.hasNorthWall()) {
            items.push(this.ent(NorthWall));
        }
        if (this.hasEastWall()) {
            items.push(this.ent(EastWall));
        }
        if (this.hasSouthWall()) {
            items.push(this.ent(SouthWall));
        }
        if (this.hasWestWall()) {
            items.push(this.ent(WestWall));
        }
        if (this.hasGround()) {
            items.push(this.get('ground'));
        }
        if (this.isPartOfForest()) {
            // TODO why can't I import this :(
            items.push(this.get('songbird'));
        }
        return items;
    }

    visibleObjects(): Entity[] {
        // TODO Can this somehow be "mentionable" instead??
        // TODO why can't I import this :(
        return [this.get('grue'), this.get('blessings')];
    }

    // TODO crs abstract this out between containers too
    contains(entity: Entity) {
        return this.contents().some((other) => other.ref() === entity.ref());
    }

    hasItem(entity: Entity): boolean {
        // TODO more efficient implementation of this....
        const container = entity.container();
        return (
            container !== undefined &&
            (this.isEqualTo(container) || this.hasItem(container))
        );
    }

    adjectives(): Adjective[] {
        return [];
    }

    nouns(): Noun[] {
        return [];
    }

    isPartOfMaze(): boolean {
        return false;
    }

    isPartOfEndgame(): boolean {
        return false;
    }

    isPartOfHouse(): boolean {
        return false;
    }

    isPartOfForest(): boolean {
        return false;
    }
}
