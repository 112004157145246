import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Lunch extends makeTakeable(Item) {
    static spec(): EntitySpec<Lunch> {
        return {
            ref: 'lunch',
            constructor: Lunch,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('pepper sandwich'),
                new Noun('sandwich'),
                new Noun('lunch'),
                new Noun('pepper sandwiches', { plural: true }),
                new Noun('sandwiches', { plural: true }),
                new Noun('lunches', { plural: true }),
            ],
            adjectives: [new Adjective('hot')],
        };
    }

    ref() {
        return Lunch.spec().ref;
    }

    name() {
        return 'hot pepper sandwich';
    }

    description() {
        return 'A hot pepper sandwich is here.';
    }

    nouns(): Noun[] {
        return Lunch.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Lunch.spec().adjectives;
    }

    isFood(): boolean {
        return true;
    }
}
