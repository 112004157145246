import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec, Handler } from '../../game';
import { Inflate } from '../../abilities';

export class Pump extends makeFlammable(makeTakeable(Item)) {
    static spec(): EntitySpec<Pump> {
        return {
            ref: 'pump',
            constructor: Pump,
            initial: {
                hasBeenTaken: false,
                isAflame: false,
            },
            nouns: [
                new Noun('pump'),
                new Noun('pumps', { plural: true }),
                new Noun('air pump'),
                new Noun('air pumps', { plural: true }),
                new Noun('air-pump'),
                new Noun('air pumps', { plural: true }),
                new Noun('airpump'),
                new Noun('airpumps', { plural: true }),
            ],
            adjectives: [
                new Adjective('hand-held'),
                new Adjective('handheld'),
                new Adjective('small'),
            ],
            handlers: [inflateWithPump],
        };
    }

    isTool() {
        return true;
    }

    ref() {
        return Pump.spec().ref;
    }

    name() {
        return 'hand-held air pump';
    }

    description() {
        return 'There is a small pump here.';
    }

    nouns(): Noun[] {
        return Pump.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Pump.spec().adjectives;
    }
}

const inflateWithPump: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(Inflate) &&
        action.tool === undefined &&
        actor?.hasItem(game.ent(Pump))
    ) {
        // TODO maybe say, "(Using the pump) "
        return game.applyAction(
            runner,
            new Inflate({ item: action.item, tool: game.ent(Pump) })
        );
    }
};
