import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push } from '../../abilities';
import { Player } from '../../actors';
import { RoundRoom } from '../../rooms';
import { buttonZap } from '../SquareButton/SquareButton';

export class RoundButton extends Item {
    static spec(): EntitySpec<RoundButton> {
        return {
            ref: 'round-button',
            constructor: RoundButton,
            initial: {},
            nouns: [
                new Noun('button'),
                new Noun('buttons', { plural: true }),
                new Noun('switch'),
                new Noun('switches', { plural: true }),
            ],
            adjectives: [
                new Adjective('round'),
                new Adjective('circular'),
                new Adjective('circle'),
            ],
            handlers: [pushRoundButton],
        };
    }

    ref() {
        return RoundButton.spec().ref;
    }

    name(): string {
        return 'round button';
    }

    description(): string {
        return '';
    }

    nouns() {
        return RoundButton.spec().nouns;
    }

    adjectives() {
        return RoundButton.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushRoundButton: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Push) && action.item.is(RoundButton)) {
        if (actor?.is(Player)) {
            return buttonZap(game, runner, actor);
        }
        const roundRoom = game.ent(RoundRoom);
        if (roundRoom.isHighPower()) {
            roundRoom.state.isHighPower = false;
            await runner.doOutput(
                'The whirring decreases in intensity slightly.'
            );
        } else {
            await runner.doOutput('Nothing seems to happen.');
        }

        return Action.complete();
    }
};
