import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { makeReadable } from '../../game/Entity';

export class Prayer extends makeReadable(Item) {
    static spec(): EntitySpec<Prayer> {
        return {
            ref: 'prayer',
            constructor: Prayer,
            initial: {},
            nouns: [
                new Noun('prayer'),
                new Noun('prayers', { plural: true }),
                new Noun('inscription'),
                new Noun('inscriptions', { plural: true }),
            ],
            adjectives: [new Adjective('ancient'), new Adjective('old')],
        };
    }

    ref() {
        return Prayer.spec().ref;
    }

    name(): string {
        return 'prayer';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    text(): string {
        return PRAYER_TEXT;
    }

    nouns() {
        return Prayer.spec().nouns;
    }

    adjectives() {
        return Prayer.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const PRAYER_TEXT =
    'The prayer is inscribed in an ancient script which is hardly ' +
    'remembered these days, much less understood. What little of it can ' +
    'be made out seems to be a philippic against small insects, ' +
    'absent-mindedness, and the picking up and dropping of small objects. ' +
    'The final verse seems to consign trespassers to the land of the dead. ' +
    'All evidence indicates that the beliefs of the ancient Zorkers were obscure.';
