import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { makeReadable } from '../../game/Entity';

export class SecuritySticker extends makeReadable(Item) {
    static spec(): EntitySpec<SecuritySticker> {
        return {
            ref: 'security-sticker',
            constructor: SecuritySticker,
            initial: {},
            nouns: [
                new Noun('sticker'),
                new Noun('stickers', { collective: true }),
            ],
            adjectives: [new Adjective('small'), new Adjective('security')],
        };
    }

    ref() {
        return SecuritySticker.spec().ref;
    }

    name(): string {
        return 'security sticker';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    text(): string {
        return STICKER_TEXT;
    }

    nouns() {
        return SecuritySticker.spec().nouns;
    }

    adjectives() {
        return SecuritySticker.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const STICKER_TEXT = `    Protected by
      FROBOZZ
       Magic Alarm Company
        (Hello, footpad!)`;
