import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { TieUpUnresolved, tieUpUnresolvedHandler } from './TieUpUnresolved';

export class TieUp extends Action {
    id = '~tie-up';

    item: Entity;

    tool: Entity;

    constructor({ item, tool }: { item: Entity; tool: Entity }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [tieUpHandler, tieUpUnresolvedHandler],
            parser,
            verbs: [new Verb('tie up'), new Verb('wrangle'), new Verb('lasso')],
            prepositions: [],
        };
    }
}

export const tieUpHandler: Handler = async ({ action, runner }) => {
    if (!action.is(TieUp)) return;
    const { item, tool } = action;
    if (!tool?.isItem() || !tool.canBeFastened()) {
        await runner.doOutput('You could certainly never tie it with that!');
    } else if (item.isActor()) {
        await runner.doOutput(
            `${item.The()} struggles and you cannot tie him up.`
        );
    } else {
        await runner.doOutput(`Why would you tie up ${item.an()}?`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, TieUpUnresolved> => {
    const tieUp = Parse.words(TieUp.ability().verbs);
    const tieUpObject = tieUp.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const tieObjectUp = Parse.word('tie')
        .then(Parse.whitespace())
        .beforeX(Parse.target(game.lexicon))
        .before(Parse.whitespace().then(Parse.word('up')));
    const tieUpObjectWith = Parse.either(tieUpObject, tieObjectUp).chain(
        (item) =>
            Parse.option(
                Parse.target(game.lexicon)
                    .after(Parse.whitespace())
                    .after(
                        Parse.either(Parse.word('with'), Parse.word('using'))
                    )
                    .after(Parse.whitespace())
            ).map((tool) => [item, tool])
    );
    return Parse.any(
        // tie up
        tieUp.into(new TieUpUnresolved({ item: undefined, tool: undefined })),
        // tie up the box / tie the box up
        tieUpObject.map(
            (item) => new TieUpUnresolved({ item, tool: undefined })
        ),
        // tie up egg with rope
        tieUpObjectWith.map(
            ([item, tool]) =>
                new TieUpUnresolved({
                    item,
                    tool,
                })
        )
    );
};
