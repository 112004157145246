import { BatRoom, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { MineEntrance } from '../MineEntrance';

export class SqueakyRoom extends Room {
    static spec(): EntitySpec<SqueakyRoom> {
        return {
            ref: 'squeaky-room',
            constructor: SqueakyRoom,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return SqueakyRoom.spec().ref;
    }

    name(): string {
        return 'Squeaky Room';
    }

    description(): string {
        return (
            'You are a small room. Strange squeaky sounds may be heard ' +
            'coming from the passage at the west end. You may also escape to the south.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: BatRoom.spec().ref }),
            new Passage({
                via: Direction.South,
                to: MineEntrance.spec().ref,
            }),
        ];
    }
}
