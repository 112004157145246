import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Request } from './Request';
import { UnresolvedAction } from '../UnresolvedAction';

export class RequestUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'request';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'request something';
        }
        return `request ${targetDescription(game, this.item)}`;
    }
}

export const requestUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(RequestUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        missing: () => 'Request what?',
        onlyVisible: false,
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    return await game.applyAction(runner, new Request({ item }), actor);
};
