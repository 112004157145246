import { Passage, Room, WindingPassage } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { MagicMirror } from '../../items';
import { Cave2 } from '../Cave2';
import { NarrowCrawlway } from '../NarrowCrawlway';

export class MirrorRoom2 extends Room {
    static spec(): EntitySpec<MirrorRoom2> {
        return {
            ref: 'mirror-room-2',
            constructor: MirrorRoom2,
            initial: {
                contents: [MagicMirror.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return MirrorRoom2.spec().ref;
    }

    name(): string {
        return 'Mirror Room';
    }

    description(): string {
        let description =
            'You are in a large square room with tall ceilings. ' +
            'On the south wall is an enormous mirror which fills the entire wall. ' +
            'There are exits on the other three sides of the room.';
        if (this.game.ent(MagicMirror).isMunged()) {
            description +=
                '\nUnfortunately, the mirror has been destroyed by your recklessness.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: WindingPassage.spec().ref }),
            new Passage({
                via: Direction.North,
                to: NarrowCrawlway.spec().ref,
            }),
            new Passage({ via: Direction.East, to: Cave2.spec().ref }),
        ];
    }
}
