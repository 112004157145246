import { Actor, Player } from '../index';
import { Adjective, Noun } from '../../../parse';
import { Axe, Knife, Sword } from '../../items';
import { Item } from '../../items/Item';
import {
    Give,
    Hello,
    Move,
    Mung,
    SpecialJigsUp,
    SpecialPrepareToFight,
    SpecialRevive,
    Take,
    Throw,
} from '../../abilities';
import { Action, Handler } from '../../game';

export class Troll extends Actor {
    static spec() {
        return {
            ref: 'troll',
            constructor: Troll,
            initial: {
                inventory: [Axe.spec().ref],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: 5,
                isEngrossed: false,
            },
            nouns: [
                new Noun('troll'),
                new Noun('trolls', { plural: true }),
                new Noun('monster'),
                new Noun('monsters', { plural: true }),
            ],
            adjectives: [
                new Adjective('big'),
                new Adjective('ugly'),
                new Adjective('nasty'),
                new Adjective('scary'),
                new Adjective('nasty-looking'),
                new Adjective('nasty looking'),
            ],
            handlers: [
                trollRecoverAxe,
                trollRevives,
                throwAtTroll,
                takeOrMoveTroll,
                helloSleepyTroll,
                trollDies,
                breakTroll,
            ],
        };
    }

    ref() {
        return Troll.spec().ref;
    }

    name(): string {
        return 'troll';
    }

    description(): string {
        if (this.isConscious()) {
            return 'A nasty-looking troll, brandishing a bloody axe, blocks all passages out of the room.';
        }
        return 'An unconscious troll is sprawled on the floor. All passages out of the room are open.';
    }

    isVillain(): boolean {
        return true;
    }

    isVictim(): boolean {
        return true;
    }

    nouns(): Noun[] {
        return Troll.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Troll.spec().adjectives;
    }

    bestWeapon(): Item | undefined {
        return this.game.ent(Sword);
    }

    isReadyToFight(): boolean {
        const axe = this.game.ent(Axe);
        return this.hasItem(axe);
    }

    shouldAttackFirst(): boolean {
        return this.game.ent(Player).testLuck(33, 66);
    }
}

const trollRecoverAxe: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(SpecialPrepareToFight) && actor?.is(Troll)) {
        const player = game.ent(Player);
        const axe = game.ent(Axe);
        const troll = actor;
        const room = game.locateEntity(player);
        if (room?.contains(axe) && player.testLuck(75, 90)) {
            axe.moveTo(troll);
            await runner.doOutput(
                'The troll, now worried about this encounter, recovers his bloody axe.'
            );
        } else {
            await runner.doOutput(
                'The troll, disarmed, cowers in terror, pleading for ' +
                    'his life in the guttural tongue of the trolls.'
            );
        }
        return Action.complete({ withConsequence: false });
    }
};

const trollRevives: Handler = async ({ action, runner, actor }) => {
    if (action.is(SpecialRevive) && actor?.is(Troll)) {
        actor.state.isConscious = true;
        await runner.doOutput(
            'The troll stirs, quickly resuming a fighting stance.'
        );
        return Action.complete({ withConsequence: false });
    }
};

const throwAtTroll: Handler = async ({ action, runner, game, actor }) => {
    let howReceived;
    let item;
    if (action.is(Throw) && action.enemy?.is(Troll)) {
        howReceived = `The troll, who is remarkably coordinated, catches ${action.item.the()}`;
        item = action.item;
    }
    if (
        action.is(Give) &&
        action.recipient.is(Troll) &&
        actor?.hasItem(action.item)
    ) {
        howReceived = `The troll, who is not overly proud, graciously accepts the gift`;
        item = action.item;
    }
    if (item && howReceived) {
        const player = game.ent(Player);
        if (item.is(Knife)) {
            await runner.doOutput(
                `${howReceived}, and being for the moment sated, throws it back. ` +
                    'Fortunately, the troll has poor control, and the knife falls ' +
                    'to the floor. He does not look pleased.'
            );
            item.moveTo(player.location());
        } else {
            await runner.doOutput(
                `${howReceived}, and not having the most discriminating tastes, gleefully eats it.`
            );
        }
        return Action.complete();
    }
};

const takeOrMoveTroll: Handler = async ({ action, runner, game }) => {
    if ((action.is(Take) || action.is(Move)) && action.item.is(Troll)) {
        if (!action.item.isConscious()) {
            await game.applyAction(runner, new SpecialRevive(), action.item);
        }
        await runner.doOutput(
            'The troll spits in your face, saying "Better luck next time."'
        );
        return Action.complete();
    }
};

const helloSleepyTroll: Handler = async ({ action, runner }) => {
    if (
        action.is(Hello) &&
        action.person?.is(Troll) &&
        !action.person.isConscious()
    ) {
        await runner.doOutput("Unfortunately, the troll can't hear you.");
        return Action.complete();
    }
};

// TODO This is purely so it doesn't say "The troll has died." There's probably a better way to do this...
const trollDies: Handler = async ({ action, runner, actor }) => {
    if (action.is(SpecialJigsUp) && actor?.is(Troll)) {
        await runner.doOutput(action.message);
        return Action.complete();
    }
};

const breakTroll: Handler = async ({ action, runner, game }) => {
    if (action.is(Mung) && action.item.is(Troll)) {
        if (!action.item.isConscious()) {
            await game.applyAction(runner, new SpecialRevive(), action.item);
        }
        await runner.doOutput('The troll laughs at your puny gesture.');
        return Action.complete();
    }
};
