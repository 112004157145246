import { EgyptianRoom, Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';

export class VolcanoView extends Room {
    static spec(): EntitySpec<VolcanoView> {
        return {
            ref: 'volcano-view',
            constructor: VolcanoView,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return VolcanoView.spec().ref;
    }

    name(): string {
        return 'Volcano View';
    }

    description(): string {
        return (
            'You are on a ledge in the middle of a large volcano. ' +
            'Below you the volcano bottom can be seen and above is ' +
            'the rim of the volcano. A couple of ledges can be seen on ' +
            'the other side of the volcano; it appears that this ledge ' +
            'is intermediate in elevation between those on the other side. ' +
            'The exit from this room is to the east.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: EgyptianRoom.spec().ref }),
            new Passage({
                via: Direction.Down,
                message: "I wouldn't try that.",
            }),
            new Passage({
                via: SpecialDirection.Cross,
                message: 'It is impossible to cross this distance.',
            }),
        ];
    }
}
