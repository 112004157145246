import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Zorkmid extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<Zorkmid> {
        return {
            ref: 'zorkmid',
            constructor: Zorkmid,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('zorkmid'),
                new Noun('zorkmids', { plural: true }),
                new Noun('coin'),
                // new Noun('coins', { plural: true }), TODO this breaks "take coins" for bag of coins
                new Noun('gold', { collective: true }),
                new Noun('money', { collective: true }),
            ],
            adjectives: [
                new Adjective('gold'),
                new Adjective('priceless'),
                new Adjective('collectible'),
                new Adjective('golden'),
                new Adjective('engraved'),
            ],
        };
    }

    ref() {
        return Zorkmid.spec().ref;
    }

    name() {
        return 'priceless zorkmid';
    }

    description() {
        return 'There is an engraved zorkmid coin here.';
    }

    initialDescription() {
        return "On the floor is a gold zorkmid coin (a valuable collector's item).";
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 12;
    }

    nouns(): Noun[] {
        return Zorkmid.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Zorkmid.spec().adjectives;
    }

    examineText() {
        return ZORKMID_IMAGE;
    }
}

const ZORKMID_IMAGE = `          ------------------------
        /      Gold Zorkmid        \\\\
       /  T e n   T h o u s a n d   \\\\
      /        Z O R K M I D S       \\\\
     /                                \\\\
    /        ||||||||||||||||||        \\\\
   /           !||||    ||||!           \\\\
  |          |||   ^^  ^^   |||          |
  |          |||   OO  OO   |||          |
  | In Frobs  |||    <<    |||  We Trust |
  |            || (______) ||            |
  |             |          |             |
  |             |__________|             |
   \\                                    /
    \\    -- Lord Dimwit Flathead --    /
     \\    -- Beloved of Zorkers --    /
      \\                              /
       \\      * 722 G.U.E. *        /
        \\                          /
          ------------------------`;
