import {
    Action,
    Effect,
    EntitySpec,
    Handler,
    Reference,
    resolve,
    State,
} from '.';
import { Parse, Target, Parser, tokenize, Value } from '../../parse';
import {
    SpecialAfter,
    SpecialLoadDone,
    SpecialLoadError,
    SpecialNoParse,
    SpecialStartUp,
    Close,
    Ulysses,
    Look,
    Inventory,
    Score,
    Save,
    Load,
    Read,
    Take,
    Drop,
    PutIn,
    SpecialAgain,
    SpecialUnknownWord,
    SpecialMostlyUnderstand,
    SpecialPartial,
    SpecialThen,
    SpecialGo,
    SpecialJigsUp,
    Go,
    Open,
    Eat,
    Drink,
    Move,
    Raise,
    LookUnder,
    TurnOn,
    TurnOff,
    Wait,
    Kill,
    Diagnose,
    Echo,
    Bug,
    Feature,
    Give,
    Wake,
    Poke,
    Throw,
    Hello,
    Board,
    Disembark,
    Pour,
    Push,
    Mung,
    Jump,
    Rub,
    GoThrough,
    Tie,
    Untie,
    Climb,
    Unlock,
    LookIn,
    Turn,
    Light,
    Extinguish,
    Curse,
    Pray,
    Lower,
    Lubricate,
    Squeeze,
    Plug,
    Inflate,
    Deflate,
    BlowInto,
    Land,
    Launch,
    Dig,
    Wave,
    Geronimo,
    Melt,
    Play,
    Wind,
    Find,
    Exorcise,
    Enter,
    Exit,
    Knock,
    Stay,
    Follow,
    SetTo,
    Spin,
    Treasure,
    Temple,
    Kick,
    Examine,
    Shake,
    Plugh,
    Foo,
    Skip,
    TieUp,
    Mumble,
    Repent,
    BlowUp,
    Chomp,
    Frobozz,
    Win,
    Yell,
    Honk,
    Smell,
    Oops,
    Count,
    Request,
    Yes,
    No,
    Restart,
    Incant,
    Swim,
    Brush,
} from '../abilities';
import { Entity, EntityConstructor } from './Entity';
import { Extensions } from './Handler';
import { LoadError, Runner } from './Runner';
import { mulberry32, pluralize } from '../utils';
import { Ability } from './Ability';
import {
    CountMoves,
    doNotDescribeSelf,
    EndTick,
    loadDone,
    loadError,
    Melee,
    noParseHandler,
    PrepareEndgame,
    recordPreviousAction,
    startUp,
    TickTimers,
} from '../handlers';
import {
    Actor,
    Bat,
    Cyclops,
    DungeonMaster,
    Player,
    Robot,
    Thief,
    Troll,
    VolcanoGnome,
    ZurichGnome,
} from '../actors';
import {
    AncientChasm,
    AncientDeadEnd1,
    AncientDeadEnd2,
    AragainFalls,
    AtlantisRoom,
    Attic,
    BankEntrance,
    BatRoom,
    BehindHouse,
    CanyonBottom,
    CanyonView,
    Cave1,
    Cellar,
    ChairmansOffice,
    Chasm,
    CircularRoom,
    Clearing,
    CoalMine1,
    CoalMine2,
    CoalMine3,
    CoalMine4,
    CoalMine5,
    CoalMine6,
    CoalMine7,
    CoalMineDeadEnd,
    ColdPassage,
    CyclopsRoom,
    Dam,
    DamBase,
    DamLobby,
    DampCave,
    DeadEnd1,
    DeadEnd2,
    DeadEnd3,
    DeadEnd4,
    DeepCanyon,
    DrearyRoom,
    EastTellersRoom,
    EastViewingRoom,
    EastWestPassage,
    EndOfTheRainbow,
    EngravingsCave,
    Forest1,
    Forest2,
    Forest3,
    Forest4,
    Forest5,
    Gallery,
    GasRoom,
    GratingRoom,
    InASlide1,
    InASlide2,
    InASlide3,
    Kitchen,
    LadderBottom,
    LadderTop,
    LandOfTheLivingDead,
    LivingRoom,
    LoudRoom,
    LowerShaft,
    LowRoom,
    MaintenanceRoom,
    Maze1,
    Maze10,
    Maze11,
    Maze12,
    Maze13,
    Maze14,
    Maze15,
    Maze2,
    Maze3,
    Maze4,
    Maze5,
    Maze6,
    Maze7,
    Maze8,
    Maze9,
    MineEntrance,
    MineMachineRoom,
    MirrorRoom1,
    MirrorRoom2,
    NorthOfHouse,
    NorthSouthCrawlway,
    NorthSouthPassage,
    OnTheRainbow,
    PearlRoom,
    Reservoir,
    ReservoirNorth,
    ReservoirSouth,
    RiddleRoom,
    River1,
    River2,
    River3,
    River4,
    River5,
    RockyLedge,
    RockyShore,
    Room,
    RoundRoom,
    SafetyDepository,
    SandyBeach,
    ShaftRoom,
    Shore,
    SlideLedge,
    SlideRoom,
    SmallBankRoom,
    SmallCave,
    SmellyRoom,
    SootyRoom,
    SouthOfHouse,
    SqueakyRoom,
    SteepCrawlway,
    StrangePassage,
    StreamView,
    Studio,
    TimberRoom,
    TinyRoom,
    TreasureRoom,
    TrollRoom,
    UpATree,
    Vault,
    WestOfChasm,
    WestOfHouse,
    WestTellersRoom,
    WestViewingRoom,
    WhiteCliffsBeach1,
    WhiteCliffsBeach2,
    WindingPassage,
    WoodenTunnel,
    RoomInAPuzzle11,
    RoomInAPuzzle12,
    RoomInAPuzzle13,
    RoomInAPuzzle14,
    RoomInAPuzzle15,
    RoomInAPuzzle16,
    RoomInAPuzzle21,
    RoomInAPuzzle22,
    RoomInAPuzzle24,
    RoomInAPuzzle25,
    RoomInAPuzzle26,
    RoomInAPuzzle31,
    RoomInAPuzzle32,
    RoomInAPuzzle33,
    RoomInAPuzzle34,
    RoomInAPuzzle36,
    RoomInAPuzzle41,
    RoomInAPuzzle42,
    RoomInAPuzzle43,
    RoomInAPuzzle44,
    RoomInAPuzzle45,
    RoomInAPuzzle46,
    RoomInAPuzzle51,
    RoomInAPuzzle52,
    RoomInAPuzzle53,
    RoomInAPuzzle54,
    RoomInAPuzzle55,
    RoomInAPuzzle56,
    RoomInAPuzzle63,
    RoomInAPuzzle64,
    RoomInAPuzzle65,
    SmallSquareRoom,
    SideRoom,
    EgyptianRoom,
    WideLedge,
    VolcanoNearWideLedge,
    VolcanoNearViewingLedge,
    VolcanoNearSmallLedge,
    VolcanoCore,
    VolcanoBottom,
    NarrowLedge,
    Library,
    LavaRoom,
    DustyRoom,
    GlacierRoom,
    Stream,
    TopOfStairs,
    WestNarrowRoom2,
    WestNarrowRoom1,
    WestGuardianNarrowRoom,
    WestCorridor,
    TreasuryOfZork,
    StoneRoom,
    SouthCorridor,
    SmallRoom,
    PrisonCell,
    Parapet,
    NorthCorridor,
    NarrowCorridor,
    InsideMirror,
    Hallway4,
    Hallway3,
    Hallway2,
    Hallway1,
    GuardianHallway,
    EastNarrowRoom3,
    EastNarrowRoom2,
    EastNarrowRoom1,
    EastGuardianNarrowRoom,
    EastCorridor,
    DungeonEntrance,
    WestNarrowRoom3,
    EastNarrowRoom4,
    WestNarrowRoom4,
} from '../rooms';

import { specialEnterHandler } from '../abilities/SpecialEnter/SpecialEnter';
import { specialLookHandler } from '../abilities/SpecialLook/SpecialLook';
import { specialDescribeRoomHandler } from '../abilities/SpecialDescribeRoom/SpecialDescribeRoom';
import {
    AtticTable,
    Axe,
    BarredHouseWindow,
    Bottle,
    Brick,
    Egg,
    FrontDoor,
    Garlic,
    Grating,
    KitchenTable,
    KitchenWindow,
    Knife,
    Lamp,
    Leaflet,
    Lunch,
    Mailbox,
    Nest,
    Newspaper,
    PileOfLeaves,
    PlatinumBar,
    QuantityOfWater,
    Rope,
    Rug,
    Sack,
    Skeleton,
    Sword,
    TrapDoor,
    Tree,
    TrophyCase,
    WelcomeMat,
    WoodenDoor,
    ReservoirWater,
    WoodenDoorLettering,
    BagOfCoins,
    Stiletto,
    Chalice,
    StoneDoor,
    Engravings,
    PearlNecklace,
    WellBottomEtchings,
    WellTopEtchings,
    Bucket,
    EatMeCake,
    OblongTable,
    RedCake,
    BlueCake,
    OrangeCake,
    Pool,
    Saffron,
    Flask,
    GreenPaper,
    WhitePalantir,
    TriangularButton,
    RoundButton,
    SquareButton,
    SteelCage,
    ViolinCase,
    SecuritySticker,
    Painting,
    Portrait,
    Bills,
    EastWall,
    SouthWall,
    NorthWall,
    WestWall,
    StoneCube,
    Curtain,
    Dome,
    Torch,
    Railing,
    Pedestal,
    BluePalantir,
    RustyKey,
    OakDoorLid2,
    OakDoorLid1,
    OakDoorKeyhole2,
    OakDoorKeyhole1,
    OakDoor,
    Crack,
    PalantirTable,
    BarredWindow,
    Screwdriver,
    MagicMirror,
    GrailPedestal,
    Grail,
    Prayer,
    Bell,
    Book,
    Candles,
    Slide,
    Timber,
    Stove,
    Jade,
    Coal,
    SapphireBracelet,
    Slag,
    Basket,
    MineLadder,
    Machine,
    Diamond,
    MachineSwitch,
    Bubble,
    GuideBook,
    Matchbook,
    BlueButton,
    RedButton,
    YellowButton,
    BrownButton,
    Gunk,
    Tube,
    ToolChests,
    Wrench,
    Bolt,
    DamLeak,
    Pump,
    TrunkOfJewels,
    Trident,
    Lungs,
    Boat,
    Stick,
    BoatInstructions,
    Hands,
    Sand,
    Buoy,
    Emerald,
    Statue,
    Guano,
    Rainbow,
    Cliffs,
    PotOfGold,
    Barrel,
    RustyKnife,
    BrokenLantern,
    WestMarbleWall,
    NorthMarbleWall,
    SouthMarbleWall,
    EastMarbleWall,
    SouthSandstoneWall,
    NorthSandstoneWall,
    WestSandstoneWall,
    EastSandstoneWall,
    WestLadder,
    EastLadder,
    GoldCard,
    SteelDoor,
    Slit,
    WarningNote,
    Coffin,
    Glacier,
    Ruby,
    Safe,
    Balloon,
    BlueLabel,
    ClothBag,
    BraidedWire,
    BalloonReceptacle,
    Hook1,
    Hook2,
    Zorkmid,
    WhiteBook,
    BlueBook,
    GreenBook,
    PurpleBook,
    FlatheadStamp,
    SafeHole,
    Fuse,
    WarningCard,
    Crown,
    VolcanoGnomeDoor,
    Violin,
    Bauble,
    Grue,
    Ghosts,
    Brochure,
    DonWoodsStamp,
    PoledHeads,
    CryptDoor,
    LargeCase,
    CokeBottles,
    Code,
    RedBeam,
    RedEndgameButton,
    Mirror1,
    ShortPole,
    RedPanel,
    WhitePanel,
    BlackPanel,
    YellowPanel,
    MahoganyPanel,
    PinePanel,
    SidePanel,
    Mirror2,
    MirrorPanel1,
    MirrorPanel2,
    WoodenBar,
    TBar,
    LongPole,
    Arrow,
    Guardians,
    QuestionDoor,
    CellDoor,
    BronzeDoor,
    GraniteWall,
    House,
    PoledCretinHead,
    DeadBodies,
    GlobalTree,
    PoolLeak,
    Songbird,
    WhiteCliffs,
    Blessings,
    River,
    Ground,
    Teeth,
} from '../items';
import { specialDescribeHandler } from '../abilities/SpecialDescribe/SpecialDescribe';
import { specialListContentsHandler } from '../abilities/SpecialListContents/SpecialListContents';
import { Lexicon } from './Lexicon';
import {
    SpecialTryTake,
    specialTryTakeHandler,
} from '../abilities/SpecialTryTake/SpecialTryTake';
import { Container, Item } from '../items/Item';
import { Answer } from '../abilities/Answer';
import { TopOfWell } from '../rooms/TopOfWell';
import { TeaRoom } from '../rooms/TeaRoom';
import { PoolRoom } from '../rooms/PoolRoom';
import { Tell } from '../abilities/Tell';
import { MachineRoom } from '../rooms/MachineRoom';
import { DingyCloset } from '../rooms/DingyCloset';
import { DeepRavine } from '../rooms/DeepRavine';
import { RockyCrawl } from '../rooms/RockyCrawl';
import { DomeRoom } from '../rooms/DomeRoom';
import { TorchRoom } from '../rooms/TorchRoom';
import { PutUnder } from '../abilities/PutUnder';
import { RedPalantir } from '../items/RedPalantir';
import { GrailRoom } from '../rooms/GrailRoom';
import { Temple as TempleRoom } from '../rooms/Temple';
import { Altar } from '../rooms/Altar';
import { NarrowCrawlway } from '../rooms/NarrowCrawlway';
import { Cave2 } from '../rooms/Cave2';
import { EntranceToHades } from '../rooms/EntranceToHades';
import { Shovel } from '../items/Shovel';
import { SkeletonKeys } from '../items/SkeletonKeys';
import { RubyRoom } from '../rooms/RubyRoom';
import { Canary } from '../items/Canary';
import { Ring } from '../abilities/Ring';
import { PourOn } from '../abilities/PourOn';
import { Postcard } from '../items/Postcard';
import { TombOfTheUnknownImplementer } from '../rooms/TombOfTheUnknownImplementer';
import { VolcanoView } from '../rooms/VolcanoView';
import { Crypt } from '../rooms/Crypt';
import { Sundial } from '../items/Sundial';
import { SundialButton } from '../items/SundialButton';
import { Wish } from '../abilities/Wish';
import { Well } from '../items/Well';
import { Quit } from '../abilities/Quit';
import { ClearYesNo } from '../handlers/ClearYesNo';
import { Fill } from '../abilities/Fill';

export class Game {
    readonly lexicon: Lexicon;

    private readonly handlers: Handler[];

    readonly parsers: Parser<Value, Action>[];

    // TODO crs make this better
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly entities: Map<string, EntityConstructor<any>>;

    private entityCache: Map<string, Entity>;

    state: State;

    // Unsaved ephemeral state
    partial: ((target: Target) => Action) | undefined;

    previous: Action | undefined;

    yesNo: ((answer: boolean) => Promise<void>) | undefined;

    rawTotalScore: number;

    rawTotalEndgameScore: number;

    constructor() {
        this.handlers = [];
        this.parsers = [];
        this.lexicon = new Lexicon();
        this.entities = new Map();
        this.entityCache = new Map();
        this.state = {
            entities: {},
            it: null,
            them: null,
            score: 0,
            isEndgame: false,
            moveCount: 0,
            seed: Math.random() * Number.MAX_SAFE_INTEGER,
            deathCount: 0,
            timeUntilEndgameHerald: 0,
            hasEndgameBeenHeralded: false,
            endgameSpell: null,
        };
        this.partial = undefined;
        this.yesNo = undefined;
        this.previous = undefined;
        this.rawTotalScore = 0;
        this.rawTotalEndgameScore = 0;

        this.addHandlers(
            CountMoves.handler(),
            ClearYesNo.handler(),
            recordPreviousAction
        );

        this.addEntities(
            Player.spec(),
            Troll.spec(),
            Cyclops.spec(),
            Thief.spec(),
            Robot.spec(),
            ZurichGnome.spec(),
            Bat.spec(),
            VolcanoGnome.spec(),
            DungeonMaster.spec()
        );

        this.addEntities(
            NorthOfHouse.spec(),
            WestOfHouse.spec(),
            Leaflet.spec(),
            BehindHouse.spec(),
            SouthOfHouse.spec(),
            Kitchen.spec(),
            LivingRoom.spec(),
            Attic.spec(),
            Cellar.spec(),
            TrollRoom.spec(),
            Forest3.spec(),
            UpATree.spec(),
            Forest1.spec(),
            Forest2.spec(),
            Forest4.spec(),
            Forest5.spec(),
            Clearing.spec(),
            RoundRoom.spec(),
            EastWestPassage.spec(),
            NorthSouthPassage.spec(),
            LoudRoom.spec(),
            Maze1.spec(),
            Maze2.spec(),
            Maze3.spec(),
            Maze4.spec(),
            Maze5.spec(),
            Maze6.spec(),
            Maze7.spec(),
            Maze8.spec(),
            Maze9.spec(),
            Maze10.spec(),
            Maze11.spec(),
            Maze12.spec(),
            Maze13.spec(),
            Maze14.spec(),
            Maze15.spec(),
            DeadEnd1.spec(),
            DeadEnd2.spec(),
            DeadEnd3.spec(),
            DeadEnd4.spec(),
            GratingRoom.spec(),
            CyclopsRoom.spec(),
            LandOfTheLivingDead.spec(),
            StrangePassage.spec(),
            TreasureRoom.spec(),
            EngravingsCave.spec(),
            RiddleRoom.spec(),
            PearlRoom.spec(),
            CircularRoom.spec(),
            TopOfWell.spec(),
            TeaRoom.spec(),
            PoolRoom.spec(),
            LowRoom.spec(),
            MachineRoom.spec(),
            DingyCloset.spec(),
            Studio.spec(),
            WestOfChasm.spec(),
            NorthSouthCrawlway.spec(),
            Gallery.spec(),
            SmallBankRoom.spec(),
            WestTellersRoom.spec(),
            SafetyDepository.spec(),
            EastViewingRoom.spec(),
            EastTellersRoom.spec(),
            ChairmansOffice.spec(),
            BankEntrance.spec(),
            Vault.spec(),
            WestViewingRoom.spec(),
            DeepRavine.spec(),
            RockyCrawl.spec(),
            DomeRoom.spec(),
            TorchRoom.spec(),
            TinyRoom.spec(),
            DrearyRoom.spec(),
            MirrorRoom1.spec(),
            MirrorRoom2.spec(),
            GrailRoom.spec(),
            TempleRoom.spec(),
            Altar.spec(),
            NarrowCrawlway.spec(),
            Cave2.spec(),
            WindingPassage.spec(),
            EntranceToHades.spec(),
            TimberRoom.spec(),
            SteepCrawlway.spec(),
            SootyRoom.spec(),
            SlideRoom.spec(),
            SlideLedge.spec(),
            InASlide3.spec(),
            InASlide2.spec(),
            InASlide1.spec(),
            ColdPassage.spec(),
            Cave1.spec(),
            SqueakyRoom.spec(),
            MineEntrance.spec(),
            BatRoom.spec(),
            CoalMine1.spec(),
            CoalMine2.spec(),
            CoalMine3.spec(),
            CoalMine4.spec(),
            CoalMine5.spec(),
            CoalMine6.spec(),
            CoalMine7.spec(),
            WoodenTunnel.spec(),
            SmellyRoom.spec(),
            ShaftRoom.spec(),
            MineMachineRoom.spec(),
            LowerShaft.spec(),
            LadderTop.spec(),
            LadderBottom.spec(),
            GasRoom.spec(),
            CoalMineDeadEnd.spec(),
            Chasm.spec(),
            MaintenanceRoom.spec(),
            DeepCanyon.spec(),
            DampCave.spec(),
            DamLobby.spec(),
            DamBase.spec(),
            Dam.spec(),
            AtlantisRoom.spec(),
            StreamView.spec(),
            ReservoirSouth.spec(),
            ReservoirNorth.spec(),
            Reservoir.spec(),
            River1.spec(),
            River2.spec(),
            River3.spec(),
            River4.spec(),
            River5.spec(),
            WhiteCliffsBeach1.spec(),
            WhiteCliffsBeach2.spec(),
            Shore.spec(),
            SandyBeach.spec(),
            RockyShore.spec(),
            SmallCave.spec(),
            AncientDeadEnd2.spec(),
            AncientDeadEnd1.spec(),
            AncientChasm.spec(),
            RockyLedge.spec(),
            OnTheRainbow.spec(),
            EndOfTheRainbow.spec(),
            CanyonView.spec(),
            CanyonBottom.spec(),
            AragainFalls.spec(),
            RoomInAPuzzle11.spec(),
            RoomInAPuzzle12.spec(),
            RoomInAPuzzle13.spec(),
            RoomInAPuzzle14.spec(),
            RoomInAPuzzle15.spec(),
            RoomInAPuzzle16.spec(),
            RoomInAPuzzle21.spec(),
            RoomInAPuzzle22.spec(),
            RoomInAPuzzle24.spec(),
            RoomInAPuzzle25.spec(),
            RoomInAPuzzle26.spec(),
            RoomInAPuzzle31.spec(),
            RoomInAPuzzle32.spec(),
            RoomInAPuzzle33.spec(),
            RoomInAPuzzle34.spec(),
            RoomInAPuzzle36.spec(),
            RoomInAPuzzle41.spec(),
            RoomInAPuzzle42.spec(),
            RoomInAPuzzle43.spec(),
            RoomInAPuzzle44.spec(),
            RoomInAPuzzle45.spec(),
            RoomInAPuzzle46.spec(),
            RoomInAPuzzle51.spec(),
            RoomInAPuzzle52.spec(),
            RoomInAPuzzle53.spec(),
            RoomInAPuzzle54.spec(),
            RoomInAPuzzle55.spec(),
            RoomInAPuzzle56.spec(),
            RoomInAPuzzle63.spec(),
            RoomInAPuzzle64.spec(),
            RoomInAPuzzle65.spec(),
            SmallSquareRoom.spec(),
            SideRoom.spec(),
            EgyptianRoom.spec(),
            GlacierRoom.spec(),
            RubyRoom.spec(),
            WideLedge.spec(),
            VolcanoNearWideLedge.spec(),
            VolcanoNearViewingLedge.spec(),
            VolcanoNearSmallLedge.spec(),
            VolcanoCore.spec(),
            VolcanoBottom.spec(),
            NarrowLedge.spec(),
            Library.spec(),
            LavaRoom.spec(),
            DustyRoom.spec(),
            Stream.spec(),
            TombOfTheUnknownImplementer.spec(),
            VolcanoView.spec(),
            Crypt.spec(),
            TopOfStairs.spec(),
            WestNarrowRoom2.spec(),
            WestNarrowRoom1.spec(),
            WestGuardianNarrowRoom.spec(),
            WestCorridor.spec(),
            TreasuryOfZork.spec(),
            StoneRoom.spec(),
            SouthCorridor.spec(),
            SmallRoom.spec(),
            PrisonCell.spec(),
            Parapet.spec(),
            NorthCorridor.spec(),
            NarrowCorridor.spec(),
            InsideMirror.spec(),
            Hallway4.spec(),
            Hallway3.spec(),
            Hallway2.spec(),
            Hallway1.spec(),
            GuardianHallway.spec(),
            EastNarrowRoom3.spec(),
            EastNarrowRoom2.spec(),
            EastNarrowRoom1.spec(),
            EastGuardianNarrowRoom.spec(),
            EastCorridor.spec(),
            DungeonEntrance.spec(),
            WestNarrowRoom3.spec(),
            EastNarrowRoom4.spec(),
            WestNarrowRoom4.spec()
        );

        this.addEntities(
            Mailbox.spec(),
            WelcomeMat.spec(),
            FrontDoor.spec(),
            BarredHouseWindow.spec(),
            KitchenWindow.spec(),
            Sack.spec(),
            Bottle.spec(),
            KitchenTable.spec(),
            Garlic.spec(),
            Lunch.spec(),
            QuantityOfWater.spec(),
            Rug.spec(),
            TrapDoor.spec(),
            Lamp.spec(),
            Knife.spec(),
            Rope.spec(),
            Brick.spec(),
            AtticTable.spec(),
            TrophyCase.spec(),
            Newspaper.spec(),
            Sword.spec(),
            WoodenDoor.spec(),
            WoodenDoorLettering.spec(),
            Axe.spec(),
            Tree.spec(),
            Nest.spec(),
            Egg.spec(),
            Grating.spec(),
            PileOfLeaves.spec(),
            PlatinumBar.spec(),
            Skeleton.spec(),
            BagOfCoins.spec(),
            Stiletto.spec(),
            Chalice.spec(),
            StoneDoor.spec(),
            Engravings.spec(),
            PearlNecklace.spec(),
            WellBottomEtchings.spec(),
            WellTopEtchings.spec(),
            Bucket.spec(),
            EatMeCake.spec(),
            OblongTable.spec(),
            RedCake.spec(),
            BlueCake.spec(),
            OrangeCake.spec(),
            Pool.spec(),
            Saffron.spec(),
            Flask.spec(),
            GreenPaper.spec(),
            WhitePalantir.spec(),
            SecuritySticker.spec(),
            TriangularButton.spec(),
            RoundButton.spec(),
            SquareButton.spec(),
            SteelCage.spec(),
            ViolinCase.spec(),
            Painting.spec(),
            Portrait.spec(),
            StoneCube.spec(),
            Bills.spec(),
            NorthWall.spec(),
            EastWall.spec(),
            SouthWall.spec(),
            WestWall.spec(),
            Curtain.spec(),
            Dome.spec(),
            Torch.spec(),
            Railing.spec(),
            Pedestal.spec(),
            BluePalantir.spec(),
            RustyKey.spec(),
            OakDoorLid2.spec(),
            OakDoorLid1.spec(),
            OakDoorKeyhole2.spec(),
            OakDoorKeyhole1.spec(),
            OakDoor.spec(),
            Crack.spec(),
            PalantirTable.spec(),
            BarredWindow.spec(),
            Screwdriver.spec(),
            RedPalantir.spec(),
            MagicMirror.spec(),
            GrailPedestal.spec(),
            Grail.spec(),
            Prayer.spec(),
            Bell.spec(),
            Book.spec(),
            Candles.spec(),
            Slide.spec(),
            Timber.spec(),
            Stove.spec(),
            Jade.spec(),
            Coal.spec(),
            SapphireBracelet.spec(),
            Slag.spec(),
            Basket.spec(),
            MineLadder.spec(),
            Machine.spec(),
            Diamond.spec(),
            MachineSwitch.spec(),
            Bubble.spec(),
            Bolt.spec(),
            GuideBook.spec(),
            Matchbook.spec(),
            BlueButton.spec(),
            RedButton.spec(),
            YellowButton.spec(),
            BrownButton.spec(),
            Gunk.spec(),
            Tube.spec(),
            ToolChests.spec(),
            Wrench.spec(),
            DamLeak.spec(),
            Pump.spec(),
            TrunkOfJewels.spec(),
            Trident.spec(),
            Lungs.spec(),
            Boat.spec(),
            Hands.spec(),
            Stick.spec(),
            BoatInstructions.spec(),
            Sand.spec(),
            Shovel.spec(),
            Buoy.spec(),
            Emerald.spec(),
            Statue.spec(),
            Guano.spec(),
            Rainbow.spec(),
            Cliffs.spec(),
            PotOfGold.spec(),
            Barrel.spec(),
            RustyKnife.spec(),
            SkeletonKeys.spec(),
            BrokenLantern.spec(),
            WestMarbleWall.spec(),
            EastMarbleWall.spec(),
            NorthMarbleWall.spec(),
            SouthMarbleWall.spec(),
            NorthSandstoneWall.spec(),
            SouthSandstoneWall.spec(),
            WestSandstoneWall.spec(),
            EastSandstoneWall.spec(),
            WestLadder.spec(),
            EastLadder.spec(),
            GoldCard.spec(),
            SteelDoor.spec(),
            Slit.spec(),
            WarningNote.spec(),
            Coffin.spec(),
            Glacier.spec(),
            Ruby.spec(),
            Safe.spec(),
            Balloon.spec(),
            BlueLabel.spec(),
            ClothBag.spec(),
            BraidedWire.spec(),
            BalloonReceptacle.spec(),
            Hook1.spec(),
            Hook2.spec(),
            Zorkmid.spec(),
            WhiteBook.spec(),
            BlueBook.spec(),
            GreenBook.spec(),
            PurpleBook.spec(),
            FlatheadStamp.spec(),
            SafeHole.spec(),
            Fuse.spec(),
            WarningCard.spec(),
            Crown.spec(),
            VolcanoGnomeDoor.spec(),
            Violin.spec(),
            Canary.spec(),
            Bauble.spec(),
            Grue.spec(),
            Ghosts.spec(),
            Postcard.spec(),
            Brochure.spec(),
            DonWoodsStamp.spec(),
            PoledHeads.spec(),
            CryptDoor.spec(),
            LargeCase.spec(),
            CokeBottles.spec(),
            Code.spec(),
            RedBeam.spec(),
            RedEndgameButton.spec(),
            Mirror1.spec(),
            ShortPole.spec(),
            RedPanel.spec(),
            WhitePanel.spec(),
            BlackPanel.spec(),
            YellowPanel.spec(),
            MahoganyPanel.spec(),
            PinePanel.spec(),
            SidePanel.spec(),
            Mirror2.spec(),
            MirrorPanel1.spec(),
            MirrorPanel2.spec(),
            WoodenBar.spec(),
            TBar.spec(),
            LongPole.spec(),
            Arrow.spec(),
            Guardians.spec(),
            QuestionDoor.spec(),
            CellDoor.spec(),
            BronzeDoor.spec(),
            Sundial.spec(),
            SundialButton.spec(),
            GraniteWall.spec(),
            House.spec(),
            PoledCretinHead.spec(),
            DeadBodies.spec(),
            GlobalTree.spec(),
            PoolLeak.spec(),
            Songbird.spec(),
            WhiteCliffs.spec(),
            Well.spec(),
            Blessings.spec(),
            River.spec(),
            ReservoirWater.spec(),
            Ground.spec(),
            Teeth.spec()
        );

        this.addAbilities(
            Ulysses.ability(),
            Look.ability(),
            Inventory.ability(),
            Score.ability(),
            Save.ability(),
            Load.ability(),
            Open.ability(),
            Close.ability(),
            Read.ability(),
            Take.ability(),
            Drop.ability(),
            PutIn.ability(),
            Go.ability(),
            Eat.ability(),
            Drink.ability(),
            Move.ability(),
            Raise.ability(),
            LookUnder.ability(),
            TurnOn.ability(),
            TurnOff.ability(),
            Wait.ability(),
            Kill.ability(),
            Diagnose.ability(),
            Echo.ability(),
            Bug.ability(),
            Feature.ability(),
            Give.ability(),
            Wake.ability(),
            Poke.ability(),
            Throw.ability(),
            Hello.ability(),
            Answer.ability(),
            Board.ability(),
            Disembark.ability(),
            Pour.ability(),
            Tell.ability(),
            Push.ability(),
            Mung.ability(),
            Jump.ability(),
            Rub.ability(),
            GoThrough.ability(),
            Tie.ability(),
            Untie.ability(),
            Climb.ability(),
            PutUnder.ability(),
            Unlock.ability(),
            LookIn.ability(),
            Turn.ability(),
            Light.ability(),
            Extinguish.ability(),
            Curse.ability(),
            Pray.ability(),
            Lower.ability(),
            Lubricate.ability(),
            Squeeze.ability(),
            Plug.ability(),
            BlowUp.ability(),
            Inflate.ability(),
            Deflate.ability(),
            BlowInto.ability(),
            Launch.ability(),
            Land.ability(),
            Dig.ability(),
            Wave.ability(),
            Geronimo.ability(),
            Melt.ability(),
            Play.ability(),
            Wind.ability(),
            Find.ability(),
            Exorcise.ability(),
            Ring.ability(),
            PourOn.ability(),
            Enter.ability(),
            Exit.ability(),
            Knock.ability(),
            Stay.ability(),
            Follow.ability(),
            Spin.ability(),
            SetTo.ability(),
            Treasure.ability(),
            Temple.ability(),
            Kick.ability(),
            Wish.ability(),
            Examine.ability(),
            Shake.ability(),
            Plugh.ability(),
            Foo.ability(),
            Skip.ability(),
            TieUp.ability(),
            Mumble.ability(),
            Repent.ability(),
            Chomp.ability(),
            Frobozz.ability(),
            Win.ability(),
            Yell.ability(),
            Honk.ability(),
            Smell.ability(),
            Oops.ability(),
            Count.ability(),
            Request.ability(),
            Quit.ability(),
            Yes.ability(),
            No.ability(),
            Restart.ability(),
            Incant.ability(),
            Swim.ability(),
            Fill.ability(),
            Brush.ability()
        );

        this.addAbilities(SpecialThen.ability());
        // TODO clean this up
        this.parsers.unshift(this.parsers.pop()!);

        this.addAbilities(
            SpecialAgain.ability(),
            SpecialUnknownWord.ability(),
            SpecialMostlyUnderstand.ability(),
            SpecialPartial.ability()
        );

        this.addHandlers(
            noParseHandler,
            startUp,
            loadError,
            loadDone,
            specialEnterHandler,
            specialLookHandler,
            specialDescribeRoomHandler,
            doNotDescribeSelf,
            specialDescribeHandler,
            specialListContentsHandler,
            specialTryTakeHandler,
            SpecialGo.handler,
            SpecialJigsUp.handler,
            TickTimers.handler(),
            Melee.handler(),
            PrepareEndgame.handler(),
            EndTick.handler()
        );
    }

    async start(runner: Runner) {
        await this.applyAction(runner, new SpecialStartUp());
    }

    parseCommand(command: string) {
        const result = Parse.any(...this.parsers)
            .end()
            .matchOne(tokenize(command));
        const action = result
            ? (result.token.value as Action)
            : new SpecialNoParse({ command });
        action.command = command;
        return action;
    }

    async applyHandlers(
        runner: Runner,
        action: Action,
        handlers: Handler[],
        actor?: Actor
    ): Promise<Effect | undefined> {
        const handler = handlers[0];
        if (handler === undefined) return;

        const extensions: Extensions = {
            deferHandling: async () =>
                this.applyHandlers(runner, action, handlers.slice(1), actor),
            replaceAction: async (newAction: Action) =>
                this.applyHandlers(runner, newAction, handlers.slice(1), actor),
        };

        const result = await handler({
            game: this,
            action,
            actor,
            extensions,
            runner,
        });
        if (result !== undefined) {
            return result;
        }

        return extensions.deferHandling();
    }

    async applyAction(
        runner: Runner,
        action: Action,
        actor?: Actor
    ): Promise<Effect> {
        if (actor === undefined) actor = this.get(Player.spec().ref) as Actor;
        const result = await this.applyHandlers(
            runner,
            action,
            this.handlers,
            actor
        );
        await runner.doDebug('action', action, result);
        if (result === undefined) {
            await runner.doDebug('Action was not handled!');
            return Action.complete({ withConsequence: false });
        }
        return result;
    }

    async saveState(runner: Runner, name: string | undefined) {
        await runner.saveState(this.state, name);
    }

    async loadState(runner: Runner, name: string | undefined) {
        const loaded = await runner.loadState(name);
        if (loaded === LoadError.Invalid || loaded === LoadError.NotFound) {
            await this.applyAction(
                runner,
                new SpecialLoadError({ error: loaded })
            );
        } else {
            this.state = loaded;
            this.state.seed = Math.random() * Number.MAX_SAFE_INTEGER;
            this.entityCache = new Map();
            await this.applyAction(runner, new SpecialLoadDone());
        }
        return true;
    }

    async quit(runner: Runner, reason = 'quit') {
        await this.printGameSummary(runner, false, this.moveCount(), reason);
        await runner.quit();
    }

    async restart(runner: Runner) {
        await this.printGameSummary(
            runner,
            false,
            this.moveCount(),
            'restarted'
        );
        await runner.restart();
    }

    async printGameSummary(
        runner: Runner,
        is: boolean,
        moveCount: number,
        reason: string | undefined
    ) {
        const moves = pluralize(moveCount, 'move');
        const endgame = this.isInEndgame() ? 'in the end game ' : '';
        if (reason) {
            await runner.doOutput(`You ${reason}.`);
        }
        const isWas = is ? 'is' : 'was';
        const scoreRatio = this.state.score / this.totalScore();

        let rank;
        if (this.isInEndgame()) {
            if (scoreRatio === 1) {
                rank = 'Dungeon Master';
            } else if (scoreRatio > 0.75) {
                rank = 'Super Cheater';
            } else if (scoreRatio > 0.5) {
                rank = 'Master Cheater';
            } else if (scoreRatio > 0.25) {
                rank = 'Advanced Cheater';
            } else {
                rank = 'Cheater';
            }
        } else if (scoreRatio === 1) {
            rank = 'Cheater';
        } else if (scoreRatio > 0.95) {
            rank = 'Wizard';
        } else if (scoreRatio > 0.89) {
            rank = 'Master';
        } else if (scoreRatio > 0.79) {
            rank = 'Winner';
        } else if (scoreRatio > 0.6) {
            rank = 'Hacker';
        } else if (scoreRatio > 0.39) {
            rank = 'Adventurer';
        } else if (scoreRatio > 0.19) {
            rank = 'Junior Adventurer';
        } else if (scoreRatio > 0.09) {
            rank = 'Novice Adventurer';
        } else if (scoreRatio > 0.049) {
            rank = 'Amateur Adventurer';
        } else if (scoreRatio >= 0) {
            rank = 'Beginner';
        } else {
            rank = 'Incompetent';
        }

        await runner.doOutput(
            `Your score ${endgame}${isWas} ${
                this.state.score
            } [total of ${this.totalScore()} points], in ${moveCount} ${moves}.`
        );
        await runner.doOutput(`This gives you the rank of ${rank}.`);
    }

    async applyCommand(runner: Runner, command: string, actor?: Actor) {
        if (actor === undefined) actor = this.get(Player.spec().ref) as Actor;
        const action = this.parseCommand(command);
        await runner.doDebug('parse', action);
        const effect = await this.applyAction(runner, action, actor);
        await this.applyAction(runner, new SpecialAfter({ action, effect }));
    }

    addAbility(ability: Ability) {
        ability.verbs.forEach((verb) => this.lexicon.addVerb(verb));
        ability.prepositions.forEach((prepositions) =>
            this.lexicon.addPreposition(prepositions)
        );
        ability.handlers.forEach((handler) => this.addHandler(handler));
        this.addParser(ability.parser(this));
    }

    addAbilities(...abilities: Ability[]) {
        abilities.forEach((ability) => this.addAbility(ability));
    }

    addHandlers(...handlers: Handler[]) {
        handlers.forEach((handler) => this.addHandler(handler));
    }

    addHandler(handler: Handler) {
        this.handlers.push(handler);
    }

    addParser(parser: Parser<Value, Action>) {
        this.parsers.push(parser);
    }

    addEntity<T extends Entity>({
        ref,
        constructor,
        initial,
        nouns,
        adjectives,
        handlers,
    }: EntitySpec<T>) {
        nouns.forEach((noun) => this.lexicon.addNoun(noun));
        adjectives.forEach((adjective) => this.lexicon.addAdjective(adjective));
        this.entities.set(ref, constructor);
        this.state.entities[ref] = initial;
        if (handlers) {
            handlers.forEach((handler) => this.addHandler(handler));
        }
        const entity = this.get(ref);
        if (entity.isRoom()) {
            if (entity.isPartOfEndgame()) {
                this.rawTotalEndgameScore += entity.miscellaneousScore();
                this.rawTotalEndgameScore += entity.scoreOnEntry();
            } else {
                this.rawTotalScore += entity.miscellaneousScore();
                this.rawTotalScore += entity.scoreOnEntry();
            }
        } else {
            this.rawTotalScore += entity.miscellaneousScore();
            if (entity.isItem() && entity.isTreasure()) {
                this.rawTotalScore +=
                    entity.scoreOnTake() + entity.scoreInCase();
            }
        }
    }

    // TODO crs find a way to make this less bad
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addEntities(...entities: EntitySpec<any>[]) {
        entities.forEach((entity) => this.addEntity(entity));
    }

    get(ref: Reference | undefined): Entity {
        if (ref === undefined) {
            throw new Error('Cannot get entity without reference.');
        }
        const cached = this.entityCache.get(ref);
        if (cached) return cached;

        const constructor = this.entities.get(ref);
        if (constructor === undefined) {
            throw new Error(`Could not find entity with ID '${ref}'.`);
        }
        const result = new constructor(this, this.state.entities[ref]);
        // TODO For some reason the mixin classes are causing the state to not actually get set during
        //      construction. Why is this?
        result.state = this.state.entities[ref];
        this.entityCache.set(ref, result);
        return result;
    }

    ent<T extends Entity>(c: EntityConstructor<T>): T {
        return this.get(c.spec().ref) as T;
    }

    findEntity(
        entity: Entity
    ): (Actor | Room | (Item & Container)) | undefined {
        // TODO crs maybe make this more efficient? Probably doesn't matter...
        for (const containerRef of Object.keys(this.state.entities)) {
            const container = this.get(containerRef);
            if (
                ((container.isItem() && container.isContainer()) ||
                    container.isRoom() ||
                    container.isActor()) &&
                container.contains(entity)
            ) {
                return container;
            }
        }
    }

    locateEntity(entity: Entity): Room | undefined {
        const container = entity.container();
        if (container !== undefined) {
            if (container.isRoom()) {
                return container;
            }
            return this.locateEntity(container);
        }
    }

    random(): number {
        const { seed, result } = mulberry32(this.state.seed);
        this.state.seed = seed;
        return result;
    }

    choiceOf<T>(options: T[]): T {
        return options[Math.floor(this.random() * options.length)];
    }

    totalScore(): number {
        return this.isInEndgame()
            ? this.rawTotalEndgameScore
            : this.rawTotalScore;
    }

    // Helpers

    async resolve(
        runner: Runner,
        target: Target | undefined,
        actor: Actor | undefined,
        options: {
            absent?: (item: string) => string;
            missing?: () => string;
            allowGroups?: boolean;
            onlyVisible?: boolean;
            condition?: (item: Entity) => boolean;
            silent?: boolean;
            partial?: (target: Target) => Action;
            ambiguous?: (
                description: string,
                options: string,
                not: string
            ) => string;
        }
    ): Promise<{ item: Entity | undefined; items: Entity[]; plural: boolean }> {
        if (actor === undefined) {
            throw new Error('Expected actor to be present');
        }
        const result = await resolve(this, runner, actor, {
            target,
            ...options,
        });
        if (result === undefined) {
            return { item: undefined, items: [], plural: false };
        }
        return result;
    }

    async reach(
        runner: Runner,
        item: Entity,
        actor: Actor | undefined,
        options: {
            silent?: boolean;
        } = { silent: false }
    ): Promise<boolean> {
        const canReach = actor?.canReachItem(item);
        if (!canReach && !options.silent) {
            await runner.doOutput(`I can't reach ${item.the()}.`);
        }
        return !!canReach;
    }

    async tryTake(runner: Runner, item: Entity, actor: Actor | undefined) {
        await this.applyAction(
            runner,
            new SpecialTryTake({
                item,
            }),
            actor
        );
        return actor?.hasItem(item);
    }

    async take(runner: Runner, item: Entity, actor: Actor | undefined) {
        if (actor && !actor.hasItem(item)) {
            await runner.doOutput(`First taking the ${item}: `, {
                newLine: false,
            });
            await this.applyAction(
                runner,
                new Take({ item, container: undefined }),
                actor
            );
        }
        return actor?.hasItem(item);
    }

    async have(runner: Runner, item: Entity, actor: Actor | undefined) {
        if (actor && !actor.hasItem(item)) {
            await runner.doOutput(`You do not have ${item.the()}.`);
            return false;
        }
        return true;
    }

    allEntities(): Entity[] {
        return [...this.entities.keys()].map((ref) => this.get(ref));
    }

    /* Accessors */

    score() {
        return this.state.score;
    }

    setScore(score: number) {
        this.state.score = score;
    }

    deathCount() {
        return this.state.deathCount;
    }

    setDeathCount(deathCount: number) {
        this.state.deathCount = deathCount;
    }

    timeUntilEndgameHerald() {
        return this.state.timeUntilEndgameHerald;
    }

    setTimeUntilEndgameHerald(timeUntilEndgameHerald: number) {
        this.state.timeUntilEndgameHerald = timeUntilEndgameHerald;
    }

    isReadyForEndgame() {
        return (
            this.score() >= this.totalScore() ||
            // TODO remove magic number
            (this.score() >= this.totalScore() - 10 && this.deathCount() === 1)
        );
    }

    hasEndgameBeenHeralded() {
        return this.state.hasEndgameBeenHeralded;
    }

    setHasEndgameBeenHeralded(hasEndgameBeenHeralded: boolean) {
        this.state.hasEndgameBeenHeralded = hasEndgameBeenHeralded;
    }

    isInEndgame() {
        return this.state.isEndgame;
    }

    setIsInEndgame(isInEndgame: boolean) {
        this.state.isEndgame = isInEndgame;
    }

    moveCount() {
        return this.state.moveCount;
    }

    here() {
        return this.ent(Player).location();
    }

    endgameSpell() {
        return this.state.endgameSpell;
    }

    setEndgameSpell(endgameSpell: string) {
        this.state.endgameSpell = endgameSpell;
    }
}
