import { MirrorRoom1, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { AtlantisRoom } from '../AtlantisRoom';

export class Cave1 extends Room {
    static spec(): EntitySpec<Cave1> {
        return {
            ref: 'cave-1',
            constructor: Cave1,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Cave1.spec().ref;
    }

    name(): string {
        return 'Cave';
    }

    description(): string {
        return 'This is a small cave with an entrance to the north and a stairway leading down.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: MirrorRoom1.spec().ref,
            }),
            new Passage({
                via: Direction.Down,
                to: AtlantisRoom.spec().ref,
            }),
        ];
    }
}
