import { Entity, Reference } from '../game';
import { FightRemarks } from '../handlers';

export interface ItemState {}

export interface TakeableState {
    hasBeenTaken: boolean;
}

export interface Takeable {
    state: TakeableState;

    hasBeenTaken(): boolean;
    canBeTaken(): boolean;
    shouldTryToTake(): boolean;
    scoreOnTake(): number;
}

export interface ContainerState {
    contents: Reference[];
}

export interface Vehicle extends Container {
    isVehicle(): boolean;
}

export interface Examinable {
    examineText(): string;
}

export interface Container {
    state: ContainerState;

    canSeeInto(): boolean;
    canReachInto(): boolean;
    isEmpty(): boolean;
    contentsString(): string;
    totalCapacity(): number | undefined;
    contents(): Entity[];
    contains(entity: Entity): boolean;
    removeEntity(entity: Entity): void;
    addEntity(entity: Entity): void;
    remainingCapacity(): number | undefined;
}

export interface OpenableState {
    isOpen: boolean;
}

export interface Openable {
    state: OpenableState;

    canBeOpened(): boolean;
    isOpen(): boolean;
}

export interface FlammableState {
    isAflame: boolean;
}

export interface Flammable {
    state: FlammableState;

    isFlammable(): boolean;
    isAflame(): boolean;
}

export interface Readable {
    text(): string;
    canBeRead(): boolean;
    shouldTakeToRead(): boolean;
}

export interface Weapon {
    fightRemarks(): FightRemarks;
    isWeapon(): boolean;
}

export interface Treasure {
    scoreOnTake(): number;
    scoreInCase(): number;
    isTreasure(): boolean;
}

export abstract class Item<S extends ItemState = ItemState> extends Entity<S> {
    initialDescription(): string | undefined {
        return undefined;
    }

    isHidden(): boolean {
        return false;
    }

    isFood(): boolean {
        return false;
    }

    isLiquid(): boolean {
        return false;
    }

    canBeMoved(): boolean {
        return this.isTakeable();
    }

    isTransparent(): boolean {
        return false;
    }

    size(): number {
        return 5;
    }

    shouldTryToTake() {
        return true;
    }

    isTakeable(): this is Takeable {
        return false;
    }

    isReadable(): this is Readable {
        return false;
    }

    isContainer(): this is Container {
        return false;
    }

    isOpenable(): this is Openable {
        return false;
    }

    isWeapon(): this is Weapon {
        return false;
    }

    isVehicle(): this is Vehicle {
        return false;
    }

    isTool(): boolean {
        return false;
    }

    shouldTryToOpen(): boolean {
        return this.isOpenable();
    }

    isItem(): this is Item {
        return true;
    }

    isTreasure(): this is Treasure {
        return false;
    }

    shouldBeDescribed(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    canBeFastened(): boolean {
        return false;
    }

    canBeClimbed(): boolean {
        return false;
    }

    canBeTurned(): boolean {
        return false;
    }

    canBeDug(): boolean {
        return false;
    }

    isWall(): boolean {
        return false;
    }

    // TODO use this elsewhere
    isDoor(): boolean {
        return false;
    }

    isFlammable(): this is Flammable {
        return false;
    }

    location() {
        return this.game.locateEntity(this);
    }

    isBodyOfWater() {
        return false;
    }
}
