import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Score extends makeDescribable(Action) {
    id = 'score';

    description(game: Game): string {
        return 'check your score';
    }

    static ability(): Ability {
        const verbs = [new Verb('score'), new Verb('check score')];
        return {
            handlers: [scoreHandler],
            parser: () => Parse.words(verbs).into(new Score()),
            verbs,
            prepositions: [],
        };
    }
}

export const scoreHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Score)) return;

    const moveCountAfterThis = game.state.moveCount + 1;
    await game.printGameSummary(runner, true, moveCountAfterThis, undefined);
    return Action.complete();
};
