import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { MungUnresolved, mungUnresolvedHandler } from './MungUnresolved';
import { performBlow } from '../../handlers';
import { HO_HUM } from '../../constants';

export class Mung extends Action {
    id = '~mung';

    weapon: Entity | undefined;

    item: Entity;

    constructor({
        weapon,
        item,
    }: {
        weapon: Entity | undefined;
        item: Entity;
    }) {
        super();
        this.weapon = weapon;
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [mungHandler, mungUnresolvedHandler],
            parser,
            verbs: [
                new Verb('mung'),
                new Verb('destroy'),
                new Verb('damage'),
                new Verb('break'),
                new Verb('smash'),
            ],
            prepositions: [new Preposition('with'), new Preposition('using')],
        };
    }
}

export const mungHandler: Handler = async ({ action, actor, runner, game }) => {
    if (!action.is(Mung) || actor === undefined) return;

    const { weapon, item } = action;

    if (!item.isActor() || !item.isVillain() || !item.isVictim()) {
        await runner.doOutput(
            `Trying to destroy ${item.an()} ${game.choiceOf(HO_HUM)}`
        );
    } else if (weapon === undefined) {
        await runner.doOutput(
            `Trying to destroy ${item.the()} with your bare hands is suicidal.`
        );
    } else if (!weapon.isItem() || !weapon.isWeapon()) {
        await runner.doOutput(
            `Trying to destroy ${item.an()} with ${weapon.an()} is quite self-destructive.`
        );
    } else {
        await performBlow(game, runner, actor, item, weapon, undefined);
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, MungUnresolved> => {
    const ability = Mung.ability();
    const mung = Parse.words(ability.verbs);
    const mungEnemy = mung.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const mungEnemyWith = mungEnemy.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((container) => [item, container])
    );
    return Parse.any(
        // mung
        mung.into(new MungUnresolved({ weapon: undefined, item: undefined })),
        // mung troll
        mungEnemy.map(
            (item) => new MungUnresolved({ weapon: undefined, item })
        ),
        // mung troll with sword
        mungEnemyWith.map(
            ([item, weapon]) => new MungUnresolved({ weapon, item })
        )
    );
};
