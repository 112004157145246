import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeFlammable, makeTakeable } from '../../game/Entity';
import { Light, SpecialJigsUp, SpecialTimerTick } from '../../abilities';
import { Brick } from '../Brick';
import { Player } from '../../actors';
import { LivingRoom, MungedSource } from '../../rooms';
import { SafeHole } from '../SafeHole';
import { Safe } from '../Safe';
import { TrophyCase } from '../TrophyCase';

interface FuseState extends ItemState {
    timeRemaining: number;
}

abstract class Base extends Item<FuseState> {}

export class Fuse extends makeFlammable(makeTakeable(Base)) {
    static spec(): EntitySpec<Fuse> {
        return {
            ref: 'fuse',
            constructor: Fuse,
            initial: {
                hasBeenTaken: false,
                isAflame: false,
                timeRemaining: 2,
            },
            nouns: [
                new Noun('fuses', { plural: true }),
                new Noun('fuse'),
                new Noun('wire', { collective: true }),
                new Noun('coils of wire', { plural: true }),
                new Noun('coil of wire'),
                new Noun('coil'),
                new Noun('coils', { plural: true }),
            ],
            adjectives: [new Adjective('shiny'), new Adjective('thin')],
            handlers: [lightFuse, burnFuse],
        };
    }

    ref() {
        return Fuse.spec().ref;
    }

    name(): string {
        return 'coil of wire';
    }

    description(): string {
        return 'There is a coil of thin shiny wire here.';
    }

    nouns() {
        return Fuse.spec().nouns;
    }

    adjectives() {
        return Fuse.spec().adjectives;
    }

    size() {
        return 1;
    }

    setTimeRemaining(timeRemaining: number) {
        this.state.timeRemaining = timeRemaining;
    }

    timeRemaining() {
        return this.state.timeRemaining;
    }
}

const lightFuse: Handler = async ({ action, runner }) => {
    if (
        action.is(Light) &&
        action.item.is(Fuse) &&
        action.tool?.isItem() &&
        action.tool.isFlammable() &&
        action.tool.isAflame() &&
        !action.item.isAflame()
    ) {
        action.item.state.isAflame = true;
        action.item.setTimeRemaining(2);
        await runner.doOutput('The wire starts to burn.');
        return Action.complete();
    }
};

const burnFuse: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const fuse = game.ent(Fuse);
        if (fuse.isAflame()) {
            fuse.setTimeRemaining(fuse.timeRemaining() - 1);
            if (fuse.timeRemaining() === 0) {
                const brick = game.ent(Brick);
                fuse.state.isAflame = false;
                const player = game.ent(Player);
                const location = player.location();
                if (brick.contains(fuse)) {
                    if (location.hasItem(brick) || player.hasItem(brick)) {
                        location.state.isMunged = true;
                        location.state.howMunged = MungedSource.Exploded;
                        await game.applyAction(
                            runner,
                            new SpecialJigsUp({
                                message:
                                    "Now you've done it. It seems that the brick has other properties than weight, " +
                                    'namely the ability to blow you to smithereens.',
                            })
                        );
                    } else {
                        await runner.doOutput('There is an explosion nearby.');
                    }
                    const brickLocation = game.locateEntity(brick);
                    const safeHole = game.ent(SafeHole);
                    if (safeHole.contains(brick)) {
                        game.ent(Safe).setIsOpen(true);
                        safeHole.moveTo(undefined);
                    }
                    brick.setExplodedInRoom(brickLocation);
                    if (brickLocation) {
                        for (const item of brickLocation.contents()) {
                            if (item.isItem() && item.isTakeable()) {
                                item.moveTo(undefined);
                            }
                        }
                        if (brickLocation.is(LivingRoom)) {
                            game.ent(TrophyCase)
                                .contents()
                                .forEach((item) => item.moveTo(undefined));
                        }
                    }
                    brick.moveTo(undefined);
                } else if (location.hasItem(fuse)) {
                    await runner.doOutput(
                        'The wire rapidly burns into nothingness.'
                    );
                }
                fuse.moveTo(undefined);
            }
        }

        return Action.incomplete();
    }
};
