import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    SqueezeUnresolved,
    squeezeUnresolvedHandler,
} from './SqueezeUnresolved';

export class Squeeze extends Action {
    id = '~squeeze';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [squeezeHandler, squeezeUnresolvedHandler],
            parser,
            verbs: [new Verb('squeeze')],
            prepositions: [],
        };
    }
}

export const squeezeHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Squeeze)) return;
    const { item } = action;
    if (item.isActor()) {
        await runner.doOutput(`${item.The()} does not understand this.`);
    } else {
        await runner.doOutput('How singularly useless.');
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, SqueezeUnresolved> => {
    const squeeze = Parse.words(Squeeze.ability().verbs);
    const squeezeObject = squeeze.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // squeeze
        squeeze.into(new SqueezeUnresolved({ item: undefined })),
        // squeeze button
        squeezeObject.map((item) => new SqueezeUnresolved({ item }))
    );
};
