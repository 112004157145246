import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Portrait extends makeFlammable(makeTreasure(makeTakeable(Item))) {
    static spec(): EntitySpec<Portrait> {
        return {
            ref: 'portrait',
            constructor: Portrait,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('portrait'),
                new Noun('portraits', { plural: true }),
                new Noun('painting'),
                new Noun('paintings', { plural: true }),
                new Noun('art', { collective: true }),
                new Noun('work of art'),
                new Noun('works of art', { plural: true }),
            ],
            adjectives: [new Adjective('flathead')],
        };
    }

    ref() {
        return Portrait.spec().ref;
    }

    name() {
        return 'portrait of J. Pierpoint Flathead';
    }

    description() {
        return 'The portrait of J. Pierpont Flathead is here.';
    }

    initialDescription() {
        return 'A portrait of J. Pierpont Flathead hangs on the wall.';
    }

    size() {
        return 25;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 5;
    }

    nouns(): Noun[] {
        return Portrait.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Portrait.spec().adjectives;
    }

    // TODO items being sacred?
    isSacred(): boolean {
        return true;
    }

    examineText(): string {
        return PORTRAIT;
    }
}

const PORTRAIT = `
               ||||||||||||||
              ||   __  __   ||
              ||   $$  $$   ||
              \\||    >>    ||/
               ||  ______  ||
                |  -//--   |
                \\\\\\\\_//_____//
               ___//|  |
              /__// |  |
                    |  |
         __________//  \\\\\\\\__________
        / $ /       ****       \\ $ \\\\
       /   /         **         \\   \\\\
      /   /|         **         |\\   \\\\
     /   / |         **         | \\   \\\\
    /   /  |         **         |  \\   \\\\
    ^   ^__|______$Z$**$Z$______|___^   ^
    \\         *   $Z$**$Z$   *         /
     \\________*___$Z$**$Z$___*________/
           |      $Z$**$Z$      |

           J.  PIERPONT  FLATHEAD
                  CHAIRMAN
`;
