import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { Leaflet } from '../index';
import { makeContainer, makeOpenable } from '../../game/Entity';
import { EntitySpec } from '../../game';

export class Mailbox extends makeOpenable(makeContainer(Item)) {
    static spec(): EntitySpec<Mailbox> {
        return {
            ref: 'mailbox',
            constructor: Mailbox,
            initial: {
                contents: [Leaflet.spec().ref],
                isOpen: false,
            },
            nouns: [
                new Noun('mailbox'),
                new Noun('mail box'),
                new Noun('box'),
                new Noun('mailboxes', { plural: true }),
                new Noun('mail boxes', { plural: true }),
                new Noun('boxes', { plural: true }),
            ],
            adjectives: [new Adjective('small')],
        };
    }

    ref() {
        return Mailbox.spec().ref;
    }

    name(): string {
        return 'mailbox';
    }

    description(): string {
        return 'There is a small mailbox here.';
    }

    totalCapacity(): number {
        return 10;
    }

    canBeOpened(): boolean {
        return true;
    }

    canSeeInto(): boolean {
        return this.isOpen() || this.isTransparent();
    }

    nouns() {
        return Mailbox.spec().nouns;
    }

    adjectives() {
        return Mailbox.spec().adjectives;
    }
}
