import { Action, Entity, Handler } from '../../game';
import { SpecialAction } from '../SpecialAction';
import { Player } from '../../actors';

export class SpecialDescribe extends SpecialAction {
    id = '*describe';

    item: Entity;

    indent: number;

    constructor({ item, indent = 0 }: { item: Entity; indent?: number }) {
        super();
        this.item = item;
        this.indent = indent;
    }
}

export const specialDescribeHandler: Handler = async ({
    action,
    runner,
    game,
}) => {
    if (!action.is(SpecialDescribe)) return;
    const indent = '  '.repeat(action.indent);
    const { item } = action;
    let description;
    if (item.isItem()) {
        if (!item.shouldBeDescribed() || item.isHidden()) {
            return Action.complete({ withConsequence: false });
        }
        if (item.isVehicle() && item.contains(game.ent(Player))) {
            description = `You are in ${item.the()}.`;
        } else if (item.isTakeable() && item.hasBeenTaken()) {
            description = item.description();
        } else {
            description = item.initialDescription() || item.description();
        }
    } else {
        description = item.description();
    }
    if (description) {
        await runner.doOutput(`${indent}${description}`);
    }
    return Action.complete({ withConsequence: false });
};
