import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import {
    LookIn,
    Mung,
    Open,
    Poke,
    Push,
    SpecialTimerTick,
} from '../../abilities';
import { RedEndgameButton } from '../RedEndgameButton';
import { InsideMirror, SmallRoom } from '../../rooms';
import { Player } from '../../actors';
import { mirrorDoorIsVisibleHere } from '../../rooms/InsideMirror/mirror_utils';

interface Mirror1State extends ItemState {
    isMunged: boolean;
}

abstract class Base extends Item<Mirror1State> {}

export class Mirror1 extends Base {
    static spec(): EntitySpec<Mirror1> {
        return {
            ref: 'mirror-1',
            constructor: Mirror1,
            initial: {
                isMunged: false,
            },
            nouns: [new Noun('mirror'), new Noun('mirrors', { plural: true })],
            adjectives: [
                new Adjective('broken'),
                new Adjective('shattered'),
                new Adjective('munged'),
            ],
            handlers: [
                openCloseMirrorTimer,
                pushMirrorDoor,
                lookInMirror,
                openMirror,
                breakMirror,
            ],
        };
    }

    ref() {
        return Mirror1.spec().ref;
    }

    name(): string {
        return this.isMunged() ? 'broken mirror' : 'mirror';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Mirror1.spec().nouns;
    }

    adjectives() {
        return this.isMunged()
            ? [
                  new Adjective('broken'),
                  new Adjective('shattered'),
                  new Adjective('munged'),
              ]
            : [];
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isMunged(): boolean {
        return this.state.isMunged;
    }

    setIsMunged(isMunged: boolean) {
        this.state.isMunged = isMunged;
    }
}

const openCloseMirrorTimer: Handler = async ({ action, game, runner }) => {
    if (action.is(SpecialTimerTick) && game.isInEndgame()) {
        const structure = game.ent(InsideMirror);
        const button = game.ent(RedEndgameButton);
        if (button.isDepressed() && !structure.isOpen()) {
            structure.setIsOpen(true);
            structure.setTimeUntilCloses(7);
        }
        if (structure.isOpen()) {
            structure.setTimeUntilCloses(structure.timeUntilCloses() - 1);
            if (structure.timeUntilCloses() === 0) {
                structure.setIsOpen(false);
                button.setIsDepressed(false);
                const player = game.ent(Player);
                const room = player.location();
                if (
                    room.like(InsideMirror) ||
                    mirrorDoorIsVisibleHere(game, room)
                ) {
                    await runner.doOutput('The mirror quietly swings shut.');
                } else if (room.like(SmallRoom)) {
                    await runner.doOutput(
                        'The button pops back to its original position.'
                    );
                }
            }
        }
        return Action.incomplete();
    }
};

const pushMirrorDoor: Handler = async ({ action, runner }) => {
    if (action.is(Push) && action.item.is(Mirror1)) {
        if (action.item.isMunged()) {
            await runner.doOutput(
                'Shards of a broken mirror are dangerous to play with.'
            );
        } else {
            await runner.doOutput(
                'The mirror is mounted on a wooden panel which moves slightly inward as ' +
                    'you push, and back out when you let go. The mirror feels fragile.'
            );
        }
        return Action.complete();
    }
};

const lookInMirror: Handler = async ({ action, runner }) => {
    if (action.is(LookIn) && action.item.is(Mirror1)) {
        if (action.item.isMunged()) {
            await runner.doOutput('The mirror is broken into little pieces.');
        } else {
            await runner.doOutput(
                'A disheveled adventurer stares back at you.'
            );
        }
        return Action.complete();
    }
};

const openMirror: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(Mirror1) && !action.item.isMunged()) {
        await runner.doOutput("I don't see a way to open the mirror here.");
        return Action.complete();
    }
};

const breakMirror: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(Poke) && action.enemy.is(Mirror1)) ||
        (action.is(Mung) && action.item.is(Mirror1))
    ) {
        const mirror = game.ent(Mirror1);
        if (mirror.isMunged()) {
            await runner.doOutput('The mirror has already been broken.');
        } else {
            mirror.setIsMunged(true);
            await runner.doOutput(
                'The mirror breaks, revealing a wooden panel behind it.'
            );
        }
        return Action.complete();
    }
};
