import { MirrorRoom1, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { ColdPassage } from '../ColdPassage';

export class SteepCrawlway extends Room {
    static spec(): EntitySpec<SteepCrawlway> {
        return {
            ref: 'steep-crawlway',
            constructor: SteepCrawlway,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return SteepCrawlway.spec().ref;
    }

    name(): string {
        return 'Steep Crawlway';
    }

    description(): string {
        return 'This is a steep and narrow crawlway. There are two exits nearby to the south and southwest.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.South, to: MirrorRoom1.spec().ref }),
            new Passage({
                via: Direction.Southwest,
                to: ColdPassage.spec().ref,
            }),
        ];
    }
}
