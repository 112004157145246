import { SpecialGo, SpecialJigsUp } from '..';
import { Direction, Parse, SpecialDirection, Verb } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { MungedSource } from '../../rooms';

export class Go extends makeDescribable(Action) {
    id = 'go';

    direction: Direction | SpecialDirection;

    constructor({ direction }: { direction: Direction | SpecialDirection }) {
        super();
        this.direction = direction;
    }

    description(game: Game): string {
        return `travel ${this.direction}`;
    }

    static ability(): Ability {
        const verbs = [
            new Verb('walk'),
            new Verb('sail'), // TODO maybe make this set a "mode" to sailing, so we can say "You're not in a boat, cretin"
            new Verb('go'),
            new Verb('travel'),
            new Verb('run'),
        ];
        return {
            handlers: [goHandler],
            parser,
            verbs,
            prepositions: [],
        };
    }
}

export const goHandler: Handler = async ({ action, runner, game, actor }) => {
    if (!action.is(Go) || actor === undefined) return;

    const room = game.locateEntity(actor);
    if (room === undefined) return;

    const passage = room.getPassage(action.direction);
    const destination = passage?.to ? game.get(passage.to) : undefined;
    if (destination && !destination?.isRoom()) {
        await runner.doDebug('Passage had non-room destination...', {
            room,
            passage,
            actor,
        });
        return;
    }

    const vehicle = actor.vehicle();

    if (
        passage &&
        passage.canGo() &&
        vehicle &&
        !passage.allowsVehicle(vehicle)
    ) {
        if (vehicle) {
            await runner.doOutput(`You can't go there in ${vehicle.an()}.`);
        } else {
            throw new Error('Handle this case...');
        }
    } else if (destination?.state.isMunged && destination?.state.howMunged) {
        await runner.doOutput(mungMessage(destination.state.howMunged));
    } else if (
        room.isLit() ||
        !actor.isAlive() ||
        (passage && passage.canGo() && destination?.isLit()) ||
        actor.testLuck(25, 50)
    ) {
        if (passage && passage.canGo() && destination) {
            await game.applyAction(
                runner,
                new SpecialGo({ from: room, to: destination }),
                actor
            );
        } else if (passage?.message) {
            await runner.doOutput(passage.message);
        } else if (action.direction === Direction.Up) {
            await runner.doOutput('There is no way up.');
        } else if (action.direction === Direction.Down) {
            await runner.doOutput('There is no way down.');
        } else if (action.direction === SpecialDirection.In) {
            await runner.doOutput('There is no obvious way in.');
        } else if (action.direction === SpecialDirection.Out) {
            await runner.doOutput('There is no obvious way out.');
        } else if (action.direction === SpecialDirection.Cross) {
            await runner.doOutput('There is nowhere to cross.');
        } else if (action.direction === SpecialDirection.Launch) {
            await runner.doOutput(
                "This doesn't appear to be a good place to launch."
            );
        } else if (action.direction === SpecialDirection.Land) {
            await runner.doOutput(
                "This doesn't appear to be a good place to land."
            );
        } else if (room.hasWalls()) {
            await runner.doOutput('There is a wall there.');
        } else {
            await runner.doOutput("I can't go that way.");
        }
    } else if (passage && passage.canGo()) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    'Oh, no! You walked into the slavering fangs of a lurking grue.',
            })
        );
    } else {
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    'Oh, no! A fearsome grue slithered into the room and devoured you.',
            })
        );
    }

    return Action.complete();
};

const parser = () =>
    Parse.option(Parse.words(Go.ability().verbs).before(Parse.whitespace()))
        .beforeX(Parse.direction())
        .map((direction) => new Go({ direction }));

function mungMessage(mungReason: MungedSource): string {
    switch (mungReason) {
        case MungedSource.BlastingCakes:
            return (
                'The door to the room seems to be blocked by sticky orange ' +
                'rubble from an explosion. Probably some careless ' +
                'adventurer was playing with blasting cakes.'
            );
        case MungedSource.NoxiousGas:
            return 'Noxious vapors prevent your entry.';
        case MungedSource.PoisonousGas:
            return 'You are stopped by a cloud of poisonous gas.';
        case MungedSource.Flooded:
            return 'The room is full of water and cannot be entered.';
        case MungedSource.Exploded:
            return 'The way is blocked by debris from an explosion.';
        case MungedSource.LedgeCollapse:
            return 'The ledge has collapsed and cannot be landed on.';
    }
}
