import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that will parse any number of a given parser, including zero.
 * This parser never fails.
 *
 * ```
 * many(word("dog").before(option(whitespace()))).match(tokenize("dog dog"))
 * many(word("dog").before(option(whitespace()))).match(tokenize(""))
 * ```
 */
export class ManyParser<I, T> extends Parser<I, T[]> {
    parser: Parser<I, T>;

    constructor(parser: Parser<I, T>) {
        super();
        this.parser = parser;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, T[]>> {
        for (const firstMatch of this.parser.match(tokens)) {
            for (const restMatches of new ManyParser(this.parser).match(
                firstMatch.rest
            )) {
                yield new Match(
                    new Token(
                        [firstMatch.token.value].concat(
                            restMatches.token.value
                        ),
                        [firstMatch.token, ...restMatches.token.source]
                    ),
                    restMatches.rest
                );
            }
        }

        yield new Match(new Token([], []), tokens);
    }
}
