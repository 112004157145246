import { Noun, Adjective, tokenize, Parse } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Ability, Action, EntitySpec, Handler, Reference } from '../../game';
import { Close, Knock, Open, Rub, SpecialTimerTick } from '../../abilities';
import { makeOpenable } from '../../game/Entity';
import { Temple } from '../../rooms/Temple';
import { Forest1 } from '../../rooms/Forest1';
import { Flask } from '../Flask';
import { Skeleton } from '../Skeleton';
import { RustyKnife } from '../RustyKnife';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';
import { DungeonMaster, Player } from '../../actors';
import { DungeonEntrance } from '../../rooms';
import { Answer } from '../../abilities/Answer';
import { pluralize } from '../../utils';
import { NUMBERS } from '../../constants';

interface QuestionDoorState extends ItemState {
    hasBeenKnocked: boolean;
    remainingQuestions: number[];
    timeUntilRepeatQuestion: number;
    remainingAttempts: number;
}

abstract class Base extends Item<QuestionDoorState> {}

export class QuestionDoor extends makeOpenable(Base) {
    static spec(): EntitySpec<QuestionDoor> {
        return {
            ref: 'question-door',
            constructor: QuestionDoor,
            initial: {
                timeUntilRepeatQuestion: 0,
                remainingQuestions: [],
                hasBeenKnocked: false,
                isOpen: false,
                remainingAttempts: 5,
            },
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [
                new Adjective('wood'),
                new Adjective('wooden'),
                new Adjective('massive'),
            ],
            handlers: [
                openQuestionDoor,
                knockOnQuestionDoor,
                judgeAnswer,
                tickAnswers,
            ],
        };
    }

    ref() {
        return QuestionDoor.spec().ref;
    }

    name(): string {
        return 'wooden door';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns() {
        return QuestionDoor.spec().nouns;
    }

    adjectives() {
        return QuestionDoor.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    hasBeenKnocked() {
        return this.state.hasBeenKnocked;
    }

    setHasBeenKnocked(hasBeenKnocked: boolean) {
        this.state.hasBeenKnocked = hasBeenKnocked;
    }

    remainingQuestions(): Question[] {
        return this.state.remainingQuestions.map(
            (index) => allQuestions()[index]
        );
    }

    registerCorrectAnswer() {
        this.state.remainingQuestions = this.state.remainingQuestions.slice(1);
    }

    setRemainingQuestionIndices(remainingQuestionIndices: number[]) {
        this.state.remainingQuestions = remainingQuestionIndices;
    }

    timeUntilRepeatQuestion() {
        return this.state.timeUntilRepeatQuestion;
    }

    setTimeUntilRepeatQuestion(timeUntilRepeatQuestion: number) {
        this.state.timeUntilRepeatQuestion = timeUntilRepeatQuestion;
    }

    remainingAttempts() {
        return this.state.remainingAttempts;
    }

    setRemainingAttempts(remainingAttempts: number) {
        this.state.remainingAttempts = remainingAttempts;
    }
}

const openQuestionDoor: Handler = async ({ action, runner }) => {
    if ((action.is(Open) || action.is(Close)) && action.item.is(QuestionDoor)) {
        await runner.doOutput("The door won't budge.");
        return Action.complete();
    }
};

const knockOnQuestionDoor: Handler = async ({ action, runner, game }) => {
    if (action.is(Knock) && action.item.is(QuestionDoor)) {
        const { item: door } = action;
        if (door.hasBeenKnocked()) {
            await runner.doOutput('There is no answer.');
        } else {
            door.setHasBeenKnocked(true);
            await runner.doOutput(QUIZ_RULES);
            door.setRemainingQuestionIndices(getThreeQuestions(game));
            door.setTimeUntilRepeatQuestion(2);
            const firstQuestion = door.remainingQuestions()[0];
            await runner.doOutput(
                `The booming voice asks:\n  "${firstQuestion.question}"`
            );
        }
        return Action.complete();
    }
};

const judgeAnswer: Handler = async ({ action, runner, game, actor }) => {
    if (
        action.is(Answer) &&
        actor?.is(Player) &&
        actor.location().is(DungeonEntrance)
    ) {
        const door = game.ent(QuestionDoor);
        const question = door.remainingQuestions()[0];
        if (
            question &&
            action.message !== undefined &&
            door.remainingAttempts() > 0
        ) {
            if (await verifyAnswer(game, runner, question, action.message)) {
                await runner.doOutput("The dungeon master says 'Excellent'.");
                door.registerCorrectAnswer();
                const nextQuestion = door.remainingQuestions()[0];
                if (!nextQuestion) {
                    await runner.doOutput(QUIZ_WIN);
                    door.setIsOpen(true);
                    game.ent(DungeonMaster).setIsFollowing(true);
                } else {
                    door.setRemainingAttempts(5);
                    door.setTimeUntilRepeatQuestion(2);
                    await runner.doOutput(
                        `The booming voice asks:\n  "${nextQuestion.question}"`
                    );
                }
            } else {
                door.setRemainingAttempts(door.remainingAttempts() - 1);
                const remainingGuesses = door.remainingAttempts();
                if (remainingGuesses > 0) {
                    await runner.doOutput(
                        `The dungeon master says, "You are wrong. You have ${
                            NUMBERS[remainingGuesses]
                        } more ${pluralize(remainingGuesses, 'chance')}."`
                    );
                    door.setTimeUntilRepeatQuestion(2);
                } else {
                    await runner.doOutput(QUIZ_LOSE);
                    door.setTimeUntilRepeatQuestion(0);
                }
            }
        } else {
            await runner.doOutput('There is no reply.');
        }
        return Action.complete();
    }
};

const tickAnswers: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const door = game.ent(QuestionDoor);
        if (door.hasBeenKnocked() && door.remainingQuestions().length > 0) {
            door.setTimeUntilRepeatQuestion(door.timeUntilRepeatQuestion() - 1);
            if (door.timeUntilRepeatQuestion() === 0) {
                const nextQuestion = door.remainingQuestions()[0];
                if (game.ent(Player).location().is(DungeonEntrance)) {
                    await runner.doOutput(
                        `The booming voice asks:\n  "${nextQuestion.question}"`
                    );
                }
                door.setTimeUntilRepeatQuestion(2);
            }
        }
        return Action.incomplete();
    }
};

const QUIZ_RULES =
    'The knock reverberates along the hall. For a time it seems there will be ' +
    'no answer. Then you hear someone unlatching the small wooden panel. ' +
    'Through the bars of the great door, the wrinkled face of an old man appears. ' +
    'He gazes down at you and intones as follows: "I am the Master of the Dungeon, ' +
    'whose task it is to insure that none but the most scholarly and masterful adventurers ' +
    'are admitted into the secret realms of the Dungeon. To ascertain whether you meet ' +
    'the stringent requirements laid down by the Great Implementers, I will ask three ' +
    'questions which should be easy for one of your reputed excellence to answer. You have ' +
    'undoubtedly discovered their answers during your travels through the Dungeon. ' +
    'Should you answer each of these questions correctly within five attempts, then ' +
    'I am obliged to acknowledge your skill and daring and admit you to these regions." ' +
    'All answers should be in the form `answer "answer"`.';

const QUIZ_WIN =
    'The dungeon master, obviously pleased, says "You are indeed a master of lore. ' +
    'I am proud to be at your service." The massive wooden door swings open, ' +
    'and the master motions for you to enter.';

const QUIZ_LOSE =
    'The dungeon master, obviously disappointed in your lack of knowledge, ' +
    'shakes his head and mumbles "I guess they\'ll let anyone in the ' +
    'Dungeon these days". With that, he departs.';

type TAnswer =
    | { type: 'string'; value: string }
    | { type: 'item'; value: Reference }
    | { type: 'room'; value: Reference }
    | { type: 'action'; value: Ability };

type Question = { question: string; answers: TAnswer[] };

function getThreeQuestions(game: Game): number[] {
    const indices = allQuestions().map((_, index) => index);
    const result = [];
    for (let i = 0; i < 3; i++) {
        const index = game.choiceOf(indices);
        result.push(index);
        indices.splice(indices.indexOf(index), 1);
    }
    return result;
}

function allQuestions(): Question[] {
    return [
        {
            question:
                "From which room can one enter the robber's hideaway without passing through the cyclops room?",
            answers: [{ type: 'room', value: Temple.spec().ref }],
        },
        {
            question:
                'Beside the Temple, to which room is it possible to go from the Altar?',
            answers: [{ type: 'room', value: Forest1.spec().ref }],
        },
        {
            question:
                'What is the absolute minimum specified value of the Zork treasures, in Zorkmids?',
            answers: [
                { type: 'string', value: '30003' },
                { type: 'string', value: '30003.00' },
                { type: 'string', value: '30003 zorkmids' },
                { type: 'string', value: 'thirty thousand three' },
                { type: 'string', value: 'thirty thousand three zorkmids' },
            ],
        },
        {
            question:
                'What object is of use in determining the function of the iced cakes?',
            answers: [{ type: 'item', value: Flask.spec().ref }],
        },
        {
            question: 'What can be done to the Mirror that is useful?',
            answers: [{ type: 'action', value: Rub.ability() }],
        },
        {
            question: 'The taking of which object offends the ghosts?',
            answers: [{ type: 'item', value: Skeleton.spec().ref }],
        },
        {
            question: 'What object in the Dungeon is haunted?',
            answers: [{ type: 'item', value: RustyKnife.spec().ref }],
        },
        {
            question: "In which room is 'Hello, Sailor!' useful?",
            answers: [
                { type: 'string', value: 'none' },
                { type: 'string', value: 'nowhere' },
                { type: 'string', value: 'n/a' },
                { type: 'string', value: 'none of them' },
            ],
        },
    ];
}

async function verifyAnswer(
    game: Game,
    runner: Runner,
    question: Question,
    guess: string
) {
    const { answers } = question;
    for (const answer of answers) {
        switch (answer.type) {
            case 'action':
                if (
                    answer.value
                        .parser(game)
                        .end()
                        .matchOne(tokenize(guess)) !== null
                ) {
                    return true;
                }
                break;
            case 'item': {
                const target = Parse.target(game.lexicon)
                    .end()
                    .matchOne(tokenize(guess))?.token.value;
                if (target) {
                    const { item } = await game.resolve(
                        runner,
                        target,
                        game.ent(Player),
                        {
                            onlyVisible: false,
                        }
                    );
                    if (item && item.ref() === answer.value) {
                        return true;
                    }
                }
                break;
            }
            case 'room': {
                const guessNormal = guess.toLowerCase();
                const name = game.get(answer.value).name().toLowerCase();
                if (guessNormal === name || guessNormal === `the ${name}`) {
                    return true;
                }
                break;
            }
            case 'string':
                if (guess.toLowerCase() === answer.value.toLowerCase()) {
                    return true;
                }
                break;
        }
    }
    return false;
}
