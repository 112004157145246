import {
    EastGuardianNarrowRoom,
    EastNarrowRoom4,
    GuardianHallway,
    Hallway2,
    Hallway3,
    Hallway4,
    Passage,
    Room,
    WestGuardianNarrowRoom,
    WestNarrowRoom4,
} from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Action, EntitySpec, Handler, Reference } from '../../game';
import {
    BlackPanel,
    Mirror1,
    MahoganyPanel,
    ShortPole,
    PinePanel,
    RedPanel,
    WhitePanel,
    YellowPanel,
    Mirror2,
    WoodenBar,
    TBar,
    LongPole,
    Arrow,
} from '../../items';
import { Go, Push, SpecialEnter, SpecialJigsUp } from '../../abilities';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';
import { Player } from '../../actors';
import { RoomState } from '../Room';
import {
    exitInDirection,
    move,
    rotateClockwise,
    rotateCounterclockwise,
} from './mirror_utils';

interface InsideMirrorState extends RoomState {
    timeUntilCloses: number;
    currentPosition: Reference;
    direction: Direction;
    isOpen: boolean;
}

export class InsideMirror extends Room<InsideMirrorState> {
    static spec(): EntitySpec<InsideMirror> {
        return {
            ref: 'inside-mirror',
            constructor: InsideMirror,
            initial: {
                contents: [
                    ShortPole.spec().ref,
                    RedPanel.spec().ref,
                    WhitePanel.spec().ref,
                    YellowPanel.spec().ref,
                    BlackPanel.spec().ref,
                    MahoganyPanel.spec().ref,
                    PinePanel.spec().ref,
                    WoodenBar.spec().ref,
                    TBar.spec().ref,
                    LongPole.spec().ref,
                    Arrow.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
                currentPosition: Hallway2.spec().ref,
                direction: Direction.West,
                timeUntilCloses: 0,
                isOpen: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [
                rotateStructure,
                slideStructure,
                goFromInsideMirror,
                enterGuardianVisibleRoom,
            ],
        };
    }

    scoreOnEntry() {
        return 15;
    }

    ref() {
        return InsideMirror.spec().ref;
    }

    name(): string {
        return 'Inside Mirror';
    }

    description(): string {
        const pole = this.game.ent(ShortPole);
        const mirrorIsInStartingRoom = this.position().is(Hallway2);
        let description = IN_MIRROR_DESCRIPTION;
        if (mirrorIsInStartingRoom && this.orientation() === Direction.West) {
            if (pole.isRaised()) {
                description +=
                    `has been lifted out of a hole carved in the stone floor. ` +
                    `There is evidently enough friction to keep the pole from dropping back down.`;
            } else {
                description += `has been dropped into a hole carved in the stone floor.`;
            }
        } else if (
            this.orientation() === Direction.North ||
            this.orientation() === Direction.South
        ) {
            if (pole.isRaised()) {
                description += `is positioned above the stone channel in the floor.`;
            } else {
                description += `has been dropped into the stone channel incised in the floor.`;
            }
        } else {
            description += `is resting on the stone floor.`;
        }
        description +=
            '\nThe long pole at the center of the bar extends from the ceiling ' +
            'through the bar to the circular area in the stone channel. This bottom ' +
            'end of the pole has a T-bar a bit less than two feet long attached to it, ' +
            'and on the T-bar is carved an arrow. The arrow and T-bar are pointing ';
        description += this.orientation();
        description += '.';
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [];
    }

    timeUntilCloses() {
        return this.state.timeUntilCloses;
    }

    setTimeUntilCloses(timeUntilCloses: number) {
        this.state.timeUntilCloses = timeUntilCloses;
    }

    isOpen(): boolean {
        return this.state.isOpen;
    }

    setIsOpen(isOpen: boolean) {
        this.state.isOpen = isOpen;
    }

    position(): Room {
        return this.game.get(this.state.currentPosition) as Room;
    }

    setCurrentPosition(currentPosition: Room) {
        this.state.currentPosition = currentPosition.ref();
    }

    orientation(): Direction {
        return this.state.direction;
    }

    setDirection(direction: Direction) {
        this.state.direction = direction;
    }
}

const rotateStructure: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Push) &&
        (action.item.like(RedPanel) ||
            action.item.like(YellowPanel) ||
            action.item.like(WhitePanel) ||
            action.item.like(BlackPanel))
    ) {
        const pole = game.ent(ShortPole);
        const structure = game.ent(InsideMirror);
        const { item: panel } = action;
        const mirrorRoom = structure.position();
        if (pole.isRaised()) {
            if (mirrorRoom.is(GuardianHallway)) {
                await runner.doOutput(
                    'The movement of the structure alerts the Guardians.'
                );
                await game.applyAction(
                    runner,
                    new SpecialJigsUp({ message: GUARDIAN_KILL })
                );
            } else {
                if (panel.like(RedPanel) || panel.like(YellowPanel)) {
                    structure.setDirection(
                        rotateClockwise(structure.orientation())
                    );
                    await runner.doOutput('The structure rotates clockwise.');
                } else {
                    structure.setDirection(
                        rotateCounterclockwise(structure.orientation())
                    );
                    await runner.doOutput(
                        'The structure rotates counterclockwise.'
                    );
                }
                await runner.doOutput(
                    `The arrow on the compass rose now indicates ${structure.orientation()}.`
                );
                const pine = game.ent(PinePanel);
                if (pine.isOpen()) {
                    pine.setIsOpen(false);
                    await runner.doOutput('The pine wall closes quietly.');
                }
            }
        } else if (
            structure.orientation() === Direction.North ||
            structure.orientation() === Direction.South
        ) {
            await runner.doOutput(
                'The short pole prevents the structure from rotating.'
            );
        } else {
            await runner.doOutput(
                "The structure shakes slightly but doesn't move."
            );
        }
        return Action.complete();
    }
};

const slideStructure: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Push) &&
        (action.item.like(MahoganyPanel) || action.item.like(PinePanel))
    ) {
        const structure = game.ent(InsideMirror);
        const { item: panel } = action;
        const direction = structure.orientation();
        if (direction !== Direction.North && direction !== Direction.South) {
            await runner.doOutput(
                "The structure rocks back and forth slightly but doesn't move."
            );
        } else if (panel.is(MahoganyPanel)) {
            const room = structure.position();
            const nextRoom = move(game, room, direction, false);
            if (nextRoom) {
                await moveStructure(game, runner, direction, nextRoom);
            } else {
                await runner.doOutput(
                    "The structure has reached the end of the stone channel and won't budge."
                );
            }
        } else {
            const room = structure.position();
            const panel = game.ent(PinePanel);
            panel.setIsOpen(true);
            panel.setTimeUntilCloses(5);
            await runner.doOutput('The pine wall swings open.');
            if (
                (room.is(Hallway4) && direction === Direction.North) ||
                (room.is(Hallway3) && direction === Direction.South)
            ) {
                await runner.doOutput(
                    'The pine door opens into the field of view of the Guardians.'
                );
                await game.applyAction(
                    runner,
                    new SpecialJigsUp({ message: GUARDIAN_KILL })
                );
            }
        }
        return Action.complete();
    }
};

async function moveStructure(
    game: Game,
    runner: Runner,
    direction: Direction.North | Direction.South,
    nextRoom: Room
) {
    const pole = game.ent(ShortPole);
    const howMove = pole.isUp() ? 'wobbles' : 'slides';
    await runner.doOutput(
        `The structure ${howMove} ${direction} and stops over another compass rose.`
    );
    const structure = game.ent(InsideMirror);
    structure.setCurrentPosition(nextRoom);
    const playerRoom = game.ent(Player).location();
    if (nextRoom.is(GuardianHallway) && playerRoom.is(InsideMirror)) {
        const pinePanelIsOpen = game.ent(PinePanel).isOpen();
        let alerted = false;
        if (pole.isUp()) {
            await runner.doOutput(
                'The structure wobbles as it moves, alerting the Guardians.'
            );
            alerted = true;
        } else if (
            game.ent(Mirror1).isMunged() ||
            game.ent(Mirror2).isMunged()
        ) {
            await runner.doOutput(
                'A Guardian notices a wooden structure creeping by, and his suspicions are aroused.'
            );
            alerted = true;
        } else if (structure.isOpen() || pinePanelIsOpen) {
            await runner.doOutput(
                'A Guardian notices the open side of the structure, and his suspicions are aroused.'
            );
            alerted = true;
        }
        if (alerted) {
            await game.applyAction(
                runner,
                new SpecialJigsUp({ message: GUARDIAN_REALIZED })
            );
        }
    }
}

const VALID_DIRECTIONS = [
    Direction.North,
    Direction.East,
    Direction.South,
    Direction.West,
    Direction.Northeast,
    Direction.Southeast,
    Direction.Southwest,
    Direction.Southwest,
    SpecialDirection.Out,
];

const goFromInsideMirror: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(Go) &&
        game.isInEndgame() &&
        actor?.is(Player) &&
        actor.location().is(InsideMirror) &&
        VALID_DIRECTIONS.includes(action.direction)
    ) {
        const newRoom = mirrorExitInDirection(game, action.direction);
        if (newRoom) {
            const pine = game.ent(PinePanel);
            if (pine.isOpen()) {
                pine.setIsOpen(false);
                await runner.doOutput('As you leave, the door swings shut.');
            }
            await game.applyAction(runner, new SpecialEnter({ room: newRoom }));
        } else if (action.direction === SpecialDirection.Out) {
            await runner.doOutput('There is no obvious way out.');
        } else {
            await runner.doOutput('There is a wall there.');
        }
        return Action.complete();
    }
};

const enterGuardianVisibleRoom: Handler = async ({ action, runner, game }) => {
    if (
        action.is(SpecialEnter) &&
        (action.room.like(EastGuardianNarrowRoom) ||
            action.room.like(WestGuardianNarrowRoom) ||
            action.room.like(EastNarrowRoom4) ||
            action.room.like(WestNarrowRoom4) ||
            action.room.like(GuardianHallway))
    ) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({ message: GUARDIAN_KILL })
        );
        return Action.complete();
    }
};

function mirrorExitInDirection(
    game: Game,
    direction: Direction | SpecialDirection
) {
    const structure = game.ent(InsideMirror);
    const mirrorDirection = structure.orientation();
    const pine = game.ent(PinePanel);
    const r = rotateCounterclockwise;
    const out = direction === SpecialDirection.Out;
    // TODO maybe if both panels are open, require you to say which one...
    if (structure.isOpen()) {
        const correctDirection = r(r(mirrorDirection));
        if (out || direction === correctDirection) {
            return exitInDirection(
                game,
                structure.position(),
                correctDirection
            );
        }
    }
    if (pine.isOpen()) {
        const correctDirection = r(r(r(r(mirrorDirection))));
        if (out || direction === correctDirection) {
            return exitInDirection(
                game,
                structure.position(),
                correctDirection
            );
        }
    }
    return undefined;
}

const IN_MIRROR_DESCRIPTION =
    'You are inside a rectangular box of wood whose structure is rather complicated. ' +
    'Four sides and the roof are filled in, and the floor is open. ' +
    'As you face the side opposite the entrance, two short sides of carved ' +
    'and polished wood are to your left and right. ' +
    'The left panel is mahogany, the right pine. ' +
    'The wall you face is red on its left half and black on its right. ' +
    'On the entrance side, the wall is white opposite the red part of the wall ' +
    'it faces, and yellow opposite the black section. The painted walls are at ' +
    'least twice the length of the unpainted ones. The ceiling is painted blue. ' +
    'In the floor is a stone channel about six inches wide and a foot deep. ' +
    'The channel is oriented in a north-south direction. In the exact center of ' +
    'the room the channel widens into a circular depression perhaps two feet wide. ' +
    'Incised in the stone around this area is a compass rose. ' +
    'Running from one short wall to the other at about waist height is a wooden bar, ' +
    'carefully carved and drilled. This bar is pierced in two places. ' +
    'The first hole is in the center of the bar (and thus the center of the room). ' +
    'The second is at the left end of the room (as you face opposite the entrance). ' +
    'Through each hole runs a wooden pole. The pole at the left end of the bar is short, ' +
    'extending about a foot above the bar, and ends in a hand grip. The pole ';

const GUARDIAN_KILL =
    'The Guardians awake, and in perfect unison, utterly destroy you ' +
    'with their stone bludgeons. Satisfied, they resume their posts.';

const GUARDIAN_REALIZED =
    'Suddenly the Guardians realize someone is trying to sneak by them in ' +
    'the structure. They awake, and in perfect unison, hammer the box and ' +
    'its contents (including you) to pulp. They then resume their posts, ' +
    'satisfied.';
