import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { makeReadable } from '../../game/Entity';

export class Engravings extends makeReadable(Item) {
    static spec(): EntitySpec<Engravings> {
        return {
            ref: 'engravings',
            constructor: Engravings,
            initial: {},
            nouns: [
                new Noun('inscription'),
                new Noun('inscriptions', { collective: true }),
                new Noun('engraving'),
                new Noun('engravings', { collective: true }),
                new Noun('wall'),
                new Noun('walls', { plural: true }),
            ],
            adjectives: [
                new Adjective('old'),
                new Adjective('ancient'),
                new Adjective('blasphemous'),
            ],
        };
    }

    ref() {
        return Engravings.spec().ref;
    }

    name(): string {
        return 'gothic lettering';
    }

    description(): string {
        return 'There are old engravings on the walls here.';
    }

    text(): string {
        return BLASPHEMOUS_ENGRAVINGS_TEXT;
    }

    nouns() {
        return Engravings.spec().nouns;
    }

    adjectives() {
        return Engravings.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const BLASPHEMOUS_ENGRAVINGS_TEXT =
    'The engravings were incised in the living rock of the cave ' +
    'wall by an unknown hand. They depict, in symbolic form, the ' +
    'beliefs of the ancient peoples of Zork. Skillfully interwoven ' +
    'with the bas reliefs are excerpts illustrating the major ' +
    'tenets expounded by the sacred texts of the religion of that ' +
    'time. Unfortunately a later age seems to have considered ' +
    'them blasphemous and just as skillfully excised them.';
