import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { LookIn, Mung, Open, Poke, Push } from '../../abilities';

interface Mirror1State extends ItemState {
    isMunged: boolean;
}

abstract class Base extends Item<Mirror1State> {}

export class Mirror2 extends Base {
    static spec(): EntitySpec<Mirror2> {
        return {
            ref: 'mirror-2',
            constructor: Mirror2,
            initial: {
                isMunged: false,
            },
            nouns: [new Noun('mirror'), new Noun('mirrors', { plural: true })],
            adjectives: [
                new Adjective('broken'),
                new Adjective('shattered'),
                new Adjective('munged'),
            ],
            handlers: [pushMirror, openMirror, breakMirror, lookInMirror],
        };
    }

    ref() {
        return Mirror2.spec().ref;
    }

    name(): string {
        return this.isMunged() ? 'broken mirror' : 'mirror';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Mirror2.spec().nouns;
    }

    adjectives() {
        return this.isMunged()
            ? [
                  new Adjective('broken'),
                  new Adjective('shattered'),
                  new Adjective('munged'),
              ]
            : [];
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isMunged(): boolean {
        return this.state.isMunged;
    }

    setIsMunged(isMunged: boolean) {
        this.state.isMunged = isMunged;
    }
}

const pushMirror: Handler = async ({ action, runner }) => {
    if (action.is(Push) && action.item.is(Mirror2)) {
        if (action.item.isMunged()) {
            await runner.doOutput(
                'Shards of a broken mirror are dangerous to play with.'
            );
        } else {
            await runner.doOutput(
                'The mirror is unyielding, but seems rather fragile.'
            );
        }
        return Action.complete();
    }
};

const openMirror: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(Mirror2) && !action.item.isMunged()) {
        await runner.doOutput("I don't see a way to open the mirror here.");
        return Action.complete();
    }
};

const lookInMirror: Handler = async ({ action, runner }) => {
    if (action.is(LookIn) && action.item.is(Mirror2)) {
        if (action.item.isMunged()) {
            await runner.doOutput('The mirror is broken into little pieces.');
        } else {
            await runner.doOutput(
                'A disheveled adventurer stares back at you.'
            );
        }
        return Action.complete();
    }
};

const breakMirror: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(Poke) && action.enemy.is(Mirror2)) ||
        (action.is(Mung) && action.item.is(Mirror2))
    ) {
        const mirror = game.ent(Mirror2);
        if (mirror.isMunged()) {
            await runner.doOutput('The mirror has already been broken.');
        } else {
            mirror.setIsMunged(true);
            await runner.doOutput(
                'The mirror breaks, revealing a wooden panel behind it.'
            );
        }
        return Action.complete();
    }
};
