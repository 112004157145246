import {
    EastGuardianNarrowRoom,
    Passage,
    Room,
    WestGuardianNarrowRoom,
} from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { DungeonEntrance } from '../DungeonEntrance';
import { GuardianHallway } from '../GuardianHallway';
import {
    enterPassage,
    hallwayGlobalObjects,
    hallwayPassage,
} from '../InsideMirror/mirror_utils';

export class Hallway4 extends Room {
    static spec(): EntitySpec<Hallway4> {
        return {
            ref: 'hallway-4',
            constructor: Hallway4,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Hallway4.spec().ref;
    }

    name(): string {
        return 'Hallway';
    }

    description(): string {
        return 'Cheater!';
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    globalObjects(): Entity[] {
        return [...super.globalObjects(), ...hallwayGlobalObjects(this)];
    }

    passages(): Passage[] {
        return [
            hallwayPassage({
                to: this.game.ent(DungeonEntrance),
                via: Direction.North,
            }),
            hallwayPassage({
                to: this.game.ent(EastGuardianNarrowRoom),
                via: Direction.Southeast,
            }),
            hallwayPassage({
                to: this.game.ent(WestGuardianNarrowRoom),
                via: Direction.Southwest,
            }),
            hallwayPassage({
                to: this.game.ent(GuardianHallway),
                via: Direction.South,
            }),
            enterPassage({ from: this }),
        ];
    }
}
