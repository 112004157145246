import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeReadable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class RedCake extends makeReadable(makeTakeable(Item)) {
    static spec(): EntitySpec<RedCake> {
        return {
            ref: 'red-cake',
            constructor: RedCake,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('cake', { collective: true }),
                new Noun('cakes', { plural: true }),
                new Noun('piece of cake'),
                new Noun('pieces of cake', { plural: true }),
                new Noun('piece of cake with red icing'),
                new Noun('pieces of cake with red icing', { plural: true }),
            ],
            adjectives: [new Adjective('red')],
            handlers: [],
        };
    }

    text(): string {
        return (
            'The only writing legible is a capital E. ' +
            'The rest is too small to be clearly visible.'
        );
    }

    enlargedText(): string {
        return 'Evaporate';
    }

    size(): number {
        return 4;
    }

    ref() {
        return RedCake.spec().ref;
    }

    name() {
        return 'piece of cake with red icing';
    }

    description() {
        return 'There is a piece of cake with red icing here.';
    }

    nouns(): Noun[] {
        return RedCake.spec().nouns;
    }

    adjectives(): Adjective[] {
        return RedCake.spec().adjectives;
    }

    isFood(): boolean {
        return true;
    }
}

// TODO this
/*
if parse.verb in game.get_verbs("throw", "put_in"):
    if direct is game.objects["red_cake"]:
      if indirect is game.objects["pool"]:
        game.move_object(direct, game.here)
        game.remove_object(indirect)
        game.objects["saffron"].hidden = False
        raise Complete("The pool of water evaporates, revealing a tin of rare spices.")
 */
