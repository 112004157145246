import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { Leaflet } from '../index';
import { makeContainer, makeOpenable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Open } from '../../abilities';

export class LargeCase extends makeOpenable(makeContainer(Item)) {
    static spec(): EntitySpec<LargeCase> {
        return {
            ref: 'large-case',
            constructor: LargeCase,
            initial: {
                contents: [Leaflet.spec().ref],
                isOpen: false,
            },
            nouns: [new Noun('case'), new Noun('cases', { plural: true })],
            adjectives: [new Adjective('large')],
            handlers: [openCase],
        };
    }

    ref() {
        return LargeCase.spec().ref;
    }

    name(): string {
        return 'large case';
    }

    description(): string {
        return 'There is a large case here, containing objects which you used to possess.';
    }

    totalCapacity(): undefined {
        return undefined;
    }

    isTransparent() {
        return true;
    }

    nouns() {
        return LargeCase.spec().nouns;
    }

    adjectives() {
        return LargeCase.spec().adjectives;
    }
}

const openCase: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(LargeCase)) {
        await runner.doOutput('Sorry, those items are gone forever.');
        return Action.complete();
    }
};
