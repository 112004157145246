import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { LookUnder, Move, Raise, Take } from '../../abilities';
import { TrapDoor } from '../TrapDoor';

interface RugState extends ItemState {
    isMoved: boolean;
}

export class Rug extends Item<RugState> {
    static spec(): EntitySpec<Rug> {
        return {
            ref: 'rug',
            constructor: Rug,
            initial: {
                isMoved: false,
            },
            nouns: [
                new Noun('rug'),
                new Noun('rugs', { plural: true }),
                new Noun('carpet'),
            ],
            adjectives: [new Adjective('large'), new Adjective('oriental')],
            handlers: [
                handleTakeRug,
                handleRaiseRug,
                handleLookUnderRug,
                handleMoveRug,
            ],
        };
    }

    ref() {
        return Rug.spec().ref;
    }

    name(): string {
        return 'rug';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Rug.spec().nouns;
    }

    adjectives() {
        return Rug.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isMoved(): boolean {
        return this.state.isMoved;
    }

    canBeMoved(): boolean {
        return true;
    }
}

const handleTakeRug: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Rug)) {
        await runner.doOutput(
            'The rug is extremely heavy and cannot be carried.'
        );
        return Action.complete();
    }
};

const handleRaiseRug: Handler = async ({ action, runner }) => {
    if (action.is(Raise) && action.item.is(Rug)) {
        await runner.doOutput(
            'The rug is too heavy to lift, but in trying to take it you have ' +
                'noticed an irregularity beneath it.'
        );
        return Action.complete();
    }
};

const handleLookUnderRug: Handler = async ({ action, runner, game }) => {
    if (
        action.is(LookUnder) &&
        action.item.is(Rug) &&
        !game.ent(Rug).isMoved()
    ) {
        await runner.doOutput('Underneath the rug is a closed trap door.');
        return Action.complete();
    }
};

const handleMoveRug: Handler = async ({ action, game, runner }) => {
    if (action.is(Move) && action.item.is(Rug)) {
        const rug = game.ent(Rug);
        if (rug.isMoved()) {
            await runner.doOutput(
                'Having moved the carpet previously, you find it impossible ' +
                    'to move it again.'
            );
        } else {
            rug.state.isMoved = true;
            const trapDoor = game.ent(TrapDoor);
            trapDoor.state.isHidden = false;
            await runner.doOutput(
                'With a great effort, the rug is moved to one side of the room. ' +
                    'With the rug moved, the dusty cover of a closed ' +
                    'trap-door appears.'
            );
        }
        return Action.complete();
    }
};
