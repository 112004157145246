import { Noun, Adjective } from '../../../parse';
import { Container, Item, ItemState, Vehicle } from '../Item';
import { makeContainer, makeVehicle, makeTakeable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Board, Deflate, Inflate, Plug } from '../../abilities';
import { Pump } from '../Pump';
import { BoatInstructions, Gunk, Lungs, Stick } from '..';

interface BoatState extends ItemState {
    isInflated: boolean;
    isPopped: boolean;
}

abstract class Base extends Item<BoatState> {}

export class Boat extends makeTakeable(makeVehicle(makeContainer(Base))) {
    static spec(): EntitySpec<Boat> {
        return {
            ref: 'boat',
            constructor: Boat,
            initial: {
                isInflated: false,
                isPopped: false,
                contents: [BoatInstructions.spec().ref],
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('boat'),
                new Noun('boats', { plural: true }),
                new Noun('pile'),
                new Noun('piles', { plural: true }),
                new Noun('pile of plastic'),
                new Noun('piles of plastic', { plural: true }),
                new Noun('raft'),
                new Noun('rafts', { plural: true }),
                new Noun('watercraft'),
                new Noun('watercrafts', { plural: true }),
                new Noun('water craft'),
                new Noun('water crafts', { plural: true }),
            ],
            adjectives: [
                new Adjective('magic'),
                new Adjective('plastic'),
                new Adjective('rubber'),
                new Adjective('seaworthy'),
                new Adjective('inflated'),
                new Adjective('uninflated'),
                new Adjective('deflated'),
                new Adjective('inflatable'),
                new Adjective('popped'),
            ],
            handlers: [
                inflateBoatWithPump,
                mendPoppedBoat,
                getInBoat,
                deflateBoat,
            ],
        };
    }

    isInflated(): boolean {
        return this.state.isInflated;
    }

    setIsInflated(isInflated: boolean) {
        this.state.isInflated = isInflated;
    }

    isPopped(): boolean {
        return this.state.isPopped;
    }

    setIsPopped(isPopped: boolean) {
        this.state.isPopped = isPopped;
    }

    ref() {
        return Boat.spec().ref;
    }

    name(): string {
        if (this.isInflated()) {
            return 'magic boat';
        }
        if (this.isPopped()) {
            return 'popped plastic boat';
        }
        return 'pile of plastic';
    }

    description(): string {
        if (this.isInflated()) {
            return 'There is an inflated boat here.';
        }
        if (this.isPopped()) {
            return 'There is a pile of plastic here with a large hole in it.';
        }
        return 'There is a folded pile of plastic here which has a small valve attached.';
    }

    isVehicle(): this is Vehicle {
        return this.isInflated() && !this.isPopped();
    }

    isContainer(): this is Container {
        return this.isInflated() && !this.isPopped();
    }

    totalCapacity(): number {
        return 100;
    }

    size(): number {
        return 20;
    }

    nouns() {
        const nouns = [
            new Noun('boat'),
            new Noun('boats', { plural: true }),
            new Noun('raft'),
            new Noun('rafts', { plural: true }),
            new Noun('watercraft'),
            new Noun('watercrafts', { plural: true }),
            new Noun('water craft'),
            new Noun('water crafts', { plural: true }),
        ];
        if (this.isPopped() || !this.isInflated()) {
            nouns.push(
                new Noun('pile'),
                new Noun('piles', { plural: true }),
                new Noun('pile of plastic'),
                new Noun('piles of plastic', { plural: true }),
                new Noun('pile of rubber'),
                new Noun('piles of rubber', { plural: true })
            );
        }
        return nouns;
    }

    adjectives() {
        const adjectives = [
            new Adjective('magic'),
            new Adjective('plastic'),
            new Adjective('rubber'),
            new Adjective('inflatable'),
        ];
        if (this.isPopped() || !this.isInflated()) {
            adjectives.push(new Adjective('popped'));
        } else if (this.isInflated()) {
            adjectives.push(
                new Adjective('seaworthy'),
                new Adjective('inflated')
            );
        } else {
            adjectives.push(
                new Adjective('uninflated'),
                new Adjective('deflated')
            );
        }
        return adjectives;
    }
}

const inflateBoatWithPump: Handler = async ({ action, runner, actor }) => {
    if (action.is(Inflate) && action.item.is(Boat) && action.tool) {
        const { item: boat } = action;
        if (boat.isPopped()) {
            await runner.doOutput(
                'This boat will not inflate since some moron put a hole in it.'
            );
        } else if (boat.isInflated()) {
            await runner.doOutput(
                'Inflating it further would probably burst it.'
            );
        } else {
            const here = actor?.location();
            if (!here?.contains(boat)) {
                await runner.doOutput(
                    'The boat must be on the ground to be inflated.'
                );
            } else if (action.tool.like(Lungs)) {
                await runner.doOutput(
                    "You don't have enough lung power to inflate it."
                );
            } else if (!action.tool.is(Pump)) {
                await runner.doOutput(
                    `With ${action.tool.an()}? Surely you jest!`
                );
            } else {
                await runner.doOutput(
                    'The boat inflates and appears seaworthy.'
                );
                boat.setIsInflated(true);
            }
        }
        return Action.complete();
    }
};

const mendPoppedBoat: Handler = async ({ action, runner }) => {
    if (action.is(Plug) && action.item.is(Boat) && action.item.isPopped()) {
        if (action.tool.is(Gunk)) {
            await runner.doOutput('Well done. The boat is repaired.');
            action.tool.moveTo(undefined);
            action.item.setIsPopped(false);
        } else {
            await runner.doOutput(`With ${action.tool.an()}?`);
        }
        return Action.complete();
    }
};

const getInBoat: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(Board) &&
        action.vehicle.is(Boat) &&
        action.vehicle.isInflated() &&
        actor?.hasItem(game.ent(Stick))
    ) {
        await runner.doOutput(
            'There is a hissing sound and the boat deflates.'
        );
        action.vehicle.setIsPopped(true);
        action.vehicle.setIsInflated(false);
        return Action.complete();
    }
};

const deflateBoat: Handler = async ({ action, runner, actor }) => {
    if (
        action.is(Deflate) &&
        action.item.is(Boat) &&
        action.item.isInflated()
    ) {
        if (actor && action.item.contains(actor)) {
            await runner.doOutput(
                "You can't deflate the boat while you're in it."
            );
        } else if (!actor?.location()?.contains(action.item)) {
            await runner.doOutput(
                'The boat must be on the ground to be deflated.'
            );
        } else {
            await runner.doOutput('The boat deflates.');
            action.item.setIsInflated(false);
        }
        return Action.complete();
    }
};

// TODO deflating the boat swallows the items and makes them inaccessible... maybe that's ok?
