import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { makeReadable } from '../../game/Entity';

export class WoodenDoorLettering extends makeReadable(Item) {
    static spec(): EntitySpec<WoodenDoorLettering> {
        return {
            ref: 'wooden-door-lettering',
            constructor: WoodenDoorLettering,
            initial: {},
            nouns: [
                new Noun('lettering'),
                new Noun('engraving'),
                new Noun('engravings', { collective: true }),
            ],
            adjectives: [new Adjective('gothic'), new Adjective('strange')],
        };
    }

    ref() {
        return WoodenDoorLettering.spec().ref;
    }

    name(): string {
        return 'gothic lettering';
    }

    description(): string {
        return '';
    }

    text(): string {
        return "The engravings translate to 'This space intentionally left blank'.";
    }

    nouns() {
        return WoodenDoorLettering.spec().nouns;
    }

    adjectives() {
        return WoodenDoorLettering.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
