import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import {
    makeContainer,
    makeOpenable,
    makeTakeable,
    makeTreasure,
} from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Garlic } from '../Garlic';
import { Lunch } from '../Lunch';
import { Rope } from '../Rope';
import { Take } from '../../abilities';
import { Timber } from '../Timber';

export class Coffin extends makeTreasure(
    makeTakeable(makeOpenable(makeContainer(Item)))
) {
    static spec(): EntitySpec<Coffin> {
        return {
            ref: 'coffin',
            constructor: Coffin,
            initial: {
                contents: [Garlic.spec().ref, Lunch.spec().ref],
                isOpen: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('coffin'),
                new Noun('coffins', { plural: true }),
                new Noun('casket'),
                new Noun('caskets', { plural: true }),
            ],
            adjectives: [
                new Adjective('ramses'),
                new Adjective("ramses ii's"),
                new Adjective("ramses'"),
                new Adjective('solid'),
                new Adjective('gold'),
            ],
            handlers: [takeCoffinOrTimber],
        };
    }

    ref() {
        return Coffin.spec().ref;
    }

    name(): string {
        return 'gold coffin';
    }

    description(): string {
        if (this.game.ent(Rope).fastenedItem()?.isEqualTo(this)) {
            return "The coil of rope is tied to Ramses II's gold coffin.";
        }
        return 'The solid-gold coffin used for the burial of Ramses II is here.';
    }

    totalCapacity(): number {
        return 35;
    }

    size(): number {
        return 55;
    }

    scoreOnTake(): number {
        return 3;
    }

    scoreInCase(): number {
        return 7;
    }

    nouns() {
        return Coffin.spec().nouns;
    }

    adjectives() {
        return Coffin.spec().adjectives;
    }

    isSacred() {
        return true;
    }
}

const takeCoffinOrTimber: Handler = async ({
    action,
    runner,
    game,
    extensions,
    actor,
}) => {
    if (action.is(Take) && (action.item.is(Coffin) || action.item.is(Timber))) {
        const rope = game.ent(Rope);
        const ropeItem = rope.fastenedItem();
        if (ropeItem && ropeItem.isEqualTo(action.item)) {
            await extensions.deferHandling();
            if (actor?.hasItem(action.item)) {
                rope.state.fastenedItem = undefined;
                await runner.doOutput(
                    `The rope comes loose as you take ${action.item.the()}.`
                );
            }
            return Action.complete();
        }
    }
};

// TODO maybe add "get in coffin" -> you die :)
