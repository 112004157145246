import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeContainer } from '../../game/Entity';
import { PutIn, Throw } from '../../abilities';
import { Saffron } from '../Saffron';
import { RedCake } from '../RedCake';

export class Pool extends makeContainer(Item) {
    static spec(): EntitySpec<Pool> {
        return {
            ref: 'pool',
            constructor: Pool,
            initial: {
                contents: [],
            },
            nouns: [
                new Noun('pool'),
                new Noun('pools', { plural: true }),
                new Noun('sewage', { collective: true }),
                new Noun('goop', { collective: true }),
                new Noun('pool of sewage'),
                new Noun('pools of sewage', { plural: true }),
                new Noun('pool of goop'),
                new Noun('pools of goop', { plural: true }),
            ],
            adjectives: [new Adjective('brown')],
            handlers: [evaporatePool],
        };
    }

    totalCapacity(): undefined {
        return undefined;
    }

    ref() {
        return Pool.spec().ref;
    }

    name(): string {
        return 'pool of sewage';
    }

    description(): string {
        return 'The leak has submerged the depressed area in a pool of sewage.';
    }

    nouns() {
        return Pool.spec().nouns;
    }

    adjectives() {
        return Pool.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const evaporatePool: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(Throw) && action.enemy?.is(Pool)) ||
        (action.is(PutIn) &&
            action.container?.is(Pool) &&
            action.item.is(RedCake))
    ) {
        await runner.doOutput(
            'The pool of water evaporates, revealing a tin of rare spices.'
        );
        game.ent(Saffron).state.isHidden = false;
        return Action.complete();
    }
};
