import { Verb, Parse, Parser, Value } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { EatUnresolved, eatUnresolvedHandler } from './EatUnresolved';

export class Eat extends Action {
    id = '~eat';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [eatHandler, eatUnresolvedHandler],
            parser,
            verbs: [
                new Verb('eat'),
                new Verb('munch'),
                new Verb('consume'),
                new Verb('gobble'),
                new Verb('gobble down'),
                new Verb('gobble up'),
                new Verb('taste'),
                new Verb('tuck into'),
                new Verb('tuck in to'),
            ],
            prepositions: [],
        };
    }
}

export const eatHandler: Handler = async ({ action, runner, game, actor }) => {
    if (!action.is(Eat)) return undefined;
    const { item } = action;
    if (item.isItem() && item.isFood()) {
        if (await game.take(runner, item, actor)) {
            item.moveTo(undefined);
            await runner.doOutput(
                'Thank you very much. It really hit the spot.'
            );
        }
    } else {
        await runner.doOutput(
            `I don't think ${item.the()} would agree with you.`
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, EatUnresolved> =>
    Parse.words(Eat.ability().verbs)
        .before(Parse.whitespace())
        .chain((_verb) =>
            Parse.target(game.lexicon).map(
                (item) => new EatUnresolved({ item })
            )
        );
