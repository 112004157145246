import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { LightUnresolved, lightUnresolvedHandler } from './LightUnresolved';
import { SpecialJigsUp } from '../SpecialJigsUp';

export class Light extends Action {
    id = '~light';

    item: Entity;

    tool: Entity | undefined;

    constructor({ item, tool }: { item: Entity; tool: Entity | undefined }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [lightHandler, lightUnresolvedHandler],
            parser,
            // TODO light x on fire, set x aflame
            verbs: [new Verb('light'), new Verb('light up'), new Verb('burn')],
            prepositions: [
                new Preposition('with'),
                new Preposition('using'),
                new Preposition('in'),
            ],
        };
    }
}

export const lightHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(Light)) return;
    const { item, tool } = action;
    // TODO auto pick tool that is already flaming
    if (tool) {
        if (tool.isItem() && tool.isFlammable() && tool.isAflame()) {
            if (item.isItem() && item.isFlammable()) {
                item.moveTo(undefined);
                if (actor?.hasItem(item)) {
                    await runner.doOutput(`${item.The()} catches fire.`);
                    await game.applyAction(
                        runner,
                        new SpecialJigsUp({
                            message:
                                'Unfortunately, you were holding it at the time.',
                        })
                    );
                } else {
                    await runner.doOutput(
                        `${item.The()} catches fire and is consumed.`
                    );
                }
            } else {
                await runner.doOutput(
                    `I don't think you can burn ${item.an()}`
                );
            }
        } else {
            await runner.doOutput(`With ${tool.an()}??!?`);
        }
    } else {
        await runner.doOutput(
            `What would you like to use to burn ${item.the()}?`
        );
        // TODO a way of defining a target that is already resolved?
        // game.partial = (tool) =>
        //     new LightUnresolved({ item: item.target(), tool });
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, LightUnresolved> => {
    const light = Parse.words(Light.ability().verbs);
    const lightObject = light.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const lightObjectWith = lightObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // light
        light.into(new LightUnresolved({ item: undefined, tool: undefined })),
        // light box
        lightObject.map(
            (item) => new LightUnresolved({ item, tool: undefined })
        ),
        // light egg with hammer
        lightObjectWith.map(
            ([item, tool]) =>
                new LightUnresolved({
                    item,
                    tool,
                })
        )
    );
};
