import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class EastLadder extends Item {
    static spec(): EntitySpec<EastLadder> {
        return {
            ref: 'east-ladder',
            constructor: EastLadder,
            initial: {},
            nouns: [new Noun('ladder'), new Noun('ladders', { plural: true })],
            adjectives: [
                new Adjective('east'),
                new Adjective('eastern'),
                new Adjective('wooden'),
            ],
        };
    }

    ref() {
        return EastLadder.spec().ref;
    }

    name(): string {
        return 'ladder';
    }

    description(): string {
        return 'There is a ladder here, firmly attached to the east wall.';
    }

    nouns() {
        return EastLadder.spec().nouns;
    }

    adjectives() {
        return EastLadder.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    canBeClimbed(): boolean {
        return true;
    }
}
