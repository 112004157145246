import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { Boat, River } from '../../items';
import { AragainFalls } from '../AragainFalls';
import { River5 } from '../River5';
import { SandyBeach } from '../SandyBeach';

export class Shore extends Room {
    static spec(): EntitySpec<Shore> {
        return {
            ref: 'shore',
            constructor: Shore,
            initial: {
                contents: [River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Shore.spec().ref;
    }

    name(): string {
        return 'Shore';
    }

    description(): string {
        return 'You are on the shore of the River. The river here seems somewhat treacherous. A path travels from north to south here, the south end quickly turning around a sharp corner.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: SpecialDirection.Launch,
                to: River5.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({ via: Direction.North, to: SandyBeach.spec().ref }),
            new Passage({ via: Direction.South, to: AragainFalls.spec().ref }),
        ];
    }

    hasWalls() {
        return false;
    }
}
