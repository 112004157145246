import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { NorthSouthCrawlway } from '../NorthSouthCrawlway';
import { Gallery } from '../Gallery';
import { Kitchen } from '../Kitchen';
import { Go } from '../../abilities';
import { Lamp, TrapDoor } from '../../items';

export class Studio extends Room {
    static spec(): EntitySpec<Studio> {
        return {
            ref: 'studio',
            constructor: Studio,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [goUpChimney],
        };
    }

    ref() {
        return Studio.spec().ref;
    }

    name(): string {
        return 'Studio';
    }

    description(): string {
        return (
            "This is what appears to have been an artist's studio. " +
            'The walls and floors are splattered with paints of 69 different colors. ' +
            'Strangely enough, nothing of value is hanging here. ' +
            'At the north and northwest of the room are open doors ' +
            '(also covered with paint). An extremely dark and narrow ' +
            'chimney leads up from a fireplace; although you might be ' +
            'able to get up it, it seems unlikely you could get back down.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: NorthSouthCrawlway.spec().ref,
            }),
            new Passage({ via: Direction.Northwest, to: Gallery.spec().ref }),
            new Passage({
                via: Direction.Up,
                to: Kitchen.spec().ref,
            }),
            new Passage({
                via: Direction.Down,
                message:
                    'The chasm probably leads straight to the infernal regions.',
            }),
        ];
    }
}

// TODO climb chimney
const goUpChimney: Handler = async ({
    action,
    runner,
    actor,
    game,
    extensions,
}) => {
    if (action.is(Go) && actor) {
        const room = game.locateEntity(actor);
        if (room && room.is(Studio) && action.direction === Direction.Up) {
            if (actor.inventory().length === 0) {
                await runner.doOutput('Going up empty-handed is a bad idea.');
            } else if (!actor.hasItem(game.ent(Lamp))) {
                await runner.doOutput("Aren't you forgetting something?");
            } else if (actor.inventory().length <= 2) {
                const trapDoor = game.ent(TrapDoor);
                if (!trapDoor.isOpen()) {
                    // This method of getting out of the dungeon is too easy, so we unset the sacred
                    // bit so the thief bars the door again next time you go down.
                    trapDoor.setIsSacred(false);
                }
                await extensions.deferHandling();
            } else {
                await runner.doOutput(
                    'The chimney is too narrow for you and all of your baggage.'
                );
            }
            return Action.complete();
        }
    }
};
