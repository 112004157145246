import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Squeeze } from './Squeeze';
import { UnresolvedAction } from '../UnresolvedAction';

export class SqueezeUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'squeeze';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'squeeze something';
        }
        return `squeeze ${targetDescription(game, this.item)}`;
    }
}

export const squeezeUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SqueezeUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new SqueezeUnresolved({ item }),
        missing: () => 'Squeeze what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to squeeze, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    return await game.applyAction(runner, new Squeeze({ item }), actor);
};
