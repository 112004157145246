import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Open } from '../../abilities';
import { makeOpenable } from '../../game/Entity';

export class SteelDoor extends makeOpenable(Item) {
    static spec(): EntitySpec<SteelDoor> {
        return {
            ref: 'steel-door',
            constructor: SteelDoor,
            initial: {
                isOpen: false,
            },
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [
                new Adjective('steel'),
                new Adjective('large'),
                new Adjective('metal'),
            ],
            handlers: [openStoneDoor],
        };
    }

    ref() {
        return SteelDoor.spec().ref;
    }

    name(): string {
        return 'steel door';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed() {
        return false;
    }

    nouns() {
        return SteelDoor.spec().nouns;
    }

    adjectives() {
        return SteelDoor.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const openStoneDoor: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(SteelDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput('The door is already open.');
        } else {
            await runner.doOutput("The door won't budge.");
        }
        return Action.complete();
    }

    if (action.is(Close) && action.item.is(SteelDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput("The door won't move.");
        } else {
            await runner.doOutput('The door is already closed.');
        }
        return Action.complete();
    }
};
