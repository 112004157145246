import { Passage, ReservoirSouth, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Chasm } from '../Chasm';
import { EastWestPassage } from '../EastWestPassage';
import { RockyCrawl } from '../RockyCrawl';
import { Player } from '../../actors';
import { Coffin } from '../../items';

export class DeepRavine extends Room {
    static spec(): EntitySpec<DeepRavine> {
        return {
            ref: 'deep-ravine',
            constructor: DeepRavine,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return DeepRavine.spec().ref;
    }

    name(): string {
        return 'Deep Ravine';
    }

    description(): string {
        return (
            'This is a deep ravine at a crossing with an east-west crawlway. ' +
            'Some stone steps are at the south of the ravine and a steep staircase descends.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: EastWestPassage.spec().ref,
            }),
            new Passage({
                via: Direction.Down,
                to: ReservoirSouth.spec().ref,
                condition: () =>
                    !this.game.ent(Player).hasItem(this.game.ent(Coffin)),
                message: 'The stairs are to steep for you with your burden.',
            }),
            new Passage({ via: Direction.East, to: Chasm.spec().ref }),
            new Passage({ via: Direction.West, to: RockyCrawl.spec().ref }),
        ];
    }
}
