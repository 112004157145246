import { Verb, Parse } from '../../../parse';
import { WHEEE } from '../../constants';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Jump extends makeDescribable(Action) {
    id = 'jump';

    description(game: Game): string {
        return 'jump';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('jump'),
            new Verb('leap'),
            new Verb('jump up and down'),
            new Verb('hop'),
        ];
        return {
            handlers: [jumpHandler],
            parser: () => Parse.words(verbs).into(new Jump()),
            verbs,
            prepositions: [],
        };
    }
}

export const jumpHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Jump)) return;
    await runner.doOutput(game.choiceOf(WHEEE));
    return Action.complete();
};
