import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Wrench extends makeTakeable(Item) {
    static spec(): EntitySpec<Wrench> {
        return {
            ref: 'wrench',
            constructor: Wrench,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [new Noun('wrench'), new Noun('wrenches', { plural: true })],
            adjectives: [],
        };
    }

    ref() {
        return Wrench.spec().ref;
    }

    name() {
        return 'wrench';
    }

    isTool() {
        return true;
    }

    description() {
        return 'There is a wrench here.';
    }

    nouns(): Noun[] {
        return Wrench.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Wrench.spec().adjectives;
    }

    canBeTurned(): boolean {
        return true;
    }

    size() {
        return 10;
    }
}
