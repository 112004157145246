import { Parse, Parser, Word } from '..';
import {
    Value,
    Noun,
    WordList,
    Adjective,
    Verb,
    Article,
    Conjunction,
    Preposition,
    Pronoun,
} from '.';

export class Lexicon {
    adjectives: WordList<Adjective>;

    nouns: WordList<Noun>;

    verbs: WordList<Verb>;

    articles: WordList<Article>;

    conjunctions: WordList<Conjunction>;

    prepositions: WordList<Preposition>;

    pronouns: WordList<Pronoun>;

    constructor() {
        this.adjectives = new WordList();
        this.nouns = new WordList();
        this.verbs = new WordList();
        this.articles = new WordList();
        this.conjunctions = new WordList();
        this.prepositions = new WordList();
        this.pronouns = new WordList();
    }

    addNoun(noun: Noun) {
        this.nouns.push(noun);
    }

    addVerb(verb: Verb) {
        this.verbs.push(verb);
    }

    addAdjective(adjective: Adjective) {
        this.adjectives.push(adjective);
    }

    addArticle(article: Article) {
        this.articles.push(article);
    }

    addConjunction(conjunction: Conjunction) {
        this.conjunctions.push(conjunction);
    }

    addPreposition(preposition: Preposition) {
        this.prepositions.push(preposition);
    }

    addPronoun(pronoun: Pronoun) {
        this.pronouns.push(pronoun);
    }

    get words() {
        return new WordList(
            ...this.adjectives,
            ...this.nouns,
            ...this.verbs,
            ...this.articles,
            ...this.conjunctions,
            ...this.prepositions,
            ...this.pronouns
        );
    }

    // Matchers specific to this lexicon

    parserForWord(string: string) {
        if (string.includes(' ')) {
            return Parse.phrase(string);
        }
        return Parse.word(string);
    }

    anyWord<W extends Word>(words: W[]): Parser<Value, W> {
        return Parse.any(
            ...words.map((word) => this.parserForWord(word.value).into(word))
        );
    }

    noun() {
        return this.anyWord(this.nouns);
    }

    singularNoun() {
        return this.anyWord(
            this.nouns.filter((noun) => noun.singular) as Noun[]
        );
    }

    pluralNoun() {
        return this.anyWord(this.nouns.filter((noun) => noun.plural) as Noun[]);
    }

    collectiveNoun() {
        return this.anyWord(
            this.nouns.filter((noun) => noun.collective) as Noun[]
        );
    }

    verb() {
        return this.anyWord(this.verbs);
    }

    adjective() {
        return this.anyWord(this.adjectives as Adjective[]);
    }

    article() {
        return this.anyWord(this.articles as Article[]);
    }

    conjunction() {
        return this.anyWord(this.conjunctions);
    }

    preposition() {
        return this.anyWord(this.prepositions);
    }

    pronoun() {
        return this.anyWord(this.pronouns as Pronoun[]);
    }

    singularPronoun() {
        return this.anyWord(
            this.pronouns.filter((pronoun) => pronoun.singular)
        );
    }

    pluralPronoun() {
        const pluralPronouns: Pronoun[] = this.pronouns.filter(
            (pronoun) => pronoun.plural
        );
        return this.anyWord(pluralPronouns);
    }

    word() {
        return Parse.any(
            this.noun(),
            this.verb(),
            this.adjective(),
            this.article(),
            this.conjunction(),
            this.preposition(),
            this.pronoun()
        );
    }

    isWord(possibleWord: Value) {
        const rawWords = [...this.words.map((word) => word.value)];
        return rawWords.includes(possibleWord.value);
    }
}

export function words<T extends Value>(list: T[]) {
    return Parse.any(
        ...list.map((item) =>
            (item.value.includes(' ')
                ? Parse.phrase(item.value)
                : Parse.word(item.value)
            ).into(item)
        )
    );
}
