import { Item, Takeable, TakeableState } from '../../items/Item';
import { Constructor } from './Constructor';

export function makeTakeable<TBase extends Constructor<Item>>(Base: TBase) {
    abstract class C extends Base implements Takeable {
        abstract state: TakeableState;

        isTakeable(): this is Takeable {
            return true;
        }

        hasBeenTaken(): boolean {
            return this.state.hasBeenTaken;
        }

        shouldTryToTake(): boolean {
            return true;
        }

        canBeTaken(): boolean {
            return true;
        }

        scoreOnTake(): number {
            return 0;
        }
    }

    return C;
}
