import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Diamond extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<Diamond> {
        return {
            ref: 'diamond',
            constructor: Diamond,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('diamond'),
                new Noun('diamonds', { plural: true }),
                new Noun('jewel'),
            ],
            adjectives: [
                new Adjective('huge'),
                new Adjective('enormous'),
                new Adjective('large'),
                new Adjective('perfectly cut'),
                new Adjective('perfectly-cut'),
                new Adjective('perfect'),
            ],
        };
    }

    ref() {
        return Diamond.spec().ref;
    }

    name() {
        return 'huge diamond';
    }

    description() {
        return 'There is an enormous diamond (perfectly cut) here.';
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 6;
    }

    nouns(): Noun[] {
        return Diamond.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Diamond.spec().adjectives;
    }
}
