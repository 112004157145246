import { Passage, Room } from '..';
import { MAZE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Maze6 } from '../Maze6';
import { Maze11 } from '../Maze11';
import { Maze10 } from '../Maze10';
import { Maze13 } from '../Maze13';
import { Maze12 } from '../Maze12';

export class Maze9 extends Room {
    static spec(): EntitySpec<Maze9> {
        return {
            ref: 'maze-9',
            constructor: Maze9,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Maze9.spec().ref;
    }

    name(): string {
        return 'Maze';
    }

    description(): string {
        return MAZE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: Maze6.spec().ref }),
            new Passage({ via: Direction.East, to: Maze11.spec().ref }),
            new Passage({ via: Direction.Down, to: Maze10.spec().ref }),
            new Passage({ via: Direction.South, to: Maze13.spec().ref }),
            new Passage({ via: Direction.West, to: Maze12.spec().ref }),
            new Passage({ via: Direction.Northwest, to: Maze9.spec().ref }),
        ];
    }

    // TODO
    isPartOfMaze() {
        return true;
    }
}
