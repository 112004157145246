import { Game } from '../game';
import { Action, Describable } from './Action';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T = {}> = abstract new (...args: any[]) => T;

export function makeDescribable<TBase extends Constructor<Action>>(
    Base: TBase
) {
    abstract class C extends Base implements Describable {
        abstract description(game: Game): string;

        isDescribable(): this is Describable {
            return true;
        }
    }

    return C;
}
