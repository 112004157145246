import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { PokeUnresolved, pokeUnresolvedHandler } from './PokeUnresolved';
import { performBlow } from '../../handlers';
import { HO_HUM } from '../../constants';

export class Poke extends Action {
    id = '~poke';

    weapon: Entity | undefined;

    enemy: Entity;

    constructor({
        weapon,
        enemy,
    }: {
        weapon: Entity | undefined;
        enemy: Entity;
    }) {
        super();
        this.weapon = weapon;
        this.enemy = enemy;
    }

    static ability(): Ability {
        return {
            handlers: [pokeHandler, pokeUnresolvedHandler],
            parser,
            verbs: [new Verb('poke'), new Verb('jab'), new Verb('blind')],
            prepositions: [new Preposition('with'), new Preposition('using')],
        };
    }
}

export const pokeHandler: Handler = async ({ action, actor, runner, game }) => {
    if (!action.is(Poke) || actor === undefined) return;

    const { weapon, enemy } = action;

    if (!enemy.isActor() || !enemy.isVillain() || !enemy.isVictim()) {
        await runner.doOutput(
            `Trying to damage ${enemy.an()} ${game.choiceOf(HO_HUM)}`
        );
    } else if (weapon === undefined) {
        await runner.doOutput(
            `Trying to damage ${enemy.the()} with your bare hands is suicidal.`
        );
    } else if (!weapon.isItem() || !weapon.isWeapon()) {
        await runner.doOutput(
            `Trying to damage ${enemy.an()} with ${weapon.an()} is quite self-destructive.`
        );
    } else {
        await performBlow(game, runner, actor, enemy, weapon, undefined);
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, PokeUnresolved> => {
    const ability = Poke.ability();
    const poke = Parse.words(ability.verbs);
    const pokeEnemy = poke.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const pokeEnemyWith = pokeEnemy.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((container) => [item, container])
    );
    return Parse.any(
        // poke
        poke.into(new PokeUnresolved({ weapon: undefined, enemy: undefined })),
        // poke troll
        pokeEnemy.map(
            (enemy) => new PokeUnresolved({ weapon: undefined, enemy })
        ),
        // poke troll with sword
        pokeEnemyWith.map(
            ([enemy, weapon]) => new PokeUnresolved({ weapon, enemy })
        )
    );
};
