import { Ability, Action, Entity, Handler } from '../../game';
import { Game } from '../../game/game';
import { Parse, Parser, Value, Verb } from '../../../parse';
import { CloseUnresolved, closeUnresolvedHandler } from './CloseUnresolved';

export class Close extends Action {
    id = '~close';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [closeHandler, closeUnresolvedHandler],
            parser,
            verbs: [new Verb('close'), new Verb('close up'), new Verb('shut')],
            prepositions: [],
        };
    }
}

export const closeHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Close)) return;
    const { item } = action;
    if (item.isItem() && item.isOpenable() && item.canBeOpened()) {
        if (!item.state.isOpen) {
            await runner.doOutput("It's already closed.");
        } else {
            item.state.isOpen = false;
            await runner.doOutput('Closed.');
        }
    } else {
        await runner.doOutput(`You must tell me how to close ${item.an()}.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, CloseUnresolved> =>
    Parse.words(Close.ability().verbs)
        .before(Parse.whitespace())
        .chain((_verb) =>
            Parse.target(game.lexicon).map(
                (item) => new CloseUnresolved({ item })
            )
        );
