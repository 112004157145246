import { Passage, Room } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { Direction } from '../../../parse';
import { TeaRoom } from '../TeaRoom';
import { MachineRoom } from '../MachineRoom';
import { Player, Robot } from '../../actors';
import { GreenPaper } from '../../items';
import { Go, SpecialEnter, SpecialJigsUp } from '../../abilities';
import { RoundRoom } from '../RoundRoom';

export class LowRoom extends Room {
    static spec(): EntitySpec<LowRoom> {
        return {
            ref: 'low-room',
            constructor: LowRoom,
            initial: {
                contents: [GreenPaper.spec().ref, Robot.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [enterLowRoom, goFromLowRoom],
        };
    }

    hasWalls(): boolean {
        return true;
    }

    ref() {
        return LowRoom.spec().ref;
    }

    name(): string {
        return 'Low Room';
    }

    description(): string {
        return (
            'You are in a room with a low ceiling which is circular in shape. ' +
            'There are exits to the east and the southeast.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Southeast, to: TeaRoom.spec().ref }),
            new Passage({ via: Direction.East, to: MachineRoom.spec().ref }),
        ];
    }
}

const EXITS = [Direction.Southeast, Direction.East];

const goFromLowRoom: Handler = async ({
    action,
    runner,
    actor,
    game,
    extensions,
}) => {
    if (action.is(Go) && actor) {
        const room = actor.location();
        const roundRoom = game.ent(RoundRoom);
        if (room?.is(LowRoom) && !roundRoom.isMagnetic()) {
            await runner.doOutput('You cannot get your bearings...');
            return extensions.replaceAction(
                new Go({ direction: game.choiceOf(EXITS) })
            );
        }
    }
};

const enterLowRoom: Handler = async ({
    action,
    runner,
    game,
    actor,
    extensions,
}) => {
    if (action.is(SpecialEnter) && action.room.is(LowRoom)) {
        const roundRoom = game.ent(RoundRoom);
        if (!roundRoom.isMagnetic()) {
            if (roundRoom.isHighPower()) {
                if (actor?.is(Player)) {
                    return game.applyAction(
                        runner,
                        new SpecialJigsUp({ message: PLAYER_MAGNET_ZAP }),
                        actor
                    );
                }
                return game.applyAction(
                    runner,
                    new SpecialJigsUp({ message: ROBOT_MAGNET_ZAP }),
                    actor
                );
            }
            await extensions.deferHandling();
            if (actor?.is(Player)) {
                await runner.doOutput(
                    'As you enter, your compass starts spinning wildly.'
                );
            }
            return Action.complete({ withConsequence: false });
        }
    }
};

const PLAYER_MAGNET_ZAP =
    'According to Prof. TAA of MIT Tech, the rapidly changing ' +
    'magnetic fields in the room are so intense as to cause you ' +
    "to be electrocuted. I really don't know, but in any event, " +
    'something just killed you.';

const ROBOT_MAGNET_ZAP =
    'According to Prof. TAA of MIT Tech, the rapidly changing ' +
    'magnetic fields in the room are so intense as to fry all ' +
    "the delicate innards of the robot. I really don't know, " +
    'but in any event, smoke is coming out of its ears ' +
    'and it has stopped moving.';
