import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeOpenable } from '../../game/Entity';
import { EntitySpec } from '../../game';

export class OakDoorLid1 extends makeOpenable(Item) {
    static spec(): EntitySpec<OakDoorLid1> {
        return {
            ref: 'oak-door-lid-1',
            constructor: OakDoorLid1,
            initial: {
                isOpen: false,
            },
            nouns: [new Noun('lid'), new Noun('lids', { plural: true })],
            adjectives: [new Adjective('thin'), new Adjective('metal')],
            handlers: [],
        };
    }

    ref() {
        return OakDoorLid1.spec().ref;
    }

    name(): string {
        return 'metal lid';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns() {
        return OakDoorLid1.spec().nouns;
    }

    adjectives() {
        return OakDoorLid1.spec().adjectives;
    }
}
