import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeContainer, makeOpenable, makeTakeable } from '../../game/Entity';
import { EntitySpec } from '../../game';
import { Violin } from '../Violin';

interface ViolinCaseState extends ItemState {
    isHidden: boolean;
}

abstract class Base extends Item<ViolinCaseState> {}

export class ViolinCase extends makeTakeable(
    makeOpenable(makeContainer(Base))
) {
    static spec(): EntitySpec<ViolinCase> {
        return {
            ref: 'violin-case',
            constructor: ViolinCase,
            initial: {
                contents: [Violin.spec().ref],
                isOpen: false,
                hasBeenTaken: false,
                isHidden: true,
            },
            nouns: [
                new Noun('box'),
                new Noun('boxes', { plural: true }),
                new Noun('case'),
                new Noun('cases', { plural: true }),
                new Noun('violin case'),
                new Noun('violin cases', { plural: true }),
            ],
            adjectives: [new Adjective('steel'), new Adjective('dented')],
        };
    }

    isHidden(): boolean {
        return this.state.isHidden;
    }

    ref() {
        return ViolinCase.spec().ref;
    }

    name(): string {
        return 'steel box';
    }

    description(): string {
        return 'There is a dented steel box here.';
    }

    totalCapacity(): number {
        return 20;
    }

    size(): number {
        return 40;
    }

    canBeOpened(): boolean {
        return true;
    }

    canSeeInto(): boolean {
        return this.isOpen() || this.isTransparent();
    }

    nouns() {
        return ViolinCase.spec().nouns;
    }

    adjectives() {
        return ViolinCase.spec().adjectives;
    }

    isSacred() {
        // This is to prevent the thief from stealing the box in between when you
        // turn off the round room and when you get back there.
        return !this.hasBeenTaken();
    }
}
