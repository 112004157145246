import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { PutIn, SpecialTimerTick, Take } from '../../abilities';
import { Player } from '../../actors';
import { FightRemarks, FightResult } from '../../handlers';

interface SwordState extends ItemState {
    glowLevel: number;
}

abstract class Base extends Item<SwordState> {}

export class Sword extends makeTakeable(Base) {
    static spec(): EntitySpec<Sword> {
        return {
            ref: 'sword',
            constructor: Sword,
            initial: {
                glowLevel: 0,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('blade'),
                new Noun('blades', { plural: true }),
                new Noun('sword'),
                new Noun('swords', { plural: true }),
                new Noun('sword of great antiquity'),
                new Noun('swords of great antiquity', { plural: true }),
            ],
            adjectives: [
                new Adjective('elfish'),
                new Adjective('elvish'),
                new Adjective('elven'),
            ],
            handlers: [updateSwordGlow],
        };
    }

    ref() {
        return Sword.spec().ref;
    }

    name() {
        return 'sword';
    }

    description() {
        return 'There is an elvish sword here.';
    }

    initialDescription() {
        return 'On hooks above the mantelpiece hangs an elvish sword of great antiquity.';
    }

    nouns(): Noun[] {
        return Sword.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Sword.spec().adjectives;
    }

    isTool(): boolean {
        return true;
    }

    size(): number {
        return 30;
    }

    glowLevel(): number {
        return this.state.glowLevel;
    }

    setGlowLevel(glowLevel: number) {
        this.state.glowLevel = glowLevel;
    }

    isWeapon(): boolean {
        return true;
    }

    fightRemarks(): FightRemarks {
        return SWORD_FIGHT_REMARKS;
    }
}

const SWORD_GLOW_MESSAGES = [
    'Your sword is no longer glowing.',
    'Your sword is glowing with a faint blue glow.',
    'Your sword has begun to glow very brightly.',
];

const updateSwordGlow: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(SpecialTimerTick)) {
        const sword = game.ent(Sword);
        const player = game.ent(Player);
        let glowLevel = 0;
        if (player.hasItem(sword)) {
            const room = game.locateEntity(player);
            if (room === undefined) {
                throw new Error('Expected player to be in a room.');
            }
            if (room.isInfested()) {
                glowLevel = 2;
            } else {
                for (const passage of room.passages()) {
                    const destination = passage.to
                        ? game.get(passage.to)
                        : undefined;
                    if (destination?.isRoom() && destination.isInfested()) {
                        glowLevel = 1;
                    }
                }
            }
            if (glowLevel !== sword.glowLevel()) {
                sword.state.glowLevel = glowLevel;
                await runner.doOutput(SWORD_GLOW_MESSAGES[glowLevel]);
            }
        }
        return Action.incomplete();
    }

    // When the player take the sword, reset sword glow
    if ((action.is(Take) || action.is(PutIn)) && action.item.is(Sword)) {
        action.item.setGlowLevel(0);
        return Action.incomplete();
    }
};

const SWORD_FIGHT_REMARKS: FightRemarks = ({ defender }) => ({
    [FightResult.Hesitated]: [],
    [FightResult.Missed]: [
        `Your swing misses the ${defender} by an inch.`,
        `A mighty blow, but it misses the ${defender} by a mile.`,
        `You charge, but the ${defender} jumps nimbly aside.`,
        `Clang! Crash! The ${defender} parries.`,
        `A good stroke, but it's too slow, the ${defender} dodges.`,
    ],
    [FightResult.Unconscious]: [
        `Your sword crashes down, knocking the ${defender} into dreamland.`,
        `The ${defender} is battered into unconsciousness.`,
        `A furious exchange, and the ${defender} is knocked out!`,
    ],
    [FightResult.Killed]: [
        `It's curtains for the ${defender} as your sword removes his head.`,
        `The fatal blow strikes the ${defender} square in the heart: He dies.`,
        `The ${defender} takes a final blow and slumps to the floor dead.`,
    ],
    [FightResult.LightWound]: [
        `The ${defender} is struck on the arm, blood begins to trickle down.`,
        `Your sword pinks the ${defender} on the wrist, but it's not serious.`,
        'Your stroke lands, but it was only the flat of the blade.',
        `The blow lands, making a shallow gash in the ${defender}'s arm!`,
    ],
    [FightResult.SeriousWound]: [
        `The ${defender} receives a deep gash in his side.`,
        `A savage blow on the thigh! The ${defender} is stunned but can still fight!`,
        'Slash! Your blow lands! That one hit an artery, it could be serious!',
    ],
    [FightResult.Stun]: [
        `The ${defender} is staggered, and drops to his knees.`,
        `The ${defender} is momentarily disoriented and can't fight back.`,
        `The force of your blow knocks the ${defender} back, stunned.`,
    ],
    [FightResult.LostWeapon]: [
        `The ${defender}'s weapon is knocked to the floor, leaving him unarmed.`,
    ],
    [FightResult.SittingDuck]: [
        `Not taking any chances, you finish ${defender.the()}.`,
        `You thrust your sword into ${defender.the()}'s heart.`,
        `The unconscious ${defender} cannot defend himself: He dies.`,
    ],
});
