import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Brush } from './Brush';
import { UnresolvedAction } from '../UnresolvedAction';

export class BrushUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'brush';

    item: Target | undefined;

    tool: Target | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Target | undefined;
        tool: Target | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    description(game: Game) {
        if (this.tool === undefined) {
            return 'brush something';
        }
        if (this.item === undefined) {
            return `brush ${targetDescription(game, this.tool)} with something`;
        }
        return `brush ${targetDescription(
            game,
            this.tool
        )} with ${targetDescription(game, this.item)}`;
    }
}

export const brushUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(BrushUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (tool) => new BrushUnresolved({ item: action.item, tool }),
        missing: () => 'What would you like to brush?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to brush, ${opt}?`,
        condition: (tool) => tool.isItem() && tool.isContainer(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (action.tool) {
        const { item: tool } = await game.resolve(runner, action.tool, actor, {
            partial: (tool) => new BrushUnresolved({ tool, item: action.item }),
            ambiguous: (desc, opt) =>
                `With which ${desc} would you like to brush ${item.the()}, ${opt}?`,
            condition: (tool) => tool.isItem(),
        });

        if (tool === undefined)
            return Action.complete({ withConsequence: false });

        if (!(await game.reach(runner, tool, actor))) {
            return Action.complete();
        }

        if (!(await game.have(runner, tool, actor))) {
            return Action.complete();
        }
        return game.applyAction(runner, new Brush({ tool, item }));
    }

    return game.applyAction(runner, new Brush({ tool: undefined, item }));
};
