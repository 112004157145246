import { Passage, Room } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { Direction } from '../../../parse';
import { BagOfCoins, Bucket, WellBottomEtchings } from '../../items';
import { PearlRoom } from '../PearlRoom';
import { Wish } from '../../abilities/Wish';
import { Well } from '../../items/Well';

export class CircularRoom extends Room {
    static spec(): EntitySpec<CircularRoom> {
        return {
            ref: 'circular-room',
            constructor: CircularRoom,
            initial: {
                contents: [
                    WellBottomEtchings.spec().ref,
                    Bucket.spec().ref,
                    Well.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    hasWalls() {
        return true;
    }

    ref() {
        return CircularRoom.spec().ref;
    }

    name(): string {
        return 'Circular Room';
    }

    description(): string {
        return (
            'This is a damp circular room, whose walls are made of ' +
            'brick and mortar. The roof of this room is not visible, but ' +
            'there appear to be some etchings on the walls. ' +
            'There is a passageway to the west.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [new Passage({ via: Direction.West, to: PearlRoom.spec().ref })];
    }
}

export const wishHandler: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Wish)) {
        const room = actor?.location();
        const coins = game.ent(BagOfCoins);
        if (
            room?.is(CircularRoom) &&
            (actor?.hasItem(coins) || room?.hasItem(coins))
        ) {
            await runner.doOutput(
                "A whispering voice replies: 'Water makes the bucket go'. " +
                    'Unfortunately, wishing makes the coins go....'
            );
            coins.moveTo(undefined);
            return Action.complete();
        }
    }
};
