import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { NO_TREE } from '../../constants';
import { EntitySpec } from '../../game';
import { Clearing } from '../Clearing';
import { Forest1 } from '../Forest1';
import { Forest4 } from '../Forest4';
import { SouthOfHouse } from '../SouthOfHouse';

export class Forest2 extends Room {
    static spec(): EntitySpec<Forest2> {
        return {
            ref: 'forest-2',
            constructor: Forest2,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Forest2.spec().ref;
    }

    name(): string {
        return 'Forest';
    }

    description(): string {
        return (
            'This is a dimly lit forest, with large trees all around. ' +
            'To the east, there appears to be sunlight.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.South, to: Forest4.spec().ref }),
            new Passage({ via: Direction.East, to: Clearing.spec().ref }),
            new Passage({ via: Direction.West, to: Forest1.spec().ref }),
            new Passage({ via: Direction.North, to: SouthOfHouse.spec().ref }),
            new Passage({ via: Direction.Up, message: NO_TREE }),
        ];
    }

    isPartOfForest() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
