import { Passage, Room, ShaftRoom, SlideRoom, SqueakyRoom } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';

export class MineEntrance extends Room {
    static spec(): EntitySpec<MineEntrance> {
        return {
            ref: 'mine-entrance',
            constructor: MineEntrance,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return MineEntrance.spec().ref;
    }

    name(): string {
        return 'Mine Entrance';
    }

    description(): string {
        return (
            'You are standing at the entrance of what might have been a ' +
            'coal mine. To the northeast and the northwest are entrances ' +
            'to the mine, and there is another exit on the south end of the room.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.South, to: SlideRoom.spec().ref }),
            new Passage({
                via: Direction.Northwest,
                to: SqueakyRoom.spec().ref,
            }),
            new Passage({ via: Direction.Northeast, to: ShaftRoom.spec().ref }),
        ];
    }
}
