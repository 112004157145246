import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { SwimUnresolved, swimUnresolvedHandler } from './SwimUnresolved';
import { SWIM_YUKS } from '../../constants';

export class Swim extends Action {
    id = '~swim';

    item: Entity | undefined;

    constructor({ item }: { item: Entity | undefined }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [swimHandler, swimUnresolvedHandler],
            parser,
            verbs: [new Verb('swim')],
            prepositions: [new Preposition('in')],
        };
    }
}

export const swimHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Swim)) return;
    const { item } = action;
    const isThereWaterHere = game
        .here()
        .contents()
        .some((item) => item.isItem() && item.isBodyOfWater());
    if (item && item.isItem() ? item.isBodyOfWater() : isThereWaterHere) {
        await runner.doOutput('Swimming is not allowed in this dungeon.');
    } else {
        await runner.doOutput(game.choiceOf(SWIM_YUKS));
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, SwimUnresolved> => {
    const swim = Parse.words(Swim.ability().verbs);
    const swimObject = swim.chain((_verb) =>
        Parse.whitespace()
            .before(Parse.word('in'))
            .before(Parse.whitespace())
            .beforeX(Parse.target(game.lexicon))
    );
    return Parse.any(
        // swim
        swim.into(new SwimUnresolved({ item: undefined })),
        // swim in reservoir
        swimObject.map((item) => new SwimUnresolved({ item }))
    );
};
