import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Ulysses extends makeDescribable(Action) {
    id = 'ulysses';

    description(game: Game): string {
        return 'mention Ulysses';
    }

    static ability(): Ability {
        const verbs = [new Verb('ulysses'), new Verb('odysseus')];
        return {
            handlers: [ulyssesHandler],
            // TODO 'say "ulysses"' or 'mention ulysses'
            parser: () => Parse.words(verbs).into(new Ulysses()),
            verbs,
            prepositions: [],
        };
    }
}

export const ulyssesHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Ulysses)) return;
    await runner.doOutput("Wasn't he a sailor?");
    return Action.complete();
};
