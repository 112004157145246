import { LandOfTheLivingDead, Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { Cave2 } from '../Cave2';
import { RoomState } from '../Room';
import { Exorcise, SpecialJigsUp, SpecialTimerTick } from '../../abilities';
import { Player } from '../../actors';
import { Bell, Book, Candles, Ghosts } from '../../items';

interface EntranceToHadesState extends RoomState {
    exorcismTimeLimit: number;
}

export class EntranceToHades extends Room<EntranceToHadesState> {
    static spec(): EntitySpec<EntranceToHades> {
        return {
            ref: 'entrance-to-hades',
            constructor: EntranceToHades,
            initial: {
                exorcismTimeLimit: 0,
                contents: [Ghosts.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [advanceExorcism, tryToExorcise],
        };
    }

    exorcismTimeLimit() {
        return this.state.exorcismTimeLimit;
    }

    setExorcismTimeLimit(exorcismTimeLimit: number) {
        this.state.exorcismTimeLimit = exorcismTimeLimit;
    }

    ref() {
        return EntranceToHades.spec().ref;
    }

    name(): string {
        return 'Entrance to Hades';
    }

    description(): string {
        return (
            'You are outside a large gateway, on which is inscribed\n  ' +
            '    "Abandon every hope, all ye who enter here."\n' +
            'The gate is open; through it you can see a desolation, with a pile of ' +
            'mangled corpses in one corner. Thousands of voices, ' +
            'lamenting some hideous fate, can be heard.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.East, SpecialDirection.In],
                to: LandOfTheLivingDead.spec().ref,
                condition: () => !this.contains(this.game.ent(Ghosts)),
                message:
                    'Some invisible force prevents you from passing through the gate.',
            }),
            new Passage({
                via: Direction.Up,
                to: Cave2.spec().ref,
            }),
        ];
    }
}

const advanceExorcism: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const room = game.ent(EntranceToHades);
        if (room.exorcismTimeLimit() > 0) {
            const player = game.ent(Player);
            const candles = game.ent(Candles);
            const ghosts = game.ent(Ghosts);
            room.setExorcismTimeLimit(room.exorcismTimeLimit() - 1);
            if (room.exorcismTimeLimit() <= 0 && room.contains(ghosts)) {
                if (room.contains(player)) {
                    await runner.doOutput(
                        'The tension of this ceremony is broken, and the wraiths, amused ' +
                            'but shaken at your clumsy attempt, resume their hideous jeering.'
                    );
                }
            } else if (
                room.contains(player) &&
                player.hasItem(candles) &&
                candles.isAflame() &&
                !ghosts.isVeryAfraid()
            ) {
                ghosts.setIsVeryAfraid(true);
                await runner.doOutput(
                    'The flames flicker wildly and appear to dance. The earth beneath ' +
                        'your feet trembles, and your legs nearly buckle beneath you. ' +
                        'The spirits cower at your unearthly power.'
                );
            }
        }
        return Action.incomplete();
    }
};

const tryToExorcise: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Exorcise)) {
        const room = actor?.location();
        if (room?.is(EntranceToHades)) {
            if (room.contains(game.ent(Ghosts))) {
                if (
                    actor?.hasItem(game.ent(Candles)) &&
                    actor?.hasItem(game.ent(Book)) &&
                    actor?.hasItem(game.ent(Bell))
                ) {
                    await runner.doOutput('You must perform the ceremony.');
                } else {
                    await runner.doOutput(
                        'You are not equipped for an exorcism.'
                    );
                }
            } else {
                await game.applyAction(
                    runner,
                    new SpecialJigsUp({
                        message:
                            'There is a clap of thunder, and a voice echoes through the cavern: ' +
                            '"Begone, chomper!"  Apparently, the voice thinks you are an evil spirit, ' +
                            'and dismisses you from the realm of the living.',
                    })
                );
            }
            return Action.complete();
        }
    }
};
