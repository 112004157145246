import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class PlatinumBar extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<PlatinumBar> {
        return {
            ref: 'platinum-bar',
            constructor: PlatinumBar,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('platinum', { collective: true }),
                new Noun('metal', { collective: true }),
                new Noun('bar'),
                new Noun('bars', { plural: true }),
                new Noun('bar of platinum'),
                new Noun('bars of platinum', { plural: true }),
            ],
            adjectives: [
                new Adjective('metal'),
                new Adjective('platinum'),
                new Adjective('large'),
            ],
        };
    }

    ref() {
        return PlatinumBar.spec().ref;
    }

    name() {
        return 'large platinum bar';
    }

    description() {
        return 'There is a large platinum bar here.';
    }

    size() {
        return 20;
    }

    scoreOnTake(): number {
        return 12;
    }

    scoreInCase(): number {
        return 10;
    }

    nouns(): Noun[] {
        return PlatinumBar.spec().nouns;
    }

    adjectives(): Adjective[] {
        return PlatinumBar.spec().adjectives;
    }

    // TODO items being sacred?
    isSacred(): boolean {
        return true;
    }
}
