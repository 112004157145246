import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push, SpecialJigsUp } from '../../abilities';
import { Actor, Player } from '../../actors';
import { RoundRoom } from '../../rooms';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';

export class SquareButton extends Item {
    static spec(): EntitySpec<SquareButton> {
        return {
            ref: 'square-button',
            constructor: SquareButton,
            initial: {},
            nouns: [
                new Noun('button'),
                new Noun('buttons', { plural: true }),
                new Noun('switch'),
                new Noun('switches', { plural: true }),
            ],
            adjectives: [new Adjective('square')],
            handlers: [pushSquareButton],
        };
    }

    ref() {
        return SquareButton.spec().ref;
    }

    name(): string {
        return 'square button';
    }

    description(): string {
        return '';
    }

    nouns() {
        return SquareButton.spec().nouns;
    }

    adjectives() {
        return SquareButton.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

export async function buttonZap(game: Game, runner: Runner, actor: Actor) {
    return game.applyAction(
        runner,
        new SpecialJigsUp({
            message: 'There is a giant spark and you are fried to a crisp.',
        }),
        actor
    );
}

const pushSquareButton: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Push) && action.item.is(SquareButton)) {
        if (actor?.is(Player)) {
            return buttonZap(game, runner, actor);
        }
        const roundRoom = game.ent(RoundRoom);
        if (!roundRoom.isHighPower()) {
            roundRoom.state.isHighPower = true;
            await runner.doOutput(
                'The whirring increases in intensity slightly.'
            );
        } else {
            await runner.doOutput('Nothing seems to happen.');
        }

        return Action.complete();
    }
};
