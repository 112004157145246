import styled from 'styled-components';
import React from 'react';

interface HeaderProps {
    roomName: string;
    score: number;
    totalScore: number;
    moveCount: number;
}

export const Header: React.FC<HeaderProps> = ({
    roomName,
    score,
    totalScore,
    moveCount,
}) => (
    <OuterDiv>
        <HeaderDiv>
            <div style={{ width: '200px' }}>{roomName}</div>
            <div>
                Score: {score} / {totalScore}
            </div>
            <div className="hidden-if-small">Moves: {moveCount}</div>
        </HeaderDiv>
    </OuterDiv>
);

const SLATE = '#3b475c';

const OuterDiv = styled.div`
    width: 100%;
    background-color: ${SLATE};
    z-index: 1;

    position: fixed;
    top: 0;
    left: 0;
`;

const HeaderDiv = styled.div`
    width: 100%;
    max-width: calc(min(600px, 100% - 20px));
    height: 18px;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 5px 50px 5px 10px;
    font-family: monospace;
    font-size: 14px;

    div {
        opacity: 0.5;
    }

    @media screen and (max-width: 600px) {
        .hidden-if-small {
            display: none;
        }
    }
`;
