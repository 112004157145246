import { Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Turn } from '../../abilities';
import { Screwdriver } from '../Screwdriver';
import { Machine } from '../Machine';
import { Coal } from '../Coal';
import { Slag } from '../Slag';
import { Diamond } from '..';

export class MachineSwitch extends Item {
    static spec(): EntitySpec<MachineSwitch> {
        return {
            ref: 'machine-switch',
            constructor: MachineSwitch,
            initial: {},
            nouns: [new Noun('switch'), new Noun('switches', { plural: true })],
            adjectives: [],
            handlers: [turnSwitch],
        };
    }

    ref() {
        return MachineSwitch.spec().ref;
    }

    name(): string {
        return 'switch';
    }

    description(): string {
        return '';
    }

    nouns() {
        return MachineSwitch.spec().nouns;
    }

    adjectives() {
        return MachineSwitch.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    canBeTurned(): boolean {
        return true;
    }
}

const turnSwitch: Handler = async ({ action, runner, game }) => {
    if (action.is(Turn) && action.item.is(MachineSwitch) && action.tool) {
        if (action.tool.is(Screwdriver)) {
            const machine = game.ent(Machine);
            if (machine.isOpen()) {
                await runner.doOutput(
                    "The machine doesn't seem to want to do anything."
                );
            } else {
                const coal = game.ent(Coal);
                if (machine.contains(coal)) {
                    coal.moveTo(undefined);
                    game.ent(Diamond).moveTo(machine);
                } else if (!machine.isEmpty()) {
                    game.ent(Slag).moveTo(machine);
                }
                await runner.doOutput(
                    'The machine comes to life (figuratively) with a dazzling ' +
                        'display of colored lights and bizarre noises. ' +
                        'After a few moments, the excitement abates.'
                );
            }
        } else {
            await runner.doOutput(
                `It seems that ${action.tool.the()} won't do.`
            );
        }
        return Action.complete();
    }
};

/*

  if game.objects["machine_switch"] is not direct: return
  if parse.verb in game.get_verbs("turn"):
    if indirect is game.objects["screwdriver"]:
      if game.objects["machine"].open:
        raise Complete("The machine doesn't seem to want to do anything.")
      if game.objects["coal"] in game.objects["machine"]:
        game.remove_object(game.objects["coal"])
        game.objects["machine"].add_object(game.objects["diamond"])
      elif not game.objects["machine"].contents.empty:
        game.objects["machine"].add_object(game.objects["slag"])
      raise Complete("The machine comes to life (figuratively) with a dazzling display of colored lights and bizarre noises. After a few moments, the excitement abates.")
    raise Complete("It seems that %s won't do." % game.add_article(indirect.name))
 */
