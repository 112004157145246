import { Noun, Adjective } from '../../../parse';
import { Entity, makeTakeable } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler, Reference } from '../../game';
import { Drop, Take, Tie, Untie } from '../../abilities';
import { Railing } from '../Railing';
import { Game } from '../../game/game';
import { Room, SmallSquareRoom, SlideRoom } from '../../rooms';
import { DomeRoom } from '../../rooms/DomeRoom';
import { Timber } from '../Timber';
import { Coffin } from '../Coffin';

interface RopeState extends ItemState {
    fastenedItem: Reference | undefined;
}

abstract class Base extends Item<RopeState> {}

export class Rope extends makeTakeable(Base) {
    static spec(): EntitySpec<Rope> {
        return {
            ref: 'rope',
            constructor: Rope,
            initial: {
                hasBeenTaken: false,
                fastenedItem: undefined,
            },
            nouns: [
                new Noun('coil'),
                new Noun('coils', { plural: true }),
                new Noun('rope'),
                new Noun('ropes', { plural: true }),
                new Noun('coil of rope'),
                new Noun('coils of rope', { plural: true }),
            ],
            adjectives: [new Adjective('large'), new Adjective('hemp')],
            handlers: [tieRope, takeRope, untieRope, dropRope],
        };
    }

    ref() {
        return Rope.spec().ref;
    }

    name() {
        return 'rope';
    }

    description() {
        const fastenedItem = this.fastenedItem();
        if (fastenedItem) {
            return `There is a large coil of rope tied to ${fastenedItem.the()}.`;
        }
        return 'There is a large coil of rope here.';
    }

    initialDescription() {
        return 'A large coil of rope is lying in the corner.';
    }

    shouldBeDescribed() {
        return !this.fastenedItem();
    }

    size() {
        return 10;
    }

    nouns(): Noun[] {
        return Rope.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Rope.spec().adjectives;
    }

    canBeFastened(): boolean {
        return true;
    }

    // TODO items being sacred?
    isSacred(): boolean {
        return true;
    }

    fastenedItem(): Entity | undefined {
        return this.state.fastenedItem
            ? this.game.get(this.state.fastenedItem)
            : undefined;
    }

    canBeClimbed(): boolean {
        // TODO select only some items
        return this.state.fastenedItem !== undefined;
    }
}

// TODO more rope actions

/*
  if game.here in [game.rooms["slide_one"], game.rooms["slide_two"], game.rooms["slide_three"]]:
    if parse.verb in game.get_verbs("take"):
      raise Complete("What do you think is suspending you in midair?")
    if parse.verb not in game.get_verbs("clumb_up", "climb_down", "climb"):
      raise Complete("It's not easy to play with the rope in your position.")
  if (game.here not in [game.rooms["dome_room"], game.rooms["slide_room"]] and
    indirect and indirect not in [game.objects["timber"], game.objects["coffin"]]):
    game.rope_on_railing = False
    game.object_tied_by_rope = None
    game.objects["coffin"].describe = False
    game.objects["timber"].describe = False
    if parse.verb in game.get_verbs("tie"):
      raise Complete("There is nothing it can be tied to.")
 */

const untieRope: Handler = async ({ action, runner }) => {
    if (action.is(Untie) && action.item.is(Rope)) {
        const { item: rope } = action;
        const fastenedTo = rope.fastenedItem();
        if (action.fixture && !fastenedTo?.isEqualTo(action.fixture)) {
            await runner.doOutput(`It is not tied to ${action.fixture.the()}.`);
        }
        if (!fastenedTo) {
            await runner.doOutput('It is not tied to anything.');
        } else {
            rope.state.fastenedItem = undefined;
            await runner.doOutput('The rope is now untied.');
        }
        return Action.complete();
    }
};

function ropeAway(game: Game, room: Room) {
    game.ent(Rope).moveTo(room);
}

const takeRope: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Rope)) {
        const { item: rope } = action;
        const fastenedTo = rope.fastenedItem();
        if (fastenedTo) {
            if (fastenedTo.is(Railing)) {
                await runner.doOutput('The rope is tied to the railing.');
            } else {
                await runner.doOutput(
                    `The rope is tied to ${fastenedTo.an()}.`
                );
            }
            return Action.complete();
        }
    }
};

const dropRope: Handler = async ({ action, runner, actor }) => {
    if (
        action.is(Drop) &&
        action.item.is(Rope) &&
        action.item.fastenedItem() === undefined
    ) {
        const room = actor?.location();
        if (room?.is(DomeRoom)) {
            action.item.moveTo(room);
            await runner.doOutput('The rope drops gently to the floor below.');
            return Action.complete();
        }
    }
};

const tieRope: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Tie) && action.item.is(Rope)) {
        let fixture;
        const { item: rope } = action;
        fixture = action.fixture;
        if (!fixture) {
            const room = actor?.location();
            let coffin;
            let timber;
            if (room?.is(DomeRoom)) {
                fixture = game.ent(Railing);
            } else if (room?.contains((coffin = game.ent(Coffin)))) {
                fixture = coffin;
            } else if (room?.contains((timber = game.ent(Timber)))) {
                fixture = timber;
            } else {
                await runner.doOutput('There is nothing it can be tied to.');
                return Action.complete();
            }
        }
        if (fixture.is(Railing)) {
            const fastenedTo = rope.fastenedItem();
            if (fastenedTo && fastenedTo.isEqualTo(fixture)) {
                await runner.doOutput('The rope is already attached.');
            } else if (fastenedTo) {
                await runner.doOutput(
                    `The rope is already tied to ${fastenedTo.the()}.`
                );
            } else {
                rope.state.fastenedItem = fixture.ref();
                ropeAway(game, game.ent(DomeRoom));
                await runner.doOutput(
                    'The rope drops over the side and comes within ten feet of the floor.'
                );
            }
            return Action.complete();
        }
        if (fixture.is(Railing) || fixture.is(Timber) || fixture.is(Coffin)) {
            const fastenedTo = rope.fastenedItem();
            const room = actor?.location();
            if (fastenedTo && fastenedTo.isEqualTo(fixture)) {
                await runner.doOutput('The rope is already attached.');
            } else if (fastenedTo) {
                await runner.doOutput(
                    `The rope is already tied to ${fastenedTo.the()}.`
                );
            } else if (!room?.contains(fixture)) {
                await runner.doOutput(
                    'It is too clumsy when you are carrying it.'
                );
            } else {
                rope.state.fastenedItem = fixture.ref();
                rope.moveTo(room);
                if (room?.is(SlideRoom)) {
                    await runner.doOutput('The rope dangles down the slide.');
                } else if (room?.is(SmallSquareRoom)) {
                    await runner.doOutput(
                        'The rope dangles down into the darkness.'
                    );
                } else {
                    await runner.doOutput('Done.');
                }
            }
            return Action.complete();
        }
    }
};
