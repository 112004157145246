import { Verb, Parse, Parser, Value } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { MoveUnresolved, moveUnresolvedHandler } from './MoveUnresolved';

export class Move extends Action {
    id = '~move';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [moveHandler, moveUnresolvedHandler],
            parser,
            verbs: [
                new Verb('move'),
                new Verb('move around'),
                new Verb('adjust'),
                new Verb('relocate'),
                new Verb('reposition'),
                new Verb('shift'),
            ],
            prepositions: [],
        };
    }
}

export const moveHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Move)) return undefined;
    const { item } = action;
    if (item.isItem() && item.canBeMoved()) {
        await runner.doOutput(`Moving ${item.the()} reveals nothing.`);
    } else {
        await runner.doOutput(`You can't move ${item.the()}.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, MoveUnresolved> =>
    Parse.words(Move.ability().verbs)
        .before(Parse.whitespace())
        .chain((_verb) =>
            Parse.target(game.lexicon).map(
                (item) => new MoveUnresolved({ item })
            )
        );
