import { Direction } from '../../../parse';
import { WestOfHouse } from '../WestOfHouse';
import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { BarredHouseWindow, House } from '../../items';
import { BehindHouse } from '../BehindHouse';
import { Forest2 } from '../Forest2';

export class SouthOfHouse extends Room {
    static spec(): EntitySpec<SouthOfHouse> {
        return {
            ref: 'south-of-house',
            constructor: SouthOfHouse,
            initial: {
                contents: [BarredHouseWindow.spec().ref, House.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return SouthOfHouse.spec().ref;
    }

    name(): string {
        return 'South of House';
    }

    description(): string {
        return 'You are facing the south side of a white house. There is no door here, and all the windows are barred.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: WestOfHouse.spec().ref }),
            new Passage({ via: Direction.East, to: BehindHouse.spec().ref }),
            new Passage({ via: Direction.South, to: Forest2.spec().ref }),
            new Passage({
                via: Direction.North,
                message: 'The windows are all barred.',
            }),
        ];
    }

    hasWalls() {
        return false;
    }

    hasNorthWall(): boolean {
        return true;
    }
}
