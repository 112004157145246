import { Item, Flammable, FlammableState } from '../../items/Item';
import { Constructor } from './Constructor';

export function makeFlammable<TBase extends Constructor<Item>>(Base: TBase) {
    abstract class C extends Base implements Flammable {
        abstract state: FlammableState;

        isAflame(): boolean {
            return this.state.isAflame;
        }

        isFlammable(): this is Flammable {
            return true;
        }

        isNaturallyLit() {
            return this.isAflame();
        }
    }

    return C;
}
