import { Forest1, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { Book, Candles } from '../../items';
import { Temple } from '../Temple';
import { Pray, SpecialEnter } from '../../abilities';

export class Altar extends Room {
    static spec(): EntitySpec<Altar> {
        return {
            ref: 'altar',
            constructor: Altar,
            initial: {
                contents: [
                    Book.spec().ref,
                    Candles.spec().ref,
                    // AltarTable?
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [prayAtAltar],
        };
    }

    ref() {
        return Altar.spec().ref;
    }

    name(): string {
        return 'Altar';
    }

    description(): string {
        return 'This is the east end of a large temple. In front of you is what appears to be an altar.';
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.West,
                to: Temple.spec().ref,
            }),
        ];
    }
}

const prayAtAltar: Handler = async ({ action, actor, game, runner }) => {
    if (action.is(Pray) && actor?.location()?.is(Altar)) {
        await game.applyAction(
            runner,
            new SpecialEnter({ room: game.ent(Forest1) })
        );
        return Action.complete();
    }
};

// TODO maybe add if you swear in the temple, you die
