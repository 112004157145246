import { Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Hello, Kill, SpecialJigsUp } from '../../abilities';

export class Guardians extends Item {
    static spec(): EntitySpec<Guardians> {
        return {
            ref: 'guardians',
            constructor: Guardians,
            initial: {},
            nouns: [
                new Noun('guardian'),
                new Noun('guardians', { collective: true }),
                new Noun('guard'),
                new Noun('guards', { collective: true }),
                new Noun('guardian of zork'),
                new Noun('guardians of zork', { collective: true }),
                new Noun('statue'),
                // new Noun('statues', { collective: true }), TODO
            ],
            adjectives: [],
            handlers: [attackGuardians, helloGuardians],
        };
    }

    ref() {
        return Guardians.spec().ref;
    }

    name(): string {
        return 'Guardians of Zork';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Guardians.spec().nouns;
    }

    adjectives() {
        return Guardians.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const attackGuardians: Handler = async ({ action, runner, game }) => {
    if (action.is(Kill) && action.weapon && action.enemy.is(Guardians)) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    'Attacking the Guardians is about as useful as attacking a stone wall. ' +
                    'Unfortunately for you, your futile blow attracts their attention, and ' +
                    'they manage to dispatch you effortlessly.',
            })
        );
        return Action.complete();
    }
};

const helloGuardians: Handler = async ({ action, runner }) => {
    if (action.is(Hello) && action.person?.is(Guardians)) {
        await runner.doOutput('The statues are impassive.');
        return Action.complete();
    }
};
