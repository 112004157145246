import { Cellar, InASlide3, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, Entity, EntitySpec, Handler } from '../../game';
import { QuantityOfWater, Rope, Slide } from '../../items';
import { InASlide2 } from '../InASlide2';
import { SlideRoom } from '../SlideRoom';
import {
    Drop,
    Eat,
    Move,
    Push,
    Rub,
    SpecialEnter,
    Take,
} from '../../abilities';
import { Actor } from '../../actors';

export class InASlide1 extends Room {
    static spec(): EntitySpec<InASlide1> {
        return {
            ref: 'in-a-slide-1',
            constructor: InASlide1,
            initial: {
                contents: [Slide.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [playWithRopeInSlide, dropInSlide],
        };
    }

    ref() {
        return InASlide1.spec().ref;
    }

    name(): string {
        return 'Slide';
    }

    description(): string {
        return (
            'This is an uncomfortable spot within the coal chute. ' +
            'The rope to which you are clinging can be seen rising into ' +
            'the darkness above. There is more rope dangling below you.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return true;
    }

    globalObjects(): Entity[] {
        return [this.game.ent(Rope)];
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Up, to: SlideRoom.spec().ref }),
            new Passage({
                via: Direction.Down,
                to: InASlide2.spec().ref,
            }),
        ];
    }
}

function isInSlide(actor: Actor) {
    const room = actor?.location();
    return (
        room &&
        (room.like(InASlide1) || room.like(InASlide2) || room.like(InASlide3))
    );
}

const playWithRopeInSlide: Handler = async ({
    action,
    runner,
    actor,
    game,
}) => {
    if (actor && action.is(Take) && action.item.is(Rope) && isInSlide(actor)) {
        await runner.doOutput('What do you think is suspending you in midair?');
        return Action.complete();
    }
    if (
        actor &&
        // TODO other actions? this was a catchall in the original
        (action.is(Push) /* || action.is2(Pull) */ ||
            action.is(Move) ||
            action.is(Rub) ||
            action.is(Eat)) &&
        action.item.is(Rope) &&
        isInSlide(actor)
    ) {
        await runner.doOutput(
            "It's not easy to play with the rope in your position."
        );
        return Action.complete();
    }

    if (actor && action.is(Drop) && action.item.is(Rope) && isInSlide(actor)) {
        await runner.doOutput('You tumble down the chute to the cellar.');
        await game.applyAction(
            runner,
            new SpecialEnter({ room: game.ent(Cellar) })
        );
        return Action.complete();
    }
};

const dropInSlide: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Drop) && actor && isInSlide(actor)) {
        await runner.doOutput(
            `${action.item.The()} falls through the slide and is gone.`
        );
        if (action.item.is(QuantityOfWater)) {
            action.item.moveTo(undefined);
        } else {
            action.item.moveTo(game.ent(Cellar));
        }
        return Action.complete();
    }
};
