import { Maze1, Passage, Room, WindingPassage } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { EastWestPassage } from '../EastWestPassage';
import { EngravingsCave } from '../EngravingsCave';
import { LowRoom } from '../LowRoom';
import { NorthSouthPassage } from '../NorthSouthPassage';
import { SpecialDescribeRoom } from '../../abilities/SpecialDescribeRoom';
import { RoomState } from '../Room';
import { Go, SpecialEnter, SpecialJigsUp } from '../../abilities';
import { ViolinCase } from '../../items';
import { GrailRoom } from '../GrailRoom';
import { DeepCanyon } from '../DeepCanyon';

interface RoundRoomState extends RoomState {
    isMagnetic: boolean;
    isHighPower: boolean;
}

export class RoundRoom extends Room<RoundRoomState> {
    static spec(): EntitySpec<RoundRoom> {
        return {
            ref: 'round-room',
            constructor: RoundRoom,
            initial: {
                contents: [ViolinCase.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
                isMagnetic: true,
                isHighPower: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [describeRoundRoom, goFromRoundRoom, enterRoundRoom],
        };
    }

    ref() {
        return RoundRoom.spec().ref;
    }

    name(): string {
        return 'Round Room';
    }

    description(): string {
        return '';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isMagnetic(): boolean {
        return this.state.isMagnetic;
    }

    isHighPower(): boolean {
        return this.state.isHighPower;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: EngravingsCave.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                to: EngravingsCave.spec().ref,
            }),
            new Passage({ via: Direction.East, to: GrailRoom.spec().ref }),
            new Passage({
                via: Direction.West,
                to: EastWestPassage.spec().ref,
            }),
            new Passage({
                via: Direction.Northwest,
                to: DeepCanyon.spec().ref,
            }),
            new Passage({
                via: Direction.Northeast,
                to: NorthSouthPassage.spec().ref,
            }),
            new Passage({
                via: Direction.Southeast,
                to: WindingPassage.spec().ref,
            }),
            new Passage({ via: Direction.Southwest, to: Maze1.spec().ref }),
            new Passage({
                via: SpecialDirection.Out,
                to: WindingPassage.spec().ref,
            }),
        ];
    }
}

const describeRoundRoom: Handler = async ({ action, runner }) => {
    if (action.is(SpecialDescribeRoom) && action.room.is(RoundRoom)) {
        await runner.doOutput(
            'You are in a circular room with passages off in all eight directions.'
        );
        if (action.room.isMagnetic()) {
            await runner.doOutput(
                "Your compass needle spins wildly, and you can't get your bearings."
            );
        }
        return Action.complete({ withConsequence: false });
    }
};

const EXITS = [
    Direction.North,
    Direction.South,
    Direction.East,
    Direction.West,
    Direction.Northwest,
    Direction.Northeast,
    Direction.Southeast,
    Direction.Southwest,
];

const goFromRoundRoom: Handler = async ({
    action,
    runner,
    actor,
    game,
    extensions,
}) => {
    if (action.is(Go) && actor) {
        const room = game.locateEntity(actor);
        if (room && room.is(RoundRoom) && room.isMagnetic()) {
            await runner.doOutput(
                'Unfortunately, it is impossible to tell directions in here.'
            );
            return extensions.replaceAction(
                new Go({ direction: game.choiceOf(EXITS) })
            );
        }
    }
};

const enterRoundRoom: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(SpecialEnter) && action.room.is(LowRoom)) {
        const roundRoom = game.ent(RoundRoom);
        if (roundRoom.isMagnetic()) {
            if (roundRoom.isHighPower()) {
                return game.applyAction(
                    runner,
                    new SpecialJigsUp({ message: ZAPPED }),
                    actor
                );
            }
        }
        return Action.incomplete();
    }
};

const ZAPPED =
    'According to Prof. TAA of MIT Tech, the rapidly changing magnetic fields in ' +
    'the room are so intense as to cause you to be electrocuted. ' +
    "I really don't know, but in any event, something just killed you.";
