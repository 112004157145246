import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeOpenable, makeTakeable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { QuantityOfWater } from '../QuantityOfWater';
import { Mung, Shake, Throw } from '../../abilities';

export class Bottle extends makeTakeable(makeOpenable(makeContainer(Item))) {
    static spec(): EntitySpec<Bottle> {
        return {
            ref: 'bottle',
            constructor: Bottle,
            initial: {
                contents: [QuantityOfWater.spec().ref],
                isOpen: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('bottle of water'),
                new Noun('bottle'),
                new Noun('water bottle'),
                new Noun('bottles', { plural: true }),
                new Noun('water bottles', { plural: true }),
                new Noun('bottles of water', { plural: true }),
            ],
            adjectives: [new Adjective('clear'), new Adjective('glass')],
            handlers: [breakBottle, shakeBottle, throwBottle],
        };
    }

    ref() {
        return Bottle.spec().ref;
    }

    name(): string {
        return 'glass bottle';
    }

    description(): string {
        return 'A clear glass bottle is here.';
    }

    initialDescription(): string {
        return 'A bottle is sitting on the table.';
    }

    totalCapacity(): number {
        return 4;
    }

    isTransparent(): boolean {
        return true;
    }

    canBeOpened(): boolean {
        return true;
    }

    canSeeInto(): boolean {
        return this.isOpen() || this.isTransparent();
    }

    nouns() {
        const nouns = [
            new Noun('bottle'),
            new Noun('bottles', { plural: true }),
        ];

        if (this.contains(this.game.ent(QuantityOfWater))) {
            nouns.push(
                new Noun('bottle of water'),
                new Noun('bottles of water', { plural: true }),
                new Noun('water bottle'),
                new Noun('water bottles', { plural: true })
            );
        }

        return nouns;
    }

    adjectives() {
        return Bottle.spec().adjectives;
    }
}

const throwBottle: Handler = async ({ action, runner, actor }) => {
    if (action.is(Throw) && action.item.is(Bottle)) {
        const whereHit = actor?.location()?.hasWalls() ? 'far wall' : 'ground';
        await runner.doOutput(`The bottle hits the ${whereHit} and shatters.`);
        action.item.moveTo(undefined);
        return Action.complete();
    }
};

const breakBottle: Handler = async ({ action, runner }) => {
    if (action.is(Mung) && action.item.is(Bottle)) {
        await runner.doOutput('A brilliant maneuver destroys the bottle.');
        action.item.moveTo(undefined);
        return Action.complete();
    }
};

const shakeBottle: Handler = async ({ action, runner, game }) => {
    if (action.is(Shake) && action.item.is(Bottle) && action.item.isOpen()) {
        const water = game.ent(QuantityOfWater);
        if (action.item.contains(water)) {
            await runner.doOutput(
                'The water spills to the floor and evaporates.'
            );
            water.moveTo(undefined);
            return Action.complete();
        }
    }
};
