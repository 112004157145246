import { GratingRoom, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { Forest2 } from '../Forest2';
import { Forest3 } from '../Forest3';
import { BehindHouse } from '../BehindHouse';
import { Forest5 } from '../Forest5';
import { Grating, PileOfLeaves } from '../../items';

export class Clearing extends Room {
    static spec(): EntitySpec<Clearing> {
        return {
            ref: 'clearing',
            constructor: Clearing,
            initial: {
                contents: [PileOfLeaves.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Clearing.spec().ref;
    }

    name(): string {
        return 'Clearing';
    }

    description(): string {
        let description =
            'You are in a clearing, with a forest surrounding you on the west and south.';
        const grating = this.game.ent(Grating);
        const leaves = this.game.ent(PileOfLeaves);
        if (grating.isOpen()) {
            description +=
                '\nThere is an open grating, descending into darkness.';
        } else if (leaves.isMoved()) {
            description +=
                '\nThere is a grating securely fastened into the ground.';
        }
        return description;
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    globalObjects(): Entity[] {
        const objects = [...super.globalObjects()];
        if (this.game.ent(PileOfLeaves).isMoved()) {
            objects.push(this.game.ent(Grating));
        }
        return objects;
    }

    passages(): Passage[] {
        // TODO crs passages can just return the actual room with `this.game.ent(Forest5)`
        let gratingMessage;
        if (!this.game.ent(PileOfLeaves).isMoved()) {
            gratingMessage = 'There is no way down.';
        } else if (this.game.ent(Grating).isLocked()) {
            gratingMessage = 'The grating is locked.';
        } else {
            gratingMessage = 'The grating is closed.';
        }
        return [
            new Passage({
                via: Direction.Southwest,
                to: BehindHouse.spec().ref,
            }),
            new Passage({ via: Direction.Southeast, to: Forest5.spec().ref }),
            new Passage({ via: Direction.North, to: Clearing.spec().ref }),
            new Passage({ via: Direction.East, to: Clearing.spec().ref }),
            new Passage({ via: Direction.South, to: Forest2.spec().ref }),
            new Passage({ via: Direction.West, to: Forest3.spec().ref }),
            new Passage({
                via: Direction.Down,
                to: GratingRoom.spec().ref,
                condition: () => this.game.ent(Grating).isOpen(),
                message: gratingMessage,
            }),
        ];
    }

    isPartOfForest() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
