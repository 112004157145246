import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { VolcanoNearWideLedge } from '../VolcanoNearWideLedge';
import { VolcanoBottom } from '../VolcanoBottom';
import { DustyRoom } from '../DustyRoom';
import { Balloon, Hook2, VolcanoGnomeDoor } from '../../items';

export class WideLedge extends Room {
    static spec(): EntitySpec<WideLedge> {
        return {
            ref: 'wide-ledge',
            constructor: WideLedge,
            initial: {
                contents: [
                    Hook2.spec().ref,
                    VolcanoGnomeDoor.spec().ref,
                    // WideLedgeItem
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return WideLedge.spec().ref;
    }

    name(): string {
        return 'Wide Ledge';
    }

    description(): string {
        let description =
            'You are on a wide ledge high into the volcano. ' +
            'The rim of the volcano is about 200 feet above and ' +
            'there is a precipitous drop below to the bottom.';
        // TODO I suppose the room could have been munged another way...
        if (this.game.ent(DustyRoom).isMunged()) {
            description += ' The way to the south is blocked by rubble.';
        } else {
            description += ' There is a small door to the south.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Down,
                message: "It's a long way down.",
            }),
            new Passage({
                via: SpecialDirection.Launch,
                to: VolcanoNearWideLedge.spec().ref,
                allowedVehicles: [this.game.ent(Balloon)],
            }),
            new Passage({
                via: Direction.West,
                to: VolcanoBottom.spec().ref,
                condition: () => {
                    const door = this.game.ent(VolcanoGnomeDoor);
                    return !door.isHidden() && door.isOpen();
                },
                message: !this.game.ent(VolcanoGnomeDoor)?.isHidden()
                    ? 'The door is closed.'
                    : undefined,
            }),
            new Passage({
                via: Direction.South,
                to: DustyRoom.spec().ref,
            }),
        ];
    }
}
