import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { TieUp } from './TieUp';
import { UnresolvedAction } from '../UnresolvedAction';

export class TieUpUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'tie-up';

    item: Target | undefined;

    tool: Target | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Target | undefined;
        tool: Target | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'tie something up';
        }
        if (this.tool === undefined) {
            return `tie up ${targetDescription(game, this.item)}`;
        }
        return `tie up ${targetDescription(
            game,
            this.item
        )} with ${targetDescription(game, this.tool)}`;
    }
}

export const tieUpUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(TieUpUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new TieUpUnresolved({ item, tool: undefined }),
        missing: () => 'Tie up what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to tie up, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    const toolPartial = (tool: Target) =>
        new TieUpUnresolved({ item: action.item, tool });
    if (action.tool) {
        const { item: tool } = await game.resolve(runner, action.tool, actor, {
            partial: toolPartial,
            missing: () => `With what would you like to tie up ${item.the()}?`,
            ambiguous: (desc, opt) =>
                `Which ${desc} would you like to use to tie up ${item.the()}, ${opt}?`,
            condition: (item) => item.isItem() && item.canBeFastened(),
        });

        if (!tool) {
            return Action.complete({ withConsequence: false });
        }

        if (tool && !(await game.have(runner, tool, actor)))
            return Action.complete();

        return await game.applyAction(runner, new TieUp({ item, tool }));
    }

    const tool = actor
        ?.inventory()
        .find((item) => item.isItem() && item.canBeFastened());

    if (tool === undefined) {
        await runner.doOutput(
            `With what would you like to tie up ${item.the()}?`
        );
        game.partial = toolPartial;
        return Action.complete({ withConsequence: false });
    }

    return await game.applyAction(runner, new TieUp({ item, tool }));
};
