import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { LowRoom } from '../LowRoom';
import { DingyCloset } from '../DingyCloset';
import { RoundButton, SquareButton, TriangularButton } from '../../items';

export class MachineRoom extends Room {
    static spec(): EntitySpec<MachineRoom> {
        return {
            ref: 'machine-room',
            constructor: MachineRoom,
            initial: {
                contents: [
                    SquareButton.spec().ref,
                    RoundButton.spec().ref,
                    TriangularButton.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return MachineRoom.spec().ref;
    }

    name(): string {
        return 'Machine Room';
    }

    description(): string {
        return (
            'This is a large room full of assorted heavy machinery. ' +
            'The room smells of burned resistors. The room is noisy ' +
            'from the whirring sounds of the machines. Along one wall ' +
            'of the room are three buttons which are, respectively, round, ' +
            'triangular, and square. Naturally, above these buttons are ' +
            'instructions written in EBCDIC. A large sign in English above ' +
            "all the buttons says\n  'DANGER -- HIGH VOLTAGE '\nThere are " +
            'exits to the west and the south.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: LowRoom.spec().ref }),
            new Passage({ via: Direction.South, to: DingyCloset.spec().ref }),
        ];
    }
}
