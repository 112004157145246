import { Game } from './game/game';
import { Group, Target, Pronoun } from '../parse';
import { ALL_WORDS } from './constants';

export function pluralize(
    num: number,
    word: string,
    _pluralWord?: string
): string {
    const pluralWord = withDefault(_pluralWord, `${word}s`);
    if (num === 1) {
        return word;
    }
    return pluralWord;
}

export function mulberry32(seed: number) {
    /* eslint-disable no-bitwise */
    const newSeed = seed + 0x6d2b79f5;
    let t = newSeed;
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return {
        result: ((t ^ (t >>> 14)) >>> 0) / 4294967296,
        seed: newSeed,
    };
    /* eslint-enable no-bitwise */
}

export function withDefault<T>(
    specifiedValue: T | undefined,
    defaultValue: T
): T {
    if (specifiedValue === undefined) {
        return defaultValue;
    }
    return specifiedValue;
}

export function listify(items: string[], conjunction = 'and') {
    return items
        .map((item, index) => {
            const comma = items.length > 2 && index !== 0 ? ', ' : '';
            const and =
                index > 0 && index === items.length - 1
                    ? `${conjunction} `
                    : '';
            const space = index === 1 && items.length === 2 ? ' ' : '';
            return `${comma}${space}${and}${item}`;
        })
        .join('');
}

export function targetDescription(game: Game, target: Target): string {
    return listify(
        target.items.map((item) => {
            if (item instanceof Group) {
                const include: string = targetDescription(
                    game,
                    new Target({ items: [item.include] })
                );
                if (item.exclude) {
                    return `${include} except ${targetDescription(
                        game,
                        new Target({ items: item.exclude })
                    )}`;
                }
                return include;
            }
            if (item instanceof Pronoun) {
                if (item.value === 'it') {
                    if (game.state.it) {
                        return game.get(game.state.it).the();
                    }
                    return 'something';
                }
                if (item.value === 'them') {
                    if (game.state.them) {
                        return listify(
                            game.state.them.map((ref) => game.get(ref).the())
                        );
                    }
                    return 'some things';
                }
                if (ALL_WORDS.some((word) => word.value === item.value)) {
                    return 'everything';
                }
                return 'something';
            }
            if (item.noun.collective || item.noun.plural) {
                return `some ${item.description}`;
            }
            // TODO crs make nouns have articles...
            return `${item.noun.article} ${item.description}`;
        })
    );
}
