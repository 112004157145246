import { DamBase, Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { CLIFFS_IN_WAY, CURRENT_STRONG } from '../../constants';
import { EntitySpec } from '../../game';
import { Boat, River } from '../../items';
import { River2 } from '../River2';

export class River1 extends Room {
    static spec(): EntitySpec<River1> {
        return {
            ref: 'river-1',
            constructor: River1,
            initial: {
                contents: [River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return River1.spec().ref;
    }

    name(): string {
        return 'Frigid River';
    }

    description(): string {
        return (
            'You are on the River Frigid in the vicinity of the Dam. ' +
            'The river flows quietly here. There is a landing on the west shore.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isOnLand(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.Up, Direction.North],
                message: CURRENT_STRONG,
            }),
            new Passage({ via: Direction.East, message: CLIFFS_IN_WAY }),
            new Passage({
                via: [Direction.West, SpecialDirection.Land],
                to: DamBase.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: [Direction.Down, Direction.South],
                to: River2.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
