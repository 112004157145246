import { SpecialAction } from '../SpecialAction';

export class SpecialNoParse extends SpecialAction {
    id = '*no-parse';

    constructor({ command }: { command: string }) {
        super();
        this.command = command;
    }
}
