import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Shovel } from '../Shovel';
import { Dig } from '../../abilities';
import { makeTakeable } from '../../game/Entity';

interface GuanoState extends ItemState {
    holeSize: number;
}

abstract class Base extends Item<GuanoState> {}

export class Guano extends makeTakeable(Base) {
    static spec(): EntitySpec<Guano> {
        return {
            ref: 'guano',
            constructor: Guano,
            initial: {
                holeSize: 0,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('guano', { collective: true }),
                new Noun('shit', { collective: true }),
                new Noun('crap', { collective: true }),
                new Noun('bat guano', { collective: true }),
                new Noun('bat shit', { collective: true }),
                new Noun('bat crap', { collective: true }),
                new Noun('hunk'),
                new Noun('hunk of bat guano'),
                new Noun('hunk of bat shit'),
                new Noun('hunk of shit'),
                new Noun('hunk of guano'),
                new Noun('hunk of crap'),
            ],
            adjectives: [],
            handlers: [digInGuano],
        };
    }

    ref() {
        return Guano.spec().ref;
    }

    name() {
        return 'hunk of bat guano';
    }

    size() {
        return 20;
    }

    description() {
        return 'There is a hunk of bat guano here.';
    }

    nouns(): Noun[] {
        return Guano.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Guano.spec().adjectives;
    }

    holeSize(): number {
        return this.state.holeSize;
    }

    setHoleSize(holeSize: number) {
        this.state.holeSize = holeSize;
    }
}

const GUANO_HOLE_PROGRESS = [
    'You are digging into a pile of bat guano.',
    'You seem to be getting knee deep in guano.',
    'You are covered with bat turds, cretin.',
];

// TODO maybe require that the guano be on the ground...
const digInGuano: Handler = async ({ action, runner }) => {
    if (action.is(Dig) && action.item?.is(Guano) && action.tool?.is(Shovel)) {
        const { item: guano } = action;
        guano.setHoleSize(guano.holeSize() + 1);
        if (guano.holeSize() > 3) {
            await runner.doOutput('This is getting you nowhere.');
        } else {
            await runner.doOutput(GUANO_HOLE_PROGRESS[guano.holeSize() - 1]);
        }
        return Action.complete();
    }
};
