import { Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { SpecialAfter } from '../SpecialAfter';
import { SpecialAction } from '../SpecialAction';

export class SpecialThen extends SpecialAction {
    id = '*then';

    actions: Action[];

    constructor({ actions }: { actions: Action[] }) {
        super();
        this.actions = actions;
    }

    static ability(): Ability {
        return {
            handlers: [specialThenHandler],
            parser,
            verbs: [],
            prepositions: [],
        };
    }
}

export const specialThenHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SpecialThen)) return;

    for (const inner of action.actions) {
        const effect = await game.applyAction(runner, inner, actor);
        await game.applyAction(runner, new SpecialAfter({ action, effect }));
    }
    return Action.complete({ withConsequence: false });
};

const parser = (game: Game) => {
    const action = Parse.any(...game.parsers);
    const thenOrAnd = Parse.any(
        Parse.whitespace().before(Parse.word('then')),
        Parse.whitespace().before(Parse.word('and')),
        Parse.comma(),
        Parse.comma().before(Parse.whitespace()).before(Parse.word('and')),
        Parse.comma().before(Parse.whitespace()).before(Parse.word('then'))
    );
    return Parse.many(action.before(thenOrAnd.then(Parse.whitespace()))).chain(
        (head) =>
            action.map((last) => {
                const actions = [...head, last];
                if (actions.length === 1) {
                    return actions[0];
                }
                return new SpecialThen({ actions: [...head, last] });
            })
    );
};
