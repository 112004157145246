import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push } from '../../abilities';
import { CellDoor } from '../CellDoor';
import { BronzeDoor } from '../BronzeDoor';
import { Sundial } from '../Sundial';
import { PrisonCell } from '../../rooms';
import { Player } from '../../actors';

export class SundialButton extends Item {
    static spec(): EntitySpec<SundialButton> {
        return {
            ref: 'sundial-button',
            constructor: SundialButton,
            initial: {},
            nouns: [
                new Noun('button'),
                new Noun('buttons', { plural: true }),
                new Noun('switch'),
                new Noun('switches', { plural: true }),
            ],
            adjectives: [
                new Adjective('sundial'),
                new Adjective('dial'),
                new Adjective('center'),
                new Adjective('middle'),
                new Adjective('central'),
                new Adjective('large'),
            ],
            handlers: [pushSundialButton],
        };
    }

    ref() {
        return SundialButton.spec().ref;
    }

    name(): string {
        return 'large button';
    }

    description(): string {
        return '';
    }

    nouns() {
        return SundialButton.spec().nouns;
    }

    adjectives() {
        return SundialButton.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushSundialButton: Handler = async ({ action, runner, game }) => {
    if (action.is(Push) && action.item.is(SundialButton)) {
        const cellDoor = game.ent(CellDoor);
        const bronzeDoor = game.ent(BronzeDoor);
        await runner.doOutput(
            'The button depresses with a slight click, and pops back.'
        );
        const sundial = game.ent(Sundial);
        const previousSetting = sundial.activeSetting();
        sundial.setActiveSetting(sundial.setting());
        if (cellDoor.isOpen()) {
            cellDoor.setIsOpen(false);
            await runner.doOutput('The cell door is now closed.');
        }
        bronzeDoor.setIsHidden(sundial.setting() !== 4);
        if (game.ent(Player).location().is(PrisonCell)) {
            cellDoor.setIsLocked(true);
            if (previousSetting === 4) {
                bronzeDoor.setIsHidden(false);
            }
        }
        return Action.complete();
    }
};
