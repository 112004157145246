import { Passage, Room, SmallCave } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { Boat, River } from '../../items';
import { River3 } from '../River3';

export class RockyShore extends Room {
    static spec(): EntitySpec<RockyShore> {
        return {
            ref: 'rocky-shore',
            constructor: RockyShore,
            initial: {
                contents: [River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return RockyShore.spec().ref;
    }

    name(): string {
        return 'Rocky Shore';
    }

    description(): string {
        return 'You are on the west shore of the river. An entrance to a cave is to the northwest. The shore is very rocky here.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: SpecialDirection.Launch,
                to: River3.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({ via: Direction.Northwest, to: SmallCave.spec().ref }),
        ];
    }

    hasWalls() {
        return false;
    }
}
