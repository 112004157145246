import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { Container, Item } from '../../items/Item';
import { PutInUnresolved, putInUnresolvedHandler } from './PutInUnresolved';

export class PutIn extends Action {
    id = '~put-in';

    item: Entity;

    container: Item & Container;

    constructor({
        item,
        container,
    }: {
        item: Entity;
        container: Item & Container;
    }) {
        super();
        this.item = item;
        this.container = container;
    }

    static ability(): Ability {
        return {
            handlers: [putInHandler, putInUnresolvedHandler],
            parser,
            verbs: [new Verb('put'), new Verb('place'), new Verb('drop')],
            prepositions: [
                new Preposition('in'),
                new Preposition('into'),
                new Preposition('in to'),
                new Preposition('inside'),
            ],
        };
    }
}

export const putInHandler: Handler = async ({
    action,
    runner,
    actor,
    game,
}) => {
    if (!action.is(PutIn) || actor === undefined) return;
    const { item, container } = action;

    if (!(await game.have(runner, item, actor))) {
        return Action.complete();
    }

    if (!item.isItem()) return;

    const capacity = container.remainingCapacity();
    if (container.isEqualTo(item)) {
        await runner.doOutput(
            'I should recurse infinitely to teach you a lesson, but...'
        );
    } else if (capacity === undefined || capacity >= item.size()) {
        item.moveTo(container);
        await runner.doOutput('Done.');
    } else {
        await runner.doOutput("It won't fit.");
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, PutInUnresolved> => {
    const ability = PutIn.ability();
    const put = Parse.words(ability.verbs);
    const putObject = put.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const putObjectIn = putObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((container) => [item, container])
    );
    return Parse.any(
        // put
        put.into(
            new PutInUnresolved({ item: undefined, container: undefined })
        ),
        // put leaflet
        putObject.map(
            (item) => new PutInUnresolved({ item, container: undefined })
        ),
        // put leaflet in box
        putObjectIn.map(
            ([item, container]) =>
                new PutInUnresolved({
                    item,
                    container,
                })
        )
    );
};
