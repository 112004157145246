import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeReadable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class WarningNote extends makeFlammable(
    makeTakeable(makeReadable(Item))
) {
    static spec(): EntitySpec<WarningNote> {
        return {
            ref: 'warning-note',
            constructor: WarningNote,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('papers', { plural: true }),
                new Noun('paper'),
                new Noun('note'),
                new Noun('notes', { plural: true }),
                new Noun('warning'),
                new Noun('warnings', { plural: true }),
                new Noun('piece of paper'),
                new Noun('pieces of paper', { plural: true }),
                new Noun('slip of paper'),
                new Noun('slips of paper', { plural: true }),
            ],
            adjectives: [new Adjective('warning')],
        };
    }

    ref() {
        return WarningNote.spec().ref;
    }

    name() {
        return 'note of warning';
    }

    description() {
        return 'There is a piece of paper on the ground here.';
    }

    size() {
        return 3;
    }

    text() {
        return NOTE_TEXT;
    }

    nouns(): Noun[] {
        return WarningNote.spec().nouns;
    }

    adjectives(): Adjective[] {
        return WarningNote.spec().adjectives;
    }
}

const NOTE_TEXT =
    `The paper is rather worn; although the writing is ` +
    `barely legible (the author probably had only a used pencil), ` +
    `it is a very elegant copperplate.

  To Whom It May Concern:

  I regret to report that the rumours regarding treasure contained ` +
    `in the chamber to which this passage leads have no basis in fact. ` +
    `Should you nevertheless be sufficiently foolhardy to enter, ` +
    `it will be quite impossible for you to exit.

      Sincerely yours,
        The Thief`;
