import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { EntitySpec } from '../../game';

interface StatueState extends ItemState {
    isHidden: boolean;
}

abstract class Base extends Item<StatueState> {}

export class Statue extends makeTreasure(makeTakeable(Base)) {
    static spec(): EntitySpec<Statue> {
        return {
            ref: 'statue',
            constructor: Statue,
            initial: {
                isHidden: true,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('statue'),
                new Noun('statues', { plural: true }),
                new Noun('sculpture'),
                new Noun('sculptures', { plural: true }),
            ],
            adjectives: [new Adjective('beautiful'), new Adjective('stone')],
        };
    }

    isHidden() {
        return this.state.isHidden;
    }

    setIsHidden(isHidden: boolean) {
        this.state.isHidden = isHidden;
    }

    ref() {
        return Statue.spec().ref;
    }

    name() {
        return 'statue';
    }

    description() {
        return 'There is a beautiful statue here.';
    }

    size() {
        return 8;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 13;
    }

    nouns(): Noun[] {
        return Statue.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Statue.spec().adjectives;
    }
}
