import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Yell extends makeDescribable(Action) {
    id = 'yell';

    description(game: Game): string {
        return 'yell';
    }

    static ability(): Ability {
        const verbs = [new Verb('yell'), new Verb('scream'), new Verb('shout')];
        return {
            handlers: [yellHandler],
            parser: () => Parse.words(verbs).into(new Yell()),
            verbs,
            prepositions: [],
        };
    }
}

export const yellHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Yell)) return;
    await runner.doOutput('Aaaarrrrrrrrgggggggggggggghhhhhhhhhhhhhh!');
    return Action.complete();
};
