import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { makeDescribable } from '../../game/Action';
import { listify, targetDescription } from '../../utils';
import { Take } from './Take';
import { UnresolvedAction } from '../UnresolvedAction';

export class TakeUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'take';

    items: Target;

    container: Target | undefined;

    constructor({
        items,
        container,
    }: {
        items: Target;
        container: Target | undefined;
    }) {
        super();
        this.items = items;
        this.container = container;
    }

    description(game: Game): string {
        return `take ${targetDescription(game, this.items)}`;
    }
}

export const takeUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(TakeUnresolved)) return;

    const { items, plural } = await game.resolve(runner, action.items, actor, {
        allowGroups: true,
        condition: (item) =>
            item.isItem() &&
            item.shouldTryToTake() &&
            !(actor?.isActor() && actor.hasItem(item)),
        partial: (items) =>
            new TakeUnresolved({ items, container: action.container }),
        ambiguous: (desc, opt, not) =>
            `Which ${desc} would you like${not} to take, ${opt}?`,
    });
    if (items.length === 0) return Action.complete({ withConsequence: false });

    let container;
    if (action.container) {
        const { item } = await game.resolve(runner, action.container, actor, {
            partial: (container) =>
                new TakeUnresolved({ items: action.items, container }),
            missing: () =>
                `From what do you want to take ${listify(
                    items.map((item) => item.the())
                )}?`,
            ambiguous: (desc, opt) =>
                `From which ${desc} would you like to take ${listify(
                    items.map((item) => item.the())
                )}, ${opt}?`,
            condition: (item) =>
                item.isItem() &&
                item.isContainer() &&
                !items.some((takeItem) => takeItem.isEqualTo(item)) &&
                (!item.isOpenable() || item.isOpen()),
        });
        if (item === undefined)
            return Action.complete({ withConsequence: false });
        container = item;

        if (!container.isItem() || !container.isContainer()) {
            await runner.doOutput("You can't take things from that.");
            return Action.complete();
        }

        if (container.isOpenable() && !container.isOpen()) {
            await runner.doOutput(`${container.The()} is closed.`);
            return Action.complete();
        }
    }

    for (const item of items) {
        if (plural) {
            await runner.doOutput(`${item.Name()}: `, { newLine: false });
        }
        if (await game.reach(runner, item, actor)) {
            await game.applyAction(
                runner,
                new Take({ item, container, silent: false }),
                actor
            );
        }
    }
    return Action.complete();
};
