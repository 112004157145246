import { Direction } from '../../../parse';
import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { NorthOfHouse } from '../NorthOfHouse';
import { UpATree } from '../UpATree';
import { Tree } from '../../items';
import { Forest2 } from '../Forest2';
import { Clearing } from '../Clearing';

export class Forest3 extends Room {
    static spec(): EntitySpec<Forest3> {
        return {
            ref: 'forest-3',
            constructor: Forest3,
            initial: {
                contents: [Tree.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Forest3.spec().ref;
    }

    name(): string {
        return 'Forest';
    }

    description(): string {
        return (
            'This is a dimly lit forest, with large trees all around. ' +
            'One particularly large tree with some low branches stands here.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: Clearing.spec().ref }),
            new Passage({ via: Direction.West, to: NorthOfHouse.spec().ref }),
            new Passage({ via: Direction.North, to: Forest2.spec().ref }),
            new Passage({ via: Direction.South, to: Clearing.spec().ref }),
            new Passage({ via: Direction.Up, to: UpATree.spec().ref }),
        ];
    }

    isPartOfForest() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
