import { LoadError } from '../../game/Runner';
import { SpecialAction } from '../SpecialAction';

export const SPECIAL_LOAD_ERROR = '*load-error';

export class SpecialLoadError extends SpecialAction {
    id = SPECIAL_LOAD_ERROR;

    error: LoadError;

    constructor({ error }: { error: LoadError }) {
        super();
        this.error = error;
    }
}
