import { Noun, Adjective } from '../../../parse';
import { makeContainer, makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Grail extends makeContainer(makeTreasure(makeTakeable(Item))) {
    static spec(): EntitySpec<Grail> {
        return {
            ref: 'grail',
            constructor: Grail,
            initial: {
                hasBeenTaken: false,
                contents: [],
            },
            nouns: [new Noun('grail'), new Noun('grails', { plural: true })],
            adjectives: [
                new Adjective('holy'),
                new Adjective('valuable'),
                new Adjective('extremely valuable'),
                new Adjective('original'),
            ],
        };
    }

    totalCapacity(): number {
        return 5;
    }

    ref() {
        return Grail.spec().ref;
    }

    name() {
        return 'grail';
    }

    description() {
        return 'There is an extremely valuable (perhaps original) grail here.';
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 2;
    }

    scoreInCase(): number {
        return 5;
    }

    nouns(): Noun[] {
        return Grail.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Grail.spec().adjectives;
    }
}
