import { Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class TBar extends Item {
    static spec(): EntitySpec<TBar> {
        return {
            ref: 't-bar',
            constructor: TBar,
            initial: {},
            nouns: [
                new Noun('t-bar'),
                new Noun('t-bars', { plural: true }),
                new Noun('t bar'),
                new Noun('t bars', { plural: true }),
                new Noun('tbar'),
                new Noun('tbars', { plural: true }),
                new Noun('bar'),
                new Noun('bars', { plural: true }),
            ],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return TBar.spec().ref;
    }

    name(): string {
        return 't-bar';
    }

    description(): string {
        return '';
    }

    nouns() {
        return TBar.spec().nouns;
    }

    adjectives() {
        return TBar.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
