import { Verb, Parse, Parser, Value } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { TurnOnUnresolved, turnOnUnresolvedHandler } from './TurnOnUnresolved';

export class TurnOn extends Action {
    id = '~turn-on';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [turnOnHandler, turnOnUnresolvedHandler],
            parser,
            verbs: [
                new Verb('turn on'),
                new Verb('turn'),
                new Verb('power'),
                new Verb('power on'),
                // TODO another action for light
            ],
            prepositions: [],
        };
    }
}

export const turnOnHandler: Handler = async ({ action, runner }) => {
    if (!action.is(TurnOn)) return undefined;
    await runner.doOutput("You can't turn that on.");
    return Action.complete();
};

const parser = (game: Game): Parser<Value, TurnOnUnresolved> => {
    const turnOn = Parse.any(
        Parse.phrase('turn on'),
        Parse.phrase('power on'),
        Parse.word('power')
    );
    const target = Parse.target(game.lexicon);
    return Parse.any(
        turnOn.map(() => new TurnOnUnresolved({ item: undefined })),
        turnOn
            .beforeX(Parse.option(Parse.whitespace().beforeX(target)))
            .map((item) => new TurnOnUnresolved({ item })),
        Parse.words([new Verb('power'), new Verb('turn')])
            .beforeX(
                Parse.option(Parse.whitespace().beforeX(target)).before(
                    Parse.whitespace().then(Parse.word('on'))
                )
            )
            .map((item) => new TurnOnUnresolved({ item }))
    );
};
