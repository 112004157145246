import { MirrorRoom2, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { SpecialEnter } from '../../abilities';
import { Action, EntitySpec, Handler } from '../../game';
import { EntranceToHades } from '../EntranceToHades';
import { NarrowCrawlway } from '../NarrowCrawlway';
import { Candles } from '../../items';

export class Cave2 extends Room {
    static spec(): EntitySpec<Cave2> {
        return {
            ref: 'cave-2',
            constructor: Cave2,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [enterCave2],
        };
    }

    ref() {
        return Cave2.spec().ref;
    }

    name(): string {
        return 'Cave';
    }

    description(): string {
        return 'This is a tiny cave with entrances west and north, and a dark, forbidding staircase leading down.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: NarrowCrawlway.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: MirrorRoom2.spec().ref,
            }),
            new Passage({
                via: Direction.Down,
                to: EntranceToHades.spec().ref,
            }),
        ];
    }
}

const enterCave2: Handler = async ({
    action,
    runner,
    game,
    actor,
    extensions,
}) => {
    if (action.is(SpecialEnter) && action.room.is(Cave2)) {
        const candles = game.ent(Candles);
        if (
            actor?.hasItem(candles) &&
            candles.isAflame() &&
            actor.testLuck(50, 80)
        ) {
            await extensions.deferHandling();
            await runner.doOutput(
                'The cave is very windy at the moment and your candles have blown out.'
            );
            candles.state.isAflame = false;
            if (!actor?.location()?.isLit()) {
                await runner.doOutput('It is now completely dark.');
            }
            return Action.complete({ withConsequence: false });
        }
    }
};
