import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Temple extends makeDescribable(Action) {
    id = 'temple';

    description(game: Game): string {
        return 'mention temple';
    }

    static ability(): Ability {
        const verbs = [new Verb('temple'), new Verb('say temple')];
        return {
            handlers: [templeHandler],
            parser: () => Parse.words(verbs).into(new Temple()),
            verbs,
            prepositions: [],
        };
    }
}

export const templeHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Temple)) return;
    await runner.doOutput('Nothing happens.');
    return Action.complete();
};
