import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Light, Mung, SpecialJigsUp, Take } from '../../abilities';
import { LandOfTheLivingDead } from '../../rooms';
import { PoledCretinHead } from '../PoledCretinHead';

export class DeadBodies extends makeTakeable(Item) {
    static spec(): EntitySpec<DeadBodies> {
        return {
            ref: 'dead-bodies',
            constructor: DeadBodies,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('bodies', { collective: true }),
                new Noun('body'),
                new Noun('remains', { collective: true }),
                new Noun('pile of bodies'),
                new Noun('corpses', { collective: true }),
                new Noun('corpse'),
            ],
            adjectives: [new Adjective('dead')],
            handlers: [takeBodies, mungBodies],
        };
    }

    ref() {
        return DeadBodies.spec().ref;
    }

    name() {
        return 'pile of bodies';
    }

    description() {
        return '';
    }

    shouldBeDescribed() {
        return false;
    }

    nouns(): Noun[] {
        return DeadBodies.spec().nouns;
    }

    adjectives(): Adjective[] {
        return DeadBodies.spec().adjectives;
    }
}

const takeBodies: Handler = async ({ action, runner, game }) => {
    if (action.is(Take) && action.item.is(DeadBodies)) {
        await runner.doOutput('A force keeps you from taking the bodies.');
        return Action.complete();
    }
};

const mungBodies: Handler = async ({ action, runner, game }) => {
    if ((action.is(Mung) || action.is(Light)) && action.item.is(DeadBodies)) {
        game.ent(PoledCretinHead).moveTo(game.ent(LandOfTheLivingDead));
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    'The voice of the guardian of the dungeon booms out from the ' +
                    "darkness 'Your disrespect costs you your life!' and places " +
                    'your head on a pole.',
            })
        );
        return Action.complete();
    }
};
