import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Count, CountMany } from './Count';
import { UnresolvedAction } from '../UnresolvedAction';

export class CountUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'count';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'count something';
        }
        return `count ${targetDescription(game, this.item)}`;
    }
}

export const countUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(CountUnresolved)) return;

    if (action.item) {
        const { item, items, plural } = await game.resolve(
            runner,
            action.item,
            actor,
            {
                partial: (item) => new CountUnresolved({ item }),
                missing: () => 'Count what?',
                allowGroups: true,
                ambiguous: (desc, opt) =>
                    `Which ${desc} would you like to count, ${opt}?`,
                condition: () => true,
            }
        );

        if (item === undefined)
            return Action.complete({ withConsequence: false });

        if (plural) {
            return await game.applyAction(
                runner,
                new CountMany({ items, target: action.item })
            );
        }

        return await game.applyAction(runner, new Count({ item }), actor);
    }

    return await game.applyAction(
        runner,
        new Count({ item: undefined }),
        actor
    );
};
