import { Action, Handler } from '../../game';
import {
    SpecialJigsUp,
    SpecialPrepareToFight,
    SpecialRevive,
    SpecialTimerTick,
} from '../../abilities';
import { Actor, Cyclops, Player, Thief, Troll } from '../../actors';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';
import { Item, Weapon } from '../../items/Item';
import { FightResult } from './FightRemarks';
import { Stiletto } from '../../items';

const handler: Handler = async ({ action, game, runner }) => {
    if (!action.is(SpecialTimerTick)) return;

    await fight(game, runner);

    return Action.incomplete();
};

export const Melee = {
    handler: () => handler,
};

const MAX_STRENGTH = 7;
const MIN_STRENGTH = 1;
// const CURE_WAIT = 30;
const WEAPON_BONUS = 1;

type FightResultTableOptions =
    | FightResult.Missed
    | FightResult.Stun
    | FightResult.Unconscious
    | FightResult.Killed
    | FightResult.LightWound
    | FightResult.SeriousWound;

function makeResultsTable(
    resultWeights: { [result in FightResultTableOptions]?: number }
) {
    const table: FightResult[] = [];
    Object.entries(resultWeights).forEach(([result, weight]) => {
        for (let i = 0; i < (weight || 0); i++) {
            table.push(result as FightResult);
        }
    });
    return table;
}

const NO_CONTEST = makeResultsTable({
    [FightResult.Missed]: 4,
    [FightResult.Stun]: 2,
    [FightResult.Unconscious]: 2,
    [FightResult.Killed]: 5,
});

const CHEATER = NO_CONTEST.slice(1);
const MASTER_CHEATER = NO_CONTEST.slice(2);

const HEADSTRONG = makeResultsTable({
    [FightResult.Missed]: 5,
    [FightResult.Stun]: 2,
    [FightResult.LightWound]: 2,
    [FightResult.Unconscious]: 1,
});

const EASY = makeResultsTable({
    [FightResult.Missed]: 3,
    [FightResult.Stun]: 2,
    [FightResult.LightWound]: 3,
    [FightResult.Unconscious]: 1,
    [FightResult.Killed]: 3,
});

const PEASY = EASY.slice(1);
const LEMON_SQUEEZY = EASY.slice(2);

const FAIR_FIGHT = makeResultsTable({
    [FightResult.Missed]: 5,
    [FightResult.Stun]: 2,
    [FightResult.LightWound]: 2,
    [FightResult.SeriousWound]: 2,
});

const KINDA_FAIR = FAIR_FIGHT.slice(1);

const HEAVY_ATTACK = makeResultsTable({
    [FightResult.Missed]: 3,
    [FightResult.Stun]: 2,
    [FightResult.LightWound]: 3,
    [FightResult.SeriousWound]: 3,
});

const PRECISE_ATTACK = HEAVY_ATTACK.slice(1);

const POWERHOUSE = makeResultsTable({
    [FightResult.Missed]: 1,
    [FightResult.Stun]: 2,
    [FightResult.LightWound]: 4,
    [FightResult.SeriousWound]: 3,
});

const PRECISE_POWERHOUSE = POWERHOUSE.slice(1);

export function findWeapon(actor: Actor): (Item & Weapon) | undefined {
    const weapon = actor
        .contents()
        .find((item) => item.isItem() && item.isWeapon());
    // TODO this is hacky -- the thief should never use any weapon other than
    //      his own stiletto... because the fight remarks wouldn't work
    if (actor.is(Thief)) {
        const stiletto = actor.game.ent(Stiletto);
        return actor.hasItem(stiletto) ? stiletto : undefined;
    }
    return weapon ? (weapon as Item & Weapon) : undefined;
}

async function revive(game: Game, villain: Actor, perform = true) {
    villain.state.isConscious = true;
    if (perform) {
        // await game.applyAction() become conscious
    }
}

async function endFight(game: Game, villain: Actor) {
    const player = game.ent(Player);
    villain.setIsEngrossed(false);
    player.state.isStunned = false;
    villain.state.isStunned = false;
    villain.state.isFighting = false;
    await revive(game, villain);
}

async function performVillainFight(game: Game, runner: Runner, villain: Actor) {
    const player = game.ent(Player);
    villain.state.isFighting = true;
    await performBlow(game, runner, villain, player, undefined, undefined);
    if (!player.isConscious() && player.isAlive()) {
        await performVillainFight(game, runner, villain);
    }
}

async function performFight(game: Game, runner: Runner, opponents: Actor[]) {
    // TODO reset cure clock
    for (const opponent of opponents) {
        if (opponent.isReadyToFight()) {
            await performVillainFight(game, runner, opponent);
        } else {
            await game.applyAction(
                runner,
                new SpecialPrepareToFight(),
                opponent
            );
        }
    }
}

export async function fight(game: Game, runner: Runner) {
    const player = game.ent(Player);
    if (!player.isAlive()) return; // Wait, might need to end fight still????

    const opponents = [];
    const villains: Actor[] = [
        game.ent(Troll),
        game.ent(Cyclops),
        game.ent(Thief),
    ]; // TODO possibly make this computed
    for (const villain of villains) {
        const room = game.locateEntity(villain);
        if (!room || !game.locateEntity(player)?.isEqualTo(room)) {
            await endFight(game, villain);
        } else if (villain.isEngrossed()) {
            villain.setIsEngrossed(false);
        } else if (!villain.isConscious()) {
            const villainReviveChance = villain.reviveChance();
            if (
                villainReviveChance &&
                player.testLuck(
                    villainReviveChance,
                    (villainReviveChance + 100) / 2
                )
            ) {
                villain.state.isConscious = true;
                villain.state.reviveChance = 0;
                await game.applyAction(runner, new SpecialRevive(), villain);
            } else {
                villain.state.reviveChance = (villainReviveChance || 0) + 10;
            }
        } else if (villain.isFighting()) {
            opponents.push(villain);
        } else if (villain.shouldAttackFirst()) {
            villain.state.isFighting = true;
            opponents.push(villain);
        }
    }
    await performFight(game, runner, opponents);
}

export function villainWinningFight(game: Game, villain: Actor) {
    const player = game.ent(Player);
    const playerStrength = getFightStrength(game, player, undefined);
    if (playerStrength > 3) {
        return player.testLuck(90, 100);
    }
    if (playerStrength > 0) {
        return player.testLuck(75, 85);
    }
    if (playerStrength === 0) {
        return player.testLuck(50, 30);
    }
    if (villain.strength() > 1) {
        return player.testLuck(25);
    }
    return player.testLuck(10, 0);
}

export function getFightStrength(
    game: Game,
    actor: Actor,
    opponentWeapon: Item | undefined
) {
    let strength = actor.strength() - 3;
    if (actor.isEqualTo(game.ent(Player))) {
        strength += Math.floor(
            0.5 +
                (MAX_STRENGTH - MIN_STRENGTH) *
                    (game.state.score / game.totalScore())
        );
    }
    if (actor.isEngrossed()) {
        strength = Math.min(strength, 2);
    }
    const actorBestWeapon = actor.bestWeapon();
    if (
        opponentWeapon &&
        actorBestWeapon &&
        opponentWeapon.isEqualTo(actorBestWeapon)
    ) {
        strength -= WEAPON_BONUS;
    }
    return Math.min(MAX_STRENGTH, Math.max(MIN_STRENGTH, strength));
}

async function prepareAttack(
    game: Game,
    runner: Runner,
    attacker: Actor,
    defender: Actor
) {
    if (attacker.isStunned()) {
        const player = game.ent(Player);
        if (attacker.isEqualTo(player)) {
            await runner.doOutput(
                'You are still recovering from that last blow, so your attack is ineffective.'
            );
        } else {
            await runner.doOutput(`${attacker.The()} slowly regains his feet.`);
        }
        attacker.state.isStunned = false;
        return false;
    }

    if (attacker.is(Player)) {
        if (defender.isEqualTo(attacker)) {
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message:
                        'Well, you really did it that time. Is suicide painless?',
                })
            );
            return false;
        }
        if (!defender.isVillain()) {
            await runner.doOutput(`Attacking ${defender.the()} is pointless.`);
            return false;
        }
    }

    return true;
}

function rawBlowResult(
    game: Game,
    attackStat: number,
    defendStat: number
): FightResult {
    if (defendStat <= 1) {
        const tables = [NO_CONTEST, CHEATER, MASTER_CHEATER];
        const attackIndex = Math.max(0, Math.min(2, attackStat - 1));
        return game.choiceOf(tables[attackIndex]);
    }
    if (defendStat === 2) {
        const tables = [HEADSTRONG, EASY, PEASY, LEMON_SQUEEZY];
        const attackIndex = Math.max(0, Math.min(3, attackStat - 2));
        return game.choiceOf(tables[attackIndex]);
    }
    // if (defendStat >= 3)
    const tables = [
        FAIR_FIGHT,
        KINDA_FAIR,
        HEAVY_ATTACK,
        PRECISE_ATTACK,
        POWERHOUSE,
        PRECISE_POWERHOUSE,
    ];
    const attackIndex = Math.max(0, Math.min(5, attackStat - defendStat));
    return game.choiceOf(tables[attackIndex]);
}

function getBlowResult(
    game: Game,
    runner: Runner,
    attacker: Actor,
    defender: Actor,
    attackStat: number,
    defendStat: number,
    attackingWeapon: Item | undefined,
    defendingWeapon: Item | undefined
): FightResult {
    const player = game.ent(Player);
    const result = rawBlowResult(game, attackStat, defendStat);
    if (!defender.isConscious()) {
        if (result === FightResult.Stun) {
            if (defender.is(Player)) {
                return FightResult.Hesitated;
            }
        }
        return FightResult.SittingDuck;
    }
    // TODO why does the room and troll have axe after it's supposedly removed??
    // if (attacker.isPlayer) result = FightResult.LostWeapon;// TODO
    if (
        result === FightResult.Stun &&
        defendingWeapon &&
        player.testLuck(25, attacker.is(Player) ? 10 : 50)
    ) {
        return FightResult.LostWeapon;
    }
    if (result === FightResult.LostWeapon && !defendingWeapon) {
        return getBlowResult(
            game,
            runner,
            attacker,
            defender,
            attackStat,
            defendStat,
            attackingWeapon,
            defendingWeapon
        );
    }
    return result;
}

// returns the new strength of the attacked actor
async function handleBlowResult(
    game: Game,
    runner: Runner,
    attacker: Actor,
    defender: Actor,
    attackingWeapon: Item | undefined,
    defendingWeapon: Item | undefined,
    result: FightResult | undefined
) {
    const player = game.ent(Player);
    if (result === FightResult.Missed || result === FightResult.Hesitated) {
        // Do nothing
    } else if (result === FightResult.Unconscious) {
        defender.state.isConscious = false;
        // game.applyAction(new SpecialFallUnconscious({}), villain);
    } else if (
        result === FightResult.Killed ||
        result === FightResult.SittingDuck
    ) {
        defender.state.strength = 0;
        defender.state.isAlive = false;
        if (defender.isEqualTo(player)) {
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message:
                        "It appears that that last blow was too much for you. I'm afraid you are dead.",
                }),
                defender
            );
        } else {
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message:
                        `Almost as soon as ${defender.the()} breathes his last breath, a cloud of sinister ` +
                        `black fog envelops him, and when the fog lifts, the carcass has disappeared.`,
                }),
                defender
            );
            defender.moveTo(undefined);
        }
    } else if (result === FightResult.LightWound) {
        defender.state.strength -= 1;
    } else if (result === FightResult.SeriousWound) {
        defender.state.strength -= 2;
    } else if (result === FightResult.Stun) {
        defender.state.isStunned = true;
    } else if (result === FightResult.LostWeapon && defendingWeapon) {
        const room = game.locateEntity(defender);
        if (room) {
            defendingWeapon.moveTo(room);
        }
        if (defender.isEqualTo(player)) {
            const newWeapon = findWeapon(defender);
            if (newWeapon) {
                await runner.doOutput(
                    `Fortunately, you still have ${newWeapon.an()}.`
                );
            }
        }
    }
}

async function reportBlowResult(
    game: Game,
    runner: Runner,
    attacker: Actor,
    defender: Actor,
    attackingWeapon: (Item & Weapon) | undefined,
    defendingWeapon: (Item & Weapon) | undefined,
    result: FightResult
) {
    const options = { attacker, defender, attackingWeapon, defendingWeapon };
    const attackerRemarks = attacker.fightRemarks();
    if (attackerRemarks) {
        await runner.doOutput(game.choiceOf(attackerRemarks(options)[result]));
    } else if (attackingWeapon === undefined) {
        throw new Error('Expected weapon or attacker to have fight remarks.');
    } else if (defender.is(Player)) {
        await runner.doOutput(
            game.choiceOf(attackingWeapon.fightRemarks()(options)[result])
        );
    } else {
        const remarks = attackingWeapon.fightRemarks()(options);
        await runner.doOutput(game.choiceOf(remarks[result]));
    }
}

export async function performBlow(
    game: Game,
    runner: Runner,
    attacker: Actor,
    defender: Actor,
    attackingWeapon: (Item & Weapon) | undefined,
    defendingWeapon: (Item & Weapon) | undefined
) {
    if (!(await prepareAttack(game, runner, attacker, defender))) return;

    attacker.state.isFighting = true;
    defender.state.isFighting = true;

    attackingWeapon = attackingWeapon || findWeapon(attacker);
    defendingWeapon = defendingWeapon || findWeapon(defender);

    const attackStat = getFightStrength(game, attacker, defendingWeapon);
    const defendStat = getFightStrength(game, defender, attackingWeapon);

    const result = await getBlowResult(
        game,
        runner,
        attacker,
        defender,
        attackStat,
        defendStat,
        attackingWeapon,
        defendingWeapon
    );
    await reportBlowResult(
        game,
        runner,
        attacker,
        defender,
        attackingWeapon,
        defendingWeapon,
        result
    );
    await handleBlowResult(
        game,
        runner,
        attacker,
        defender,
        attackingWeapon,
        defendingWeapon,
        result
    );

    return result;
}
