import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { KickUnresolved, kickUnresolvedHandler } from './KickUnresolved';
import { HO_HUM } from '../../constants';

export class Kick extends Action {
    id = '~kick';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [kickHandler, kickUnresolvedHandler],
            parser,
            verbs: [
                new Verb('kick'),
                new Verb('dropkick'),
                new Verb('drop kick'),
            ],
            prepositions: [],
        };
    }
}

export const kickHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Kick)) return;
    const { item } = action;
    await runner.doOutput(`Kicking ${item.the()} ${game.choiceOf(HO_HUM)}`);
    return Action.complete();
};

const parser = (game: Game): Parser<Value, KickUnresolved> => {
    const kick = Parse.words(Kick.ability().verbs);
    const kickObject = kick.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // kick
        kick.into(new KickUnresolved({ item: undefined })),
        // kick the bucket
        kickObject.map((item) => new KickUnresolved({ item }))
    );
};
