import { Passage, Room } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { SpecialEnter } from '../../abilities';

export class TreasuryOfZork extends Room {
    static spec(): EntitySpec<TreasuryOfZork> {
        return {
            ref: 'treasury-of-zork',
            constructor: TreasuryOfZork,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [enterTreasury],
        };
    }

    scoreOnEntry(): number {
        return 35;
    }

    ref() {
        return TreasuryOfZork.spec().ref;
    }

    name(): string {
        return 'Treasury of Zork';
    }

    description(): string {
        return TREASURY_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [];
    }
}

const TREASURY_DESCRIPTION =
    'This is a room of large size, richly appointed and decorated in a style that ' +
    'bespeaks exquisite taste. To judge from its contents, it is the ' +
    'ultimate storehouse of the treasures of Zork.\n' +
    'There are chests here containing precious jewels, mountains of zorkmids, ' +
    'rare paintings, ancient statuary, and beguiling curios. In one corner of the ' +
    "room is a bookcase boasting such volumes as 'The History of the Great " +
    "Underground Empire,' 'The Lives of the Twelve Flatheads,' 'The Wisdom of " +
    "the Implementors,' and other informative and inspiring works.\n" +
    'On one wall is a completely annotated map of the Great Underground Empire, ' +
    'showing points of interest, various troves of treasure, and indicating the ' +
    'locations of several superior scenic views.\n' +
    'On a desk at the far end of the room may be found stock certificates ' +
    'representing a controlling interest in FrobozzCo International, the ' +
    'multinational conglomerate and parent company of the Frobozz Magic Boat Co., etc.';

const enterTreasury: Handler = async ({ action, runner, game, extensions }) => {
    if (action.is(SpecialEnter) && action.room.is(TreasuryOfZork)) {
        await extensions.deferHandling();
        await game.quit(runner, 'won totally');
        return Action.complete();
    }
};
