/**
 * Helper value type for nodes that are named (for later reference
 * using a `CollectParser`).
 */
export class Named<T> {
    name: string;

    value: T;

    constructor(name: string, value: T) {
        this.name = name;
        this.value = value;
    }
}
