import { Actor, ActorState } from '../index';
import { Adjective, Noun } from '../../../parse';
import { Forest3, Room } from '../../rooms';
import { Action, Handler } from '../../game';
import {
    Bug,
    Close,
    Deflate,
    Diagnose,
    Drink,
    Eat,
    Enter,
    Exit,
    Feature,
    Give,
    Go,
    Hello,
    Inflate,
    Inventory,
    Kill,
    Light,
    Load,
    Look,
    Mung,
    No,
    Open,
    Pray,
    Quit,
    Restart,
    Rub,
    Save,
    Score,
    SpecialEnter,
    SpecialJigsUp,
    SpecialTimerTick,
    Take,
    Tie,
    Turn,
    TurnOn,
    Untie,
    Yes,
} from '../../abilities';
import { CURE_WAIT } from '../../constants';
import { Lamp } from '../../items';
import { Tell } from '../../abilities/Tell';
import { Altar } from '../../rooms/Altar';
import { SpecialAction } from '../../abilities/SpecialAction';
import { SpecialDescribeRoom } from '../../abilities/SpecialDescribeRoom';
import { UnresolvedAction } from '../../abilities/UnresolvedAction';
import { KillUnresolved } from '../../abilities/Kill/KillUnresolved';
import { PokeUnresolved } from '../../abilities/Poke/PokeUnresolved';
import { DropUnresolved } from '../../abilities/Drop/DropUnresolved';
import { ThrowUnresolved } from '../../abilities/Throw/ThrowUnresolved';
import { PutInUnresolved } from '../../abilities/PutIn/PutInUnresolved';

interface PlayerState extends ActorState {
    healProgress: number;
}

const PLAYER_MAX_STRENGTH = 4;

export class Player extends Actor<PlayerState> {
    static spec() {
        return {
            ref: 'cretin',
            constructor: Player,
            initial: {
                inventory: [],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: PLAYER_MAX_STRENGTH,
                healProgress: 0,
                isEngrossed: false,
            },
            nouns: [new Noun('cretin'), new Noun('me')],
            adjectives: [],
            handlers: [
                healPlayer,
                takeMe,
                deadPlayerDoesNothing,
                giveMeTheThing,
                killMe,
            ],
        };
    }

    ref() {
        return Player.spec().ref;
    }

    name(): string {
        return 'cretin';
    }

    description(): string {
        return 'There is a cretin here.';
    }

    location(): Room {
        const location = super.location();
        if (location === undefined) {
            throw new Error('Expected player to have location.');
        }
        return location;
    }

    nouns(): Noun[] {
        return Player.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Player.spec().adjectives;
    }

    speed(): number {
        throw new Error('Unimplemented');
    }

    isReadyToFight(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return !this.isAlive();
    }

    healProgress() {
        return this.state.healProgress;
    }

    setHealProgress(healProgress: number) {
        this.state.healProgress = healProgress;
    }
}

// TODO maybe this should apply to all actors...
const healPlayer: Handler = async ({ action, game }) => {
    if (action.is(SpecialTimerTick)) {
        const player = game.ent(Player);
        if (player.strength() < PLAYER_MAX_STRENGTH) {
            player.setHealProgress(player.healProgress() + 1);
            if (player.healProgress() > CURE_WAIT) {
                player.setStrength(player.strength() + 1);
                player.setHealProgress(0);
            }
        }
        return Action.incomplete();
    }
};

const deadPlayerDoesNothing: Handler = async ({
    action,
    game,
    runner,
    actor,
}) => {
    if (actor?.is(Player) && !actor?.isAlive()) {
        // TODO quit and restart should be allowed
        // TODO potentially other "Kill-like" actions?
        if (
            action.is(KillUnresolved) ||
            action.is(PokeUnresolved) ||
            action.is(Mung)
        ) {
            await runner.doOutput(
                'All such attacks are vain in your condition.'
            );
        } else if (
            action.is(Open) ||
            action.is(Close) ||
            action.is(Eat) ||
            action.is(Drink) ||
            action.is(Inflate) ||
            action.is(Deflate) ||
            action.is(Turn) ||
            action.is(Light) ||
            action.is(Tie) ||
            action.is(Untie) ||
            action.is(Rub)
        ) {
            await runner.doOutput(
                'Even such a simple action is beyond your capabilities.'
            );
        } else if (action.is(TurnOn) && action.item.is(Lamp)) {
            await runner.doOutput('You need no light to guide you.');
        } else if (action.is(Score)) {
            await runner.doOutput(
                'How can you think about your score in your condition?'
            );
        } else if (action.is(Tell) || action.is(Hello)) {
            await runner.doOutput('No one can hear you.');
        } else if (action.is(Take)) {
            await runner.doOutput(
                `Your hand passes through ${action.item.the()}.`
            );
        } else if (
            action.is(DropUnresolved) ||
            action.is(ThrowUnresolved) ||
            action.is(PutInUnresolved) ||
            action.is(Inventory)
        ) {
            await runner.doOutput('You have no possessions.');
        } else if (action.is(Pray)) {
            if (actor.location().is(Altar)) {
                await runner.doOutput(
                    'From the distance the sound of a lone trumpet is heard. ' +
                        'The room becomes very bright and you feel disembodied. ' +
                        'In a moment, the brightness fades and you find yourself rising ' +
                        'as if from a long sleep, deep in the woods. In the distance ' +
                        'you can faintly hear a song bird and the sounds of the forest.'
                );
                actor.setIsAlive(true);
                await game.applyAction(
                    runner,
                    new SpecialEnter({ room: game.ent(Forest3) })
                );
            } else {
                await runner.doOutput('Your prayers are not heard.');
            }
        } else if (action.is(SpecialDescribeRoom)) {
            const room = actor.location();
            const anyObjects =
                room.contents().filter((item) => item.isItem()).length > 0;
            const objects = anyObjects ? ' and objects appear indistinct' : '';
            await runner.doOutput(
                `The room looks strange and unearthly${objects}.`
            );
            if (!room.isLit()) {
                await runner.doOutput(
                    'Although there is no light, the room seems dimly illuminated.'
                );
            }
            return Action.incomplete();
        } else if (
            !(
                action.is(Look) ||
                action.is(Bug) ||
                action.is(Feature) ||
                action.is(Diagnose) ||
                action.is(Go) ||
                action.is(Exit) ||
                action.is(Enter) ||
                action.is(Save) ||
                action.is(Restart) ||
                action.is(Quit) ||
                action.is(Yes) ||
                action.is(No) ||
                action.is(Load) ||
                action instanceof SpecialAction ||
                action instanceof UnresolvedAction
            )
        ) {
            await runner.doOutput("You can't do even that.");
        } else {
            return Action.incomplete();
        }
        return Action.complete();
    }
};

export const takeMe: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Player)) {
        await runner.doOutput('How romantic!');
        return Action.complete();
    }
};

export const giveMeTheThing: Handler = async ({ action, runner, game }) => {
    if (action.is(Give) && action.item && action.recipient.is(Player)) {
        return game.applyAction(
            runner,
            new Take({ item: action.item, container: undefined })
        );
    }
};

export const killMe: Handler = async ({ action, runner, game }) => {
    if (action.is(Kill) && action.enemy.is(Player)) {
        const message = action.weapon
            ? 'If you insist....'
            : "If you insist.... Poof, you're dead!";
        await game.applyAction(runner, new SpecialJigsUp({ message }));
        return Action.complete();
    }
};
