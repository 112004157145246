import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { getFightStrength } from '../../handlers';
import { Player } from '../../actors';
import { CURE_WAIT } from '../../constants';

export class Diagnose extends makeDescribable(Action) {
    id = 'diagnose';

    description(game: Game): string {
        return 'check your health';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('diagnose'),
            new Verb('check health'),
            new Verb('health'),
        ];
        return {
            handlers: [diagnoseHandler],
            parser: () => Parse.words(verbs).into(new Diagnose()),
            verbs,
            prepositions: [],
        };
    }
}

export const diagnoseHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(Diagnose) || !actor?.is(Player)) return;

    if (!actor.isAlive()) {
        await runner.doOutput('You are dead.');
        return Action.complete();
    }

    const strength = actor.strength();
    const fightStrength = getFightStrength(game, actor, undefined);

    if (strength === 4) {
        await runner.doOutput('You are in perfect health.');
    } else {
        let healthStatus;
        if (strength === 3) {
            healthStatus = 'You have a light wound,';
        } else if (strength === 2) {
            healthStatus = 'You have a serious wound,';
        } else if (strength === 1) {
            healthStatus = 'You have several wounds,';
        } else {
            healthStatus = 'You have serious wounds,';
        }

        const movesToHeal = CURE_WAIT * (4 - strength) - actor.healProgress();
        await runner.doOutput(
            `${healthStatus} which will be cured after ${movesToHeal} moves.`
        );
    }

    let diagnosis;
    if (fightStrength === 0) {
        diagnosis = "You are at death's door.";
    } else if (fightStrength === 1) {
        diagnosis = 'You can be killed by one more light wound.';
    } else if (fightStrength === 2) {
        diagnosis = 'You can be killed by a serious wound.';
    } else if (fightStrength === 3) {
        diagnosis = 'You can survive one serious wound.';
    } else {
        diagnosis = 'You are strong enough to take several wounds.';
    }
    await runner.doOutput(diagnosis);

    if (game.state.deathCount) {
        const timesKilled = game.state.deathCount === 1 ? 'once' : 'twice';
        await runner.doOutput(`You have been killed ${timesKilled}.`);
    }

    return Action.complete();
};
