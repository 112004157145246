import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Light } from './Light';
import { UnresolvedAction } from '../UnresolvedAction';

export class LightUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'light';

    item: Target | undefined;

    tool: Target | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Target | undefined;
        tool: Target | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'light something';
        }
        if (this.tool === undefined) {
            return `light ${targetDescription(game, this.item)}`;
        }
        return `light ${targetDescription(
            game,
            this.item
        )} with ${targetDescription(game, this.tool)}`;
    }
}

export const lightUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(LightUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new LightUnresolved({ item, tool: undefined }),
        missing: () => 'Light what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to light, ${opt}?`,
        condition: (item) => item.isItem() && item.shouldTryToOpen(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    let tool;
    if (action.tool) {
        const { item: resolvedTool } = await game.resolve(
            runner,
            action.tool,
            actor,
            {
                partial: (tool) =>
                    new LightUnresolved({ item: action.item, tool }),
                ambiguous: (desc, opt) =>
                    `Which ${desc} would you like to use to light ${item.the()}, ${opt}?`,
                condition: (item) =>
                    item.isItem() && item.isFlammable() && item.isAflame(),
            }
        );
        tool = resolvedTool;
    }

    if (tool && !(await game.have(runner, tool, actor)))
        return Action.complete();

    return await game.applyAction(runner, new Light({ item, tool }));
};
