import { Room } from '../Room';
import { Game } from '../../game/game';
import { Direction, SpecialDirection } from '../../../parse';
import { InsideMirror } from './InsideMirror';
import {
    Mirror1,
    Mirror2,
    SidePanel,
    MirrorPanel1,
    MirrorPanel2,
} from '../../items';
import { Hallway1 } from '../Hallway1';
import { Hallway2 } from '../Hallway2';
import { Hallway3 } from '../Hallway3';
import { GuardianHallway } from '../GuardianHallway';
import { Hallway4 } from '../Hallway4';
import { DungeonEntrance } from '../DungeonEntrance';
import { SmallRoom } from '../SmallRoom';
import { EastNarrowRoom1 } from '../EastNarrowRoom1';
import { EastNarrowRoom2 } from '../EastNarrowRoom2';
import { EastNarrowRoom3 } from '../EastNarrowRoom3';
import { EastGuardianNarrowRoom } from '../EastGuardianNarrowRoom';
import { EastNarrowRoom4 } from '../EastNarrowRoom4';
import { WestNarrowRoom1 } from '../WestNarrowRoom1';
import { WestNarrowRoom2 } from '../WestNarrowRoom2';
import { WestNarrowRoom3 } from '../WestNarrowRoom3';
import { WestGuardianNarrowRoom } from '../WestGuardianNarrowRoom';
import { WestNarrowRoom4 } from '../WestNarrowRoom4';
import { Passage } from '../Passage';

export function getNarrowRoomDescription(
    game: Game,
    room: Room,
    direction: Direction.East | Direction.West
) {
    let description = 'You are in a narrow room, whose ';
    description += direction === Direction.West ? 'east' : 'west';
    description += ` wall is a large `;
    const structure = game.ent(InsideMirror);
    const orientation = structure.orientation();
    const cc = rotateCounterclockwise;
    const visibleMirror =
        cc(cc(orientation)) === direction
            ? game.ent(Mirror1)
            : game.ent(Mirror2);
    description += !visibleMirror.isMunged()
        ? 'mirror.'
        : 'wooden panel which once contained a mirror.';
    if (visibleMirror.is(Mirror1) && structure.isOpen()) {
        if (!visibleMirror.isMunged()) {
            description +=
                '\nThe mirror is mounted on a panel which has been opened outward.';
        } else {
            description += '\nThe panel has been opened outward.';
        }
    }
    description += '\nThe opposite wall is solid rock.';
    return description;
}

export function getHallwayDescription(
    game: Game,
    northRoom: Room | undefined,
    southRoom: Room | undefined,
    northTell: string | undefined,
    southTell: string | undefined,
    hallTell = true
) {
    let description = '';
    if (hallTell) {
        description +=
            'This is a part of the long hallway. The east and west walls ' +
            'are dressed stone. In the center of the hall is a shallow stone channel. ' +
            'In the center of the room the channel widens into a large hole around ' +
            'which is engraved a compass rose.';
    }
    if (northTell) {
        description += `\n${northTell}`;
    } else if (southTell) {
        description += `\n${southTell}`;
    }
    const structure = game.ent(InsideMirror);
    const orientation = structure.orientation();
    let directionToStructure;
    const mirrorRoom = structure.position();
    if (northRoom && mirrorRoom.isEqualTo(northRoom)) {
        directionToStructure = Direction.North;
    } else if (southRoom && mirrorRoom.isEqualTo(southRoom)) {
        directionToStructure = Direction.South;
    }
    if (directionToStructure) {
        if (isNorthOrSouth(orientation)) {
            description +=
                `\nThe ${directionToStructure} side of the room is divided by ` +
                `a wooden wall into small hallways to the east and west.`; // TODO maybe sometimes only one or the other???
            if (hallTell) {
                if (!northTell && !southTell) {
                    description += '\nThe corridor continues north and south.';
                } else if (
                    !northTell &&
                    directionToStructure !== Direction.North
                ) {
                    description += '\nThe corridor continues north.';
                } else if (
                    !southTell &&
                    directionToStructure !== Direction.South
                ) {
                    description += '\nThe corridor continues south.';
                }
            }
        } else {
            const cc = rotateCounterclockwise;
            const visibleMirror =
                cc(cc(directionToStructure)) === orientation
                    ? game.ent(Mirror1)
                    : game.ent(Mirror2);
            const isMirrorBroken = visibleMirror.isMunged();
            if (!isMirrorBroken) {
                description += `\nA large mirror fills the `;
            } else {
                description += `\nA large panel fills the `;
            }
            description += `${directionToStructure} side of the hallway.`;
            if (visibleMirror.is(Mirror1) && structure.isOpen()) {
                if (!isMirrorBroken) {
                    description += `\nThe mirror is mounted on a panel which has been opened outward.`;
                } else {
                    description += `\nThe panel has been opened outward.`;
                }
            }
            if (isMirrorBroken) {
                description += `\nThe shattered pieces of a mirror cover the floor.`;
            }
        }
    }
    return description.trim();
}

export const GUARD_STRING =
    ', identical stone statues face each other from pedestals ' +
    'on opposite sides of the corridor. The statues represent Guardians of Zork, ' +
    'a military order of ancient lineage. They are portrayed as heavily armored ' +
    'warriors standing at ease, hands clasped around formidable bludgeons.';

function isNorthOrSouth(direction: Direction) {
    return direction === Direction.North || direction === Direction.South;
}

export function structureVisibleInDirection(game: Game, room: Room) {
    const structure = game.ent(InsideMirror);
    const position = structure.position();
    if (move(game, position, Direction.South, true)?.isEqualTo(room)) {
        return Direction.North;
    }
    if (move(game, position, Direction.West, true)?.isEqualTo(room)) {
        return Direction.East;
    }
    if (move(game, position, Direction.North, true)?.isEqualTo(room)) {
        return Direction.South;
    }
    if (move(game, position, Direction.East, true)?.isEqualTo(room)) {
        return Direction.West;
    }
}

export function structureIsVisibleHere(game: Game, room: Room) {
    return structureVisibleInDirection(game, room) !== undefined;
}

export function mirrorDoorIsVisibleHere(game: Game, room: Room) {
    const structure = game.ent(InsideMirror);
    const visibleDirection = structureVisibleInDirection(game, room);
    const orientation = structure.orientation();
    return rotateClockwise(rotateClockwise(orientation)) === visibleDirection;
}

export function hallwayGlobalObjects(room: Room) {
    const objects = [];
    const { game } = room;
    if (eitherWoodPanelIsVisibleHere(game, room)) {
        objects.push(game.ent(SidePanel));
    }
    if (mirrorDoorIsVisibleHere(game, room)) {
        objects.push(game.ent(Mirror1));
        objects.push(game.ent(MirrorPanel1));
    } else if (backOfMirrorIsVisibleHere(game, room)) {
        objects.push(game.ent(Mirror2));
        objects.push(game.ent(MirrorPanel2));
    }
    return objects;
}

export function backOfMirrorIsVisibleHere(game: Game, room: Room) {
    const structure = game.ent(InsideMirror);
    const visibleDirection = structureVisibleInDirection(game, room);
    const orientation = structure.orientation();
    const counterclockwise = rotateCounterclockwise;
    return counterclockwise(counterclockwise(orientation)) === visibleDirection;
}

export function eitherWoodPanelIsVisibleHere(game: Game, room: Room) {
    const structure = game.ent(InsideMirror);
    const visibleDirection = structureVisibleInDirection(game, room);
    const orientation = structure.orientation();
    const r = rotateClockwise;
    return (
        orientation === visibleDirection ||
        r(r(r(r(orientation)))) === visibleDirection
    );
}

export function eitherMirrorIsVisibleHere(game: Game, room: Room) {
    return (
        mirrorDoorIsVisibleHere(game, room) ||
        backOfMirrorIsVisibleHere(game, room)
    );
}

export function canEnter(
    game: Game,
    origin: Room
): { allowed: boolean; message?: string } {
    const structure = game.ent(InsideMirror);
    if (structureIsVisibleHere(game, origin)) {
        if (structure.isOpen()) {
            return { allowed: true };
        }
        return { allowed: false, message: 'The panel is closed.' };
    }
    return { allowed: false }; // "The structure blocks your way."???
}

export function enterPassage({ from }: { from: Room }) {
    const result = canEnter(from.game, from);
    return new Passage({
        via: SpecialDirection.In,
        condition: () => result.allowed,
        to: InsideMirror.spec().ref,
        message: result.message,
    });
}

export function hallwayPassage({ to, via }: { to: Room; via: Direction }) {
    const result = canGo(to.game, to, via);
    return new Passage({
        via,
        condition: () => result.allowed,
        to: to.ref(),
        message: result.message,
    });
}

export function narrowRoomEnter(
    game: Game,
    via: Direction
): {
    allowed: boolean;
    message?: string;
} {
    const structure = game.ent(InsideMirror);
    const cc = rotateCounterclockwise;
    const visibleMirror =
        cc(cc(via)) === structure.orientation()
            ? game.ent(Mirror1)
            : game.ent(Mirror2);
    if (structure.isOpen() && visibleMirror.is(Mirror1)) {
        return { allowed: true };
    }
    if (visibleMirror.isMunged()) {
        return {
            allowed: false,
            message: 'There is a large wooden panel there.',
        };
    }
    return { allowed: false, message: 'There is a large mirror there.' };
}

export function narrowRoomEnterPassage(game: Game, via: Direction) {
    const result = narrowRoomEnter(game, via);
    return new Passage({
        via: [SpecialDirection.In, via],
        condition: () => result.allowed,
        to: InsideMirror.spec().ref,
        message: result.message,
    });
}

export function canGo(
    game: Game,
    destination: Room,
    direction: Direction
): { allowed: boolean; message?: string } {
    const structure = game.ent(InsideMirror);
    const orientation = structure.orientation();
    const isStructureInDestination = structure
        .position()
        .isEqualTo(destination);
    if (isNorthOrSouth(direction)) {
        if (isStructureInDestination) {
            if (isNorthOrSouth(orientation)) {
                return {
                    allowed: false,
                    message: 'There is a wooden wall blocking your way.',
                };
            }
            return {
                allowed: false,
                message: mirrorBlock(game, orientation, direction),
            };
        }
        return { allowed: true };
    }
    return { allowed: isNorthOrSouth(orientation) };
}

function mirrorBlock(game: Game, orientation: Direction, direction: Direction) {
    const cc = rotateCounterclockwise;
    const visibleMirror =
        cc(cc(direction)) === orientation
            ? game.ent(Mirror1)
            : game.ent(Mirror2);
    if (visibleMirror.isMunged()) {
        return 'There is a large broken mirror blocking your way.';
    }
    return 'There is a large mirror blocking your way.';
}

export function exitInDirection(game: Game, room: Room, direction: Direction) {
    if (direction === Direction.North || direction === Direction.South) {
        return move(game, room, direction, true);
    }
    if (
        direction === Direction.East ||
        direction === Direction.Southeast ||
        direction === Direction.Northeast
    ) {
        return move(game, room, Direction.East, true);
    }
    if (
        direction === Direction.West ||
        direction === Direction.Northwest ||
        direction === Direction.Southwest
    ) {
        return move(game, room, Direction.West, true);
    }
}

export function move(
    game: Game,
    room: Room,
    direction:
        | Direction.North
        | Direction.South
        | Direction.East
        | Direction.West,
    toExit: boolean
) {
    switch (direction) {
        case Direction.North:
            return moveNorth(game, room, toExit);
        case Direction.South:
            return moveSouth(game, room, toExit);
        case Direction.East:
            return moveEast(game, room);
        case Direction.West:
            return moveWest(game, room);
    }
}

export function moveNorth(game: Game, room: Room, toExit: boolean) {
    if (room.like(Hallway1)) {
        return game.ent(Hallway2);
    }
    if (room.like(Hallway2)) {
        return game.ent(Hallway3);
    }
    if (room.like(Hallway3)) {
        return game.ent(GuardianHallway);
    }
    if (room.like(GuardianHallway)) {
        return game.ent(Hallway4);
    }
    if (room.like(Hallway4) && toExit) {
        return game.ent(DungeonEntrance);
    }
    return undefined;
}

export function moveSouth(game: Game, room: Room, toExit: boolean) {
    if (room.like(Hallway2)) {
        return game.ent(Hallway1);
    }
    if (room.like(Hallway3)) {
        return game.ent(Hallway2);
    }
    if (room.like(GuardianHallway)) {
        return game.ent(Hallway3);
    }
    if (room.like(Hallway4)) {
        return game.ent(GuardianHallway);
    }
    if (room.like(Hallway1) && toExit) {
        return game.ent(SmallRoom);
    }
    return undefined;
}

export function moveEast(game: Game, room: Room) {
    if (room.like(Hallway1)) {
        return game.ent(EastNarrowRoom1);
    }
    if (room.like(Hallway2)) {
        return game.ent(EastNarrowRoom2);
    }
    if (room.like(Hallway3)) {
        return game.ent(EastNarrowRoom3);
    }
    if (room.like(GuardianHallway)) {
        return game.ent(EastGuardianNarrowRoom);
    }
    if (room.like(Hallway4)) {
        return game.ent(EastNarrowRoom4);
    }
    return undefined;
}

export function moveWest(game: Game, room: Room) {
    if (room.like(Hallway1)) {
        return game.ent(WestNarrowRoom1);
    }
    if (room.like(Hallway2)) {
        return game.ent(WestNarrowRoom2);
    }
    if (room.like(Hallway3)) {
        return game.ent(WestNarrowRoom3);
    }
    if (room.like(GuardianHallway)) {
        return game.ent(WestGuardianNarrowRoom);
    }
    if (room.like(Hallway4)) {
        return game.ent(WestNarrowRoom4);
    }
    return undefined;
}

export function rotateClockwise(direction: Direction) {
    switch (direction) {
        case Direction.North:
            return Direction.Northeast;
        case Direction.Northeast:
            return Direction.East;
        case Direction.East:
            return Direction.Southeast;
        case Direction.Southeast:
            return Direction.South;
        case Direction.South:
            return Direction.Southwest;
        case Direction.Southwest:
            return Direction.West;
        case Direction.West:
            return Direction.Northwest;
        case Direction.Northwest:
            return Direction.North;
        default:
            throw new Error('Non-rotatable direction');
    }
}

export function rotateCounterclockwise(direction: Direction) {
    switch (direction) {
        case Direction.North:
            return Direction.Northwest;
        case Direction.Northeast:
            return Direction.North;
        case Direction.East:
            return Direction.Northeast;
        case Direction.Southeast:
            return Direction.East;
        case Direction.South:
            return Direction.Southeast;
        case Direction.Southwest:
            return Direction.South;
        case Direction.West:
            return Direction.Southwest;
        case Direction.Northwest:
            return Direction.West;
        default:
            throw new Error('Non-rotatable direction');
    }
}
