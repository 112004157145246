import { Verb, Parse, Parser, Value, Target, Obj, Group } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { CountUnresolved, countUnresolvedHandler } from './CountUnresolved';
import { NUMBERS } from '../../constants';
import { pluralize } from '../../utils';

export class Count extends Action {
    id = '~count';

    item: Entity | undefined;

    constructor({ item }: { item: Entity | undefined }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [countHandler, countManyHandler, countUnresolvedHandler],
            parser,
            verbs: [
                new Verb('count'),
                new Verb('tally'),
                new Verb('count up'),
                new Verb('how many'),
            ],
            prepositions: [],
        };
    }
}

export const countHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Count)) return;
    const { item } = action;
    if (!item) {
        await runner.doOutput("Let's see, one, two, ...what comes next?");
    } else {
        await runner.doOutput('You have lost your mind.');
    }
    return Action.complete();
};

export class CountMany extends Action {
    id = '~count-many';

    items: Entity[];

    target: Target;

    constructor({ items, target }: { items: Entity[]; target: Target }) {
        super();
        this.items = items;
        this.target = target;
    }
}

// TODO test this
export const countManyHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(CountMany)) return;
    const { items, target } = action;
    const playerItems = items.filter((item) => actor?.hasItem(item));
    const shouldCountRoomItems = playerItems.length === 0;
    const countableItems = shouldCountRoomItems ? items : playerItems;
    const count = countableItems.length;
    const leadIn = shouldCountRoomItems
        ? `There ${pluralize(count, 'is', 'are')}`
        : 'You have';
    const numString = count === 0 ? 'no' : NUMBERS[count];
    const description = describeTarget(target);
    if (count === 1) {
        await runner.doOutput(
            `${leadIn} just the one ${countableItems[0].name()}.`
        );
    } else {
        await runner.doOutput(`${leadIn} ${numString}${description}.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, CountUnresolved> => {
    const count = Parse.words(Count.ability().verbs);
    const countObject = count.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // count
        count.into(new CountUnresolved({ item: undefined })),
        // count button
        countObject.map((item) => new CountUnresolved({ item }))
    );
};

function describeTarget(target: Target) {
    if (target.items.length > 1) {
        return '';
    }
    const item = target.items[0];
    if (item instanceof Obj) {
        if (item.noun.plural) {
            return ` ${item.description}`;
        }
    } else if (item instanceof Group) {
        if (!item.exclude && item.include instanceof Obj) {
            if (item.include.noun.plural) {
                return ` ${item.include.description}`;
            }
        }
    }
    return '';
}
