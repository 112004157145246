import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { GasRoom } from '../GasRoom';
import { WoodenTunnel } from '../WoodenTunnel';

export class SmellyRoom extends Room {
    static spec(): EntitySpec<SmellyRoom> {
        return {
            ref: 'smelly-room',
            constructor: SmellyRoom,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return SmellyRoom.spec().ref;
    }

    name(): string {
        return 'Smelly Room';
    }

    description(): string {
        return (
            'This is a small nondescript room. However, from the direction ' +
            'of a small descending staircase a foul odor can be detected. ' +
            'To the east is a narrow path.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Down,
                to: GasRoom.spec().ref,
            }),
            new Passage({
                via: Direction.East,
                to: WoodenTunnel.spec().ref,
            }),
        ];
    }
}
