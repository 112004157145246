import { Noun, Adjective } from '../../../parse';
import {
    makeFlammable,
    makeOpenable,
    makeReadable,
    makeTakeable,
} from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Light, Open, Read, SpecialJigsUp } from '../../abilities';
import { EntranceToHades } from '../../rooms/EntranceToHades';
import { Ghosts } from '../Ghosts';

export class Book extends makeFlammable(
    makeTakeable(makeReadable(makeOpenable(Item)))
) {
    static spec(): EntitySpec<Book> {
        return {
            ref: 'book',
            constructor: Book,
            initial: {
                isOpen: true,
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('book'),
                new Noun('books', { plural: true }),
                new Noun('prayerbook'),
                new Noun('prayerbooks', { plural: true }),
                new Noun('prayer book'),
                new Noun('prayer books', { plural: true }),
                new Noun('bible'),
                new Noun('bibles', { plural: true }),
                new Noun('hymnal'),
                new Noun('hymnals', { plural: true }),
            ],
            adjectives: [new Adjective('large'), new Adjective('black')],
            handlers: [openBook, closeBook, burnBook, readBook],
        };
    }

    ref() {
        return Book.spec().ref;
    }

    name() {
        return 'book';
    }

    description() {
        return 'There is a large black book here.';
    }

    initialDescription() {
        return 'On the altar is a large black book, open to page 569.';
    }

    size() {
        return 10;
    }

    text() {
        return BIBLE_TEXT;
    }

    nouns(): Noun[] {
        return Book.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Book.spec().adjectives;
    }
}

const BIBLE_TEXT =
    '   COMMANDMENT #12592\nOh ye who go about saying unto each: "Hello sailor": ' +
    'dost thou know the magnitude of thy sin before the gods? Yea, verily, ' +
    'thou shalt be ground between two stones. Shall the angry gods cast thy body ' +
    'into the whirlpool? Surely, thy eye shall be put out with a sharp stick! ' +
    'Even unto the ends of the earth shalt thou wander and unto the land of ' +
    'the dead shalt thou be sent at last. Surely thou shalt repent of thy cunning.';

const openBook: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(Book)) {
        await runner.doOutput('The book is already open to page 569.');
        return Action.complete();
    }
};

const closeBook: Handler = async ({ action, runner }) => {
    if (action.is(Close) && action.item.is(Book)) {
        await runner.doOutput('As hard as you try, the book cannot be closed.');
        return Action.complete();
    }
};

// TODO test that putting this in the balloon receptacle and burning it still kills you
const burnBook: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Light) &&
        action.item.is(Book) &&
        action.tool?.isItem() &&
        action.tool.isFlammable() &&
        action.tool.isAflame()
    ) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    "A booming voice says 'Wrong, cretin!' and you notice that you have turned into a pile of dust.",
            })
        );
        return Action.complete();
    }
};

const readBook: Handler = async ({
    action,
    runner,
    actor,
    game,
    extensions,
}) => {
    if (
        action.is(Read) &&
        action.item.is(Book) &&
        actor?.location()?.is(EntranceToHades) &&
        game.ent(Ghosts).isVeryAfraid()
    ) {
        await extensions.deferHandling();
        await runner.doOutput(
            'Each word of the prayer reverberates through the hall in a deafening confusion. ' +
                "As the last word fades, a voice, loud and commanding, speaks: 'Begone, fiends!'. " +
                'A heart-stopping scream fills the cavern, and the spirits, ' +
                'sensing a greater power, flee through the walls.'
        );
        game.ent(Ghosts).moveTo(undefined);
        return Action.complete();
    }
};
