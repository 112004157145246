import {
    EastNarrowRoom4,
    NarrowCorridor,
    Passage,
    Room,
    WestNarrowRoom4,
} from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { Hallway4 } from '../Hallway4';
import {
    enterPassage,
    getHallwayDescription,
    hallwayPassage,
} from '../InsideMirror/mirror_utils';
import { QuestionDoor } from '../../items';
import { DungeonMaster } from '../../actors';

export class DungeonEntrance extends Room {
    static spec(): EntitySpec<DungeonEntrance> {
        return {
            ref: 'dungeon-entrance',
            constructor: DungeonEntrance,
            initial: {
                contents: [QuestionDoor.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    scoreOnEntry() {
        return 15;
    }

    ref() {
        return DungeonEntrance.spec().ref;
    }

    name(): string {
        return 'Dungeon Entrance';
    }

    description(): string {
        let description = getHallwayDescription(
            this.game,
            undefined,
            this.game.ent(Hallway4),
            'You are in a north-south hallway which ends in a large wooden door.',
            undefined,
            false
        );
        const door = this.ent(QuestionDoor);
        const doorOpenClose = door.isOpen() ? 'open' : 'closed';
        const panelOpenClose = door.hasBeenKnocked() ? 'open' : 'closed';
        description +=
            `\nThe wooden door has a barred panel in it at about ` +
            `head height. The panel is ${panelOpenClose} and the door is ` +
            `${doorOpenClose}.`;
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            hallwayPassage({
                to: this.game.ent(Hallway4),
                via: Direction.South,
            }),
            hallwayPassage({
                to: this.game.ent(EastNarrowRoom4),
                via: Direction.Southeast,
            }),
            hallwayPassage({
                to: this.game.ent(WestNarrowRoom4),
                via: Direction.Southwest,
            }),
            enterPassage({ from: this }),
            new Passage({
                via: Direction.North,
                condition: () => this.game.ent(QuestionDoor).isOpen(),
                to: NarrowCorridor.spec().ref,
                message: 'The door is shut.',
            }),
        ];
    }

    visibleObjects(): Entity[] {
        const objects = [...super.visibleObjects()];
        const dungeonMaster = this.game.ent(DungeonMaster);
        if (
            this.game.ent(NarrowCorridor).contains(dungeonMaster) &&
            this.ent(QuestionDoor).hasBeenKnocked()
        ) {
            objects.push(dungeonMaster);
        }
        return objects;
    }
}
