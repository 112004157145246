import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { BankEntrance } from '../BankEntrance';

export class EastViewingRoom extends Room {
    static spec(): EntitySpec<EastViewingRoom> {
        return {
            ref: 'east-viewing-room',
            constructor: EastViewingRoom,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return EastViewingRoom.spec().ref;
    }

    name(): string {
        return "East Teller's Room";
    }

    description(): string {
        return VIEWING_ROOM_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.South, to: BankEntrance.spec().ref }),
        ];
    }

    hasWalls(): boolean {
        return true;
    }
}

const VIEWING_ROOM_DESCRIPTION =
    'This is a room used by holders of safety deposit ' +
    'boxes to view their contents. On the north side of the room is a sign which say\n\n' +
    `   REMAIN HERE WHILE THE BANK OFFICER RETRIEVES YOUR DEPOSIT BOX
    WHEN YOU ARE FINISHED, LEAVE THE BOX, AND EXIT TO THE SOUTH
     AN ADVANCED PROTECTIVE DEVICE PREVENTS ALL CUSTOMERS FROM
      REMOVING ANY SAFETY DEPOSIT BOX FROM THIS VIEWING AREA!
               Thank You for banking at the Zork!`;
