import { EastNarrowRoom1, Passage, Room, WestNarrowRoom1 } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { Hallway1 } from '../Hallway1';
import { StoneRoom } from '../StoneRoom';
import { RedBeam } from '../../items';
import { Drop, Take } from '../../abilities';
import { Player } from '../../actors';
import {
    enterPassage,
    getHallwayDescription,
    hallwayPassage,
} from '../InsideMirror/mirror_utils';

export class SmallRoom extends Room {
    static spec(): EntitySpec<SmallRoom> {
        return {
            ref: 'small-room',
            constructor: SmallRoom,
            initial: {
                contents: [RedBeam.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [blockUnblockBeam],
        };
    }

    ref() {
        return SmallRoom.spec().ref;
    }

    name(): string {
        return 'Small Room';
    }

    description(): string {
        let description =
            'You are in a small room, with narrow passages exiting ' +
            'to the north and south. A narrow red beam of light crosses the ' +
            'room at the north end, inches above the floor.';
        const beam = this.game.ent(RedBeam);
        const blockingItem = beam.blockedBy();
        if (blockingItem) {
            description += ` The beam is stopped halfway across the room by ${blockingItem.an()} lying on the floor.`;
        }
        const hallwayDescription = getHallwayDescription(
            this.game,
            this.game.ent(Hallway1),
            undefined,
            undefined,
            undefined,
            false
        );
        if (hallwayDescription) {
            description += `\n${hallwayDescription}`;
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            hallwayPassage({
                to: this.game.ent(Hallway1),
                via: Direction.North,
            }),
            hallwayPassage({
                to: this.game.ent(EastNarrowRoom1),
                via: Direction.Northeast,
            }),
            hallwayPassage({
                to: this.game.ent(WestNarrowRoom1),
                via: Direction.Northwest,
            }),
            enterPassage({ from: this }),
            new Passage({
                via: Direction.South,
                to: StoneRoom.spec().ref,
            }),
        ];
    }
}

const blockUnblockBeam: Handler = async ({
    action,
    runner,
    actor,
    game,
    extensions,
}) => {
    if (actor?.is(Player) && actor.location().is(SmallRoom)) {
        const beam = game.ent(RedBeam);
        if (action.is(Drop) && !beam.isBlocked()) {
            await extensions.deferHandling();
            if (beam.isBlocked()) {
                await runner.doOutput(
                    `The beam is now interrupted by a ${action.item} lying on the floor.`
                );
            }
            return Action.complete();
        }
        if (action.is(Take) && beam.isBlocked()) {
            await extensions.deferHandling();
            if (!beam.isBlocked()) {
                await runner.doOutput(`The beam is no longer interrupted.`);
            }
            return Action.complete();
        }
    }
};
