import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Follow } from './Follow';
import { UnresolvedAction } from '../UnresolvedAction';
import { Player } from '../../actors';

export class FollowUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'follow';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'follow';
        }
        return `follow ${targetDescription(game, this.item)}`;
    }
}

export const followUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(FollowUnresolved)) return;

    let item;
    if (action.item) {
        const { item: resolvedItem } = await game.resolve(
            runner,
            action.item,
            actor,
            {
                partial: (item) => new FollowUnresolved({ item }),
                missing: () => 'Follow what?',
                ambiguous: (desc, opt) =>
                    `Which ${desc} would you like to follow, ${opt}?`,
                condition: (item) => item.isActor(),
            }
        );
        if (resolvedItem === undefined)
            return Action.complete({ withConsequence: false });
        item = resolvedItem;
    } else {
        item = game.ent(Player);
    }

    return await game.applyAction(runner, new Follow({ item }), actor);
};
