import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { NorthOfHouse } from '../NorthOfHouse';
import { SouthOfHouse } from '../SouthOfHouse';
import { Clearing } from '../Clearing';
import { Kitchen } from '../Kitchen';
import { Action, EntitySpec, Handler } from '../../game';
import { SpecialDescribeRoom } from '../../abilities/SpecialDescribeRoom';
import { House, KitchenWindow } from '../../items';

export class BehindHouse extends Room {
    static spec(): EntitySpec<BehindHouse> {
        return {
            ref: 'behind-house',
            constructor: BehindHouse,
            initial: {
                contents: [KitchenWindow.spec().ref, House.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [describeBehindHouse],
        };
    }

    ref() {
        return BehindHouse.spec().ref;
    }

    name(): string {
        return 'Behind House';
    }

    description(): string {
        throw new Error('Handler should have overridden this.');
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: NorthOfHouse.spec().ref }),
            new Passage({ via: Direction.South, to: SouthOfHouse.spec().ref }),
            new Passage({ via: Direction.East, to: Clearing.spec().ref }),
            new Passage({
                via: Direction.West,
                to: Kitchen.spec().ref,
                condition: () => this.game.ent(KitchenWindow).isOpen(),
                message: 'The window is closed.',
            }),
        ];
    }

    hasWalls() {
        return false;
    }

    hasWestWall(): boolean {
        return true;
    }
}

const describeBehindHouse: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialDescribeRoom) && action.room.is(BehindHouse)) {
        const window = game.ent(KitchenWindow);
        const status = window.isOpen() ? 'open' : 'slightly ajar';
        await runner.doOutput(
            `You are behind the white house. In one corner of the house there is a small window which is ${status}.`
        );
        return Action.complete({ withConsequence: false });
    }
};
