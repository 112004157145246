import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { MeltUnresolved, meltUnresolvedHandler } from './MeltUnresolved';

export class Melt extends Action {
    id = '~melt';

    item: Entity;

    tool: Entity;

    constructor({ item, tool }: { item: Entity; tool: Entity }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [meltHandler, meltUnresolvedHandler],
            parser,
            verbs: [new Verb('melt'), new Verb('liquefy'), new Verb('thaw')],
            prepositions: [],
        };
    }
}

export const meltHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Melt)) return;
    await runner.doOutput(`I'm not sure ${action.item.the()} can be melted.`);
    return Action.complete();
};

const parser = (game: Game): Parser<Value, MeltUnresolved> => {
    const melt = Parse.words(Melt.ability().verbs);
    const meltObject = melt.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const meltObjectWith = meltObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // melt
        melt.into(new MeltUnresolved({ item: undefined, tool: undefined })),
        // melt box
        meltObject.map((item) => new MeltUnresolved({ item, tool: undefined })),
        // melt egg with hammer
        meltObjectWith.map(
            ([item, tool]) =>
                new MeltUnresolved({
                    item,
                    tool,
                })
        )
    );
};
