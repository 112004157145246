import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class ClothBag extends Item {
    static spec(): EntitySpec<ClothBag> {
        return {
            ref: 'cloth-bag',
            constructor: ClothBag,
            initial: {},
            nouns: [new Noun('bag'), new Noun('bags', { plural: true })],
            adjectives: [new Adjective('cloth')],
        };
    }

    ref() {
        return ClothBag.spec().ref;
    }

    name(): string {
        return 'cloth bag';
    }

    description(): string {
        return '';
    }

    nouns() {
        return ClothBag.spec().nouns;
    }

    adjectives() {
        return ClothBag.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

/*
TODO

  if parse.verb in game.get_verbs("take") and direct in game.objects["balloon"]:
    wire = (" The wire might possibly be tied, though."
      if direct is game.objects["braided_wire"] else "")
    raise Complete("The %s is an integral part of the basket and cannot be removed.%s" % direct.name, wire)
  if parse.verb in game.get_verbs("find", "examine"):
    raise Complete("The %s is part of the basket. It may be manipulated within the basket but cannot be removed." % direct.name)
 */
