import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { BehindHouse } from '../BehindHouse';
import { Attic } from '../Attic';
import { Action, EntitySpec, Handler } from '../../game';
import { SpecialDescribeRoom } from '../../abilities/SpecialDescribeRoom';
import { KitchenWindow, Sack, Bottle, KitchenTable } from '../../items';
import { LivingRoom } from '../LivingRoom';

export class Kitchen extends Room {
    static spec(): EntitySpec<Kitchen> {
        return {
            ref: 'kitchen',
            constructor: Kitchen,
            initial: {
                contents: [
                    KitchenWindow.spec().ref,
                    Sack.spec().ref,
                    Bottle.spec().ref,
                    KitchenTable.spec().ref,
                    // TODO dark staircase leading up
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [describeKitchen],
        };
    }

    ref() {
        return Kitchen.spec().ref;
    }

    name(): string {
        return 'Kitchen';
    }

    description(): string {
        throw new Error('Handler should have overridden this.');
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    scoreOnEntry(): number {
        return 10;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Up, to: Attic.spec().ref }),
            new Passage({ via: Direction.West, to: LivingRoom.spec().ref }),
            new Passage({
                via: Direction.Down,
                message: 'Only Santa Claus climbs down chimneys.',
            }),
            new Passage({
                via: Direction.East,
                to: BehindHouse.spec().ref,
                condition: () => this.game.ent(KitchenWindow).isOpen(),
                message: 'The window is closed.',
            }),
        ];
    }

    isPartOfHouse(): boolean {
        return true;
    }
}

const describeKitchen: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialDescribeRoom) && action.room.is(Kitchen)) {
        const window = game.ent(KitchenWindow);
        const status = window.isOpen() ? 'open' : 'slightly ajar';
        await runner.doOutput(
            `You are in the kitchen of the white house. ` +
                `A table seems to have been used recently for the preparation of food. ` +
                `A passage leads to the west and a dark staircase can be seen leading upward. ` +
                `To the east is a small window which is ${status}.`
        );
        return Action.complete({ withConsequence: false });
    }
};
