import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { LowerUnresolved, lowerUnresolvedHandler } from './LowerUnresolved';
import { HO_HUM } from '../../constants';

export class Lower extends Action {
    id = '~lower';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [lowerHandler, lowerUnresolvedHandler],
            parser,
            verbs: [
                new Verb('lower'),
                new Verb('send down'),
                new Verb('let down'),
            ],
            prepositions: [],
        };
    }
}

export const lowerHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Lower)) return;
    const { item } = action;
    await runner.doOutput(
        `Playing in this way with ${item.an()} ${game.choiceOf(HO_HUM)}`
    );
    return Action.complete();
};

const parser = (game: Game): Parser<Value, LowerUnresolved> => {
    const lower = Parse.words(Lower.ability().verbs);
    const lowerObject = lower.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // lower
        lower.into(new LowerUnresolved({ item: undefined })),
        // lower basket
        lowerObject.map((item) => new LowerUnresolved({ item }))
    );
};
