import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Answer extends makeDescribable(Action) {
    id = '~answer';

    message: string | undefined;

    constructor({ message }: { message: string | undefined }) {
        super();
        this.message = message;
    }

    static ability(): Ability {
        return {
            handlers: [answerHandler],
            parser,
            verbs: [new Verb('answer'), new Verb('reply')],
            prepositions: [],
        };
    }

    description(game: Game): string {
        if (this.message) {
            return `answer "${this.message}"`;
        }
        return 'answer somehow';
    }
}

export const answerHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Answer)) return undefined;

    await runner.doOutput('No one seems to be listening.');

    return Action.complete();
};

const parser = (): Parser<Value, Answer> =>
    Parse.any(
        Parse.words(Answer.ability().verbs).into(undefined),
        Parse.words(Answer.ability().verbs)
            .before(Parse.whitespace())
            .before(Parse.quote())
            .beforeX(Parse.many(Parse.node()))
            .before(Parse.quote())
            .map((nodes) => nodes.map((node) => node.value).join(''))
    ).map((message) => new Answer({ message }));
