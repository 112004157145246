import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { TieUnresolved, tieUnresolvedHandler } from './TieUnresolved';

export class Tie extends Action {
    id = '~tie';

    fixture: Entity | undefined;

    item: Entity;

    constructor({
        fixture,
        item,
    }: {
        fixture: Entity | undefined;
        item: Entity;
    }) {
        super();
        this.fixture = fixture;
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [tieHandler, tieUnresolvedHandler],
            parser,
            verbs: [
                new Verb('tie'),
                new Verb('fasten'),
                new Verb('knot'),
                new Verb('bind'),
            ],
            prepositions: [
                new Preposition('to'),
                new Preposition('on to'),
                new Preposition('onto'),
            ],
        };
    }
}

export const tieHandler: Handler = async ({ action, actor, runner }) => {
    if (!action.is(Tie) || actor === undefined) return;

    const { fixture, item } = action;

    if (!item.isItem() || !item.canBeFastened()) {
        await runner.doOutput('How can you tie that to anything?');
    } else if (!fixture) {
        await runner.doOutput(`What would you like to tie ${item.the()} to?`);
        // TODO set partial
    } else if (item.isEqualTo(fixture)) {
        await runner.doOutput('What good would that do you?');
    } else if (fixture.isEqualTo(actor)) {
        await runner.doOutput(`You can't tie ${item.the()} to yourself.`);
    } else {
        await runner.doOutput(`You can't tie ${item.the()} to that.`);
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, TieUnresolved> => {
    const ability = Tie.ability();
    const tie = Parse.words(ability.verbs);
    const tieEnemy = tie.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const tieEnemyWith = tieEnemy.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((container) => [item, container])
    );
    return Parse.any(
        // tie
        tie.into(new TieUnresolved({ fixture: undefined, item: undefined })),
        // tie rope
        tieEnemy.map((item) => new TieUnresolved({ fixture: undefined, item })),
        // tie rope to railing
        tieEnemyWith.map(
            ([item, fixture]) => new TieUnresolved({ fixture, item })
        )
    );
};

// TODO "tie up" action
