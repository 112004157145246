import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { MINE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { CoalMine6 } from '../CoalMine6';
import { CoalMine4 } from '../CoalMine4';
import { CoalMine3 } from '../CoalMine3';
import { CoalMine2 } from '../CoalMine2';
import { CoalMine7 } from '../CoalMine7';

export class CoalMine5 extends Room {
    static spec(): EntitySpec<CoalMine5> {
        return {
            ref: 'coal-mine-5',
            constructor: CoalMine5,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return CoalMine5.spec().ref;
    }

    name(): string {
        return 'Coal Mine';
    }

    description(): string {
        return MINE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Down, to: CoalMine6.spec().ref }),
            new Passage({ via: Direction.North, to: CoalMine7.spec().ref }),
            new Passage({ via: Direction.West, to: CoalMine2.spec().ref }),
            new Passage({ via: Direction.South, to: CoalMine3.spec().ref }),
            new Passage({ via: Direction.Up, to: CoalMine3.spec().ref }),
            new Passage({ via: Direction.East, to: CoalMine4.spec().ref }),
        ];
    }
}
