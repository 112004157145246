import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeOpenable, makeReadable } from '../../game/Entity';
import { EntitySpec } from '../../game';

export class StoneCube extends makeReadable(makeOpenable(makeContainer(Item))) {
    static spec(): EntitySpec<StoneCube> {
        return {
            ref: 'stone-cube',
            constructor: StoneCube,
            initial: {
                contents: [],
                isOpen: false,
            },
            nouns: [
                // TODO lettering
                new Noun('vault'),
                new Noun('vaults', { plural: true }),
                new Noun('cube'),
                new Noun('cubes', { plural: true }),
            ],
            adjectives: [new Adjective('large'), new Adjective('stone')],
        };
    }

    ref() {
        return StoneCube.spec().ref;
    }

    name(): string {
        return 'large stone cube';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    totalCapacity(): undefined {
        return undefined;
    }

    canBeOpened(): boolean {
        return true;
    }

    nouns() {
        return StoneCube.spec().nouns;
    }

    adjectives() {
        return StoneCube.spec().adjectives;
    }

    text(): string {
        return VAULT_TEXT;
    }
}

const VAULT_TEXT = `          Bank of Zork
             VAULT
           *722 GUE*
   Frobozz Magic Vault Company`;
