import { Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeExaminable } from '../../game/Entity';
import { Find } from '../../abilities';

export class Songbird extends makeExaminable(Item) {
    static spec(): EntitySpec<Songbird> {
        return {
            ref: 'songbird',
            constructor: Songbird,
            initial: {},
            nouns: [
                new Noun('songbird'),
                new Noun('songbirds', { plural: true }),
                new Noun('song bird'),
                new Noun('song birds', { plural: true }),
                new Noun('birdie'),
                new Noun('birdies', { plural: true }),
                new Noun('bird'),
                new Noun('birds', { plural: true }),
            ],
            adjectives: [],
            handlers: [findBird],
        };
    }

    ref() {
        return Songbird.spec().ref;
    }

    name(): string {
        return 'songbird';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Songbird.spec().nouns;
    }

    adjectives() {
        return Songbird.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    examineText(): string {
        return "You can't get a good look at the songbird, considering you can't tell where it is.";
    }
}

const findBird: Handler = async ({ action, runner }) => {
    if (action.is(Find) && action.item.is(Songbird)) {
        await runner.doOutput(
            'The songbird is not here, but is probably nearby.'
        );
        return Action.complete();
    }
};
