import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeTakeable, makeTreasure } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Mung } from '../../abilities';

interface PaintingState extends ItemState {
    isMunged: boolean;
}

abstract class Base extends Item<PaintingState> {}

export class Painting extends makeFlammable(makeTreasure(makeTakeable(Base))) {
    static spec(): EntitySpec<Painting> {
        return {
            ref: 'painting',
            constructor: Painting,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
                isMunged: false,
            },
            nouns: [
                new Noun('painting'),
                new Noun('paintings', { plural: true }),
                new Noun('masterpiece'),
                new Noun('masterpieces', { plural: true }),
                new Noun('art', { collective: true }),
                new Noun('canvas'),
                new Noun('picture'),
                new Noun('pictures', { plural: true }),
                new Noun('work of art'),
                new Noun('works of art', { plural: true }),
            ],
            adjectives: [
                new Adjective('beautiful'),
                new Adjective('masterful'),
            ],
            handlers: [mungPainting],
        };
    }

    ref() {
        return Painting.spec().ref;
    }

    name() {
        return this.state.isMunged ? 'worthless piece of canvas' : 'painting';
    }

    description() {
        return this.state.isMunged
            ? 'There is a worthless piece of canvas here.'
            : 'A masterpiece by a neglected genius is here.';
    }

    initialDescription() {
        return (
            'Fortunately, there is still one chance for you to be a vandal, ' +
            'for on the far wall is a work of unparalleled beauty.'
        );
    }

    size() {
        return 15;
    }

    scoreOnTake(): number {
        return 4;
    }

    scoreInCase(): number {
        return this.state.isMunged ? 0 : 7;
    }

    nouns(): Noun[] {
        return Painting.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Painting.spec().adjectives;
    }
}

// TODO test this
const mungPainting: Handler = async ({ action, runner, actor }) => {
    if (actor && action.is(Mung) && action.item.is(Painting)) {
        await runner.doOutput(
            'Congratulations! Unlike the other vandals, who merely stole ' +
                "the artist's masterpieces, you have destroyed one."
        );
        action.item.state.isMunged = true;
        return Action.complete();
    }
};
