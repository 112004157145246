import { EgyptianRoom, Passage, Room, StreamView } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Glacier } from '../../items';
import { RubyRoom } from '../RubyRoom';

export class GlacierRoom extends Room {
    static spec(): EntitySpec<GlacierRoom> {
        return {
            ref: 'glacier-room',
            constructor: GlacierRoom,
            initial: {
                contents: [Glacier.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return GlacierRoom.spec().ref;
    }

    name(): string {
        return 'Glacier Room';
    }

    description(): string {
        let description =
            'This is a large room, with giant icicles hanging from the walls and ceiling. There are passages to the north and east.';
        const glacier = this.game.ent(Glacier);
        if (this.contains(glacier)) {
            if (glacier.isPartiallyMelted()) {
                description += '\nPart of the glacier has been melted.';
            }
        } else {
            description += '\nThere is a large passageway leading westward.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: StreamView.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: RubyRoom.spec().ref,
                condition: () => !this.contains(this.game.ent(Glacier)),
                // TODO message
                message: 'There is a large glacier in the way.',
            }),
            new Passage({
                via: Direction.East,
                to: EgyptianRoom.spec().ref,
            }),
        ];
    }
}
