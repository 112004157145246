import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { TeaRoom } from '../../rooms/TeaRoom';

export class OblongTable extends Item {
    static spec(): EntitySpec<OblongTable> {
        return {
            ref: 'oblong-table',
            constructor: OblongTable,
            initial: {},
            nouns: [new Noun('table'), new Noun('tables', { plural: true })],
            adjectives: [new Adjective('large'), new Adjective('oblong')],
        };
    }

    ref() {
        return OblongTable.spec().ref;
    }

    isEnlarged(): boolean {
        return this.game.ent(TeaRoom).isEnlarged();
    }

    name(): string {
        if (this.isEnlarged()) {
            return 'group of wooden posts';
        }
        return 'oblong table';
    }

    description(): string {
        return '';
    }

    nouns() {
        if (this.isEnlarged()) {
            return [
                new Noun('posts', { collective: true }),
                new Noun('post'),
                new Noun('group of wooden posts'),
                new Noun('group of posts'),
            ];
        }
        return [new Noun('table'), new Noun('tables', { plural: true })];
    }

    adjectives() {
        if (this.isEnlarged()) {
            return [new Adjective('wooden'), new Adjective('large')];
        }
        return [new Adjective('large'), new Adjective('oblong')];
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
