import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Oops extends makeDescribable(Action) {
    id = 'oops';

    description(game: Game): string {
        return 'oops';
    }

    static ability(): Ability {
        const verbs = [new Verb('oops'), new Verb('oopsie'), new Verb('woops')];
        return {
            handlers: [oopsHandler],
            parser: () => Parse.words(verbs).into(new Oops()),
            verbs,
            prepositions: [],
        };
    }
}

export const oopsHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Oops)) return;
    await runner.doOutput("You haven't made any spelling mistakes....lately.");
    return Action.complete();
};
