import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { BrushUnresolved, brushUnresolvedHandler } from './BrushUnresolved';

export class Brush extends Action {
    id = '~brush';

    item: Entity;

    tool: Entity | undefined;

    constructor({ item, tool }: { item: Entity; tool: Entity | undefined }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [brushHandler, brushUnresolvedHandler],
            parser,
            verbs: [
                new Verb('brush'),
                new Verb('clean'),
                new Verb('brush off'),
                new Verb('clean off'),
            ],
            prepositions: [new Preposition('with'), new Preposition('using')],
        };
    }
}

export const brushHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Brush)) return;
    await runner.doOutput("If you wish, but I can't understand why??");
    return Action.complete();
};

const parser = (game: Game): Parser<Value, BrushUnresolved> => {
    const ability = Brush.ability();
    const brush = Parse.words(ability.verbs);
    const brushItem = brush.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const brushItemWithTool = brushItem.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((tool) => [tool, item])
    );
    return Parse.any(
        // brush
        brush.into(new BrushUnresolved({ item: undefined, tool: undefined })),
        // brush bottle
        brushItem.map((item) => new BrushUnresolved({ item, tool: undefined })),
        // brush bottle with water
        brushItemWithTool.map(
            ([tool, item]) => new BrushUnresolved({ item, tool })
        )
    );
};
