import { Game } from '../../game/game';
import { RoomInAPuzzle } from './RoomInAPuzzle';

export function puzzleRoomAtPosition(
    game: Game,
    row: number,
    column: number
): RoomInAPuzzle | undefined {
    try {
        return game.get(`room-in-a-puzzle-${row}-${column}`) as RoomInAPuzzle;
    } catch {
        return undefined;
    }
}
