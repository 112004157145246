import { Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { SetTo, Spin } from '../../abilities';
import { NUMBERS } from '../../constants';

interface SundialState extends ItemState {
    setting: number;
    activeSetting: number;
}

export class Sundial extends Item<SundialState> {
    static spec(): EntitySpec<Sundial> {
        return {
            ref: 'sundial',
            constructor: Sundial,
            initial: {
                setting: 1,
                activeSetting: 1,
            },
            nouns: [
                new Noun('sundial'),
                new Noun('sundials', { plural: true }),
                new Noun('dial'),
                new Noun('dials', { plural: true }),
                new Noun('indicator'),
                new Noun('indicators', { plural: true }),
            ],
            adjectives: [],
            handlers: [setDial, spinSundial],
        };
    }

    ref() {
        return Sundial.spec().ref;
    }

    name(): string {
        return 'sundial';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Sundial.spec().nouns;
    }

    adjectives() {
        return Sundial.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    setting() {
        return this.state.setting;
    }

    setSetting(setting: number) {
        this.state.setting = setting;
    }

    activeSetting() {
        return this.state.activeSetting;
    }

    setActiveSetting(activeSetting: number) {
        this.state.activeSetting = activeSetting;
    }
}

const setDial: Handler = async ({ action, runner }) => {
    if (action.is(SetTo) && action.item.is(Sundial)) {
        if (action.setting) {
            const setting = getSetting(action.setting);
            if (!setting.valid) {
                await runner.doOutput(setting.reason);
            } else {
                action.item.setSetting(setting.value);
                await runner.doOutput(
                    `The dial now points to '${NUMBERS[setting.value]}'.`
                );
            }
        } else {
            await runner.doOutput('You must specify what to set the dial to.');
        }
        return Action.complete();
    }
};

const spinSundial: Handler = async ({ action, runner, game }) => {
    if (action.is(Spin) && action.item?.is(Sundial)) {
        const setting = game.choiceOf([1, 2, 3, 4, 5, 6, 7, 8]);
        action.item.setSetting(setting);
        await runner.doOutput(
            `The dial spins and comes to a stop pointing at '${NUMBERS[setting]}'.`
        );
        return Action.complete();
    }
};

function getSetting(
    setting: string
): { valid: true; value: number } | { valid: false; reason: string } {
    let numeric;
    if (setting.match(/-?[0-9]+/)) {
        numeric = parseInt(setting, 10);
    } else {
        const index = NUMBERS.findIndex(
            (number) => number === setting.toLowerCase()
        );
        numeric = index === -1 ? undefined : index;
    }
    if (numeric === undefined) {
        return {
            valid: false,
            reason: 'The dial face only contains numbers.',
        };
    }
    if (numeric < 1) {
        return { valid: false, reason: 'The sundial does not go that low.' };
    }
    if (numeric > 8) {
        return { valid: false, reason: 'The sundial does not go that high.' };
    }
    return { valid: true, value: numeric };
}
