import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Troll } from '../../actors';
import { EastWestPassage } from '../EastWestPassage';
import { Maze1 } from '../Maze1';
import { Cellar } from '../Cellar';
import { NorthSouthCrawlway } from '../NorthSouthCrawlway';

export class TrollRoom extends Room {
    static spec(): EntitySpec<TrollRoom> {
        return {
            ref: 'troll-room',
            constructor: TrollRoom,
            initial: {
                contents: [Troll.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return TrollRoom.spec().ref;
    }

    name(): string {
        return 'The Troll Room';
    }

    description(): string {
        return (
            'This is a small room with passages off in all directions. ' +
            'Bloodstains and deep scratches (perhaps made by an axe) mar the walls.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        const trollCondition = () => {
            const troll = this.game.ent(Troll);
            return !troll.isConscious() || !troll.isAlive();
        };
        const trollMessage = 'The troll fends you off with a menacing gesture.';
        return [
            new Passage({
                via: Direction.West,
                to: Cellar.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                to: Maze1.spec().ref,
                condition: trollCondition,
                message: trollMessage,
            }),
            new Passage({
                via: Direction.East,
                to: NorthSouthCrawlway.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                to: EastWestPassage.spec().ref,
                condition: trollCondition,
                message: trollMessage,
            }),
            // TODO more exits
        ];
    }
}
