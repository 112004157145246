import { Noun, Adjective } from '../../../parse';
import { makeTreasure, makeTakeable, makeFlammable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Extinguish } from '../../abilities';

export class Torch extends makeFlammable(makeTreasure(makeTakeable(Item))) {
    static spec(): EntitySpec<Torch> {
        return {
            ref: 'torch',
            constructor: Torch,
            initial: {
                isAflame: true,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('torch'),
                new Noun('torches', { plural: true }),
                new Noun('ivory', { collective: true }),
            ],
            adjectives: [
                new Adjective('ivory'),
                new Adjective('burned-out'),
                new Adjective('burned out'),
                new Adjective('flaming'),
            ],
            handlers: [extinguishTorch],
        };
    }

    scoreOnTake() {
        return 14;
    }

    scoreInCase() {
        return 6;
    }

    ref() {
        return Torch.spec().ref;
    }

    name() {
        return this.isAflame() ? 'torch' : 'burned-out ivory torch';
    }

    description() {
        return this.isAflame()
            ? 'There is an ivory torch here.'
            : 'There is a burned out ivory torch here.';
    }

    initialDescription() {
        return 'Sitting on the pedestal is a flaming torch, made of ivory.';
    }

    size() {
        return 20;
    }

    nouns(): Noun[] {
        return Torch.spec().nouns;
    }

    adjectives(): Adjective[] {
        const adjectives = [new Adjective('ivory')];
        if (this.isAflame()) {
            adjectives.push(new Adjective('flaming'));
        } else {
            adjectives.push(
                new Adjective('burned-out'),
                new Adjective('burned out')
            );
        }
        return adjectives;
    }
}

const extinguishTorch: Handler = async ({ action, runner }) => {
    if (
        action.is(Extinguish) &&
        action.item.is(Torch) &&
        action.item.isAflame()
    ) {
        await runner.doOutput(
            'You burn your hand as you attempt to extinguish the flame.'
        );
        return Action.complete();
    }
};
