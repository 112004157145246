import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler, Reference } from '../../game';
import { Tie, Untie } from '../../abilities';
import { Hook1 } from '../Hook1';
import { Hook2 } from '../Hook2';
import { Balloon } from '../Balloon';

interface BraidedWireState extends ItemState {
    fastenedItem: Reference | undefined;
}

export class BraidedWire extends Item<BraidedWireState> {
    static spec(): EntitySpec<BraidedWire> {
        return {
            ref: 'braided-wire',
            constructor: BraidedWire,
            initial: {
                fastenedItem: undefined,
            },
            nouns: [
                new Noun('wire', { collective: true }),
                new Noun('wires', { plural: true }),
                new Noun('rope'),
                new Noun('ropes', { plural: true }),
                new Noun('piece of wire'),
                new Noun('pieces of wire', { plural: true }),
            ],
            adjectives: [new Adjective('braided')],
            handlers: [tieWire, untieWire],
        };
    }

    ref() {
        return BraidedWire.spec().ref;
    }

    name(): string {
        return 'braided wire';
    }

    description(): string {
        return '';
    }

    nouns() {
        return BraidedWire.spec().nouns;
    }

    adjectives() {
        return BraidedWire.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const tieWire: Handler = async ({ action, runner, game }) => {
    if (action.is(Tie) && action.item.is(BraidedWire)) {
        let { fixture } = action;
        const hook1 = game.ent(Hook1);
        const hook2 = game.ent(Hook2);
        if (!fixture) {
            if (game.here().contains(hook1)) {
                fixture = hook1;
            } else if (game.here().contains(hook2)) {
                fixture = hook2;
            } else {
                return Action.incomplete();
            }
        }
        if (fixture.is(Hook1) || fixture.is(Hook2)) {
            fixture.setIsAnchoring(true);
            await runner.doOutput('The balloon is fastened to the hook.');
            return Action.complete();
        }
    }
};

const untieWire: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Untie) &&
        action.item.is(BraidedWire) &&
        (!action.fixture ||
            action.fixture.is(Hook1) ||
            action.fixture.is(Hook2))
    ) {
        const hook1 = game.ent(Hook1);
        const hook2 = game.ent(Hook2);
        if (action.fixture && !action.fixture.isAnchoring()) {
            await runner.doOutput('The wire is not tied to anything.');
        } else if (hook1.isAnchoring() || hook2.isAnchoring()) {
            const balloon = game.ent(Balloon);
            balloon.setTimeUntilNextFly(4);
            hook1.setIsAnchoring(false);
            hook2.setIsAnchoring(false);
            await runner.doOutput('The wire falls off of the hook.');
        }
        return Action.complete();
    }
};
