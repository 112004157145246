import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Brush, SpecialJigsUp } from '../../abilities';
import { Gunk } from '../Gunk';

export class Teeth extends Item {
    static spec(): EntitySpec<Teeth> {
        return {
            ref: 'teeth',
            constructor: Teeth,
            initial: {},
            nouns: [
                new Noun('teeth', { collective: true }),
                new Noun('pearly whites', { collective: true }),
                new Noun('set of teeth'),
            ],
            adjectives: [
                // TODO I know this isn't really an adjective...
                new Adjective('my'),
                new Adjective('my own'),
            ],
            handlers: [brushTeeth],
        };
    }

    isTool(): boolean {
        return true;
    }

    ref() {
        return Teeth.spec().ref;
    }

    name(): string {
        return 'set of teeth';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Teeth.spec().nouns;
    }

    adjectives() {
        return Teeth.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const brushTeeth: Handler = async ({ action, game, actor, runner }) => {
    if (action.is(Brush) && action.item.is(Teeth)) {
        if (!action.tool) {
            await runner.doOutput(
                "Dental hygiene is highly recommended, but I'm not sure what " +
                    'you want to brush them with.'
            );
        } else if (action.tool.is(Gunk)) {
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message:
                        'Well, you seem to have been brushing your teeth with ' +
                        'some sort of glue. As a result, your mouth gets glued ' +
                        'together (with your nose) and you die of respiratory ' +
                        'failure.',
                })
            );
        } else {
            // TODO maybe set game.yesNo to yuk
            await runner.doOutput(`A nice idea, but with ${action.tool.an()}?`);
        }
        return Action.complete();
    }
};
