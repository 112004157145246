import { Examinable, Item } from '../../items/Item';
import { Constructor } from './Constructor';

export function makeExaminable<TBase extends Constructor<Item>>(Base: TBase) {
    abstract class C extends Base implements Examinable {
        abstract examineText(): string;

        isExaminable(): this is Examinable {
            return true;
        }
    }
    return C;
}
