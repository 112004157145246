import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { SpecialJigsUp, Wave } from '../../abilities';
import { AragainFalls, EndOfTheRainbow, OnTheRainbow } from '../../rooms';
import { Rainbow } from '../Rainbow';
import { PotOfGold } from '..';

export class Stick extends makeTakeable(Item) {
    static spec(): EntitySpec<Stick> {
        return {
            ref: 'stick',
            constructor: Stick,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('stick'),
                new Noun('sticks', { plural: true }),
                new Noun('wand'),
                new Noun('wands', { plural: true }),
                new Noun('twig'),
                new Noun('twigs', { plural: true }),
            ],
            adjectives: [
                new Adjective('sharp'),
                new Adjective('broken'),
                new Adjective('magic'),
            ],
            handlers: [waveStick],
        };
    }

    ref() {
        return Stick.spec().ref;
    }

    name() {
        return 'sharp broken stick';
    }

    isTool() {
        return true;
    }

    description() {
        return 'There is a sharp broken stick here.';
    }

    initialDescription() {
        return 'A sharp stick, which appears to have been broken at one end, is here.';
    }

    size() {
        return 3;
    }

    nouns(): Noun[] {
        return Stick.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Stick.spec().adjectives;
    }
}

const waveStick: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Wave) && action.item.is(Stick)) {
        const room = actor?.location();
        const rainbow = game.ent(Rainbow);
        if (room?.like(AragainFalls) || room?.like(EndOfTheRainbow)) {
            rainbow.setIsSolid(!rainbow.isSolid());
            if (rainbow.isSolid()) {
                game.ent(PotOfGold).setIsHidden(false);
                await runner.doOutput(
                    'Suddenly, the rainbow appears to become solid and, ' +
                        'I venture, walkable (I think the giveaway was the stairs and bannister).'
                );
            } else {
                await runner.doOutput(
                    'The rainbow seems to have become somewhat run-of-the-mill.'
                );
            }
        } else if (room?.is(OnTheRainbow)) {
            rainbow.setIsSolid(false);
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message:
                        'The structural integrity of the rainbow seems to have left it, ' +
                        'leaving you about 450 feet in the air, supported by water vapor.',
                })
            );
        } else {
            await runner.doOutput('Very good.');
        }
        return Action.complete();
    }
};

// TODO maybe add weapon remarks for stick?
