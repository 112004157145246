import { Actor } from '../index';
import { Adjective, Noun } from '../../../parse';

export class Bat extends Actor {
    static spec() {
        return {
            ref: 'bat',
            constructor: Bat,
            initial: {
                inventory: [],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: 0,
                isEngrossed: false,
                hitPoints: 1,
            },
            nouns: [
                new Noun('bat'),
                new Noun('vampire bat'),
                new Noun('bats', { plural: true }),
                new Noun('vampire bats', { plural: true }),
            ],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Bat.spec().ref;
    }

    name(): string {
        return 'vampire bat';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    isVillain(): boolean {
        return false;
    }

    isVictim(): boolean {
        return false;
    }

    nouns(): Noun[] {
        return Bat.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Bat.spec().adjectives;
    }

    isReadyToFight(): boolean {
        return false;
    }
}

// TODO interactions with bat?
