import { Verb, Parse, Parser, Preposition, Value } from '../../../parse';
import { Player } from '../../actors';
import { YUKS } from '../../constants';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { TakeUnresolved, takeUnresolvedHandler } from './TakeUnresolved';

export class Take extends Action {
    id = '~take';

    item: Entity;

    silent: boolean;

    container: Entity | undefined;

    sizeMultiplier: number;

    constructor({
        item,
        silent = false,
        container,
        sizeMultiplier = 1,
    }: {
        item: Entity;
        silent?: boolean;
        container: Entity | undefined;
        sizeMultiplier?: number;
    }) {
        super();
        this.item = item;
        this.silent = silent;
        this.container = container;
        this.sizeMultiplier = sizeMultiplier;
    }

    static ability(): Ability {
        return {
            handlers: [takeHandler, takeUnresolvedHandler],
            parser,
            verbs: [
                new Verb('take'),
                new Verb('pick up'),
                new Verb('get'),
                new Verb('pick'),
            ],
            prepositions: [new Preposition('up')],
        };
    }
}

export const takeHandler: Handler = async ({ action, runner, game, actor }) => {
    if (!action.is(Take) || !actor) return undefined;
    const { item, container } = action;
    if (actor.isActor() && actor.contains(item)) {
        if (actor.is(Player)) {
            !action.silent &&
                (await runner.doOutput(`You already have ${item.the()}.`));
        } else {
            !action.silent &&
                (await runner.doOutput(
                    `${actor.The()} already has ${item.the()}.`
                ));
        }
    } else if (item.isItem() && item.isTakeable()) {
        const actualContainer = action.item.container();
        if (container && !actualContainer?.isEqualTo(container)) {
            !action.silent &&
                (await runner.doOutput(`It isn't in ${container.the()}.`));
        } else if (
            actualContainer?.isRoom() ||
            (actualContainer?.isItem() && actualContainer.isContainer())
        ) {
            if (actor.remainingLoad() >= item.size() * action.sizeMultiplier) {
                if (!item.hasBeenTaken()) {
                    game.state.score += item.scoreOnTake();
                    item.state.hasBeenTaken = true;
                }
                item.moveTo(actor);
                !action.silent && (await runner.doOutput('Taken.'));
            } else if (actor.is(Player)) {
                !action.silent &&
                    (await runner.doOutput(
                        'Your load is too heavy. You will have to leave something behind.'
                    ));
            } else {
                !action.silent &&
                    (await runner.doOutput(
                        `${actor.The()}'s load is too heavy and will have to leave something behind.`
                    ));
            }
        }
    } else {
        !action.silent && (await runner.doOutput(game.choiceOf(YUKS)));
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, TakeUnresolved> => {
    const object = Parse.target(game.lexicon);
    return Parse.either(
        Parse.any(
            Parse.word('take'),
            Parse.phrase('pick up'),
            Parse.word('get')
        )
            .then(Parse.whitespace())
            .beforeX(object)
            .chain((items) =>
                Parse.option(
                    Parse.whitespace()
                        .before(
                            Parse.any(
                                Parse.word('from'),
                                Parse.phrase('from inside'),
                                Parse.phrase('from within'),
                                Parse.phrase('out of'),
                                Parse.phrase('out from')
                            )
                        )
                        .before(Parse.whitespace())
                        .beforeX(object)
                ).map((container) => new TakeUnresolved({ items, container }))
            ),
        Parse.word('pick')
            .then(Parse.whitespace())
            // TODO crs come up with a better name for beforeX and afterX
            .beforeX(object)
            .before(Parse.whitespace().then(Parse.word('up')))
            .map((items) => new TakeUnresolved({ items, container: undefined }))
    );
};
