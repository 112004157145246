import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class WoodenBar extends Item {
    static spec(): EntitySpec<WoodenBar> {
        return {
            ref: 'wooden-bar',
            constructor: WoodenBar,
            initial: {},
            nouns: [new Noun('bar'), new Noun('bars', { plural: true })],
            adjectives: [new Adjective('wooden'), new Adjective('wood')],
            handlers: [],
        };
    }

    ref() {
        return WoodenBar.spec().ref;
    }

    name(): string {
        return 'wooden bar';
    }

    description(): string {
        return '';
    }

    nouns() {
        return WoodenBar.spec().nouns;
    }

    adjectives() {
        return WoodenBar.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
