import { MineMachineRoom, Passage, Room, TimberRoom } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Action, Entity, EntitySpec, Handler } from '../../game';
import { Basket } from '../../items';
import { Player } from '../../actors';
import { SpecialEnter } from '../../abilities';
import { RoomState } from '../Room';

interface LowerShaftState extends RoomState {
    hasBeenLitBefore: boolean;
}

export class LowerShaft extends Room<LowerShaftState> {
    static spec(): EntitySpec<LowerShaft> {
        return {
            ref: 'lower-shaft',
            constructor: LowerShaft,
            initial: {
                hasBeenLitBefore: false,
                contents: [
                    // Chain?
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [enterLowerShaft],
        };
    }

    miscellaneousScore() {
        return 10;
    }

    ref() {
        return LowerShaft.spec().ref;
    }

    name(): string {
        return 'Lower Shaft';
    }

    description(): string {
        return (
            'This is a small square room which is at the ' +
            'bottom of a long shaft. To the east is a passageway ' +
            'and to the northeast a very narrow passage. In the shaft ' +
            'can be seen a heavy iron chain.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return true;
    }

    visibleObjects(): Entity[] {
        const basket = this.game.ent(Basket);
        return this.contains(basket) ? [] : [basket];
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.East,
                to: MineMachineRoom.spec().ref,
            }),
            new Passage({
                via: [Direction.Northeast, SpecialDirection.Out],
                condition: () => this.game.ent(Player).isEmptyHanded(),
                message: 'You cannot fit through this passage with that load.',
                to: TimberRoom.spec().ref,
            }),
            new Passage({
                via: Direction.Up,
                message: 'The chain is not climbable',
            }),
        ];
    }

    hasBeenLitBefore(): boolean {
        return this.state.hasBeenLitBefore;
    }

    setHasBeenLitBefore(hasBeenLitBefore: boolean) {
        this.state.hasBeenLitBefore = hasBeenLitBefore;
    }
}

// TODO double check that this is actually what's supposed to be happening?
const enterLowerShaft: Handler = async ({ action, game, actor, runner }) => {
    if (action.is(SpecialEnter) && action.room.is(LowerShaft)) {
        if (!actor?.isAlive()) {
            await runner.doOutput('You cannot enter in your condition.');
            return Action.complete();
        }

        if (!action.room.hasBeenLitBefore() && action.room.isLit()) {
            action.room.setHasBeenLitBefore(true);
            game.state.score += action.room.miscellaneousScore();
        }

        return Action.incomplete();
    }
};
