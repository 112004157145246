import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeContainer, makeFlammable, makeTakeable } from '../../game/Entity';
import { Action, EntitySpec, Handler, Reference } from '../../game';
import {
    Light,
    SpecialEnter,
    SpecialJigsUp,
    SpecialTimerTick,
} from '../../abilities';
import { Player } from '../../actors';
import {
    DustyRoom,
    MungedSource,
    Room,
    VolcanoBottom,
    VolcanoNearWideLedge,
    WideLedge,
} from '../../rooms';
import { Balloon } from '../Balloon';
import { Hook2 } from '../Hook2';
import { BalloonReceptacle } from '../BalloonReceptacle';

interface BrickState extends ItemState {
    explodedInRoom: Reference | undefined;
    timeSinceExplosion: number;
}

abstract class Base extends Item<BrickState> {}

export class Brick extends makeFlammable(makeTakeable(makeContainer(Base))) {
    static spec(): EntitySpec<Brick> {
        return {
            ref: 'brick',
            constructor: Brick,
            initial: {
                explodedInRoom: undefined,
                timeSinceExplosion: 0,
                isAflame: false,
                contents: [],
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('brick'),
                new Noun('bricks', { plural: true }),
                new Noun('clay'),
                new Noun('c4'),
                new Noun('explosive'),
            ],
            adjectives: [
                new Adjective('clay'),
                new Adjective('square'),
                new Adjective('plastic'),
            ],
            handlers: [burnBrick, waitForBoomCollapse],
        };
    }

    explodedInRoom() {
        const ref = this.state.explodedInRoom;
        if (ref === undefined) return undefined;
        return this.game.get(ref) as Room;
    }

    setExplodedInRoom(room: Room | undefined) {
        this.state.explodedInRoom = room && room.ref();
    }

    timeSinceExplosion() {
        return this.state.timeSinceExplosion;
    }

    setTimeSinceExplosion(timeUntilCollapse: number) {
        this.state.timeSinceExplosion = timeUntilCollapse;
    }

    ref() {
        return Brick.spec().ref;
    }

    name(): string {
        return 'brick';
    }

    description(): string {
        return 'There is a square brick here which feels like clay.';
    }

    totalCapacity(): number {
        return 2;
    }

    size(): number {
        return 9;
    }

    nouns() {
        return Brick.spec().nouns;
    }

    adjectives() {
        return Brick.spec().adjectives;
    }

    isFlammable() {
        return true;
    }

    // TODO crs
    canBeFastened() {
        return true;
    }
}

const burnBrick: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Light) &&
        action.item.is(Brick) &&
        action.tool?.isItem() &&
        action.tool.isFlammable() &&
        action.tool.isAflame()
    ) {
        action.item.moveTo(undefined);
        const location = game.ent(Player).location();
        location.state.isMunged = true;
        location.state.howMunged = MungedSource.Exploded;
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    "Now you've done it. It seems that the brick has other properties than weight, " +
                    'namely the ability to blow you to smithereens.',
            })
        );
        return Action.complete();
    }
};

const waitForBoomCollapse: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const brick = game.ent(Brick);
        const room = brick.explodedInRoom();
        if (room) {
            brick.setTimeSinceExplosion(brick.timeSinceExplosion() + 1);
            if (brick.timeSinceExplosion() === 5) {
                room.state.isMunged = true;
                room.state.howMunged = MungedSource.Exploded;
                if (room.isEqualTo(game.ent(Player).location())) {
                    let message;
                    if (room.isPartOfHouse()) {
                        message =
                            "The house shakes, and the ceiling of the room you're in collapses, turning you into a pancake.";
                    } else {
                        message =
                            'The room trembles and 50,000 pounds of rock fall on you, turning you into a pancake.';
                    }
                    await game.applyAction(
                        runner,
                        new SpecialJigsUp({ message })
                    );
                } else {
                    await runner.doOutput(
                        'You may recall that recent explosion. Well, probably as a result of that, ' +
                            'you hear an ominous rumbling, as if one of the rooms in the dungeon had collapsed.'
                    );
                }
            } else if (brick.timeSinceExplosion() === 7) {
                brick.setExplodedInRoom(undefined);
                if (room.is(DustyRoom)) {
                    const player = game.ent(Player);
                    const balloon = game.ent(Balloon);
                    const location = player.location();
                    if (location.is(WideLedge)) {
                        if (balloon.contains(player)) {
                            if (game.ent(Hook2).isAnchoring()) {
                                const bottom = game.ent(VolcanoBottom);
                                balloon.moveTo(bottom);
                                player.moveTo(bottom);
                                balloon.setIsMunged(true);
                                const tank = game.ent(BalloonReceptacle);
                                tank.setFuelRemaining(0);
                                await game.applyAction(
                                    runner,
                                    new SpecialJigsUp({
                                        message:
                                            'The ledge collapses, probably as a result of the explosion. A large chunk of it, which is attached to the hook, drags you down to the ground. Fatally.',
                                    })
                                );
                            } else {
                                await runner.doOutput(
                                    'The ledge collapses, leaving you with no place to land.'
                                );
                                balloon.setTimeUntilNextFly(4);
                                await game.applyAction(
                                    runner,
                                    new SpecialEnter({
                                        room: game.ent(VolcanoNearWideLedge),
                                    })
                                );
                            }
                        } else {
                            await game.applyAction(
                                runner,
                                new SpecialJigsUp({
                                    message:
                                        'The force of the explosion has caused the ledge to collapse belatedly.',
                                })
                            );
                        }
                    } else {
                        await runner.doOutput(
                            'The ledge collapses, giving you a narrow escape.'
                        );
                    }
                    const wideLedge = game.ent(WideLedge);
                    wideLedge.state.isMunged = true;
                    wideLedge.state.howMunged = MungedSource.LedgeCollapse;
                }
            }
        }
        return Action.incomplete();
    }
};
