import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Tree extends Item {
    static spec(): EntitySpec<Tree> {
        return {
            ref: 'tree',
            constructor: Tree,
            initial: {},
            nouns: [
                new Noun('tree'),
                new Noun('trees', { plural: true }),
                new Noun('branch'),
                new Noun('branches', { collective: true }),
            ],
            adjectives: [new Adjective('large')],
        };
    }

    ref() {
        return Tree.spec().ref;
    }

    name(): string {
        return 'large tree';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Tree.spec().nouns;
    }

    adjectives() {
        return Tree.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    canBeClimbed() {
        return true;
    }
}

// TODO climb tree
