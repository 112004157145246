import { listify, withDefault } from '../utils';
import { Game } from './game';
import { Runner } from './Runner';
import { Group, Obj, Pronoun, Target } from '../../parse';
import { Action } from './Action';
import { Actor } from '../actors';
import { ALL_WORDS } from '../constants';
import { Entity } from '.';

export class ResolveResult {
    items: Entity[] | undefined;

    plural: boolean;

    illegalGroup: boolean;

    nothingFound: boolean;

    cantSeeIt: Entity | undefined;

    cantSeeThem: Entity[] | undefined;

    unknownPronoun: Pronoun | undefined;

    missingObject: Obj | undefined;

    ambiguousObject:
        | { attempt: Obj; options: Entity[]; except: boolean }
        | undefined;

    constructor({
        items,
        plural,
        illegalGroup,
        nothingFound,
        cantSeeIt,
        cantSeeThem,
        unknownPronoun,
        missingObject,
        ambiguousObject,
    }: {
        items?: Entity[];
        plural?: boolean;
        illegalGroup?: boolean;
        nothingFound?: boolean;
        cantSeeIt?: Entity;
        cantSeeThem?: Entity[];
        unknownPronoun?: Pronoun;
        missingObject?: Obj;
        ambiguousObject?: { attempt: Obj; options: Entity[]; except: boolean };
    }) {
        this.items = items;
        this.plural = withDefault(plural, false);
        this.illegalGroup = withDefault(illegalGroup, false);
        this.nothingFound = withDefault(nothingFound, false);
        this.cantSeeIt = cantSeeIt;
        this.cantSeeThem = cantSeeThem;
        this.unknownPronoun = unknownPronoun;
        this.missingObject = missingObject;
        this.ambiguousObject = ambiguousObject;
    }

    static illegalGroup() {
        return new ResolveResult({ illegalGroup: true });
    }

    static nothingFound() {
        return new ResolveResult({ nothingFound: true });
    }

    static cantSeeIt(it: Entity) {
        return new ResolveResult({ cantSeeIt: it });
    }

    static cantSeeThem(them: Entity[]) {
        return new ResolveResult({ cantSeeThem: them });
    }

    static unknownPronoun(pronoun: Pronoun) {
        return new ResolveResult({ unknownPronoun: pronoun });
    }

    static missingObject(object: Obj) {
        return new ResolveResult({ missingObject: object });
    }

    static ambiguousObject(attempt: Obj, options: Entity[], except: boolean) {
        return new ResolveResult({
            ambiguousObject: { attempt, options, except },
        });
    }

    get message() {
        if (this.illegalGroup) {
            return "I can't do that with everything at once!";
        }
        if (this.nothingFound) {
            return "I couldn't find anything.";
        }
        if (this.cantSeeIt) {
            return `You can't see "it" (the ${this.cantSeeIt}) at the moment.`;
        }
        if (this.cantSeeThem) {
            return `You can't see the ${this.cantSeeThem[0]} at the moment.`;
        }
        if (this.unknownPronoun) {
            return `I'm not sure what "${this.unknownPronoun.value}" refers to.`;
        }
        if (this.missingObject) {
            return `I can't see any ${this.missingObject.description} here.`;
        }
        if (this.ambiguousObject) {
            // TODO crs we can actually remove this message? Not needed.
            return `Which ${this.ambiguousObject.attempt.description}?`;
        }
        return undefined;
    }

    get item() {
        return this.items ? this.items[0] : undefined;
    }
}

function disambiguate(entities: Entity[]) {
    // TODO crs implement this for real...
    return entities.map((entity) => entity.the().toLowerCase());
}

export async function resolve(
    game: Game,
    runner: Runner,
    actor: Actor,
    {
        target,
        missing,
        absent,
        allowGroups = false,
        condition = () => true,
        onlyVisible = true,
        silent = false,
        partial,
        ambiguous,
    }: {
        target: Target | undefined;
        missing?: () => string;
        allowGroups?: boolean;
        onlyVisible?: boolean;
        condition?: (item: Entity) => boolean;
        absent?: (item: string) => string;
        silent?: boolean;
        partial?: (target: Target) => Action;
        ambiguous?: (
            description: string,
            options: string,
            not: string
        ) => string;
    }
) {
    if (target === undefined) {
        if (missing !== undefined) {
            game.partial = partial;
            await runner.doOutput(missing());
            return;
        }
        return;
    }

    const result = await resolveTarget(game, runner, actor, {
        target,
        allowGroups,
        condition,
        silent,
        onlyVisible,
        partial,
    });
    if (result?.items) {
        return {
            items: result.items,
            item: result.item,
            plural: result.plural,
        };
    }
    if (result?.ambiguousObject) {
        // TODO crs set "both" and "all of them"
        !silent &&
            (await runner.doOutput(
                ambiguous
                    ? ambiguous(
                          result?.ambiguousObject.attempt.description,
                          listify(
                              disambiguate(result.ambiguousObject.options),
                              'or'
                          ),
                          result?.ambiguousObject.except ? ' not' : ''
                      )
                    : 'Which one?'
            ));
    } else if (absent && result.missingObject) {
        const { description } = result.missingObject;
        !silent && (await runner.doOutput(absent(description)));
    } else if (result?.message) {
        !silent && (await runner.doOutput(result.message));
    }
}

async function resolveTarget(
    game: Game,
    runner: Runner,
    actor: Actor,
    {
        target,
        allowGroups,
        condition = () => true,
        silent = false,
        onlyVisible = true,
        partial,
        isForExclude = false,
    }: {
        target: Target;
        allowGroups: boolean;
        condition?: (item: Entity) => boolean;
        onlyVisible?: boolean;
        silent?: boolean;
        partial?: (target: Target) => Action;
        isForExclude?: boolean;
    }
): Promise<ResolveResult> {
    let items: Entity[] = [];
    let plural = target.items.length > 1;

    if (plural && !allowGroups) {
        return ResolveResult.illegalGroup();
    }

    for (let index = 0; index < target.items.length; index++) {
        const item = target.items[index];
        const innerPartial =
            partial &&
            ((t: Target) =>
                partial(
                    new Target({
                        items: [
                            ...target.items.slice(0, index),
                            ...t.items,
                            ...target.items.slice(index + 1),
                        ],
                    })
                ));
        if (item instanceof Obj) {
            const result = await resolveObject(game, runner, actor, {
                object: item,
                allowMultiple: allowGroups,
                onlyVisible,
                partial: innerPartial,
                isForExclude,
                condition,
            });

            if (!result?.items) {
                return result;
            }

            if (
                (item.noun.plural || item.noun.collective) &&
                result.items.length > 1
            ) {
                plural = true;
            }

            items = items.concat(result.items);
        } else if (item instanceof Pronoun) {
            const result = await resolvePronoun(game, runner, actor, {
                pronoun: item,
            });

            if (item.plural) {
                plural = true;
            }

            if (!result?.items) {
                return result;
            }
            if (result.plural) {
                items = items.concat(
                    result.items.filter((item) => condition(item))
                );
            } else if (result.item) {
                items.push(result.item);
            }
        } /* if (item instanceof Group) */ else {
            plural = true;
            const includes = await resolveTarget(game, runner, actor, {
                target: new Target({ items: [item.include] }),
                allowGroups: true,
                partial:
                    innerPartial &&
                    ((t: Target) =>
                        innerPartial(
                            new Target({
                                items: [
                                    new Group({
                                        include: t.items[0],
                                        exclude: item.exclude,
                                    }),
                                ],
                            })
                        )),
            });
            if (!includes?.items) {
                return includes;
            }
            items.push(...includes.items);
            if (item.exclude) {
                const excludes = await resolveTarget(game, runner, actor, {
                    target: new Target({ items: item.exclude }),
                    allowGroups: true,
                    partial:
                        innerPartial &&
                        ((t: Target) =>
                            innerPartial(
                                new Target({
                                    items: [
                                        new Group({
                                            include: item.include,
                                            exclude: t.items,
                                        }),
                                    ],
                                })
                            )),
                    isForExclude: true,
                });
                if (!excludes?.items) {
                    return excludes;
                }
                items = includes.items.filter(
                    (item) =>
                        !excludes.items?.some(
                            (excluded) => excluded.ref === item.ref
                        )
                );
            }
            items = items.filter((item) => condition(item));
        }
    }

    // Deduplicate items
    items = items.filter(
        (item, index) =>
            items.findIndex((other) => other.isEqualTo(item)) === index
    );

    if (plural && !allowGroups) {
        return ResolveResult.illegalGroup();
    }

    if (items.length === 0) {
        return ResolveResult.nothingFound();
    }

    if (plural) {
        game.state.them = items.map((item) => item.ref());
    } else {
        game.state.it = items[0].ref();
    }

    return new ResolveResult({ items, plural });
}

async function resolveObject(
    game: Game,
    runner: Runner,
    actor: Actor,
    {
        object,
        allowMultiple,
        partial,
        onlyVisible = true,
        isForExclude = false,
        condition = () => true,
    }: {
        object: Obj;
        allowMultiple: boolean;
        partial?: (target: Target) => Action;
        onlyVisible?: boolean;
        isForExclude?: boolean;
        condition?: (item: Entity) => boolean;
    }
): Promise<ResolveResult> {
    const matches = [];
    const options = onlyVisible ? actor.visibleEntities() : game.allEntities();
    for (const item of options) {
        const nouns = [...item.nouns(), ...item.sharedNouns()];
        if (
            nouns.some((noun) => noun.isEqual(object.noun)) &&
            object.adjectives.every((adjective) =>
                item.adjectives().some((other) => other.isEqual(adjective))
            )
        ) {
            matches.push(item);
        }
    }

    if (matches.length === 0) {
        return ResolveResult.missingObject(object);
    }

    if (matches.length > 1) {
        if (allowMultiple && object.noun.plural) {
            game.state.them = matches.map((item) => item.ref());
            return new ResolveResult({ items: matches, plural: true });
        }
        game.partial = partial;
        const conditionalMatches = matches.filter(condition);

        // TODO crs not sure if this is really desirable -- "put thing in thing" works...
        if (conditionalMatches.length === 1 && !object.noun.plural) {
            return new ResolveResult({
                items: conditionalMatches,
                plural: false,
            });
        }
        return ResolveResult.ambiguousObject(
            object,
            conditionalMatches.length > 1 ? conditionalMatches : matches,
            isForExclude
        );
    }
    game.state.it = matches[0].ref();
    return new ResolveResult({ items: matches, plural: false });
}

async function resolvePronoun(
    game: Game,
    runner: Runner,
    actor: Actor,
    { pronoun }: { pronoun: Pronoun }
): Promise<ResolveResult> {
    const visible = actor.visibleEntities();
    if (
        pronoun.isEqual(new Pronoun('it')) ||
        pronoun.isEqual(new Pronoun('that'))
    ) {
        const { it } = game.state;
        if (it !== null) {
            if (visible.some((visibleItem) => visibleItem.ref() === it)) {
                return new ResolveResult({
                    items: [game.get(it)],
                    plural: false,
                });
            }
            return ResolveResult.cantSeeIt(game.get(it));
        }
    } else if (pronoun.isEqual(new Pronoun('them', { plural: true }))) {
        const { them } = game.state;
        if (them !== null) {
            const cantSee = them.filter(
                (itemRef) =>
                    !visible.some(
                        (visibleItem) => visibleItem.ref() === itemRef
                    )
            );
            if (cantSee.length) {
                return ResolveResult.cantSeeThem(
                    cantSee.map((ref) => game.get(ref))
                );
            }
            return new ResolveResult({
                items: them.map((ref) => game.get(ref)),
                plural: true,
            });
        }
    } else if (ALL_WORDS.includes(pronoun)) {
        return new ResolveResult({ items: visible, plural: true });
    }

    return ResolveResult.unknownPronoun(pronoun);
}
