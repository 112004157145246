import { Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Melt, SpecialJigsUp, Throw } from '../../abilities';
import { Torch } from '../Torch';
import { StreamView } from '../../rooms';

interface GlacierState extends ItemState {
    isPartiallyMelted: boolean;
}

export class Glacier extends Item<GlacierState> {
    static spec(): EntitySpec<Glacier> {
        return {
            ref: 'glacier',
            constructor: Glacier,
            initial: {
                isPartiallyMelted: false,
            },
            nouns: [
                new Noun('glacier'),
                new Noun('glaciers', { plural: true }),
                new Noun('mass of ice'),
                new Noun('mass'),
                new Noun('ice', { collective: true }),
            ],
            adjectives: [],
            handlers: [throwTorchAtGlacier, meltGlacier],
        };
    }

    ref() {
        return Glacier.spec().ref;
    }

    name(): string {
        return 'glacier';
    }

    description(): string {
        return '';
    }

    isPartiallyMelted() {
        return this.state.isPartiallyMelted;
    }

    setIsPartiallyMelted(isPartiallyMelted: boolean) {
        this.state.isPartiallyMelted = isPartiallyMelted;
    }

    nouns() {
        return Glacier.spec().nouns;
    }

    adjectives() {
        return Glacier.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const throwTorchAtGlacier: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (action.is(Throw) && action.enemy?.is(Glacier)) {
        if (action.item.is(Torch) && action.item.isAflame()) {
            action.item.moveTo(game.ent(StreamView));
            action.enemy?.moveTo(undefined);
            action.item.state.isAflame = false;
            await runner.doOutput(
                'The torch hits the glacier and explodes into a great ball of flame, ' +
                    'devouring the glacier. The water from the melting glacier rushes ' +
                    'downstream, carrying the torch with it. In the place of the glacier, ' +
                    'there is a passageway leading west.'
            );
            if (!actor?.location()?.isLit()) {
                await runner.doOutput(
                    'The melting glacier seems to have carried the torch away, leaving you in the dark.'
                );
            }
        } else {
            await runner.doOutput(
                'The glacier is unmoved by your ridiculous attempt.'
            );
        }
        return Action.complete();
    }
};

const meltGlacier: Handler = async ({ action, runner, game }) => {
    if (action.is(Melt) && action.item.is(Glacier)) {
        if (
            action.tool.isItem() &&
            action.tool.isFlammable() &&
            action.tool.isAflame()
        ) {
            action.tool.state.isAflame = false;
            action.item.state.isPartiallyMelted = true;
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message:
                        'Part of the glacier melts, drowning you under a torrent of water.',
                })
            );
        } else {
            await runner.doOutput(
                `You certainly won't melt it with ${action.tool.an()}.`
            );
        }
        return Action.complete();
    }
};
