import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Rub } from './Rub';
import { UnresolvedAction } from '../UnresolvedAction';

export class RubUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'rub';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'rub something';
        }
        return `rub ${targetDescription(game, this.item)}`;
    }
}

export const rubUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(RubUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new RubUnresolved({ item }),
        missing: () => 'Rub what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to rub, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    return await game.applyAction(runner, new Rub({ item }), actor);
};
