import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { LavaRoom } from '../LavaRoom';
import { Balloon } from '../../items';

export class VolcanoBottom extends Room {
    static spec(): EntitySpec<VolcanoBottom> {
        return {
            ref: 'volcano-bottom',
            constructor: VolcanoBottom,
            initial: {
                contents: [Balloon.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return VolcanoBottom.spec().ref;
    }

    name(): string {
        return 'Volcano Bottom';
    }

    description(): string {
        return (
            'You are at the bottom of a large dormant volcano. ' +
            'High above you light may be seen entering from the ' +
            'cone of the volcano. The only exit here is to the north.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: LavaRoom.spec().ref }),
            // new Passage({
            //     via: SpecialDirection.FlyUp,
            //     to: VolcanoCore.spec().ref,
            // }),
        ];
    }
}
