import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push } from '../../abilities';
import { buttonZap } from '../SquareButton/SquareButton';
import { Player } from '../../actors';
import { RoundRoom } from '../../rooms';
import { ViolinCase } from '../ViolinCase';

export class TriangularButton extends Item {
    static spec(): EntitySpec<TriangularButton> {
        return {
            ref: 'triangular-button',
            constructor: TriangularButton,
            initial: {},
            nouns: [
                new Noun('button'),
                new Noun('buttons', { plural: true }),
                new Noun('switch'),
                new Noun('switches', { plural: true }),
            ],
            adjectives: [
                new Adjective('triangular'),
                new Adjective('triangle'),
            ],
            handlers: [pushTriangularButton],
        };
    }

    ref() {
        return TriangularButton.spec().ref;
    }

    name(): string {
        return 'triangular button';
    }

    description(): string {
        return '';
    }

    nouns() {
        return TriangularButton.spec().nouns;
    }

    adjectives() {
        return TriangularButton.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushTriangularButton: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (action.is(Push) && action.item.is(TriangularButton)) {
        if (actor?.is(Player)) {
            return buttonZap(game, runner, actor);
        }
        const roundRoom = game.ent(RoundRoom);
        roundRoom.state.isMagnetic = !roundRoom.state.isMagnetic;
        const violinCase = game.ent(ViolinCase);
        if (roundRoom.contains(violinCase)) {
            violinCase.state.isHidden = !violinCase.state.isHidden;
            await runner.doOutput('A dull thump is heard in the distance.');
        } else {
            await runner.doOutput('Click.');
        }

        return Action.complete();
    }
};
