import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { SpinUnresolved, spinUnresolvedHandler } from './SpinUnresolved';
import { WHEEE } from '../../constants';

export class Spin extends Action {
    id = '~spin';

    item: Entity | undefined;

    constructor({ item }: { item: Entity | undefined }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [spinHandler, spinUnresolvedHandler],
            parser,
            verbs: [new Verb('spin')],
            prepositions: [],
        };
    }
}

export const spinHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Spin)) return;
    if (action.item) {
        await runner.doOutput('That cannot be spun.');
    } else {
        await runner.doOutput(game.choiceOf(WHEEE));
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, SpinUnresolved> => {
    const spin = Parse.words(Spin.ability().verbs);
    const spinObject = spin.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // spin
        spin.into(new SpinUnresolved({ item: undefined })),
        // spin dial
        spinObject.map((item) => new SpinUnresolved({ item }))
    );
};
