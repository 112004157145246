import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { Entity, makeContainer } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { PutIn, Take } from '../../abilities';
import { Player } from '../../actors';
import { SmallRoom } from '../../rooms';

export class RedBeam extends makeContainer(Item) {
    static spec(): EntitySpec<RedBeam> {
        return {
            ref: 'red-beam',
            constructor: RedBeam,
            initial: {
                contents: [],
            },
            nouns: [
                new Noun('beam'),
                new Noun('beam of light'),
                new Noun('beams', { plural: true }),
                new Noun('beams of light', { plural: true }),
            ],
            adjectives: [new Adjective('red')],
            handlers: [takeBeam, putInBeam],
        };
    }

    ref() {
        return RedBeam.spec().ref;
    }

    name(): string {
        return 'red beam of light';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed() {
        return false;
    }

    totalCapacity(): undefined {
        return undefined;
    }

    nouns() {
        return RedBeam.spec().nouns;
    }

    adjectives() {
        return RedBeam.spec().adjectives;
    }

    blockedBy(): Entity | undefined {
        return this.game
            .ent(SmallRoom)
            .contents()
            .find((item) => item.isItem() && item.isTakeable());
    }

    isBlocked() {
        return this.blockedBy() !== undefined;
    }
}

const takeBeam: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(RedBeam)) {
        await runner.doOutput(
            'No doubt you have a bottle of moonbeams as well.'
        );
        return Action.incomplete();
    }
};

// TODO original let you say "break beam with" Maybe I should add "block/interrupt beam with"
const putInBeam: Handler = async ({ action, runner, actor }) => {
    if (action.is(PutIn) && action.container.is(RedBeam) && actor?.is(Player)) {
        const room = actor.location();
        const { item } = action;
        if (room.contains(item)) {
            await runner.doOutput(`${item.The()} already breaks the beam.`);
        } else if (!actor?.hasItem(item)) {
            await runner.doOutput(
                `You can't break the beam with ${item.an()}.`
            );
        } else {
            item.moveTo(room);
            await runner.doOutput(
                `The beam is now interrupted by ${action.item.an()} lying on the floor.`
            );
        }
        return Action.incomplete();
    }
};
