import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { River4 } from '../River4';
import { WhiteCliffsBeach1 } from '../WhiteCliffsBeach1';
import { Boat, River, WhiteCliffs } from '../../items';

export class WhiteCliffsBeach2 extends Room {
    static spec(): EntitySpec<WhiteCliffsBeach2> {
        return {
            ref: 'white-cliffs-beach-2',
            constructor: WhiteCliffsBeach2,
            initial: {
                contents: [WhiteCliffs.spec().ref, River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return WhiteCliffsBeach2.spec().ref;
    }

    name(): string {
        return 'White Cliffs Beach';
    }

    description(): string {
        return 'You are on a rocky, narrow strip of beach beside the Cliffs. A narrow path leads north along the shore.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            // TODO it seems like when you go onto the beach your boat is supposed to deflate??
            new Passage({
                via: Direction.North,
                to: WhiteCliffsBeach1.spec().ref,
            }),
            new Passage({
                via: SpecialDirection.Launch,
                to: River4.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
