import { Verb, Parse, Parser, Value } from '../../../parse';
import { HELLOS } from '../../constants';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { HelloUnresolved, helloUnresolvedHandler } from './HelloUnresolved';

export class Hello extends Action {
    id = '~hello';

    person: Entity | undefined;

    constructor({ person }: { person: Entity | undefined }) {
        super();
        this.person = person;
    }

    static ability(): Ability {
        return {
            handlers: [helloHandler, helloUnresolvedHandler],
            parser,
            verbs: [new Verb('hello'), new Verb('skim')],
            prepositions: [],
        };
    }
}

export const helloHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Hello)) return undefined;
    const { person } = action;

    // TODO hello sailor handler?
    // TODO hello aviator

    if (person) {
        if (person.isActor()) {
            await runner.doOutput(
                `${person.The()} bows his head to you in greeting.`
            );
        } else {
            await runner.doOutput(
                `I think that only schizophrenics say 'Hello' to ${person.an()}.`
            );
        }
    } else {
        await runner.doOutput(game.choiceOf(HELLOS));
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, HelloUnresolved> =>
    Parse.any(
        Parse.any(
            Parse.word('hello'),
            Parse.word('hi'),
            Parse.word('greetings')
        ).into(undefined),
        Parse.any(Parse.word('hello'), Parse.word('greet'))
            .before(Parse.whitespace())
            .beforeX(Parse.target(game.lexicon)),
        Parse.any(Parse.phrase('say hello to'), Parse.phrase('say hi to'))
            .before(Parse.whitespace())
            .beforeX(Parse.target(game.lexicon))
    ).map((person) => new HelloUnresolved({ person }));
