import { LoudRoom, Passage, Room, SmallCave } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { AncientDeadEnd1 } from '../AncientDeadEnd1';
import { AncientDeadEnd2 } from '../AncientDeadEnd2';

export class AncientChasm extends Room {
    static spec(): EntitySpec<AncientChasm> {
        return {
            ref: 'ancient-chasm',
            constructor: AncientChasm,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return AncientChasm.spec().ref;
    }

    name(): string {
        return 'Ancient Chasm';
    }

    description(): string {
        return (
            'A chasm, evidently produced by an ancient river, ' +
            'runs through the cave here. Passages lead off in all directions.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: LoudRoom.spec().ref,
            }),
            new Passage({ via: Direction.East, to: SmallCave.spec().ref }),
            new Passage({
                via: Direction.North,
                to: AncientDeadEnd1.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: AncientDeadEnd2.spec().ref,
            }),
        ];
    }
}
