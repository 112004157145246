import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { CoalMine1 } from '../CoalMine1';
import { ShaftRoom } from '../ShaftRoom';
import { SmellyRoom } from '../SmellyRoom';

export class WoodenTunnel extends Room {
    static spec(): EntitySpec<WoodenTunnel> {
        return {
            ref: 'wooden-tunnel',
            constructor: WoodenTunnel,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return WoodenTunnel.spec().ref;
    }

    name(): string {
        return 'Wooden Tunnel';
    }

    description(): string {
        return (
            'This is a narrow tunnel with large wooden beams running ' +
            'across the ceiling and around the walls. A path from the south ' +
            'splits into paths running west and northeast.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: ShaftRoom.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: SmellyRoom.spec().ref,
            }),
            new Passage({
                via: Direction.Northeast,
                to: CoalMine1.spec().ref,
            }),
        ];
    }
}
