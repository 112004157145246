import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeTakeable } from '../../game/Entity';
import { EntitySpec } from '../../game';

export class BrokenLantern extends makeTakeable(Item) {
    static spec(): EntitySpec<BrokenLantern> {
        return {
            ref: 'broken-lantern',
            constructor: BrokenLantern,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('lamp'),
                new Noun('lamps', { plural: true }),
                new Noun('lantern'),
                new Noun('lanterns', { plural: true }),
            ],
            adjectives: [new Adjective('broken'), new Adjective('brass')],
            handlers: [],
        };
    }

    size(): number {
        return 10;
    }

    ref() {
        return BrokenLantern.spec().ref;
    }

    name(): string {
        return 'broken lamp';
    }

    description(): string {
        return 'There is a broken brass lantern here.';
    }

    nouns() {
        return BrokenLantern.spec().nouns;
    }

    adjectives() {
        return BrokenLantern.spec().adjectives;
    }
}
