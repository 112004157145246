import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Spin } from './Spin';
import { UnresolvedAction } from '../UnresolvedAction';

export class SpinUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'spin';

    item: Target | undefined;

    constructor({ item }: { item: Target | undefined }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'spin something';
        }
        return `spin ${targetDescription(game, this.item)}`;
    }
}

export const spinUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SpinUnresolved)) return;

    if (action.item) {
        const { item } = await game.resolve(runner, action.item, actor, {
            partial: (item) => new SpinUnresolved({ item }),
            missing: () => 'Spin what?',
            ambiguous: (desc, opt) =>
                `Which ${desc} would you like to spin, ${opt}?`,
            condition: (item) => item.isActor(),
        });

        if (item === undefined)
            return Action.complete({ withConsequence: false });

        if (!(await game.reach(runner, item, actor))) return Action.complete();

        return await game.applyAction(runner, new Spin({ item }), actor);
    }
    return await game.applyAction(runner, new Spin({ item: undefined }), actor);
};
