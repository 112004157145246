export class Value {
    value: string;

    constructor(value: string) {
        this.value = value;
    }

    isEqual(other: Value) {
        return (
            this.constructor === other.constructor && this.value === other.value
        );
    }
}
