import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { PutUnder } from './PutUnder';
import { UnresolvedAction } from '../UnresolvedAction';

export class PutUnderUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'put-under';

    item: Target | undefined;

    cover: Target | undefined;

    constructor({
        item,
        cover,
    }: {
        item: Target | undefined;
        cover: Target | undefined;
    }) {
        super();
        this.item = item;
        this.cover = cover;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'put something';
        }
        if (this.cover === undefined) {
            return `put ${targetDescription(game, this.item)} under something`;
        }
        return `put ${targetDescription(
            game,
            this.item
        )} under ${targetDescription(game, this.cover)}`;
    }
}

export const putUnderUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(PutUnderUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) =>
            new PutUnderUnresolved({ item, cover: action.cover }),
        missing: () => 'What would you like to put?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to put, ${opt}?`,
        condition: (item) => actor.hasItem(item),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    const { item: cover } = await game.resolve(runner, action.cover, actor, {
        partial: (cover) =>
            new PutUnderUnresolved({ item: action.item, cover }),
        missing: () => `What do you want to put ${item.the()} under?`,
        ambiguous: (desc, opt) =>
            `Under which ${desc} would you like to put ${item.the()}, ${opt}?`,
        condition: (item) => item.isItem(),
    });

    if (cover === undefined) return Action.complete({ withConsequence: false });

    if (await game.reach(runner, item, actor)) {
        await game.applyAction(runner, new PutUnder({ item, cover }));
    }
    return Action.complete();
};
