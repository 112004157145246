import { Hello } from './Hello';
import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { UnresolvedAction } from '../UnresolvedAction';

export class HelloUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'hello';

    person: Target | undefined;

    constructor({ person }: { person: Target | undefined }) {
        super();
        this.person = person;
    }

    description(game: Game) {
        if (this.person) {
            return `say hello to ${targetDescription(game, this.person)}`;
        }
        return `say hello`;
    }
}

export const helloUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(HelloUnresolved)) return;
    if (action.person) {
        const { item: person } = await game.resolve(
            runner,
            action.person,
            actor,
            {
                partial: (person) => new HelloUnresolved({ person }),
                ambiguous: (desc, opt) =>
                    `Which ${desc} would you like to greet, ${opt}?`,
            }
        );

        if (!person) return Action.complete({ withConsequence: false });

        return await game.applyAction(runner, new Hello({ person }));
    }
    return await game.applyAction(runner, new Hello({ person: undefined }));
};
