import {
    EastNarrowRoom1,
    EastNarrowRoom3,
    Passage,
    Room,
    WestNarrowRoom1,
    WestNarrowRoom3,
} from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { Hallway3 } from '../Hallway3';
import { Hallway1 } from '../Hallway1';
import {
    enterPassage,
    getHallwayDescription,
    hallwayGlobalObjects,
    hallwayPassage,
} from '../InsideMirror/mirror_utils';

export class Hallway2 extends Room {
    static spec(): EntitySpec<Hallway2> {
        return {
            ref: 'hallway-2',
            constructor: Hallway2,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Hallway2.spec().ref;
    }

    name(): string {
        return 'Hallway';
    }

    description(): string {
        return getHallwayDescription(
            this.game,
            this.game.ent(Hallway3),
            this.game.ent(Hallway1),
            undefined,
            undefined,
            true
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    globalObjects(): Entity[] {
        return [...super.globalObjects(), ...hallwayGlobalObjects(this)];
    }

    passages(): Passage[] {
        return [
            hallwayPassage({
                to: this.game.ent(Hallway3),
                via: Direction.North,
            }),
            hallwayPassage({
                to: this.game.ent(EastNarrowRoom3),
                via: Direction.Northeast,
            }),
            hallwayPassage({
                to: this.game.ent(WestNarrowRoom3),
                via: Direction.Northwest,
            }),
            hallwayPassage({
                to: this.game.ent(EastNarrowRoom1),
                via: Direction.Southeast,
            }),
            hallwayPassage({
                to: this.game.ent(WestNarrowRoom1),
                via: Direction.Southwest,
            }),
            hallwayPassage({
                to: this.game.ent(Hallway1),
                via: Direction.South,
            }),
            enterPassage({ from: this }),
        ];
    }
}
