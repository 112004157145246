import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Plugh extends makeDescribable(Action) {
    id = 'plugh';

    description(game: Game): string {
        return 'say nonsense';
    }

    static ability(): Ability {
        const verbs = [new Verb('plugh'), new Verb('xyzzy')];
        return {
            handlers: [adventureHandler],
            parser: () => Parse.words(verbs).into(new Plugh()),
            verbs,
            prepositions: [],
        };
    }
}

export const adventureHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Plugh)) return;
    await runner.doOutput("A hollow voice says 'Cretin'.");
    return Action.complete();
};
