import { Actor, ActorState, Player } from '../index';
import { Adjective, Noun } from '../../../parse';
import { Brick, Egg, Fuse, Knife, Stiletto } from '../../items';
import { Item } from '../../items/Item';
import { Entity, Action, Handler, Reference } from '../../game';
import {
    Give,
    Hello,
    SpecialDescribe,
    SpecialJigsUp,
    SpecialPrepareToFight,
    SpecialRevive,
    SpecialTimerTick,
    Take,
    Throw,
} from '../../abilities';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';
import { Room, TreasureRoom } from '../../rooms';
import { villainWinningFight } from '../../handlers';
import { listify } from '../../utils';

interface ThiefState extends ActorState {
    hiddenItems: Reference[];
    hasBeenIntroduced: boolean;
}

export class Thief extends Actor<ThiefState> {
    static spec() {
        return {
            ref: 'thief',
            constructor: Thief,
            initial: {
                inventory: [
                    Stiletto.spec().ref,
                    // Bag
                ],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: 9,
                hiddenItems: [],
                hasBeenIntroduced: false,
                isEngrossed: false,
            },
            nouns: [
                new Noun('thief'),
                new Noun('thieves', { plural: true }),
                new Noun('robber'),
                new Noun('robbers', { plural: true }),
                new Noun('crook'),
                new Noun('crooks', { plural: true }),
                new Noun('criminal'),
                new Noun('criminals', { plural: true }),
                new Noun('bandit'),
                new Noun('bandits', { plural: true }),
                new Noun('gentleman'),
                new Noun('gentlemen', { plural: true }),
                new Noun('gent'),
                new Noun('gents', { plural: true }),
                new Noun('man'),
                new Noun('men', { plural: true }),
                new Noun('individual'),
                new Noun('individuals', { plural: true }),
            ],
            adjectives: [new Adjective('shady'), new Adjective('suspicious')],
            handlers: [
                thiefActivity,
                describeThief,
                thiefRecoverKnife,
                thiefDies,
                thiefRevives,
                throwAtThief,
                giveToThief,
                takeThief,
                helloThief,
            ],
        };
    }

    hasBeenIntroduced(): boolean {
        return this.state.hasBeenIntroduced;
    }

    ref() {
        return Thief.spec().ref;
    }

    name(): string {
        return 'thief';
    }

    description(): string {
        return (
            'There is a suspicious-looking individual, holding a bag, ' +
            'leaning against one wall. He is armed with a vicious-looking stiletto.'
        );
    }

    initialDescription(): string {
        return (
            'Someone carrying a large bag is casually leaning against one ' +
            'of the walls here. He does not speak, but it is clear from ' +
            'his aspect that the bag will be taken only over his dead body.'
        );
    }

    isVillain(): boolean {
        return true;
    }

    isVictim(): boolean {
        return true;
    }

    nouns(): Noun[] {
        return Thief.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Thief.spec().adjectives;
    }

    bestWeapon(): Item | undefined {
        return this.game.ent(Knife);
    }

    isReadyToFight(): boolean {
        const knife = this.game.ent(Stiletto);
        return this.hasItem(knife);
    }

    hiddenItems(): Entity[] {
        return this.state.hiddenItems.map((ref) => this.game.get(ref));
    }

    shouldAttackFirst(): boolean {
        return this.game.ent(Player).testLuck(20, 75);
    }
}

const thiefRecoverKnife: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(SpecialPrepareToFight) && actor?.is(Thief)) {
        const stiletto = game.ent(Stiletto);
        stiletto.moveTo(actor);
        await runner.doOutput(
            'The robber, somewhat surprised at this turn of events, nimbly retrieves his stiletto.'
        );
        return Action.complete({ withConsequence: false });
    }
};

const thiefDies: Handler = async ({ action, runner, actor }) => {
    if (action.is(SpecialJigsUp) && actor?.is(Thief)) {
        await runner.doOutput(action.message);
        const room = actor.location();
        if (!room) throw new Error('Expected thief to be in a room.');
        for (const item of actor.inventory()) {
            if (!item.is(Stiletto)) {
                item.moveTo(room);
            }
        }
        if (room?.is(TreasureRoom)) {
            const hiddenItems = actor.hiddenItems();
            if (hiddenItems.length > 0) {
                for (const item of hiddenItems) {
                    item.moveTo(room);
                }
                actor.state.hiddenItems = [];
                await runner.doOutput(
                    `As the thief dies, the power of his magic decreases, and his treasures reappear: ${listify(
                        hiddenItems.map((item) => item.an())
                    )}.`
                );
                // TODO it might be worth abstracting this logic so it can be used when items are opened as well.
                for (const item of hiddenItems) {
                    if (item.isItem() && item.isContainer()) {
                        for (const innerItem of item.contents()) {
                            if (innerItem.isItem()) {
                                const init = innerItem.initialDescription();
                                if (
                                    innerItem.isTakeable() &&
                                    !innerItem.hasBeenTaken() &&
                                    init
                                ) {
                                    await runner.doOutput(init);
                                }
                            }
                        }
                    }
                }
            }
        }
        return Action.complete({ withConsequence: false });
    }
};

const thiefRevives: Handler = async ({ action, runner, actor }) => {
    if (action.is(SpecialRevive) && actor?.is(Thief)) {
        actor.setIsConscious(true);
        await runner.doOutput(
            'The robber revives, briefly feigning continued unconsciousness, ' +
                'and when he sees his moment, scrambles away from you.'
        );
        return Action.complete({ withConsequence: false });
    }
};

const describeThief: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialDescribe) && action.item.is(Thief)) {
        const thief = action.item;
        if (!thief.isConscious()) {
            await runner.doOutput(
                'There is a suspicious-looking individual lying unconscious ' +
                    'on the ground. His bag and stiletto seem to have vanished.'
            );
        } else if (thief.hasBeenIntroduced()) {
            await runner.doOutput(thief.description());
        }
        // if the thief has not announced yet, announce him
        // but only 30% of the time
        else if (game.ent(Player).testLuck(30)) {
            thief.state.hasBeenIntroduced = true;
            await runner.doOutput(thief.initialDescription());
        } else {
            await moveThief(game, runner);
        }
        return Action.complete({ withConsequence: false });
    }
};

const takeThief: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item?.is(Thief)) {
        await runner.doOutput('Once you got him, what would you do with him?');
        return Action.complete();
    }
};

const throwAtThief: Handler = async ({ action, runner, game }) => {
    if (action.is(Throw) && action.enemy?.is(Thief)) {
        const player = game.ent(Player);
        const room = player.location();
        action.item.moveTo(room);
        if (!action.enemy.isConscious()) {
            action.enemy.setIsConscious(true);
            await runner.doOutput(
                'Your proposed victim suddenly recovers consciousness.'
            );
            return Action.complete();
        }
        if (action.item.is(Knife) && !action.enemy.isFighting()) {
            if (player.testLuck(10, 0)) {
                const loot = action.enemy
                    .inventory()
                    .filter((item) => item.isItem() && !item.is(Stiletto));
                const suffix =
                    loot.length > 0
                        ? ', but the contents of his bag fall on the floor.'
                        : '.';
                await runner.doOutput(
                    'You evidently frightened the robber, ' +
                        `though you didn't hit him. He flees${suffix}`
                );
                loot.forEach((item) => item.moveTo(room));
                await moveThief(game, runner);
                return Action.complete();
            }

            action.enemy.state.isFighting = true;
            await runner.doOutput(
                'You missed. The thief makes no attempt to take the knife, ' +
                    'though it would be a fine addition to the collection in ' +
                    'his bag. He does seem angered by your attempt.'
            );
            return Action.complete();
        }
    }
};

const giveToThief: Handler = async ({ action, runner, actor }) => {
    if (
        action.is(Give) &&
        action.recipient?.is(Thief) &&
        actor?.hasItem(action.item)
    ) {
        if (!action.recipient.isConscious()) {
            action.recipient.state.isConscious = true;
            await runner.doOutput('The thief suddenly recovers consciousness.');
        }
        if (isItemABomb(action.item)) {
            await runner.doOutput(
                'The thief seems rather offended by your offer. ' +
                    "Do you think he's as stupid as you are?"
            );
            return Action.complete();
        }
        action.item.moveTo(action.recipient);
        if (action.item.isItem() && action.item.isTreasure()) {
            action.recipient.setIsEngrossed(true);
            await runner.doOutput(
                'The thief is taken aback by your unexpected generosity, ' +
                    `but accepts ${action.item.the()} and stops to admire its beauty.`
            );
        } else {
            await runner.doOutput(
                `The thief places the ${action.item.the()} in his bag and thanks you politely.`
            );
        }
        return Action.complete();
    }
};

// TODO abstract this out somewhere
function isItemABomb(item: Entity) {
    const fuse = item.game.ent(Fuse);
    return item.is(Brick) && item.contains(fuse) && fuse.isAflame();
}

const helloThief: Handler = async ({ action, runner }) => {
    if (
        action.is(Hello) &&
        action.person?.is(Thief) &&
        !action.person.isConscious()
    ) {
        await runner.doOutput(
            'The thief, being temporarily incapacitated, is unable to ' +
                'acknowledge your greeting with his usual graciousness.'
        );
        return Action.complete();
    }
};

const thiefActivity: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const thief = game.ent(Thief);
        if (!thief.isAlive()) return;
        const player = game.ent(Player);
        const playerLocation = player.location();
        const thiefLocation = thief.location();
        if (thief.isFighting()) return;
        if (thiefLocation?.isEqualTo(playerLocation)) {
            await thiefEncounter(game, runner);
        } else {
            if (thiefLocation?.is(TreasureRoom)) {
                await dropItemsAndOpenEgg(game);
            }
            await moveThief(game, runner);
            const newLocation = thief.location();
            if (newLocation?.isEqualTo(playerLocation)) {
                await thiefPassThrough(game, runner);
            } else if (newLocation?.hasBeenVisited()) {
                await thiefShenanigans(game, runner);
            }
        }
        return Action.incomplete();
    }
};

function getPotentialRooms(game: Game): Room[] {
    const thief = game.ent(Thief);
    const room = thief.location();
    if (room === undefined) return [];
    return room
        .passages()
        .filter((passage) => passage.to !== undefined)
        .map((passage) => game.get(passage.to) as Room)
        .filter((destination) => destination && !destination.isEqualTo(room));
}

async function moveThief(game: Game, runner: Runner) {
    const thief = game.ent(Thief);
    thief.state.hasBeenIntroduced = false;
    const player = game.ent(Player);
    let destinationIsOk = false;
    const playerRoom = player.location();
    const startedInRoomWithPlayer = thief.location()?.isEqualTo(playerRoom);
    let moveAttempts = 0;
    while (!destinationIsOk) {
        moveAttempts += 1;
        if (moveAttempts > 1000) {
            throw new Error(
                `Thief could not find a place to move. Current location is ${thief
                    .location()
                    ?.ref()}.`
            );
        }
        const room = game.choiceOf(getPotentialRooms(game));
        thief.moveTo(room);
        await runner.doDebug(
            `Thief has moved to ${room.name()} (${room.ref()}).`
        );
        if (
            !room.isPartOfEndgame() &&
            !room.isSacred() &&
            room.isOnLand() &&
            (!room.isEqualTo(player.location()) || player.testLuck(70))
        ) {
            destinationIsOk = true;
        }
        if (thief.location()?.is(TreasureRoom)) {
            thief.state.hasBeenIntroduced = true;
        }
    }

    if (startedInRoomWithPlayer && !playerRoom.isLit()) {
        await runner.doOutput('The thief seems to have left you in the dark.');
    }
}

function getRoomObjects(game: Game, goodLuck: number, badLuck?: number) {
    const room = game.ent(Thief).location();
    const player = game.ent(Player);
    if (!room) return [];
    return room
        .contents()
        .filter(
            (item) =>
                item.isItem() &&
                !item.isHidden() &&
                item.isTakeable() &&
                player.testLuck(goodLuck, badLuck)
        );
}

async function thiefPassThrough(game: Game, runner: Runner) {
    const player = game.ent(Player);
    if (player.testLuck(30)) {
        await stealFromPlayer(
            game,
            runner,
            'A seedy-looking individual with a large bag just wandered ' +
                'through the room. On the way through, he quietly abstracted ' +
                'all valuables from the room and from your possession, ' +
                'mumbling something about "Doing unto others before..."',
            "A 'lean and hungry' gentleman just wandered through, carrying " +
                'a large bag. Finding nothing of value, he left disgruntled.'
        );
    } else {
        await moveThief(game, runner);
    }
}

function getPlayerPlunder(game: Game) {
    const player = game.ent(Player);
    return player
        .inventory()
        .filter((item) => item.isItem() && item.isTreasure());
}

function getRoomPlunder(game: Game) {
    const player = game.ent(Player);
    return player
        .location()
        .contents()
        .filter((item) => item.isItem() && item.isTreasure());
}

function getWorthlessItems(game: Game) {
    const thief = game.ent(Thief);
    return thief
        .inventory()
        .filter(
            (item) => item.isItem() && !item.is(Stiletto) && !item.isTreasure()
        ); // TODO or bag?
}

async function stealFromPlayer(
    game: Game,
    runner: Runner,
    success: string,
    failure: string
) {
    let stolen = false;
    const thief = game.ent(Thief);
    for (const treasure of getPlayerPlunder(game)) {
        treasure.moveTo(thief);
        stolen = true;
    }
    const plunder = getRoomPlunder(game)[0];
    if (stolen || plunder) {
        await runner.doOutput(success);
        if (plunder) {
            plunder.moveTo(thief);
            await runner.doOutput(
                `You suddenly notice that ${plunder.the()} vanished.`
            );
        }
    } else {
        await runner.doOutput(failure);
    }
    return moveThief(game, runner);
}

async function thiefShenanigans(game: Game, runner: Runner) {
    const thief = game.ent(Thief);
    const player = game.ent(Player);
    const playerLocation = player.location();
    const thiefLocation = thief.location();
    if (playerLocation.isPartOfMaze() && thiefLocation?.isPartOfMaze()) {
        const plunder = getRoomObjects(game, 40)[0];
        if (plunder) {
            await runner.doOutput(
                `You hear, off in the distance, someone saying ` +
                    `"My, I wonder what this fine ${plunder} is doing here."`
            );
            if (player.testLuck(60, 80)) {
                plunder.moveTo(thief);
            }
        }
    } else {
        // TODO this
        // const rope = game.ent(Rope);
        // if (thiefLocation?.contains(rope) {
        //     rope.moveTo(thief);
        //     // TODO unattach rope
        // }
        const plunder = getRoomObjects(game, 20, 40)[0];
        if (plunder) {
            plunder.moveTo(thief);
        }
    }
}

async function dropItemsAndOpenEgg(game: Game) {
    const egg = game.ent(Egg);
    const thief = game.ent(Thief);
    const treasureRoom = game.ent(TreasureRoom);
    if (thief.hasItem(egg) && !egg.isBroken()) {
        egg.setIsOpen(true);
    }
    // TODO decide if everything or just treasures
    for (const item of thief.inventory()) {
        if (!item.is(Stiletto)) {
            // TODO or bag
            item.moveTo(treasureRoom);
        }
    }
}

async function thiefEncounter(game: Game, runner: Runner) {
    const thief = game.ent(Thief);
    const player = game.ent(Player);
    if (thief.isFighting() && !villainWinningFight(game, thief)) {
        // if the thief thinks he's losing, he leaves
        // TODO test this
        await runner.doOutput(
            'Your opponent, determining discretion to be the better part of ' +
                'valor, decides to terminate this little contretemps. ' +
                'With a rueful nod of his head, he steps backward into ' +
                'the gloom and disappears.'
        );
    } else if (player.testLuck(30)) {
        // the thief might decide to just leave
        await runner.doOutput(
            'The holder of the large bag just left, looking disgusted. Fortunately, he took nothing.'
        );
        await moveThief(game, runner);
    } else if (player.testLuck(30)) {
        // or he might decide to drop some stuff
        let dropped = false;
        const room = player.location();
        for (const item of getWorthlessItems(game)) {
            item.moveTo(room);
            dropped = true;
        }
        if (dropped) {
            await runner.doOutput(
                'The robber, rummaging through his bag, dropped a few items he found valueless.'
            );
        }
    } else if (player.testLuck(30)) {
        // or maybe he tries to steal from you
        await stealFromPlayer(
            game,
            runner,
            'The other occupant just left, still carrying his large bag. ' +
                'You may not have noticed that he robbed you blind first.',
            'The other occupant (he of the large bag), ' +
                'finding nothing of value, left disgusted.'
        );
    }
}
