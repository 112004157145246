import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { WellTopEtchings } from '../../items';
import { TeaRoom } from '../TeaRoom';
import { Well } from '../../items/Well';

export class TopOfWell extends Room {
    static spec(): EntitySpec<TopOfWell> {
        return {
            ref: 'top-of-well',
            constructor: TopOfWell,
            initial: {
                contents: [
                    WellTopEtchings.spec().ref,
                    Well.spec().ref,
                    // TODO crack
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    scoreOnEntry(): number {
        return 10;
    }

    ref() {
        return TopOfWell.spec().ref;
    }

    name(): string {
        return 'Top of the Well';
    }

    description(): string {
        return (
            'You are at the top of the well. Well done. ' +
            'There are etchings on the side of the well. ' +
            'There is a small crack across the floor at the entrance ' +
            'to a room on the east, but it can be crossed easily.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: TeaRoom.spec().ref }),
            new Passage({
                via: Direction.Down,
                message: "It's a long way down.",
            }),
        ];
    }
}
