import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { OakDoorKeyhole1 } from '../OakDoorKeyhole1';
import { OakDoorKeyhole2 } from '../OakDoorKeyhole2';
import { OakDoor } from '../OakDoor';
import { Lock, Turn, Unlock } from '../../abilities';

export class RustyKey extends makeTakeable(Item) {
    static spec(): EntitySpec<RustyKey> {
        return {
            ref: 'rusty-key',
            constructor: RustyKey,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [new Noun('key'), new Noun('keys', { collective: true })],
            adjectives: [new Adjective('rusty'), new Adjective('iron')],
            handlers: [turnKey],
        };
    }

    ref() {
        return RustyKey.spec().ref;
    }

    name() {
        return 'rusty iron key';
    }

    isTool() {
        return true;
    }

    description() {
        return 'There is a rusty iron key here.';
    }

    size() {
        return 3;
    }

    nouns(): Noun[] {
        return RustyKey.spec().nouns;
    }

    adjectives(): Adjective[] {
        return RustyKey.spec().adjectives;
    }

    canBeTurned(): boolean {
        return true;
    }
}

const turnKey: Handler = async ({ action, runner, game }) => {
    if (action.is(Turn) && action.item.is(RustyKey)) {
        const container = action.item.container();
        if (container?.is(OakDoorKeyhole1) || container?.is(OakDoorKeyhole2)) {
            const oakDoor = game.ent(OakDoor);
            const newAction = oakDoor.isLocked()
                ? new Unlock({ item: oakDoor, tool: action.item })
                : new Lock({ item: oakDoor, tool: action.item });
            return game.applyAction(runner, newAction);
        }
        await runner.doOutput('Perhaps it would help if it were in a lock.');
        return Action.complete();
    }
    // TODO turning the key in the wrong lock?
};
