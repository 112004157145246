import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that never consumes any nodes, and yields exactly one match with
 * the given `value`.
 *
 * ```
 * constant(2).match(tokenize("")) // yields 2
 * ```
 */
export class ConstantParser<I, T> extends Parser<I, T> {
    value: T;

    constructor(value: T) {
        super();
        this.value = value;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, T>> {
        yield new Match(new Token(this.value, []), tokens);
    }
}
