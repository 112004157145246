import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { BoardUnresolved, boardUnresolvedHandler } from './BoardUnresolved';
import { Player } from '../../actors';

export class Board extends Action {
    id = '~board';

    vehicle: Entity;

    constructor({ vehicle }: { vehicle: Entity }) {
        super();
        this.vehicle = vehicle;
    }

    static ability(): Ability {
        return {
            handlers: [boardHandler, boardUnresolvedHandler],
            parser,
            verbs: [
                new Verb('board'),
                new Verb('get in'),
                new Verb('get into'),
                new Verb('get in to'),
                new Verb('get inside'),
                new Verb('enter'),
                new Verb('go in'),
                new Verb('go into'),
                new Verb('go in to'),
                new Verb('go inside'),
                new Verb('step in'),
                new Verb('step into'),
                new Verb('step in to'),
                new Verb('step inside'),
            ],
            prepositions: [],
        };
    }
}

export const boardHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Board)) return;
    const { vehicle } = action;
    const player = game.ent(Player);
    if (!vehicle.isItem() || !vehicle.isVehicle()) {
        await runner.doOutput(
            `I suppose you have a theory on boarding ${vehicle.the()}.`
        );
    } else if (!player.location().contains(vehicle)) {
        await runner.doOutput(
            `${vehicle.The()} must be on the ground to be boarded.`
        );
    } else if (vehicle.contains(player)) {
        await runner.doOutput(`You are already in ${vehicle.the()}, cretin!`);
    } else {
        player.moveTo(vehicle);
        await runner.doOutput(`You are now in ${vehicle.the()}.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, BoardUnresolved> => {
    const board = Parse.words([
        new Verb('board'),
        new Verb('get in'),
        new Verb('step in'),
    ]);
    const boardObject = Parse.words(Board.ability().verbs).chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // board
        board.into(new BoardUnresolved({ vehicle: undefined })),
        // board bucket
        boardObject.map((vehicle) => new BoardUnresolved({ vehicle }))
    );
};
