import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeExaminable } from '../../game/Entity';
import { Climb } from '../../abilities';

export class WhiteCliffs extends makeExaminable(Item) {
    static spec(): EntitySpec<WhiteCliffs> {
        return {
            ref: 'white-cliffs',
            constructor: WhiteCliffs,
            initial: {},
            nouns: [
                new Noun('cliff'),
                new Noun('cliffs', { collective: true }),
            ],
            adjectives: [new Adjective('white')],
            handlers: [climbWhiteCliffs],
        };
    }

    ref() {
        return WhiteCliffs.spec().ref;
    }

    name(): string {
        return 'white cliffs';
    }

    description(): string {
        return '';
    }

    nouns() {
        return WhiteCliffs.spec().nouns;
    }

    adjectives() {
        return WhiteCliffs.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    examineText(): string {
        return (
            'The White Cliffs form a giant wall stretching along the east ' +
            'shore of the Frigid River. They look far too steep to climb.'
        );
    }

    canBeClimbed() {
        return true;
    }
}

const climbWhiteCliffs: Handler = async ({ action, runner, game }) => {
    if (action.is(Climb) && action.item?.is(WhiteCliffs)) {
        await runner.doOutput('The cliff is too steep for climbing.');
        return Action.complete();
    }
};
