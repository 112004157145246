import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push } from '../../abilities';
import { Bolt } from '../Bolt';

export class BrownButton extends Item {
    static spec(): EntitySpec<BrownButton> {
        return {
            ref: 'brown-button',
            constructor: BrownButton,
            initial: {},
            nouns: [
                new Noun('button'),
                new Noun('buttons', { plural: true }),
                new Noun('switch'),
                new Noun('switches', { plural: true }),
            ],
            adjectives: [new Adjective('brown')],
            handlers: [pushBrownButton],
        };
    }

    ref() {
        return BrownButton.spec().ref;
    }

    name(): string {
        return 'brown button';
    }

    description(): string {
        return '';
    }

    nouns() {
        return BrownButton.spec().nouns;
    }

    adjectives() {
        return BrownButton.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushBrownButton: Handler = async ({ action, runner, game }) => {
    if (action.is(Push) && action.item.is(BrownButton)) {
        game.ent(Bolt).state.isLocked = true;
        await runner.doOutput('Click.');

        return Action.complete();
    }
};
