import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { MINE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { CoalMine2 } from '../CoalMine2';
import { CoalMine4 } from '../CoalMine4';
import { WoodenTunnel } from '../WoodenTunnel';

export class CoalMine1 extends Room {
    static spec(): EntitySpec<CoalMine1> {
        return {
            ref: 'coal-mine-1',
            constructor: CoalMine1,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return CoalMine1.spec().ref;
    }

    name(): string {
        return 'Coal Mine';
    }

    description(): string {
        return MINE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: CoalMine4.spec().ref }),
            new Passage({ via: Direction.Southwest, to: CoalMine2.spec().ref }),
            new Passage({ via: Direction.East, to: WoodenTunnel.spec().ref }),
        ];
    }
}
