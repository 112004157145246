import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { RoundRoom } from '../RoundRoom';
import { LoudRoom } from '../LoudRoom';
import { Chasm } from '../Chasm';

export class NorthSouthPassage extends Room {
    static spec(): EntitySpec<NorthSouthPassage> {
        return {
            ref: 'north-south-passage',
            constructor: NorthSouthPassage,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return NorthSouthPassage.spec().ref;
    }

    name(): string {
        return 'North-South Passage';
    }

    description(): string {
        return 'This is a high north-south passage, which forks to the northeast.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: Chasm.spec().ref }),
            new Passage({ via: Direction.Northeast, to: LoudRoom.spec().ref }),
            new Passage({ via: Direction.South, to: RoundRoom.spec().ref }),
        ];
    }
}
