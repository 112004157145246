import { Passage, RockyShore, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Guano } from '../../items';
import { Shovel } from '../../items/Shovel';
import { AncientChasm } from '../AncientChasm';

export class SmallCave extends Room {
    static spec(): EntitySpec<SmallCave> {
        return {
            ref: 'small-cave',
            constructor: SmallCave,
            initial: {
                contents: [Guano.spec().ref, Shovel.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return SmallCave.spec().ref;
    }

    name(): string {
        return 'Small Cave';
    }

    description(): string {
        return 'This is a small cave whose exits are on the south and northwest.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: RockyShore.spec().ref,
            }),
            new Passage({
                via: Direction.Northwest,
                to: AncientChasm.spec().ref,
            }),
        ];
    }
}
