import { Cave1, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { ReservoirNorth } from '../ReservoirNorth';
import { Trident } from '../../items';

export class AtlantisRoom extends Room {
    static spec(): EntitySpec<AtlantisRoom> {
        return {
            ref: 'atlantis-room',
            constructor: AtlantisRoom,
            initial: {
                contents: [Trident.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return AtlantisRoom.spec().ref;
    }

    name(): string {
        return 'Atlantis Room';
    }

    description(): string {
        return (
            'This is an ancient room, long under water. ' +
            'There are exits here to the southeast and upward.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Southeast,
                to: ReservoirNorth.spec().ref,
            }),
            new Passage({ via: Direction.Up, to: Cave1.spec().ref }),
        ];
    }
}
