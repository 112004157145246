import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeReadable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Code extends makeFlammable(makeTakeable(makeReadable(Item))) {
    static spec(): EntitySpec<Code> {
        return {
            ref: 'code',
            constructor: Code,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('list'),
                new Noun('print out'),
                new Noun('printout'),
                new Noun('stack of listings'),
                new Noun('stack'),
                new Noun('output', { collective: true }),
                new Noun('listings', { collective: true }),
                new Noun('lists', { collective: true }),
                new Noun('paper', { collective: true }),
                new Noun('code', { collective: true }),
            ],
            adjectives: [new Adjective('gigantic')],
        };
    }

    ref() {
        return Code.spec().ref;
    }

    name() {
        return 'stack of listings';
    }

    description() {
        return 'There is an enormous stack of line-printer paper here.  It is barely readable.';
    }

    initialDescription() {
        return (
            'There is a gigantic pile of line-printer output here. ' +
            'Although the paper once contained useful information, ' +
            'almost nothing can be distinguished now.'
        );
    }

    size() {
        return 70;
    }

    text() {
        return CODE_TEXT;
    }

    nouns(): Noun[] {
        return Code.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Code.spec().adjectives;
    }
}

const CODE_TEXT = `
<DEFINE FEEL-FREE (LOSER)
  <TELL "FEEL FREE, CHOMPER!">>
    ...
The rest is, alas, unintelligible (as were the implementers).`;
