import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { TrapDoor } from '../../items';
import { SpecialLook } from '../../abilities';
import { SpecialDescribeRoom } from '../../abilities/SpecialDescribeRoom';
import { Player } from '../../actors';
import { WestOfChasm } from '../WestOfChasm';
import { LivingRoom } from '../LivingRoom';
import { TrollRoom } from '../TrollRoom';

export class Cellar extends Room {
    static spec(): EntitySpec<Cellar> {
        return {
            ref: 'cellar',
            constructor: Cellar,
            initial: {
                contents: [TrapDoor.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [describeCellar, enterCellar],
        };
    }

    ref() {
        return Cellar.spec().ref;
    }

    name(): string {
        return 'Cellar';
    }

    description(): string {
        return (
            'You are in a dark and damp cellar with a narrow passageway ' +
            'leading east, and a crawlway to the south. On the west is the ' +
            'bottom of a steep metal ramp which is unclimbable.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    scoreOnEntry(): number {
        return 25;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Up,
                to: LivingRoom.spec().ref,
                condition: () => this.game.ent(TrapDoor).isOpen(),
                message: 'The trap door is closed.',
            }),
            new Passage({ via: Direction.East, to: TrollRoom.spec().ref }),
            new Passage({
                via: Direction.West,
                message:
                    'You try to ascend the ramp, but it is impossible, and you slide back down.',
            }),
            new Passage({ via: Direction.South, to: WestOfChasm.spec().ref }),
        ];
    }
}

const describeCellar: Handler = async ({
    action,
    extensions,
    game,
    runner,
}) => {
    if (action.is(SpecialDescribeRoom) && action.room.is(Cellar)) {
        await extensions.deferHandling();
        const trapDoor = game.ent(TrapDoor);
        if (game.ent(Cellar).isLit() && trapDoor.isOpen()) {
            await runner.doOutput('Above you is an open trap door.');
        }
        return Action.complete({ withConsequence: false });
    }
};

const enterCellar: Handler = async ({ action, runner, game, extensions }) => {
    if (action.is(SpecialLook)) {
        const room = game.locateEntity(game.ent(Player));
        if (!room?.is(Cellar)) return;
        await extensions.deferHandling();
        const trapDoor = game.ent(TrapDoor);
        if (game.ent(Cellar).isLit() && !trapDoor.isSacred()) {
            trapDoor.setIsOpen(false);
            trapDoor.setIsSacred(true);
            await runner.doOutput(
                'The door crashes shut, and you hear someone barring it.'
            );
        }
        return Action.complete({ withConsequence: false });
    }
};
