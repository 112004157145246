import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { makeDescribable } from '../../game/Action';
import { targetDescription } from '../../utils';
import { Drop } from './Drop';
import { UnresolvedAction } from '../UnresolvedAction';

export class DropUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'drop';

    items: Target;

    constructor({ items }: { items: Target }) {
        super();
        this.items = items;
    }

    description(game: Game): string {
        return `drop ${targetDescription(game, this.items)}`;
    }
}

export const dropUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(DropUnresolved)) return;

    const { items, plural } = await game.resolve(runner, action.items, actor, {
        allowGroups: true,
        condition: (item) =>
            item.isItem() &&
            item.shouldTryToTake() &&
            !(actor?.isActor() && !actor.hasItem(item)),
        partial: (items) => new DropUnresolved({ items }),
        ambiguous: (desc, opt, not) =>
            `Which ${desc} would you like${not} to drop, ${opt}?`,
    });
    if (items.length === 0) return Action.complete({ withConsequence: false });

    for (const item of items) {
        if (plural) {
            await runner.doOutput(`${item.Name()}: `, { newLine: false });
        }
        if (await game.reach(runner, item, actor)) {
            await game.applyAction(
                runner,
                new Drop({ item, silent: false }),
                actor
            );
        }
    }
    return Action.complete();
};
