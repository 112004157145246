import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class PearlNecklace extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<PearlNecklace> {
        return {
            ref: 'pearl-necklace',
            constructor: PearlNecklace,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('pearls', { collective: true }),
                new Noun('necklace'),
                new Noun('necklaces', { plural: true }),
                new Noun('jewelry', { collective: true }),
            ],
            adjectives: [new Adjective('pearl'), new Adjective('pearly')],
        };
    }

    ref() {
        return PearlNecklace.spec().ref;
    }

    name() {
        return 'pearl necklace';
    }

    description() {
        return 'There is a pearl necklace here with hundreds of large pearls.';
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 9;
    }

    scoreInCase(): number {
        return 5;
    }

    nouns(): Noun[] {
        return PearlNecklace.spec().nouns;
    }

    adjectives(): Adjective[] {
        return PearlNecklace.spec().adjectives;
    }
}
