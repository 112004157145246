import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Give } from './Give';
import { UnresolvedAction } from '../UnresolvedAction';

export class GiveUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'give';

    recipient: Target | undefined;

    item: Target | undefined;

    constructor({
        recipient,
        item,
    }: {
        recipient: Target | undefined;
        item: Target | undefined;
    }) {
        super();
        this.recipient = recipient;
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'give something';
        }
        if (this.recipient === undefined) {
            return `give ${targetDescription(game, this.item)} to someone`;
        }
        return `give ${targetDescription(
            game,
            this.item
        )} to ${targetDescription(game, this.recipient)}`;
    }
}

export const giveUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(GiveUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) =>
            new GiveUnresolved({ item, recipient: action.recipient }),
        missing: () => 'What would you like to give?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to give, ${opt}?`,
        condition: (item) => item.isItem() && actor.hasItem(item),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    const { item: recipient } = await game.resolve(
        runner,
        action.recipient,
        actor,
        {
            partial: (recipient) =>
                new GiveUnresolved({ recipient, item: action.item }),
            missing: () => `To whom would you like to give ${item.the()}?`,
            ambiguous: (desc, opt) =>
                `To which ${desc} would you like to give ${item.the()}, ${opt}?`,
            condition: (item) => item.isActor(),
        }
    );

    if (recipient === undefined)
        return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) {
        return Action.complete();
    }

    return game.applyAction(runner, new Give({ item, recipient }));
};
