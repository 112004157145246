import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, Entity, EntitySpec, Handler } from '../../game';
import { Examine, LookIn, Mung, Poke, Rub, Take, Throw } from '../../abilities';
import { Actor, Player } from '../../actors';
import { MirrorRoom1, MirrorRoom2 } from '../../rooms';
import { Runner } from '../../game/Runner';

interface MagicMirror1State extends ItemState {
    isMunged: boolean;
}

export class MagicMirror extends Item<MagicMirror1State> {
    static spec(): EntitySpec<MagicMirror> {
        return {
            ref: 'magic-mirror',
            constructor: MagicMirror,
            initial: {
                isMunged: false,
            },
            nouns: [new Noun('mirror'), new Noun('mirrors', { plural: true })],
            adjectives: [
                new Adjective('broken'),
                new Adjective('shattered'),
                new Adjective('munged'),
            ],
            handlers: [
                rubMirror,
                lookInMirror,
                takeMirror,
                breakMirror,
                attackMirror,
            ],
        };
    }

    ref() {
        return MagicMirror.spec().ref;
    }

    name(): string {
        return this.isMunged() ? 'broken mirror' : 'mirror';
    }

    description(): string {
        return '';
    }

    nouns() {
        return MagicMirror.spec().nouns;
    }

    adjectives() {
        return this.isMunged()
            ? [
                  new Adjective('broken'),
                  new Adjective('shattered'),
                  new Adjective('munged'),
              ]
            : [];
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isMunged(): boolean {
        return this.state.isMunged;
    }
}

const rubMirror: Handler = async ({ action, runner, game, actor }) => {
    if (
        action.is(Rub) &&
        action.item.is(MagicMirror) &&
        actor?.is(Player) &&
        !action.item.isMunged()
    ) {
        const room = actor.location();
        const otherRoom = room.is(MirrorRoom1)
            ? game.ent(MirrorRoom2)
            : game.ent(MirrorRoom1);
        const roomContents = [...room.contents()];
        const isNotMirror = (item: Entity) => !item.is(MagicMirror);
        otherRoom
            .contents()
            .filter(isNotMirror)
            .forEach((item) => item.moveTo(room));
        roomContents
            .filter(isNotMirror)
            .forEach((item) => item.moveTo(otherRoom));
        await runner.doOutput(
            'There is a rumble from deep within the earth and the room shakes.'
        );
        return Action.complete();
    }
};

const lookInMirror: Handler = async ({ action, runner }) => {
    if (
        // TODO more actions
        (action.is(LookIn) || action.is(Examine)) /* || action.is(LookAt)  */ &&
        action.item.is(MagicMirror)
    ) {
        if (action.item.isMunged()) {
            await runner.doOutput('The mirror is broken into many pieces.');
        } else {
            await runner.doOutput(
                'There is an ugly person staring back at you.'
            );
        }
        return Action.complete();
    }
};

const takeMirror: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(MagicMirror)) {
        await runner.doOutput(
            'Nobody but a greedy surgeon would allow you to attempt that trick.'
        );
        return Action.complete();
    }
};

async function destroyMirror(
    runner: Runner,
    actor: Actor | undefined,
    mirror: MagicMirror
) {
    if (actor?.isLucky()) {
        actor.state.isLucky = false;
        mirror.state.isMunged = true;
        await runner.doOutput(
            'You have broken the mirror. I hope you have a seven years supply of good luck handy.'
        );
    } else {
        await runner.doOutput("Haven't you done enough already?");
    }
}

const breakMirror: Handler = async ({ action, runner, actor }) => {
    if (action.is(Mung) && action.item.is(MagicMirror)) {
        await destroyMirror(runner, actor, action.item);
        return Action.complete();
    }
};

const attackMirror: Handler = async ({ action, runner, actor }) => {
    if (
        (action.is(Throw) || action.is(Poke)) &&
        action.enemy?.is(MagicMirror)
    ) {
        await destroyMirror(runner, actor, action.enemy);
        return Action.complete();
    }
};
