import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push } from '../../abilities';
import { MaintenanceRoom } from '../../rooms';

export class BlueButton extends Item {
    static spec(): EntitySpec<BlueButton> {
        return {
            ref: 'blue-button',
            constructor: BlueButton,
            initial: {},
            nouns: [
                new Noun('button'),
                new Noun('buttons', { plural: true }),
                new Noun('switch'),
                new Noun('switches', { plural: true }),
            ],
            adjectives: [new Adjective('blue')],
            handlers: [pushBlueButton],
        };
    }

    ref() {
        return BlueButton.spec().ref;
    }

    name(): string {
        return 'blue button';
    }

    description(): string {
        return '';
    }

    nouns() {
        return BlueButton.spec().nouns;
    }

    adjectives() {
        return BlueButton.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushBlueButton: Handler = async ({ action, runner, game }) => {
    if (action.is(Push) && action.item.is(BlueButton)) {
        const room = game.ent(MaintenanceRoom);
        if (room.isLeaky()) {
            await runner.doOutput('The blue button appears to be jammed.');
        } else {
            room.state.isLeaky = true;
            // game.ent(Leak).state.isHidden = false; TODO
            await runner.doOutput(
                'There is a rumbling sound and a stream of water ' +
                    'appears to burst from the east wall of the room ' +
                    '(apparently, a leak has occurred in a pipe.)'
            );
        }

        room.state.areLightsPowered = !room.state.areLightsPowered;

        return Action.complete();
    }
};
