import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { PourOn } from './PourOn';
import { UnresolvedAction } from '../UnresolvedAction';

export class PourOnUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'pour';

    item: Target;

    surface: Target;

    constructor({ item, surface }: { item: Target; surface: Target }) {
        super();
        this.item = item;
        this.surface = surface;
    }

    description(game: Game) {
        return `pour ${targetDescription(
            game,
            this.item
        )} on ${targetDescription(game, this.surface)}`;
    }
}

export const pourUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(PourOnUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) =>
            new PourOnUnresolved({ item, surface: action.surface }),
        missing: () => 'What would you like to pour?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to pour, ${opt}?`,
        condition: (item) => actor.hasItem(item),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    const { item: surface } = await game.resolve(
        runner,
        action.surface,
        actor,
        {
            partial: (surface) =>
                new PourOnUnresolved({ item: action.item, surface }),
            ambiguous: (desc, opt) =>
                `On which ${desc} would you like to pour ${item.the()}, ${opt}?`,
            condition: (item) => item.isItem(),
        }
    );
    if (surface === undefined)
        return Action.complete({ withConsequence: false });

    if (
        (await game.reach(runner, item, actor)) &&
        (await game.reach(runner, surface, actor))
    ) {
        await game.applyAction(runner, new PourOn({ item, surface }));
    }

    return Action.complete();
};
