import { Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { Bolt } from '../Bolt';

export class ReservoirWater extends Item {
    static spec(): EntitySpec<ReservoirWater> {
        return {
            ref: 'reservoir-water',
            constructor: ReservoirWater,
            initial: {},
            nouns: [
                new Noun('reservoir'),
                new Noun('reservoirs', { plural: true }),
                new Noun('water', { collective: true }),
            ],
            adjectives: [],
        };
    }

    ref() {
        return ReservoirWater.spec().ref;
    }

    name(): string {
        return 'reservoir';
    }

    description(): string {
        return '';
    }

    nouns() {
        return ReservoirWater.spec().nouns;
    }

    adjectives() {
        return ReservoirWater.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isBodyOfWater() {
        return !this.ent(Bolt).isPowered();
    }
}
