import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Disembark } from './Disembark';
import { Player } from '../../actors';
import { UnresolvedAction } from '../UnresolvedAction';

export class DisembarkUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'disembark';

    vehicle: Target | undefined;

    constructor({ vehicle }: { vehicle: Target | undefined }) {
        super();
        this.vehicle = vehicle;
    }

    description(game: Game) {
        if (this.vehicle === undefined) {
            return 'get in something';
        }
        return `get in ${targetDescription(game, this.vehicle)}`;
    }
}

export const disembarkUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(DisembarkUnresolved)) return;

    let vehicle;
    if (action.vehicle) {
        const { item: resolvedVehicle } = await game.resolve(
            runner,
            action.vehicle,
            actor,
            {
                partial: (vehicle) => new DisembarkUnresolved({ vehicle }),
                missing: () => 'What would you like to disembark?',
                ambiguous: (desc, opt) =>
                    `Which ${desc} would you like to disembark, ${opt}?`,
                condition: (vehicle) => vehicle.isItem() && vehicle.isVehicle(),
            }
        );
        vehicle = resolvedVehicle;

        if (!resolvedVehicle)
            return Action.complete({ withConsequence: false });

        if (!(await game.reach(runner, resolvedVehicle, actor)))
            return Action.complete();
    } else {
        const playerPlace = game.ent(Player).container();
        if (playerPlace?.isItem() && playerPlace.isVehicle()) {
            vehicle = playerPlace;
        }
    }

    if (!vehicle) {
        await runner.doOutput('What would you like to disembark?');
        // TODO set partial!
        return Action.complete({ withConsequence: false });
    }

    return await game.applyAction(runner, new Disembark({ vehicle }));
};
