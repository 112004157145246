import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { DamBase } from '../DamBase';
import { DamLobby } from '../DamLobby';
import { DampCave } from '../DampCave';
import { DeepCanyon } from '../DeepCanyon';
import { Bolt, Bubble } from '../../items';

export class Dam extends Room {
    static spec(): EntitySpec<Dam> {
        return {
            ref: 'dam',
            constructor: Dam,
            initial: {
                contents: [
                    // Dam? maybe as visible/mentionable object
                    Bolt.spec().ref,
                    Bubble.spec().ref,
                    // Control Panel?
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Dam.spec().ref;
    }

    name(): string {
        return 'Dam';
    }

    // TODO test this
    description(): string {
        let description =
            'You are standing on the top of the Flood Control Dam #3, ' +
            'which was quite a tourist attraction in times far distant. ' +
            'There are paths to the north, south, east, and down.';
        const sluiceGatesOpen = this.game.ent(Bolt).isPowered();
        if (sluiceGatesOpen) {
            description +=
                '\nIt appears that the dam has been opened since the water ' +
                'level behind it is low and the sluice gate has been opened. ' +
                'Water is rushing downstream through the gates.';
        } else {
            description +=
                '\nThe sluice gates on the dam are closed. Behind the dam, ' +
                'there can be seen a wide lake. ' +
                'A small stream is formed by the runoff from the lake.';
        }
        description +=
            '\nThere is a control panel here. ' +
            'There is a large metal bolt on the panel. ' +
            'Above the bolt is a small green plastic bubble.';
        const sluiceGatesUnlocked = !this.game.ent(Bolt).isLocked();
        if (sluiceGatesUnlocked) {
            description += '\nThe green bubble is glowing.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Down, to: DamBase.spec().ref }),
            new Passage({ via: Direction.East, to: DampCave.spec().ref }),
            new Passage({ via: Direction.North, to: DamLobby.spec().ref }),
            new Passage({ via: Direction.South, to: DeepCanyon.spec().ref }),
        ];
    }
}
