import { Passage, Room, TopOfStairs } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { Direction, SpecialDirection } from '../../../parse';
import { TombOfTheUnknownImplementer } from '../TombOfTheUnknownImplementer';
import { CryptDoor, Lamp, Sword } from '../../items';
import { SpecialEnter, SpecialTimerTick } from '../../abilities';
import { Player } from '../../actors';
import { RoomState } from '../Room';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';

interface CryptState extends RoomState {
    timeUntilEndgame: number;
}

export class Crypt extends Room<CryptState> {
    static spec(): EntitySpec<Crypt> {
        return {
            ref: 'crypt',
            constructor: Crypt,
            initial: {
                timeUntilEndgame: 3,
                contents: [
                    CryptDoor.spec().ref,
                    // TOMB
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [enterEndgame],
        };
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    miscellaneousScore(): number {
        return 5;
    }

    ref() {
        return Crypt.spec().ref;
    }

    name(): string {
        return 'Crypt';
    }

    description(): string {
        const openClose = this.game.ent(CryptDoor).isOpen() ? 'open' : 'closed';
        return (
            'Though large and esthetically pleasing the marble crypt is empty; ' +
            'the sarcophagi, bodies, and rich treasure to be expected in a tomb of ' +
            'this magnificence are missing. Inscribed on one wall is the motto of ' +
            'the implementers, "Feel Free". There is a door leading out of ' +
            `the crypt to the south. The door is ${openClose}.`
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.South, SpecialDirection.Out],
                condition: () => this.game.ent(CryptDoor).isOpen(),
                message: 'The crypt is closed.',
                to: TombOfTheUnknownImplementer.spec().ref,
            }),
        ];
    }

    timeUntilEndgame() {
        return this.state.timeUntilEndgame;
    }

    setTimeUntilEndgame(timeUntilEndgame: number) {
        this.state.timeUntilEndgame = timeUntilEndgame;
    }
}

const enterEndgame: Handler = async ({ action, game, runner }) => {
    if (action.is(SpecialTimerTick)) {
        const player = game.ent(Player);
        const room = player.location();
        if (room.is(Crypt) && !game.isInEndgame()) {
            if (room.isLit()) {
                room.setTimeUntilEndgame(3);
            } else {
                room.setTimeUntilEndgame(room.timeUntilEndgame() - 1);
                if (room.timeUntilEndgame() <= 0) {
                    await runner.doOutput(PASSWORD_INSTRUCTIONS);
                    await goToEndgame(game, runner);
                }
            }
        }
        return Action.incomplete();
    }
};

export async function goToEndgame(game: Game, runner: Runner) {
    const player = game.ent(Player);
    game.setScore(game.ent(Crypt).miscellaneousScore());
    game.setIsInEndgame(true);
    game.ent(Lamp).moveTo(player);
    game.ent(Lamp).state.hasBeenTaken = true;
    // TODO power lamp forever
    game.ent(Sword).moveTo(player);
    game.ent(Sword).state.hasBeenTaken = true;
    await game.applyAction(
        runner,
        new SpecialEnter({ room: game.ent(TopOfStairs) })
    );
}

const PASSWORD_INSTRUCTIONS =
    'Suddenly, as you wait in the dark, you begin to feel somewhat disoriented. ' +
    'The feeling passes, but something seems different. As you regain your composure, ' +
    'the cloaked figure appears before you, and says, "You are now ready to face the ' +
    'ultimate challenge of Zork. Should you wish to do this somewhat more quickly in ' +
    'the future, you will be given a magic phrase which will at any time transport you ' +
    'by magic to this point. To select the phrase, say\n' +
    '  `incant "word"`\n' +
    'and you will be told your own magic phrase to use by saying\n' +
    '  `incant "phrase"`\n' +
    'Good luck, and choose wisely!';
