import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeReadable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Eat, SpecialJigsUp, Throw } from '../../abilities';
import { Actor, Player } from '../../actors';
import { MungedSource } from '../../rooms';
import { TeaRoom } from '../../rooms/TeaRoom';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';

export class OrangeCake extends makeReadable(makeTakeable(Item)) {
    static spec(): EntitySpec<OrangeCake> {
        return {
            ref: 'orange-cake',
            constructor: OrangeCake,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('cake', { collective: true }),
                new Noun('cakes', { plural: true }),
                new Noun('piece of cake'),
                new Noun('pieces of cake', { plural: true }),
                new Noun('piece of cake with orange icing'),
                new Noun('pieces of cake with orange icing', { plural: true }),
            ],
            adjectives: [new Adjective('orange')],
            handlers: [eatOrangeCake, throwOrangeCake],
        };
    }

    text(): string {
        return (
            'The only writing legible is a capital E. ' +
            'The rest is too small to be clearly visible.'
        );
    }

    enlargedText(): string {
        return 'Explode';
    }

    size(): number {
        return 4;
    }

    ref() {
        return OrangeCake.spec().ref;
    }

    name() {
        return 'piece of cake with orange icing';
    }

    description() {
        return 'There is a piece of cake with orange icing here.';
    }

    nouns(): Noun[] {
        return OrangeCake.spec().nouns;
    }

    adjectives(): Adjective[] {
        return OrangeCake.spec().adjectives;
    }

    isFood(): boolean {
        return true;
    }
}

async function explode(game: Game, runner: Runner, actor: Actor) {
    const room = actor.location();
    if (!room) return;
    room.state.isMunged = true;
    room.state.howMunged = MungedSource.BlastingCakes;
    await game.applyAction(
        runner,
        new SpecialJigsUp({
            message:
                'You have been blasted to smithereens (wherever they are).',
        }),
        actor
    );
}

const eatOrangeCake: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Eat) && action.item.is(OrangeCake)) {
        // TODO this
        await runner.doOutput(
            'The room around you seems to be getting smaller.'
        );
        const player = game.ent(Player);
        await explode(game, runner, player);
        return Action.complete();
    }
};

const throwOrangeCake: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Throw) && action.item.is(OrangeCake)) {
        const room = actor?.location();
        if (room?.is(TeaRoom)) {
            const player = game.ent(Player);
            await explode(game, runner, player);
            return Action.complete();
        }
    }
};
