import { Passage, Room } from '..';
import { EntitySpec } from '../../game';

export class EastGuardianNarrowRoom extends Room {
    static spec(): EntitySpec<EastGuardianNarrowRoom> {
        return {
            ref: 'east-guardian-narrow-room',
            constructor: EastGuardianNarrowRoom,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return EastGuardianNarrowRoom.spec().ref;
    }

    name(): string {
        return 'Narrow Room';
    }

    description(): string {
        return 'Cheater!';
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [];
    }
}
