import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Throw } from './Throw';
import { UnresolvedAction } from '../UnresolvedAction';

export class ThrowUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'throw';

    item: Target | undefined;

    enemy: Target | undefined;

    constructor({
        item,
        enemy,
    }: {
        item: Target | undefined;
        enemy: Target | undefined;
    }) {
        super();
        this.item = item;
        this.enemy = enemy;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'throw something';
        }
        if (this.enemy === undefined) {
            return `throw ${targetDescription(game, this.item)}`;
        }
        return `throw ${targetDescription(
            game,
            this.item
        )} at ${targetDescription(game, this.enemy)}`;
    }
}

export const throwUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(ThrowUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new ThrowUnresolved({ item, enemy: action.enemy }),
        missing: () => 'What would you like to throw?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to throw, ${opt}?`,
        condition: (item) => item.isItem() && actor.hasItem(item),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    const { item: enemy } = await game.resolve(runner, action.enemy, actor, {
        partial: (enemy) => new ThrowUnresolved({ enemy, item: action.item }),
        ambiguous: (desc, opt) =>
            `At which ${desc} would you like to throw ${item.the()}, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (!(await game.reach(runner, item, actor))) {
        return Action.complete();
    }

    if (!(await game.have(runner, item, actor))) {
        return Action.complete();
    }

    return game.applyAction(runner, new Throw({ enemy, item }));
};
