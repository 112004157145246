import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeReadable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class BoatInstructions extends makeFlammable(
    makeTakeable(makeReadable(Item))
) {
    static spec(): EntitySpec<BoatInstructions> {
        return {
            ref: 'boat-instructions',
            constructor: BoatInstructions,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('labels', { plural: true }),
                new Noun('label'),
                new Noun('instructions', { collective: true }),
                new Noun('fine print', { collective: true }),
                new Noun('fineprint', { collective: true }),
            ],
            adjectives: [new Adjective('tan')],
        };
    }

    ref() {
        return BoatInstructions.spec().ref;
    }

    name() {
        return 'tan label';
    }

    description() {
        return 'There is a tan label here.';
    }

    size() {
        return 2;
    }

    text() {
        return BOAT_INSTRUCTIONS;
    }

    nouns(): Noun[] {
        return BoatInstructions.spec().nouns;
    }

    adjectives(): Adjective[] {
        return BoatInstructions.spec().adjectives;
    }
}

const BOAT_INSTRUCTIONS = `  !!!!  FROBOZZ MAGIC BOAT COMPANY  !!!!

Hello, Sailor!

Instructions for use:
  To get into boat, say 'board'
  To leave boat, say 'disembark'
  To get into a body of water, say 'launch'
  To get to shore, say 'land'

Warranty:
  This boat is guaranteed against all defects in parts and workmanship for a period of 76 milliseconds from date of purchase or until first used, whichever comes first.

Warning:
  This boat is made of plastic. Good Luck!`;
