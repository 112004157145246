import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { BankEntrance } from '../BankEntrance';
import { SafetyDepository } from '../SafetyDepository';
import { WestViewingRoom } from '../WestViewingRoom';

export class WestTellersRoom extends Room {
    static spec(): EntitySpec<WestTellersRoom> {
        return {
            ref: 'west-tellers-room',
            constructor: WestTellersRoom,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return WestTellersRoom.spec().ref;
    }

    name(): string {
        return "West Teller's Room";
    }

    description(): string {
        return WEST_TELLER_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: WestViewingRoom.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                to: BankEntrance.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: SafetyDepository.spec().ref,
            }),
        ];
    }

    hasWalls(): boolean {
        return true;
    }
}

const WEST_TELLER_DESCRIPTION =
    'You are in a small square room, which was used by a ' +
    'bank officer whose job it was to retrieve safety deposit ' +
    'boxes for the customer. On the north side of the room is a ' +
    "sign which reads 'Viewing Room'. On the west side of room, " +
    'above an open door, is a sign reading\n\n  BANK PERSONNEL ONLY"';
