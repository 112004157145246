import { Value } from './Value';

export class Noun extends Value {
    plural: boolean;

    collective: boolean;

    singular: boolean;

    _article: string | undefined;

    constructor(
        value: string,
        {
            plural = false,
            collective = false,
            article,
        }: { plural?: boolean; collective?: boolean; article?: string } = {}
    ) {
        super(value);
        this.plural = plural;
        this.collective = collective;
        this.singular = !plural && !collective;
        this._article = article;
    }

    isEqual(other: Value): boolean {
        return (
            super.isEqual(other) &&
            other instanceof Noun &&
            this.plural === other.plural &&
            this.collective === other.collective &&
            this.singular === other.singular
        );
    }

    get article() {
        return this._article || 'aeiou'.includes(this.value[0]) ? 'an' : 'a';
    }
}
