import { Read } from './Read';
import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { UnresolvedAction } from '../UnresolvedAction';

export class ReadUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'read';

    item: Target | undefined;

    tool: Target | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Target | undefined;
        tool: Target | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'read something';
        }
        if (this.tool === undefined) {
            return `read ${targetDescription(game, this.item)}`;
        }
        return `read ${targetDescription(
            game,
            this.item
        )} through ${targetDescription(game, this.tool)}`;
    }
}

export const readUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(ReadUnresolved)) return;
    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new ReadUnresolved({ item, tool: action.tool }),
        missing: () => 'What would you like to read?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to read, ${opt}?`,
    });
    if (item === undefined) return Action.complete({ withConsequence: false });

    let tool;
    if (action.tool) {
        const { item: resolvedContainer } = await game.resolve(
            runner,
            action.tool,
            actor,
            {
                partial: (tool) =>
                    new ReadUnresolved({ item: action.item, tool }),
                ambiguous: (desc, opt) =>
                    `Through which ${desc} would you like to read ${item.the()}, ${opt}?`,
                condition: (item) =>
                    item.isItem() &&
                    item.isContainer() &&
                    (!item.isOpenable() || item.isOpen()),
            }
        );
        tool = resolvedContainer;

        if (tool) {
            if (!(await game.have(runner, tool, actor)))
                return Action.complete();
        } else {
            return Action.complete({ withConsequence: false });
        }
    }

    await game.tryTake(runner, item, actor);

    return await game.applyAction(runner, new Read({ item, tool }), actor);
};
