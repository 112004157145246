import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer } from '../../game/Entity';
import { EntitySpec } from '../../game';

export class SafeHole extends makeContainer(Item) {
    static spec(): EntitySpec<SafeHole> {
        return {
            ref: 'safe-hole',
            constructor: SafeHole,
            initial: {
                contents: [],
            },
            nouns: [
                new Noun('hole'),
                new Noun('slot'),
                new Noun('slots', { plural: true }),
                new Noun('holes', { plural: true }),
            ],
            adjectives: [new Adjective('small')],
            handlers: [],
        };
    }

    ref() {
        return SafeHole.spec().ref;
    }

    name(): string {
        return 'small slot';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed() {
        return false;
    }

    totalCapacity(): number {
        return 10;
    }

    nouns() {
        return SafeHole.spec().nouns;
    }

    adjectives() {
        return SafeHole.spec().adjectives;
    }
}
