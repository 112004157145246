import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Frobozz extends makeDescribable(Action) {
    id = 'frobozz';

    description(game: Game): string {
        return 'mention Frobozz';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('frobozz'),
            new Verb('say frobozz'),
            new Verb('mention frobozz'),
        ];
        return {
            handlers: [frobozzHandler],
            parser: () => Parse.words(verbs).into(new Frobozz()),
            verbs,
            prepositions: [],
        };
    }
}

export const frobozzHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Frobozz)) return;
    await runner.doOutput(
        'The FROBOZZ Corporation created, owns, and operates this dungeon.'
    );
    return Action.complete();
};
