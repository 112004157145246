import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

type Rec<I, X, K extends keyof X> = {
    type: 'good';
    name: K;
    parser: Parser<I, X[K]>;
};

export class CollectedSequenceParser<
    I,
    X extends { [K in keyof X]: X[K] }
> extends Parser<I, X> {
    parsers: (
        | { type: 'bad'; name: undefined; parser: Parser<I, unknown> }
        | Rec<I, X, keyof X>
    )[];

    constructor(
        ...parsers: (
            | Parser<I, unknown>
            | { type: 'bad'; name: undefined; parser: Parser<I, unknown> }
            | Rec<I, X, keyof X>
            | { name: keyof X; parser: Parser<I, X[keyof X]> }
        )[]
    ) {
        super();
        this.parsers = parsers.map((parser) => {
            if (parser instanceof Parser) {
                return { type: 'bad', name: undefined, parser };
            }
            return { type: 'good', ...parser };
        });
    }

    *match(tokens: Token<I>[]): Generator<Match<I, X>> {
        if (this.parsers.length === 0) {
            yield new Match(new Token({} as X, []), tokens);
            // TODO: This seems like arms-length recursion to me
        } else if (this.parsers.length === 1) {
            const parser = this.parsers[0];
            if (parser.type === 'good') {
                for (const match of parser.parser.match(tokens)) {
                    yield new Match(
                        new Token(
                            {
                                [parser.name]: match.token.value,
                            } as X,
                            [match.token]
                        ),
                        match.rest
                    );
                }
            } else {
                for (const match of parser.parser.match(tokens)) {
                    yield new Match(
                        new Token({} as X, [match.token]),
                        match.rest
                    );
                }
            }
        } else {
            const [firstParser, ...restParsers] = this.parsers;
            if (firstParser.type === 'good') {
                for (const firstMatch of firstParser.parser.match(tokens)) {
                    for (const restMatches of new CollectedSequenceParser(
                        ...restParsers
                    ).match(firstMatch.rest)) {
                        yield new Match(
                            new Token(
                                {
                                    [firstParser.name]: firstMatch.token.value,
                                    ...restMatches.token.value,
                                },
                                [firstMatch.token, ...restMatches.token.source]
                            ),
                            restMatches.rest
                        );
                    }
                }
            } else {
                for (const firstMatch of firstParser.parser.match(tokens)) {
                    for (const restMatches of new CollectedSequenceParser(
                        ...restParsers
                    ).match(firstMatch.rest)) {
                        yield new Match(
                            new Token({ ...restMatches.token.value }, [
                                firstMatch.token,
                                ...restMatches.token.source,
                            ]),
                            restMatches.rest
                        );
                    }
                }
            }
        }
    }
}
