import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push } from '../../abilities';

export class SidePanel extends Item {
    static spec(): EntitySpec<SidePanel> {
        return {
            ref: 'side-panel',
            constructor: SidePanel,
            initial: {},
            nouns: [
                new Noun('panel'),
                new Noun('panels', { plural: true }),
                new Noun('wall'),
                new Noun('walls', { plural: true }),
            ],
            adjectives: [new Adjective('wooden'), new Adjective('wood')],
            handlers: [pushPanel],
        };
    }

    ref() {
        return SidePanel.spec().ref;
    }

    name(): string {
        return 'wood panel';
    }

    description(): string {
        return '';
    }

    nouns() {
        return SidePanel.spec().nouns;
    }

    adjectives() {
        return SidePanel.spec().adjectives;
        // TODO "south" and "north" depending on where it is
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushPanel: Handler = async ({ action, runner }) => {
    if (action.is(Push) && action.item.is(SidePanel)) {
        await runner.doOutput("The structure won't budge.");
        return Action.complete();
    }
};
