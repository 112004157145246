import { Container, ContainerState, Item } from '../../items/Item';
import { listify } from '../../utils';
import { Constructor } from './Constructor';
import { Entity } from './Entity';

export function makeContainer<TBase extends Constructor<Item>>(Base: TBase) {
    abstract class C extends Base implements Container {
        abstract state: ContainerState;

        canSeeInto(): boolean {
            return !this.isOpenable() || this.isOpen() || this.isTransparent();
        }

        isContainer(): this is Container {
            return true;
        }

        canReachInto(): boolean {
            return !this.isOpenable() || this.isOpen();
        }

        isEmpty() {
            return this.contents().length === 0;
        }

        contents(): Entity[] {
            return this.state.contents.map((ref) => this.get(ref));
        }

        abstract totalCapacity(): number | undefined;

        remainingCapacity(): number | undefined {
            const total = this.totalCapacity();
            if (total === undefined) return undefined;
            return (
                total -
                this.contents().reduce(
                    (size, item) => size + (item.isItem() ? item.size() : 0),
                    0
                )
            );
        }

        contentsString() {
            return listify(this.contents().map((item) => item.an()));
        }

        contains(entity: Entity) {
            return this.state.contents.includes(entity.ref());
        }

        removeEntity(entity: Entity) {
            const index = this.state.contents.findIndex(
                (ref) => entity.ref() === ref
            );
            if (index !== undefined) {
                this.state.contents.splice(index, 1);
            }
        }

        addEntity(entity: Entity) {
            this.state.contents.push(entity.ref());
        }
    }
    return C;
}
