import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { LookIn, SpecialLook } from '../../abilities';
import { Player } from '../../actors';
import { WhitePalantir } from '../WhitePalantir';
import { RedPalantir } from '../RedPalantir';

export class BluePalantir extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<BluePalantir> {
        return {
            ref: 'blue-palantir',
            constructor: BluePalantir,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('sphere'),
                new Noun('spheres', { plural: true }),
                new Noun('ball'),
                new Noun('balls', { plural: true }),
                new Noun('palantir'),
                new Noun('palantirs', { plural: true }),
                new Noun('stone'),
                new Noun('stones', { plural: true }),
            ],
            adjectives: [
                new Adjective('seeing'),
                new Adjective('beautiful'),
                new Adjective('blue'),
                new Adjective('crystal'),
                new Adjective('glass'),
            ],
            handlers: [lookInPalantir],
        };
    }

    ref() {
        return BluePalantir.spec().ref;
    }

    name() {
        return 'blue crystal sphere';
    }

    description() {
        return 'There is blue crystal sphere here.';
    }

    initialDescription() {
        return 'In the center of the table sits a blue crystal sphere.';
    }

    size() {
        return 10;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 5;
    }

    nouns(): Noun[] {
        return BluePalantir.spec().nouns;
    }

    adjectives(): Adjective[] {
        return BluePalantir.spec().adjectives;
    }
}

const lookInPalantir: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(LookIn) &&
        (action.item.is(BluePalantir) ||
            action.item.is(WhitePalantir) ||
            action.item.is(RedPalantir)) &&
        actor?.is(Player)
    ) {
        const { item: palantir } = action;
        let otherPalantir;
        if (palantir.is(BluePalantir)) {
            otherPalantir = game.ent(RedPalantir);
        } else if (palantir.is(RedPalantir)) {
            otherPalantir = game.ent(WhitePalantir);
        } else {
            otherPalantir = game.ent(BluePalantir);
        }
        const container = otherPalantir.container();
        const otherRoom = otherPalantir.location();
        const canSeeOutIntoOtherRoom =
            otherRoom &&
            otherRoom.isLit() &&
            container &&
            (container.isRoom() ||
                container.isActor() ||
                (container.isItem() && container.canSeeInto()));
        if (!canSeeOutIntoOtherRoom) {
            await runner.doOutput('You see only darkness.');
        } else {
            await runner.doOutput(
                'As you peer into the sphere, a strange vision takes shape of a distant room, which can be described clearly...'
            );
            await game.applyAction(
                runner,
                new SpecialLook({
                    room: otherRoom,
                    force: true,
                    showRoomName: false,
                })
            );
            if (otherRoom?.isEqualTo(actor?.location())) {
                await runner.doOutput(
                    'An astonished adventurer is staring into a crystal sphere.'
                );
            }
            await runner.doOutput(
                'The vision fades, revealing only an ordinary crystal sphere.'
            );
        }
        return Action.complete();
    }
};
