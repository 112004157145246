import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that, given two parsers, `parser1` and `parser2`, yields all of
 * the possible matches of the first parser, then all of the possible matches
 * of the second parser.
 *
 * ```
 * word("cat").or(word("dog")).match(tokenize("cat"))
 * either(word("cat"), word("dog")).match(tokenize("dog"))
 * ```
 */
export class EitherParser<I, O1, O2> extends Parser<I, O1 | O2> {
    parser1: Parser<I, O1>;

    parser2: Parser<I, O2>;

    constructor(parser1: Parser<I, O1>, parser2: Parser<I, O2>) {
        super();
        this.parser1 = parser1;
        this.parser2 = parser2;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, O1 | O2>> {
        for (const parser of [this.parser1, this.parser2]) {
            for (const match of parser.match(tokens)) {
                yield match;
            }
        }
    }
}
