import { Action, Handler } from '../game';
import { Close, Open } from '../abilities';
import { Entity, EntityConstructor } from '../game/Entity';
import { Item, Openable } from './Item';
import { DUMMY } from '../constants';

export function badDoor<T extends Entity>({
    item,
    open,
    close,
}: {
    item: EntityConstructor<T>;
    open: string;
    close: string;
}): Handler {
    return async ({ action, runner }) => {
        if (action.is(Open) && action.item.is(item)) {
            await runner.doOutput(open);
            return Action.complete();
        }

        if (action.is(Close) && action.item.is(item)) {
            await runner.doOutput(close);
            return Action.complete();
        }
    };
}

export function goodDoor<T extends Item & Openable>({
    item,
    open,
    close,
}: {
    item: EntityConstructor<T>;
    open: string;
    close: string;
}): Handler {
    return async ({ action, runner, game }) => {
        if (action.is(Open) && action.item.is(item)) {
            const door = game.ent(item);
            if (door.isOpen()) {
                await runner.doOutput(game.choiceOf(DUMMY));
            } else {
                await runner.doOutput(open);
                door.state.isOpen = true;
            }
            return Action.complete();
        }

        if (action.is(Close) && action.item.is(item)) {
            const door = game.ent(item);
            if (door.isOpen()) {
                await runner.doOutput(close);
                door.state.isOpen = false;
            } else {
                await runner.doOutput(game.choiceOf(DUMMY));
            }
            return Action.complete();
        }
    };
}
