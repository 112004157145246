import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Bubble extends Item {
    static spec(): EntitySpec<Bubble> {
        return {
            ref: 'bubble',
            constructor: Bubble,
            initial: {},
            nouns: [
                new Noun('bubble'),
                new Noun('bubbles', { plural: true }),
                new Noun('indicator'),
                new Noun('indicators', { plural: true }),
                new Noun('led'),
                new Noun('leds', { plural: true }),
                new Noun('light'),
                new Noun('lights', { plural: true }),
            ],
            adjectives: [
                new Adjective('green'),
                new Adjective('plastic'),
                new Adjective('small'),
            ],
        };
    }

    ref() {
        return Bubble.spec().ref;
    }

    name(): string {
        return 'green bubble';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Bubble.spec().nouns;
    }

    adjectives() {
        return Bubble.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
