import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Mumble extends makeDescribable(Action) {
    id = 'mumble';

    description(game: Game): string {
        return 'mumble';
    }

    static ability(): Ability {
        const verbs = [new Verb('mumble'), new Verb('sigh')];
        return {
            handlers: [adventureHandler],
            parser: () => Parse.words(verbs).into(new Mumble()),
            verbs,
            prepositions: [],
        };
    }
}

export const adventureHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Mumble)) return;
    await runner.doOutput(
        "You'll have to speak up if you expect me to hear you!"
    );
    return Action.complete();
};
