import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { ShakeUnresolved, shakeUnresolvedHandler } from './ShakeUnresolved';

export class Shake extends Action {
    id = '~shake';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [shakeHandler, shakeUnresolvedHandler],
            parser,
            verbs: [new Verb('shake')],
            prepositions: [],
        };
    }
}

export const shakeHandler: Handler = async ({ action, runner, actor }) => {
    if (!action.is(Shake)) return;
    const { item } = action;
    if (item.isActor()) {
        await runner.doOutput('This seems to have no effect.');
    } else if (!item.isItem() || !item.isTakeable()) {
        await runner.doOutput("You can't take it; thus, you can't shake it!");
    } else if (item.isContainer() && !item.isEmpty()) {
        if (!item.isOpenable() || !item.isOpen()) {
            // TODO maybe better handle if the thing is transparent, like the bottle.
            await runner.doOutput(
                `It sounds like there is something inside ${item.the()}.`
            );
        } else {
            // TODO maybe handle better when you do this in a vehicle
            const location = actor?.container();
            item.contents().forEach((contained) => contained.moveTo(location));
            await runner.doOutput('All of the objects spill onto the floor.');
        }
    } else {
        await runner.doOutput('Nothing happens.');
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, ShakeUnresolved> => {
    const shake = Parse.words(Shake.ability().verbs);
    const shakeObject = shake.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // shake
        shake.into(new ShakeUnresolved({ item: undefined })),
        // shake button
        shakeObject.map((item) => new ShakeUnresolved({ item }))
    );
};
