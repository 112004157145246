import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeExaminable } from '../../game/Entity';
import { Board, Light, SpecialEnter } from '../../abilities';
import { BehindHouse, Kitchen } from '../../rooms';
import { KitchenWindow } from '../KitchenWindow';

export class House extends makeExaminable(Item) {
    static spec(): EntitySpec<House> {
        return {
            ref: 'house',
            constructor: House,
            initial: {},
            nouns: [
                new Noun('house'),
                new Noun('houses', { plural: true }),
                new Noun('home'),
                new Noun('homes', { plural: true }),
            ],
            adjectives: [
                new Adjective('beautiful'),
                new Adjective('white'),
                new Adjective('colonial'),
            ],
            handlers: [burnHouse, goInHouse],
        };
    }

    ref() {
        return House.spec().ref;
    }

    name(): string {
        return 'white house';
    }

    description(): string {
        return '';
    }

    nouns() {
        return House.spec().nouns;
    }

    adjectives() {
        return House.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    examineText(): string {
        return (
            'The house is a beautiful colonial house which is painted white. ' +
            'It is clear that the owners must have been extremely wealthy.'
        );
    }
}

// TODO maybe parse phrase "burn down house"
const burnHouse: Handler = async ({ action, runner }) => {
    if (action.is(Light) && action.item.is(House)) {
        await runner.doOutput('You must be joking.');
        return Action.complete();
    }
};

const goInHouse: Handler = async ({ action, runner, actor, game }) => {
    // TODO maybe only parse "go in house" and not "board house"
    if (action.is(Board) && action.vehicle.is(House)) {
        await runner.doOutput('You must be joking.');
        if (actor?.location()?.is(BehindHouse)) {
            if (game.ent(KitchenWindow).isOpen()) {
                await game.applyAction(
                    runner,
                    new SpecialEnter({ room: game.ent(Kitchen) })
                );
            } else {
                await runner.doOutput('The window is closed.');
            }
        } else {
            await runner.doOutput("I can't see how to get in from here.");
        }
        return Action.complete();
    }
};
