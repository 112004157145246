import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that, given any number of other parsers, will parse
 * each parser's rules in sequence. Performs recursive backtracking
 * to yield all the possible matches of the given parsers in sequence.
 *
 * ```
 * sequence(word("the"), whitespace(), word("dog")).match(tokenize("the dog"))
 * ```
 */
export class SequenceParser<I, T> extends Parser<I, T[]> {
    parsers: Parser<I, T>[];

    constructor(...parsers: Parser<I, T>[]) {
        super();
        this.parsers = parsers;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, T[]>> {
        if (this.parsers.length === 0) {
            yield new Match(new Token([], []), tokens);
            // TODO: This seems like arms-length recursion to me
        } else if (this.parsers.length === 1) {
            for (const match of this.parsers[0].match(tokens)) {
                yield new Match(
                    new Token([match.token.value], [match.token]),
                    match.rest
                );
            }
        } else {
            const [firstParser, ...restParsers] = this.parsers;
            for (const firstMatch of firstParser.match(tokens)) {
                for (const restMatches of new SequenceParser(
                    ...restParsers
                ).match(firstMatch.rest)) {
                    yield new Match(
                        new Token(
                            [firstMatch.token.value].concat(
                                restMatches.token.value
                            ),
                            [firstMatch.token, ...restMatches.token.source]
                        ),
                        restMatches.rest
                    );
                }
            }
        }
    }
}
