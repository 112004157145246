import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { WideLedge } from '../WideLedge';
import { Balloon } from '../../items';

export class VolcanoNearWideLedge extends Room {
    static spec(): EntitySpec<VolcanoNearWideLedge> {
        return {
            ref: 'volcano-near-wide-ledge',
            constructor: VolcanoNearWideLedge,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return VolcanoNearWideLedge.spec().ref;
    }

    name(): string {
        return 'Volcano Near Wide Ledge';
    }

    description(): string {
        return (
            'You are near the rim of the volcano which is only ' +
            'about 15 feet across. To the west, there is a place to land on a wide ledge.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [SpecialDirection.Land, Direction.West],
                to: WideLedge.spec().ref,
                allowedVehicles: [this.game.ent(Balloon)],
            }),
        ];
    }

    isOnLand(): boolean {
        return false;
    }

    isSacred() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
