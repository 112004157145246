import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { SouthCorridor } from '../SouthCorridor';
import { DungeonEntrance } from '../DungeonEntrance';
import { QuestionDoor } from '../../items';
import { DungeonMaster } from '../../actors';

export class NarrowCorridor extends Room {
    static spec(): EntitySpec<NarrowCorridor> {
        return {
            ref: 'narrow-corridor',
            constructor: NarrowCorridor,
            initial: {
                contents: [QuestionDoor.spec().ref, DungeonMaster.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    scoreOnEntry() {
        return 20;
    }

    ref() {
        return NarrowCorridor.spec().ref;
    }

    name(): string {
        return 'Narrow Corridor';
    }

    description(): string {
        const openClose = this.game.ent(QuestionDoor).isOpen()
            ? 'open'
            : 'closed';
        return (
            'You are in a narrow north-south corridor. ' +
            'At the south end is a door and at the north end is an east-west corridor. ' +
            `The door is ${openClose}.`
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: SouthCorridor.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                condition: () => this.game.ent(QuestionDoor).isOpen(),
                to: DungeonEntrance.spec().ref,
                message: 'The door is shut',
            }),
        ];
    }
}
