import { Token } from '../lexicon';

/**
 * A type to represent a successful match. A parser's match function
 * should always be a generator of Match instances.
 */
export class Match<I, T> {
    token: Token<T>;

    rest: Token<I>[];

    constructor(token: Token<T>, rest: Token<I>[]) {
        this.token = token;
        this.rest = rest;
    }
}
