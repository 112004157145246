import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { PourOnUnresolved, pourUnresolvedHandler } from './PourOnUnresolved';

export class PourOn extends Action {
    id = '~pour';

    item: Entity;

    surface: Entity;

    constructor({ item, surface }: { item: Entity; surface: Entity }) {
        super();
        this.item = item;
        this.surface = surface;
    }

    static ability(): Ability {
        return {
            handlers: [pourHandler, pourUnresolvedHandler],
            parser,
            verbs: [
                new Verb('pour'),
                new Verb('pour out'),
                new Verb('spill'),
                new Verb('dump'),
                new Verb('dump out'),
            ],
            prepositions: [
                new Preposition('on'),
                new Preposition('onto'),
                new Preposition('on to'),
            ],
        };
    }
}

export const pourHandler: Handler = async ({ action, runner, actor }) => {
    if (!action.is(PourOn) || actor === undefined) return;
    const { item, surface } = action;

    if (!item.isItem() || !item.isLiquid()) {
        await runner.doOutput("You can't pour that on anything.");
    } else {
        item.moveTo(undefined);
        await runner.doOutput(
            `The liquid spills over ${surface.the()} and onto the floor where it evaporates.`
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, PourOnUnresolved> => {
    const ability = PourOn.ability();
    const pour = Parse.words(ability.verbs);
    const pourObject = pour.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // pour the water on the bell
        pourObject.chain((item) =>
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
                .map(
                    (surface) =>
                        new PourOnUnresolved({
                            item,
                            surface,
                        })
                )
        )
    );
};
