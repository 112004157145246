import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    ExtinguishUnresolved,
    extinguishUnresolvedHandler,
} from './ExtinguishUnresolved';

export class Extinguish extends Action {
    id = '~extinguish';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [extinguishHandler, extinguishUnresolvedHandler],
            parser,
            verbs: [
                new Verb('extinguish'),
                new Verb('unlight'),
                new Verb('douse'),
                new Verb('snuff'),
                new Verb('put out'),
                new Verb('smother'),
                // TODO blow out as a separate action
            ],
            prepositions: [],
        };
    }
}

export const extinguishHandler: Handler = async ({ action, runner, actor }) => {
    if (!action.is(Extinguish)) return;
    const { item } = action;
    if (item.isItem() && item.isFlammable()) {
        if (item.isAflame()) {
            item.state.isAflame = false;
            await runner.doOutput(`${item.The()} is extinguished.`);
            if (!actor?.location()?.isLit()) {
                await runner.doOutput(`It is now pitch black.`);
            }
        } else {
            await runner.doOutput(`${item.The()} is not lighted.`);
        }
    } else {
        await runner.doOutput(`I don't think you can extinguish ${item.an()}`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, ExtinguishUnresolved> => {
    const extinguish = Parse.words(Extinguish.ability().verbs);
    const extinguishObject = extinguish.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // extinguish
        extinguish.into(new ExtinguishUnresolved({ item: undefined })),
        // extinguish lamp
        extinguishObject.map((item) => new ExtinguishUnresolved({ item }))
    );
};
