import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { LookInUnresolved, lookInUnresolvedHandler } from './LookInUnresolved';
import { SpecialListContents } from '../SpecialListContents';

export class LookIn extends Action {
    id = '~look-in';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [lookInHandler, lookInUnresolvedHandler],
            parser,
            verbs: [
                new Verb('look'),
                new Verb('gaze'),
                new Verb('peer'),
                new Verb('stare'),
            ],
            prepositions: [
                new Preposition('in'),
                new Preposition('into'),
                new Preposition('in to'),
                new Preposition('inside'),
                new Preposition('through'),
            ],
        };
    }
}

export const lookInHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(LookIn)) return;
    const { item } = action;
    if (item.isItem() && item.isDoor()) {
        if (item.isOpenable() && item.isOpen()) {
            await runner.doOutput(
                `${item.The()} is open, but I can't tell what's beyond it.`
            );
        } else {
            await runner.doOutput(`${item.The()} is closed.`);
        }
    } else if (item.isItem() && item.isContainer()) {
        if (item.canSeeInto()) {
            if (item.isEmpty()) {
                await runner.doOutput(`${item.The()} is empty.`);
            } else {
                await game.applyAction(
                    runner,
                    new SpecialListContents({ item })
                );
            }
        } else {
            await runner.doOutput(`${item.The()} is closed.`);
        }
    } else {
        await runner.doOutput(`I don't know how to look inside ${item.an()}.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, LookInUnresolved> => {
    const ability = LookIn.ability();
    const lookIn = Parse.words(ability.verbs)
        .then(Parse.whitespace())
        .then(Parse.words(ability.prepositions));
    const lookInObject = lookIn.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // look in
        lookIn.into(new LookInUnresolved({ item: undefined })),
        // look in window
        lookInObject.map((item) => new LookInUnresolved({ item }))
    );
};
