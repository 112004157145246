import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeOpenable } from '../../game/Entity';
import { EntitySpec } from '../../game';

export class Machine extends makeOpenable(makeContainer(Item)) {
    static spec(): EntitySpec<Machine> {
        return {
            ref: 'machine',
            constructor: Machine,
            initial: {
                contents: [],
                isOpen: false,
            },
            nouns: [
                new Noun('machine'),
                new Noun('machines', { plural: true }),
                new Noun('pdp10'),
                new Noun('pdp10s', { plural: true }),
                new Noun('pdp-10'),
                new Noun('pdp-10s', { plural: true }),
                new Noun('dryer'),
                new Noun('dryers', { plural: true }),
                new Noun('lid'),
                new Noun('lids', { plural: true }),
            ],
            adjectives: [new Adjective('large')],
        };
    }

    ref() {
        return Machine.spec().ref;
    }

    name(): string {
        return 'machine';
    }

    description(): string {
        const openClose = this.isOpen() ? 'open' : 'closed';
        return (
            'In one corner there is a machine (?) which is shaped ' +
            "somewhat like a clothes dryer. On the 'panel' there is a " +
            'switch which is labelled in a dialect of Swahili. ' +
            "Fortunately, I know this dialect and the label translates to 'start'. " +
            'The switch does not appear to be manipulable by any human hand ' +
            '(unless the fingers are about 1/16 by 1/4 inch). ' +
            `On the front of the machine is a large lid, which is ${openClose}.`
        );
    }

    totalCapacity(): number {
        return 50;
    }

    nouns() {
        return Machine.spec().nouns;
    }

    adjectives() {
        return Machine.spec().adjectives;
    }
}
