import { Parse, SpecialDirection, Verb } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { Go } from '../Go';
import { Player } from '../../actors';
import { Disembark } from '../Disembark';

export class Exit extends makeDescribable(Action) {
    id = 'exit';

    description(game: Game): string {
        return 'exit';
    }

    static ability(): Ability {
        const verbs = [new Verb('exit'), new Verb('go out'), new Verb('leave')];
        return {
            handlers: [enterHandler],
            parser: () => Parse.words(verbs).into(new Exit()),
            verbs,
            prepositions: [],
        };
    }
}

export const enterHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(Exit)) return;
    const vehicle = game.ent(Player).vehicle();
    if (vehicle) {
        await game.applyAction(runner, new Disembark({ vehicle }));
    } else {
        await game.applyAction(
            runner,
            new Go({ direction: SpecialDirection.Out }),
            actor
        );
    }
    return Action.complete();
};
