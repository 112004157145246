import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { FillUnresolved, fillUnresolvedHandler } from './FillUnresolved';
import { YUKS } from '../../constants';

export class Fill extends Action {
    id = '~fill';

    container: Entity;

    item: Entity | undefined;

    constructor({
        container,
        item,
    }: {
        container: Entity;
        item: Entity | undefined;
    }) {
        super();
        this.container = container;
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [fillHandler, fillUnresolvedHandler],
            parser,
            verbs: [new Verb('fill')],
            prepositions: [new Preposition('with'), new Preposition('in')],
        };
    }
}

export const fillHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Fill)) return;
    if (action.item) {
        await runner.doOutput(game.choiceOf(YUKS));
    } else {
        await runner.doOutput('With what?');
        // TODO set partial
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, FillUnresolved> => {
    const ability = Fill.ability();
    const fill = Parse.words(ability.verbs);
    const fillContainer = fill.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const fillContainerWithItem = fillContainer.chain((container) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((item) => [item, container])
    );
    return Parse.any(
        // fill
        fill.into(
            new FillUnresolved({ container: undefined, item: undefined })
        ),
        // fill bottle
        fillContainer.map(
            (container) => new FillUnresolved({ container, item: undefined })
        ),
        // fill bottle with water
        fillContainerWithItem.map(
            ([item, container]) => new FillUnresolved({ container, item })
        )
    );
};
