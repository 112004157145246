import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { Jump, SpecialJigsUp } from '../../abilities';
import { JUMP_DEATH } from '../../constants';
import { RockyCrawl } from '../RockyCrawl';
import { TorchRoom } from '../TorchRoom';
import { Dome, Railing, Rope } from '../../items';

export class DomeRoom extends Room {
    static spec(): EntitySpec<DomeRoom> {
        return {
            ref: 'dome-room',
            constructor: DomeRoom,
            initial: {
                contents: [Railing.spec().ref, Dome.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [jumpOffRailing],
        };
    }

    ref() {
        return DomeRoom.spec().ref;
    }

    name(): string {
        return 'Dome Room';
    }

    description(): string {
        let description =
            'You are at the periphery of a large dome, which forms the ' +
            'ceiling of another room below. Protecting you from a ' +
            'precipitous drop is a wooden railing which circles the dome.';
        if (this.game.ent(Rope).fastenedItem()?.is(Railing)) {
            description +=
                '\nHanging down from the railing is a rope which ends about ten feet from the floor below.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.East,
                to: RockyCrawl.spec().ref,
            }),
            new Passage({
                via: Direction.Down,
                to: TorchRoom.spec().ref,
                condition: () => {
                    const fastenedTo = this.game.ent(Rope).fastenedItem();
                    return fastenedTo !== undefined && fastenedTo.is(Railing);
                },
                message: 'You cannot go down without fracturing many bones.',
            }),
        ];
    }
}

// TODO climb down rope

// TODO jump over
// TODO jump off
const jumpOffRailing: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Jump) && actor?.location()?.is(DomeRoom)) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({ message: game.choiceOf(JUMP_DEATH) })
        );
        return Action.complete();
    }
};
