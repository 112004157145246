import {
    CoalMine1,
    CoalMine2,
    CoalMine3,
    CoalMine4,
    CoalMine5,
    CoalMine6,
    CoalMine7,
    LadderBottom,
    LadderTop,
    Passage,
    Room,
} from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { SqueakyRoom } from '../SqueakyRoom';
import { Garlic, Jade } from '../../items';
import { SpecialEnter } from '../../abilities';
import { Bat, Player } from '../../actors';

export class BatRoom extends Room {
    static spec(): EntitySpec<BatRoom> {
        return {
            ref: 'bat-room',
            constructor: BatRoom,
            initial: {
                contents: [Bat.spec().ref, Jade.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [enterBatRoom],
        };
    }

    ref() {
        return BatRoom.spec().ref;
    }

    name(): string {
        return 'Bat Room';
    }

    description(): string {
        let description =
            'You are in a small room which has only one door, to the east.';
        const garlic = this.game.ent(Garlic);
        if (garlic.location()?.is(BatRoom)) {
            description +=
                '\nIn the corner of the room on the ceiling is a large vampire ' +
                'bat who is obviously deranged and holding his nose.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: SqueakyRoom.spec().ref }),
        ];
    }
}

const enterBatRoom: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialEnter) && action.room.is(BatRoom)) {
        const garlic = game.ent(Garlic);
        if (
            !(
                game.ent(Player).hasItem(garlic) ||
                garlic.location()?.is(BatRoom)
            )
        ) {
            const BAT_ROOMS: Room[] = [
                game.ent(CoalMine1),
                game.ent(CoalMine2),
                game.ent(CoalMine3),
                game.ent(CoalMine4),
                game.ent(CoalMine5),
                game.ent(CoalMine6),
                game.ent(CoalMine7),
                game.ent(LadderTop),
                game.ent(LadderBottom),
            ];
            const room = game.choiceOf(BAT_ROOMS);
            await runner.doOutput(
                'A deranged giant vampire bat (a reject from WUMPUS) ' +
                    'swoops down from his belfry and lifts you away...'
            );
            await game.applyAction(runner, new SpecialEnter({ room }));
            return Action.complete();
        }
    }
};
