import { Passage, Room } from '..';
import { DEAD_END_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Maze4 } from '../Maze4';

export class DeadEnd1 extends Room {
    static spec(): EntitySpec<DeadEnd1> {
        return {
            ref: 'dead-end-1',
            constructor: DeadEnd1,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return DeadEnd1.spec().ref;
    }

    name(): string {
        return 'Dead End';
    }

    description(): string {
        return DEAD_END_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [new Passage({ via: Direction.South, to: Maze4.spec().ref })];
    }

    // TODO
    isPartOfMaze() {
        return true;
    }
}
