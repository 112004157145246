import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class LongPole extends Item {
    static spec(): EntitySpec<LongPole> {
        return {
            ref: 'long-pole',
            constructor: LongPole,
            initial: {},
            nouns: [new Noun('pole'), new Noun('poles', { plural: true })],
            adjectives: [new Adjective('long')],
            handlers: [],
        };
    }

    ref() {
        return LongPole.spec().ref;
    }

    name(): string {
        return 'long pole';
    }

    description(): string {
        return '';
    }

    nouns() {
        return LongPole.spec().nouns;
    }

    adjectives() {
        return LongPole.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
