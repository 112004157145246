import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Echo extends makeDescribable(Action) {
    id = 'echo';

    description(game: Game): string {
        return 'say "echo"';
    }

    static ability(): Ability {
        // TODO 'say echo', 'say "echo"'
        const verbs = [new Verb('echo')];
        return {
            handlers: [echoHandler],
            parser: () => Parse.words(verbs).into(new Echo()),
            verbs,
            prepositions: [],
        };
    }
}

export const echoHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Echo)) return;

    const message = `Echo echo...`;
    await runner.doOutput(message);
    return Action.complete();
};
