import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Coal } from '../../items/Coal';
import { LadderBottom } from '../LadderBottom';

export class CoalMineDeadEnd extends Room {
    static spec(): EntitySpec<CoalMineDeadEnd> {
        return {
            ref: 'coal-mine-dead-end',
            constructor: CoalMineDeadEnd,
            initial: {
                contents: [Coal.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return CoalMineDeadEnd.spec().ref;
    }

    name(): string {
        return 'Dead End';
    }

    description(): string {
        // TODO ???
        return 'You have reached a dead end.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: LadderBottom.spec().ref,
            }),
        ];
    }
}
