import { Item, TakeableState, Treasure } from '../../items/Item';
import { Constructor } from './Constructor';

export function makeTreasure<TBase extends Constructor<Item>>(Base: TBase) {
    abstract class C extends Base implements Treasure {
        abstract state: TakeableState;

        isTreasure(): this is Treasure {
            return true;
        }

        scoreOnTake(): number {
            return 0;
        }

        scoreInCase(): number {
            return 0;
        }
    }

    return C;
}
