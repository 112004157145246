import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { BankEntrance } from '../BankEntrance';
import { EastViewingRoom } from '../EastViewingRoom';
import { SafetyDepository } from '../SafetyDepository';

export class EastTellersRoom extends Room {
    static spec(): EntitySpec<EastTellersRoom> {
        return {
            ref: 'east-tellers-room',
            constructor: EastTellersRoom,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return EastTellersRoom.spec().ref;
    }

    name(): string {
        return "East Teller's Room";
    }

    description(): string {
        return EAST_TELLER_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: EastViewingRoom.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                to: BankEntrance.spec().ref,
            }),
            new Passage({
                via: Direction.East,
                to: SafetyDepository.spec().ref,
            }),
        ];
    }

    hasWalls(): boolean {
        return true;
    }
}

const EAST_TELLER_DESCRIPTION =
    'You are in a small square room, which was used by a ' +
    'bank officer whose job it was to retrieve safety deposit ' +
    'boxes for the customer. On the north side of the room is a ' +
    "sign which reads 'Viewing Room'. On the east side of room, " +
    'above an open door, is a sign reading\n\n  BANK PERSONNEL ONLY"';
