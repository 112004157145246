import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Lungs extends Item {
    static spec(): EntitySpec<Lungs> {
        return {
            ref: 'lungs',
            constructor: Lungs,
            initial: {},
            nouns: [
                new Noun('lungs', { collective: true }),
                new Noun('pair of lungs'),
                new Noun('set of lungs'),
            ],
            adjectives: [
                // TODO I know this isn't really an adjective...
                new Adjective('my'),
                new Adjective('my own'),
            ],
        };
    }

    isTool(): boolean {
        return true;
    }

    ref() {
        return Lungs.spec().ref;
    }

    name(): string {
        return 'pair of lungs';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Lungs.spec().nouns;
    }

    adjectives() {
        return Lungs.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
