import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeReadable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Leaflet extends makeFlammable(makeTakeable(makeReadable(Item))) {
    static spec(): EntitySpec<Leaflet> {
        return {
            ref: 'leaflet',
            constructor: Leaflet,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('leaflet'),
                new Noun('pamphlet'),
                new Noun('paper'),
                new Noun('leaflets', { plural: true }),
                new Noun('pamphlets', { plural: true }),
                new Noun('papers', { plural: true }),
                new Noun('paper', { collective: true }),
                new Noun('mail', { collective: true }),
            ],
            adjectives: [new Adjective('small')],
        };
    }

    ref() {
        return Leaflet.spec().ref;
    }

    name() {
        return 'leaflet';
    }

    description() {
        return 'There is a small leaflet here.';
    }

    size() {
        return 2;
    }

    text() {
        return LEAFLET_TEXT;
    }

    nouns(): Noun[] {
        return Leaflet.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Leaflet.spec().adjectives;
    }
}

const LEAFLET_TEXT = `
WELCOME TO ZORK

ZORK is a game of adventure, danger, and low cunning. In it you
will explore some of the most amazing territory ever seen by mortal
man. Hardened adventurers have run screaming from the terrors
contained within!

In ZORK the intrepid explorer delves into the forgotten secrets
of a lost labyrinth deep in the bowels of the earth, searching for
vast treasures long hidden from prying eyes, treasures guarded by
fearsome monsters and diabolical traps!

No PDP-10 should be without one!

ZORK was created at the MIT Laboratory for Computer Science, by
Tim Anderson, Marc Blank, Bruce Daniels, and Dave Lebling. It was
inspired by the ADVENTURE game of Crowther and Woods, and the long
tradition of fantasy and science fiction adventure. ZORK is written
in MDL (alias MUDDLE).

On-line information may be available using the HELP and INFO
commands (most systems).

Direct inquiries, comments, etc. by Net mail to ZORK@MIT-DMS.

(c) Copyright 1978,1979 Massachusetts Institute of Technology.
        All rights reserved.`;
