import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { SafetyDepository } from '../SafetyDepository';
import { Portrait } from '../../items';

export class ChairmansOffice extends Room {
    static spec(): EntitySpec<ChairmansOffice> {
        return {
            ref: 'chairmans-office',
            constructor: ChairmansOffice,
            initial: {
                contents: [Portrait.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return ChairmansOffice.spec().ref;
    }

    name(): string {
        return "Chairman's Office";
    }

    description(): string {
        return CHAIRMAN_OFFICE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: SafetyDepository.spec().ref,
            }),
        ];
    }

    hasWalls(): boolean {
        return true;
    }
}

const CHAIRMAN_OFFICE_DESCRIPTION =
    'This room was the office of the Chairman of the Bank of Zork. ' +
    'Like the other rooms here, it has been extensively vandalized. ' +
    'The lone exit is to the north.';
