import { Cave1, ColdPassage, Passage, Room, SteepCrawlway } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { MagicMirror } from '../../items';

export class MirrorRoom1 extends Room {
    static spec(): EntitySpec<MirrorRoom1> {
        return {
            ref: 'mirror-room-1',
            constructor: MirrorRoom1,
            initial: {
                contents: [MagicMirror.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return MirrorRoom1.spec().ref;
    }

    name(): string {
        return 'Mirror Room';
    }

    description(): string {
        let description =
            'You are in a large square room with tall ceilings. ' +
            'On the south wall is an enormous mirror which fills the entire wall. ' +
            'There are exits on the other three sides of the room.';
        if (this.game.ent(MagicMirror).isMunged()) {
            description +=
                '\nUnfortunately, the mirror has been destroyed by your recklessness.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: ColdPassage.spec().ref }),
            new Passage({ via: Direction.North, to: SteepCrawlway.spec().ref }),
            new Passage({ via: Direction.East, to: Cave1.spec().ref }),
        ];
    }
}
