import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Coal extends makeFlammable(makeTakeable(Item)) {
    static spec(): EntitySpec<Coal> {
        return {
            ref: 'coal',
            constructor: Coal,
            initial: {
                hasBeenTaken: false,
                isAflame: false,
            },
            nouns: [
                new Noun('piece of coal'),
                new Noun('pieces of coal', { plural: true }),
                new Noun('pile of coal'),
                new Noun('heap of coal'),
                new Noun('pile'),
                new Noun('heap'),
                new Noun('coal', { collective: true }),
            ],
            adjectives: [new Adjective('small')],
        };
    }

    ref() {
        return Coal.spec().ref;
    }

    name() {
        return 'small pile of coal';
    }

    description() {
        return 'There is a small heap of coal here.';
    }

    nouns(): Noun[] {
        return Coal.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Coal.spec().adjectives;
    }

    size(): number {
        return 20;
    }
}
