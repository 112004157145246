import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { OFFENDED } from '../../constants';

export class Curse extends makeDescribable(Action) {
    id = 'curse';

    description(game: Game): string {
        return 'curse';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('curse'),
            new Verb('fuck'),
            new Verb('shit'),
            new Verb('damn'),
            new Verb('bitch'),
            new Verb('crap'),
        ];
        return {
            handlers: [curseHandler],
            // TODO 'say "fuck"' / "shout"
            parser: () => Parse.words(verbs).into(new Curse()),
            verbs,
            prepositions: [],
        };
    }
}

export const curseHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Curse)) return;
    await runner.doOutput(game.choiceOf(OFFENDED));
    return Action.complete();
};
