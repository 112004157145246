import { Item, Readable } from '../../items/Item';
import { Constructor } from './Constructor';

export function makeReadable<TBase extends Constructor<Item>>(Base: TBase) {
    abstract class C extends Base implements Readable {
        isReadable(): this is Readable {
            return true;
        }

        abstract text(): string;

        canBeRead(): boolean {
            return true;
        }

        shouldTakeToRead(): boolean {
            return this.isTakeable();
        }
    }

    return C;
}
