import { EntitySpec } from '../../game';
import { RoomInAPuzzle } from '../RoomInAPuzzle11';

export class RoomInAPuzzle34 extends RoomInAPuzzle {
    static spec(): EntitySpec<RoomInAPuzzle34> {
        return {
            ref: 'room-in-a-puzzle-3-4',
            constructor: RoomInAPuzzle34,
            initial: {
                containsSandstone: false,
                hasWestLadder: false,
                hasEastLadder: false,
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    row() {
        return 3;
    }

    column() {
        return 4;
    }

    ref() {
        return RoomInAPuzzle34.spec().ref;
    }
}
