import { SpecialListContents } from '..';
import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Inventory extends makeDescribable(Action) {
    id = 'inventory';

    description(game: Game): string {
        return 'check your inventory';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('inventory'),
            new Verb('i'),
            new Verb('check inventory'),
        ];
        return {
            handlers: [inventoryHandler],
            parser: () => Parse.words(verbs).into(new Inventory()),
            verbs,
            prepositions: [],
        };
    }
}

export const inventoryHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(Inventory) || actor === undefined) return;
    // TODO handle text for other actors
    if (actor.inventory().length !== 0) {
        await game.applyAction(
            runner,
            new SpecialListContents({
                item: actor,
                leadIn: 'You are carrying:',
            })
        );
    } else {
        await runner.doOutput('You are empty-handed.');
    }
    return Action.complete();
};
