import { Action, Effect, Handler } from '../../game';
import { SpecialAfter } from '../../abilities';

const handler: Handler = async ({ action, game }) => {
    if (!action.is(SpecialAfter)) return;
    if (action.effect !== Effect.NoConsequence) {
        game.state.moveCount += 1;
    }
    return Action.incomplete();
};

export const CountMoves = {
    handler: () => handler,
};
