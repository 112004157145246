import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Bell, GraniteWall, Prayer } from '../../items';
import { Altar } from '../Altar';
import { GrailRoom } from '../GrailRoom';

export class Temple extends Room {
    static spec(): EntitySpec<Temple> {
        return {
            ref: 'temple',
            constructor: Temple,
            initial: {
                contents: [
                    Prayer.spec().ref,
                    Bell.spec().ref,
                    GraniteWall.spec().ref,
                    // Marble Pillars?
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Temple.spec().ref;
    }

    name(): string {
        return 'Temple';
    }

    description(): string {
        return (
            'This is the west end of a large temple. ' +
            'On the south wall is an ancient inscription, probably a prayer ' +
            'in a long-forgotten language. The north wall is solid granite. ' +
            'The entrance at the west end of the room is through huge marble pillars.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.West,
                to: GrailRoom.spec().ref,
            }),
            new Passage({
                via: Direction.East,
                to: Altar.spec().ref,
            }),
        ];
    }

    hasNorthWall() {
        return false;
    }
}
