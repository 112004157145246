import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Pour } from './Pour';
import { UnresolvedAction } from '../UnresolvedAction';

export class PourUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'pour';

    item: Target | undefined;

    container: Target | undefined;

    constructor({
        item,
        container,
    }: {
        item: Target | undefined;
        container: Target | undefined;
    }) {
        super();
        this.item = item;
        this.container = container;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'put something';
        }
        if (this.container === undefined) {
            return `put ${targetDescription(game, this.item)} in something`;
        }
        return `put ${targetDescription(
            game,
            this.item
        )} into ${targetDescription(game, this.container)}`;
    }
}

export const pourUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(PourUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) =>
            new PourUnresolved({ item, container: action.container }),
        missing: () => 'What would you like to pour?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to pour, ${opt}?`,
        condition: (item) => actor.hasItem(item),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    let container;
    if (action.container) {
        const { item: resolvedContainer } = await game.resolve(
            runner,
            action.container,
            actor,
            {
                partial: (container) =>
                    new PourUnresolved({ item: action.item, container }),
                missing: () => `What do you want to put ${item.the()} in?`,
                ambiguous: (desc, opt) =>
                    `In which ${desc} would you like to put ${item.the()}, ${opt}?`,
                condition: (item) =>
                    item.isItem() &&
                    item.isContainer() &&
                    (!item.isOpenable() || item.isOpen()),
            }
        );
        container = resolvedContainer;

        if (container) {
            if (!container.isItem() || !container.isContainer()) {
                await runner.doOutput("You can't pour things into that.");
                return Action.complete();
            }

            if (container.isOpenable() && !container.isOpen()) {
                await runner.doOutput(`${container.The()} is closed.`);
                return Action.complete();
            }
        }
    }

    const actualContainer = item.container();

    if (
        actualContainer?.isItem() &&
        actualContainer.isOpenable() &&
        !actualContainer.isOpen()
    ) {
        await runner.doOutput(`${actualContainer.The()} is closed.`);
        return Action.complete();
    }

    if (await game.reach(runner, item, actor)) {
        await game.applyAction(runner, new Pour({ item, container }));
    }
    return Action.complete();
};
