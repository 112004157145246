import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Lubricate } from '../../abilities/Lubricate';

export class Gunk extends makeFlammable(makeTakeable(Item)) {
    static spec(): EntitySpec<Gunk> {
        return {
            ref: 'gunk',
            constructor: Gunk,
            initial: {
                hasBeenTaken: false,
                isAflame: false,
            },
            nouns: [
                new Noun('putty', { collective: true }),
                new Noun('material', { collective: true }),
                new Noun('gunk', { collective: true }),
                new Noun('glue', { collective: true }),
            ],
            adjectives: [new Adjective('magic'), new Adjective('viscous')],
            handlers: [lubricateWithGunk],
        };
    }

    ref() {
        return Gunk.spec().ref;
    }

    isTool() {
        return true;
    }

    name() {
        return 'viscous material';
    }

    description() {
        return 'There is some gunk here.';
    }

    nouns(): Noun[] {
        return Gunk.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Gunk.spec().adjectives;
    }

    size(): number {
        return 6;
    }
}

const lubricateWithGunk: Handler = async ({ action, runner }) => {
    if (action.is(Lubricate) && action.tool.is(Gunk)) {
        await runner.doOutput("That's not very useful.");
        return Action.complete();
    }
};
