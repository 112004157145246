import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeReadable } from '../../game/Entity';
import { Close, Open } from '../../abilities';

export class WoodenDoor extends makeReadable(Item) {
    static spec(): EntitySpec<WoodenDoor> {
        return {
            ref: 'wooden-door',
            constructor: WoodenDoor,
            initial: {},
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [
                new Adjective('west'),
                new Adjective('western'),
                new Adjective('wooden'),
            ],
            handlers: [openCloseWoodenDoor],
        };
    }

    ref() {
        return WoodenDoor.spec().ref;
    }

    name(): string {
        return 'wooden door';
    }

    description(): string {
        return '';
    }

    text(): string {
        return "The engravings translate to 'This space intentionally left blank'.";
    }

    nouns() {
        return WoodenDoor.spec().nouns;
    }

    adjectives() {
        return WoodenDoor.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToOpen(): boolean {
        return true;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const CYCLOPS_SUFFIX = ', but it has a hole in it about the size of a cyclops';

const openCloseWoodenDoor: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(WoodenDoor)) {
        // TODO actual cyclops stuff
        const cyclopsInRoom = true;
        const suffix = !cyclopsInRoom ? CYCLOPS_SUFFIX : '';
        await runner.doOutput(`The door is nailed shut${suffix}.`);
        return Action.complete();
    }

    if (action.is(Close) && action.item.is(WoodenDoor)) {
        // TODO actual cyclops stuff
        const cyclopsInRoom = true;
        const suffix = !cyclopsInRoom ? CYCLOPS_SUFFIX : '';
        await runner.doOutput(`The wooden door is already closed${suffix}.`);
        return Action.complete();
    }
};
