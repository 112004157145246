import { Passage, Room, SouthCorridor } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { NorthCorridor } from '../NorthCorridor';
import { TreasuryOfZork } from '../TreasuryOfZork';
import { BronzeDoor, CellDoor } from '../../items';
import { DungeonMaster } from '../../actors';
import { Sundial } from '../../items/Sundial';

// TODO in the original this was three rooms: also Nirvana Prison Cell and Closed Prison Cell

export class PrisonCell extends Room {
    static spec(): EntitySpec<PrisonCell> {
        return {
            ref: 'prison-cell',
            constructor: PrisonCell,
            initial: {
                contents: [CellDoor.spec().ref, BronzeDoor.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return PrisonCell.spec().ref;
    }

    name(): string {
        return 'Prison Cell';
    }

    description(): string {
        let description = 'You are in a featureless prison cell.';
        const cellDoor = this.game.ent(CellDoor);
        const bronzeDoor = this.game.ent(BronzeDoor);
        const bronzeOpenClose = bronzeDoor.isOpen() ? 'open' : 'closed';
        if (cellDoor.isLocked()) {
            description +=
                ' Its wooden door is securely fastened, ' +
                'and you can see only the flames and smoke of the pit out the small window.';
            if (!bronzeDoor.isHidden()) {
                description += `On the other side of the cell is a bronze door which seems to be ${bronzeOpenClose}.`;
            }
        } else {
            description += ' You can see ';
            if (cellDoor.isOpen()) {
                description +=
                    'the east-west corridor outside the open wooden door in front of you.';
            } else {
                description +=
                    'only the flames and smoke of the pit out the small window in a closed door in front of you.';
            }
            if (!bronzeDoor.isHidden()) {
                description += ` Behind you is a bronze door which seems to be ${bronzeOpenClose}.`;
            }
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    visibleObjects(): Entity[] {
        return [...super.visibleObjects(), this.game.ent(DungeonMaster)];
    }

    passages(): Passage[] {
        const door = this.game.ent(BronzeDoor);
        return [
            new Passage({
                via: !door.isHidden()
                    ? [Direction.South, SpecialDirection.Out]
                    : Direction.South,
                condition: () => door.isOpen() && !door.isHidden(),
                to:
                    this.game.ent(Sundial).activeSetting() === 4
                        ? SouthCorridor.spec().ref
                        : TreasuryOfZork.spec().ref,
                message: !door.isHidden()
                    ? 'The bronze door is closed.'
                    : undefined,
            }),
            new Passage({
                via: [Direction.North, SpecialDirection.Out],
                to: NorthCorridor.spec().ref,
                condition: () => this.game.ent(CellDoor).isOpen(),
                message: 'The cell door is closed.',
            }),
        ];
    }
}
