import {
    ChainParser,
    EitherParser,
    EndParser,
    MapParser,
    Match,
    PremiseParser,
    ThenParser,
} from '.';
import { Named, Parse, Token } from '..';

/**
 * A class to represent parsers.
 */
export abstract class Parser<I, O> {
    /**
     * A generator that yields Match instances for successful matches.
     * Should be overridden by subclasses to provide actual functionality.
     * This base instance will never yield any successful matches.
     *
     * @param {Set} tokens The Set of nodes for this parser to consume.
     */
    abstract match(tokens: Token<I>[]): Generator<Match<I, O>>;

    /**
     * Attempt to parse the given nodes, and return the first result,
     * or null.
     *
     * @param {Set} tokens The Set of nodes for this parser to consume.
     */
    matchOne(tokens: Token<I>[]): Match<I, O> | null {
        const match = this.match(tokens).next();
        return match.value !== undefined ? match.value : null;
    }

    /**
     * Helper method to map this parser's returned values.
     *
     * @param {Function} mapper The function to apply to each of the
     * parser's returned values.
     */
    map<O2>(mapper: (value: O) => O2): Parser<I, O2> {
        return new MapParser(this, mapper);
    }

    /**
     * Return a parser that first parses according to this parser,
     * then attempts to consume remaining nodes with the given parser.
     *
     * @param {*} parser The parser to apply after this one.
     */
    then<O2>(parser: Parser<I, O2>): Parser<I, [O, O2]> {
        return new ThenParser(this, parser);
    }

    /**
     * Map this parser's value into a `Named` node with the given name.
     *
     * @param {string} name The name for the `Named` node.
     */
    named(name: string): Parser<I, Named<O>> {
        return Parse.named(this, name);
    }

    /**
     * Collect the `Named` nodes from results of this parser into an
     * object where the `.name`s are keys and `.values` are values.
     */
    collect() {
        return Parse.collect(this);
    }

    /**
     * Parse either this parser or another one.
     */
    or<O2>(that: Parser<I, O2>): Parser<I, O | O2> {
        return new EitherParser(this, that);
    }

    /**
     * Apply this parser, then another parser (`that`), and ignore the result
     * of the other parser.
     *
     * @param {*} that The parser to follow this one in sequence, then to ignore.
     */
    before<O2>(that: Parser<I, O2>): Parser<I, O> {
        return new ThenParser(this, that).map(([first, _]) => first);
    }

    /**
     * Apply another (`that`) parser, then this parser, and ignore the result
     * of the other parser.
     *
     * @param {*} that The parser to precede this one in sequence, then to ignore.
     */
    after<O2>(that: Parser<I, O2>): Parser<I, O> {
        return new ThenParser(that, this).map(([_, second]) => second);
    }

    /**
     * Apply this parser, then another parser (`that`), and ignore the result
     * of the other parser.
     *
     * @param {*} that The parser to follow this one in sequence, then to ignore.
     */
    beforeX<O2>(that: Parser<I, O2>): Parser<I, O2> {
        return new ThenParser(this, that).map(([_, second]) => second);
    }

    /**
     * Apply this parser, mapped to a constant value.
     *
     * @param {*} that The value to return instead of whatever this parser returns.
     */
    into<O2>(that: O2): Parser<I, O2> {
        return new MapParser(this, () => that);
    }

    /**
     * Apply this parser, then ensure that the input ends afterward.
     */
    end(): Parser<I, O> {
        return new EndParser(this);
    }

    /**
     * Succeed only if this parser succeeds and meets a condition.
     */
    where(condition: (v: O) => boolean): Parser<I, O> {
        return new PremiseParser(this, condition);
    }

    // TODO crs doc
    chain<O2>(maker: (value: O) => Parser<I, O2>): Parser<I, O2> {
        return new ChainParser(this, maker);
    }
}
