import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that matches under the same conditions as the given `parser`, mapping
 * each match using the given `mapper` function.
 *
 * ```
 * constant(2).map((x) => x * 2).match(tokenize("")) // yields 4
 * ```
 */
export class MapParser<I, O1, O2> extends Parser<I, O2> {
    parser: Parser<I, O1>;

    mapper: (v: O1) => O2;

    constructor(parser: Parser<I, O1>, mapper: (v: O1) => O2) {
        super();
        this.parser = parser;
        this.mapper = mapper;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, O2>> {
        for (const match of this.parser.match(tokens)) {
            yield new Match(
                match.token.withValue(this.mapper(match.token.value)),
                match.rest
            );
        }
    }
}
