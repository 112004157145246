import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { DeepRavine } from '../DeepRavine';
import { RoundRoom } from '../RoundRoom';
import { TrollRoom } from '../TrollRoom';

export class EastWestPassage extends Room {
    static spec(): EntitySpec<EastWestPassage> {
        return {
            ref: 'east-west-passage',
            constructor: EastWestPassage,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    scoreOnEntry(): number {
        return 5;
    }

    ref() {
        return EastWestPassage.spec().ref;
    }

    name(): string {
        return 'East-West Passage';
    }

    description(): string {
        return 'This is a narrow east-west passageway. There is a narrow stairway leading down at the north end of the room.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: RoundRoom.spec().ref }),
            new Passage({ via: Direction.West, to: TrollRoom.spec().ref }),
            new Passage({ via: Direction.Down, to: DeepRavine.spec().ref }),
            new Passage({ via: Direction.North, to: DeepRavine.spec().ref }),
        ];
    }
}
