import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { SmellUnresolved, smellUnresolvedHandler } from './SmellUnresolved';

export class Smell extends Action {
    id = '~smell';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [smellHandler, smellUnresolvedHandler],
            parser,
            verbs: [new Verb('smell'), new Verb('sniff')],
            prepositions: [],
        };
    }
}

export const smellHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Smell)) return;
    const { item } = action;
    await runner.doOutput(`It smells like ${item.an()}.`);
    return Action.complete();
};

const parser = (game: Game): Parser<Value, SmellUnresolved> => {
    const smell = Parse.words(Smell.ability().verbs);
    const smellObject = smell.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // smell
        smell.into(new SmellUnresolved({ item: undefined })),
        // smell button
        smellObject.map((item) => new SmellUnresolved({ item }))
    );
};
