import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeFlammable, makeReadable } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Count, Extinguish, Light, SpecialTimerTick } from '../../abilities';
import { Player } from '../../actors';
import { pluralize } from '../../utils';
import { NUMBERS } from '../../constants';

interface MatchbookState extends ItemState {
    quantity: number;
    timeRemaining: number;
}

abstract class Base extends Item<MatchbookState> {}

// TODO maybe make this multiple objects -- a matchbook, and 5 matches.
//      this would be cool, but also requires implementing the concept of quantity
export class Matchbook extends makeReadable(makeFlammable(makeTakeable(Base))) {
    static spec(): EntitySpec<Matchbook> {
        return {
            ref: 'matchbook',
            constructor: Matchbook,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
                timeRemaining: 0,
                quantity: 5,
            },
            nouns: [
                new Noun('match'),
                new Noun('matches', { collective: true }),
                new Noun('matchbook'),
                new Noun('matchbooks', { plural: true }),
            ],
            adjectives: [new Adjective('flaming'), new Adjective('unlit')],
            handlers: [lightMatch, extinguishMatch, tickMatch, countMatches],
        };
    }

    ref() {
        return Matchbook.spec().ref;
    }

    name() {
        return 'matchbook';
    }

    quantity() {
        return this.state.quantity;
    }

    description() {
        return "There is a matchbook whose cover says 'Visit Beautiful FCD#3' here.";
    }

    text() {
        return MATCHBOOK_TEXT;
    }

    size() {
        return 2;
    }

    nouns(): Noun[] {
        return Matchbook.spec().nouns;
    }

    adjectives(): Adjective[] {
        const adjectives = [];
        if (this.isAflame()) {
            adjectives.push(new Adjective('flaming'));
        } else {
            adjectives.push(new Adjective('unlit'));
        }
        return adjectives;
    }

    setTimeRemaining(timeRemaining: number) {
        this.state.timeRemaining = timeRemaining;
    }

    timeRemaining() {
        return this.state.timeRemaining;
    }

    setQuantity(quantity: number) {
        this.state.quantity = quantity;
    }
}

const MATCHBOOK_TEXT = `    [close cover before striking BKD]

  YOU too can make BIG MONEY in the exciting field of
    PAPER SHUFFLING!

  Mr. TAA of Muddle, Mass. says: "Before I took this course I used
to be a lowly bit twiddler. Now with what I learned at MIT Tech
I feel really important and can obfuscate and confuse with the best."

  Mr. MARC had this to say: "Ten short days ago all I could look
forward to was a dead-end job as a doctor. Now I have a promising
future and make really big Zorkmids."

  MIT Tech can't promise these fantastic results to everyone. But when
you earn your MDL degree from MIT Tech your future will be brighter.

  Send for our free brochure today.`;

const lightMatch: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Light) &&
        action.item.is(Matchbook) &&
        action.tool === undefined
    ) {
        if (action.item.quantity() < 1) {
            await runner.doOutput(
                "I'm afraid that you have run out of matches."
            );
        } else {
            action.item.state.isAflame = true;
            action.item.setTimeRemaining(2);
            action.item.setQuantity(action.item.quantity() - 1);
            await runner.doOutput('One of the matches starts to burn.');
        }
        return Action.complete();
    }
};

const extinguishMatch: Handler = async ({ action, runner }) => {
    if (
        action.is(Extinguish) &&
        action.item.is(Matchbook) &&
        action.item.isAflame()
    ) {
        action.item.state.isAflame = true;
        action.item.setTimeRemaining(0);
        await runner.doOutput('The match is out.');
        return Action.complete();
    }
};

const tickMatch: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const matches = game.ent(Matchbook);
        if (matches.timeRemaining() > 0) {
            matches.setTimeRemaining(matches.timeRemaining() - 1);
            if (matches.timeRemaining() === 0) {
                matches.state.isAflame = false;
                if (
                    matches.location()?.isEqualTo(game.ent(Player).location())
                ) {
                    await runner.doOutput('The match is out.');
                }
            }
        }
        return Action.incomplete();
    }
};

const countMatches: Handler = async ({ action, runner }) => {
    if (action.is(Count) && action.item?.is(Matchbook)) {
        const count = action.item.quantity();
        const numString = count === 0 ? 'no' : NUMBERS[count];
        await runner.doOutput(
            `You have ${numString} ${pluralize(count, 'match', 'matches')}.`
        );
        return Action.complete();
    }
};
