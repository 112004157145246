import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Count } from '../../abilities';

export class Blessings extends Item {
    static spec(): EntitySpec<Blessings> {
        return {
            ref: 'blessings',
            constructor: Blessings,
            initial: {},
            nouns: [new Noun('blessings', { collective: true })],
            adjectives: [new Adjective('my')],
            handlers: [countBlessings],
        };
    }

    ref() {
        return Blessings.spec().ref;
    }

    name(): string {
        return 'blessings';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Blessings.spec().nouns;
    }

    adjectives() {
        return Blessings.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const countBlessings: Handler = async ({ action, runner }) => {
    if (action.is(Count) && action.item?.is(Blessings)) {
        await runner.doOutput('Well, for one, you are playing Zork....');
        return Action.complete();
    }
};
