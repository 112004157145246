import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Ground extends Item {
    static spec(): EntitySpec<Ground> {
        return {
            ref: 'ground',
            constructor: Ground,
            initial: {},
            nouns: [
                new Noun('ground', { collective: true }),
                new Noun('floor', { collective: true }),
                new Noun('earth', { collective: true }),
            ],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Ground.spec().ref;
    }

    name() {
        return 'ground';
    }

    description() {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    nouns(): Noun[] {
        return Ground.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Ground.spec().adjectives;
    }
}
