import { Parse, Named } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { SpecialAction } from '../SpecialAction';

export class SpecialUnknownWord extends SpecialAction {
    id = '*unknown-word';

    unknownWord: string;

    constructor({ unknownWord }: { unknownWord: string }) {
        super();
        this.unknownWord = unknownWord;
    }

    static ability(): Ability {
        return {
            handlers: [specialUnknownWordHandler],
            parser,
            verbs: [],
            prepositions: [],
        };
    }
}

export const specialUnknownWordHandler: Handler = async ({
    action,
    runner,
}) => {
    if (!action.is(SpecialUnknownWord)) return;

    await runner.doOutput(
        `I don't understand the word "${action.unknownWord}".`
    );
    return Action.complete({ withConsequence: false });
};

const parser = (game: Game) =>
    Parse.many(
        Parse.either(
            // TODO make this work for quotations and commas and stuff
            game.lexicon.word(),
            Parse.word()
                .where((node) => !game.lexicon.isWord(node))
                .named('unknownWord')
        ).before(Parse.option(Parse.whitespace()))
    )
        .map((nodes) => nodes.filter((node) => node instanceof Named)[0])
        .where((unknownWord) => unknownWord)
        .map(
            (unknownWord) =>
                new SpecialUnknownWord({
                    unknownWord: unknownWord.value.value,
                })
        );
