import { Passage, Room } from '..';
import { MAZE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Maze9 } from '../Maze9';
import { Maze13 } from '../Maze13';
import { Maze11 } from '../Maze11';

export class Maze10 extends Room {
    static spec(): EntitySpec<Maze10> {
        return {
            ref: 'maze-10',
            constructor: Maze10,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Maze10.spec().ref;
    }

    name(): string {
        return 'Maze';
    }

    description(): string {
        return MAZE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.East, to: Maze9.spec().ref }),
            new Passage({ via: Direction.West, to: Maze13.spec().ref }),
            new Passage({ via: Direction.Up, to: Maze11.spec().ref }),
        ];
    }

    // TODO
    isPartOfMaze() {
        return true;
    }
}
