import { LandOfTheLivingDead, LivingRoom, Passage, Room } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { Direction, SpecialDirection } from '../../../parse';
import { Crypt } from '../Crypt';
import {
    Code,
    CokeBottles,
    CryptDoor,
    LargeCase,
    PoledHeads,
} from '../../items';
import {
    Kick,
    Kill,
    Light,
    Mung,
    Open,
    Poke,
    Rub,
    SpecialJigsUp,
    Take,
} from '../../abilities';
import { Player } from '../../actors';

export class TombOfTheUnknownImplementer extends Room {
    static spec(): EntitySpec<TombOfTheUnknownImplementer> {
        return {
            ref: 'tomb-of-the-unknown-implementer',
            constructor: TombOfTheUnknownImplementer,
            initial: {
                contents: [
                    CryptDoor.spec().ref,
                    PoledHeads.spec().ref,
                    Code.spec().ref,
                    CokeBottles.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [offendImplementers],
        };
    }

    ref() {
        return TombOfTheUnknownImplementer.spec().ref;
    }

    name(): string {
        return 'Tomb of the Unknown Implementer';
    }

    description(): string {
        // TODO
        const openClose = 'closed';
        return (
            'This is the Tomb of the Unknown Implementer. A hollow voice says: ' +
            '"That\'s not a bug, it\'s a feature!" In the north wall of the room is the ' +
            'Crypt of the Implementers. It is made of the finest marble, ' +
            `and apparently large enough for four headless corpses. The crypt is ${openClose}.\n` +
            `Above the entrance is the cryptic inscription:\n` +
            `    "Feel Free."`
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.West,
                to: LandOfTheLivingDead.spec().ref,
            }),
            new Passage({
                via: [Direction.North, SpecialDirection.In],
                condition: () => this.game.ent(CryptDoor).isOpen(),
                message: 'The crypt is closed',
                to: Crypt.spec().ref,
            }),
        ];
    }
}

// TODO test this
const offendImplementers: Handler = async ({ action, game, runner, actor }) => {
    if (
        actor?.is(Player) &&
        (((action.is(Mung) ||
            action.is(Light) ||
            action.is(Take) ||
            action.is(Kick) ||
            action.is(Rub)) &&
            (action.item.is(PoledHeads) || action.item.is(CryptDoor))) ||
            ((action.is(Poke) || (action.is(Kill) && action.weapon)) &&
                (action.enemy.is(PoledHeads) || action.enemy.is(CryptDoor))) ||
            (action.is(Open) &&
                action.item.is(CryptDoor) &&
                !game.isReadyForEndgame()))
    ) {
        const largeCase = game.ent(LargeCase);
        const items = [
            ...actor.location().contents(),
            ...actor.inventory(),
        ].filter((item) => item.isItem() && item.isTreasure());
        if (items.length > 0) {
            items.forEach((item) => item.moveTo(largeCase));
            largeCase.moveTo(game.ent(LivingRoom));
        }
        await runner.doOutput(
            'Although the implementers are dead, they foresaw that some cretin ' +
                'would tamper with their remains. Therefore, they took steps to punish such actions.'
        );
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    "Unfortunately, we've run out of poles. Therefore, in punishment " +
                    'for your most grievous sin, we shall deprive you of all your valuables, and of your life.',
            })
        );
        return Action.complete();
    }
};
