import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { WestOfChasm } from '../WestOfChasm';
import { Studio } from '../Studio';
import { BankEntrance } from '../BankEntrance';
import { Painting } from '../../items';

export class Gallery extends Room {
    static spec(): EntitySpec<Gallery> {
        return {
            ref: 'gallery',
            constructor: Gallery,
            initial: {
                contents: [Painting.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Gallery.spec().ref;
    }

    name(): string {
        return 'Gallery';
    }

    description(): string {
        return (
            'This is an art gallery. Most of the paintings which were ' +
            'here have been stolen by vandals with exceptional taste. ' +
            'The vandals left through either the north, south, or west exits.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: WestOfChasm.spec().ref,
            }),
            new Passage({ via: Direction.South, to: Studio.spec().ref }),
            new Passage({ via: Direction.West, to: BankEntrance.spec().ref }),
        ];
    }
}
