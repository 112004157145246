import { Passage, Room } from '..';
import { MAZE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Maze14 } from '../Maze14';
import { Maze7 } from '../Maze7';
import { CyclopsRoom } from '../CyclopsRoom';

export class Maze15 extends Room {
    static spec(): EntitySpec<Maze15> {
        return {
            ref: 'maze-15',
            constructor: Maze15,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Maze15.spec().ref;
    }

    name(): string {
        return 'Maze';
    }

    description(): string {
        return MAZE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: Maze14.spec().ref }),
            new Passage({ via: Direction.South, to: Maze7.spec().ref }),
            new Passage({
                via: Direction.Northeast,
                to: CyclopsRoom.spec().ref,
            }),
        ];
    }

    // TODO
    isPartOfMaze() {
        return true;
    }
}
