import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Mung } from './Mung';
import { UnresolvedAction } from '../UnresolvedAction';

export class MungUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'mung';

    weapon: Target | undefined;

    item: Target | undefined;

    constructor({
        weapon,
        item,
    }: {
        weapon: Target | undefined;
        item: Target | undefined;
    }) {
        super();
        this.weapon = weapon;
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'mung someone';
        }
        if (this.weapon === undefined) {
            return `mung ${targetDescription(game, this.item)} with something`;
        }
        return `mung ${targetDescription(
            game,
            this.item
        )} with ${targetDescription(game, this.weapon)}`;
    }
}

export const mungUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(MungUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new MungUnresolved({ weapon: action.weapon, item }),
        missing: () => 'What would you like to destroy?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to destroy, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    const { item: weapon } = await game.resolve(runner, action.weapon, actor, {
        partial: (weapon) => new MungUnresolved({ weapon, item: action.item }),
        ambiguous: (desc, opt) =>
            `With which ${desc} would you like to destroy ${item.the()}, ${opt}?`,
        condition: (item) => item.isItem() && item.isWeapon(),
    });

    if (weapon && !(await game.reach(runner, weapon, actor))) {
        return Action.complete();
    }

    if (weapon && !(await game.have(runner, weapon, actor))) {
        return Action.complete();
    }

    return game.applyAction(runner, new Mung({ item, weapon }));
};
