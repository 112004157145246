import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { Rope } from '../Rope';

export class Timber extends makeTakeable(Item) {
    static spec(): EntitySpec<Timber> {
        return {
            ref: 'timber',
            constructor: Timber,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('piece of timber'),
                new Noun('pieces of timber', { plural: true }),
                new Noun('pile of timber'),
                new Noun('pile'),
                new Noun('timber', { collective: true }),
            ],
            adjectives: [
                new Adjective('wood'),
                new Adjective('wooden'),
                new Adjective('broken'),
            ],
        };
    }

    ref() {
        return Timber.spec().ref;
    }

    name() {
        return 'broken timber';
    }

    description() {
        if (this.game.ent(Rope).fastenedItem()?.isEqualTo(this)) {
            return 'The coil of rope is tied to the wooden timber.';
        }
        return 'There is a wooden timber on the ground here.';
    }

    nouns(): Noun[] {
        return Timber.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Timber.spec().adjectives;
    }

    size(): number {
        return 50;
    }
}
