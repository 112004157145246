import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Fill } from './Fill';
import { UnresolvedAction } from '../UnresolvedAction';

export class FillUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'fill';

    container: Target | undefined;

    item: Target | undefined;

    constructor({
        container,
        item,
    }: {
        container: Target | undefined;
        item: Target | undefined;
    }) {
        super();
        this.container = container;
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'fill something';
        }
        if (this.container === undefined) {
            return `fill ${targetDescription(game, this.item)} with something`;
        }
        return `fill ${targetDescription(
            game,
            this.item
        )} with ${targetDescription(game, this.container)}`;
    }
}

export const fillUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(FillUnresolved) || actor === undefined) return;

    const { item: container } = await game.resolve(
        runner,
        action.container,
        actor,
        {
            partial: (item) =>
                new FillUnresolved({ container: action.container, item }),
            missing: () => 'What would you like to fill?',
            ambiguous: (desc, opt) =>
                `Which ${desc} would you like to fill, ${opt}?`,
            condition: (item) => item.isItem() && item.isContainer(),
        }
    );

    if (container === undefined)
        return Action.complete({ withConsequence: false });

    if (action.item) {
        const { item } = await game.resolve(runner, action.item, actor, {
            partial: (item) =>
                new FillUnresolved({ item, container: action.container }),
            ambiguous: (desc, opt) =>
                `With which ${desc} would you like to fill ${container.the()}, ${opt}?`,
            condition: (item) => item.isItem(),
        });

        if (item === undefined)
            return Action.complete({ withConsequence: false });

        if (!(await game.reach(runner, container, actor))) {
            return Action.complete();
        }

        if (!(await game.have(runner, container, actor))) {
            return Action.complete();
        }
        return game.applyAction(runner, new Fill({ item, container }));
    }

    return game.applyAction(runner, new Fill({ item: undefined, container }));
};
