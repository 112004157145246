import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    ExamineUnresolved,
    examineUnresolvedHandler,
} from './ExamineUnresolved';

export class Examine extends Action {
    id = '~examine';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [examineHandler, examineUnresolvedHandler],
            parser,
            verbs: [
                new Verb('examine'),
                new Verb('describe'),
                new Verb('what is'),
                new Verb("what's"),
            ],
            prepositions: [],
        };
    }
}

export const examineHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Examine)) return;
    const { item } = action;
    if (item.isExaminable()) {
        await runner.doOutput(item.examineText());
    } else {
        await runner.doOutput(`I see nothing special about ${item.the()}.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, ExamineUnresolved> => {
    const examine = Parse.words(Examine.ability().verbs);
    const examineObject = examine.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // examine
        examine.into(new ExamineUnresolved({ item: undefined })),
        // examine button
        examineObject.map((item) => new ExamineUnresolved({ item }))
    );
};
