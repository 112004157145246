import { CoalMineDeadEnd, Passage, Room, TimberRoom } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { LadderTop } from '../LadderTop';
import { MineLadder } from '../../items';

export class LadderBottom extends Room {
    static spec(): EntitySpec<LadderBottom> {
        return {
            ref: 'ladder-bottom',
            constructor: LadderBottom,
            initial: {
                contents: [MineLadder.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return LadderBottom.spec().ref;
    }

    name(): string {
        return 'Ladder Bottom';
    }

    description(): string {
        return (
            'This is a rather wide room. On one side is the ' +
            'bottom of a narrow wooden ladder. To the northeast ' +
            'and the south are passages leaving the room.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: TimberRoom.spec().ref,
            }),
            new Passage({
                via: Direction.Up,
                to: LadderTop.spec().ref,
            }),
            new Passage({
                via: Direction.Northeast,
                to: CoalMineDeadEnd.spec().ref,
            }),
        ];
    }
}
