import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import {
    SpecialLook,
    SpecialTimerTick,
    Throw,
    TurnOff,
    TurnOn,
    Light,
    Extinguish,
} from '../../abilities';
import { Player } from '../../actors';

interface LampState extends ItemState {
    isPowered: boolean;
    batteryLevel: number;
    isMunged: boolean;
}

abstract class Base extends Item<LampState> {}

export class Lamp extends makeTakeable(Base) {
    static spec(): EntitySpec<Lamp> {
        return {
            ref: 'lamp',
            constructor: Lamp,
            initial: {
                isPowered: false,
                hasBeenTaken: false,
                batteryLevel: 350,
                isMunged: false,
            },
            nouns: [
                new Noun('lamp'),
                new Noun('lamps', { plural: true }),
                new Noun('lantern'),
                new Noun('lanterns', { plural: true }),
            ],
            adjectives: [
                new Adjective('battery-powered'),
                new Adjective('battery powered'),
                new Adjective('brass'),
            ],
            handlers: [
                handleTurnOnLamp,
                handleTurnOffLamp,
                depleteLampBattery,
                throwLamp,
            ],
        };
    }

    ref() {
        return Lamp.spec().ref;
    }

    isMunged() {
        return this.state.isMunged;
    }

    name() {
        return this.isMunged() ? 'broken lamp' : 'lamp';
    }

    description() {
        return this.isMunged()
            ? 'There is a broken brass lantern here.'
            : 'There is a brass lantern (battery-powered) here.';
    }

    initialDescription() {
        return 'A battery-powered brass lantern is on the trophy case.';
    }

    size() {
        return 15;
    }

    nouns(): Noun[] {
        return Lamp.spec().nouns;
    }

    adjectives(): Adjective[] {
        const adjectives = [
            new Adjective('battery-powered'),
            new Adjective('battery powered'),
            new Adjective('brass'),
        ];
        if (this.isDepleted()) {
            adjectives.push(
                new Adjective('depleted'),
                new Adjective('dead'),
                new Adjective('useless')
            );
        }
        if (this.isMunged()) {
            adjectives.push(new Adjective('broken'), new Adjective('smashed'));
        }
        return adjectives;
    }

    isPowered() {
        return this.state.isPowered;
    }

    isNaturallyLit() {
        return this.isPowered() && !this.isMunged();
    }

    batteryLevel() {
        return this.state.batteryLevel;
    }

    isDepleted() {
        return this.batteryLevel() <= 0;
    }
}

const handleTurnOnLamp: Handler = async ({ action, runner, game, actor }) => {
    if (
        (action.is(TurnOn) ||
            (action.is(Light) && action.tool === undefined)) &&
        action.item.is(Lamp) &&
        actor
    ) {
        const lamp = game.ent(Lamp);
        if (lamp.isMunged()) {
            await runner.doOutput('The lamp is not fit to be turned on.');
        } else if (!lamp.isPowered()) {
            if (lamp.isDepleted()) {
                await runner.doOutput("The lamp's battery is depleted.");
            } else {
                const room = game.locateEntity(actor);
                const wasLit = room?.isLit();

                lamp.state.isPowered = true;
                // This action should not deplete the lamp's battery.
                lamp.state.batteryLevel += 1;
                await runner.doOutput('The lamp is now on.');

                if (!wasLit && room?.isLit()) {
                    await game.applyAction(runner, new SpecialLook({}), actor);
                }
            }
        } else {
            await runner.doOutput('The lamp is already on.');
        }
        return Action.complete();
    }
};

const handleTurnOffLamp: Handler = async ({ action, runner, game, actor }) => {
    if (
        (action.is(TurnOff) || action.is(Extinguish)) &&
        action.item.is(Lamp) &&
        actor
    ) {
        const lamp = game.ent(Lamp);
        if (lamp.isMunged()) {
            await runner.doOutput('The lamp is not fit to be turned off.');
        } else if (lamp.isPowered()) {
            lamp.state.isPowered = false;
            await runner.doOutput('The lamp is now off.');

            const room = game.locateEntity(actor);
            if (!room?.isLit()) {
                await runner.doOutput('It is now pitch dark in here.');
            }
        } else {
            await runner.doOutput('The lamp is already off.');
        }
        return Action.complete();
    }
};

const depleteLampBattery: Handler = async ({ action, runner, game }) => {
    // TODO what if player is not holding lamp?
    if (action.is(SpecialTimerTick)) {
        const lamp = game.ent(Lamp);
        if (lamp.isPowered() && lamp.batteryLevel() > 0 && !lamp.isMunged()) {
            const newLevel = lamp.batteryLevel() - 1;
            lamp.state.batteryLevel = newLevel;
            if ([50, 30, 20, 10].includes(newLevel)) {
                await runner.doOutput('The lamp appears to be getting dimmer.');
            } else if (newLevel === 4) {
                await runner.doOutput('The lamp is dying.');
            } else if (newLevel === 0) {
                lamp.state.isPowered = false;
                await runner.doOutput(
                    'I hope you have more light than from a lamp.'
                );
                const room = game.locateEntity(game.ent(Player));
                if (!room?.isLit()) {
                    await runner.doOutput('It is now pitch black.');
                }
            }
        }
        return Action.incomplete();
    }
};

const throwLamp: Handler = async ({ action, runner, actor }) => {
    if (action.is(Throw) && action.item.is(Lamp) && actor) {
        await runner.doOutput(
            'The lamp has smashed into the floor and the light has gone out.'
        );
        action.item.state.isMunged = true;
        return Action.complete();
    }
};
