import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { DeepRavine } from '../DeepRavine';
import { NorthSouthPassage } from '../NorthSouthPassage';

export class Chasm extends Room {
    static spec(): EntitySpec<Chasm> {
        return {
            ref: 'chasm',
            constructor: Chasm,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Chasm.spec().ref;
    }

    name(): string {
        return 'Chasm';
    }

    description(): string {
        return (
            'A chasm runs southwest to northeast. You are on the south edge; ' +
            'the path exits to the south and to the east.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.South, to: DeepRavine.spec().ref }),
            new Passage({
                via: Direction.East,
                to: NorthSouthPassage.spec().ref,
            }),
        ];
    }
}
