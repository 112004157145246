import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { UnresolvedAction } from '../UnresolvedAction';

export class Load extends makeDescribable(UnresolvedAction) {
    id = 'Load';

    name: string | undefined;

    constructor({ name }: { name: string | undefined }) {
        super();
        this.name = name;
    }

    description(game: Game): string {
        return 'load saved progress';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('load'),
            new Verb('load state'),
            new Verb('load progress'),
            new Verb('restore'),
        ];
        return {
            handlers: [loadHandler],
            parser: () =>
                Parse.words(verbs)
                    .beforeX(
                        Parse.option(
                            Parse.whitespace()
                                .before(Parse.quote())
                                .beforeX(Parse.string())
                                .before(Parse.quote())
                        )
                    )
                    .map((name) => new Load({ name })),
            verbs,
            prepositions: [],
        };
    }
}

export const loadHandler: Handler = async ({ action, game, runner }) => {
    if (!action.is(Load)) return;

    await game.loadState(runner, action.name);
    return Action.complete({ withConsequence: false });
};
