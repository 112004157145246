import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { LowerShaft } from '../LowerShaft';
import { Machine, MachineSwitch } from '../../items';

export class MineMachineRoom extends Room {
    static spec(): EntitySpec<MineMachineRoom> {
        return {
            ref: 'mine-machine-room',
            constructor: MineMachineRoom,
            initial: {
                contents: [Machine.spec().ref, MachineSwitch.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return MineMachineRoom.spec().ref;
    }

    name(): string {
        return 'Machine Room';
    }

    description(): string {
        return 'This is a large room which seems to be air-conditioned.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Northwest,
                to: LowerShaft.spec().ref,
            }),
        ];
    }
}
