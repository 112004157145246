import { Value } from '.';

export class WordList<T extends Value> extends Array {
    push(...words: T[]): number {
        for (const word of words) {
            if (!this.includes(word)) {
                super.push(word);
            }
        }
        return this.length;
    }

    includes(unknown: T) {
        return this.some((known) => known.isEqual(unknown));
    }
}
