import { Game } from '../game';

export enum Effect {
    Consequence = 'consequence',
    NoConsequence = 'no-consequence',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T = {}> = abstract new (...args: any[]) => T;

export abstract class Action {
    abstract id: string;

    command: string | undefined;

    setCommand(command: string) {
        this.command = command;
    }

    isDescribable(): this is Describable {
        return false;
    }

    is<T>(ActionClass: Constructor<T>): this is T {
        return this instanceof ActionClass;
    }

    is2<T>(ActionClass: Constructor<T>): boolean {
        return this instanceof ActionClass;
    }

    static complete({ withConsequence = true } = {}): Effect {
        return withConsequence ? Effect.Consequence : Effect.NoConsequence;
    }

    static incomplete(): undefined {
        return undefined;
    }
}

export interface Describable {
    description(game: Game): string;
}
