import { Direction } from '../../../parse';
import { WestOfHouse } from '../WestOfHouse';
import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { BarredHouseWindow, House } from '../../items';
import { BehindHouse } from '../BehindHouse';
import { Forest3 } from '../Forest3';

export class NorthOfHouse extends Room {
    static spec(): EntitySpec<NorthOfHouse> {
        return {
            ref: 'north-of-house',
            constructor: NorthOfHouse,
            initial: {
                contents: [BarredHouseWindow.spec().ref, House.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return NorthOfHouse.spec().ref;
    }

    name(): string {
        return 'North of House';
    }

    description(): string {
        return 'You are facing the north side of a white house. There is no door here, and all the windows are barred.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: WestOfHouse.spec().ref }),
            new Passage({ via: Direction.East, to: BehindHouse.spec().ref }),
            new Passage({ via: Direction.North, to: Forest3.spec().ref }),
            new Passage({
                via: Direction.South,
                message: 'The windows are all barred.',
            }),
        ];
    }

    hasWalls() {
        return false;
    }

    hasSouthWall(): boolean {
        return true;
    }
}
