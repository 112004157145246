import { CanyonView, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { NO_TREE } from '../../constants';
import { EntitySpec } from '../../game';
import { Forest2 } from '../Forest2';
import { Forest4 } from '../Forest4';

export class Forest5 extends Room {
    static spec(): EntitySpec<Forest5> {
        return {
            ref: 'forest-5',
            constructor: Forest5,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Forest5.spec().ref;
    }

    name(): string {
        return 'Forest';
    }

    description(): string {
        return 'This is a forest, with trees in all directions around you.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: Forest5.spec().ref }),
            new Passage({
                via: Direction.Southeast,
                to: CanyonView.spec().ref,
            }),
            new Passage({ via: Direction.South, to: Forest4.spec().ref }),
            new Passage({ via: Direction.West, to: Forest2.spec().ref }),
            new Passage({ via: Direction.Up, message: NO_TREE }),
        ];
    }

    isPartOfForest() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
