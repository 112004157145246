import { Passage, Room } from '..';
import { EntitySpec } from '../../game';

export class EastNarrowRoom4 extends Room {
    static spec(): EntitySpec<EastNarrowRoom4> {
        return {
            ref: 'east-narrow-room-4',
            constructor: EastNarrowRoom4,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return EastNarrowRoom4.spec().ref;
    }

    name(): string {
        return 'Narrow Room';
    }

    description(): string {
        return 'Cheater!';
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [];
    }
}
