import { MungedSource, Passage, Room } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { Direction } from '../../../parse';
import { MachineRoom } from '../MachineRoom';
import { SecuritySticker, SteelCage, WhitePalantir } from '../../items';
import { RoomState } from '../Room';
import { SpecialJigsUp, SpecialLook, SpecialTimerTick } from '../../abilities';
import { Player } from '../../actors';

interface DingyClosetState extends RoomState {
    isPoisonTimerActivated: boolean;
    poisonDelay: number;
}

export class DingyCloset extends Room<DingyClosetState> {
    static spec(): EntitySpec<DingyCloset> {
        return {
            ref: 'dingy-closet',
            constructor: DingyCloset,
            initial: {
                contents: [
                    WhitePalantir.spec().ref,
                    SecuritySticker.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
                isPoisonTimerActivated: false,
                poisonDelay: 10,
            },
            nouns: [],
            adjectives: [],
            handlers: [poisonTimer, describeRoomWhileCaged],
        };
    }

    ref() {
        return DingyCloset.spec().ref;
    }

    name(): string {
        return 'Dingy Closet';
    }

    description(): string {
        return (
            'This is a dingy closet adjacent to the machine room. ' +
            `On one wall is a small sticker which says
    Protected by
      FROBOZZ
       Magic Alarm Company
        (Hello, footpad!)`
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: MachineRoom.spec().ref }),
        ];
    }
}

const poisonTimer: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const room = game.ent(DingyCloset);
        if (room.state.isPoisonTimerActivated) {
            room.state.poisonDelay -= 1;
            if (room.state.poisonDelay <= 0) {
                room.state.isMunged = true;
                room.state.howMunged = MungedSource.PoisonousGas;
                room.state.isPoisonTimerActivated = false;
                if (game.ent(Player).location().isEqualTo(room)) {
                    await game.applyAction(
                        runner,
                        new SpecialJigsUp({
                            message:
                                'Time passes... and you die from some obscure poisoning.',
                        })
                    );
                }
            }
        }
    }
    return Action.incomplete();
};

const describeRoomWhileCaged: Handler = async ({
    action,
    runner,
    game,
    extensions,
}) => {
    if (action.is(SpecialLook)) {
        const player = game.ent(Player);
        const room = player.location();
        if (room.is(DingyCloset) && game.ent(SteelCage).contains(player)) {
            await extensions.deferHandling();
            await runner.doOutput('You are trapped in the steel cage.');
            return Action.complete({ withConsequence: false });
        }
    }
};
