import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { CURRENT_STRONG } from '../../constants';
import { EntitySpec, Handler } from '../../game';
import { Shore } from '../Shore';
import { Go, SpecialJigsUp } from '../../abilities';
import { Boat, River } from '../../items';

export class River5 extends Room {
    static spec(): EntitySpec<River5> {
        return {
            ref: 'river-5',
            constructor: River5,
            initial: {
                contents: [River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [goFromRiver5],
        };
    }

    ref() {
        return River5.spec().ref;
    }

    name(): string {
        return 'Frigid River';
    }

    description(): string {
        return (
            'The sound of rushing water is nearly unbearable here. ' +
            'On the west shore is a large landing area.'
        );
    }

    isOnLand(): boolean {
        return false;
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [SpecialDirection.Land, Direction.West],
                to: Shore.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: [Direction.Up, Direction.North],
                message: CURRENT_STRONG,
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}

const goFromRiver5: Handler = async ({ action, runner, game, actor }) => {
    if (
        action.is(Go) &&
        (action.direction === Direction.Down ||
            action.direction === Direction.South) &&
        actor?.location()?.is(River5)
    ) {
        return game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    "Unfortunately, a rubber raft doesn't provide much " +
                    'protection from the unfriendly sorts of rocks and boulders ' +
                    'one meets at the bottom of many waterfalls. Including this one.',
            })
        );
    }
};
