import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Open } from '../../abilities';
import { makeOpenable } from '../../game/Entity';

export class StoneDoor extends makeOpenable(Item) {
    static spec(): EntitySpec<StoneDoor> {
        return {
            ref: 'stone-door',
            constructor: StoneDoor,
            initial: {
                isOpen: false,
            },
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [new Adjective('stone'), new Adjective('heavy')],
            handlers: [openStoneDoor],
        };
    }

    ref() {
        return StoneDoor.spec().ref;
    }

    name(): string {
        return 'stone door';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns() {
        return StoneDoor.spec().nouns;
    }

    adjectives() {
        return StoneDoor.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const openStoneDoor: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(StoneDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput(
                'What do you think the clap of thunder was for?'
            );
        } else {
            await runner.doOutput(
                "There doesn't seem to be any way to open it."
            );
        }
        return Action.complete();
    }

    if (action.is(Close) && action.item.is(StoneDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput('The door is far too heavy to move.');
        } else {
            await runner.doOutput('The door is already closed.');
        }
        return Action.complete();
    }
};
