import { Handler } from '../../game';
import { SpecialAction } from '../SpecialAction';
import { Room } from '../../rooms';
import { SpecialEnter } from '..';

export class SpecialGo extends SpecialAction {
    id = '*go';

    from: Room;

    to: Room;

    constructor({ from, to }: { from: Room; to: Room }) {
        super();
        this.from = from;
        this.to = to;
    }

    static handler: Handler = async ({ action, runner, game, actor }) => {
        if (!action.is(SpecialGo)) return;

        return game.applyAction(
            runner,
            new SpecialEnter({ room: action.to }),
            actor
        );
    };
}
