import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Crack extends Item {
    static spec(): EntitySpec<Crack> {
        return {
            ref: 'crack',
            constructor: Crack,
            initial: {},
            nouns: [
                new Noun('crack'),
                new Noun('cracks', { collective: true }),
            ],
            adjectives: [new Adjective('narrow')],
        };
    }

    ref() {
        return Crack.spec().ref;
    }

    name(): string {
        return 'narrow crack';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Crack.spec().nouns;
    }

    adjectives() {
        return Crack.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
