import { Forest4, Forest5, Passage, RockyLedge, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';

export class CanyonView extends Room {
    static spec(): EntitySpec<CanyonView> {
        return {
            ref: 'canyon-view',
            constructor: CanyonView,
            initial: {
                contents: [
                    // TODO Cliffs
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return CanyonView.spec().ref;
    }

    name(): string {
        return 'Canyon View';
    }

    description(): string {
        return (
            'You are at the top of the Great Canyon on its south wall. ' +
            'From here there is a marvelous view of the Canyon and parts of ' +
            'the Frigid River upstream. Across the canyon, the walls of the White ' +
            'Cliffs still appear to loom far above. Following the Canyon upstream ' +
            '(north and northwest), Aragain Falls may be seen, complete with rainbow. ' +
            'Fortunately, my vision is better than average and I can discern the top ' +
            'of the Flood Control Dam #3 far to the distant north. To the west and ' +
            'south can be seen an immense forest, stretching for miles around. ' +
            'It is possible to climb down into the canyon from here.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Down,
                to: RockyLedge.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                to: Forest4.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: Forest5.spec().ref,
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
