import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    BlowIntoUnresolved,
    blowIntoUnresolvedHandler,
} from './BlowIntoUnresolved';
import { Inflate } from '../Inflate';
import { Lungs } from '../../items';

export class BlowInto extends Action {
    id = '~blow-into';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [blowIntoHandler, blowIntoUnresolvedHandler],
            parser,
            verbs: [new Verb('blow'), new Verb('breathe')],
            prepositions: [
                new Preposition('into'),
                new Preposition('in'),
                new Preposition('in to'),
            ],
        };
    }
}

export const blowIntoHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(BlowInto)) return;
    await game.applyAction(
        runner,
        new Inflate({ item: action.item, tool: game.ent(Lungs) })
    );
    return Action.complete();
};

const parser = (game: Game): Parser<Value, BlowIntoUnresolved> => {
    const ability = BlowInto.ability();
    const blowInto = Parse.words(ability.verbs)
        .before(Parse.whitespace())
        .before(Parse.words(ability.prepositions));
    const blowIntoObject = blowInto.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // blow into
        blowInto.into(new BlowIntoUnresolved({ item: undefined })),
        // blow into pile of plastic
        blowIntoObject.map((item) => new BlowIntoUnresolved({ item }))
    );
};
