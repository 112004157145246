import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { WindUnresolved, windUnresolvedHandler } from './WindUnresolved';

export class Wind extends Action {
    id = '~wind';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [windHandler, windUnresolvedHandler],
            parser,
            verbs: [new Verb('wind'), new Verb('wind up')],
            prepositions: [],
        };
    }
}

export const windHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Wind)) return;
    const { item } = action;
    await runner.doOutput(`You cannot wind up ${item.an()}.`);
    return Action.complete();
};

const parser = (game: Game): Parser<Value, WindUnresolved> => {
    const wind = Parse.words(Wind.ability().verbs);
    const windObject = wind.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // wind
        wind.into(new WindUnresolved({ item: undefined })),
        // wind button
        windObject.map((item) => new WindUnresolved({ item }))
    );
};
