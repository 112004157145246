import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Open } from '../../abilities';
import { makeOpenable } from '../../game/Entity';
import { PrisonCell } from '../../rooms';
import { Sundial } from '../Sundial';

interface CellDoorState extends ItemState {
    isHidden: boolean;
}

abstract class Base extends Item<CellDoorState> {}

export class BronzeDoor extends makeOpenable(Base) {
    static spec(): EntitySpec<BronzeDoor> {
        return {
            ref: 'bronze-door',
            constructor: BronzeDoor,
            initial: {
                isHidden: true,
                isOpen: false,
            },
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [
                new Adjective('cell'),
                new Adjective('bronze'),
                new Adjective('metal'),
            ],
            handlers: [openBronzeDoor],
        };
    }

    ref() {
        return BronzeDoor.spec().ref;
    }

    name(): string {
        return 'bronze door';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns() {
        return BronzeDoor.spec().nouns;
    }

    adjectives() {
        return BronzeDoor.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isHidden() {
        return this.state.isHidden;
    }

    setIsHidden(isHidden: boolean) {
        this.state.isHidden = isHidden;
    }
}

const openBronzeDoor: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Open) && action.item.is(BronzeDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput('The bronze door is open.');
        } else {
            door.setIsOpen(true);
            await runner.doOutput('The bronze door opens.');
            const room = actor?.location();
            if (
                room?.is(PrisonCell) &&
                game.ent(Sundial).activeSetting() !== 4
            ) {
                await runner.doOutput(
                    'On the other side of the door is a narrow passage ' +
                        'which opens out into a larger area.'
                );
            }
        }
        return Action.complete();
    }

    if (action.is(Close) && action.item.is(BronzeDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            door.setIsOpen(false);
            await runner.doOutput('The bronze door closes.');
        } else {
            await runner.doOutput('The bronze door is closed.');
        }
        return Action.complete();
    }
};
