import { NarrowCorridor, Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { WestCorridor } from '../WestCorridor';
import { EastCorridor } from '../EastCorridor';
import { PrisonCell } from '../PrisonCell';
import { BronzeDoor } from '../../items';

export class SouthCorridor extends Room {
    static spec(): EntitySpec<SouthCorridor> {
        return {
            ref: 'south-corridor',
            constructor: SouthCorridor,
            initial: {
                contents: [BronzeDoor.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return SouthCorridor.spec().ref;
    }

    name(): string {
        return 'South Corridor';
    }

    description(): string {
        let description =
            'You are in an east-west corridor which turns north at its eastern ' +
            'and western ends. The walls of the corridor are marble. ' +
            'An additional passage leads south at the center of the corridor.';
        if (!this.game.ent(BronzeDoor).isHidden()) {
            const openClose = 'closed'; // TODO
            description +=
                '\nIn the center of the north wall of the passage is a bronze door ' +
                `which is ${openClose}.`;
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        const door = this.game.ent(BronzeDoor);
        return [
            new Passage({
                via: Direction.West,
                to: WestCorridor.spec().ref,
            }),
            new Passage({
                via: Direction.East,
                to: EastCorridor.spec().ref,
            }),
            new Passage({
                via: [Direction.North, SpecialDirection.In],
                condition: () => door.isOpen() && !door.isHidden(),
                to: PrisonCell.spec().ref,
                message: !door.isHidden()
                    ? 'The bronze door is closed.'
                    : undefined,
            }),
            new Passage({
                via: Direction.South,
                to: NarrowCorridor.spec().ref,
            }),
        ];
    }
}
