import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeFlammable, makeReadable } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Open, PutIn, SpecialTimerTick, Take } from '../../abilities';
import { Mailbox } from '../Mailbox';
import { Brochure } from '..';
import { Player } from '../../actors';
import { WestOfHouse } from '../../rooms';

interface PostcardState extends ItemState {
    timeUntilReceived: number;
    hasBeenMailed: boolean;
}

abstract class Base extends Item<PostcardState> {}

export class Postcard extends makeReadable(makeFlammable(makeTakeable(Base))) {
    static spec(): EntitySpec<Postcard> {
        return {
            ref: 'postcard',
            constructor: Postcard,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
                timeUntilReceived: 0,
                hasBeenMailed: false,
            },
            nouns: [
                new Noun('postcard'),
                new Noun('postcards', { plural: true }),
                new Noun('ad'),
                new Noun('ads', { plural: true }),
                new Noun('advert'),
                new Noun('adverts', { plural: true }),
                new Noun('advertisement'),
                new Noun('advertisements', { plural: true }),
                new Noun('spam', { collective: true }),
                new Noun('mail', { collective: true }),
            ],
            adjectives: [new Adjective('MIT')],
            handlers: [putInMailbox, takePostcard, checkMailbox, controlMail],
        };
    }

    ref() {
        return Postcard.spec().ref;
    }

    name() {
        return 'MIT postcard';
    }

    description() {
        return 'There is a postcard advertising MIT here.';
    }

    text() {
        return POSTCARD_TEXT;
    }

    size() {
        return 1;
    }

    nouns(): Noun[] {
        return Postcard.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Postcard.spec().adjectives;
    }

    setTimeUntilReceived(timeUntilReceived: number) {
        this.state.timeUntilReceived = timeUntilReceived;
    }

    timeUntilReceived() {
        return this.state.timeUntilReceived;
    }

    setHasBeenMailed(hasBeenMailed: boolean) {
        this.state.hasBeenMailed = hasBeenMailed;
    }

    hasBeenMailed() {
        return this.state.hasBeenMailed;
    }
}

const POSTCARD_TEXT = `  YOU too can make BIG MONEY in the exciting field of
    PAPER SHUFFLING!

  Mr. TAA of Muddle, Mass. says: "Before I took this course I used
to be a lowly bit twiddler. Now with what I learned at MIT Tech
I feel really important and can obfuscate and confuse with the best."

  Mr. MARC had this to say: "Ten short days ago all I could look
forward to was a dead-end job as a doctor. Now I have a promising
future and make really big Zorkmids."

  MIT Tech can't promise these fantastic results to everyone. But when
you earn your MDL degree from MIT Tech your future will be brighter.

  Send for our free brochure today.`;

const putInMailbox: Handler = async ({ action, runner, extensions }) => {
    if (
        action.is(PutIn) &&
        action.item.is(Postcard) &&
        action.container.is(Mailbox)
    ) {
        await extensions.deferHandling();
        if (action.container.contains(action.item)) {
            action.item.setTimeUntilReceived(20);
            action.item.setHasBeenMailed(true);
            await runner.doOutput('Ok, but you know the postal service...');
        }
        return Action.complete();
    }
};

const takePostcard: Handler = async ({ action }) => {
    if (action.is(Take) && action.item.is(Postcard)) {
        action.item.setTimeUntilReceived(0);
        action.item.hasBeenMailed();
        return Action.incomplete();
    }
};

const checkMailbox: Handler = async ({ action, game, runner, extensions }) => {
    if (action.is(Open) && action.item.is(Mailbox)) {
        const postcard = game.ent(Postcard);
        const brochure = game.ent(Brochure);
        await extensions.deferHandling();
        if (
            postcard.hasBeenMailed() &&
            !brochure.hasBeenRequested() &&
            action.item.contains(postcard)
        ) {
            await runner.doOutput("They'll pick it up eventually.");
        } else if (
            brochure.hasBeenRequested() &&
            !brochure.hasBeenDelivered()
        ) {
            await runner.doOutput("It's probably on the way.");
        }
        return Action.complete();
    }
};

const controlMail: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const postcard = game.ent(Postcard);
        const brochure = game.ent(Brochure);
        const mailbox = game.ent(Mailbox);
        const room = game.ent(Player).location();
        if (postcard.hasBeenMailed() && postcard.timeUntilReceived() > 0) {
            postcard.setTimeUntilReceived(postcard.timeUntilReceived() - 1);
            if (postcard.timeUntilReceived() === 0) {
                if (
                    mailbox.contains(postcard) &&
                    !mailbox.isOpen() &&
                    !room.like(WestOfHouse)
                ) {
                    postcard.moveTo(undefined);
                    brochure.setTimeUntilDelivered(20);
                    // TODO consider timing... in the original, it was triggered 3 turns after the
                    //      next time you enter the kitchen.
                    brochure.setHasBeenRequested(true);
                }
            }
        }
        if (brochure.hasBeenRequested() && !brochure.hasBeenDelivered()) {
            brochure.setTimeUntilDelivered(brochure.timeUntilDelivered() - 1);
            if (brochure.timeUntilDelivered() <= 0) {
                if (!mailbox.isOpen() && !room.like(WestOfHouse)) {
                    brochure.moveTo(mailbox);
                    brochure.setHasBeenDelivered(true);
                    if (room.isPartOfHouse()) {
                        await runner.doOutput(
                            'There is a knocking sound from the front of the house.'
                        );
                    }
                }
            }
        }
        return Action.incomplete();
    }
};
