import { Noun } from '../../../parse';
import { Item } from '../Item';
import { makeContainer } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Lower, Raise, Take } from '../../abilities';
import { LowerShaft, ShaftRoom } from '../../rooms';
import { DUMMY } from '../../constants';

export class Basket extends makeContainer(Item) {
    static spec(): EntitySpec<Basket> {
        return {
            ref: 'basket',
            constructor: Basket,
            initial: {
                contents: [],
            },
            nouns: [
                new Noun('basket'),
                new Noun('baskets', { plural: true }),
                new Noun('cage'),
                new Noun('cages', { plural: true }),
                new Noun('dumbwaiter'),
                new Noun('dumbwaiters', { plural: true }),
            ],
            adjectives: [],
            handlers: [takeBasket, raiseBasket, lowerBasket],
        };
    }

    ref() {
        return Basket.spec().ref;
    }

    name(): string {
        return 'basket';
    }

    description(): string {
        return 'At the end of the chain is a basket.';
    }

    totalCapacity(): number {
        return 50;
    }

    nouns() {
        return Basket.spec().nouns;
    }

    adjectives() {
        return Basket.spec().adjectives;
    }
}

const takeBasket: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Basket)) {
        await runner.doOutput(
            'The cage is securely fastened to the iron chain.'
        );
        return Action.complete();
    }
};

// TODO somehow say "The basket is at the other end of the chain." when you try to mess
//      with the basket (other than take, raise, lower)

const raiseBasket: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Raise) && action.item.is(Basket)) {
        if (action.item.location()?.is(ShaftRoom)) {
            await runner.doOutput(game.choiceOf(DUMMY));
        } else {
            action.item.moveTo(game.ent(ShaftRoom));
            await runner.doOutput(
                'The basket is raised to the top of the shaft.'
            );
            if (!actor?.location()?.isLit()) {
                await runner.doOutput('It is now pitch black.');
            }
        }
        return Action.complete();
    }
};

const lowerBasket: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Lower) && action.item.is(Basket)) {
        if (action.item.location()?.is(LowerShaft)) {
            await runner.doOutput(game.choiceOf(DUMMY));
        } else {
            action.item.moveTo(game.ent(LowerShaft));
            await runner.doOutput(
                'The basket is lowered to the bottom of the shaft.'
            );
            if (!actor?.location()?.isLit()) {
                await runner.doOutput('It is now pitch black.');
            }
        }
        return Action.complete();
    }
};
