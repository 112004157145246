import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { Dam } from '../Dam';
import { Boat, River, Stick } from '../../items';
import { River1 } from '../River1';

export class DamBase extends Room {
    static spec(): EntitySpec<DamBase> {
        return {
            ref: 'dam-base',
            constructor: DamBase,
            initial: {
                contents: [Boat.spec().ref, Stick.spec().ref, River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return DamBase.spec().ref;
    }

    name(): string {
        return 'Dam Base';
    }

    description(): string {
        return (
            'You are at the base of Flood Control Dam #3, which looms ' +
            'above you and to the north. The river Frigid is flowing by here. ' +
            'Across the river are the White Cliffs which seem to form a giant wall ' +
            'stretching from north to south along the east shore of ' +
            'the river as it winds its way downstream.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.North, Direction.Up],
                to: Dam.spec().ref,
            }),
            new Passage({
                via: SpecialDirection.Launch,
                to: River1.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
