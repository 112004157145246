import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';
import { makeExaminable } from '../../game/Entity';
import { InsideMirror } from '../../rooms';

export class Arrow extends makeExaminable(Item) {
    static spec(): EntitySpec<Arrow> {
        return {
            ref: 'arrow',
            constructor: Arrow,
            initial: {},
            nouns: [
                new Noun('arrow'),
                new Noun('arrows', { plural: true }),
                new Noun('pointer'),
                new Noun('pointers', { plural: true }),
            ],
            adjectives: [new Adjective('compass')],
            handlers: [],
        };
    }

    ref() {
        return Arrow.spec().ref;
    }

    name(): string {
        return 'compass arrow';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Arrow.spec().nouns;
    }

    adjectives() {
        return Arrow.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    examineText(): string {
        const orientation = this.game.ent(InsideMirror).orientation();
        return (
            'The arrow is carved onto the t-bar at the bottom of the pole. ' +
            `It is currently pointing ${orientation}.`
        );
    }
}
