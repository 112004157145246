import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import {
    LookUnderUnresolved,
    lookUnderUnresolvedHandler,
} from './LookUnderUnresolved';

export class LookUnder extends Action {
    id = '~look-under';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [lookUnderHandler, lookUnderUnresolvedHandler],
            parser,
            verbs: [
                new Verb('look'),
                new Verb('search'),
                new Verb('investigate'),
                new Verb('check'),
                new Verb('peer'),
            ],
            prepositions: [
                new Preposition('under'),
                new Preposition('underneath'),
                new Preposition('beneath'),
            ],
        };
    }
}

export const lookUnderHandler: Handler = async ({ action, runner }) => {
    if (!action.is(LookUnder)) return undefined;
    const { item } = action;
    if (item.isItem() && item.canBeMoved()) {
        await runner.doOutput(`There is nothing interesting there.`);
    } else {
        await runner.doOutput(`You can't look under ${item.the()}.`);
    }
    return Action.complete();
};

// TODO crs for a bunch of these verbs add parser for just the verb itself! "Look under what?"
const parser = (game: Game): Parser<Value, LookUnderUnresolved> =>
    Parse.words(LookUnder.ability().verbs)
        .before(Parse.whitespace())
        .before(Parse.words(LookUnder.ability().prepositions))
        .before(Parse.whitespace())
        .chain((_verb) =>
            Parse.target(game.lexicon).map(
                (item) => new LookUnderUnresolved({ item })
            )
        );
