import { Adjective, Direction, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeContainer, makeExaminable, makeVehicle } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import {
    BalloonReceptacle,
    BlueLabel,
    BraidedWire,
    ClothBag,
    Hook1,
    Hook2,
} from '..';
import {
    Examine,
    Go,
    SpecialEnter,
    SpecialJigsUp,
    SpecialTimerTick,
    Take,
} from '../../abilities';
import {
    NarrowLedge,
    Room,
    VolcanoBottom,
    VolcanoCore,
    VolcanoNearSmallLedge,
    VolcanoNearViewingLedge,
    VolcanoNearWideLedge,
    WideLedge,
} from '../../rooms';
import { Player, VolcanoGnome } from '../../actors';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';

interface BalloonState extends ItemState {
    isMunged: boolean;
    timeUntilNextFly: number;
}

abstract class Base extends Item<BalloonState> {}

export class Balloon extends makeExaminable(makeVehicle(makeContainer(Base))) {
    static spec(): EntitySpec<Balloon> {
        return {
            ref: 'balloon',
            constructor: Balloon,
            initial: {
                isMunged: false,
                timeUntilNextFly: 0,
                contents: [
                    BlueLabel.spec().ref,
                    ClothBag.spec().ref,
                    BraidedWire.spec().ref,
                    BalloonReceptacle.spec().ref,
                ],
            },
            nouns: [
                new Noun('balloon'),
                new Noun('balloons', { plural: true }),
                new Noun('basket'),
                new Noun('baskets', { plural: true }),
            ],
            adjectives: [
                new Adjective('hot air'),
                new Adjective('hot-air'),
                new Adjective('wicker'),
                new Adjective('very large'),
                new Adjective('large'),
                new Adjective('heavy'),
                new Adjective('extremely heavy'),
            ],
            handlers: [
                flyBalloon,
                examineBalloonContents,
                takeBalloonContents,
                goInBalloon,
            ],
        };
    }

    ref() {
        return Balloon.spec().ref;
    }

    name(): string {
        return this.isMunged() ? 'broken balloon' : 'basket';
    }

    isMunged() {
        return this.state.isMunged;
    }

    isAnchored() {
        return (
            this.game.ent(Hook1).isAnchoring() ||
            this.game.ent(Hook2).isAnchoring()
        );
    }

    setIsMunged(isMunged: boolean) {
        this.state.isMunged = isMunged;
    }

    description(): string {
        if (this.isMunged()) {
            return 'There is a balloon here, broken into pieces.';
        }
        let description =
            'There is a very large and extremely heavy wicker basket ' +
            'with a cloth bag here. Inside the basket is a metal receptacle ' +
            'of some kind. Attached to the basket on the outside is a piece of wire.';
        const tank = this.ent(BalloonReceptacle);
        if (tank.fuelRemaining()) {
            const fuelItem = tank.contents()[0];
            description +=
                '\nThe cloth bag is inflated and there is ' +
                `${fuelItem.an()} burning in the receptacle.`;
        } else {
            description += '\nThe cloth bag is draped over the the basket.';
        }
        if (this.ent(Hook1).isAnchoring() || this.ent(Hook2).isAnchoring()) {
            description +=
                '\nThe basket is anchored to a small hook by the braided wire.';
        }
        return description;
    }

    totalCapacity(): number {
        return 100;
    }

    size(): number {
        return this.isMunged() ? 40 : 70;
    }

    nouns() {
        return Balloon.spec().nouns;
    }

    adjectives() {
        // TODO adjectives for broken state
        return Balloon.spec().adjectives;
    }

    timeUntilNextFly() {
        return this.state.timeUntilNextFly;
    }

    setTimeUntilNextFly(timeUntilNextFly: number) {
        this.state.timeUntilNextFly = timeUntilNextFly;
    }

    isContainer() {
        return !this.isMunged();
    }

    isVehicle() {
        return !this.isMunged();
    }

    examineText(): string {
        if (this.isMunged()) {
            return (
                'The balloon is well and truly broken: the basket is ' +
                'splintered, the cloth bag tattered, and the receptacle dented beyond repair.'
            );
        }
        let description =
            'The basket seems to be hand-woven and is certainly very large, ' +
            'perhaps even big enough to stand in. Attached to the basket is a ' +
            'huge cloth bag which looks to be weatherproof. Inside the basket is a metal receptacle ' +
            'of some kind. There is also some braided wire attached to the edge of the basket.';
        const tank = this.ent(BalloonReceptacle);
        if (tank.fuelRemaining()) {
            const fuelItem = tank.contents()[0];
            description +=
                '\nThe cloth bag is inflated and there is ' +
                `${fuelItem.an()} burning in the receptacle.`;
        } else {
            description += '\nThe cloth bag is draped over the the basket.';
        }
        if (this.ent(Hook1).isAnchoring() || this.ent(Hook2).isAnchoring()) {
            description +=
                '\nThe basket is anchored to a small hook by the braided wire.';
        }
        return description;
    }
}

const flyBalloon: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const balloon = game.ent(Balloon);
        const tank = game.ent(BalloonReceptacle);
        const player = game.ent(Player);
        const volcanoBottom = game.ent(VolcanoBottom);
        const balloonRoom = balloon.location();
        // Decrease the fuel remaining in the tank, and if it reaches 0, tell
        // the player.
        if (tank.fuelRemaining() > 0) {
            tank.setFuelRemaining(tank.fuelRemaining() - 1);
            if (tank.fuelRemaining() === 0) {
                const fuelItem = tank.contents()[0];
                fuelItem.moveTo(undefined);
                if (balloonRoom?.isEqualTo(player.location())) {
                    await runner.doOutput(
                        `You notice that ${fuelItem.the()} has burned out, ` +
                            'and that the cloth bag starts to deflate.'
                    );
                }
            }
        }
        if (balloon.isAnchored() || balloon.isMunged()) return;
        if (balloon.timeUntilNextFly() > 0) {
            balloon.setTimeUntilNextFly(balloon.timeUntilNextFly() - 1);
            if (balloon.timeUntilNextFly() !== 0) {
                return;
            }
        }
        if (!balloonRoom) return;
        if (
            (tank.fuelRemaining() > 0 && tank.isOpen()) ||
            balloonRoom.like(WideLedge) ||
            balloonRoom.like(NarrowLedge)
        ) {
            if (balloonRoom.like(VolcanoNearWideLedge)) {
                // Blow up the balloon if it is too high
                balloon.setIsMunged(true);
                balloon.moveTo(volcanoBottom);
                tank.setFuelRemaining(0);
                if (balloon.contains(player)) {
                    await game.applyAction(
                        runner,
                        new SpecialJigsUp({
                            message:
                                'Your balloon has hit the rim of the volcano, ripping the ' +
                                'cloth and causing you a 500 foot drop. Did you get your flight insurance?',
                        })
                    );
                } else if (volcanoBottom.contains(player)) {
                    await runner.doOutput(
                        'You watch the balloon explode after hitting the rim; ' +
                            'its tattered remains land on the ground by your feet.'
                    );
                } else {
                    await runner.doOutput(
                        'You hear a boom and notice that the balloon is falling to the ground.'
                    );
                }
            } else {
                // Rise balloon
                const nextRoom = getNextRoomUp(game, balloon.location());
                const move = (m: string) =>
                    moveBalloon(game, runner, nextRoom, m);
                if (
                    balloonRoom.like(WideLedge) ||
                    balloonRoom.like(NarrowLedge)
                ) {
                    if (!balloon.contains(player)) {
                        await move(
                            'floats away. It seems to be ascending, due to its light load.'
                        );
                        const gnome = game.ent(VolcanoGnome);
                        gnome.setTimeUntilArrival(10);
                        gnome.setIsArrivingSoon(true);
                    } else {
                        await move('leaves the ledge.');
                    }
                } else if (balloonRoom.is(VolcanoBottom)) {
                    if (!balloon.contains(player)) {
                        await move('rises slowly from the ground.');
                    } else {
                        await move('lifts off.');
                    }
                } else {
                    await move('ascends.');
                }
            }
        } else if (!balloonRoom?.is(VolcanoBottom)) {
            const nextRoom = getNextRoomDown(game, balloonRoom);
            if (balloonRoom?.is(VolcanoCore)) {
                if (balloon.contains(player)) {
                    player.moveTo(volcanoBottom);
                    balloon.setTimeUntilNextFly(3);
                    await runner.doOutput(`The balloon has landed.`);
                    await game.applyAction(
                        runner,
                        new SpecialEnter({ room: volcanoBottom })
                    );
                    await runner.doOutput(
                        'You have landed, but the balloon did not survive.'
                    );
                    balloon.moveTo(volcanoBottom);
                    balloon.setIsMunged(true);
                } else {
                    await moveBalloon(game, runner, nextRoom, 'lands.');
                }
            } else {
                await moveBalloon(game, runner, nextRoom, 'descends.');
            }
        }

        return Action.incomplete();
    }
};

function getNextRoomUp(game: Game, room: Room | undefined) {
    if (!room) throw new Error('Expected balloon to be in a room.');
    if (room.like(VolcanoBottom)) {
        return game.ent(VolcanoCore);
    }
    if (room.like(VolcanoCore) || room.like(NarrowLedge)) {
        return game.ent(VolcanoNearSmallLedge);
    }
    if (room.like(VolcanoNearSmallLedge)) {
        return game.ent(VolcanoNearViewingLedge);
    }
    if (room.like(VolcanoNearViewingLedge) || room.like(WideLedge)) {
        return game.ent(VolcanoNearWideLedge);
    }
    throw new Error('Unexpected room for balloon.');
}

function getNextRoomDown(game: Game, room: Room | undefined) {
    if (!room) throw new Error('Expected balloon to be in a room.');
    if (room.like(VolcanoNearSmallLedge)) {
        return game.ent(VolcanoCore);
    }
    if (room.like(VolcanoCore)) {
        return game.ent(VolcanoBottom);
    }
    if (room.like(NarrowLedge)) {
        return game.ent(VolcanoNearSmallLedge);
    }
    if (room.like(VolcanoNearViewingLedge)) {
        return game.ent(VolcanoNearSmallLedge);
    }
    if (room.like(VolcanoNearWideLedge)) {
        return game.ent(VolcanoNearViewingLedge);
    }
    if (room.like(WideLedge)) {
        return game.ent(WideLedge);
    }
    if (room.like(VolcanoBottom)) {
        return game.ent(VolcanoBottom);
    }
    throw new Error('Unexpected room for balloon.');
}

async function moveBalloon(
    game: Game,
    runner: Runner,
    room: Room,
    message: string
) {
    const balloon = game.ent(Balloon);
    const player = game.ent(Player);
    balloon.setTimeUntilNextFly(3);
    if (balloon.contains(player)) {
        await runner.doOutput(`The balloon ${message}`);
        await game.applyAction(runner, new SpecialEnter({ room }));
    } else {
        balloon.moveTo(room);
        await runner.doOutput(`You watch as the balloon slowly ${message}`);
    }
}

const examineBalloonContents: Handler = async ({ action, runner }) => {
    if (
        action.is(Examine) &&
        (action.item.is(BraidedWire) ||
            action.item.is(BalloonReceptacle) ||
            action.item.is(ClothBag))
    ) {
        await runner.doOutput(
            `${action.item.The()} is part of the basket. ` +
                'It may be manipulated within the basket but cannot be removed.'
        );
        return Action.complete();
    }
};

const takeBalloonContents: Handler = async ({ action, runner }) => {
    if (
        action.is(Take) &&
        (action.item.is(BraidedWire) ||
            action.item.is(BalloonReceptacle) ||
            action.item.is(ClothBag))
    ) {
        let message = `${action.item.The()} is an integral part of the basket and cannot be removed.`;
        if (action.item.is(BraidedWire)) {
            message += ` The wire might possibly be tied, though.`;
        }
        await runner.doOutput(message);
        return Action.complete();
    }
};

const goInBalloon: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Go) && actor?.vehicle()?.is(Balloon)) {
        if (
            action.direction === Direction.Up ||
            action.direction === Direction.Down
        ) {
            await runner.doOutput(
                "I'm afraid you can't control the balloon in this way."
            );
            return Action.complete();
        }

        if (game.ent(Hook1).isAnchoring() || game.ent(Hook2).isAnchoring()) {
            await runner.doOutput('You are tied to the ledge.');
            return Action.complete();
        }
    }
};
