import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Untie } from './Untie';
import { UnresolvedAction } from '../UnresolvedAction';

export class UntieUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'untie';

    fixture: Target | undefined;

    item: Target | undefined;

    constructor({
        fixture,
        item,
    }: {
        fixture: Target | undefined;
        item: Target | undefined;
    }) {
        super();
        this.fixture = fixture;
        this.item = item;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'untie something';
        }
        if (this.fixture === undefined) {
            return `untie ${targetDescription(game, this.item)} from something`;
        }
        return `untie ${targetDescription(
            game,
            this.item
        )} from ${targetDescription(game, this.fixture)}`;
    }
}

export const untieUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(UntieUnresolved) || actor === undefined) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) =>
            new UntieUnresolved({ fixture: action.fixture, item }),
        missing: () => 'What would you like to untie?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to untie, ${opt}?`,
        condition: (item) => item.isItem(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    let fixture;
    if (action.fixture) {
        const { item: resolvedFixture } = await game.resolve(
            runner,
            action.fixture,
            actor,
            {
                partial: (fixture) =>
                    new UntieUnresolved({ fixture, item: action.item }),
                missing: () => `To what would you like to untie ${item.the()}?`,
                ambiguous: (desc, opt) =>
                    `To which ${desc} would you like to untie ${item.the()}, ${opt}?`,
                condition: (item) => item.isItem() && item.isWeapon(),
            }
        );

        if (resolvedFixture === undefined)
            return Action.complete({ withConsequence: false });

        fixture = resolvedFixture;
    }

    if (!(await game.reach(runner, item, actor))) {
        return Action.complete();
    }

    if (fixture && !(await game.reach(runner, fixture, actor))) {
        return Action.complete();
    }

    return game.applyAction(runner, new Untie({ item, fixture }));
};
