import { Noun, Adjective } from '../../../parse';
import {
    makeFlammable,
    makeOpenable,
    makeReadable,
    makeTakeable,
} from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class GuideBook extends makeFlammable(
    makeTakeable(makeReadable(makeOpenable(Item)))
) {
    static spec(): EntitySpec<GuideBook> {
        return {
            ref: 'guide-book',
            constructor: GuideBook,
            initial: {
                isAflame: false,
                isOpen: true,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('book'),
                new Noun('books', { plural: true }),
                new Noun('guide'),
                new Noun('guides', { plural: true }),
                new Noun('guidebook'),
                new Noun('guidebooks', { plural: true }),
                new Noun('guide book'),
                new Noun('guide books', { plural: true }), // TODO collective? or make the item just singular...
            ],
            adjectives: [new Adjective('tour')],
            handlers: [],
        };
    }

    ref() {
        return GuideBook.spec().ref;
    }

    name() {
        return 'tour guidebook';
    }

    description() {
        return 'There are tour guidebooks here.';
    }

    initialDescription() {
        return "Some guidebooks entitled 'Flood Control Dam #3' are on the reception desk.";
    }

    size() {
        return 10;
    }

    text() {
        return GUIDE_BOOK_TEXT;
    }

    nouns(): Noun[] {
        return GuideBook.spec().nouns;
    }

    adjectives(): Adjective[] {
        return GuideBook.spec().adjectives;
    }
}

const GUIDE_BOOK_TEXT = `
  Guide Book to
    Flood Control Dam #3

  Flood Control Dam #3 (FCD#3) was constructed in year 783 of the Great Underground Empire to harness the destructive power of the Frigid River. This work was supported by a grant of 37 million zorkmids from the Central Bureaucracy and your omnipotent local tyrant Lord Dimwit Flathead the Excessive. This impressive structure is composed of 3.7 cubic feet of concrete, is 256 feet tall at the center, and 193 feet wide at the top. The reservoir created behind the dam has a volume of 37 billion cubic feet, an area of 12 million square feet, and a shore line of 36 thousand feet.

  The construction of FCD#3 took 112 days from ground breaking to the dedication. It required a work force of 384 slaves, 34 slave drivers, 12 engineers, 2 turtle doves, and a partridge in a pear tree. The work was managed by a command team composed of 2345 bureaucrats, 2347 secretaries (at least two of whom can type), 12,256 paper shufflers, 52,469 rubber stampers, 245,193 red tape processors, and nearly one million dead trees.

  We will now point out some of the more interesting features of FCD#3 as we conduct you on a guided tour of the facilities:

  1) You start your tour here in the Dam Lobby.
     You will notice on your right that .........`;
