import { Value } from '.';

export class Pronoun extends Value {
    plural: boolean;

    singular: boolean;

    constructor(value: string, { plural = false } = {}) {
        super(value);
        this.plural = plural;
        this.singular = !plural;
    }

    isEqual(other: Value): boolean {
        return (
            super.isEqual(other) &&
            other instanceof Pronoun &&
            this.plural === other.plural &&
            this.singular === other.singular
        );
    }
}
