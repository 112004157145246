import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Exorcise extends makeDescribable(Action) {
    id = 'exorcise';

    description(game: Game): string {
        return 'perform an exorcism';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('exorcise'),
            new Verb('perform exorcism'),
            new Verb('perform an exorcism'),
        ];
        return {
            handlers: [exorciseHandler],
            parser: () => Parse.words(verbs).into(new Exorcise()),
            verbs,
            prepositions: [],
        };
    }
}

export const exorciseHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Exorcise)) return;
    await runner.doOutput('Nothing happens.');
    return Action.complete();
};
