import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Melt } from './Melt';
import { UnresolvedAction } from '../UnresolvedAction';

export class MeltUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'melt';

    item: Target | undefined;

    tool: Target | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Target | undefined;
        tool: Target | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'melt something';
        }
        if (this.tool === undefined) {
            return `melt ${targetDescription(game, this.item)}`;
        }
        return `melt ${targetDescription(
            game,
            this.item
        )} with ${targetDescription(game, this.tool)}`;
    }
}

export const meltUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(MeltUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new MeltUnresolved({ item, tool: undefined }),
        missing: () => 'Melt what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to melt, ${opt}?`,
        condition: (item) => item.isItem(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    const { item: tool } = await game.resolve(runner, action.tool, actor, {
        partial: (tool) => new MeltUnresolved({ item: action.item, tool }),
        missing: () => `With what would you like to melt ${item.the()}?`,
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to use to melt ${item.the()}, ${opt}?`,
        condition: (item) => item.isItem(),
    });

    if (!tool) {
        return Action.complete({ withConsequence: false });
    }

    if (tool && !(await game.have(runner, tool, actor)))
        return Action.complete();

    return await game.applyAction(runner, new Melt({ item, tool }));
};
