import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class DonWoodsStamp extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<DonWoodsStamp> {
        return {
            ref: 'don-woods-stamp',
            constructor: DonWoodsStamp,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('stamp'),
                new Noun('stamps', { plural: true }),
                new Noun('postage stamp'),
                new Noun('postage stamps'),
                new Noun('postage', { collective: true }),
            ],
            adjectives: [
                new Adjective('don woods'),
                new Adjective('commemorative'),
            ],
        };
    }

    ref() {
        return DonWoodsStamp.spec().ref;
    }

    name() {
        return 'Don Woods stamp';
    }

    description() {
        return 'There is a Don Woods Commemorative stamp here.';
    }

    scoreOnTake(): number {
        return 0;
    }

    scoreInCase(): number {
        return 1;
    }

    size() {
        return 1;
    }

    nouns(): Noun[] {
        return DonWoodsStamp.spec().nouns;
    }

    adjectives(): Adjective[] {
        return DonWoodsStamp.spec().adjectives;
    }

    examineText() {
        return DON_WOODS_STAMP_IMAGE;
    }
}

const DON_WOODS_STAMP_IMAGE = ` --v----v----v----v----v--
|          _____          |
>  One    /     \\      G  <
| Lousy  /       \\     U  |
> Point |   ___   |    E  <
|       |  (___)  |       |
>       <--)___(-->    P  <
|       / /     \\ \\    o  |
>      / /       \\ \\   s  <
|     |-|---------|-|  t  |
>     | |  \\ _ /  | |  a  <
|     | | --(_)-- | |  g  |
>     | |  /| |\\  | |  e  <
|     |-|---|_|---|-|     |
>      \\ \\__/_\\__/ /      <
|      _/_________\\_      |
>      |  f.m.l.c. |      <
|       -----------       |
>                         <
|   Donald Woods, Editor  |
>     Spelunker Today     <
|                         |
 --^----^----^----^----^--`;
