import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { SlideLedge } from '../SlideLedge';
import { RedPalantir } from '../../items/RedPalantir';
import { Stove } from '../../items';

export class SootyRoom extends Room {
    static spec(): EntitySpec<SootyRoom> {
        return {
            ref: 'sooty-room',
            constructor: SootyRoom,
            initial: {
                contents: [
                    RedPalantir.spec().ref,
                    Stove.spec().ref,
                    // Crack?
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return SootyRoom.spec().ref;
    }

    name(): string {
        return 'Sooty Room';
    }

    description(): string {
        return (
            'This is a small room with rough walls, and a ceiling ' +
            'which is steeply sloping from north to south. There is coal ' +
            'dust covering almost everything, and little bits of coal are scattered ' +
            'around the only exit (which is a narrow passage to the north). ' +
            'In one corner of the room is an old coal stove which lights the room with ' +
            'a cheery red glow. There is a very narrow crack in the north wall.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: SlideLedge.spec().ref }),
        ];
    }
}
