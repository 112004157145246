import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Engravings } from '../../items';
import { RoundRoom } from '../RoundRoom';
import { RiddleRoom } from '../RiddleRoom';

export class EngravingsCave extends Room {
    static spec(): EntitySpec<EngravingsCave> {
        return {
            ref: 'engravings-cave',
            constructor: EngravingsCave,
            initial: {
                contents: [Engravings.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return EngravingsCave.spec().ref;
    }

    name(): string {
        return 'Engravings Cave';
    }

    description(): string {
        return 'You have entered a cave with passages leading north and southeast.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: RoundRoom.spec().ref }),
            new Passage({
                via: Direction.Southeast,
                to: RiddleRoom.spec().ref,
            }),
        ];
    }
}
