import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { WakeUnresolved, wakeUnresolvedHandler } from './WakeUnresolved';

export class Wake extends Action {
    id = '~wake';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [wakeHandler, wakeUnresolvedHandler],
            parser,
            verbs: [
                new Verb('wake'),
                new Verb('wake up'),
                new Verb('awaken'),
                new Verb('startle'),
            ],
            prepositions: [],
        };
    }
}

export const wakeHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Wake)) return;
    const { item } = action;
    if (item.isActor() && !item.isConscious()) {
        item.state.isConscious = true;
        await runner.doOutput(`Done.`);
    } else {
        await runner.doOutput(`${item.The()} isn't sleeping.`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, WakeUnresolved> => {
    const wake = Parse.words(Wake.ability().verbs);
    const wakeObject = wake.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const wakeObjectUp = Parse.word('wake').chain((_verb) =>
        Parse.target(game.lexicon)
            .after(Parse.whitespace())
            .before(Parse.whitespace().then(Parse.word('up')))
    );
    return Parse.any(
        // wake
        wake.into(new WakeUnresolved({ item: undefined })),
        // wake cyclops / wake cyclops up
        Parse.either(wakeObject, wakeObjectUp).map(
            (item) => new WakeUnresolved({ item })
        )
    );
};
