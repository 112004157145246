import { LadderBottom, LowerShaft, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Timber } from '../../items';
import { Player } from '../../actors';

export class TimberRoom extends Room {
    static spec(): EntitySpec<TimberRoom> {
        return {
            ref: 'timber-room',
            constructor: TimberRoom,
            initial: {
                contents: [Timber.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return TimberRoom.spec().ref;
    }

    name(): string {
        return 'Timber Room';
    }

    description(): string {
        return (
            'This is a long and narrow passage, which is cluttered with broken timbers. ' +
            'A wide passage comes from the north and turns at the ' +
            'southwest corner of the room into a very narrow passageway.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.North, to: LadderBottom.spec().ref }),
            new Passage({
                via: Direction.Southwest,
                condition: () => this.game.ent(Player).isEmptyHanded(),
                message: 'You cannot fit through this passage with that load.',
                to: LowerShaft.spec().ref,
            }),
        ];
    }
}
