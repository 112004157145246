import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Quit extends makeDescribable(Action) {
    id = 'quit';

    description(game: Game): string {
        return 'quit';
    }

    static ability(): Ability {
        const verbs = [new Verb('quit')];
        return {
            handlers: [quitHandler],
            parser: () => Parse.words(verbs).into(new Quit()),
            verbs,
            prepositions: [],
        };
    }
}

export const quitHandler: Handler = async ({ action, game, runner }) => {
    if (!action.is(Quit)) return;
    game.yesNo = async (answer: boolean) => {
        if (answer) {
            await game.quit(runner);
        } else {
            await runner.doOutput('Ok.');
        }
    };
    await runner.doOutput('Are you sure you want to quit?');
    return Action.complete({ withConsequence: false });
};
