import { Item, Openable, OpenableState } from '../../items/Item';
import { Constructor } from './Constructor';

export function makeOpenable<TBase extends Constructor<Item>>(Base: TBase) {
    abstract class C extends Base implements Openable {
        abstract state: OpenableState;

        canBeOpened(): boolean {
            return true;
        }

        isOpen(): boolean {
            return this.state.isOpen;
        }

        setIsOpen(isOpen: boolean) {
            this.state.isOpen = isOpen;
        }

        isOpenable(): this is Openable {
            return true;
        }
    }

    return C;
}
