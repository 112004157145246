import { Pronoun, WordList } from '../parse';

export const VOWELS = ['a', 'e', 'i', 'o', 'u'];

export const ALL_WORDS = new WordList();
ALL_WORDS.push(
    new Pronoun('all', { plural: true }),
    new Pronoun('everything', { plural: true }),
    new Pronoun('it all', { plural: true })
);

export const CURE_WAIT = 30;

export const YUKS = [
    'A valiant attempt.',
    "You can't be serious.",
    'Not a prayer.',
    'Not likely!',
    'An interesting idea...',
    'What a concept!',
    'Ha!', // ADD
];

export const SWIM_YUKS = [
    "I don't really see how.",
    'I think that swimming is best performed in water.',
    'Perhaps it is your head that is swimming.',
];

export const OFFENDED = [
    'Such language in a high-class establishment like this!',
    'You ought to be ashamed of yourself.',
    'Its not so bad. You could have been killed already.',
    'Tough shit, asshole.',
    'Oh, dear. Such language from a supposed winning adventurer!',
    'That is most distasteful.',
];

export const JUMP_DEATH = [
    'You should have looked before you leaped.',
    "I'm afraid that leap was a bit much for your weak frame.",
    'In the movies, your life would be passing in front of your eyes.',
    "I'm afraid that the leap you attempted has done you in.",
    'Geronimo.....',
];

export const WHEEE = [
    'Very good. Now you can go to the second grade.',
    'Have you tried hopping around the dungeon, too?',
    'Are you enjoying yourself?',
    'Wheeeeeeeeee!!!!!',
    'Do you expect me to applaud?',
];

export const DUMMY = [
    'Look around.',
    "You think it isn't?",
    "I think you've already done that.",
];

export const HO_HUM = [
    'does not seem to do anything.',
    'is not notably useful.',
    "isn't very interesting.",
    "doesn't appear worthwhile.",
    'has no effect.',
    "doesn't do anything.",
];

export const NO_TREE = 'There is no tree here suitable for climbing.';

export const MAZE_DESCRIPTION =
    'This is part of a maze of twisty little passages, all alike.';
export const DEAD_END_DESCRIPTION = 'You have come to a dead end in the maze.';

export const MINE_DESCRIPTION = 'This is a nondescript part of a coal mine.';

export const CURRENT_STRONG = 'You cannot go upstream due to strong currents.';

export const CLIFFS_IN_WAY = 'The White Cliffs prevent your landing here.';

export const HELLOS = [
    'Hello.',
    'Good day.',
    "Nice weather we've been having lately.",
    'Goodbye.',
];

export const PLAYER_SYMBOL = '▫';
export const MARBLE_SYMBOL = '▣';
export const SANDSTONE_SYMBOL = '▥';
export const UNKNOWN_SYMBOL = '?';
export const EMPTY_SYMBOL = ' ';

export const COMPLEX_ARCHITECTURE =
    '...\nThe architecture of this region is getting complex, so that ' +
    'further descriptions will include diagrams of the immediate vicinity in ' +
    'a 3x3 grid. The walls here are rock, but of two different types - ' +
    'sandstone and marble. The following notations will be used:\n' +
    `  ${PLAYER_SYMBOL} = current position (middle of grid)
  ${MARBLE_SYMBOL} = marble wall
  ${SANDSTONE_SYMBOL} = sandstone wall
  ${UNKNOWN_SYMBOL} = unknown (blocked by walls)`;

export const NUMBERS = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty',
    'twenty-one',
    'twenty-two',
    'twenty-three',
    'twenty-four',
    'twenty-five',
    'twenty-six',
    'twenty-eight',
    'twenty-nine',
    'thirty',
];
