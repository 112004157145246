import { Match } from './Match';
import { Parser } from './parser';
import { Token } from '../lexicon';

/**
 * Parser that yields all of the matches of each of the given `parsers`.
 *
 * ```
 * any(word("one"), word("two"), word("three")).match(tokenize("one"))
 * any(word("one"), word("two"), word("three")).match(tokenize("two"))
 * any(word("one"), word("two"), word("three")).match(tokenize("three"))
 * ```
 */
export class AnyParser<I, T> extends Parser<I, T> {
    parsers: Parser<I, T>[];

    constructor(...parsers: Parser<I, T>[]) {
        super();
        this.parsers = parsers;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, T>> {
        for (const parser of this.parsers) {
            for (const match of parser.match(tokens)) {
                yield match;
            }
        }
    }
}
