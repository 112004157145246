import { Noun } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeOpenable, makeTakeable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Gunk } from '..';
import { PutIn, Squeeze } from '../../abilities';

export class Tube extends makeTakeable(makeOpenable(makeContainer(Item))) {
    static spec(): EntitySpec<Tube> {
        return {
            ref: 'tube',
            constructor: Tube,
            initial: {
                contents: [Gunk.spec().ref],
                isOpen: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('tube'),
                new Noun('tubes', { plural: true }),
                new Noun('toothpaste', { collective: true }),
                new Noun('tube of toothpaste'),
                new Noun('tubes of toothpaste', { plural: true }),
                new Noun('tube of gunk'),
                new Noun('tubes of gunk', { plural: true }),
                new Noun('tube of glue'),
                new Noun('tubes of glue', { plural: true }),
                new Noun('tube of putty'),
                new Noun('tubes of putty', { plural: true }),
            ],
            adjectives: [],
            handlers: [putStuffInTube, squeezeTube],
        };
    }

    ref() {
        return Tube.spec().ref;
    }

    name(): string {
        return 'tube';
    }

    description(): string {
        return 'There is an object which looks like a tube of toothpaste here.';
    }

    totalCapacity(): number {
        return 7;
    }

    size(): number {
        return 10;
    }

    canBeOpened(): boolean {
        return true;
    }

    canSeeInto(): boolean {
        return this.isOpen() || this.isTransparent();
    }

    nouns() {
        return Tube.spec().nouns;
    }

    adjectives() {
        return Tube.spec().adjectives;
    }

    text() {
        return `---> Frobozz Magic Gunk Company <---
         All-Purpose Gunk`;
    }
}

const putStuffInTube: Handler = async ({ action, runner }) => {
    if (action.is(PutIn) && action.container.is(Tube)) {
        await runner.doOutput('The tube refuses to accept anything.');
        return Action.complete();
    }
};

const squeezeTube: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Squeeze) && action.item.is(Tube)) {
        if (!action.item.isOpen()) {
            await runner.doOutput('The tube is closed.');
        } else if (action.item.isEmpty()) {
            await runner.doOutput('The tube is apparently empty.');
        } else {
            game.ent(Gunk).moveTo(actor);
            await runner.doOutput('The viscous material oozes into your hand.');
        }
        return Action.complete();
    }
};
