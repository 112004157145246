import { Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Kill, Mung, Poke, Throw } from '../../abilities';

interface GhostsState extends ItemState {
    isVeryAfraid: boolean;
}

export class Ghosts extends Item<GhostsState> {
    static spec(): EntitySpec<Ghosts> {
        return {
            ref: 'ghosts',
            constructor: Ghosts,
            initial: {
                isVeryAfraid: false,
            },
            nouns: [
                new Noun('ghost'),
                new Noun('ghosts', { collective: true }),
                new Noun('fiend'),
                new Noun('fiends', { collective: true }),
                new Noun('spirit'),
                new Noun('spirits', { collective: true }),
            ],
            adjectives: [],
            handlers: [attackGhosts],
        };
    }

    isVeryAfraid() {
        return this.state.isVeryAfraid;
    }

    setIsVeryAfraid(isVeryAfraid: boolean) {
        this.state.isVeryAfraid = isVeryAfraid;
    }

    isVictim() {
        return true;
    }

    ref() {
        return Ghosts.spec().ref;
    }

    name(): string {
        return 'number of ghosts';
    }

    description(): string {
        return (
            'The way through the gate is barred by evil spirits, ' +
            'who jeer at your attempts to pass.'
        );
    }

    nouns() {
        return Ghosts.spec().nouns;
    }

    adjectives() {
        return Ghosts.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const attackGhosts: Handler = async ({ action, runner }) => {
    if (
        ((action.is(Kill) || action.is(Poke)) && action.enemy.is(Ghosts)) ||
        (action.is(Mung) && action.item.is(Ghosts)) ||
        (action.is(Throw) && action.enemy?.is(Ghosts))
    ) {
        if (
            ((action.is(Kill) || action.is(Poke) || action.is(Mung)) &&
                action.weapon) ||
            (action.is(Throw) && action.item)
        ) {
            await runner.doOutput(
                'How can you attack a spirit with material objects?'
            );
        } else {
            await runner.doOutput('You seem unable to affect these spirits.');
        }
        return Action.complete();
    }
};
