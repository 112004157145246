import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { GoldCard, Leaflet, SteelDoor } from '../index';
import { makeContainer } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { PutIn } from '../../abilities';
import { YUKS } from '../../constants';

export class Slit extends makeContainer(Item) {
    static spec(): EntitySpec<Slit> {
        return {
            ref: 'slit',
            constructor: Slit,
            initial: {
                contents: [Leaflet.spec().ref],
            },
            nouns: [
                new Noun('slit'),
                new Noun('slot'),
                new Noun('slits', { plural: true }),
                new Noun('slots', { plural: true }),
                new Noun('card reader'),
                new Noun('card readers', { plural: true }),
            ],
            adjectives: [new Adjective('small')],
            handlers: [putCardInReader],
        };
    }

    ref() {
        return Slit.spec().ref;
    }

    name(): string {
        return 'small slit';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed() {
        return false;
    }

    totalCapacity(): number {
        return 4;
    }

    canBeOpened(): boolean {
        return true;
    }

    nouns() {
        return Slit.spec().nouns;
    }

    adjectives() {
        return Slit.spec().adjectives;
    }
}

const putCardInReader: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(PutIn) &&
        action.container.is(Slit) &&
        actor?.hasItem(action.item)
    ) {
        action.item.moveTo(undefined);
        if (action.item.is(GoldCard)) {
            game.ent(SteelDoor).setIsOpen(true);
            await runner.doOutput(
                'The card slides easily into the slot and vanishes ' +
                    'and the metal door slides open revealing a passageway ' +
                    'to the west. A moment later, a previously unseen sign flashes:\n' +
                    "    'Unauthorized/Illegal Use of Pass Card -- Card Confiscated'"
            );
        } else if (action.item.isActor()) {
            await runner.doOutput(game.choiceOf(YUKS));
        } else {
            await runner.doOutput(
                'The item vanishes into the slot. A moment later, a previously unseen ' +
                    "sign flashes 'Garbage In, Garbage Out' and " +
                    `spews ${action.item.the()} (now atomized) through the slot.`
            );
        }
        return Action.complete();
    }
};
