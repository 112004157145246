import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeOpenable, makeTakeable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Mung, Open, SpecialJigsUp, Throw } from '../../abilities';
import { MungedSource } from '../../rooms';
import { Actor } from '../../actors';
import { Game } from '../../game/game';
import { Runner } from '../../game/Runner';

export class Flask extends makeTakeable(makeOpenable(makeContainer(Item))) {
    static spec(): EntitySpec<Flask> {
        return {
            ref: 'flask',
            constructor: Flask,
            initial: {
                contents: [],
                isOpen: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('flask of poison'),
                new Noun('flask'),
                new Noun('flasks', { plural: true }),
                new Noun('flasks of poison', { plural: true }),
            ],
            adjectives: [new Adjective('glass')],
            handlers: [openFlask, throwFlask],
        };
    }

    size(): number {
        return 10;
    }

    ref() {
        return Flask.spec().ref;
    }

    name(): string {
        return 'glass flask filled with liquid';
    }

    description(): string {
        return (
            'A stoppered glass flask with a skull-and-crossbones ' +
            'marking is here.\nThe flask is filled with some clear liquid.'
        );
    }

    totalCapacity(): number {
        return 5;
    }

    isTransparent(): boolean {
        return true;
    }

    nouns() {
        return Flask.spec().nouns;
    }

    adjectives() {
        return Flask.spec().adjectives;
    }
}

async function oops(game: Game, runner: Runner, actor: Actor) {
    const room = actor.location();
    if (room) {
        room.mung(MungedSource.NoxiousGas);
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    'Just before you pass out, you notice that the vapors ' +
                    "from the flask's contents are fatal.",
            })
        );
    }
}

const openFlask: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Open) && action.item.is(Flask) && actor) {
        await oops(game, runner, actor);
        return Action.complete();
    }
};

const throwFlask: Handler = async ({ action, runner, game, actor }) => {
    if (
        (action.is(Throw) || action.is(Mung)) &&
        action.item.is(Flask) &&
        actor
    ) {
        action.item.moveTo(undefined);
        await runner.doOutput('The flask breaks into pieces.');
        await oops(game, runner, actor);
        return Action.complete();
    }
};
