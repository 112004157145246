import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { PlayUnresolved, playUnresolvedHandler } from './PlayUnresolved';
import { SpecialJigsUp } from '../SpecialJigsUp';
import { HO_HUM } from '../../constants';

export class Play extends Action {
    id = '~play';

    item: Entity;

    tool: Entity | undefined;

    constructor({ item, tool }: { item: Entity; tool: Entity | undefined }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [playHandler, playUnresolvedHandler],
            parser,
            verbs: [new Verb('play')],
            prepositions: [new Preposition('with'), new Preposition('using')],
        };
    }
}

export const playHandler: Handler = async ({ action, runner, game, actor }) => {
    if (!action.is(Play)) return;
    const { item, tool } = action;

    if (tool === undefined && item.isActor() && item.isVillain()) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    `You are so engrossed in the role of ${item.the()} ` +
                    `that you kill yourself, just as he would have done!`,
            })
        );
    } else {
        await runner.doOutput(
            `Playing with ${item.the()} ${game.choiceOf(HO_HUM)}`
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, PlayUnresolved> => {
    const play = Parse.words(Play.ability().verbs);
    const playObject = play.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const playObjectWith = playObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // play
        play.into(new PlayUnresolved({ item: undefined, tool: undefined })),
        // play box
        playObject.map((item) => new PlayUnresolved({ item, tool: undefined })),
        // play egg with hammer
        playObjectWith.map(
            ([item, tool]) =>
                new PlayUnresolved({
                    item,
                    tool,
                })
        )
    );
};
