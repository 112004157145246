import { Verb, Parse, Parser, Value, SpecialDirection } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { LaunchUnresolved, launchUnresolvedHandler } from './LaunchUnresolved';
import { Player } from '../../actors';
import { Go } from '..';

export class Launch extends Action {
    id = '~launch';

    vehicle: Entity;

    constructor({ vehicle }: { vehicle: Entity }) {
        super();
        this.vehicle = vehicle;
    }

    static ability(): Ability {
        return {
            handlers: [launchHandler, launchUnresolvedHandler],
            parser,
            verbs: [new Verb('launch'), new Verb('set sail')],
            prepositions: [],
        };
    }
}

export const launchHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Launch)) return;
    const { vehicle } = action;
    const player = game.ent(Player);

    if (
        !vehicle.isItem() ||
        !vehicle.isVehicle() ||
        !vehicle.contains(player)
    ) {
        await runner.doOutput(
            `You might want to get in ${vehicle.the()} before launching.`
        );
    } else {
        await game.applyAction(
            runner,
            new Go({ direction: SpecialDirection.Launch })
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, LaunchUnresolved> => {
    const launch = Parse.words([new Verb('launch'), new Verb('set sail')]);
    const launchObject = Parse.words([new Verb('launch')]).chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // launch
        launch.into(new LaunchUnresolved({ vehicle: undefined })),
        // launch boat
        launchObject.map((vehicle) => new LaunchUnresolved({ vehicle }))
    );
};
