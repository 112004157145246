import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler, Reference } from '../../game';
import {
    GoThrough,
    Kill,
    Move,
    Mung,
    Poke,
    Push,
    Rub,
    SpecialEnter,
    SpecialJigsUp,
    SpecialTimerTick,
    Take,
    Throw,
} from '../../abilities';
import { Room, SmallBankRoom, Vault } from '../../rooms';
import { Player, ZurichGnome } from '../../actors';

interface CurtainState extends ItemState {
    destinationRoom: Reference | undefined;
    alarmTimer: number;
    isAlarmTriggered: boolean;
}

export class Curtain extends Item<CurtainState> {
    static spec(): EntitySpec<Curtain> {
        return {
            ref: 'curtain',
            constructor: Curtain,
            initial: {
                destinationRoom: undefined,
                isAlarmTriggered: false,
                alarmTimer: 5,
            },
            nouns: [
                new Noun('curtain'),
                new Noun('curtains', { plural: true }),
                new Noun('light', { collective: true }),
                new Noun('wall'),
                new Noun('walls', { plural: true }),
                new Noun('screen'),
                new Noun('screens', { plural: true }),
                new Noun('curtain of light'),
                new Noun('curtains of light', { plural: true }),
            ],
            adjectives: [
                new Adjective('shimmering'),
                new Adjective('north'),
                new Adjective('northern'),
            ],
            handlers: [
                touchCurtain,
                attackCurtain,
                throwAtCurtain,
                goThroughCurtain,
                tickCurtainAlarm,
            ],
        };
    }

    ref() {
        return Curtain.spec().ref;
    }

    name(): string {
        return 'shimmering curtain of light';
    }

    description(): string {
        return '';
    }

    nouns() {
        return Curtain.spec().nouns;
    }

    adjectives() {
        return Curtain.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    destinationRoom(): Room | undefined {
        return this.state.destinationRoom
            ? (this.game.get(this.state.destinationRoom) as Room)
            : undefined;
    }
}

const touchCurtain: Handler = async ({ action, runner }) => {
    if (
        (action.is(Take) ||
            action.is(Push) ||
            action.is(Move) ||
            action.is(Rub)) &&
        action.item.is(Curtain)
    ) {
        await runner.doOutput('As you try, your hand seems to go through it.');
        return Action.complete();
    }
};

const attackCurtain: Handler = async ({ action, runner }) => {
    if (
        ((action.is(Poke) || (action.is(Kill) && action.weapon)) &&
            action.enemy.is(Curtain)) ||
        (action.is(Mung) && action.item.is(Curtain))
    ) {
        if (action.weapon) {
            await runner.doOutput(`${action.weapon.The()} goes through it.`);
        } else {
            await runner.doOutput(
                'As you try, your hand seems to go through it.'
            );
        }
        return Action.complete();
    }
};

const throwAtCurtain: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Throw) && action.enemy?.is(Curtain)) {
        const destination = action.enemy.destinationRoom();
        if (destination) {
            action.item.moveTo(destination);
            game.ent(Curtain).state.destinationRoom = undefined;
            await runner.doOutput(
                `The curtain dims slightly as the ${action.item.the()} passes through.`
            );
        } else {
            const room = actor?.location();
            if (room) action.item.moveTo(room);
            await runner.doOutput(
                `As if caught by an invisible net, ${action.item.the()} ` +
                    'slows midair then falls gently to the floor.'
            );
        }
        return Action.complete();
    }
};

const goThroughCurtain: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(GoThrough) && action.item?.is(Curtain)) {
        const destination = action.item.destinationRoom();
        if (destination) {
            await runner.doOutput(
                'You feel somewhat disoriented as you pass through...'
            );
            action.item.state.alarmTimer = 5;
            action.item.state.isAlarmTriggered = true;
            await game.applyAction(
                runner,
                new SpecialEnter({ room: destination }),
                actor
            );
        } else {
            await runner.doOutput(
                "You can't go more than part way through the curtain."
            );
        }
        return Action.complete();
    }
};

const tickCurtainAlarm: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const curtain = game.ent(Curtain);
        if (curtain.state.isAlarmTriggered) {
            curtain.state.alarmTimer -= 1;
            if (curtain.state.alarmTimer === 0) {
                const player = game.ent(Player);
                const room = player.location();
                if (room.is(SmallBankRoom)) {
                    const gnome = game.ent(ZurichGnome);
                    gnome.state.isArrivingSoon = true;
                    gnome.state.timeUntilArrival = 5;
                }
                if (room.is(Vault)) {
                    return game.applyAction(
                        runner,
                        new SpecialJigsUp({ message: VAULT_TRAPPED })
                    );
                }
            }
        }
    }
};

const VAULT_TRAPPED =
    "A metallic voice says, 'Hello, Intruder! Your unauthorized " +
    'presence in the vault area of the Bank of Zork has set off ' +
    'all sorts of nasty surprises, several of which seem to have ' +
    'been fatal. This message brought to you by the ' +
    "Frobozz Magic Alarm Company.'";
