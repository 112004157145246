import { Cellar, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { Rope, Slide } from '../../items';
import { InASlide2 } from '../InASlide2';
import { SlideLedge } from '../SlideLedge';

export class InASlide3 extends Room {
    static spec(): EntitySpec<InASlide3> {
        return {
            ref: 'in-a-slide-3',
            constructor: InASlide3,
            initial: {
                contents: [Slide.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return InASlide3.spec().ref;
    }

    name(): string {
        return 'Slide';
    }

    description(): string {
        return (
            'You have reached the end of your rope. Below you ' +
            'is darkness as the chute makes a sharp turn. On the east here ' +
            'is a small ledge which you might be able to stand on.'
        );
    }

    globalObjects(): Entity[] {
        return [this.game.ent(Rope)];
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Up, to: InASlide2.spec().ref }),
            new Passage({ via: Direction.East, to: SlideLedge.spec().ref }),
            new Passage({
                via: Direction.Down,
                to: Cellar.spec().ref,
            }),
        ];
    }
}
