import { Noun } from '../../../parse';
import { Item } from '../Item';
import { makeContainer } from '../../game/Entity';
import { EntitySpec } from '../../game';
import { RustyKey } from '../RustyKey';

export class OakDoorKeyhole2 extends makeContainer(Item) {
    static spec(): EntitySpec<OakDoorKeyhole2> {
        return {
            ref: 'oak-door-keyhole-2',
            constructor: OakDoorKeyhole2,
            initial: {
                contents: [RustyKey.spec().ref],
            },
            nouns: [
                new Noun('keyhole'),
                new Noun('key hole'),
                new Noun('hole'),
                new Noun('keyholes', { plural: true }),
                new Noun('key holes', { plural: true }),
                new Noun('holes', { plural: true }),
            ],
            adjectives: [],
        };
    }

    ref() {
        return OakDoorKeyhole2.spec().ref;
    }

    name(): string {
        return 'keyhole';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    description(): string {
        return '';
    }

    totalCapacity(): number {
        return 0;
    }

    nouns() {
        return OakDoorKeyhole2.spec().nouns;
    }

    adjectives() {
        return OakDoorKeyhole2.spec().adjectives;
    }
}
