import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeReadable, makeTakeable } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { EntitySpec } from '../../game';

interface BlueLabelState extends ItemState {
    isHidden: boolean;
}

abstract class Base extends Item<BlueLabelState> {}

export class BlueLabel extends makeFlammable(makeTakeable(makeReadable(Base))) {
    static spec(): EntitySpec<BlueLabel> {
        return {
            ref: 'blue-label',
            constructor: BlueLabel,
            initial: {
                isAflame: false,
                isHidden: true,
                hasBeenTaken: false,
            },
            nouns: [new Noun('label'), new Noun('labels', { plural: true })],
            adjectives: [new Adjective('blue')],
        };
    }

    isHidden() {
        return this.state.isHidden;
    }

    setIsHidden(isHidden: boolean) {
        this.state.isHidden = isHidden;
    }

    ref() {
        return BlueLabel.spec().ref;
    }

    name() {
        return 'blue label';
    }

    description() {
        return 'There is a blue label here.';
    }

    size() {
        return 3;
    }

    text() {
        return BALLOON_INSTRUCTIONS;
    }

    nouns(): Noun[] {
        return BlueLabel.spec().nouns;
    }

    adjectives(): Adjective[] {
        return BlueLabel.spec().adjectives;
    }
}

const BALLOON_INSTRUCTIONS = `    !!!!  FROBOZZ MAGIC BALLOON COMPANY !!!!

  Hello, Aviator!

  Instructions for use:
    To get into balloon, say 'board'
    To leave balloon, say 'disembark'
    To land, say 'land'

  Warranty:
    No warranty is expressed or implied. You're on your own, sport!
    Good Luck.`;
