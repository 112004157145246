import { Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Examine, Take } from '../../abilities';

export class ToolChests extends Item {
    static spec(): EntitySpec<ToolChests> {
        return {
            ref: 'tool-chests',
            constructor: ToolChests,
            initial: {},
            nouns: [
                new Noun('tool chest'),
                new Noun('tool chests', { plural: true }),
                new Noun('toolbox'),
                new Noun('toolboxes', { plural: true }),
                new Noun('tool box'),
                new Noun('tool boxes', { plural: true }),
            ],
            adjectives: [],
            handlers: [examineChests, takeChests],
        };
    }

    ref() {
        return ToolChests.spec().ref;
    }

    name(): string {
        return 'group of tool chests';
    }

    description(): string {
        return '';
    }

    nouns() {
        return ToolChests.spec().nouns;
    }

    adjectives() {
        return ToolChests.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

export const examineChests: Handler = async ({ action, runner }) => {
    if (action.is(Examine) && action.item.is(ToolChests)) {
        await runner.doOutput('The chests are all empty.');
        return Action.complete();
    }
};

export const takeChests: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(ToolChests)) {
        await runner.doOutput('The chests are fastened to the walls.');
        return Action.complete();
    }
};

/*
TODO maybe
  if parse.verb in game.get_verbs("open"):
    raise Complete("The chests are already open.")
  if parse.verb in game.get_verbs("close"):
    raise Complete("The hinges are stiff with rust and won't budge.")
 */
