import { LoudRoom, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { Dam } from '../Dam';

export class DampCave extends Room {
    static spec(): EntitySpec<DampCave> {
        return {
            ref: 'damp-cave',
            constructor: DampCave,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return DampCave.spec().ref;
    }

    name(): string {
        return 'Damp Cave';
    }

    description(): string {
        return (
            'This is a cave. Passages exit to the south and to the east, ' +
            'but the cave narrows to a crack to the west. ' +
            'The earth is particularly damp here.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.South, to: LoudRoom.spec().ref }),
            new Passage({ via: Direction.East, to: Dam.spec().ref }),
        ];
    }
}
