import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Feature extends makeDescribable(Action) {
    id = 'feature';

    description(game: Game): string {
        return 'say "feature"';
    }

    static ability(): Ability {
        const verbs = [new Verb('feature')];
        return {
            handlers: [featureHandler],
            parser: () => Parse.words(verbs).into(new Feature()),
            verbs,
            prepositions: [],
        };
    }
}

export const featureHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Feature)) return;
    await runner.doOutput(
        'Please forward suggestions by Internet mail to chrisotpher@swenson.org.'
    );
    return Action.complete();
};
