import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Take } from '../../abilities';

export class Slag extends makeFlammable(makeTakeable(Item)) {
    static spec(): EntitySpec<Slag> {
        return {
            ref: 'slag',
            constructor: Slag,
            initial: {
                hasBeenTaken: false,
                isAflame: false,
            },
            nouns: [
                new Noun('piece of vitreous slag'),
                new Noun('pieces of vitreous slag', { plural: true }),
                new Noun('slag', { collective: true }),
                new Noun('gunk', { collective: true }),
            ],
            adjectives: [new Adjective('vitreous'), new Adjective('small')],
            handlers: [takeSlag],
        };
    }

    ref() {
        return Slag.spec().ref;
    }

    name() {
        return 'piece of vitreous slag';
    }

    description() {
        return 'There is a small piece of vitreous slag here.';
    }

    nouns(): Noun[] {
        return Slag.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Slag.spec().adjectives;
    }

    size(): number {
        return 10;
    }
}

// TODO it seems like the intent is if you do _anything_ to the slag, it dies, not just take
const takeSlag: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Slag)) {
        await runner.doOutput(
            'The slag turns out to be rather insubstantial, and crumbles ' +
                'into dust at your touch. It must not have been very valuable.'
        );
        action.item.moveTo(undefined);
        return Action.complete();
    }
};
