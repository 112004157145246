import { Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Plug } from '../../abilities';

export class PoolLeak extends Item {
    static spec(): EntitySpec<PoolLeak> {
        return {
            ref: 'pool-leak',
            constructor: PoolLeak,
            initial: {},
            nouns: [
                new Noun('leak'),
                new Noun('leaks', { collective: true }),
                new Noun('drip'),
                new Noun('drips', { collective: true }),
            ],
            adjectives: [],
            handlers: [plugLeak],
        };
    }

    ref() {
        return PoolLeak.spec().ref;
    }

    name(): string {
        return 'leak';
    }

    description(): string {
        return '';
    }

    nouns() {
        return PoolLeak.spec().nouns;
    }

    adjectives() {
        return PoolLeak.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const plugLeak: Handler = async ({ action, runner }) => {
    if (action.is(Plug) && action.item.is(PoolLeak)) {
        await runner.doOutput('The leak is too high above you to reach.');
        return Action.complete();
    }
};
