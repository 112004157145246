import { Tell } from './Tell';
import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { UnresolvedAction } from '../UnresolvedAction';

export class TellUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'tell';

    person: Target | undefined;

    message: string | undefined;

    constructor({
        person,
        message,
    }: {
        person: Target | undefined;
        message: string | undefined;
    }) {
        super();
        this.person = person;
        this.message = message;
    }

    description(game: Game) {
        if (!this.person) {
            return 'tell someone something';
        }
        if (!this.message) {
            return `tell ${targetDescription(game, this.person)} something`;
        }
        return `tell ${targetDescription(game, this.person)} "${this.message}"`;
    }
}

export const tellUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(TellUnresolved)) return;
    const { item: person } = await game.resolve(runner, action.person, actor, {
        partial: (person) =>
            new TellUnresolved({ person, message: action.message }),
        ambiguous: (desc, opt) =>
            `To which ${desc} would you like to speak, ${opt}?`,
    });

    if (!person) return Action.complete({ withConsequence: false });

    if (!action.message) {
        await runner.doOutput(`You must specify what to tell ${person.the()}`);
        return Action.complete({ withConsequence: false });
    }

    return await game.applyAction(
        runner,
        new Tell({ person, message: action.message })
    );
};
