import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Open } from '../../abilities';
import { makeOpenable } from '../../game/Entity';

interface CellDoorState extends ItemState {
    isLocked: boolean;
}

abstract class Base extends Item<CellDoorState> {}

export class CellDoor extends makeOpenable(Base) {
    static spec(): EntitySpec<CellDoor> {
        return {
            ref: 'cell-door',
            constructor: CellDoor,
            initial: {
                isLocked: false,
                isOpen: false,
            },
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [
                new Adjective('cell'),
                new Adjective('wood'),
                new Adjective('wooden'),
            ],
            handlers: [openCellDoor],
        };
    }

    ref() {
        return CellDoor.spec().ref;
    }

    name(): string {
        return 'cell door';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns() {
        return CellDoor.spec().nouns;
    }

    adjectives() {
        return CellDoor.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isLocked() {
        return this.state.isLocked;
    }

    setIsLocked(isLocked: boolean) {
        this.state.isLocked = isLocked;
    }
}

const openCellDoor: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(CellDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput('The wooden door is open.');
        } else if (door.isLocked()) {
            await runner.doOutput('The door is securely fastened.');
        } else {
            door.setIsOpen(true);
            await runner.doOutput('The wooden door opens.');
        }
        return Action.complete();
    }

    if (action.is(Close) && action.item.is(CellDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            door.setIsOpen(false);
            await runner.doOutput('The wooden door closes.');
        } else {
            await runner.doOutput('The wooden door is already closed.');
        }
        return Action.complete();
    }
};
