import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeReadable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Eat } from '../../abilities';
import { TeaRoom } from '../../rooms/TeaRoom';

export class EatMeCake extends makeReadable(makeTakeable(Item)) {
    static spec(): EntitySpec<EatMeCake> {
        return {
            ref: 'eat-me-cake',
            constructor: EatMeCake,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('cake', { collective: true }),
                new Noun('cakes', { plural: true }),
                new Noun('piece of cake'),
                new Noun('pieces of cake', { plural: true }),
                new Noun("piece of 'eat-me' cake"),
                new Noun("pieces of 'eat-me' cake", { plural: true }),
                new Noun('piece of eat me cake'),
                new Noun('pieces of eat me cake', { plural: true }),
                new Noun('piece of eatme cake'),
                new Noun('pieces of eatme cake', { plural: true }),
                new Noun('piece of eat-me cake'),
                new Noun('pieces of eat-me cake', { plural: true }),
            ],
            adjectives: [
                new Adjective('eat-me'),
                new Adjective("'eat-me'"),
                new Adjective('eat me'),
                new Adjective('eatme'),
            ],
            handlers: [eatEatMeCake],
        };
    }

    text(): string {
        throw new Error('Expected this to be overridden.');
    }

    size(): number {
        return 4;
    }

    ref() {
        return EatMeCake.spec().ref;
    }

    name() {
        return "piece of 'Eat-Me' cake";
    }

    description() {
        return "There is a piece of cake here with the words 'Eat-Me' on it.";
    }

    nouns(): Noun[] {
        return EatMeCake.spec().nouns;
    }

    adjectives(): Adjective[] {
        return EatMeCake.spec().adjectives;
    }

    isFood(): boolean {
        return true;
    }
}

const eatEatMeCake: Handler = async ({ action, runner, actor }) => {
    if (action.is(Eat) && action.item.is(EatMeCake)) {
        const room = actor?.location();
        if (room?.is(TeaRoom)) {
            action.item.moveTo(undefined);
            room.state.isEnlarged = true;
            // TODO this
            await runner.doOutput(
                'Suddenly, the room appears to have become very large.'
            );
            return Action.complete();
        }
    }
};
