import { SpecialAction } from '../SpecialAction';
import { Action, Effect } from '../../game';

export class SpecialAfter extends SpecialAction {
    id = '*after';

    action: Action;

    effect: Effect;

    constructor({ action, effect }: { action: Action; effect: Effect }) {
        super();
        this.action = action;
        this.effect = effect;
    }
}
