import { Adjective, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Push } from '../../abilities';
import { RedBeam } from '../RedBeam';

interface RedEndgameButtonState extends ItemState {
    isDepressed: boolean;
}

export class RedEndgameButton extends Item<RedEndgameButtonState> {
    static spec(): EntitySpec<RedEndgameButton> {
        return {
            ref: 'red-endgame-button',
            constructor: RedEndgameButton,
            initial: {
                isDepressed: false,
            },
            nouns: [
                new Noun('button'),
                new Noun('buttons', { plural: true }),
                new Noun('switch'),
                new Noun('switches', { plural: true }),
            ],
            adjectives: [new Adjective('red')],
            handlers: [pushRedButton],
        };
    }

    ref() {
        return RedEndgameButton.spec().ref;
    }

    name(): string {
        return 'red button';
    }

    description(): string {
        return '';
    }

    nouns() {
        return RedEndgameButton.spec().nouns;
    }

    adjectives() {
        return RedEndgameButton.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isDepressed() {
        return this.state.isDepressed;
    }

    setIsDepressed(isDepressed: boolean) {
        this.state.isDepressed = isDepressed;
    }
}

const pushRedButton: Handler = async ({ action, runner, game }) => {
    if (action.is(Push) && action.item.is(RedEndgameButton)) {
        const { item: button } = action;
        if (button.isDepressed()) {
            await runner.doOutput('The button is already depressed.');
        } else if (!game.ent(RedBeam).isBlocked()) {
            await runner.doOutput('The button pops back out.');
        } else {
            button.setIsDepressed(true);
            await runner.doOutput('The button becomes depressed.');
        }
        return Action.complete();
    }
};
