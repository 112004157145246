import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { badDoor } from '../utils';
import { GoThrough, LookIn, SpecialLook } from '../../abilities';
import { Player } from '../../actors';
import { OakDoor } from '../OakDoor';
import { DrearyRoom, TinyRoom } from '../../rooms';

export class BarredWindow extends Item {
    static spec(): EntitySpec<BarredWindow> {
        return {
            ref: 'barred-window',
            constructor: BarredWindow,
            initial: {},
            nouns: [
                new Noun('window'),
                new Noun('windows', { plural: true }),
                new Noun('bars', { collective: true }),
            ],
            adjectives: [new Adjective('barred')],
            handlers: [
                handleBarredWindowOpenClose,
                lookInWindow,
                walkThroughWindow,
            ],
        };
    }

    ref() {
        return BarredWindow.spec().ref;
    }

    name(): string {
        return 'barred window';
    }

    description(): string {
        return ''; // TODO make description not exist if shouldBeDescribed is false
    }

    nouns() {
        return BarredWindow.spec().nouns;
    }

    adjectives() {
        return BarredWindow.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToOpen(): boolean {
        return true;
    }
}

const handleBarredWindowOpenClose = badDoor({
    item: BarredWindow,
    open: 'There is no way to open this window.',
    close: 'The window is barred.',
});

const lookInWindow: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(LookIn) &&
        action.item.is(BarredWindow) &&
        actor?.is(Player)
    ) {
        if (game.ent(OakDoor).isOpen()) {
            await runner.doOutput('The door is open, dummy.');
        } else {
            const room = actor?.location();
            const otherRoom = room.is(TinyRoom)
                ? game.ent(DrearyRoom)
                : game.ent(TinyRoom);
            await game.applyAction(
                runner,
                new SpecialLook({
                    room: otherRoom,
                    force: true,
                    showRoomName: false,
                })
            );
            return Action.complete();
        }
    }
};

const walkThroughWindow: Handler = async ({ action, runner, actor, game }) => {
    if (
        action.is(GoThrough) &&
        action.item.is(BarredWindow) &&
        actor?.is(Player)
    ) {
        await runner.doOutput('Perhaps if you were diced....');
        return Action.complete();
    }
};
