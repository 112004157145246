import { Action, Entity } from '../game';

export abstract class UnresolvedAction extends Action {
    isUnresolved() {
        return true;
    }

    mentions(entity: Entity) {
        return false;
    }
}
