import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { SetTo } from './SetTo';
import { UnresolvedAction } from '../UnresolvedAction';

export class SetToUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'set-to';

    item: Target | undefined;

    setting: string | undefined;

    constructor({
        item,
        setting,
    }: {
        item: Target | undefined;
        setting: string | undefined;
    }) {
        super();
        this.item = item;
        this.setting = setting;
    }

    description(game: Game) {
        if (this.item === undefined) {
            return 'set something';
        }
        if (this.setting === undefined) {
            return 'set something';
        }
        return `set ${targetDescription(game, this.item)} to ${this.setting}`;
    }
}

export const setToUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SetToUnresolved)) return;

    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) =>
            new SetToUnresolved({ item, setting: action.setting }),
        missing: () => 'Set what?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to set, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) return Action.complete();

    return await game.applyAction(
        runner,
        new SetTo({ item, setting: action.setting }),
        actor
    );
};
