import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { NorthSouthCrawlway } from '../NorthSouthCrawlway';
import { Cellar } from '../Cellar';
import { Gallery } from '../Gallery';

export class WestOfChasm extends Room {
    static spec(): EntitySpec<WestOfChasm> {
        return {
            ref: 'west-of-chasm',
            constructor: WestOfChasm,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return WestOfChasm.spec().ref;
    }

    name(): string {
        return 'West of Chasm';
    }

    description(): string {
        return (
            'You are on the west edge of a chasm, the bottom of ' +
            'which cannot be seen. The east side is sheer rock, providing ' +
            'no exits. A narrow passage goes west, and the path you are ' +
            'on continues to the north and south.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: NorthSouthCrawlway.spec().ref,
            }),
            new Passage({ via: Direction.West, to: Cellar.spec().ref }),
            new Passage({
                via: Direction.Down,
                message:
                    'The chasm probably leads straight to the infernal regions.',
            }),
            new Passage({ via: Direction.South, to: Gallery.spec().ref }),
        ];
    }
}
