import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    DeflateUnresolved,
    deflateUnresolvedHandler,
} from './DeflateUnresolved';

export class Deflate extends Action {
    id = '~deflate';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [deflateHandler, deflateUnresolvedHandler],
            parser,
            verbs: [new Verb('deflate')],
            prepositions: [],
        };
    }
}

export const deflateHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Deflate)) return;
    await runner.doOutput('Come on, now!');
    return Action.complete();
};

const parser = (game: Game): Parser<Value, DeflateUnresolved> => {
    const deflate = Parse.words(Deflate.ability().verbs);
    const deflateObject = deflate.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // deflate
        deflate.into(new DeflateUnresolved({ item: undefined })),
        // deflate button
        deflateObject.map((item) => new DeflateUnresolved({ item }))
    );
};
