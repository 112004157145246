import { Parse, Verb } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Stay extends makeDescribable(Action) {
    id = 'stay';

    description(game: Game): string {
        return 'stay';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('stay'),
            new Verb('stay put'),
            new Verb('stay here'),
            new Verb('stay there'),
            new Verb("don't move"),
        ];
        return {
            handlers: [stayHandler],
            parser: () => Parse.words(verbs).into(new Stay()),
            verbs,
            prepositions: [],
        };
    }
}

export const stayHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Stay)) return;
    await runner.doOutput('You will be lost without me.');
    return Action.complete();
};
