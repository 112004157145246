import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { NorthOfHouse } from '../NorthOfHouse';
import { Forest1 } from '../Forest1';
import { SouthOfHouse } from '../SouthOfHouse';
import { Player } from '../../actors';
import { FrontDoor, House, Mailbox, WelcomeMat } from '../../items';
import { EntitySpec } from '../../game';

export class WestOfHouse extends Room {
    static spec(): EntitySpec<WestOfHouse> {
        return {
            ref: 'west-of-house',
            constructor: WestOfHouse,
            initial: {
                contents: [
                    Player.spec().ref,
                    FrontDoor.spec().ref,
                    Mailbox.spec().ref,
                    WelcomeMat.spec().ref,
                    House.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return WestOfHouse.spec().ref;
    }

    name(): string {
        return 'West of House';
    }

    description(): string {
        return 'This is an open field west of a white house, with a boarded front door.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: Forest1.spec().ref }),
            new Passage({ via: Direction.North, to: NorthOfHouse.spec().ref }),
            new Passage({ via: Direction.South, to: SouthOfHouse.spec().ref }),
            new Passage({
                via: Direction.East,
                message: 'The door is locked, and there is evidently no key.',
            }),
        ];
    }

    hasWalls() {
        return false;
    }

    hasEastWall(): boolean {
        return true;
    }
}
