import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { SmallRoom } from '../SmallRoom';
import { Hallway2 } from '../Hallway2';
import {
    getNarrowRoomDescription,
    hallwayGlobalObjects,
    narrowRoomEnterPassage,
} from '../InsideMirror/mirror_utils';

export class EastNarrowRoom1 extends Room {
    static spec(): EntitySpec<EastNarrowRoom1> {
        return {
            ref: 'east-narrow-room-1',
            constructor: EastNarrowRoom1,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return EastNarrowRoom1.spec().ref;
    }

    name(): string {
        return 'Narrow Room';
    }

    description(): string {
        let description = getNarrowRoomDescription(
            this.game,
            this,
            Direction.East
        );
        description +=
            '\nTo the north is a large hallway, and to the south, a small room.';
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    globalObjects(): Entity[] {
        return [...super.globalObjects(), ...hallwayGlobalObjects(this)];
    }

    passages(): Passage[] {
        return [
            narrowRoomEnterPassage(this.game, Direction.West),
            new Passage({
                via: Direction.South,
                to: SmallRoom.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                to: Hallway2.spec().ref,
            }),
        ];
    }
}
