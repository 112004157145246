import { Passage, Room, RoomInAPuzzle63 } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { SmallSquareRoom } from '../SmallSquareRoom';
import { SteelDoor } from '../../items';

export class SideRoom extends Room {
    static spec(): EntitySpec<SideRoom> {
        return {
            ref: 'side-room',
            constructor: SideRoom,
            initial: {
                contents: [SteelDoor.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return SideRoom.spec().ref;
    }

    name(): string {
        return 'Side Room';
    }

    description(): string {
        const thing = this.game.ent(SteelDoor).isOpen()
            ? 'passage'
            : 'metal door';
        return `You are in a room with an exit to the north and a ${thing} to the east.`;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: SmallSquareRoom.spec().ref,
            }),
            new Passage({
                via: Direction.East,
                to: RoomInAPuzzle63.spec().ref,
                condition: () =>
                    this.game.ent(SteelDoor).isOpen() &&
                    !this.game.ent(RoomInAPuzzle63).containsSandstone(),
                message: this.game.ent(SteelDoor).isOpen()
                    ? 'The way is blocked by sandstone.'
                    : 'The steel door bars the way.',
            }),
        ];
    }
}
