import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Entity, makeOpenable } from '../../game/Entity';
import { Action, EntitySpec, Handler, Reference } from '../../game';
import { Close, Lock, LookUnder, Open, Unlock } from '../../abilities';
import { WelcomeMat } from '../WelcomeMat';
import { PutUnder } from '../../abilities/PutUnder';
import { Player } from '../../actors';
import { TinyRoom, DrearyRoom } from '../../rooms';
import { Newspaper } from '../Newspaper';
import { RustyKey } from '../RustyKey';
import { OakDoorKeyhole1 } from '../OakDoorKeyhole1';
import { OakDoorKeyhole2 } from '../OakDoorKeyhole2';
import { SkeletonKeys } from '../SkeletonKeys';
import { StoneDoor } from '../StoneDoor';
import { DUMMY } from '../../constants';
import { BlueLabel } from '../BlueLabel';
import { WarningCard } from '../WarningCard';
import { WarningNote } from '../WarningNote';
import { GuideBook } from '../GuideBook';
import { BoatInstructions } from '../BoatInstructions';

interface OakDoorState extends ItemState {
    itemUnderneath: Reference | undefined;
    isLocked: boolean;
}

abstract class Base extends Item<OakDoorState> {}

export class OakDoor extends makeOpenable(Base) {
    static spec(): EntitySpec<OakDoor> {
        return {
            ref: 'oak-door',
            constructor: OakDoor,
            initial: {
                isOpen: false,
                itemUnderneath: undefined,
                isLocked: true,
            },
            nouns: [new Noun('door'), new Noun('doors', { plural: true })],
            adjectives: [
                new Adjective('oak'),
                new Adjective('oaken'),
                new Adjective('wood'),
                new Adjective('wooden'),
                new Adjective('massive'),
                new Adjective('huge'),
                new Adjective('large'),
                new Adjective('big'),
            ],
            handlers: [
                lookUnderOakDoor,
                putUnderOakDoor,
                unlockOakDoor,
                lockOakDoor,
                openCloseOakDoor,
            ],
        };
    }

    ref() {
        return OakDoor.spec().ref;
    }

    name(): string {
        return 'door made of oak';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    nouns() {
        return OakDoor.spec().nouns;
    }

    adjectives() {
        return OakDoor.spec().adjectives;
    }

    itemUnderneath(): Item | undefined {
        return this.state.itemUnderneath
            ? (this.game.get(this.state.itemUnderneath) as Item)
            : undefined;
    }

    isLocked(): boolean {
        return this.state.isLocked;
    }
}

const lookUnderOakDoor: Handler = async ({ action, runner }) => {
    if (
        action.is(LookUnder) &&
        action.item.is(OakDoor) &&
        action.item.itemUnderneath()?.is(WelcomeMat)
    ) {
        await runner.doOutput('The welcome mat is under the door.');
        return Action.complete();
    }
};

const putUnderOakDoor: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(PutUnder) && action.cover.is(OakDoor) && actor?.is(Player)) {
        // TODO handle case where door is open???
        const room = actor?.location();
        const otherRoom = room.is(TinyRoom)
            ? game.ent(DrearyRoom)
            : game.ent(TinyRoom);
        if (isSmallPaper(action.item)) {
            action.item.moveTo(otherRoom);
            await runner.doOutput(
                'The paper is very small and vanishes under the door.'
            );
            return Action.complete();
        }
        if (action.item.is(WelcomeMat)) {
            if (action.cover.itemUnderneath()?.is(WelcomeMat)) {
                await runner.doOutput("It's already under the door.");
            } else {
                action.item.moveTo(otherRoom);
                action.cover.state.itemUnderneath = action.item.ref();
                await runner.doOutput('The mat fits easily under the door.');
            }
            return Action.complete();
        }
    }
};

function isSmallPaper(item: Entity): boolean {
    return (
        item.is(Newspaper) ||
        item.is(BlueLabel) ||
        item.is(WarningCard) ||
        item.is(WarningNote) ||
        item.is(GuideBook) ||
        item.is(BoatInstructions)
    );
}

const openCloseOakDoor: Handler = async ({ action, runner, game }) => {
    if (action.is(Open) && action.item.is(OakDoor)) {
        const door = action.item;
        if (door.isLocked()) {
            await runner.doOutput('The door is locked.');
        } else if (door.isOpen()) {
            await runner.doOutput(game.choiceOf(DUMMY));
        } else {
            door.state.isOpen = true;
            await runner.doOutput('The door is now open.');
        }
        return Action.complete();
    }

    if (action.is(Close) && action.item.is(StoneDoor)) {
        const door = action.item;
        if (door.isOpen()) {
            await runner.doOutput('The door is now closed.');
            door.state.isOpen = false;
        } else {
            await runner.doOutput(game.choiceOf(DUMMY));
        }
        return Action.complete();
    }
};

const unlockOakDoor: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Unlock) && action.item.is(OakDoor) && actor?.is(Player)) {
        const { item: door, tool: key } = action;
        if (!door.isLocked()) {
            await runner.doOutput('The door is already unlocked.');
        } else if (key.is(RustyKey)) {
            const keyhole1 = game.ent(OakDoorKeyhole1);
            const keyhole2 = game.ent(OakDoorKeyhole2);
            if (
                (keyhole1.isEmpty() && keyhole2.isEmpty()) ||
                (keyhole1.contains(key) && keyhole2.isEmpty()) ||
                (keyhole2.contains(key) && keyhole1.isEmpty())
            ) {
                door.state.isLocked = false;
                await runner.doOutput('The door is now unlocked.');
            } else {
                await runner.doOutput('The door is blocked.');
            }
        } else if (key.is(SkeletonKeys)) {
            await runner.doOutput('These are apparently the wrong keys.');
        } else {
            await runner.doOutput("It can't be unlocked with that.");
        }
        return Action.complete();
    }
};

const lockOakDoor: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Lock) && action.item.is(OakDoor) && actor?.is(Player)) {
        const { item: door, tool: key } = action;
        if (door.isOpen()) {
            await runner.doOutput('You should close it first.');
        } else if (key.is(RustyKey)) {
            const keyhole1 = game.ent(OakDoorKeyhole1);
            const keyhole2 = game.ent(OakDoorKeyhole2);
            if (
                (keyhole1.isEmpty() && keyhole2.isEmpty()) ||
                (keyhole1.contains(key) && keyhole2.isEmpty()) ||
                (keyhole2.contains(key) && keyhole1.isEmpty())
            ) {
                door.state.isLocked = false;
                await runner.doOutput('The door is now locked.');
            } else {
                await runner.doOutput('The door is blocked.');
            }
        } else if (key.is(SkeletonKeys)) {
            await runner.doOutput('These are apparently the wrong keys.');
        } else {
            await runner.doOutput("It can't be locked with that.");
        }
        return Action.complete();
    }
};
