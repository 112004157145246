import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Header, useTerminal } from './components';
import { LoadError, Runner } from '../zork/game/Runner';
import { Game } from '../zork/game/game';
import { State } from '../zork/game';

const SLATE = '#3b475c';

const GameBox = styled.div`
    border-radius: 6px;
    background-color: ${SLATE};
    margin-top: 28px;
    height: calc(100% - 28px);
    width: calc(100% - 20px);
`;

export function App() {
    const game = useRef<Game>() as MutableRefObject<Game>;
    const [score, setScore] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const [moveCount, setMoveCount] = useState(0);
    const [roomName, setRoomName] = useState('West of House');

    const updateMetrics = () => {
        setScore(game.current.score());
        setTotalScore(game.current.totalScore());
        setMoveCount(game.current.moveCount());
        setRoomName(game.current.here().name());
    };

    if (!game.current) {
        game.current = new Game();
        updateMetrics();
    }

    const onInput = async (line: string) => {
        await game.current.applyCommand(runner.current, line);
        await runner.current.doDebug('state', game.current.state);
        updateMetrics();
    };

    const { terminal, onOutput } = useTerminal({ onInput });

    const runner = useRef<Runner>({
        doOutput: async (
            message: string,
            options = { newLine: true, isDimmed: false }
        ) => {
            onOutput(message, options);
            return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        doDebug: async (...args: any) => {
            // eslint-disable-next-line no-console
            console.log(...args);
            return true;
        },
        saveState: async (state: State, name: string | undefined) => {
            localStorage.setItem(saveKey(name), JSON.stringify(state));
            return true;
        },
        loadState: async (name: string | undefined) => {
            const loaded = localStorage.getItem(saveKey(name));
            if (loaded === null) {
                return LoadError.NotFound;
            }
            try {
                return JSON.parse(loaded);
            } catch (error) {
                return LoadError.Invalid;
            }
        },
        restart: async () => {
            onOutput('', {});
            game.current = new Game();
            await game.current.start(runner.current);
        },
        quit: async () => {
            onOutput('', {});
            game.current = new Game();
            await game.current.start(runner.current);
        },
        userName: () => {
            const stored = localStorage.getItem('USER_NAME');
            if (stored) {
                return stored;
            }
            const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
            const newValue = [1, 2, 3, 4, 5]
                .map(() => ALPHABET[Math.floor(Math.random() * 26)])
                .join('');
            localStorage.setItem('USER_NAME', newValue);
            return newValue;
        },
    });

    useEffect(() => {
        game.current.start(runner.current);
    }, [game, runner]);

    return (
        <Page>
            <Header
                roomName={roomName}
                score={score}
                totalScore={totalScore}
                moveCount={moveCount}
            />
            <GameBox>{terminal}</GameBox>
        </Page>
    );
}

function saveKey(name: string | undefined) {
    if (name === undefined) {
        return `SAVE_STATE`;
    }
    return `SAVE_STATE/${name}`;
}

const Page = styled.div`
    overflow-x: hidden;
    max-width: 100vw;
    min-width: 100vw;
`;
