import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class RedPanel extends Item {
    static spec(): EntitySpec<RedPanel> {
        return {
            ref: 'red-panel',
            constructor: RedPanel,
            initial: {},
            nouns: [new Noun('panel'), new Noun('panels', { plural: true })],
            adjectives: [new Adjective('red')],
        };
    }

    ref() {
        return RedPanel.spec().ref;
    }

    name(): string {
        return 'red panel';
    }

    description(): string {
        return '';
    }

    nouns() {
        return RedPanel.spec().nouns;
    }

    adjectives() {
        return RedPanel.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
