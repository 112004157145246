import { Passage, Room } from '..';
import { EntitySpec } from '../../game';

export class VolcanoNearViewingLedge extends Room {
    static spec(): EntitySpec<VolcanoNearViewingLedge> {
        return {
            ref: 'volcano-near-viewing-ledge',
            constructor: VolcanoNearViewingLedge,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return VolcanoNearViewingLedge.spec().ref;
    }

    name(): string {
        return 'Volcano Near Viewing Ledge';
    }

    description(): string {
        return (
            'You are high above the floor of the volcano. ' +
            'From here the rim of the volcano looks very narrow and ' +
            'you are very near it. To the east is what appears to ' +
            'be a viewing ledge, too thin to land on.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [];
    }

    isOnLand(): boolean {
        return false;
    }

    isSacred() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
