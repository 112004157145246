import { Actor, ActorState, Player } from '../index';
import { Adjective, Noun } from '../../../parse';
import {
    Give,
    Kill,
    Mung,
    Poke,
    SpecialTimerTick,
    Throw,
} from '../../abilities';
import { Action, Entity, Handler } from '../../game';
import { BankEntrance, SmallBankRoom } from '../../rooms';
import { Brick, Fuse } from '../../items';

interface ZurichGnomeState extends ActorState {
    isArrivingSoon: boolean;
    timeUntilArrival: number;
    isDepartingSoon: boolean;
    timeUntilDeparture: number;
}

export class ZurichGnome extends Actor<ZurichGnomeState> {
    static spec() {
        return {
            ref: 'zurich-gnome',
            constructor: ZurichGnome,
            initial: {
                inventory: [],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: 0,
                isArrivingSoon: false,
                timeUntilArrival: 5,
                isDepartingSoon: false,
                timeUntilDeparture: 12,
                isEngrossed: false,
            },
            nouns: [
                new Noun('gnome'),
                new Noun('gnomes', { plural: true }),
                new Noun('gnome of zurich'),
                new Noun('gnomes of zurich', { plural: true }),
            ],
            adjectives: [
                new Adjective('zurich'),
                new Adjective('epicene'),
                new Adjective('bespectacled'),
            ],
            handlers: [tickGnome, attackGnome, giveToGnome],
        };
    }

    ref() {
        return ZurichGnome.spec().ref;
    }

    name(): string {
        return 'Gnome of Zurich';
    }

    description(): string {
        return 'An epicene gnome of Zurich wearing a three-piece suit and carrying a safety-deposit box is standing here, looking impatient.';
    }

    isVillain(): boolean {
        return false;
    }

    isVictim(): boolean {
        return true;
    }

    nouns(): Noun[] {
        return ZurichGnome.spec().nouns;
    }

    adjectives(): Adjective[] {
        return ZurichGnome.spec().adjectives;
    }

    isReadyToFight(): boolean {
        return false;
    }
}
const tickGnome: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const gnome = game.ent(ZurichGnome);
        if (gnome.state.isArrivingSoon) {
            gnome.state.timeUntilArrival -= 1;
            if (gnome.state.timeUntilArrival <= 0) {
                const room = game.ent(Player).location();
                if (room.is(SmallBankRoom)) {
                    gnome.moveTo(room);
                    await runner.doOutput(GNOME_APPEARS);
                    gnome.state.isDepartingSoon = true;
                    gnome.state.timeUntilDeparture = 12;
                }
                gnome.state.isArrivingSoon = false;
            }
        } else if (gnome.state.isDepartingSoon) {
            gnome.state.timeUntilDeparture -= 1;
            const room = game.ent(Player).location();
            if (gnome.state.timeUntilDeparture <= 0) {
                const smallBankRoom = game.ent(SmallBankRoom);
                if (smallBankRoom.contains(gnome)) {
                    if (room.isEqualTo(smallBankRoom)) {
                        await runner.doOutput(
                            "The gnome looks impatient: 'I may have another customer waiting; " +
                                "you'll just have to fend for yourself, I'm afraid. " +
                                'He disappears, leaving you alone in the bare room.'
                        );
                    }
                    gnome.moveTo(undefined);
                }
                gnome.state.isDepartingSoon = false;
            } else if (room.is(SmallBankRoom)) {
                await runner.doOutput(
                    'The gnome appears increasingly impatient.'
                );
            }
        }
    }
    return Action.incomplete();
};

const GNOME_APPEARS =
    'An epicene gnome of Zurich wearing a three-piece ' +
    'suit and carrying a safety-deposit box materializes in the room. ' +
    "'You seem to have forgotten to deposit your valuables,' he says, " +
    "tapping the lid of the box impatiently. 'We don't usually allow " +
    'customers to use the boxes here, but we can make this ONE exception, ' +
    "I suppose...' He looks askance at you over his wire-rimmed bifocals.";

const attackGnome: Handler = async ({ action, runner, game }) => {
    if (
        (((action.is(Kill) && action.weapon) || action.is(Poke)) &&
            action.enemy.is(ZurichGnome)) ||
        (action.is(Mung) && action.item.is(ZurichGnome))
    ) {
        const gnome = game.ent(ZurichGnome);
        gnome.moveTo(undefined);
        gnome.state.isDepartingSoon = false;
        await runner.doOutput(
            "The gnome says 'Well, I never...' and disappears with a snap of his fingers, leaving you alone."
        );
        return Action.complete();
    }
};

const giveToGnome: Handler = async ({ action, runner, actor, game }) => {
    if (
        ((action.is(Give) && action.recipient.is(ZurichGnome)) ||
            (action.is(Throw) && action.enemy?.is(ZurichGnome))) &&
        actor?.is(Player) &&
        actor?.hasItem(action.item)
    ) {
        const { item: gift } = action;
        const gnome = game.ent(ZurichGnome);
        if (
            gift.isItem() &&
            gift.isTreasure() &&
            gift.scoreInCase() > 0 &&
            gift.scoreOnTake() > 0
        ) {
            gift.moveTo(gnome);
            actor.moveTo(game.ent(BankEntrance));
            gnome.moveTo(undefined);
            await runner.doOutput(
                `The gnome carefully places ${gift.the()} in the deposit box. ` +
                    "'Let me show you the way out,' he says, making it clear " +
                    'he will be pleased to see the last of you. Then, you are ' +
                    'momentarily disoriented, and when you recover you ' +
                    'are back at the Bank Entrance.'
            );
            gnome.state.isDepartingSoon = false;
        } else if (isItABomb(gift)) {
            gift.moveTo(actor.location());
            gnome.moveTo(undefined);
            await runner.doOutput(
                "'Surely you jest,' he says. He tosses the brick over " +
                    "his shoulder, and disappears with an understated 'pop'."
            );
            gnome.state.isDepartingSoon = false;
        } else {
            gift.moveTo(undefined);
            await runner.doOutput(
                "'I wouldn't put THAT in a safety deposit box,' remarks the " +
                    'gnome with disdain, tossing it over his shoulder, where ' +
                    "it disappears with an understated 'pop'."
            );
        }

        return Action.complete();
    }
};

function isItABomb(item: Entity): boolean {
    const fuse = item.ent(Fuse);
    return item.is(Brick) && item.contains(fuse) && fuse.isAflame();
}
