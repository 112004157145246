import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { UnresolvedAction } from '../UnresolvedAction';

export class Pray extends makeDescribable(UnresolvedAction) {
    id = 'pray';

    description(game: Game): string {
        return 'pray';
    }

    static ability(): Ability {
        const verbs = [
            new Verb('say prayer'),
            new Verb('say a prayer'),
            new Verb('pray'),
        ];
        return {
            handlers: [prayHandler],
            parser: () => Parse.words(verbs).into(new Pray()),
            verbs,
            prepositions: [],
        };
    }
}

export const prayHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Pray)) return;
    await runner.doOutput('If you pray enough, your prayers may be answered.');
    return Action.complete();
};
