import { Token } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that combines two other parsers in sequence.
 *
 * ```
 * word("cat").then(whitespace()).match(tokenize("cat "))
 * ```
 */
export class ThenParser<I, O1, O2> extends Parser<I, [O1, O2]> {
    parser1: Parser<I, O1>;

    parser2: Parser<I, O2>;

    constructor(parser1: Parser<I, O1>, parser2: Parser<I, O2>) {
        super();
        this.parser1 = parser1;
        this.parser2 = parser2;
    }

    *match(tokens: Token<I>[]): Generator<Match<I, [O1, O2]>> {
        for (const match1 of this.parser1.match(tokens)) {
            for (const match2 of this.parser2.match(match1.rest)) {
                yield new Match(
                    new Token(
                        [match1.token.value, match2.token.value],
                        [match1.token, match2.token]
                    ),
                    match2.rest
                );
            }
        }
    }
}
