import { EndOfFile, Token, Value, Word } from '../lexicon';
import { Match } from './Match';
import { Parser } from './parser';

/**
 * Parser that parses a single word with the given value. Will only parse
 * a single `Word` node, not multiple word nodes delimited by `Whitespace` nodes.
 * If no value is provided, matches any single word. This is case insensitive.
 *
 * ```
 * word("cat").match(tokenize("cat"))           // successfully matches
 * word("cat").match(tokenize("dog"))           // no match
 * word("mail box").match(tokenize("mail box")) // no match
 * ```
 */
export class WordParser extends Parser<Value, Word> {
    value: string | undefined;

    constructor(value?: string) {
        super();
        this.value = value;
    }

    *match(tokens: Token<Value>[]): Generator<Match<Value, Word>> {
        const token = tokens[0] || new Token(new EndOfFile(), []);
        if (token.value instanceof Word) {
            if (
                this.value === undefined ||
                token.value.value.toLowerCase() === this.value.toLowerCase()
            ) {
                yield new Match(token.clone(), tokens.slice(1));
            }
        }
    }
}
