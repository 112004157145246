import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class FlatheadStamp extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<FlatheadStamp> {
        return {
            ref: 'flathead-stamp',
            constructor: FlatheadStamp,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('stamp'),
                new Noun('stamps', { plural: true }),
                new Noun('postage stamp'),
                new Noun('postage stamps'),
                new Noun('postage', { collective: true }),
            ],
            adjectives: [new Adjective('flathead')],
        };
    }

    ref() {
        return FlatheadStamp.spec().ref;
    }

    name() {
        return 'Flathead stamp';
    }

    description() {
        return 'There is a Flathead stamp here.';
    }

    scoreOnTake(): number {
        return 4;
    }

    scoreInCase(): number {
        return 10;
    }

    size() {
        return 1;
    }

    nouns(): Noun[] {
        return FlatheadStamp.spec().nouns;
    }

    adjectives(): Adjective[] {
        return FlatheadStamp.spec().adjectives;
    }

    examineText() {
        return FLATHEAD_STAMP_IMAGE;
    }
}

const FLATHEAD_STAMP_IMAGE = ` --v----v----v----v----v----v----v----v---
|                                         |
|    ||||||||||           LORD            |
>   ||||  !|||||           DIMWIT         <
|   ||||    ---|          FLATHEAD        |
|   |||C     CC \\                         |
>   ||||       _ \\                        <
|     ||| (____|                          |
|      ||      |                          |
>       |______|             Our          <
|         /   \\           Excessive       |
|        /     \\            Leader        |
>       |       |                         <
|       |       |                         |
|                                         |
>     G.U.E. POSTAGE    3 Zorkmids        <
|                                         |
 --^----^----^----^----^----^----^----^---`;
