import { Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class GlobalTree extends Item {
    static spec(): EntitySpec<GlobalTree> {
        return {
            ref: 'global-tree',
            constructor: GlobalTree,
            initial: {},
            nouns: [
                new Noun('tree'),
                new Noun('trees', { plural: true }),
                new Noun('branch'),
                new Noun('branches', { collective: true }),
            ],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return GlobalTree.spec().ref;
    }

    name(): string {
        return 'tree';
    }

    description(): string {
        return '';
    }

    nouns() {
        return GlobalTree.spec().nouns;
    }

    adjectives() {
        return GlobalTree.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    canBeClimbed() {
        return true;
    }
}
