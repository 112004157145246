import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Action, Entity, EntitySpec, Handler } from '../../game';
import { TorchRoom } from '../TorchRoom';
import { DrearyRoom } from '../DrearyRoom';
import {
    BarredWindow,
    OakDoor,
    OakDoorKeyhole1,
    OakDoorKeyhole2,
    OakDoorLid1,
    OakDoorLid2,
} from '../../items';
import { SpecialDescribeRoom } from '../../abilities/SpecialDescribeRoom';

export class TinyRoom extends Room {
    static spec(): EntitySpec<TinyRoom> {
        return {
            ref: 'tiny-room',
            constructor: TinyRoom,
            initial: {
                contents: [
                    OakDoor.spec().ref,
                    OakDoorKeyhole1.spec().ref,
                    BarredWindow.spec().ref,
                    OakDoorLid1.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [describeRooms],
        };
    }

    ref() {
        return TinyRoom.spec().ref;
    }

    name(): string {
        return 'Tiny Room';
    }

    description(): string {
        return 'This is a tiny room, which has an exit to the east.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.North, SpecialDirection.In],
                to: DrearyRoom.spec().ref,
                condition: () => this.game.ent(OakDoor).isOpen(),
                message: 'The oak door is closed.',
            }),
            new Passage({ via: Direction.East, to: TorchRoom.spec().ref }),
        ];
    }

    globalObjects(): Entity[] {
        const objects = [...super.globalObjects()];
        const itemUnderneathDoor = this.ent(OakDoor).itemUnderneath();
        if (
            itemUnderneathDoor &&
            !itemUnderneathDoor.location()?.isEqualTo(this)
        ) {
            objects.push(itemUnderneathDoor);
        }
        return objects;
    }
}

const describeRooms: Handler = async ({ action, runner, game }) => {
    if (
        action.is(SpecialDescribeRoom) &&
        (action.room.isEqualTo(game.ent(TinyRoom)) ||
            action.room.isEqualTo(game.ent(DrearyRoom)))
    ) {
        const { room } = action;
        const keyhole = room.is(DrearyRoom)
            ? game.ent(OakDoorKeyhole2)
            : game.ent(OakDoorKeyhole1);
        const lid = room.is(DrearyRoom)
            ? game.ent(OakDoorLid2)
            : game.ent(OakDoorLid1);
        const direction = room.is(DrearyRoom) ? 'south' : 'north';
        const covered = lid.isOpen() ? '' : 'covered by a thin metal lid ';
        await runner.doOutput(action.room.description());
        await runner.doOutput(
            `On the ${direction} side of the room is a massive wooden door, ` +
                `near the top of which, in the center, is a window barred with iron. ` +
                `A formidable bolt lock is set within the door frame. ` +
                `A keyhole ${covered}lies within the lock.`
        );
        if (!keyhole.isEmpty()) {
            await runner.doOutput(
                `${keyhole.contents()[0].An()} is in place within the keyhole.`
            );
        }
        return Action.complete({ withConsequence: false });
    }
};
