import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { CoalMine7 } from '../CoalMine7';
import { LadderBottom } from '../LadderBottom';
import { MineLadder } from '../../items';

export class LadderTop extends Room {
    static spec(): EntitySpec<LadderTop> {
        return {
            ref: 'ladder-top',
            constructor: LadderTop,
            initial: {
                contents: [MineLadder.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return LadderTop.spec().ref;
    }

    name(): string {
        return 'Ladder Top';
    }

    description(): string {
        return (
            'This is a very small room. In the corner is a rickety ' +
            'wooden ladder, leading downward. It might be safe to descend. ' +
            'There is also a staircase leading upward.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Down,
                to: LadderBottom.spec().ref,
            }),
            new Passage({
                via: Direction.Up,
                to: CoalMine7.spec().ref,
            }),
        ];
    }
}
