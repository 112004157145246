import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import {
    Entity,
    makeContainer,
    makeOpenable,
    makeTakeable,
    makeTreasure,
} from '../../game/Entity';
import { Action, EntitySpec, Handler, Reference } from '../../game';
import { Mung, Open, Poke } from '../../abilities';
import { Hands } from '../Hands';
import { Canary } from '../Canary';

interface EggState extends ItemState {
    isBroken: boolean;
    lastAttemptedOpener: Reference | undefined;
}

abstract class Base extends Item<EggState> {}

export class Egg extends makeTreasure(
    makeTakeable(makeOpenable(makeContainer(Base)))
) {
    static spec(): EntitySpec<Egg> {
        return {
            ref: 'egg',
            constructor: Egg,
            initial: {
                contents: [Canary.spec().ref],
                isOpen: false,
                hasBeenTaken: false,
                isBroken: false,
                lastAttemptedOpener: undefined,
            },
            nouns: [new Noun('egg'), new Noun('eggs', { plural: true })],
            adjectives: [
                new Adjective('encrusted'),
                new Adjective('jewel encrusted'),
                new Adjective('jewel-encrusted'),
                new Adjective('bejeweled'),
                new Adjective('jeweled'),
                new Adjective('fabergé'),
                new Adjective('faberge'),
                new Adjective('imperial'),
                new Adjective("bird's"),
                new Adjective('birds'),
            ],
            handlers: [openEgg, destroyEgg],
        };
    }

    ref() {
        return Egg.spec().ref;
    }

    name(): string {
        return this.isBroken()
            ? 'broken jewel-encrusted egg'
            : 'jewel-encrusted egg';
    }

    isBroken(): boolean {
        return this.state.isBroken;
    }

    scoreOnTake(): number {
        return 5;
    }

    scoreInCase(): number {
        return this.isBroken() ? 2 : 5;
    }

    description(): string {
        return this.isBroken()
            ? 'There is a somewhat ruined egg here.'
            : 'There is a jewel-encrusted egg here.';
    }

    initialDescription(): string {
        return (
            "In the bird's nest is a large egg encrusted with precious " +
            'jewels, apparently scavenged somewhere by a childless songbird. ' +
            'The egg is covered with fine gold inlay, and ornamented in lapis ' +
            'lazuli and mother-of-pearl. Unlike most eggs, this one is hinged ' +
            'and has a delicate looking clasp holding it closed. ' +
            'The egg appears extremely fragile.'
        );
    }

    totalCapacity(): number {
        return 6;
    }

    nouns() {
        return Egg.spec().nouns;
    }

    adjectives() {
        const adjectives = [...Egg.spec().adjectives];
        if (this.isBroken()) {
            adjectives.push(
                new Adjective('broken'),
                new Adjective('somewhat ruined'),
                new Adjective('somewhat-ruined'),
                new Adjective('ruined')
            );
        }
        return adjectives;
    }

    lastAttemptedOpener(): Entity | undefined {
        const ref = this.state.lastAttemptedOpener;
        return ref ? this.game.get(ref) : undefined;
    }
}

const openEgg: Handler = async ({ action, runner, game }) => {
    if (action.is(Open) && action.item.is(Egg)) {
        const { tool, item: egg } = action;

        if (egg.isOpen()) {
            await runner.doOutput('The egg is already open.');
        } else if (tool === undefined) {
            await runner.doOutput('There is no obvious way to open the egg.');
        } else if (tool.is(Hands)) {
            await runner.doOutput(
                'I doubt you could do that without damaging it.'
            );
        } else if (tool.isItem() && tool.isWeapon() && tool.isTool()) {
            egg.state.isBroken = true;
            egg.state.isOpen = true;
            const canary = game.ent(Canary);
            canary.setIsBroken(true);
            await runner.doOutput(
                'The egg is now open, but the clumsiness of your attempt ' +
                    'has seriously compromised its aesthetic appeal.'
            );
            await runner.doOutput(canary.initialDescription());
        } else {
            const previousTool = egg.lastAttemptedOpener();
            if (!previousTool) {
                egg.state.lastAttemptedOpener = tool.ref();
                await runner.doOutput(
                    `The concept of using ${tool.an()} is certainly original.`
                );
            } else if (tool.isEqualTo(previousTool)) {
                await runner.doOutput(
                    'Insanity is doing the same thing over and over and expecting different results.'
                );
            } else {
                egg.state.lastAttemptedOpener = tool.ref();
                await runner.doOutput(
                    `Not to say that using ${tool.the()} isn't original too...`
                );
            }
        }
        return Action.complete();
    }
};

const destroyEgg: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(Mung) && action.item.is(Egg)) ||
        (action.is(Poke) && action.enemy.is(Egg))
    ) {
        const egg = game.ent(Egg);
        if (!egg.isBroken()) {
            egg.state.isBroken = true;
            egg.state.isOpen = true;
            const canary = game.ent(Canary);
            canary.setIsBroken(true);
            await runner.doOutput(
                'Your rather indelicate handling of the egg has caused it some damage. The egg is now open.'
            );
            await runner.doOutput(canary.initialDescription());
            return Action.complete();
        }
    }
};
