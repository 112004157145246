import { Ability, Action, Handler } from '../../game';
import { SpecialAction } from '../SpecialAction';
import { Parse, Target } from '../../../parse';

export class SpecialPartial extends SpecialAction {
    id = '*partial';

    target: Target;

    constructor({ target }: { target: Target }) {
        super();
        this.target = target;
    }

    static ability(): Ability {
        return {
            handlers: [specialPartialHandler],
            parser: (game) =>
                Parse.target(game.lexicon).map(
                    (target) => new SpecialPartial({ target })
                ),
            verbs: [],
            prepositions: [],
        };
    }
}

// TODO parse "with the X"
export const specialPartialHandler: Handler = async ({
    game,
    action,
    actor,
    runner,
}) => {
    if (!action.is(SpecialPartial)) return;

    if (game.partial) {
        const getAction = game.partial;
        game.partial = undefined;
        return game.applyAction(runner, getAction(action.target), actor);
    }
    const result = await game.resolve(runner, action.target, actor, {
        allowGroups: true,
        partial: (target) => new SpecialPartial({ target }),
        ambiguous: (description, options) =>
            `Which ${description}, ${options}?`,
    });
    if (result.items.length === 0)
        return Action.complete({ withConsequence: false });
    if (result.items) {
        if (result.items.length === 1) {
            const item = result.items[0];
            await runner.doOutput(
                `What do you want to do with the ${result.items[0]}?`
            );
            game.state.it = item.ref();
        } else {
            await runner.doOutput('What do you want to do with them?');
            game.state.them = result.items.map((item) => item.ref());
        }
    }

    return Action.complete({ withConsequence: false });
};
