import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { EntitySpec } from '../../game';

interface PotOfGoldState extends ItemState {
    isHidden: boolean;
}

abstract class Base extends Item<PotOfGoldState> {}

export class PotOfGold extends makeTreasure(makeTakeable(Base)) {
    static spec(): EntitySpec<PotOfGold> {
        return {
            ref: 'pot-of-gold',
            constructor: PotOfGold,
            initial: {
                isHidden: true,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('pot'),
                new Noun('pots', { plural: true }),
                new Noun('gold', { collective: true }),
                new Noun('pot of gold'),
                new Noun('pots of gold', { plural: true }),
            ],
            adjectives: [new Adjective('leprechaun'), new Adjective('irish')],
        };
    }

    isHidden() {
        return this.state.isHidden;
    }

    setIsHidden(isHidden: boolean) {
        this.state.isHidden = isHidden;
    }

    ref() {
        return PotOfGold.spec().ref;
    }

    name() {
        return 'pot of gold';
    }

    description() {
        return 'There is a pot of gold here.';
    }

    size() {
        return 15;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 10;
    }

    initialDescription() {
        return 'At the end of the rainbow is a pot of gold.';
    }

    nouns(): Noun[] {
        return PotOfGold.spec().nouns;
    }

    adjectives(): Adjective[] {
        return PotOfGold.spec().adjectives;
    }
}
