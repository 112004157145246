import { Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { makeContainer, makeOpenable, makeVehicle } from '../../game/Entity';
import { Board, Disembark, Open, Raise } from '../../abilities';
import { Player } from '../../actors';
import { DingyCloset } from '../../rooms/DingyCloset';

export class SteelCage extends makeVehicle(makeOpenable(makeContainer(Item))) {
    static spec(): EntitySpec<SteelCage> {
        return {
            ref: 'steel-cage',
            constructor: SteelCage,
            initial: {
                contents: [],
                isOpen: false,
            },
            nouns: [new Noun('cage'), new Noun('cages', { plural: true })],
            adjectives: [new Noun('steel')],
            handlers: [disembarkCage, liftCage, openCage, boardCage],
        };
    }

    totalCapacity(): undefined {
        return undefined;
    }

    isTransparent(): boolean {
        return true;
    }

    ref() {
        return SteelCage.spec().ref;
    }

    name(): string {
        return 'steel cage';
    }

    description(): string {
        return 'There is a steel cage in the middle of the room.';
    }

    nouns() {
        return SteelCage.spec().nouns;
    }

    adjectives() {
        return SteelCage.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const disembarkCage: Handler = async ({ action, runner, actor }) => {
    if (
        actor &&
        action.is(Disembark) &&
        action.vehicle.is(SteelCage) &&
        action.vehicle.contains(actor)
    ) {
        await runner.doOutput("There doesn't seem to be any way out.");
        return Action.complete();
    }
};

const boardCage: Handler = async ({ action, runner, actor }) => {
    if (
        actor &&
        action.is(Board) &&
        action.vehicle.is(SteelCage) &&
        !action.vehicle.contains(actor)
    ) {
        await runner.doOutput("There doesn't seem to be any way in.");
        return Action.complete();
    }
};

const liftCage: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Raise) && action.item.is(SteelCage)) {
        if (actor?.is(Player)) {
            await runner.doOutput('The cage is too heavy to lift.');
        } else {
            await runner.doOutput(
                'The cage shakes and is hurled across the room.'
            );
            const cage = action.item;
            const closet = game.ent(DingyCloset);
            for (const item of cage.contents()) {
                item.moveTo(closet);
            }
        }
        return Action.complete();
    }
};

const openCage: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(SteelCage)) {
        await runner.doOutput("There doesn't seem to be any way to open it.");
        return Action.complete();
    }
};
