import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Yes extends makeDescribable(Action) {
    id = 'yes';

    description(game: Game): string {
        return 'yes';
    }

    static ability(): Ability {
        const verbs = [new Verb('yes'), new Verb('yep'), new Verb('yeah')];
        return {
            handlers: [yesHandler],
            parser: () => Parse.words(verbs).into(new Yes()),
            verbs,
            prepositions: [],
        };
    }
}

export const yesHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Yes)) return;
    if (game.yesNo) {
        await game.yesNo(true);
    } else {
        await runner.doOutput('Yes.');
    }
    return Action.complete({ withConsequence: false });
};
