import { Action, Handler } from '../../game';
import { SpecialAction } from '../SpecialAction';
import { Room } from '../../rooms';
import { SpecialLook } from '../SpecialLook';
import { Player } from '../../actors';

export class SpecialEnter extends SpecialAction {
    id = '*enter';

    room: Room;

    constructor({ room }: { room: Room }) {
        super();
        this.room = room;
    }
}

export const specialEnterHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(SpecialEnter) || actor === undefined) return;

    const vehicle = actor.vehicle();
    const thingToMove = vehicle || actor;

    thingToMove.moveTo(action.room);

    if (actor.is(Player)) {
        await game.applyAction(runner, new SpecialLook({}));

        if (!action.room.hasBeenVisited() && actor.isAlive()) {
            action.room.state.hasBeenVisited = true;
            game.state.score += action.room.scoreOnEntry();
        }
    }

    return Action.complete({ withConsequence: false });
};
