import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { WaveUnresolved, waveUnresolvedHandler } from './WaveUnresolved';
import { HO_HUM } from '../../constants';

export class Wave extends Action {
    id = '~wave';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [waveHandler, waveUnresolvedHandler],
            parser,
            verbs: [
                new Verb('wave'),
                new Verb('brandish'),
                new Verb('flourish'),
                new Verb('twirl'),
                new Verb('swish'),
            ],
            prepositions: [],
        };
    }
}

export const waveHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Wave)) return;
    const { item } = action;
    await runner.doOutput(`Waving ${item.an()} ${game.choiceOf(HO_HUM)}`);
    return Action.complete();
};

const parser = (game: Game): Parser<Value, WaveUnresolved> => {
    const wave = Parse.words(Wave.ability().verbs);
    const waveObject = wave.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // wave
        wave.into(new WaveUnresolved({ item: undefined })),
        // wave button
        waveObject.map((item) => new WaveUnresolved({ item }))
    );
};
