import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { RubUnresolved, rubUnresolvedHandler } from './RubUnresolved';
import { HO_HUM } from '../../constants';

export class Rub extends Action {
    id = '~rub';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [rubHandler, rubUnresolvedHandler],
            parser,
            verbs: [
                new Verb('rub'),
                new Verb('caress'),
                new Verb('touch'),
                new Verb('feel'),
                new Verb('fondle'),
            ],
            prepositions: [],
        };
    }
}

export const rubHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Rub)) return;
    const { item } = action;
    await runner.doOutput(
        `Fiddling with ${item.an()} ${game.choiceOf(HO_HUM)}`
    );
    return Action.complete();
};

const parser = (game: Game): Parser<Value, RubUnresolved> => {
    const rub = Parse.words(Rub.ability().verbs);
    const rubObject = rub.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // rub
        rub.into(new RubUnresolved({ item: undefined })),
        // rub button
        rubObject.map((item) => new RubUnresolved({ item }))
    );
};
