import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { DigUnresolved, digUnresolvedHandler } from './DigUnresolved';

export class Dig extends Action {
    id = '~dig';

    item: Entity | undefined;

    tool: Entity | undefined;

    constructor({
        item,
        tool,
    }: {
        item: Entity | undefined;
        tool: Entity | undefined;
    }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [digHandler, digUnresolvedHandler],
            parser,
            verbs: [new Verb('dig')],
            prepositions: [new Verb('into'), new Verb('in'), new Verb('in to')],
        };
    }
}

export const digHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Dig)) return;
    const { item, tool } = action;
    if (!item || !tool) {
        await runner.doOutput('The ground is too hard for digging here.');
    } else if (!item.isItem() || item.canBeDug()) {
        await runner.doOutput(`How does one dig into ${item.an()}?`);
    } else if (!tool.isItem() || !tool.isTool()) {
        await runner.doOutput(`Digging with ${tool.an()} is silly.`);
    } else {
        await runner.doOutput(`Digging with ${tool.the()} is slow and tedious`);
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, DigUnresolved> => {
    const dig = Parse.words(Dig.ability().verbs);
    const digObject = dig.chain((_verb) =>
        Parse.target(game.lexicon).after(
            Parse.whitespace().then(
                Parse.option(
                    Parse.words(Dig.ability().prepositions).then(
                        Parse.whitespace()
                    )
                )
            )
        )
    );
    const digWith = dig.beforeX(
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        )
    );
    const digObjectWith = digObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    const digWithIntoObject = digWith.chain((tool) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(Dig.ability().prepositions))
                .after(Parse.whitespace())
        ).map((item) => [item, tool])
    );
    return Parse.any(
        // dig
        dig.into(new DigUnresolved({ item: undefined, tool: undefined })),
        // dig box
        digObject.map((item) => new DigUnresolved({ item, tool: undefined })),
        // dig with shovel
        digWith.map((tool) => new DigUnresolved({ item: undefined, tool })),
        // dig sand with hammer / dig with shovel into sand
        Parse.either(digObjectWith, digWithIntoObject).map(
            ([item, tool]) =>
                new DigUnresolved({
                    item,
                    tool,
                })
        )
    );
};
