import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { WestTellersRoom } from '../WestTellersRoom';
import { EastTellersRoom } from '../EastTellersRoom';
import { ChairmansOffice } from '../ChairmansOffice';
import { Go, GoThrough, SpecialEnter, Throw } from '../../abilities';
import { WestViewingRoom } from '../WestViewingRoom';
import { EastViewingRoom } from '../EastViewingRoom';
import { SmallBankRoom } from '../SmallBankRoom';
import { Vault } from '../Vault';
import {
    Bills,
    Curtain,
    EastWall,
    NorthWall,
    Portrait,
    SouthWall,
    WestWall,
} from '../../items';
import { Actor, Player } from '../../actors';
import { Game } from '../../game/game';

export class SafetyDepository extends Room {
    static spec(): EntitySpec<SafetyDepository> {
        return {
            ref: 'safety-depository',
            constructor: SafetyDepository,
            initial: {
                contents: [Curtain.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [
                goThroughBankWalls,
                setCurtainRoom,
                leaveDepository,
                throwItemsAtBankWalls,
            ],
        };
    }

    isNaturallyLit(): boolean {
        return true;
    }

    ref() {
        return SafetyDepository.spec().ref;
    }

    name(): string {
        return 'Safety Depository';
    }

    description(): string {
        return DEPOSITORY_DESCRIPTION;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: ChairmansOffice.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                message: 'There is a curtain of light there.',
            }),
            new Passage({
                via: Direction.West,
                to: WestTellersRoom.spec().ref,
            }),
            new Passage({
                via: Direction.East,
                to: EastTellersRoom.spec().ref,
            }),
        ];
    }

    hasWalls(): boolean {
        return true;
    }

    hasNorthWall(): boolean {
        return false;
    }
}

const DEPOSITORY_DESCRIPTION =
    'This is a large rectangular room. The east and west walls here were ' +
    'used for storing safety deposit boxes. As might be expected, all ' +
    'have been carefully removed by evil persons. To the east, west, ' +
    "and south of the room are large doorways. The northern 'wall' of " +
    'the room is a shimmering curtain of light. In the center of the ' +
    'room is a large stone cube, about 10 feet on a side. ' +
    'Engraved on the side of the cube is some lettering.';

const goThroughBankWalls: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(GoThrough)) {
        const curtain = game.ent(Curtain);
        const { portalWall, portalRoom } = getPortalWall(game, actor);
        if (portalWall && action.item.isEqualTo(portalWall)) {
            curtain.state.destinationRoom = portalRoom?.ref();
            await runner.doOutput(
                'You feel somewhat disoriented as you pass through...'
            );
            await game.applyAction(
                runner,
                new SpecialEnter({ room: game.ent(SafetyDepository) }),
                actor
            );
            return Action.complete();
        }
    }
};

const setCurtainRoom: Handler = async ({ action, game }) => {
    if (action.is(SpecialEnter)) {
        if (action.room.is(EastTellersRoom)) {
            const curtain = game.ent(Curtain);
            curtain.state.destinationRoom = EastViewingRoom.spec().ref;
        }
        if (action.room.is(WestTellersRoom)) {
            const curtain = game.ent(Curtain);
            curtain.state.destinationRoom = WestViewingRoom.spec().ref;
        }
        if (action.room.is(ChairmansOffice)) {
            const curtain = game.ent(Curtain);
            curtain.state.destinationRoom = SmallBankRoom.spec().ref;
        }
        return Action.incomplete();
    }
};

const leaveDepository: Handler = async ({ action, game, actor, runner }) => {
    if (
        action.is(Go) &&
        actor?.is(Player) &&
        (action.direction === Direction.East ||
            action.direction === Direction.West)
    ) {
        const room = actor.location();
        if (room.is(SafetyDepository)) {
            if (
                actor?.hasItem(game.ent(Bills)) ||
                actor?.hasItem(game.ent(Portrait))
            ) {
                await runner.doOutput(
                    'An alarm rings briefly and an invisible force prevents your leaving.'
                );
                return Action.complete();
            }
        }
    }
};

function getPortalWall(game: Game, actor: Actor | undefined) {
    const curtain = game.ent(Curtain);
    const curtainRoom = curtain.destinationRoom();
    const room = actor?.location();
    let portalWall;
    let portalRoom;
    if (curtainRoom && room && room.isEqualTo(curtainRoom)) {
        if (room.isEqualTo(game.ent(WestViewingRoom))) {
            portalWall = game.ent(EastWall);
            portalRoom = game.ent(EastViewingRoom);
        } else if (room.isEqualTo(game.ent(EastViewingRoom))) {
            portalWall = game.ent(WestWall);
            portalRoom = game.ent(WestViewingRoom);
        } else if (room.isEqualTo(game.ent(SmallBankRoom))) {
            portalWall = game.ent(SouthWall);
            portalRoom = game.ent(Vault);
        } else {
            portalWall = game.ent(NorthWall);
            portalRoom = game.ent(SmallBankRoom);
        }
    }
    return { portalWall, portalRoom };
}

const throwItemsAtBankWalls: Handler = async ({
    action,
    runner,
    actor,
    game,
}) => {
    if (action.is(Throw)) {
        const curtain = game.ent(Curtain);
        const { portalWall, portalRoom } = getPortalWall(game, actor);
        if (portalWall && action.enemy?.isEqualTo(portalWall)) {
            curtain.state.destinationRoom = portalRoom?.ref();
            await runner.doOutput(
                `${action.item.The()} passes through the wall and vanishes.`
            );
            action.item.moveTo(game.ent(SafetyDepository));
            return Action.complete();
        }
    }
};
