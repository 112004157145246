import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { TinyRoom } from '../TinyRoom';
import {
    BarredWindow,
    BluePalantir,
    Crack,
    OakDoor,
    OakDoorKeyhole2,
    OakDoorLid2,
    PalantirTable,
} from '../../items';

export class DrearyRoom extends Room {
    static spec(): EntitySpec<DrearyRoom> {
        return {
            ref: 'dreary-room',
            constructor: DrearyRoom,
            initial: {
                contents: [
                    OakDoor.spec().ref,
                    Crack.spec().ref,
                    OakDoorLid2.spec().ref,
                    OakDoorKeyhole2.spec().ref,
                    BluePalantir.spec().ref,
                    PalantirTable.spec().ref,
                    BarredWindow.spec().ref,
                    // TODO "formidable bolt lock"
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return DrearyRoom.spec().ref;
    }

    name(): string {
        return 'Dreary Room';
    }

    description(): string {
        return (
            'This is a small and rather dreary room, which is eerily ' +
            'illuminated by a red glow emanating from a crack in one of the walls. ' +
            'The light appears to focus on a dusty wooden table in the center of the room.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.South, SpecialDirection.Out],
                to: TinyRoom.spec().ref,
                condition: () => this.game.ent(OakDoor).isOpen(),
                message: 'The oak door is closed.',
            }),
        ];
    }

    globalObjects(): Entity[] {
        const objects = [...super.globalObjects()];
        const itemUnderneathDoor = this.ent(OakDoor).itemUnderneath();
        if (
            itemUnderneathDoor &&
            !itemUnderneathDoor.location()?.isEqualTo(this)
        ) {
            objects.push(itemUnderneathDoor);
        }
        return objects;
    }
}
