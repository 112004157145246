import { Lexicon, words } from './lexicon/lexicon';
import {
    AnyParser,
    ConstantParser,
    EitherParser,
    EndParser,
    LongestParser,
    ManyParser,
    MapParser,
    NodeParser,
    NothingParser,
    OptionParser,
    Parser,
    PremiseParser,
    RecurseParser,
    SequenceParser,
    ThenParser,
    WhitespaceParser,
    WordParser,
} from './parser';
import {
    Comma,
    Direction,
    Named,
    Pronoun,
    QuotationMark,
    Value,
    Word,
} from './lexicon';
import { Group, Obj, Target } from '.';
import { CollectedSequenceParser } from './parser/CollectedSequenceParser';

/**
 * Match predicate that gives a match function that wraps the result of
 * the given `matcher` in a `Named` node with the given `name`. To be used
 * in conjunction with `collect`.
 */
export function named<I, T>(
    parser: Parser<I, T>,
    name: string
): Parser<I, Named<T>> {
    return map(parser, (value: T) => new Named(name, value));
}

function many<I, T>(parser: Parser<I, T>): Parser<I, T[]> {
    return new ManyParser(parser);
}

/**
 * Match predicate that collects any `Named` nodes into an object, e.g.
 *
 * ```
 * map(collect(sequence(
 *     named(word("a"), "firstWord"),
 *     named(word("b"), "secondWord")
 * )), (nodes) => {
 *     return `First word: ${firsWord}, second word: ${secondWord}.`;
 * })
 * ```
 */
function collect<T>(parser: Parser<T, unknown>) {
    return map(parser, (values: unknown[]) =>
        Object.fromEntries(
            values
                .filter((value) => value instanceof Named)
                .map((named) => [
                    (named as Named<unknown>).name,
                    (named as Named<unknown>).value,
                ])
        )
    );
}

// TODO crs doc
function gather<I, X extends { [K in keyof X]: X[K] }>(
    ...parsers: (
        | Parser<I, unknown>
        | { name: keyof X; parser: Parser<I, X[keyof X]> }
    )[]
): Parser<I, X> {
    return new CollectedSequenceParser(...parsers);
}

// type ParsersT<I, X extends { [K in keyof X]: X[K] }> = { [K in keyof X]: Parser<I, K> };
//
// function gather2<I, X extends { [K in keyof X]: X[K] }>(
//   ...parsers: ParsersT<I, X>[]
// ): Parser<I, X> {
//     return new CollectedSequenceParser(...parsers);
// }

// type Rec<I, X, K extends keyof X> = { name: K; parser: Parser<I, X[K]> }
//
// // TODO crs doc
// function gather<I, X extends { [K in keyof X]: X[K] }>(
//   ...parsers: Rec<I, X, keyof X>[]
// ): Parser<I, X> {
//     return new CollectedSequenceParser(...parsers);
// }

/**
 * Function that returns a parser that always fails.
 */
function fail<I>(): Parser<I, null> {
    return premise(constant(null), () => false);
}

/**
 * Helper function to create a SequenceParser.
 */
function sequence<I, T>(...parsers: Parser<I, T>[]): Parser<I, T[]> {
    return new SequenceParser(...parsers);
}

/**
 * Helper function to create an EitherParser.
 */
function either<I, O1, O2>(
    parser1: Parser<I, O1>,
    parser2: Parser<I, O2>
): Parser<I, O1 | O2> {
    return new EitherParser(parser1, parser2);
}

/**
 * Helper function to create an OptionParser.
 */
function option<I, O>(parser: Parser<I, O>): Parser<I, O | undefined> {
    return new OptionParser(parser);
}

/**
 * Helper function to create a WhitespaceParser.=
 */
function whitespace() {
    return new WhitespaceParser();
}

/**
 * Helper function to create an AnyParser.
 */
function any<I, T>(...parsers: Parser<I, T>[]): Parser<I, T> {
    return new AnyParser(...parsers);
}

/**
 * Helper function to create a MapParser.
 */
function map<I, O1, O2>(
    parser: Parser<I, O1>,
    mapper: (v: O1) => O2
): Parser<I, O2> {
    return new MapParser(parser, mapper);
}

/**
 * Helper function to create a RecurseParser.
 */
function recurse<I, T>(maker: () => Parser<I, T>): Parser<I, T> {
    return new RecurseParser(maker);
}

/**
 * Helper function to create a PremiseParser.
 */
function premise<I, O>(
    parser: Parser<I, O>,
    condition: (v: O) => boolean
): Parser<I, O> {
    return new PremiseParser(parser, condition);
}

/**
 * Helper function to create a ConstantParser.
 */
function constant<I, T>(value: T): Parser<I, T> {
    return new ConstantParser(value);
}

/**
 * Helper function to create a NothingParser.
 */
function nothing<T>() {
    return new NothingParser<T>();
}

/**
 * Helper function to create a WordParser.
 */
function word(value?: string) {
    return new WordParser(value);
}

/**
 * Helper function to create a ThenParser.
 */
function then<I, O1, O2>(
    parser1: Parser<I, O1>,
    parser2: Parser<I, O2>
): Parser<I, [O1, O2]> {
    return new ThenParser(parser1, parser2);
}

/**
 * Helper function to create an EndParser.
 */
function end<I, O>(parser: Parser<I, O>): Parser<I, O> {
    return new EndParser(parser);
}

/**
 * Helper function to create an NodeParser.
 */
function node<T>() {
    return new NodeParser<T>();
}

/**
 * Parser predicate that matches a comma node.
 */
function comma(): Parser<Value, Comma> {
    return node<Value>().where((node) => node instanceof Comma);
}

/**
 * Parser predicate that matches a quote node.
 */
function quote(): Parser<Value, QuotationMark> {
    return node<Value>().where((node) => node instanceof QuotationMark);
}

/**
 * Helper function to create a parser that parses a series of words.
 */
function phrase(phrase: string) {
    return sequence(
        ...phrase
            .split(/\s/)
            .flatMap((segment) => [whitespace(), word(segment)])
            .slice(1)
    ).into(new Word(phrase));
}

/**
 * Helper function to create a parser that parses any number of nodes.
 */
function anything<T>() {
    return many(node<T>());
}

/**
 * TODO crs doc
 * TODO crs test
 */
function longest<I, T>(...parsers: Parser<I, T>[]): Parser<I, T> {
    return new LongestParser(...parsers);
}

const target = (lexicon: Lexicon): Parser<Value, Target> => {
    // "it"
    const pronoun = lexicon.pronoun();

    // "the shiny sword"
    const single = option(lexicon.article().before(whitespace())).chain(
        (article) =>
            many(lexicon.adjective().before(whitespace())).chain((adjectives) =>
                either(lexicon.singularNoun(), lexicon.collectiveNoun()).map(
                    (noun) => new Obj(article, adjectives, noun)
                )
            )
    );

    // "the treasures"
    const plural = option(lexicon.article().before(whitespace())).chain(
        (article) =>
            many(lexicon.adjective().before(whitespace())).chain((adjectives) =>
                lexicon
                    .pluralNoun()
                    .map((noun) => new Obj(article, adjectives, noun))
            )
    );

    // "all of the treasures"
    const group = plural
        .after(option(word('all').or(phrase('all of')).before(whitespace())))
        .map((set) => new Group({ include: set }));

    const commaAnd = any(
        whitespace().then(word('and')).then(whitespace()).into(null),
        comma().then(whitespace()).into(null),
        comma()
            .then(whitespace())
            .then(word('and'))
            .then(whitespace())
            .into(null)
    );

    // "the leaflet, the mat, and the mailbox"
    const objectList = many(either(single, group).before(commaAnd)).chain(
        (head) => either(single, group).map((last) => head.concat(last))
    );

    // "all of the things except this and that"
    const all = either(
        group,
        either(
            group,
            lexicon
                .pluralPronoun()
                .map((pronoun) => new Group({ include: pronoun }))
        )
            .map((group) => group.include)
            .before(
                whitespace()
                    .then(
                        any(word('except'), phrase('except for'), word('but'))
                    )
                    .then(whitespace())
            )
            .chain((include) =>
                objectList.map((exclude) => new Group({ include, exclude }))
            )
    );

    // "it and the leaflet, and all the treasures except the sword and all the books"
    return either(
        pronoun.map((pronoun) => new Target({ items: [pronoun] })),
        option(pronoun.before(commaAnd)).chain((pronoun) =>
            many(either(all, single).before(commaAnd)).chain((head) =>
                either(all, single).map((last) => {
                    let items: (Pronoun | Group | Obj)[] =
                        pronoun !== undefined ? [pronoun] : [];
                    if (head !== undefined) items = items.concat(head);
                    if (last !== undefined) items = items.concat(last);
                    return new Target({ items });
                })
            )
        )
    );
};

// function run<I, T, Q>(g: Generator<Parser<I, T>, Q, T>): Parser<I, Q> {
//     const next = (x: T): Parser<I, T> => {
//         const { value, done } = g.next(x);
//         if (value instanceof Parser) {
//             return value.chain(next);
//         }
//     }
//     return next(nothing())
// }

// function Do<I, Q, T>(
//     generator: () => Generator<Parser<I, T>, Q, T>
// ): Parser<I, Q> {
//     const iterator = generator();
//     const state = iterator.next();
//
//     function run<T>(state: IteratorResult<Parser<I, T>, Q>): Parser<I, Q> {
//         if (state.done) {
//             // any - if iterator is done, then its type is A, not HKT<M, A>
//             return constant(state.value as Q);
//         }
//
//         return (state.value as Parser<I, T>).chain((value) =>
//             run(iterator.next(value))
//         );
//     }
//
//     return run(state);
// }

function direction(direction?: Direction): Parser<Value, Direction> {
    const all = {
        [Direction.North]: any(word('north'), word('n')).into(Direction.North),
        [Direction.East]: any(word('east'), word('e')).into(Direction.East),
        [Direction.South]: any(word('south'), word('s')).into(Direction.South),
        [Direction.West]: any(word('west'), word('w')).into(Direction.West),
        [Direction.Northwest]: any(
            word('northwest'),
            word('nw'),
            phrase('north west')
        ).into(Direction.Northwest),
        [Direction.Northeast]: any(
            word('northeast'),
            word('ne'),
            phrase('north east')
        ).into(Direction.Northeast),
        [Direction.Southwest]: any(
            word('southwest'),
            word('sw'),
            phrase('south west')
        ).into(Direction.Southwest),
        [Direction.Southeast]: any(
            word('southeast'),
            word('se'),
            phrase('south east')
        ).into(Direction.Southeast),
        [Direction.Up]: any(word('up'), word('u')).into(Direction.Up),
        [Direction.Down]: any(word('down'), word('d')).into(Direction.Down),
    };

    if (direction) {
        return all[direction];
    }
    return Parse.any(...Object.values(all));
}

function string(): Parser<Value, string> {
    return Parse.many(Parse.node<Value>()).map((nodes) =>
        nodes.map((node) => node.value).join('')
    );
}

export const Parse = {
    words,
    any,
    word,
    phrase,
    sequence,
    whitespace,
    target,
    either,
    many,
    option,
    anything,
    longest,
    collect,
    named,
    recurse,
    nothing,
    then,
    end,
    comma,
    fail,
    constant,
    map,
    premise,
    node,
    gather,
    direction,
    quote,
    string,
    // do: Do,
};
