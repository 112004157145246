import { Passage, Room } from '..';
import { EntitySpec, Handler } from '../../game';
import { Direction } from '../../../parse';
import { TopOfWell } from '../TopOfWell';
import { RoomState } from '../Room';
import { Take } from '../../abilities';
import {
    EatMeCake,
    BlueCake,
    OblongTable,
    OrangeCake,
    RedCake,
} from '../../items';
import { PoolRoom } from '../PoolRoom';
import { LowRoom } from '../LowRoom';

interface TeaRoomState extends RoomState {
    isEnlarged: boolean;
}

export class TeaRoom extends Room<TeaRoomState> {
    static spec(): EntitySpec<TeaRoom> {
        return {
            ref: 'tea-room',
            constructor: TeaRoom,
            initial: {
                contents: [
                    OblongTable.spec().ref,
                    EatMeCake.spec().ref,
                    OrangeCake.spec().ref,
                    RedCake.spec().ref,
                    BlueCake.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
                isEnlarged: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [takeWhileEnlarged],
        };
    }

    ref() {
        return TeaRoom.spec().ref;
    }

    name(): string {
        if (this.isEnlarged()) {
            return 'Posts Room';
        }
        return 'Tea Room';
    }

    description(): string {
        if (this.isEnlarged()) {
            return (
                'This is an enormous room, in the center of which ' +
                'are four wooden posts delineating a rectangular area, above ' +
                'which is what appears to be a wooden roof. In fact, all objects ' +
                'in this room appear to be abnormally large. ' +
                'To the east is a passageway. There is a large chasm on ' +
                'the west and the northwest.'
            );
        }
        return (
            'This is a small square room, in the center of which is a ' +
            'large oblong table, no doubt set for afternoon tea. It is ' +
            'clear from the objects on the table that the users were ' +
            'indeed mad. In the eastern corner of the room is a small hole ' +
            '(no more than four inches high). There are passageways ' +
            'leading away to the west and the northwest.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        const TINY_CHASM = 'There is a chasm too large to jump across.';
        return [
            new Passage({
                via: Direction.West,
                to: TopOfWell.spec().ref,
                condition: () => !this.isEnlarged(),
                message: TINY_CHASM,
            }),
            new Passage({
                via: Direction.East,
                to: PoolRoom.spec().ref,
                condition: () => this.isEnlarged(),
                message: 'Only a mouse could get in there.',
            }),
            new Passage({
                via: Direction.Northwest,
                to: LowRoom.spec().ref,
                condition: () => !this.isEnlarged(),
                message: TINY_CHASM,
            }),
            new Passage({
                via: Direction.Down,
                message: this.isEnlarged()
                    ? TINY_CHASM
                    : 'There is no way down.',
            }),
        ];
    }

    isEnlarged(): boolean {
        return this.state.isEnlarged;
    }

    setIsEnlarged(isEnlarged: boolean) {
        this.state.isEnlarged = isEnlarged;
    }
}

const takeWhileEnlarged: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Take) && action.sizeMultiplier === 1) {
        const room = actor?.location();
        // TODO maybe only apply this to the objects that were in the room at the time?
        if (room?.is(TeaRoom) && room.isEnlarged()) {
            return game.applyAction(
                runner,
                new Take({
                    item: action.item,
                    silent: action.silent,
                    container: action.container,
                    sizeMultiplier: 64,
                }),
                actor
            );
        }
    }
};
