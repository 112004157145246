import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { NUMBERS } from '../../constants';
import { Entity, EntitySpec } from '../../game';
import { NorthCorridor } from '../NorthCorridor';
import { Sundial } from '../../items/Sundial';
import { SundialButton } from '../../items/SundialButton';
import { DungeonMaster } from '../../actors';

export class Parapet extends Room {
    static spec(): EntitySpec<Parapet> {
        return {
            ref: 'parapet',
            constructor: Parapet,
            initial: {
                contents: [Sundial.spec().ref, SundialButton.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Parapet.spec().ref;
    }

    name(): string {
        return 'Parapet';
    }

    description(): string {
        const sundialSetting = this.game.ent(Sundial).setting();
        return (
            'You are standing behind a stone retaining wall which rims a ' +
            'large parapet overlooking a fiery pit. It is difficult to see ' +
            'through the smoke and flame which fills the pit, but it seems to ' +
            'be more or less bottomless. It also extends upward out of sight. ' +
            'The pit itself is of roughly dressed stone and circular in shape. ' +
            'It is about two hundred feet in diameter. The flames generate considerable ' +
            'heat, so it is rather uncomfortable standing here. There is an object here ' +
            'which looks like a sundial. On it are an indicator arrow and (in the center) ' +
            "a large button. On the face of the dial are numbers 'one' through 'eight'. " +
            `The indicator points to the number '${NUMBERS[sundialSetting]}'.`
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.South,
                to: NorthCorridor.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                message: 'You would be burned to a crisp in no time.',
            }),
        ];
    }

    visibleObjects(): Entity[] {
        const objects = [...super.visibleObjects()];
        const dungeonMaster = this.game.ent(DungeonMaster);
        if (this.game.ent(NorthCorridor).contains(dungeonMaster)) {
            objects.push(dungeonMaster);
        }
        return objects;
    }
}
