import { Passage, Room } from '..';
import { EntitySpec } from '../../game';

export class VolcanoCore extends Room {
    static spec(): EntitySpec<VolcanoCore> {
        return {
            ref: 'volcano-core',
            constructor: VolcanoCore,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return VolcanoCore.spec().ref;
    }

    name(): string {
        return 'Volcano Core';
    }

    description(): string {
        return (
            'You are about one hundred feet above the bottom of the volcano. ' +
            'The top of the volcano is clearly visible here.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [];
    }

    isOnLand(): boolean {
        return false;
    }

    isSacred() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
