import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { GuideBook, Matchbook } from '../../items';
import { Postcard } from '../../items/Postcard';
import { Dam } from '../Dam';
import { MaintenanceRoom } from '../MaintenanceRoom';

export class DamLobby extends Room {
    static spec(): EntitySpec<DamLobby> {
        return {
            ref: 'dam-lobby',
            constructor: DamLobby,
            initial: {
                contents: [
                    Matchbook.spec().ref,
                    Postcard.spec().ref,
                    GuideBook.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return DamLobby.spec().ref;
    }

    name(): string {
        return 'Dam Lobby';
    }

    description(): string {
        return (
            'This room appears to have been the waiting room for ' +
            'groups touring the dam. There are exits here to the north and ' +
            "east marked 'Private', though the doors are open, and an exit to the south."
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.South, to: Dam.spec().ref }),
            new Passage({
                via: Direction.North,
                to: MaintenanceRoom.spec().ref,
            }),
            new Passage({
                via: Direction.East,
                to: MaintenanceRoom.spec().ref,
            }),
        ];
    }
}
