import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Mung, Open, Poke, Push } from '../../abilities';
import { Mirror1 } from '../Mirror1';

export class MirrorPanel1 extends Item {
    static spec(): EntitySpec<MirrorPanel1> {
        return {
            ref: 'mirror-panel-1',
            constructor: MirrorPanel1,
            initial: {},
            nouns: [new Noun('panel'), new Noun('panels', { plural: true })],
            adjectives: [new Adjective('wood'), new Adjective('wooden')],
            handlers: [pushMirrorDoorPanel, openMirror, breakPanel],
        };
    }

    ref() {
        return MirrorPanel1.spec().ref;
    }

    name(): string {
        return 'wooden panel';
    }

    description(): string {
        return '';
    }

    nouns() {
        return MirrorPanel1.spec().nouns;
    }

    adjectives() {
        return MirrorPanel1.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushMirrorDoorPanel: Handler = async ({ action, runner }) => {
    if (action.is(Push) && action.item.is(MirrorPanel1)) {
        await runner.doOutput(
            'The wooden panel moves slightly inward as you push, and back out when you let go.'
        );
        return Action.complete();
    }
};

const openMirror: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(MirrorPanel1)) {
        await runner.doOutput("I don't see a way to open the panel here.");
        return Action.complete();
    }
};

const breakPanel: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(Poke) && action.enemy.is(MirrorPanel1)) ||
        (action.is(Mung) && action.item.is(MirrorPanel1))
    ) {
        const mirror = game.ent(Mirror1);
        if (mirror.isMunged()) {
            await runner.doOutput('The panel is not that easily destroyed.');
        } else {
            await runner.doOutput(
                'To break the panel you would have to break the mirror first.'
            );
        }
        return Action.complete();
    }
};
