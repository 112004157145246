import { Clearing, Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { Maze11 } from '../Maze11';
import { Grating } from '../../items';

export class GratingRoom extends Room {
    static spec(): EntitySpec<GratingRoom> {
        return {
            ref: 'grating-room',
            constructor: GratingRoom,
            initial: {
                contents: [
                    Grating.spec().ref,
                    // TODO maybe Lock
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return GratingRoom.spec().ref;
    }

    name(): string {
        return 'Grating Room';
    }

    description(): string {
        let description =
            'You are in a small room near the maze. There are twisty passages in the immediate vicinity.';
        const grating = this.game.ent(Grating);
        if (grating.isOpen()) {
            description +=
                '\nAbove you is an open grating with sunlight pouring in.';
        } else if (grating.isLocked()) {
            description +=
                '\nAbove you is a grating locked with a skull-and-crossbones lock.';
        } else {
            description += '\nAbove you is a grating.';
        }
        return description;
    }

    isNaturallyLit(): boolean {
        return this.game.ent(Grating).isOpen();
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Southwest, to: Maze11.spec().ref }),
            new Passage({
                via: Direction.Up,
                to: Clearing.spec().ref,
                condition: () => this.game.ent(Grating).isOpen(),
                message: this.game.ent(Grating).isLocked()
                    ? 'The grating is locked.'
                    : 'The grating is closed.',
            }),
        ];
    }

    // TODO
    isPartOfMaze() {
        return true;
    }
}
