import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class GrailPedestal extends Item {
    static spec(): EntitySpec<GrailPedestal> {
        return {
            ref: 'grail-pedestal',
            constructor: GrailPedestal,
            initial: {},
            nouns: [
                new Noun('pedestal'),
                new Noun('pedestals', { plural: true }),
                new Noun('plinth'),
                new Noun('plinths', { plural: true }),
                new Noun('stand'),
                new Noun('stands', { plural: true }),
            ],
            adjectives: [
                new Adjective('white'),
                new Adjective('marble'),
                new Adjective('stone'),
                new Adjective("grail's"),
            ],
        };
    }

    ref() {
        return GrailPedestal.spec().ref;
    }

    name(): string {
        return 'pedestal';
    }

    description(): string {
        return '';
    }

    nouns() {
        return GrailPedestal.spec().nouns;
    }

    adjectives() {
        return GrailPedestal.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}
