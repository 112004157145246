import { Noun, Adjective } from '../../../parse';
import { makeReadable, makeTakeable, makeFlammable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Newspaper extends makeFlammable(makeTakeable(makeReadable(Item))) {
    static spec(): EntitySpec<Newspaper> {
        return {
            ref: 'newspaper',
            constructor: Newspaper,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('paper'),
                new Noun('paper', { collective: true }),
                new Noun('newspaper'),
                new Noun('newspaper', { collective: true }),
                new Noun('issue'),
                new Noun('issues', { plural: true }),
                new Noun('news paper'),
                new Noun('news papers', { plural: true }),
                new Noun('news'),
                new Noun('news', { plural: true }),
            ],
            adjectives: [new Adjective('old')],
        };
    }

    ref() {
        return Newspaper.spec().ref;
    }

    name() {
        return 'newspaper';
    }

    description() {
        // TODO update the date
        return 'There is an issue of US NEWS & DUNGEON REPORT dated 7/22/81 here.';
    }

    text() {
        return NEWSPAPER_TEXT;
    }

    nouns(): Noun[] {
        return Newspaper.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Newspaper.spec().adjectives;
    }
}

// TODO crs update this
const NEWSPAPER_TEXT =
    `
US NEWS & DUNGEON REPORT
7/22/81                Last G.U.E. Edition

This version of ZORK is no longer being supported on this or any other machine. ` +
    `In particular, bugs and feature requests will, most likely, be read and ` +
    `ignored. There are updated versions of ZORK, including some altogether new ` +
    `problems, available for PDP-11s and various microcomputers (TRS-80, APPLE, ` +
    `maybe more later). For information, send a SASE to:
  Infocom, Inc.
  P.O. Box 120, Kendall Station
  Cambridge, Ma. 02142
`;
