import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class SouthWall extends Item {
    static spec(): EntitySpec<SouthWall> {
        return {
            ref: 'south-wall',
            constructor: SouthWall,
            initial: {},
            nouns: [new Noun('wall'), new Noun('walls', { plural: true })],
            adjectives: [
                new Adjective('south'),
                new Adjective('southern'),
                new Adjective('southernmost'),
            ],
        };
    }

    ref() {
        return SouthWall.spec().ref;
    }

    name(): string {
        return 'south wall';
    }

    description(): string {
        return '';
    }

    nouns() {
        return SouthWall.spec().nouns;
    }

    adjectives() {
        return SouthWall.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isWall(): boolean {
        return true;
    }
}
