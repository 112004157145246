import { Eat } from './Eat';
import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { UnresolvedAction } from '../UnresolvedAction';

export class EatUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'eat';

    item: Target;

    constructor({ item }: { item: Target }) {
        super();
        this.item = item;
    }

    description(game: Game) {
        return `eat ${targetDescription(game, this.item)}`;
    }
}

export const eatUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(EatUnresolved)) return;
    const { item } = await game.resolve(runner, action.item, actor, {
        partial: (item) => new EatUnresolved({ item }),
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to eat, ${opt}?`,
        condition: (item) => item.isItem() && item.isFood(),
    });
    if (item === undefined) return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, item, actor))) {
        return Action.complete();
    }

    return await game.applyAction(runner, new Eat({ item }), actor);
};
