import { Passage, Room } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { Direction } from '../../../parse';
import { StoneDoor } from '../../items';
import { EngravingsCave } from '../EngravingsCave';
import { Answer } from '../../abilities/Answer';
import { Player } from '../../actors';
import { PearlRoom } from '../PearlRoom';

export class RiddleRoom extends Room {
    static spec(): EntitySpec<RiddleRoom> {
        return {
            ref: 'riddle-room',
            constructor: RiddleRoom,
            initial: {
                contents: [StoneDoor.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [judgeAnswer],
        };
    }

    ref() {
        return RiddleRoom.spec().ref;
    }

    name(): string {
        return 'Riddle Room';
    }

    description(): string {
        return RIDDLE_ROOM_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Down, to: EngravingsCave.spec().ref }),
            new Passage({
                via: Direction.East,
                condition: () => this.game.ent(StoneDoor).isOpen(),
                to: PearlRoom.spec().ref,
                message: 'Your way is blocked by an invisible force.',
            }),
        ];
    }
}

const RIDDLE_ROOM_DESCRIPTION =
    'This is a room which is bare on all sides. There is an exit down. ' +
    'To the east is a great door made of stone. Above the stone, the ' +
    'following words are written: \n\n' +
    "'No man shall enter this room without solving this riddle: \n\n" +
    "What is tall as a house, round as a cup, and all the king's " +
    "horses can't draw it up?'\n\n" +
    '(Reply via `answer "something"`)';

const judgeAnswer: Handler = async ({ action, runner, game }) => {
    if (action.is(Answer)) {
        const room = game.ent(Player).location();
        const door = game.ent(StoneDoor);
        if (
            room.is(RiddleRoom) &&
            action.message?.toLowerCase() === 'well' &&
            !door.isOpen()
        ) {
            await runner.doOutput(
                'There is a clap of thunder and the east door opens.'
            );
            door.state.isOpen = true;
            return Action.complete();
        }
    }
};
