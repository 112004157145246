export enum Direction {
    North = 'north',
    East = 'east',
    South = 'south',
    West = 'west',
    Northwest = 'northwest',
    Northeast = 'northeast',
    Southwest = 'southwest',
    Southeast = 'southeast',
    Up = 'up',
    Down = 'down',

    // In = 'in',
    // Out = 'out',
}

export enum SpecialDirection {
    In = 'in',
    Out = 'out',
    Launch = 'launch',
    Land = 'land',
    Cross = 'cross',
}
