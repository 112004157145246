import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeOpenable, makeTakeable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Take } from '../../abilities';
import { Emerald } from '../Emerald';

export class Buoy extends makeTakeable(makeOpenable(makeContainer(Item))) {
    static spec(): EntitySpec<Buoy> {
        return {
            ref: 'buoy',
            constructor: Buoy,
            initial: {
                contents: [Emerald.spec().ref],
                isOpen: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('buoy'),
                new Noun('float'),
                new Noun('buoys', { plural: true }),
                new Noun('floats', { plural: true }),
            ],
            adjectives: [new Adjective('red'), new Adjective('warning')],
            handlers: [takeBuoy],
        };
    }

    ref() {
        return Buoy.spec().ref;
    }

    name(): string {
        return 'red buoy';
    }

    description(): string {
        return 'There is a red buoy here (probably a warning).';
    }

    totalCapacity(): number {
        return 20;
    }

    size(): number {
        return 10;
    }

    nouns() {
        return Buoy.spec().nouns;
    }

    adjectives() {
        return Buoy.spec().adjectives;
    }

    canBeFound(): boolean {
        return true; // TODO what is this?
    }
}

const takeBuoy: Handler = async ({ action, runner, extensions, actor }) => {
    if (
        action.is(Take) &&
        action.item.is(Buoy) &&
        !action.item.hasBeenTaken()
    ) {
        await extensions.deferHandling();
        if (actor?.hasItem(action.item)) {
            await runner.doOutput(
                'Something seems funny about the feel of the buoy.'
            );
        }
        return Action.complete();
    }
};
