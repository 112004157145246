import { Actor, ActorState, Player } from '../index';
import { Adjective, Noun } from '../../../parse';
import { Give, SpecialTimerTick, Throw } from '../../abilities';
import { Action, Entity, Handler } from '../../game';
import { NarrowLedge, WideLedge } from '../../rooms';
import { Brick, Fuse, VolcanoGnomeDoor } from '../../items';

interface VolcanoGnomeState extends ActorState {
    isArrivingSoon: boolean;
    timeUntilArrival: number;
    isDepartingSoon: boolean;
    timeUntilDeparture: number;
}

export class VolcanoGnome extends Actor<VolcanoGnomeState> {
    static spec() {
        return {
            ref: 'volcano-gnome',
            constructor: VolcanoGnome,
            initial: {
                inventory: [],
                isLucky: true,
                isConscious: true,
                isFighting: false,
                isStunned: false,
                isAlive: true,
                reviveChance: undefined,
                strength: 0,
                isArrivingSoon: false,
                timeUntilArrival: 10,
                isDepartingSoon: false,
                timeUntilDeparture: 10,
                isEngrossed: false,
            },
            nouns: [
                new Noun('gnome'),
                new Noun('gnomes', { plural: true }),
                new Noun('volcano gnome'),
                new Noun('volcano gnomes', { plural: true }),
            ],
            adjectives: [new Adjective('nervous')],
            handlers: [tickGnome, giveToGnome],
        };
    }

    ref() {
        return VolcanoGnome.spec().ref;
    }

    name(): string {
        return 'volcano gnome';
    }

    description(): string {
        return 'There is a nervous Volcano Gnome here.';
    }

    isVillain(): boolean {
        return false;
    }

    isVictim(): boolean {
        return true;
    }

    nouns(): Noun[] {
        return VolcanoGnome.spec().nouns;
    }

    adjectives(): Adjective[] {
        return VolcanoGnome.spec().adjectives;
    }

    isReadyToFight(): boolean {
        return false;
    }

    isArrivingSoon() {
        return this.state.isArrivingSoon;
    }

    setIsArrivingSoon(isArrivingSoon: boolean) {
        this.state.isArrivingSoon = isArrivingSoon;
    }

    timeUntilArrival() {
        return this.state.timeUntilArrival;
    }

    setTimeUntilArrival(timeUntilArrival: number) {
        this.state.timeUntilArrival = timeUntilArrival;
    }

    isDepartingSoon() {
        return this.state.isDepartingSoon;
    }

    setIsDepartingSoon(isDepartingSoon: boolean) {
        this.state.isDepartingSoon = isDepartingSoon;
    }

    timeUntilDeparture() {
        return this.state.timeUntilDeparture;
    }

    setTimeUntilDeparture(timeUntilDeparture: number) {
        this.state.timeUntilDeparture = timeUntilDeparture;
    }
}
const tickGnome: Handler = async ({ action, runner, game }) => {
    if (action.is(SpecialTimerTick)) {
        const gnome = game.ent(VolcanoGnome);
        const room = game.ent(Player).location();
        if (gnome.isArrivingSoon()) {
            gnome.setTimeUntilArrival(gnome.timeUntilArrival() - 1);
            if (
                gnome.timeUntilArrival() <= 0 &&
                (room.like(WideLedge) || room.like(NarrowLedge))
            ) {
                gnome.moveTo(room);
                await runner.doOutput(GNOME_APPEARS);
                gnome.setIsDepartingSoon(true);
                gnome.setTimeUntilDeparture(10);
                gnome.setIsArrivingSoon(false);
            }
        } else if (gnome.isDepartingSoon()) {
            gnome.setTimeUntilDeparture(gnome.timeUntilDeparture() - 1);
            if (gnome.timeUntilDeparture() <= 0) {
                if (gnome.location()?.isEqualTo(room)) {
                    await runner.doOutput(
                        "The gnome glances at his watch. 'Oops. I'm late for an appointment!' " +
                            'He disappears, leaving you alone on the ledge.'
                    );
                }
                gnome.moveTo(undefined);
                gnome.setIsDepartingSoon(false);
            } else if (gnome.location()?.isEqualTo(room)) {
                await runner.doOutput(
                    'The gnome appears increasingly nervous.'
                );
            }
        }
    }
    return Action.incomplete();
};

const GNOME_APPEARS =
    'A volcano gnome seems to walk straight out of the wall and says ' +
    "'I have a very busy appointment schedule and little time to waste on trespassers, " +
    "but for a small fee, I'll show you the way out.' " +
    'You notice the gnome nervously glancing at his watch.';

const giveToGnome: Handler = async ({ action, runner, actor, game }) => {
    if (
        ((action.is(Give) && action.recipient.is(VolcanoGnome)) ||
            (action.is(Throw) && action.enemy?.is(VolcanoGnome))) &&
        actor?.is(Player) &&
        actor?.hasItem(action.item)
    ) {
        const { item: gift } = action;
        const gnome = game.ent(VolcanoGnome);
        if (gift.isItem() && gift.isTreasure()) {
            gift.moveTo(gnome);
            gnome.moveTo(undefined);
            await runner.doOutput(
                `'Thank you very much for ${gift.the()}. I don't believe I've ever seen one ` +
                    "as beautiful. Follow me', he says, and a door appears on the west " +
                    'end of the ledge. Through the door, you can see a narrow chimney ' +
                    'sloping steeply downward. The gnome moves quickly, and he disappears from sight.'
            );
            game.ent(VolcanoGnomeDoor).setIsHidden(false);
            gnome.setIsDepartingSoon(false);
        } else if (isItABomb(gift)) {
            gift.moveTo(actor.location());
            gnome.moveTo(undefined);
            await runner.doOutput(
                "'That certainly wasn't what I had in mind', he says, and disappears."
            );
            gnome.setIsDepartingSoon(false);
        } else {
            gift.moveTo(undefined);
            await runner.doOutput(
                `'That wasn't quite what I had in mind', he says, crunching ${gift.the()} in his rock-hard hands.`
            );
        }

        return Action.complete();
    }
};

// TODO put this somewhere shared
export function isItABomb(item: Entity): boolean {
    const fuse = item.game.ent(Fuse);
    return item.is(Brick) && item.contains(fuse) && fuse.isAflame();
}
