import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { Hallway1 } from '../Hallway1';
import { Hallway3 } from '../Hallway3';
import {
    getNarrowRoomDescription,
    hallwayGlobalObjects,
    narrowRoomEnterPassage,
} from '../InsideMirror/mirror_utils';

export class WestNarrowRoom2 extends Room {
    static spec(): EntitySpec<WestNarrowRoom2> {
        return {
            ref: 'west-narrow-room-1',
            constructor: WestNarrowRoom2,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return WestNarrowRoom2.spec().ref;
    }

    name(): string {
        return 'Narrow Room';
    }

    description(): string {
        let description = getNarrowRoomDescription(
            this.game,
            this,
            Direction.West
        );
        description += '\nTo the north and south are large hallways.';
        return description;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    globalObjects(): Entity[] {
        return [...super.globalObjects(), ...hallwayGlobalObjects(this)];
    }

    passages(): Passage[] {
        return [
            narrowRoomEnterPassage(this.game, Direction.East),
            new Passage({
                via: Direction.South,
                to: Hallway1.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                to: Hallway3.spec().ref,
            }),
        ];
    }
}
