import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { FollowUnresolved, followUnresolvedHandler } from './FollowUnresolved';
import { Player } from '../../actors';

export class Follow extends Action {
    id = '~follow';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [followHandler, followUnresolvedHandler],
            parser,
            verbs: [new Verb('follow')],
            prepositions: [],
        };
    }
}

export const followHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Follow)) return;
    const { item } = action;
    if (action.item.isActor() && action.item.isVillain()) {
        await runner.doOutput(`${item.The()} eludes you.`);
    } else if (item.is(Player)) {
        await runner.doOutput('Ok.');
    } else {
        await runner.doOutput(
            "I don't enjoy leading crazies through the dungeon."
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, FollowUnresolved> => {
    const follow = Parse.words(Follow.ability().verbs);
    const followObject = follow.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // follow
        follow.into(new FollowUnresolved({ item: undefined })),
        // follow button
        followObject.map((item) => new FollowUnresolved({ item }))
    );
};
