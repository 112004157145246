import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Trident extends makeTreasure(makeTakeable(Item)) {
    static spec(): EntitySpec<Trident> {
        return {
            ref: 'trident',
            constructor: Trident,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('trident'),
                new Noun('fork'),
                new Noun('tridents', { plural: true }),
                new Noun('forks', { plural: true }),
            ],
            adjectives: [
                new Adjective('crystal'),
                new Adjective("poseidon's"),
                new Adjective("poseidon's own"),
                new Adjective('poseidons'),
            ],
        };
    }

    ref() {
        return Trident.spec().ref;
    }

    name() {
        return 'crystal trident';
    }

    description() {
        return "Poseidon's own crystal trident is here.";
    }

    initialDescription() {
        return "On the shore lies Poseidon's own crystal trident.";
    }

    size() {
        return 20;
    }

    scoreOnTake(): number {
        return 4;
    }

    scoreInCase(): number {
        return 11;
    }

    nouns(): Noun[] {
        return Trident.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Trident.spec().adjectives;
    }
}

// TODO maybe add messages for if you try to attack with it
