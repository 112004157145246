import { Noun, Adjective } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Balloon, BlueLabel } from '../index';
import { makeContainer, makeOpenable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Light, PutIn, Take } from '../../abilities';

interface BalloonReceptacleState extends ItemState {
    fuelRemaining: number;
}

abstract class Base extends Item<BalloonReceptacleState> {}

export class BalloonReceptacle extends makeOpenable(makeContainer(Base)) {
    static spec(): EntitySpec<BalloonReceptacle> {
        return {
            ref: 'balloon-receptacle',
            constructor: BalloonReceptacle,
            initial: {
                contents: [],
                fuelRemaining: 0,
                isOpen: false,
            },
            nouns: [
                new Noun('receptacle'),
                new Noun('receptacles', { plural: true }),
                new Noun('box'),
                new Noun('boxes', { plural: true }),
            ],
            adjectives: [new Adjective('fuel'), new Adjective('metal')],
            handlers: [
                burnItemInReceptacle,
                takeItemFromReceptacle,
                putItemInReceptacle,
            ],
        };
    }

    fuelRemaining() {
        return this.state.fuelRemaining;
    }

    setFuelRemaining(fuelRemaining: number) {
        this.state.fuelRemaining = fuelRemaining;
    }

    ref() {
        return BalloonReceptacle.spec().ref;
    }

    name(): string {
        return 'receptacle';
    }

    totalCapacity(): number {
        return 6;
    }

    description() {
        return '';
    }

    shouldBeDescribed() {
        return false;
    }

    nouns() {
        return BalloonReceptacle.spec().nouns;
    }

    adjectives() {
        return BalloonReceptacle.spec().adjectives;
    }
}

const burnItemInReceptacle: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Light) &&
        game.ent(BalloonReceptacle).contains(action.item) &&
        action.tool &&
        action.tool.isItem() &&
        action.tool.isFlammable() &&
        action.tool.isAflame() &&
        action.item.isItem() &&
        action.item.isFlammable() &&
        !action.item.isAflame()
    ) {
        await runner.doOutput(
            `${action.item.The()} burns inside the receptacle.`
        );
        game.ent(Balloon).setTimeUntilNextFly(4); // 4 because this action will tick it down to 3
        game.ent(BalloonReceptacle).setFuelRemaining(action.item.size() * 20);
        action.item.state.isAflame = true;
        game.ent(BlueLabel).setIsHidden(false);
        await runner.doOutput(
            'The cloth bag inflates as it fills with hot air.'
        );
        return Action.complete();
    }
};

const takeItemFromReceptacle: Handler = async ({ action, runner, game }) => {
    if (
        action.is(Take) &&
        game.ent(BalloonReceptacle).contains(action.item) &&
        action.item.isItem() &&
        action.item.isFlammable() &&
        action.item.isAflame()
    ) {
        await runner.doOutput(
            `You don't really want to hold a burning ${action.item.name()}.`
        );
        return Action.complete();
    }
};

const putItemInReceptacle: Handler = async ({ action, runner }) => {
    if (
        action.is(PutIn) &&
        action.container.is(BalloonReceptacle) &&
        !action.container.isEmpty()
    ) {
        await runner.doOutput('The receptacle is already occupied.');
        return Action.complete();
    }
};

// TODO what if you put a flaming item in the receptacle?
