import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { CLIFFS_IN_WAY, CURRENT_STRONG } from '../../constants';
import { EntitySpec } from '../../game';
import { River3 } from '../River3';
import { Boat, River } from '../../items';

export class River2 extends Room {
    static spec(): EntitySpec<River2> {
        return {
            ref: 'river-2',
            constructor: River2,
            initial: {
                contents: [River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return River2.spec().ref;
    }

    name(): string {
        return 'Frigid River';
    }

    description(): string {
        return (
            'The River turns a corner here making it impossible to see ' +
            'the Dam. The White Cliffs loom on the east bank and large ' +
            'rocks prevent landing on the west.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isOnLand(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.Up, Direction.North],
                message: CURRENT_STRONG,
            }),
            new Passage({
                via: [Direction.Down, Direction.South],
                to: River3.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({ via: Direction.East, message: CLIFFS_IN_WAY }),
            // TODO maybe add a custom message for no-landing
        ];
    }

    hasWalls() {
        return false;
    }
}
