import { Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { makeOpenable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Lock, Open, Unlock } from '../../abilities';
import { DUMMY } from '../../constants';
import { GratingRoom } from '../../rooms';
import { SkeletonKeys } from '../SkeletonKeys';
import { RustyKey } from '../RustyKey';
import { PileOfLeaves } from '../PileOfLeaves';

interface TrapDoorState extends ItemState {
    isLocked: boolean;
}

abstract class Base extends Item<TrapDoorState> {}

export class Grating extends makeOpenable(Base) {
    static spec(): EntitySpec<Grating> {
        return {
            ref: 'grating',
            constructor: Grating,
            initial: {
                isLocked: true,
                isOpen: false,
            },
            // TODO maybe add a lock that you can put the key in and turn
            nouns: [
                new Noun('grating'),
                new Noun('gratings', { plural: true }),
                new Noun('grate'),
                new Noun('grates', { plural: true }),
            ],
            adjectives: [],
            handlers: [openGrating, closeGrating, lockGrating, unlockGrating],
        };
    }

    ref() {
        return Grating.spec().ref;
    }

    name(): string {
        return 'grating';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    canBeOpened(): boolean {
        return true;
    }

    nouns() {
        return Grating.spec().nouns;
    }

    adjectives() {
        return Grating.spec().adjectives;
    }

    isLocked(): boolean {
        return this.state.isLocked;
    }

    setIsLocked(isLocked: boolean) {
        this.state.isLocked = isLocked;
    }
}

const openGrating: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(Open) && action.item.is(Grating)) {
        const grating = action.item;
        if (grating.isOpen()) {
            await runner.doOutput(game.choiceOf(DUMMY));
        } else if (grating.isLocked()) {
            await runner.doOutput('The grating is locked.');
        } else {
            grating.setIsOpen(true);
            if (actor?.location()?.is(GratingRoom)) {
                await runner.doOutput(
                    'The grating opens to reveal trees above you.'
                );
                game.ent(PileOfLeaves).setIsMoved(true);
            } else {
                await runner.doOutput('The grating opens.');
            }
        }
        return Action.complete();
    }
};

const closeGrating: Handler = async ({ action, runner, game }) => {
    if (action.is(Close) && action.item.is(Grating)) {
        const grating = action.item;
        if (grating.isOpen()) {
            grating.setIsOpen(false);
            await runner.doOutput('The grating is closed.');
        } else {
            await runner.doOutput(game.choiceOf(DUMMY));
        }
        return Action.complete();
    }
};

const lockGrating: Handler = async ({ action, runner }) => {
    if (
        action.is(Lock) &&
        action.item.is(Grating) &&
        action.tool.is(SkeletonKeys)
    ) {
        const grating = action.item;
        grating.setIsLocked(true);
        await runner.doOutput('The grate is locked.');
        return Action.complete();
    }
};

const unlockGrating: Handler = async ({ action, runner }) => {
    if (action.is(Unlock) && action.item.is(Grating)) {
        const grating = action.item;
        if (action.tool.is(SkeletonKeys)) {
            grating.setIsLocked(false);
            await runner.doOutput('The grate is unlocked.');
        } else if (action.tool.is(RustyKey)) {
            await runner.doOutput('This appears to be the wrong key.');
        } else {
            await runner.doOutput(
                `Can you unlock a grating with ${action.tool.an()}?`
            );
        }
        return Action.complete();
    }
};
