import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import {
    LubricateUnresolved,
    lubricateUnresolvedHandler,
} from './LubricateUnresolved';

export class Lubricate extends Action {
    id = '~lubricate';

    item: Entity;

    tool: Entity;

    constructor({ item, tool }: { item: Entity; tool: Entity }) {
        super();
        this.item = item;
        this.tool = tool;
    }

    static ability(): Ability {
        return {
            handlers: [lubricateHandler, lubricateUnresolvedHandler],
            parser,
            verbs: [new Verb('lubricate'), new Verb('oil'), new Verb('grease')],
            prepositions: [],
        };
    }
}

export const lubricateHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Lubricate)) return;
    await runner.doOutput('You probably put spinach in your gas tank, too.');
    return Action.complete();
};

const parser = (game: Game): Parser<Value, LubricateUnresolved> => {
    const lubricate = Parse.words(Lubricate.ability().verbs);
    const lubricateObject = lubricate.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const lubricateObjectWith = lubricateObject.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.either(Parse.word('with'), Parse.word('using')))
                .after(Parse.whitespace())
        ).map((tool) => [item, tool])
    );
    return Parse.any(
        // lubricate
        lubricate.into(
            new LubricateUnresolved({ item: undefined, tool: undefined })
        ),
        // lubricate box
        lubricateObject.map(
            (item) => new LubricateUnresolved({ item, tool: undefined })
        ),
        // lubricate egg with hammer
        lubricateObjectWith.map(
            ([item, tool]) =>
                new LubricateUnresolved({
                    item,
                    tool,
                })
        )
    );
};
