import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class Garlic extends makeTakeable(Item) {
    static spec(): EntitySpec<Garlic> {
        return {
            ref: 'garlic',
            constructor: Garlic,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('clove of garlic'),
                new Noun('cloves of garlic', { plural: true }),
                new Noun('clove'),
                new Noun('cloves', { plural: true }),
                new Noun('garlic', { collective: true }),
            ],
            adjectives: [
                new Adjective('stinky'),
                new Adjective('smelly'),
                new Adjective('odorous'),
            ],
        };
    }

    ref() {
        return Garlic.spec().ref;
    }

    name() {
        return 'clove of garlic';
    }

    description() {
        return 'There is a clove of garlic here.';
    }

    nouns(): Noun[] {
        return Garlic.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Garlic.spec().adjectives;
    }

    isFood(): boolean {
        return true;
    }
}
