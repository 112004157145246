import { Action, Handler } from '../../game';
import { SpecialTimerTick } from '../../abilities';

const handler: Handler = async ({ action }) => {
    if (!action.is(SpecialTimerTick)) return;
    return Action.complete({ withConsequence: false });
};

export const EndTick = {
    handler: () => handler,
};
