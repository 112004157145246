import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Kill } from './Kill';
import { UnresolvedAction } from '../UnresolvedAction';

export class KillUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'kill';

    weapon: Target | undefined;

    enemy: Target | undefined;

    constructor({
        weapon,
        enemy,
    }: {
        weapon: Target | undefined;
        enemy: Target | undefined;
    }) {
        super();
        this.weapon = weapon;
        this.enemy = enemy;
    }

    description(game: Game) {
        if (this.enemy === undefined) {
            return 'kill someone';
        }
        if (this.weapon === undefined) {
            return `kill ${targetDescription(game, this.enemy)} with something`;
        }
        return `kill ${targetDescription(
            game,
            this.enemy
        )} with ${targetDescription(game, this.weapon)}`;
    }
}

export const putInUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(KillUnresolved) || actor === undefined) return;

    const { item: enemy } = await game.resolve(runner, action.enemy, actor, {
        partial: (enemy) =>
            new KillUnresolved({ weapon: action.weapon, enemy }),
        missing: () => 'Who would you like to kill?',
        ambiguous: (desc, opt) =>
            `Which ${desc} would you like to kill, ${opt}?`,
        condition: (item) => item.isActor(),
    });

    if (enemy === undefined) return Action.complete({ withConsequence: false });

    let weapon;
    const weaponPartial = (weapon: Target) =>
        new KillUnresolved({ weapon, enemy: action.enemy });
    if (action.weapon) {
        const { item: resolvedWeapon } = await game.resolve(
            runner,
            action.weapon,
            actor,
            {
                partial: weaponPartial,
                ambiguous: (desc, opt) =>
                    `With which ${desc} would you like to kill ${enemy.the()}, ${opt}?`,
                condition: (item) =>
                    item.isItem() && item.isWeapon() && actor.hasItem(item),
            }
        );

        if (resolvedWeapon === undefined)
            return Action.complete({ withConsequence: false });

        weapon = resolvedWeapon;

        if (!(await game.reach(runner, weapon, actor))) {
            return Action.complete();
        }

        if (!(await game.have(runner, weapon, actor))) {
            return Action.complete();
        }
    }

    return game.applyAction(runner, new Kill({ enemy, weapon }));
};
