import { Passage, Reservoir, Room, StreamView } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { Boat, Bolt } from '../../items';

export class Stream extends Room {
    static spec(): EntitySpec<Stream> {
        return {
            ref: 'stream',
            constructor: Stream,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Stream.spec().ref;
    }

    name(): string {
        return 'Stream';
    }

    description(): string {
        return (
            'You are on the gently flowing stream. The upstream ' +
            'route is too narrow to navigate and the downstream route is ' +
            'invisible due to twisting walls. There is a narrow beach to land on.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isOnLand(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Up,
                message: 'The way is too narrow.',
            }),
            new Passage({
                via: SpecialDirection.Land,
                to: StreamView.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: Direction.Down,
                to: Reservoir.spec().ref,
                allowedVehicles: this.game.ent(Bolt).isPowered()
                    ? []
                    : [this.game.ent(Boat)],
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
