import { Noun, Adjective } from '../../../parse';
import { makeExaminable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Take } from '../../abilities';
import { FightRemarks, FightResult } from '../../handlers';
import { Troll } from '../../actors';

export class Axe extends makeExaminable(makeTakeable(Item)) {
    static spec(): EntitySpec<Axe> {
        return {
            ref: 'axe',
            constructor: Axe,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [new Noun('axe'), new Noun('axes', { plural: true })],
            adjectives: [new Adjective('bloody')],
            handlers: [handleTakeAxe],
        };
    }

    ref() {
        return Axe.spec().ref;
    }

    name() {
        return 'bloody axe';
    }

    description(): string {
        throw new Error('Should not be described.');
    }

    nouns(): Noun[] {
        return Axe.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Axe.spec().adjectives;
    }

    isTool(): boolean {
        return true;
    }

    isWeapon(): boolean {
        return true;
    }

    fightRemarks(): FightRemarks {
        return TROLL_FIGHT_REMARKS;
    }

    examineText(): string {
        const troll = this.game.ent(Troll);
        if (troll.hasItem(this) && troll.isConscious()) {
            return "You can't seem to get a good look at it with the troll swinging it wildly around.";
        }
        return 'It looks surprisingly dull for how bloody it is.';
    }
}

const handleTakeAxe: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Axe)) {
        await runner.doOutput(
            "The troll's axe seems white-hot. You can't hold on to it."
        );
        return Action.complete();
    }
};

const TROLL_FIGHT_REMARKS: FightRemarks = ({ defendingWeapon }) => ({
    [FightResult.Missed]: [
        'The troll swings his axe, but it misses.',
        "The troll's axe barely misses your ear.",
        'The axe sweeps past as you jump aside.',
        'The axe crashes against the rock, throwing sparks!',
    ],
    [FightResult.Unconscious]: [
        "The flat of the troll's axe hits you delicately on the head, knocking you out.",
    ],
    [FightResult.Killed]: [
        'The troll lands a killing blow. You are dead.',
        'The troll neatly removes your head.',
        "The troll's axe stroke cleaves you from the nave to the chops.",
        "The troll's axe removes your head.",
    ],
    [FightResult.LightWound]: [
        'The axe gets you right in the side. Ouch!',
        "The flat of the troll's axe skins across your forearm.",
        "The troll's swing almost knocks you over as you barely parry in time.",
        'The troll swings his axe, and it nicks your arm as you dodge.',
    ],
    [FightResult.SeriousWound]: [
        `The troll charges, and his axe slashes you on your ${defendingWeapon} arm.`,
        'An axe stroke makes a deep wound in your leg.',
        "The troll's axe swings down, gashing your shoulder.",
        'The troll sees a hole in your defense, and a lightning stroke opens a wound in your left side.',
    ],
    [FightResult.Stun]: [
        'The troll hits you with a glancing blow, and you are momentarily stunned.',
        'The troll swings; the blade turns on your armor but crashes broadside into your head.',
        'You stagger back under a hail of axe strokes.',
        "The troll's mighty blow drops you to your knees.",
    ],
    [FightResult.LostWeapon]: [
        `The axe hits your ${defendingWeapon} and knocks it spinning.`,
        'The troll swings, you parry, but the force of his blow disarms you.',
        `The axe knocks your ${defendingWeapon} out of your hand. It falls to the floor.`,
        `Your ${defendingWeapon} is knocked out of your hands, but you parried the blow.`,
    ],
    [FightResult.Hesitated]: [
        'The troll strikes at your unconscious form, but misses in his rage.',
        'The troll hesitates, fingering his axe.',
        'The troll scratches his head ruminatively: Might you be magically protected, he wonders?',
        'The troll seems afraid to approach your crumpled form.',
    ],
    [FightResult.SittingDuck]: [
        'Conquering his fears, the troll puts you to death.',
    ],
});
