import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { StoneRoom } from '../StoneRoom';

export class TopOfStairs extends Room {
    static spec(): EntitySpec<TopOfStairs> {
        return {
            ref: 'top-of-stairs',
            constructor: TopOfStairs,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    scoreOnEntry(): number {
        return 10;
    }

    ref() {
        return TopOfStairs.spec().ref;
    }

    name(): string {
        return 'Top of Stairs';
    }

    description(): string {
        return (
            'You are standing at the top of a flight of stairs that lead ' +
            'down to a passage below. Dim light, as from torches, can be seen ' +
            'in the passage. Behind you the stairs lead into untouched rock.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.South, Direction.Up],
                message: 'The wall is solid rock',
            }),
            new Passage({
                via: [Direction.North, Direction.Down],
                to: StoneRoom.spec().ref,
            }),
        ];
    }
}

// TODO maybe torches? as a visibleObject?
