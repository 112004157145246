import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeFlammable, makeVehicle } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Geronimo, Go, Light, Look, SpecialJigsUp } from '../../abilities';

export class Barrel extends makeVehicle(makeContainer(makeFlammable(Item))) {
    static spec(): EntitySpec<Barrel> {
        return {
            ref: 'barrel',
            constructor: Barrel,
            initial: {
                isAflame: false,
                contents: [],
            },
            nouns: [new Noun('barrel'), new Noun('barrels', { plural: true })],
            adjectives: [
                new Adjective('man-sized'),
                new Adjective('large'),
                new Adjective('huge'),
                new Adjective('wood'),
                new Adjective('damp'),
                new Adjective('wooden'),
            ],
            handlers: [lookInBarrel, goInBarrel, burnBarrel, geronimoBarrel],
        };
    }

    ref() {
        return Barrel.spec().ref;
    }

    name(): string {
        return 'wooden barrel';
    }

    description(): string {
        return 'There is a man-sized barrel here which you might be able to enter.';
    }

    totalCapacity(): number {
        return 100;
    }

    nouns() {
        return Barrel.spec().nouns;
    }

    adjectives() {
        return Barrel.spec().adjectives;
    }
}

const lookInBarrel: Handler = async ({ action, runner, actor }) => {
    if (action.is(Look) && actor?.vehicle()?.is(Barrel)) {
        await runner.doOutput(
            'You are inside a barrel. Congratulations. ' +
                "Etched into the side of the barrel is the word 'Geronimo!'. " +
                'From your position, you cannot see the falls.'
        );
        return Action.complete();
    }
};

const goInBarrel: Handler = async ({ action, runner, actor }) => {
    if (action.is(Go) && actor?.vehicle()?.is(Barrel)) {
        await runner.doOutput('You cannot move the barrel.');
        return Action.complete();
    }
};

const burnBarrel: Handler = async ({ action, runner }) => {
    if (
        action.is(Light) &&
        action.item.is(Barrel) &&
        action.tool?.isItem() &&
        action.tool.isFlammable() &&
        action.tool.isAflame()
    ) {
        await runner.doOutput('The barrel is damp and cannot be burned.');
        return Action.complete();
    }
};

const geronimoBarrel: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Geronimo) && actor?.vehicle()?.is(Barrel)) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({
                message:
                    "I didn't think you would REALLY try to go over the " +
                    'falls in a barrel. It seems that some 450 feet below, ' +
                    'you were met by a number of unfriendly rocks and boulders, ' +
                    "causing your immediate demise. Is this what 'over a barrel' means?",
            })
        );
        return Action.complete();
    }
};
