import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeTreasure, makeReadable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class GoldCard extends makeReadable(makeTreasure(makeTakeable(Item))) {
    static spec(): EntitySpec<GoldCard> {
        return {
            ref: 'gold-card',
            constructor: GoldCard,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('card'),
                new Noun('pass'),
                new Noun('cards', { plural: true }),
                new Noun('passes', { plural: true }),
            ],
            adjectives: [
                new Adjective('solid gold'),
                new Adjective('solid-gold'),
                new Adjective('gold'),
                new Adjective('golden'),
                new Adjective('engraved'),
            ],
        };
    }

    ref() {
        return GoldCard.spec().ref;
    }

    name() {
        return 'gold card';
    }

    description() {
        return 'There is a solid gold engraved card here.';
    }

    initialDescription() {
        return 'Nestled inside the niche is an engraved gold card.';
    }

    size() {
        return 4;
    }

    scoreOnTake(): number {
        return 10;
    }

    scoreInCase(): number {
        return 15;
    }

    nouns(): Noun[] {
        return GoldCard.spec().nouns;
    }

    adjectives(): Adjective[] {
        return GoldCard.spec().adjectives;
    }

    text(): string {
        return GOLD_CARD_TEXT;
    }
}

const GOLD_CARD_TEXT = ` ------------------------------------------------------------
|                                                             |
|                           FROBOZZ MAGIC SECURITY SYSTEMS    |
|    Door Pass                    Royal Zork Puzzle Museum    |
|                                                             |
|                     #632-988-496-XTHF                       |
|                                                             |
|                                                             |
|     USE OF THIS PASS BY UNAUTHORIZED PERSONS OR AFTER       |
|   EXPIRATION DATE WILL RESULT IN IMMEDIATE CONFISCATION     |
|                                                             |
|                                                             |
|                                    (approved)               |
|                                     Will Weng               |
|                                     789 G.U.E.              |
|                                                             |
|                                        Expires 792 G.U.E.   |
 -------------------------------------------------------------`;
