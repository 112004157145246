import { State } from './State';

export enum LoadError {
    NotFound = 'not-found',
    Invalid = 'invalid',
}

export interface Runner {
    doOutput: (
        message: string,
        options?: { newLine?: boolean; isDimmed?: boolean }
    ) => Promise<boolean>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    doDebug: (...args: any[]) => Promise<boolean>;
    saveState: (state: State, name: string | undefined) => Promise<boolean>;
    loadState: (name: string | undefined) => Promise<State | LoadError>;
    quit: () => Promise<void>;
    restart: () => Promise<void>;
    userName: () => string;
}
