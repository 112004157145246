import { Group } from './Group';
import { Obj } from './Obj';
import { Pronoun } from './lexicon';

export class Target {
    items: (Group | Obj | Pronoun)[];

    constructor({ items }: { items: (Group | Obj | Pronoun)[] }) {
        this.items = items;
    }
}
