import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Treasure extends makeDescribable(Action) {
    id = 'treasure';

    description(game: Game): string {
        return 'mention treasure';
    }

    static ability(): Ability {
        const verbs = [new Verb('treasure'), new Verb('say treasure')];
        return {
            handlers: [treasureHandler],
            // TODO 'say "treasure"'
            // TODO maybe change the method of teleporting between treasure and temple room
            //      to something more like `incant "treasure room"`?
            parser: () => Parse.words(verbs).into(new Treasure()),
            verbs,
            prepositions: [],
        };
    }
}

export const treasureHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Treasure)) return;
    await runner.doOutput('Nothing happens.');
    return Action.complete();
};
