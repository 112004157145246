import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { StoneRoom } from '../../rooms';
import { goToEndgame } from '../../rooms/Crypt';

export class Incant extends makeDescribable(Action) {
    id = 'incant';

    spell: string | undefined;

    constructor({ spell }: { spell: string | undefined }) {
        super();
        this.spell = spell;
    }

    description(game: Game): string {
        return 'incant a spell';
    }

    static ability(): Ability {
        const verbs = [new Verb('incant')];
        return {
            handlers: [incantHandler],
            parser: () =>
                Parse.words(verbs)
                    .beforeX(
                        Parse.option(
                            Parse.whitespace()
                                .before(Parse.quote())
                                .beforeX(Parse.string())
                                .before(Parse.quote())
                        )
                    )
                    .map((spell) => new Incant({ spell })),
            verbs,
            prepositions: [],
        };
    }
}

export const incantHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Incant)) return;
    const { spell } = action;

    if (game.endgameSpell() || game.ent(StoneRoom).hasBeenVisited()) {
        await runner.doOutput(
            'Incantations are useless once you have gotten this far.'
        );
    } else {
        const words = (spell || '').split(' ');
        if (!spell || words.length === 0) {
            await runner.doOutput(
                'That incantation seems to have been a failure.'
            );
        } else if (words.length < 2) {
            const thisSpell = words[0];
            const previousSpell = game.endgameSpell();
            if (previousSpell && thisSpell !== previousSpell) {
                await runner.doOutput(
                    'Sorry, only one incantation to a customer.'
                );
            } else if (!game.isInEndgame()) {
                await runner.doOutput('That spell has no obvious effect.');
            } else {
                const nextSpell = password(runner.userName(), thisSpell);
                game.setEndgameSpell(thisSpell);
                await runner.doOutput(
                    `A hollow voice replies: "${thisSpell.toUpperCase()} ${nextSpell}".`
                );
            }
        } else if (
            password(runner.userName(), words[0]) === words[1].toUpperCase()
        ) {
            game.setEndgameSpell(words[0]);
            await runner.doOutput(
                'As the last syllable of your spell fades into silence, ' +
                    'darkness envelops you, and the earth shakes briefly. ' +
                    'Then all is quiet.'
            );
            await goToEndgame(game, runner);
        } else {
            await runner.doOutput(
                "That spell doesn't appear to have done anything useful."
            );
        }
    }
    return Action.complete({ withConsequence: false });
};

function password(userName: string, phrase: string) {
    const pairs = [];
    for (let i = 0; i < 5; i++) {
        pairs.push({
            k: phrase.charCodeAt(i % phrase.length) - 64,
            s: userName.charCodeAt(i % userName.length) - 64,
        });
    }
    let uSum =
        (sum(pairs.map((pair) => pair.s)) % 8) +
        8 * (sum(pairs.map((pair) => pair.k)) % 8);
    const result = [];
    for (const pair of pairs) {
        const { s, k } = pair;
        // eslint-disable-next-line no-bitwise
        let v = (s ^ k ^ uSum) & 31;
        uSum = (uSum + 1) % 32;
        if (v > 26) v %= 26;
        if (v === 0) v = 1;
        result.push(String.fromCharCode(64 + v));
    }
    return result.join('');
}

const sum = (x: number[]) => x.reduce((a, b) => a + b, 0);
