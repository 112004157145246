import { Direction } from '../../../parse';
import { Passage, Room } from '..';
import { Action, EntitySpec, Handler } from '../../game';
import { Forest3 } from '../Forest3';
import { Egg, Nest, Tree } from '../../items';
import { Drop, SpecialListContents, SpecialLook } from '../../abilities';

export class UpATree extends Room {
    static spec(): EntitySpec<UpATree> {
        return {
            ref: 'up-a-tree',
            constructor: UpATree,
            initial: {
                contents: [Tree.spec().ref, Nest.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [dropFromUpATree, describeUpATree],
        };
    }

    ref() {
        return UpATree.spec().ref;
    }

    name(): string {
        return 'Up a Tree';
    }

    description(): string {
        return (
            'You are about ten feet above the ground nestled among some ' +
            'large branches. The nearest branch above you is beyond your reach.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Up,
                message: 'You cannot climb any higher.',
            }),
            new Passage({ via: Direction.Down, to: Forest3.spec().ref }),
        ];
    }

    isPartOfForest() {
        return true;
    }

    hasWalls() {
        return false;
    }

    visibleObjects() {
        return [
            ...super.visibleObjects(),
            ...this.game
                .ent(Forest3)
                .contents()
                .filter((item) => !item.is(Tree)),
        ];
    }
}

const dropFromUpATree: Handler = async ({ action, runner, game, actor }) => {
    if (!action.is(Drop) || !actor) return;
    const room = game.locateEntity(actor);
    if (!room?.is(UpATree)) return;

    const { item } = action;

    if (actor.contains(item)) {
        item.moveTo(game.ent(Forest3));
        if (item.is(Egg)) {
            item.state.isBroken = true;
            await runner.doOutput(
                'The egg falls to the ground, and is seriously damaged.'
            );
        } else {
            await runner.doOutput(`${item.The()} falls to the ground.`);
        }
    } else {
        await runner.doOutput(`You do not have the ${item}.`);
    }

    return Action.complete();
};

const describeUpATree: Handler = async ({
    action,
    runner,
    game,
    actor,
    extensions,
}) => {
    if (action.is(SpecialLook) && actor?.location()?.is(UpATree)) {
        await extensions.deferHandling();
        await game.applyAction(
            runner,
            new SpecialListContents({
                item: game.ent(Forest3),
                leadIn: 'On the ground below you can see:',
            })
        );
        return Action.complete({ withConsequence: false });
    }
};
