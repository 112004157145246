import { Noun, Adjective } from '../../../parse';
import { makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Take } from '../../abilities';
import { Player } from '../../actors';
import { LandOfTheLivingDead, Maze5 } from '../../rooms';

export class Skeleton extends makeTakeable(Item) {
    static spec(): EntitySpec<Skeleton> {
        return {
            ref: 'skeleton',
            constructor: Skeleton,
            initial: {
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('bones', { collective: true }),
                new Noun('skeleton'),
                new Noun('skeletons', { plural: true }),
                new Noun('body'),
                new Noun('bodies', { plural: true }),
                new Noun('remains', { collective: true }),
                new Noun('adventurer'),
            ],
            adjectives: [new Adjective('fallen')],
            handlers: [takeSkeleton],
        };
    }

    ref() {
        return Skeleton.spec().ref;
    }

    name() {
        return 'skeleton';
    }

    description() {
        return 'A skeleton, probably the remains of a luckless adventurer, lies here.';
    }

    nouns(): Noun[] {
        return Skeleton.spec().nouns;
    }

    adjectives(): Adjective[] {
        return Skeleton.spec().adjectives;
    }
}

const takeSkeleton: Handler = async ({ action, runner, game }) => {
    if (action.is(Take) && action.item.is(Skeleton)) {
        await runner.doOutput(
            'A ghost appears in the room and is appalled at your having ' +
                'desecrated the remains of a fellow adventurer. He casts a curse ' +
                'on all of your valuables and orders them banished to the Land of ' +
                'the Living Dead. The ghost leaves, muttering obscenities.'
        );
        const player = game.ent(Player);
        const room = game.ent(Maze5);
        const hell = game.ent(LandOfTheLivingDead);
        for (const item of [...player.inventory(), ...room.contents()]) {
            if (item.isItem() && item.isTreasure()) {
                item.moveTo(hell);
            }
        }
        return Action.complete();
    }
};
