import { Target } from '../../../parse';
import { Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { targetDescription } from '../../utils';
import { makeDescribable } from '../../game/Action';
import { Board } from './Board';
import { UnresolvedAction } from '../UnresolvedAction';

export class BoardUnresolved extends makeDescribable(UnresolvedAction) {
    id = 'board';

    vehicle: Target | undefined;

    constructor({ vehicle }: { vehicle: Target | undefined }) {
        super();
        this.vehicle = vehicle;
    }

    description(game: Game) {
        if (this.vehicle === undefined) {
            return 'get in something';
        }
        return `get in ${targetDescription(game, this.vehicle)}`;
    }
}

export const boardUnresolvedHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(BoardUnresolved)) return;

    const { item: vehicle } = await game.resolve(
        runner,
        action.vehicle,
        actor,
        {
            partial: (vehicle) => new BoardUnresolved({ vehicle }),
            missing: () => 'What would you like to board?',
            ambiguous: (desc, opt) =>
                `Which ${desc} would you like to board, ${opt}?`,
            condition: (vehicle) => vehicle.isItem() && vehicle.isVehicle(),
        }
    );

    // TODO if there's only one vehicle, select it

    if (vehicle === undefined)
        return Action.complete({ withConsequence: false });

    if (!(await game.reach(runner, vehicle, actor))) return Action.complete();

    return await game.applyAction(runner, new Board({ vehicle }));
};
