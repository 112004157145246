import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction } from '../../../parse';
import { PearlNecklace } from '../../items';
import { CircularRoom } from '../CircularRoom';
import { RiddleRoom } from '../RiddleRoom';

export class PearlRoom extends Room {
    static spec(): EntitySpec<PearlRoom> {
        return {
            ref: 'pearl-room',
            constructor: PearlRoom,
            initial: {
                contents: [PearlNecklace.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return PearlRoom.spec().ref;
    }

    name(): string {
        return 'Pearl Room';
    }

    description(): string {
        return 'This is a former broom closet. The exits are to the east and west.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: RiddleRoom.spec().ref }),
            new Passage({
                via: Direction.East,
                to: CircularRoom.spec().ref,
            }),
        ];
    }
}
