import { Reference, Entity } from '../game';
import { FightRemarks } from '../handlers';
import { Item, Vehicle } from '../items/Item';

export interface ActorState {
    isLucky: boolean;
    inventory: Reference[];
    isConscious: boolean;
    isFighting: boolean;
    isStunned: boolean;
    isAlive: boolean;
    reviveChance: number | undefined;
    strength: number;
    isEngrossed: boolean;
}

export abstract class Actor<
    S extends ActorState = ActorState
> extends Entity<S> {
    isActor() {
        return true;
    }

    isVillain() {
        return false;
    }

    isVictim() {
        return false;
    }

    vehicle(): (Item & Vehicle) | undefined {
        const place = this.container();
        if (place?.isItem() && place.isVehicle()) {
            return place;
        }
        return undefined;
    }

    testLuck(goodLuck: number, badLuck?: number) {
        const random = Math.floor(this.game.random() * 100);
        if (this.state.isLucky || badLuck === undefined) {
            return random < goodLuck;
        }
        return random < badLuck;
    }

    hasItem(item: Entity): boolean {
        const container = item.container();
        return (
            container !== undefined &&
            (this.isEqualTo(container) || this.hasItem(container))
        );
    }

    removeEntity(entity: Entity) {
        const index = this.state.inventory.findIndex(
            (ref) => entity.ref() === ref
        );
        if (index !== undefined) {
            this.state.inventory.splice(index, 1);
        }
    }

    addEntity(entity: Entity) {
        this.state.inventory.push(entity.ref());
    }

    inventory() {
        return this.state.inventory.map((ref) => this.get(ref));
    }

    contents() {
        return this.inventory();
    }

    contains(item: Entity) {
        return this.state.inventory.includes(item.ref());
    }

    location() {
        return this.game.locateEntity(this);
    }

    maximumLoad(): number {
        return 100;
    }

    loadWeight(): number {
        return this.inventory().reduce(
            (total, item) => total + (item.isItem() ? item.size() : 0),
            0
        );
    }

    remainingLoad() {
        return this.maximumLoad() - this.loadWeight();
    }

    isEngrossed() {
        return this.state.isEngrossed;
    }

    setIsEngrossed(isEngrossed: boolean) {
        this.state.isEngrossed = isEngrossed;
    }

    canReachItem(entity: Entity): boolean {
        let place = this.container();
        while (
            place &&
            place.isItem() &&
            place.isContainer() &&
            place.canReachInto()
        ) {
            place = place.container();
        }
        if (place === undefined) {
            throw new Error('Expected actor to be in a room.');
        }
        let placesToCheck: Entity[] = [place, this];
        while (placesToCheck.length > 0) {
            const place = placesToCheck.pop();
            if (place) {
                if (place.isEqualTo(entity)) {
                    return true;
                }
                if (
                    place.isActor() ||
                    place.isRoom() ||
                    (place.isItem() &&
                        place.isContainer() &&
                        (!place.isOpenable() || place.isOpen()))
                ) {
                    const contents = place
                        .contents()
                        .filter(
                            (inner) =>
                                !this.isEqualTo(inner) &&
                                (!inner.isItem() || !inner.isHidden())
                        );
                    if (place.isRoom()) contents.push(...place.globalObjects());
                    placesToCheck = placesToCheck.concat(contents.slice());
                }
            }
        }
        return false;
    }

    visibleEntities(): Entity[] {
        const room = this.game.locateEntity(this);
        if (room === undefined) {
            throw new Error('Expected actor to be in a room.');
        }
        let placesToCheck: Entity[] = [room, this];
        let matches: Entity[] = [this];
        while (placesToCheck.length > 0) {
            const place = placesToCheck.pop();
            if (
                place &&
                (this.isEqualTo(place) || room.isLit() || !this.isAlive()) &&
                (place.isActor() ||
                    place.isRoom() ||
                    (place.isItem() &&
                        place.isContainer() &&
                        place.canSeeInto()))
            ) {
                const contents = place
                    .contents()
                    .filter(
                        (inner) =>
                            !this.isEqualTo(inner) &&
                            (!inner.isItem() || !inner.isHidden())
                    );
                if (place.isRoom()) contents.push(...place.globalObjects());
                // TODO should this instead be "mentionable" items? for things that are visible but can't see contents?
                if (place.isRoom()) matches.push(...place.visibleObjects());
                placesToCheck = placesToCheck.concat(contents.slice());
                matches = matches.concat(contents.slice());
            }
        }

        return matches;
    }

    isConscious(): boolean {
        return this.state.isConscious;
    }

    setIsConscious(isConscious: boolean) {
        this.state.isConscious = isConscious;
    }

    isAlive(): boolean {
        return this.state.isAlive;
    }

    setIsAlive(isAlive: boolean) {
        this.state.isAlive = isAlive;
    }

    isStunned(): boolean {
        return this.state.isStunned;
    }

    isFighting(): boolean {
        return this.state.isFighting;
    }

    setIsFighting(isFighting: boolean) {
        this.state.isFighting = isFighting;
    }

    isLucky(): boolean {
        return this.state.isLucky;
    }

    reviveChance(): number | undefined {
        return this.state.reviveChance;
    }

    strength(): number {
        return this.state.strength;
    }

    setStrength(strength: number) {
        this.state.strength = strength;
    }

    bestWeapon(): Item | undefined {
        return undefined;
    }

    abstract isReadyToFight(): boolean;

    fightRemarks(): FightRemarks | undefined {
        return undefined;
    }

    shouldAttackFirst(): boolean {
        return false;
    }

    respondsToCommands(): boolean {
        return false;
    }

    isEmptyHanded(): boolean {
        return this.inventory().length === 0;
    }
}
