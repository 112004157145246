import { Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import {
    Count,
    Light,
    LookUnder,
    Move,
    SpecialJigsUp,
    Take,
} from '../../abilities';
import { Runner } from '../../game/Runner';
import { Game } from '../../game/game';
import { makeFlammable, makeTakeable } from '../../game/Entity';

interface PileOfLeavesState extends ItemState {
    isMoved: boolean;
}

abstract class Base extends Item<PileOfLeavesState> {}

export class PileOfLeaves extends makeFlammable(makeTakeable(Base)) {
    static spec(): EntitySpec<PileOfLeaves> {
        return {
            ref: 'pile-of-leaves',
            constructor: PileOfLeaves,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
                isMoved: false,
            },
            nouns: [
                new Noun('leaves', { collective: true }),
                new Noun('pile of leaves'),
                new Noun('piles of leaves', { collective: true }),
                new Noun('pile'),
                new Noun('piles', { collective: true }),
            ],
            adjectives: [],
            handlers: [
                takeLeaves,
                lookUnderLeaves,
                moveLeaves,
                burnLeaves,
                countLeaves,
            ],
        };
    }

    size(): number {
        return 25;
    }

    setIsMoved(isMoved: boolean) {
        this.state.isMoved = isMoved;
    }

    isFlammable(): boolean {
        return true;
    }

    ref() {
        return PileOfLeaves.spec().ref;
    }

    name(): string {
        return 'pile of leaves';
    }

    description(): string {
        return 'There is a pile of leaves on the ground.';
    }

    nouns() {
        return PileOfLeaves.spec().nouns;
    }

    adjectives() {
        return PileOfLeaves.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    isMoved(): boolean {
        return this.state.isMoved;
    }

    canBeMoved(): boolean {
        return true;
    }
}

async function revealGrate(game: Game, runner: Runner) {
    const leaves = game.ent(PileOfLeaves);
    leaves.state.isMoved = true;
    await runner.doOutput('A grating appears on the ground.');
}

const takeLeaves: Handler = async ({
    action,
    runner,
    game,
    extensions,
    actor,
}) => {
    const leaves = game.ent(PileOfLeaves);
    if (
        action.is(Take) &&
        action.item.is(PileOfLeaves) &&
        !game.ent(PileOfLeaves).isMoved()
    ) {
        await extensions.deferHandling();
        if (actor?.hasItem(leaves)) {
            await revealGrate(game, runner);
        }
        return Action.complete();
    }
};

const lookUnderLeaves: Handler = async ({ action, runner, game }) => {
    if (
        action.is(LookUnder) &&
        action.item.is(PileOfLeaves) &&
        !game.ent(PileOfLeaves).isMoved()
    ) {
        await runner.doOutput('Underneath the pile of leaves is a grating.');
        return Action.complete();
    }
};

const moveLeaves: Handler = async ({ action, game, runner }) => {
    if (
        action.is(Move) &&
        action.item.is(PileOfLeaves) &&
        !game.ent(PileOfLeaves).isMoved()
    ) {
        await runner.doOutput('Done.');
        await revealGrate(game, runner);
        return Action.complete();
    }
};

// TODO handle if the leaves are put in the balloon receptacle and burned...
const burnLeaves: Handler = async ({ action, game, runner, actor }) => {
    if (
        action.is(Light) &&
        action.item.is(PileOfLeaves) &&
        action.tool?.isItem() &&
        action.tool.isFlammable() &&
        action.tool.isAflame()
    ) {
        const { item: leaves } = action;
        if (actor?.hasItem(leaves)) {
            leaves.moveTo(actor?.location());
            await game.applyAction(
                runner,
                new SpecialJigsUp({
                    message:
                        'The sight of someone carrying a pile of burning leaves ' +
                        'so offends the neighbors that they come over and put you out.',
                })
            );
        } else {
            leaves.moveTo(undefined);
            await runner.doOutput(
                'The leaves burn and the neighbors start to complain.'
            );
            await revealGrate(game, runner);
        }
        return Action.complete();
    }
};

const countLeaves: Handler = async ({ action, runner }) => {
    if (action.is(Count) && action.item?.is(PileOfLeaves)) {
        await runner.doOutput('There are 69,105 leaves here.');
        return Action.complete();
    }
};
