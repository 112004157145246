import { Adjective, Direction, Noun } from '../../../parse';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Lower, Push, Raise } from '../../abilities';
import { Hallway2, InsideMirror } from '../../rooms';

enum MirrorPolePosition {
    Up = 'up',
    Down = 'down',
    Middle = 'middle',
}

interface MirrorPoleState extends ItemState {
    position: MirrorPolePosition;
}

export class ShortPole extends Item<MirrorPoleState> {
    static spec(): EntitySpec<ShortPole> {
        return {
            ref: 'short-pole',
            constructor: ShortPole,
            initial: {
                position: MirrorPolePosition.Down,
            },
            nouns: [new Noun('pole'), new Noun('poles', { plural: true })],
            adjectives: [new Adjective('short')],
            handlers: [raisePole, lowerPole],
        };
    }

    ref() {
        return ShortPole.spec().ref;
    }

    name(): string {
        return 'short pole';
    }

    description(): string {
        return '';
    }

    nouns() {
        return ShortPole.spec().nouns;
    }

    adjectives() {
        return ShortPole.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }

    position() {
        return this.state.position;
    }

    setPosition(position: MirrorPolePosition) {
        this.state.position = position;
    }

    isDown() {
        return this.state.position === MirrorPolePosition.Down;
    }

    isRaised() {
        return this.isUp() || this.isMiddle();
    }

    isUp() {
        return this.state.position === MirrorPolePosition.Up;
    }

    isMiddle() {
        return this.state.position === MirrorPolePosition.Middle;
    }
}

const raisePole: Handler = async ({ action, runner }) => {
    if (action.is(Raise) && action.item.is(ShortPole)) {
        const { item: pole } = action;
        if (pole.isUp()) {
            await runner.doOutput('The pole cannot be raised further.');
        } else {
            pole.setPosition(MirrorPolePosition.Up);
            await runner.doOutput('The pole is now slightly above the floor.');
        }
        return Action.complete();
    }
};

const lowerPole: Handler = async ({ action, runner, game }) => {
    if ((action.is(Lower) || action.is(Push)) && action.item.is(ShortPole)) {
        const { item: pole } = action;
        const mirror = game.ent(InsideMirror);
        const direction = mirror.orientation();
        if (pole.isDown()) {
            await runner.doOutput('The pole cannot be lowered further.');
        } else if (
            direction === Direction.South ||
            direction === Direction.North
        ) {
            pole.setPosition(MirrorPolePosition.Down);
            await runner.doOutput('The pole is lowered into the channel.');
        } else if (
            direction === Direction.West &&
            mirror.position().is(Hallway2)
        ) {
            pole.setPosition(MirrorPolePosition.Down);
            await runner.doOutput('The pole is lowered into the stone hole.');
        } else if (pole.isMiddle()) {
            await runner.doOutput('The pole is already resting on the floor.');
        } else {
            pole.setPosition(MirrorPolePosition.Middle);
            await runner.doOutput('The pole now rests on the stone floor.');
        }
        return Action.complete();
    }
};
