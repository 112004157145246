import { Verb, Parse, Parser, Value } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { SetToUnresolved, setToUnresolvedHandler } from './SetToUnresolved';

export class SetTo extends Action {
    id = '~set-to';

    item: Entity;

    setting: string | undefined;

    constructor({
        item,
        setting,
    }: {
        item: Entity;
        setting: string | undefined;
    }) {
        super();
        this.item = item;
        this.setting = setting;
    }

    static ability(): Ability {
        return {
            handlers: [setToHandler, setToUnresolvedHandler],
            parser,
            verbs: [
                new Verb('set'),
                new Verb('turn'),
                new Verb('move'),
                new Verb('put'),
                new Verb('spin'),
            ],
            prepositions: [],
        };
    }
}

export const setToHandler: Handler = async ({ action, runner }) => {
    if (!action.is(SetTo)) return;
    await runner.doOutput('That cannot be turned.');
    return Action.complete();
};

const parser = (game: Game): Parser<Value, SetToUnresolved> => {
    const set = Parse.words(SetTo.ability().verbs);
    const setObject = set.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const setObjectTo = setObject.chain((item) =>
        Parse.whitespace()
            .before(Parse.word('to'))
            .before(Parse.whitespace())
            .beforeX(
                Parse.either(
                    Parse.quote().beforeX(Parse.string()).before(Parse.quote()),
                    Parse.string()
                )
            )
            .map((setting) => ({ item, setting }))
    );
    return Parse.any(
        // set
        set.into(new SetToUnresolved({ item: undefined, setting: undefined })),
        // setTo button
        setObject.map(
            (item) => new SetToUnresolved({ item, setting: undefined })
        ),
        // set dial to 5 / set dial to "five"
        setObjectTo.map(
            ({ item, setting }) => new SetToUnresolved({ item, setting })
        )
    );
};
