import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Mung, Open, Poke, Push } from '../../abilities';
import { Mirror2 } from '../Mirror2';

export class MirrorPanel2 extends Item {
    static spec(): EntitySpec<MirrorPanel2> {
        return {
            ref: 'mirror-panel-2',
            constructor: MirrorPanel2,
            initial: {},
            nouns: [new Noun('panel'), new Noun('panels', { plural: true })],
            adjectives: [new Adjective('wood'), new Adjective('wooden')],
            handlers: [pushMirrorPanel, openMirror, breakPanel],
        };
    }

    ref() {
        return MirrorPanel2.spec().ref;
    }

    name(): string {
        return 'wooden panel';
    }

    description(): string {
        return '';
    }

    nouns() {
        return MirrorPanel2.spec().nouns;
    }

    adjectives() {
        return MirrorPanel2.spec().adjectives;
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    shouldTryToTake(): boolean {
        return false;
    }
}

const pushMirrorPanel: Handler = async ({ action, runner }) => {
    if (action.is(Push) && action.item.is(MirrorPanel2)) {
        await runner.doOutput('The panel is unyielding.');
        return Action.complete();
    }
};

const openMirror: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(MirrorPanel2)) {
        await runner.doOutput("I don't see a way to open the panel here.");
        return Action.complete();
    }
};

const breakPanel: Handler = async ({ action, runner, game }) => {
    if (
        (action.is(Poke) && action.enemy.is(MirrorPanel2)) ||
        (action.is(Mung) && action.item.is(MirrorPanel2))
    ) {
        const mirror = game.ent(Mirror2);
        if (mirror.isMunged()) {
            await runner.doOutput('The panel is not that easily destroyed.');
        } else {
            await runner.doOutput(
                'To break the panel you would have to break the mirror first.'
            );
        }
        return Action.complete();
    }
};
