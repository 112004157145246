import { Verb, Parse, Parser, Value } from '../../../parse';
import { HO_HUM } from '../../constants';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { RaiseUnresolved, raiseUnresolvedHandler } from './RaiseUnresolved';

export class Raise extends Action {
    id = '~raise';

    item: Entity;

    constructor({ item }: { item: Entity }) {
        super();
        this.item = item;
    }

    static ability(): Ability {
        return {
            handlers: [raiseHandler, raiseUnresolvedHandler],
            parser,
            verbs: [
                new Verb('lift'),
                new Verb('raise'),
                new Verb('lift up'),
                new Verb('heave'),
                new Verb('hoist'),
            ],
            prepositions: [],
        };
    }
}

export const raiseHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Raise)) return undefined;
    const { item } = action;
    await runner.doOutput(
        `Playing in this way with ${item.an()} ${game.choiceOf(HO_HUM)}`
    );
    return Action.complete();
};

const parser = (game: Game): Parser<Value, RaiseUnresolved> =>
    Parse.words(Raise.ability().verbs)
        .before(Parse.whitespace())
        .chain((_verb) =>
            Parse.target(game.lexicon).map(
                (item) => new RaiseUnresolved({ item })
            )
        );
