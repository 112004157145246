import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Geronimo extends makeDescribable(Action) {
    id = 'geronimo';

    description(game: Game): string {
        return 'say Geronimo';
    }

    static ability(): Ability {
        const verbs = [new Verb('geronimo'), new Verb('say geronimo')];
        return {
            handlers: [geronimoHandler],
            parser: () => Parse.words(verbs).into(new Geronimo()),
            verbs,
            prepositions: [],
        };
    }
}

export const geronimoHandler: Handler = async ({ action, runner }) => {
    if (!action.is(Geronimo)) return;
    await runner.doOutput("Wasn't he an Apache?");
    return Action.complete();
};
