import { Verb, Parse, Parser, Value, SpecialDirection } from '../../../parse';
import { Ability, Action, Handler, Entity } from '../../game';
import { Game } from '../../game/game';
import { LandUnresolved, landUnresolvedHandler } from './LandUnresolved';
import { Player } from '../../actors';
import { Go } from '..';

export class Land extends Action {
    id = '~land';

    vehicle: Entity;

    constructor({ vehicle }: { vehicle: Entity }) {
        super();
        this.vehicle = vehicle;
    }

    static ability(): Ability {
        return {
            handlers: [landHandler, landUnresolvedHandler],
            parser,
            verbs: [new Verb('land')],
            prepositions: [],
        };
    }
}

export const landHandler: Handler = async ({ action, runner, game }) => {
    if (!action.is(Land)) return;
    const { vehicle } = action;
    const player = game.ent(Player);

    if (
        !vehicle.isItem() ||
        !vehicle.isVehicle() ||
        !vehicle.contains(player)
    ) {
        await runner.doOutput(`You are not in ${vehicle.the()}.`);
    } else {
        await game.applyAction(
            runner,
            new Go({ direction: SpecialDirection.Land })
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, LandUnresolved> => {
    const land = Parse.words(Land.ability().verbs);
    const landObject = land.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    return Parse.any(
        // land
        land.into(new LandUnresolved({ vehicle: undefined })),
        // land boat
        landObject.map((vehicle) => new LandUnresolved({ vehicle }))
    );
};
