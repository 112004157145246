import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { AtticTable, Brick, Knife, Rope } from '../../items';
import { Kitchen } from '../Kitchen';

export class Attic extends Room {
    static spec(): EntitySpec<Attic> {
        return {
            ref: 'attic',
            constructor: Attic,
            initial: {
                contents: [
                    Knife.spec().ref,
                    Rope.spec().ref,
                    Brick.spec().ref,
                    AtticTable.spec().ref,
                    // TODO dark staircase leading up
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return Attic.spec().ref;
    }

    name(): string {
        return 'Attic';
    }

    description(): string {
        return 'This is the attic. The only exit is stairs that lead down.';
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [new Passage({ via: Direction.Down, to: Kitchen.spec().ref })];
    }

    isPartOfHouse(): boolean {
        return true;
    }
}
