import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { MINE_DESCRIPTION } from '../../constants';
import { EntitySpec } from '../../game';
import { CoalMine1 } from '../CoalMine1';
import { CoalMine2 } from '../CoalMine2';
import { CoalMine5 } from '../CoalMine5';
import { CoalMine6 } from '../CoalMine6';

export class CoalMine4 extends Room {
    static spec(): EntitySpec<CoalMine4> {
        return {
            ref: 'coal-mine-4',
            constructor: CoalMine4,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return CoalMine4.spec().ref;
    }

    name(): string {
        return 'Coal Mine';
    }

    description(): string {
        return MINE_DESCRIPTION;
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.Up, to: CoalMine5.spec().ref }),
            new Passage({ via: Direction.Northeast, to: CoalMine6.spec().ref }),
            new Passage({ via: Direction.South, to: CoalMine1.spec().ref }),
            new Passage({ via: Direction.West, to: CoalMine2.spec().ref }),
        ];
    }
}
