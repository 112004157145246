import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeContainer, makeOpenable } from '../../game/Entity';
import { Action, EntitySpec, Handler } from '../../game';
import { Close, Open, Take } from '../../abilities';
import { Crown } from '../Crown';
import { WarningCard } from '../WarningCard';

export class Safe extends makeOpenable(makeContainer(Item)) {
    static spec(): EntitySpec<Safe> {
        return {
            ref: 'safe',
            constructor: Safe,
            initial: {
                contents: [Crown.spec().ref, WarningCard.spec().ref],
                isOpen: false,
            },
            nouns: [
                new Noun('safe'),
                new Noun('safe box'),
                new Noun('box'),
                new Noun('safes', { plural: true }),
                new Noun('safe boxes', { plural: true }),
            ],
            adjectives: [new Adjective('steel')],
            handlers: [takeBox, openBox, closeBox],
        };
    }

    ref() {
        return Safe.spec().ref;
    }

    name(): string {
        return 'mailbox';
    }

    description(): string {
        if (this.isOpen()) {
            return 'On the far wall is a rusty box, whose door has been blown off.';
        }
        return (
            'Embedded in the far wall, there is a rusty old box. ' +
            'It appears that the box is somewhat damaged, since an ' +
            'oblong hole has been chipped out of the front of it.'
        );
    }

    totalCapacity(): number {
        return 15;
    }

    nouns() {
        return Safe.spec().nouns;
    }

    adjectives() {
        return Safe.spec().adjectives;
    }
}

const takeBox: Handler = async ({ action, runner }) => {
    if (action.is(Take) && action.item.is(Safe)) {
        await runner.doOutput('The box is embedded in the wall.');
        return Action.complete();
    }
};

const openBox: Handler = async ({ action, runner }) => {
    if (action.is(Open) && action.item.is(Safe)) {
        if (action.item.isOpen()) {
            await runner.doOutput('The box has no door!');
        } else {
            await runner.doOutput('The box is rusted and will not open.');
        }
        return Action.complete();
    }
};

const closeBox: Handler = async ({ action, runner }) => {
    if (action.is(Close) && action.item.is(Safe)) {
        if (action.item.isOpen()) {
            await runner.doOutput('The box has no door!');
        } else {
            await runner.doOutput('The box is not open, chomper!');
        }
        return Action.complete();
    }
};
