import { MungedSource, Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import {
    BlueButton,
    BrownButton,
    DamLeak,
    RedButton,
    Screwdriver,
    ToolChests,
    Tube,
    Wrench,
    YellowButton,
} from '../../items';
import { DamLobby } from '../DamLobby';
import { RoomState } from '../Room';
import { SpecialJigsUp, SpecialTimerTick } from '../../abilities';

interface MaintenanceRoomState extends RoomState {
    isLeaky: boolean;
    areLightsPowered: boolean;
    fillLevel: number;
}

export class MaintenanceRoom extends Room<MaintenanceRoomState> {
    static spec(): EntitySpec<MaintenanceRoom> {
        return {
            ref: 'maintenance-room',
            constructor: MaintenanceRoom,
            initial: {
                isLeaky: false,
                areLightsPowered: false,
                fillLevel: 0,
                contents: [
                    Tube.spec().ref,
                    Wrench.spec().ref,
                    DamLeak.spec().ref,
                    RedButton.spec().ref,
                    BlueButton.spec().ref,
                    ToolChests.spec().ref,
                    Screwdriver.spec().ref,
                    BrownButton.spec().ref,
                    YellowButton.spec().ref,
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [waterFillIncrease],
        };
    }

    ref() {
        return MaintenanceRoom.spec().ref;
    }

    name(): string {
        return 'Maintenance Room';
    }

    description(): string {
        return (
            'This is what appears to have been the maintenance room ' +
            'for Flood Control Dam #3, judging by the assortment of tool ' +
            'chests around the room. Apparently, this room has been ransacked ' +
            'recently, for most of the valuable equipment is gone. ' +
            'On the wall in front of you is a group of buttons, which are ' +
            'labelled in EBCDIC. However, they are of different colors: ' +
            'Blue, Yellow, Brown, and Red. ' +
            'The doors to this room are in the west and south ends.'
        );
    }

    isNaturallyLit(): boolean {
        return this.areLightsPowered();
    }

    areLightsPowered() {
        return this.state.areLightsPowered;
    }

    isLeaky() {
        return this.state.isLeaky;
    }

    fillLevel() {
        return this.state.fillLevel;
    }

    passages(): Passage[] {
        return [
            new Passage({ via: Direction.West, to: DamLobby.spec().ref }),
            new Passage({ via: Direction.South, to: DamLobby.spec().ref }),
        ];
    }
}

const DROWNINGS = [
    'up to your ankles',
    'up to your shin',
    'up to your knees',
    'up to your hips',
    'up to your waist',
    'up to your chest',
    'up to your neck',
    'over your head',
    'high in your lungs',
];

const waterFillIncrease: Handler = async ({ action, runner, game, actor }) => {
    if (action.is(SpecialTimerTick)) {
        const room = game.ent(MaintenanceRoom);
        const here = actor?.location();
        const playerIsInRoom = here?.isEqualTo(room);
        if (room.isLeaky()) {
            room.state.fillLevel += 1;
            if (playerIsInRoom) {
                const status = DROWNINGS[Math.floor(room.fillLevel() / 2)];
                await runner.doOutput(`The water level here is now ${status}.`);
            }
            if (room.fillLevel() > 16) {
                room.state.isMunged = true;
                room.state.howMunged = MungedSource.Flooded;
                room.state.isLeaky = false;
                if (playerIsInRoom) {
                    await game.applyAction(
                        runner,
                        new SpecialJigsUp({
                            message:
                                "I'm afraid you have done drowned yourself.",
                        })
                    );
                }
            }
        }
        return Action.incomplete();
    }
};
