import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { EntitySpec } from '../../game';
import { NorthCorridor } from '../NorthCorridor';
import { SouthCorridor } from '../SouthCorridor';

export class WestCorridor extends Room {
    static spec(): EntitySpec<WestCorridor> {
        return {
            ref: 'west-corridor',
            constructor: WestCorridor,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return WestCorridor.spec().ref;
    }

    name(): string {
        return 'West Corridor';
    }

    description(): string {
        return (
            'This is a corridor with polished marble walls. ' +
            'The corridor widens into larger areas as it turns east at its northern and southern ends.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    isPartOfEndgame(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.North,
                to: NorthCorridor.spec().ref,
            }),
            new Passage({
                via: Direction.South,
                to: SouthCorridor.spec().ref,
            }),
        ];
    }
}
