import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import {
    makeContainer,
    makeOpenable,
    makeTakeable,
    makeFlammable,
    makeReadable,
} from '../../game/Entity';
import { EntitySpec } from '../../game';

export class WhiteBook extends makeFlammable(
    makeReadable(makeTakeable(makeOpenable(makeContainer(Item))))
) {
    static spec(): EntitySpec<WhiteBook> {
        return {
            ref: 'white-book',
            constructor: WhiteBook,
            initial: {
                contents: [],
                isAflame: false,
                isOpen: false,
                hasBeenTaken: false,
            },
            nouns: [new Noun('book'), new Noun('books', { plural: true })],
            adjectives: [new Adjective('white')],
        };
    }

    ref() {
        return WhiteBook.spec().ref;
    }

    name(): string {
        return 'white book';
    }

    description(): string {
        return 'There is a white book here';
    }

    text() {
        return 'This book is written in a tongue with which I am unfamiliar.';
    }

    totalCapacity(): number {
        return 2;
    }

    size(): number {
        return 10;
    }

    nouns() {
        return WhiteBook.spec().nouns;
    }

    adjectives() {
        return WhiteBook.spec().adjectives;
    }
}
