import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { KillUnresolved, putInUnresolvedHandler } from './KillUnresolved';
import { Entity } from '../../game/Entity';
import { performBlow } from '../../handlers';

export class Kill extends Action {
    id = '~kill';

    weapon: Entity | undefined;

    enemy: Entity;

    constructor({
        weapon,
        enemy,
    }: {
        weapon: Entity | undefined;
        enemy: Entity;
    }) {
        super();
        this.weapon = weapon;
        this.enemy = enemy;
    }

    static ability(): Ability {
        return {
            handlers: [putInHandler, putInUnresolvedHandler],
            parser,
            verbs: [
                new Verb('kill'),
                new Verb('attack'),
                new Verb('murder'),
                new Verb('slay'),
                new Verb('dispatch'),
                new Verb('stab'),
                new Verb('do away with'),
                new Verb('end'),
                new Verb('execute'),
                new Verb('finish off'),
                new Verb('strike'),
                new Verb('slash'),
            ],
            prepositions: [new Preposition('with'), new Preposition('using')],
        };
    }
}

export const putInHandler: Handler = async ({
    action,
    actor,
    runner,
    game,
}) => {
    if (!action.is(Kill) || actor === undefined) return;

    const { weapon, enemy } = action;

    if (!weapon) {
        const weapons = actor
            .inventory()
            .filter((item) => item.isItem() && item.isWeapon());
        if (weapons.length !== 1) {
            await runner.doOutput(
                `With what do you want to kill ${enemy.the()}?`
            );
            // TODO set partial
            return Action.complete({ withConsequence: false });
        }
        const [newWeapon] = weapons;
        await game.applyAction(runner, new Kill({ weapon: newWeapon, enemy }));
    } else if (!enemy.isActor() || !enemy.isVillain() || !enemy.isVictim()) {
        await runner.doOutput(
            `I've known strange people, but fighting ${enemy.an()}?`
        );
    } else if (weapon === undefined) {
        await runner.doOutput(
            `Trying to attack ${enemy.the()} with your bare hands is suicidal.`
        );
    } else if (!weapon.isItem() || !weapon.isWeapon()) {
        await runner.doOutput(
            `Trying to attack ${enemy.the()} with ${weapon.an()} is suicidal.`
        );
    } else {
        await performBlow(game, runner, actor, enemy, weapon, undefined);
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, KillUnresolved> => {
    const ability = Kill.ability();
    const kill = Parse.words(ability.verbs);
    const killEnemy = kill.chain((_verb) =>
        Parse.target(game.lexicon).after(Parse.whitespace())
    );
    const killEnemyWith = killEnemy.chain((item) =>
        Parse.option(
            Parse.target(game.lexicon)
                .after(Parse.whitespace())
                .after(Parse.words(ability.prepositions))
                .after(Parse.whitespace())
        ).map((container) => [item, container])
    );
    return Parse.any(
        // kill
        kill.into(new KillUnresolved({ weapon: undefined, enemy: undefined })),
        // kill troll
        killEnemy.map(
            (enemy) => new KillUnresolved({ weapon: undefined, enemy })
        ),
        // kill troll with sword
        killEnemyWith.map(
            ([enemy, weapon]) => new KillUnresolved({ weapon, enemy })
        )
    );
};
