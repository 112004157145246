import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Direction, SpecialDirection } from '../../../parse';
import { TombOfTheUnknownImplementer } from '../TombOfTheUnknownImplementer';
import { EntranceToHades } from '../EntranceToHades';
import { DeadBodies } from '../../items';

export class LandOfTheLivingDead extends Room {
    static spec(): EntitySpec<LandOfTheLivingDead> {
        return {
            ref: 'land-of-the-living-dead',
            constructor: LandOfTheLivingDead,
            initial: {
                contents: [DeadBodies.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return LandOfTheLivingDead.spec().ref;
    }

    name(): string {
        return 'Land of the Living Dead';
    }

    scoreOnEntry(): number {
        return 30;
    }

    description(): string {
        return (
            'You have entered the Land of the Living Dead, a large desolate room. ' +
            'Although it is apparently uninhabited, you can hear the sounds of ' +
            'thousands of lost souls weeping and moaning. In the east corner are ' +
            'stacked the remains of dozens of previous adventurers who were ' +
            'less fortunate than yourself. To the east is an ornate passage, ' +
            'apparently recently constructed. A passage exits to the west.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.East,
                to: TombOfTheUnknownImplementer.spec().ref,
            }),
            new Passage({
                via: [Direction.West, SpecialDirection.Out],
                to: EntranceToHades.spec().ref,
            }),
        ];
    }
}
