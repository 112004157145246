import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { EntitySpec } from '../../game';
import { NarrowLedge } from '../NarrowLedge';
import { Balloon } from '../../items';

export class VolcanoNearSmallLedge extends Room {
    static spec(): EntitySpec<VolcanoNearSmallLedge> {
        return {
            ref: 'volcano-near-small-ledge',
            constructor: VolcanoNearSmallLedge,
            initial: {
                contents: [],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return VolcanoNearSmallLedge.spec().ref;
    }

    name(): string {
        return 'Volcano Near Small Ledge';
    }

    description(): string {
        return (
            'You are about two hundred feet above the volcano floor. ' +
            'Looming above is the rim of the volcano. ' +
            'There is a small ledge on the west side.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [SpecialDirection.Land, Direction.West],
                to: NarrowLedge.spec().ref,
                allowedVehicles: [this.game.ent(Balloon)],
            }),
        ];
    }

    isOnLand(): boolean {
        return false;
    }

    isSacred() {
        return true;
    }

    hasWalls() {
        return false;
    }
}
