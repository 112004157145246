import { Passage, Room } from '..';
import { EntitySpec } from '../../game';
import { Bills } from '../../items';

export class Vault extends Room {
    static spec(): EntitySpec<Vault> {
        return {
            ref: 'vault',
            constructor: Vault,
            initial: {
                contents: [Bills.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return Vault.spec().ref;
    }

    name(): string {
        return 'Vault';
    }

    description(): string {
        return 'This is the Vault of the Bank of Zork, in which there are no doors.';
    }

    isNaturallyLit(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [];
    }

    hasWalls(): boolean {
        return true;
    }
}
