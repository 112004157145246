import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Action, EntitySpec, Handler } from '../../game';
import { Jump, SpecialJigsUp } from '../../abilities';
import { JUMP_DEATH } from '../../constants';
import { EndOfTheRainbow } from '../EndOfTheRainbow';
import { AragainFalls } from '../AragainFalls';
import { Rainbow } from '../../items';

export class OnTheRainbow extends Room {
    static spec(): EntitySpec<OnTheRainbow> {
        return {
            ref: 'on-the-rainbow',
            constructor: OnTheRainbow,
            initial: {
                contents: [
                    Rainbow.spec().ref,
                    // TODO maybe NBCCommissary
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [jumpOffRainbow],
        };
    }

    ref() {
        return OnTheRainbow.spec().ref;
    }

    name(): string {
        return 'On the Rainbow';
    }

    description(): string {
        return (
            'You are on top of a rainbow (I bet you never thought ' +
            'you would walk on a rainbow), with a magnificent view of the Falls. ' +
            'The rainbow travels east-west here. There is an NBC Commissary here.'
        );
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isSacred(): boolean {
        return true;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Down,
                message: 'The fall would probably kill you.',
            }),
            new Passage({
                via: Direction.East,
                to: EndOfTheRainbow.spec().ref,
            }),
            new Passage({
                via: Direction.West,
                to: AragainFalls.spec().ref,
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}

const jumpOffRainbow: Handler = async ({ action, runner, actor, game }) => {
    if (action.is(Jump) && actor?.location()?.is(OnTheRainbow)) {
        await game.applyAction(
            runner,
            new SpecialJigsUp({ message: game.choiceOf(JUMP_DEATH) })
        );
        return Action.complete();
    }
};
