import { Passage, Room } from '..';
import { Direction, SpecialDirection } from '../../../parse';
import { CURRENT_STRONG } from '../../constants';
import { EntitySpec } from '../../game';
import { River4 } from '../River4';
import { WhiteCliffsBeach1 } from '../WhiteCliffsBeach1';
import { RockyShore } from '../RockyShore';
import { Boat, River } from '../../items';

export class River3 extends Room {
    static spec(): EntitySpec<River3> {
        return {
            ref: 'river-3',
            constructor: River3,
            initial: {
                contents: [River.spec().ref],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
        };
    }

    ref() {
        return River3.spec().ref;
    }

    name(): string {
        return 'Frigid River';
    }

    description(): string {
        return (
            'The river descends here into a valley. There is a narrow beach ' +
            'on the east below the cliffs and there is some shore on the west ' +
            'which may be suitable. In the distance a faint rumbling can be heard.'
        );
    }

    isSacred(): boolean {
        return true;
    }

    isNaturallyLit(): boolean {
        return true;
    }

    isOnLand(): boolean {
        return false;
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: [Direction.Down, Direction.South],
                to: River4.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: Direction.East,
                to: WhiteCliffsBeach1.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: Direction.West,
                to: RockyShore.spec().ref,
                allowedVehicles: [this.game.ent(Boat)],
            }),
            new Passage({
                via: SpecialDirection.Land,
                message: 'Which direction do you want to land, east or west?',
            }),
            new Passage({
                via: [Direction.Up, Direction.North],
                message: CURRENT_STRONG,
            }),
        ];
    }

    hasWalls() {
        return false;
    }
}
