import { Noun, Adjective } from '../../../parse';
import { makeTakeable, makeFlammable } from '../../game/Entity';
import { Item, ItemState } from '../Item';
import { Action, EntitySpec, Handler } from '../../game';
import { Count, Extinguish, Light, SpecialTimerTick } from '../../abilities';
import { Player } from '../../actors';
import { Torch } from '../Torch';
import { Matchbook } from '../Matchbook';

interface TorchState extends ItemState {
    timeRemaining: number;
}

abstract class Base extends Item<TorchState> {}

export class Candles extends makeFlammable(makeTakeable(Base)) {
    static spec(): EntitySpec<Candles> {
        return {
            ref: 'candles',
            constructor: Candles,
            initial: {
                isAflame: true,
                hasBeenTaken: false,
                timeRemaining: 50,
            },
            nouns: [
                new Noun('candle', { collective: true }),
                new Noun('candles', { collective: true }),
                new Noun('pair of candles'),
                new Noun('pairs of candles', { plural: true }),
                new Noun('pair'),
            ],
            adjectives: [
                new Adjective('flaming'),
                new Adjective('unlit'),
                new Adjective('altar'),
                new Adjective('wax'),
            ],
            handlers: [
                depleteCandlesLife,
                lightCandles,
                extinguishCandles,
                countCandles,
            ],
        };
    }

    ref() {
        return Candles.spec().ref;
    }

    name() {
        return 'pair of candles';
    }

    quantity() {
        return 2;
    }

    description() {
        return 'There are two candles here.';
    }

    initialDescription() {
        return 'On the two ends of the altar are burning candles.';
    }

    size() {
        return 20;
    }

    nouns(): Noun[] {
        return Candles.spec().nouns;
    }

    adjectives(): Adjective[] {
        const adjectives = [new Adjective('altar'), new Adjective('wax')];
        if (this.isAflame()) {
            adjectives.push(new Adjective('flaming'));
        } else {
            adjectives.push(new Adjective('unlit'));
        }
        return adjectives;
    }

    timeRemaining() {
        return this.state.timeRemaining;
    }

    isDepleted() {
        return this.timeRemaining() <= 0;
    }
}

const depleteCandlesLife: Handler = async ({ action, runner, game }) => {
    // TODO what if you're not holding the candles when the tick happens?
    //      and same with the lamp
    if (action.is(SpecialTimerTick)) {
        const candles = game.ent(Candles);
        if (
            candles.isAflame() &&
            !candles.isDepleted() &&
            candles.hasBeenTaken()
        ) {
            const newLevel = candles.timeRemaining() - 1;
            candles.state.timeRemaining = newLevel;
            if ([20, 10].includes(newLevel)) {
                await runner.doOutput('The candles grow shorter.');
            } else if (newLevel === 5) {
                await runner.doOutput('The candles are very short');
            } else if (newLevel === 0) {
                candles.state.isAflame = false;
                await runner.doOutput(
                    'I hope you have more light than from a pair of candles.'
                );
                const room = game.locateEntity(game.ent(Player));
                if (!room?.isLit()) {
                    await runner.doOutput('It is now pitch black.');
                }
            }
        }
        return Action.incomplete();
    }
};

const lightCandles: Handler = async ({ action, runner }) => {
    if (action.is(Light) && action.item.is(Candles) && action.tool) {
        const { item: candles, tool } = action;
        if (candles.isDepleted()) {
            await runner.doOutput(
                "Alas, there's not much left of the candles. Certainly not enough to burn."
            );
        } else if (tool.isItem() && tool.isFlammable() && tool.isAflame()) {
            if (tool.is(Torch)) {
                if (candles.isAflame()) {
                    await runner.doOutput(
                        'You realize, just in time, that the candles are already lighted.'
                    );
                } else {
                    await runner.doOutput(
                        'The heat from the torch is so intense that the candles are vaporised.'
                    );
                    candles.moveTo(undefined);
                }
            } else if (tool.is(Matchbook)) {
                if (candles.isAflame()) {
                    await runner.doOutput('The candles are already lighted.');
                } else {
                    await runner.doOutput('The candles are lighted.');
                    candles.state.isAflame = true;
                }
            } else {
                return Action.incomplete();
            }
        } else {
            await runner.doOutput(
                "You have to light them with something that's burning, you know."
            );
        }
        return Action.complete();
    }
};

const extinguishCandles: Handler = async ({ action, runner }) => {
    if (action.is(Extinguish) && action.item.is(Candles)) {
        if (action.item.isAflame()) {
            action.item.state.isAflame = false;
            await runner.doOutput('The flame is extinguished.');
        } else {
            await runner.doOutput('The candles are not lighted.');
        }
        return Action.complete();
    }
};

const countCandles: Handler = async ({ action, runner }) => {
    if (action.is(Count) && action.item?.is(Candles)) {
        await runner.doOutput(
            "Let's see, how many objects in a pair? Don't tell me, I'll get it."
        );
        return Action.complete();
    }
};
