import { Verb, Parse, Parser, Value, Preposition } from '../../../parse';
import { Entity, Ability, Action, Handler } from '../../game';
import { Game } from '../../game/game';
import { TellUnresolved, tellUnresolvedHandler } from './TellUnresolved';
import { Player } from '../../actors';

export class Tell extends Action {
    id = '~tell';

    person: Entity;

    message: string;

    constructor({ person, message }: { person: Entity; message: string }) {
        super();
        this.person = person;
        this.message = message;
    }

    static ability(): Ability {
        return {
            handlers: [tellHandler, tellUnresolvedHandler],
            parser,
            verbs: [
                new Verb('tell'),
                new Verb('command'),
                new Verb('ask'),
                new Verb('instruct'),
                new Verb('order'),
                new Verb('direct'),
            ],
            prepositions: [new Preposition('to')],
        };
    }
}

export const tellHandler: Handler = async ({ action, runner, game, actor }) => {
    if (!action.is(Tell)) return undefined;
    const { person } = action;

    if (!actor?.is(Player)) {
        await runner.doOutput('You cannot talk through another person!');
    } else if (person.isActor()) {
        if (person.respondsToCommands()) {
            await game.applyCommand(runner, action.message, person);
        } else {
            await runner.doOutput(`${person.The()} pays no attention.`);
        }
    } else {
        await runner.doOutput('You cannot talk to that!');
    }

    return Action.complete();
};

const parser = (game: Game): Parser<Value, TellUnresolved> => {
    const tell = Parse.words(Tell.ability().verbs);
    const tellActor = tell
        .before(Parse.whitespace())
        .beforeX(Parse.target(game.lexicon));
    return Parse.any(
        tell.into(
            new TellUnresolved({ message: undefined, person: undefined })
        ),
        tellActor.map(
            (person) => new TellUnresolved({ message: undefined, person })
        ),
        tellActor.chain((person) =>
            Parse.either(
                Parse.whitespace()
                    .before(Parse.quote())
                    .beforeX(Parse.many(Parse.node()))
                    .before(Parse.quote()),
                Parse.whitespace()
                    .before(Parse.word('to'))
                    .before(Parse.whitespace())
                    .beforeX(Parse.many(Parse.node()))
            )
                .map((nodes) => nodes.map((node) => node.value).join(''))
                .map((message) => new TellUnresolved({ message, person }))
        )
    );
};
