import { Noun, Adjective } from '../../../parse';
import { makeFlammable, makeReadable, makeTakeable } from '../../game/Entity';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class GreenPaper extends makeFlammable(
    makeTakeable(makeReadable(Item))
) {
    static spec(): EntitySpec<GreenPaper> {
        return {
            ref: 'green-paper',
            constructor: GreenPaper,
            initial: {
                isAflame: false,
                hasBeenTaken: false,
            },
            nouns: [
                new Noun('papers', { plural: true }),
                new Noun('paper'),
                new Noun('instructions', { collective: true }),
                new Noun('piece of paper'),
                new Noun('pieces of paper', { plural: true }),
                new Noun('slip of paper'),
                new Noun('slips of paper', { plural: true }),
            ],
            adjectives: [new Adjective('green')],
        };
    }

    ref() {
        return GreenPaper.spec().ref;
    }

    name() {
        return 'green piece of paper';
    }

    description() {
        return 'There is a green piece of paper here.';
    }

    size() {
        return 3;
    }

    text() {
        return ROBOT_INSTRUCTIONS;
    }

    nouns(): Noun[] {
        return GreenPaper.spec().nouns;
    }

    adjectives(): Adjective[] {
        return GreenPaper.spec().adjectives;
    }
}

const ROBOT_INSTRUCTIONS = `!!!!  FROBOZZ MAGIC ROBOT COMPANY  !!!!

  Hello, Master!
    I am a late-model robot, trained at MIT Tech to perform various simple household functions.

  Instructions for use:
    To activate me, use the following formula:
    \`tell robot "do something"\` or \`tell robot to do something\`
    The quotation marks are not required!

  Warranty:
    No warranty is expressed or implied.
      At your service!`;
