import { Adjective, Noun } from '../../../parse';
import { Item } from '../Item';
import { EntitySpec } from '../../game';

export class PoledCretinHead extends Item {
    static spec(): EntitySpec<PoledCretinHead> {
        return {
            ref: 'poled-cretin-head',
            constructor: PoledCretinHead,
            initial: {},
            nouns: [
                new Noun('head'),
                new Noun('heads', { plural: true }),
                new Noun('head on a pole'),
                new Noun('pole'),
                new Noun('poles', { plural: true }),
                new Noun('loser'),
                new Noun('losers', { plural: true }),
            ],
            adjectives: [new Adjective('poled')],
        };
    }

    ref() {
        return PoledCretinHead.spec().ref;
    }

    name(): string {
        return 'head on a pole';
    }

    description(): string {
        return (
            'Amid the desolation, you spot what appears to be your ' +
            'head, at the end of a long pole.'
        );
    }

    nouns() {
        return PoledCretinHead.spec().nouns;
    }

    adjectives() {
        return PoledCretinHead.spec().adjectives;
    }

    shouldTryToTake(): boolean {
        return true;
    }

    isSacred() {
        return true;
    }
}
