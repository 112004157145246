import { Parse, SpecialDirection, Verb } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';
import { Go } from '../Go';
import { Player } from '../../actors';
import { Board } from '../Board';

export class Enter extends makeDescribable(Action) {
    id = 'enter';

    description(game: Game): string {
        return 'enter';
    }

    static ability(): Ability {
        const verbs = [new Verb('enter'), new Verb('go in')];
        return {
            handlers: [enterHandler],
            parser: () => Parse.words(verbs).into(new Enter()),
            verbs,
            prepositions: [],
        };
    }
}

export const enterHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(Enter)) return;
    const vehicle = game
        .ent(Player)
        .location()
        .contents()
        .find((item) => item.isItem() && item.isVehicle());
    if (vehicle) {
        await game.applyAction(runner, new Board({ vehicle }));
    } else {
        await game.applyAction(
            runner,
            new Go({ direction: SpecialDirection.In }),
            actor
        );
    }
    return Action.complete();
};
