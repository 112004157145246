import {
    Direction,
    Parse,
    Parser,
    Preposition,
    Value,
    Verb,
} from '../../../parse';
import { Ability, Action, Entity, Handler } from '../../game';
import { Game } from '../../game/game';
import { ClimbUnresolved, climbUnresolvedHandler } from './ClimbUnresolved';
import { Go } from '..';

export class Climb extends Action {
    id = '~climb';

    item: Entity | undefined;

    direction: Direction | undefined;

    constructor({
        item,
        direction,
    }: {
        item: Entity | undefined;
        direction: Direction | undefined;
    }) {
        super();
        this.item = item;
        this.direction = direction;
    }

    static ability(): Ability {
        return {
            handlers: [climbHandler, climbUnresolvedHandler],
            parser,
            verbs: [new Verb('climb'), new Verb('scale')],
            prepositions: [new Preposition('down'), new Preposition('up')],
        };
    }
}

export const climbHandler: Handler = async ({
    action,
    runner,
    game,
    actor,
}) => {
    if (!action.is(Climb)) return;
    const { item, direction } = action;
    if (item?.isItem() && item.isWall()) {
        await runner.doOutput('Climbing the walls is of no avail.');
    } else if (item?.isItem() && !item.canBeClimbed()) {
        await runner.doOutput('Bizarre!');
    } else {
        await game.applyAction(
            runner,
            new Go({ direction: direction || Direction.Up }),
            actor
        );
    }
    return Action.complete();
};

const parser = (game: Game): Parser<Value, ClimbUnresolved> => {
    const climb = Parse.words(Climb.ability().verbs).into(undefined);
    const climbUpDown = climb
        .before(Parse.whitespace())
        .beforeX(
            Parse.either(
                Parse.word('up').into(Direction.Up),
                Parse.word('down').into(Direction.Down)
            )
        );
    const climbObject = Parse.either(climb, climbUpDown).chain((direction) =>
        Parse.target(game.lexicon)
            .after(Parse.whitespace())
            .map((item) => ({ item, direction }))
    );
    return Parse.any(
        // climb
        climb.into(
            new ClimbUnresolved({ item: undefined, direction: undefined })
        ),
        // climb up
        climbUpDown.map(
            (direction) => new ClimbUnresolved({ item: undefined, direction })
        ),
        // climb (up) tree
        climbObject.map(
            ({ item, direction }) => new ClimbUnresolved({ item, direction })
        )
    );
};
