import { Handler } from '../../game';
import { SpecialAfter, SpecialTimerTick } from '../../abilities';
import { Effect } from '../../game/Action';

const handler: Handler = async ({ action, game, runner }) => {
    if (!action.is(SpecialAfter)) return;
    if (action.effect === Effect.NoConsequence) return;
    return game.applyAction(runner, new SpecialTimerTick({}));
};

export const TickTimers = {
    handler: () => handler,
};
