import { Noun, Adjective } from '../../../parse';
import { Item } from '../Item';
import { makeOpenable } from '../../game/Entity';
import { EntitySpec } from '../../game';
import { goodDoor } from '../utils';

export class KitchenWindow extends makeOpenable(Item) {
    static spec(): EntitySpec<KitchenWindow> {
        return {
            ref: 'kitchen-window',
            constructor: KitchenWindow,
            initial: {
                isOpen: false,
            },
            nouns: [new Noun('window'), new Noun('windows', { plural: true })],
            adjectives: [new Adjective('kitchen'), new Adjective('small')],
            handlers: [handleKitchenWindowOpenClose],
        };
    }

    ref() {
        return KitchenWindow.spec().ref;
    }

    name(): string {
        return 'kitchen window';
    }

    description(): string {
        return '';
    }

    shouldBeDescribed(): boolean {
        return false;
    }

    canBeOpened(): boolean {
        return true;
    }

    canSeeInto(): boolean {
        return this.isOpen() || this.isTransparent();
    }

    nouns() {
        return KitchenWindow.spec().nouns;
    }

    adjectives() {
        return KitchenWindow.spec().adjectives;
    }
}

const handleKitchenWindowOpenClose = goodDoor({
    item: KitchenWindow,
    open: 'With great effort, you open the window far enough to allow entry.',
    close: 'The window closes (more easily than it opened).',
});
