import { Verb, Parse } from '../../../parse';
import { Ability, Action, Handler } from '../../game';
import { makeDescribable } from '../../game/Action';
import { Game } from '../../game/game';

export class Restart extends makeDescribable(Action) {
    id = 'restart';

    description(game: Game): string {
        return 'restart';
    }

    static ability(): Ability {
        const verbs = [new Verb('restart')];
        return {
            handlers: [restartHandler],
            parser: () => Parse.words(verbs).into(new Restart()),
            verbs,
            prepositions: [],
        };
    }
}

export const restartHandler: Handler = async ({ action, game, runner }) => {
    if (!action.is(Restart)) return;
    game.yesNo = async (answer: boolean) => {
        if (answer) {
            await game.restart(runner);
        } else {
            await runner.doOutput('Ok.');
        }
    };
    await runner.doOutput('Are you sure you want to restart?');
    return Action.complete({ withConsequence: false });
};
