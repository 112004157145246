import { Passage, Room } from '..';
import { Direction } from '../../../parse';
import { Entity, EntitySpec } from '../../game';
import { MineEntrance } from '../MineEntrance';
import { WoodenTunnel } from '../WoodenTunnel';
import { Basket } from '../../items';

export class ShaftRoom extends Room {
    static spec(): EntitySpec<ShaftRoom> {
        return {
            ref: 'shaft-room',
            constructor: ShaftRoom,
            initial: {
                contents: [
                    Basket.spec().ref,
                    // TODO "chain"?
                ],
                hasBeenVisited: false,
                hasBeenDescribed: false,
            },
            nouns: [],
            adjectives: [],
            handlers: [],
        };
    }

    ref() {
        return ShaftRoom.spec().ref;
    }

    name(): string {
        return 'Shaft Room';
    }

    description(): string {
        return (
            'This is a large room, in the middle of which is a ' +
            'small shaft descending through the floor into darkness below. ' +
            'To the west and the north are exits from this room. ' +
            'Constructed over the top of the shaft is a metal framework ' +
            'to which a heavy iron chain is attached.'
        );
    }

    isNaturallyLit(): boolean {
        return false;
    }

    isSacred(): boolean {
        return false;
    }

    visibleObjects(): Entity[] {
        const basket = this.game.ent(Basket);
        return this.contains(basket) ? [] : [basket];
    }

    passages(): Passage[] {
        return [
            new Passage({
                via: Direction.Down,
                message: "You wouldn't fit and would die if you could.",
            }),
            new Passage({
                via: Direction.West,
                to: MineEntrance.spec().ref,
            }),
            new Passage({
                via: Direction.North,
                to: WoodenTunnel.spec().ref,
            }),
        ];
    }
}
